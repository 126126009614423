export const GUIDELINE_LIST_REQUEST = 'GUIDELINE_LIST_REQUEST'
export const GUIDELINE_LIST_SUCCESS = 'GUIDELINE_LIST_SUCCESS'
export const GUIDELINE_LIST_FAIL = 'GUIDELINE_LIST_FAIL'


export const GUIDELINE_DETAILS_REQUEST = 'GUIDELINE_DETAILS_REQUEST'
export const GUIDELINE_DETAILS_SUCCESS = 'GUIDELINE_DETAILS_SUCCESS'
export const GUIDELINE_DETAILS_FAIL = 'GUIDELINE_DETAILS_FAIL'


export const GUIDELINE_DELETE_REQUEST = 'GUIDELINE_DELETE_REQUEST'
export const GUIDELINE_DELETE_SUCCESS = 'GUIDELINE_DELETE_SUCCESS'
export const GUIDELINE_DELETE_FAIL = 'GUIDELINE_DELETE_FAIL'

export const GUIDELINE_CREATE_REQUEST = 'GUIDELINE_CREATE_REQUEST'
export const GUIDELINE_CREATE_SUCCESS = 'GUIDELINE_CREATE_SUCCESS'
export const GUIDELINE_CREATE_FAIL = 'GUIDELINE_CREATE_FAIL'
export const GUIDELINE_CREATE_RESET = 'GUIDELINE_CREATE_RESET'

export const GUIDELINE_UPDATE_REQUEST = 'GUIDELINE_UPDATE_REQUEST'
export const GUIDELINE_UPDATE_SUCCESS = 'GUIDELINE_UPDATE_SUCCESS'
export const GUIDELINE_UPDATE_FAIL = 'GUIDELINE_UPDATE_FAIL'
export const GUIDELINE_UPDATE_RESET = 'GUIDELINE_UPDATE_RESET'

export const GUIDELINE_REQUEST = 'GUIDELINE_REQUEST'
export const GUIDELINE_SUCCESS = 'GUIDELINE_SUCCESS'
export const GUIDELINE_FAIL = 'GUIDELINE_FAIL'
export const GUIDELINE_RESET = 'GUIDELINE_RESET'

export const GUIDELINE_COUNT_REQUEST = "GUIDELINE_COUNT_REQUEST";
export const GUIDELINE_COUNT_SUCCESS = "GUIDELINE_COUNT_SUCCESS";
export const GUIDELINE_COUNT_FAIL = "GUIDELINE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const GUIDELINE_DTO_REQUEST = 'GUIDELINE_DTO_REQUEST';
export const GUIDELINE_DTO_SUCCESS = 'GUIDELINE_DTO_SUCCESS';
export const GUIDELINE_DTO_FAIL = 'GUIDELINE_DTO_FAIL';
export const GUIDELINE_DTO_RESET = 'GUIDELINE_DTO_RESET';

export const GUIDELINE_LIST_BYIDS_REQUEST = 'GUIDELINE_LIST_BYIDS_REQUEST';
export const GUIDELINE_LIST_BYIDS_SUCCESS = 'GUIDELINE_LIST_BYIDS_SUCCESS';
export const GUIDELINE_LIST_BYIDS_FAIL = 'GUIDELINE_LIST_BYIDS_FAIL';
export const GUIDELINE_LIST_BYIDS_RESET = 'GUIDELINE_LIST_BYIDS_RESET';