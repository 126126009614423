import {

    INVOICE_LINE_LIST_REQUEST,
    INVOICE_LINE_LIST_SUCCESS,
    INVOICE_LINE_LIST_FAIL,

    INVOICE_LINE_DETAILS_REQUEST,
    INVOICE_LINE_DETAILS_SUCCESS,
    INVOICE_LINE_DETAILS_FAIL,

    INVOICE_LINE_DELETE_REQUEST,
    INVOICE_LINE_DELETE_SUCCESS,
    INVOICE_LINE_DELETE_FAIL,

    INVOICE_LINE_CREATE_REQUEST,
    INVOICE_LINE_CREATE_SUCCESS,
    INVOICE_LINE_CREATE_FAIL,
    INVOICE_LINE_CREATE_RESET,

    INVOICE_LINE_UPDATE_REQUEST,
    INVOICE_LINE_UPDATE_SUCCESS,
    INVOICE_LINE_UPDATE_FAIL,
    INVOICE_LINE_UPDATE_RESET,

    SET_CURRENT_PAGE,

    INVOICE_LINE_DTO_FAIL,
    INVOICE_LINE_DTO_REQUEST,
    INVOICE_LINE_DTO_SUCCESS,
    INVOICE_LINE_DTO_RESET,

    INVOICE_LINE_LIST_BYIDS_REQUEST,
    INVOICE_LINE_LIST_BYIDS_SUCCESS,
    INVOICE_LINE_LIST_BYIDS_FAIL,
    INVOICE_LINE_LIST_BYIDS_RESET

} from "../constants/invoiceLineConstants";

const initialState = {
    invoiceLines: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const invoiceLineListReducer = (
    state = {
        invoiceLines: []
    }, action) => {
    switch (action.type) {
        case INVOICE_LINE_LIST_REQUEST:
            return { loading: true, invoiceLines: [] };
        case INVOICE_LINE_LIST_SUCCESS:
            // our state will have a list of invoiceLines after the request
            return {
                loading: false,
                invoiceLines: action.payload.invoiceLines,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case INVOICE_LINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const invoiceLineListByIDsReducer = (
    state = {
        invoiceLines: []
    }, action) => {
    switch (action.type) {
        case INVOICE_LINE_LIST_BYIDS_REQUEST:
            return { loading: true, invoiceLines: [] };
        case INVOICE_LINE_LIST_BYIDS_SUCCESS:
            // our state will have a list of invoiceLines after the request
            return { loading: false, invoiceLines: action.payload };
        case INVOICE_LINE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case INVOICE_LINE_LIST_BYIDS_RESET:
            return { invoiceLines: [] };
        default:
            return state;
    }
}


export const invoiceLineDetailsReducer = (
    state = {
        invoiceLine: { comments: [] }
    }, action) => {
    switch (action.type) {
        case INVOICE_LINE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case INVOICE_LINE_DETAILS_SUCCESS:
            // our state will have a list of invoiceLines after the request
            return { loading: false, invoiceLine: action.payload };
        case INVOICE_LINE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const invoiceLineDtoReducer = (state = { invoiceLineDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case INVOICE_LINE_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case INVOICE_LINE_DTO_SUCCESS:
        return { ...state, loading: false, invoiceLineDto: action.payload };
      case INVOICE_LINE_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case INVOICE_LINE_DTO_RESET:
        return { invoiceLineDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const invoiceLineDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_LINE_DELETE_REQUEST:
            return { loading: true }

        case INVOICE_LINE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case INVOICE_LINE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const invoiceLineCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_LINE_CREATE_REQUEST:
            return { loading: true }

        case INVOICE_LINE_CREATE_SUCCESS:
            return { loading: false, success: true, invoiceLine: action.payload }

        case INVOICE_LINE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case INVOICE_LINE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const invoiceLineUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case INVOICE_LINE_UPDATE_REQUEST:
            return { loading: true }

        case INVOICE_LINE_UPDATE_SUCCESS:
            return { loading: false, success: true, invoiceLine: action.payload }

        case INVOICE_LINE_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case INVOICE_LINE_UPDATE_RESET:
            return { invoiceLine: {} }

        default:
            return state
    }
}