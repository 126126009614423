import React, { useState, useEffect, useCallback } from 'react';
import { Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const { Option, OptGroup } = Select;

const CustomAutoComplete = ({
  entities,
  selectedEntities,
  setSelectedEntities,
  selectedFilter,
  pluralEntity,
  primarySearchProp,
  secondarySearchProp,
  groupSearchProp,
  oneToOneView,
  onFilterChange,
  currentPage,
  totalPages,
  limit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]); // State to manage selected options
  const [currentEntities, setCurrentEntities] = useState(entities || []); // State to manage current entities
  const { t } = useTranslation(); // Initialize the translation hook

  // Effect to sync selected options with selectedEntities on initial load or change
  useEffect(() => {
    const initialSelectedOptions = selectedEntities?.map(entity => entity[primarySearchProp]);
    setSelectedOptions(initialSelectedOptions);
  }, [selectedEntities, primarySearchProp]);

  // Handler for when selected options change
  const handleChange = (selectedValues) => {
    const uniqueSelectedValues = [...new Set(selectedValues)]; // Remove duplicates
    
    setSelectedOptions(uniqueSelectedValues);
    
    // Filter selected entities from currentEntities
    const selectedEntitiesFromCurrent = currentEntities?.filter(entity =>
      uniqueSelectedValues.includes(entity[primarySearchProp])
    );


    // Filter selected entities not in currentEntities
    const selectedEntitiesNotInCurrent = selectedEntities?.filter(entity =>
      !currentEntities.some(e => e[primarySearchProp] === entity[primarySearchProp])
    );


    // Combine and remove duplicates to update selected entities
    const updatedSelectedEntities = [
      ...selectedEntitiesFromCurrent,
      ...selectedEntitiesNotInCurrent,
    ];

    // Ensure uniqueness by primarySearchProp and update state
    const uniqueSelectedEntities = Array.from(
      new Map(updatedSelectedEntities?.map(entity => [entity[primarySearchProp], entity])).values()
    );

    setSelectedEntities(uniqueSelectedEntities);
  };

  // Handler for search term change
  const handleSearch = useCallback(
    debounce((value) => {
      const filter = { query: value, filter: selectedFilter, page: currentPage, limit }
      onFilterChange(filter);
    }, 300),
    [onFilterChange, selectedFilter, currentPage, limit]
  );

  // Effect to combine entities and selectedEntities to create currentEntities without duplicates
  useEffect(() => {
    const combinedEntities = entities ? [...entities, ...selectedEntities] : selectedEntities;
    const uniqueEntities = Array.from(new Map(combinedEntities?.map(entity => [entity[primarySearchProp], entity])).values());
    setCurrentEntities(uniqueEntities);
  }, [entities, selectedEntities, primarySearchProp]);

  // Determine select mode based on oneToOneView
  const selectMode = oneToOneView ? undefined : 'multiple';

  // Conditional rendering based on groupSearchProp
  if (groupSearchProp) {
    // Extract unique groups from currentEntities
    const uniqueGroups = [...new Set(currentEntities?.map(entity => entity[groupSearchProp]))];

    // Handler to select all entities within a group
    const handleGroupSelectAll = (group, checked) => {
      const groupEntities = currentEntities
        .filter(entity => entity[groupSearchProp] === group)
        ?.map(entity => entity[primarySearchProp]);

      let updatedSelectedOptions;
      let updatedSelectedEntities;

      if (checked) {
        updatedSelectedOptions = [...new Set([...selectedOptions, ...groupEntities])];
        updatedSelectedEntities = [
          ...new Set([...selectedEntities, ...currentEntities?.filter(entity => groupEntities.includes(entity[primarySearchProp]))]),
        ];
      } else {
        updatedSelectedOptions = selectedOptions?.filter(option => !groupEntities.includes(option));
        updatedSelectedEntities = selectedEntities?.filter(entity => !groupEntities.includes(entity[primarySearchProp]));
      }

      setSelectedOptions(updatedSelectedOptions);
      setSelectedEntities(updatedSelectedEntities);
    };

    // Render Select with OptionGroups for each unique group
    return (
      <Select
        mode={selectMode}
        
        style={{ width: '100%', maxWidth: '500px' }}
        placeholder={`Search for ${pluralEntity}`}
        value={selectedOptions}
        onChange={handleChange}
        onSearch={handleSearch}
        optionLabelProp="label"
        filterOption={false}
        maxTagCount={3}

      >
        {uniqueGroups?.map(group => {
          // Filter entities by group and ensure uniqueness
          const groupEntities = currentEntities
            .filter(entity => entity[groupSearchProp] === group)
            .reduce((acc, entity) => {
              if (!acc.some(e => e[primarySearchProp] === entity[primarySearchProp])) {
                acc.push(entity);
              }
              return acc;
            }, []);

          // Determine if all entities in the group are selected
          const isAllSelected = groupEntities.every(entity => selectedOptions.includes(entity[primarySearchProp])) && groupEntities.length > 0;

          return (
            <OptGroup             
              key={group}
              label={
                <div>
                  {!oneToOneView && (
                    <Checkbox
                      checked={isAllSelected}
                      onChange={e => handleGroupSelectAll(group, e.target.checked)}
                    >
                      {group}
                    </Checkbox>
                  )}
                  {oneToOneView && <span>{group}</span>}
                </div>
              }
            >
              {groupEntities?.map(entity => (
                <Option key={entity[primarySearchProp]} value={entity[primarySearchProp]} label={entity[primarySearchProp]}>
                  {!oneToOneView && (
                    <Checkbox checked={selectedOptions.includes(entity[primarySearchProp])}>{entity[primarySearchProp]}</Checkbox>
                  )}
                  {oneToOneView && <span>{entity[primarySearchProp]}</span>}
                </Option>
              ))}
            </OptGroup>
          );
        })}
      </Select>
    );
  }

  // Render Select for non-grouped entities
  return (
    <Select
      mode={selectMode}
      style={{ width: '100%', maxWidth: '500px' }}

      placeholder={`${t("Search for")} ${pluralEntity}`}
      value={selectedOptions}
      onChange={handleChange}
      onSearch={handleSearch}
      optionLabelProp="label"
      filterOption={false}
      showSearch
    >
      {currentEntities?.map(entity => (
        <Option key={entity[primarySearchProp]} value={entity[primarySearchProp]} label={entity[primarySearchProp]}>
          {!oneToOneView && (
            <Checkbox checked={selectedOptions.includes(entity[primarySearchProp])}>{entity[primarySearchProp]}</Checkbox>
          )}
          {oneToOneView && <span>{entity[primarySearchProp]}</span>}
        </Option>
      ))}
    </Select>
  );
};

export default CustomAutoComplete;
