import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Space,
  Button,
  Input,
  Modal,
  Form,
  message,
  Popconfirm,
  Typography,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  listGenericTemplates,
  createGenericTemplate,
  updateGenericTemplate,
  deleteGenericTemplate,
} from '../../actions/templateActions';

const { Title } = Typography;
const { Search } = Input;
const { TextArea } = Input;

const GenericTemplateManagementScreen = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();

  const genericTemplateList = useSelector((state) => state.genericTemplateList);
  const { loading, error, genericTemplates, page, pages } = genericTemplateList;

  const genericTemplateCreate = useSelector((state) => state.genericTemplateCreate);
  const { success: createSuccess, error: createError } = genericTemplateCreate;

  const genericTemplateUpdate = useSelector((state) => state.genericTemplateUpdate);
  const { success: updateSuccess, error: updateError } = genericTemplateUpdate;

  const genericTemplateDelete = useSelector((state) => state.genericTemplateDelete);
  const { success: deleteSuccess, error: deleteError } = genericTemplateDelete;

  useEffect(() => {
    dispatch(listGenericTemplates());
  }, [dispatch, createSuccess, updateSuccess, deleteSuccess]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      setIsModalVisible(false);
      form.resetFields();
      setEditingTemplate(null);
      message.success(createSuccess ? 'Template created successfully' : 'Template updated successfully');
    }
    if (createError || updateError) {
      message.error(createError || updateError);
    }
    if (deleteSuccess) {
      message.success('Template deleted successfully');
    }
    if (deleteError) {
      message.error(deleteError);
    }
  }, [createSuccess, updateSuccess, deleteSuccess, createError, updateError, deleteError, form]);

  const showModal = (template = null) => {
    setEditingTemplate(template);
    if (template) {
      form.setFieldsValue(template);
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingTemplate(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (editingTemplate) {
      dispatch(updateGenericTemplate(editingTemplate.id, values));
    } else {
      dispatch(createGenericTemplate(values));
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteGenericTemplate(id));
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    dispatch(listGenericTemplates(1, value));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => showModal(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this template?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2}>Generic Templates</Title>
      <Space style={{ marginBottom: 16 }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
          Add New Template
        </Button>
        <Search
          placeholder="Search templates"
          onSearch={handleSearch}
          style={{ width: 200 }}
          allowClear
        />
      </Space>
      <Table
        columns={columns}
        dataSource={genericTemplates}
        rowKey="id"
        loading={loading}
        pagination={{
          current: page,
          total: pages * 10, // Assuming 10 items per page
          onChange: (page) => dispatch(listGenericTemplates(page, searchTerm)),
        }}
      />
      <Modal
        title={editingTemplate ? 'Edit Template' : 'Create New Template'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="Template Name"
            rules={[{ required: true, message: 'Please provide the name of the template' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="htmlContent"
            label="HTML Content"
            rules={[{ required: true, message: 'Please provide the HTML content of the template' }]}
          >
            <TextArea rows={15} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingTemplate ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GenericTemplateManagementScreen;