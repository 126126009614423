export const REPORT_LIST_REQUEST = 'REPORT_LIST_REQUEST'
export const REPORT_LIST_SUCCESS = 'REPORT_LIST_SUCCESS'
export const REPORT_LIST_FAIL = 'REPORT_LIST_FAIL'


export const REPORT_DETAILS_REQUEST = 'REPORT_DETAILS_REQUEST'
export const REPORT_DETAILS_SUCCESS = 'REPORT_DETAILS_SUCCESS'
export const REPORT_DETAILS_FAIL = 'REPORT_DETAILS_FAIL'


export const REPORT_DELETE_REQUEST = 'REPORT_DELETE_REQUEST'
export const REPORT_DELETE_SUCCESS = 'REPORT_DELETE_SUCCESS'
export const REPORT_DELETE_FAIL = 'REPORT_DELETE_FAIL'

export const REPORT_CREATE_REQUEST = 'REPORT_CREATE_REQUEST'
export const REPORT_CREATE_SUCCESS = 'REPORT_CREATE_SUCCESS'
export const REPORT_CREATE_FAIL = 'REPORT_CREATE_FAIL'
export const REPORT_CREATE_RESET = 'REPORT_CREATE_RESET'

export const REPORT_UPDATE_REQUEST = 'REPORT_UPDATE_REQUEST'
export const REPORT_UPDATE_SUCCESS = 'REPORT_UPDATE_SUCCESS'
export const REPORT_UPDATE_FAIL = 'REPORT_UPDATE_FAIL'
export const REPORT_UPDATE_RESET = 'REPORT_UPDATE_RESET'

export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAIL = 'REPORT_FAIL'
export const REPORT_RESET = 'REPORT_RESET'

export const REPORT_COUNT_REQUEST = "REPORT_COUNT_REQUEST";
export const REPORT_COUNT_SUCCESS = "REPORT_COUNT_SUCCESS";
export const REPORT_COUNT_FAIL = "REPORT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const REPORT_DTO_REQUEST = 'REPORT_DTO_REQUEST';
export const REPORT_DTO_SUCCESS = 'REPORT_DTO_SUCCESS';
export const REPORT_DTO_FAIL = 'REPORT_DTO_FAIL';
export const REPORT_DTO_RESET = 'REPORT_DTO_RESET';

export const REPORT_LIST_BYIDS_REQUEST = 'REPORT_LIST_BYIDS_REQUEST';
export const REPORT_LIST_BYIDS_SUCCESS = 'REPORT_LIST_BYIDS_SUCCESS';
export const REPORT_LIST_BYIDS_FAIL = 'REPORT_LIST_BYIDS_FAIL';
export const REPORT_LIST_BYIDS_RESET = 'REPORT_LIST_BYIDS_RESET';