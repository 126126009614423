import {

    TEST_RESULT_REPORT_LIST_REQUEST,
    TEST_RESULT_REPORT_LIST_SUCCESS,
    TEST_RESULT_REPORT_LIST_FAIL,

    TEST_RESULT_REPORT_DETAILS_REQUEST,
    TEST_RESULT_REPORT_DETAILS_SUCCESS,
    TEST_RESULT_REPORT_DETAILS_FAIL,

    TEST_RESULT_REPORT_DELETE_REQUEST,
    TEST_RESULT_REPORT_DELETE_SUCCESS,
    TEST_RESULT_REPORT_DELETE_FAIL,

    TEST_RESULT_REPORT_CREATE_REQUEST,
    TEST_RESULT_REPORT_CREATE_SUCCESS,
    TEST_RESULT_REPORT_CREATE_FAIL,
    TEST_RESULT_REPORT_CREATE_RESET,

    TEST_RESULT_REPORT_UPDATE_REQUEST,
    TEST_RESULT_REPORT_UPDATE_SUCCESS,
    TEST_RESULT_REPORT_UPDATE_FAIL,
    TEST_RESULT_REPORT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_RESULT_REPORT_DTO_FAIL,
    TEST_RESULT_REPORT_DTO_REQUEST,
    TEST_RESULT_REPORT_DTO_SUCCESS,
    TEST_RESULT_REPORT_DTO_RESET,

    TEST_RESULT_REPORT_LIST_BYIDS_REQUEST,
    TEST_RESULT_REPORT_LIST_BYIDS_SUCCESS,
    TEST_RESULT_REPORT_LIST_BYIDS_FAIL,
    TEST_RESULT_REPORT_LIST_BYIDS_RESET,

    TEST_RESULT_REPORT_VALIDATION_UPDATE_REQUEST,
    TEST_RESULT_REPORT_VALIDATION_UPDATE_SUCCESS,
    TEST_RESULT_REPORT_VALIDATION_UPDATE_FAIL,
    TEST_RESULT_REPORT_VALIDATION_UPDATE_RESET

} from "../constants/testResultReportConstants";

const initialState = {
    testResultReports: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testResultReportListReducer = (
    state = {
        testResultReports: []
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_LIST_REQUEST:
            return { loading: true, testResultReports: [] };
        case TEST_RESULT_REPORT_LIST_SUCCESS:
            // our state will have a list of testResultReports after the request
            return {
                loading: false,
                testResultReports: action.payload.testResultReports,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                totalCount: action.payload.statistics.totalCount,
                statistics: action.payload.statistics
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_RESULT_REPORT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testResultReportListByIDsReducer = (
    state = {
        testResultReports: []
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_LIST_BYIDS_REQUEST:
            return { loading: true, testResultReports: [] };
        case TEST_RESULT_REPORT_LIST_BYIDS_SUCCESS:
            // our state will have a list of testResultReports after the request
            return { loading: false, testResultReports: action.payload };
        case TEST_RESULT_REPORT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_RESULT_REPORT_LIST_BYIDS_RESET:
            return { testResultReports: [] };
        default:
            return state;
    }
}


export const testResultReportDetailsReducer = (
    state = {
        testResultReport: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_RESULT_REPORT_DETAILS_SUCCESS:
            // our state will have a list of testResultReports after the request
            return { loading: false, testResultReport: action.payload };
        case TEST_RESULT_REPORT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testResultReportDtoReducer = (state = { testResultReportDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case TEST_RESULT_REPORT_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case TEST_RESULT_REPORT_DTO_SUCCESS:
        return { ...state, loading: false, testResultReportDto: action.payload };
      case TEST_RESULT_REPORT_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case TEST_RESULT_REPORT_DTO_RESET:
        return { testResultReportDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };

export const testResultReportValidationUpdateReducer = (state = { testResultReport: {}, loading: false, error: null }, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_VALIDATION_UPDATE_REQUEST:
            return { ...state, loading: true, error: null };
        case TEST_RESULT_REPORT_VALIDATION_UPDATE_SUCCESS:
            return { ...state, loading: false, testResultReport: action.payload };
        case TEST_RESULT_REPORT_VALIDATION_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case TEST_RESULT_REPORT_VALIDATION_UPDATE_RESET:
            return { testResultReport: {}, loading: false, error: null };
        default:
            return state;
        }

}

export const testResultReportDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_DELETE_REQUEST:
            return { loading: true }

        case TEST_RESULT_REPORT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_RESULT_REPORT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testResultReportCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_CREATE_REQUEST:
            return { loading: true }

        case TEST_RESULT_REPORT_CREATE_SUCCESS:
            return { loading: false, success: true, testResultReport: action.payload }

        case TEST_RESULT_REPORT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_RESULT_REPORT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testResultReportUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_RESULT_REPORT_UPDATE_REQUEST:
            return { loading: true }

        case TEST_RESULT_REPORT_UPDATE_SUCCESS:
            return { loading: false, success: true, testResultReport: action.payload }

        case TEST_RESULT_REPORT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_RESULT_REPORT_UPDATE_RESET:
            return { testResultReport: {} }

        default:
            return state
    }
}