import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { theme, Alert } from 'antd';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { ThemeContext } from '../../index';

const SupersetDashboard = ({ baseURL, dashboardId, username, password, first_name, last_name }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [guestToken, setGuestToken] = useState(null);
  const [error, setError] = useState(null); // State to track errors
  const { themeConfig } = useContext(ThemeContext);

  const { algorithm } = themeConfig;

  // Create axios instance using the baseURL prop
  const api = axios.create({
    baseURL,  // Using the prop `baseURL`
    withCredentials: true,  // Include cookies in requests
  });

  // Function to log in and get the access token
  const login = async () => {
    try {
      const response = await api.post('/api/v1/security/login', {
        username,  // Using the prop `username`
        password,  // Using the prop `password`
        provider: "db",
        refresh: true
      });
      return response.data.access_token;
    } catch (error) {
      console.error('Error during login:', error);
      setError('Unable to connect to the Superset API. Please contact the administrator.');
      return null;
    }
  };

  // Function to fetch the guest token
  const fetchGuestToken = async (token) => {
    try {
      const response = await api.post('/api/v1/security/guest_token/', {
        resources: [
          {
            id: dashboardId,  // Using the prop `dashboardId`
            type: "dashboard"
          }
        ],
        rls: [],
        user: {
          first_name: first_name,  // Using the prop `first_name`
          last_name: last_name,  // Using the prop `last_name`
          username: username  // Using the prop `username`
        }
      }, { headers: { 'Authorization': `Bearer ${token}` } });
      return response.data.token;
    } catch (error) {
      console.error('Error fetching guest token:', error);
      setError('Unable to connect to the Superset API. Please contact the administrator.');
      return null;
    }
  };

  // Initialize session and get the access token
  useEffect(() => {
    const initializeSession = async () => {
      const token = await login();
      if (token) {
        setAccessToken(token);
      }
    };

    initializeSession();
  }, []);

  // Fetch the guest token once the access token is available
  useEffect(() => {
    const getGuestToken = async () => {
      if (accessToken) {
        const token = await fetchGuestToken(accessToken);
        if (token) {
          setGuestToken(token);
        }
      }
    };

    getGuestToken();
  }, [accessToken]);

  // Embed the dashboard once the guest token is available
  useEffect(() => {
    const embedDashboardInstance = async () => {
      if (!guestToken) return;

      try {
        await embedDashboard({
          id: dashboardId,  // Using the prop `dashboardId`
          supersetDomain: baseURL,  // Using the prop `baseURL`
          mountPoint: document.getElementById('dashboard-container'),
          fetchGuestToken: () => guestToken,
          dashboardUiConfig: {
            filters: {
              expanded: false,
              visible: false
            },
            hideTitle: true,
            hideChartControls: true,
            hideTab: true,
          },
          cssTemplates: algorithm === theme.darkAlgorithm ? ['dark_template'] : undefined,
        });
      } catch (error) {
        console.error('Error embedding dashboard:', error);
        setError('Unable to connect to the Stat API. Please contact the administrator.');
      }
    };

    embedDashboardInstance();
  }, [guestToken, dashboardId, baseURL]);

  // If there's an error, display the error message
  if (error) {
    return (
      <Alert
      message="Feature Unavailable"
      description={error}
      type="warning"
      showIcon
      closable
      onClose={() => setError(null)}
    />

    );
  }

  // Render the dashboard container
  return (
    <div
      id="dashboard-container"
      className={`superset-dashboard ${algorithm === theme.darkAlgorithm ? 'dark-mode' : ''}`}
      style={{
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        position: 'relative',
        overflow: 'auto',
      }}
    />
  );
};

export default SupersetDashboard;
