export const NOTE_LIST_REQUEST = 'NOTE_LIST_REQUEST'
export const NOTE_LIST_SUCCESS = 'NOTE_LIST_SUCCESS'
export const NOTE_LIST_FAIL = 'NOTE_LIST_FAIL'


export const NOTE_DETAILS_REQUEST = 'NOTE_DETAILS_REQUEST'
export const NOTE_DETAILS_SUCCESS = 'NOTE_DETAILS_SUCCESS'
export const NOTE_DETAILS_FAIL = 'NOTE_DETAILS_FAIL'


export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST'
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS'
export const NOTE_DELETE_FAIL = 'NOTE_DELETE_FAIL'

export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST'
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS'
export const NOTE_CREATE_FAIL = 'NOTE_CREATE_FAIL'
export const NOTE_CREATE_RESET = 'NOTE_CREATE_RESET'

export const NOTE_UPDATE_REQUEST = 'NOTE_UPDATE_REQUEST'
export const NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS'
export const NOTE_UPDATE_FAIL = 'NOTE_UPDATE_FAIL'
export const NOTE_UPDATE_RESET = 'NOTE_UPDATE_RESET'

export const NOTE_REQUEST = 'NOTE_REQUEST'
export const NOTE_SUCCESS = 'NOTE_SUCCESS'
export const NOTE_FAIL = 'NOTE_FAIL'
export const NOTE_RESET = 'NOTE_RESET'

export const NOTE_COUNT_REQUEST = "NOTE_COUNT_REQUEST";
export const NOTE_COUNT_SUCCESS = "NOTE_COUNT_SUCCESS";
export const NOTE_COUNT_FAIL = "NOTE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const NOTE_DTO_REQUEST = 'NOTE_DTO_REQUEST';
export const NOTE_DTO_SUCCESS = 'NOTE_DTO_SUCCESS';
export const NOTE_DTO_FAIL = 'NOTE_DTO_FAIL';
export const NOTE_DTO_RESET = 'NOTE_DTO_RESET';

export const NOTE_LIST_BYIDS_REQUEST = 'NOTE_LIST_BYIDS_REQUEST';
export const NOTE_LIST_BYIDS_SUCCESS = 'NOTE_LIST_BYIDS_SUCCESS';
export const NOTE_LIST_BYIDS_FAIL = 'NOTE_LIST_BYIDS_FAIL';
export const NOTE_LIST_BYIDS_RESET = 'NOTE_LIST_BYIDS_RESET';