export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

// create 
export const IDENTITY_USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const IDENTITY_USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const IDENTITY_USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const IDENTITY_USER_CREATE_RESET = 'USER_CREATE_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const IDENTITY_USER_DETAILS_REQUEST = 'IDENTITY_USER_DETAILS_REQUEST'
export const IDENTITY_USER_DETAILS_SUCCESS = 'IDENTITY_USER_DETAILS_SUCCESS'
export const IDENTITY_USER_DETAILS_FAIL = 'IDENTITY_USER_DETAILS_FAIL'
export const IDENTITY_USER_DETAILS_RESET = 'IDENTITY_USER_DETAILS_RESET'

export const IDENTITY_USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const IDENTITY_USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const IDENTITY_USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const IDENTITY_USER_UPDATE_RESET = 'USER_UPDATE_RESET'


export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL'
export const LOGOUT = 'LOGOUT'

export const AUTHENTICATED_REQUEST = 'AUTHENTICATED_REQUEST'
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST'
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS'
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL'
export const UPDATE_USER_PASSWORD_RESET = 'UPDATE_USER_PASSWORD_FAIL'

// send verification code
export const SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST'
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_FAIL = 'SEND_VERIFICATION_CODE_FAIL'
export const SEND_VERIFICATION_CODE_RESET = 'SEND_VERIFICATION_CODE_RESET'

// verify code
export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST'
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS'
export const VERIFY_CODE_FAIL = 'VERIFY_CODE_FAIL'
export const VERIFY_CODE_RESET = 'VERIFY_CODE_RESET'

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

// Policies
export const GET_POLICIES_REQUEST = 'GET_POLICIES_REQUEST'
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS'
export const GET_POLICIES_FAIL = 'GET_POLICIES_FAIL'

// Roles
export const GET_ALL_ROLES_REQUEST = 'GET_ALL_ROLES_REQUEST'
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS'
export const GET_ALL_ROLES_FAIL = 'GET_ALL_ROLES_FAIL'

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL'

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'

// Role Policies
export const UPDATE_ROLE_POLICIES_REQUEST = 'UPDATE_ROLE_POLICIES_REQUEST'
export const UPDATE_ROLE_POLICIES_SUCCESS = 'UPDATE_ROLE_POLICIES_SUCCESS'
export const UPDATE_ROLE_POLICIES_FAIL = 'UPDATE_ROLE_POLICIES_FAIL'

export const GET_ROLE_POLICIES_REQUEST = 'GET_ROLE_POLICIES_REQUEST'
export const GET_ROLE_POLICIES_SUCCESS = 'GET_ROLE_POLICIES_SUCCESS'
export const GET_ROLE_POLICIES_FAIL = 'GET_ROLE_POLICIES_FAIL'
