import React from 'react';
import { Input, Select, Button, DatePicker, Tag, Space, Switch, Card } from 'antd';
import { FileSearchOutlined, EyeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { convertKeyToCamelCase } from '../../../utils/functions';
import { fieldEntityActionConfig } from './fieldEntityActionConfig';
import SingleEntitySelect from '../SingleEntitySelect.component';
import RandomBarcode from '../../barcode/RandomBarcode.component';
import EntityNumberInput from '../EntityNumberInput.component';
import EntityCodeInput from '../EntityCodeInput.component';
import FileUploaderAndViewer from '../../uploader/FileUploader.component';
import QCFrequencyInput from '../../qc/QCFrequencyInput.component';
import DatetimeInput from '../../datetime/DateTimeInput.component';

import { getTranslatedOptions } from './fieldOptions';
import FormulaBuilder from '../../calculationFormula/FormulaBuilder.component';

// Helper function to render fields
export const renderField = (fieldName, fieldProps, initialDto, handleInputChange, handleBrowseEntity, userInfo) => {

    const {
        statusOptions,
        bloodTypeOptions,
        genderOptions,
        encounterTypeOptions,
        orderTypeOptions,
        sampleTypeOptions,
        containerTypeOptions,
        qcResultStatusOptions,
        subscriptionPackageOptions
    } = getTranslatedOptions();


    const fieldType = fieldProps.type;
    const isReadOnly = fieldProps.isReadOnly;
    const value = initialDto[convertKeyToCamelCase(fieldName)];

    switch (fieldType) {
        case 'String':
            if (isReadOnly) {
                return <span>{value || '-'}</span>;
            }
            switch (fieldName) {
                case 'Password':
                    return <Input.Password placeholder={`Enter ${fieldName}`} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />;
                case 'Email':
                    return <Input placeholder={`Enter ${fieldName}`} type="email" onBlur={(e) => handleInputChange(fieldName, e.target.value)} />;
                case 'Notes':
                case 'Comments':
                case 'Description':
                case 'ReportData':
                case 'StreetAddress':
                case 'Method':
                case 'Interpretation':
                case 'AdditionalDemographicInformation':
                case 'AdditionalClinicalInformation':
                case 'ConsentScope':
                case 'ConsentPurpose':
                case 'ConsentAdditionalInfo':
                case 'ReportConclusion':
                case 'SampleDescription':
                case 'CollectionMethod':
                case 'StorageConditions':
                case 'HandlingInstructions':
                case 'History':
                case 'ConservationMethod':
                case 'QCNotes':

                    return <Input.TextArea style={{ minHeight: '100px' }} placeholder={`Enter ${fieldName}`} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />;
                case 'Status':
                case 'PaymentStatus':
                case 'CurrentStatus':
                case 'InvoiceStatus':
                case 'QCScheduleStatus':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                            defaultValue={statusOptions[0].value}
                        >
                            {statusOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                case 'QCResultStatus':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                            defaultValue={qcResultStatusOptions[0].value}
                        >
                            {qcResultStatusOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                case 'QCFrequency':
                    const frequencyConfig = initialDto.qcFrequency || {};
                    return (
                        <QCFrequencyInput
                            inputValue={initialDto.qcFrequency}
                            onChange={(e) => handleInputChange(fieldName, e.target.value)}
                        />
                    );

                case 'Barcode':
                    return (
                        <RandomBarcode
                            startsWith={"120"}
                            length={12}
                            format={"CODE128"}
                            width={2}
                            height={50}
                            fontSize={12}
                            inputValue={initialDto.barcode}
                            onValueChange={(e) => handleInputChange(fieldName, e.target.value)}
                        />);
                case 'OrderNumber':
                    return (
                        <EntityNumberInput
                            inputValue={initialDto.orderNumber}
                            prefix={"ORD"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }}
                        />);


                case 'OrderCode':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.orderCode}
                            prefix={"ORD"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);
                case 'ReferenceCode':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.referenceCode}
                            prefix={"REF"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);
                case 'SampleCode':
                    return (
                        <EntityCodeInput

                            inputValue={initialDto.sampleCode}
                            prefix={"SMP"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);

                case 'LotCode':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.lotCode}
                            prefix={"LOT"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);
                case 'InventoryCode':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.inventoryCode}
                            prefix={"INV"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);

                case 'Identifier':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.identifier}
                            prefix={"ORG"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);

                case 'ReferenceNumber':
                    return (
                        <EntityCodeInput
                            inputValue={initialDto.referenceNumber}
                            prefix={"REF"}
                            onValueChange={(e) => {
                                handleInputChange(fieldName, e.target.value);
                            }
                            }
                        />);

                case 'SubscriptionPackage':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                        >
                            {subscriptionPackageOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );


                case 'BloodType':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                        >
                            {bloodTypeOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );

                case 'SampleType':
                    return (
                        <Select

                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                        >
                            {sampleTypeOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );

                case 'EncounterType':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                        >
                            {encounterTypeOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                case 'ContainerType':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                        >
                            {containerTypeOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );

                case 'OrderType':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                            defaultValue={orderTypeOptions[0].value}
                        >
                            {orderTypeOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                case 'Gender':
                    return (
                        <Select
                            placeholder={`Select ${fieldName}`}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            value={value}
                            defaultValue={genderOptions[0].value}
                        >
                            {genderOptions?.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                case 'Documents':
                    return (
                        <FileUploaderAndViewer
                            value={initialDto.documents || "[]"}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            category="documents"
                            ownerId={initialDto.id}
                            ownerName={`${initialDto.forename || ''} ${initialDto.surname || ''}`.trim() || 'unknown'}
                            bucketName={userInfo.businessOrganisationId ? userInfo.businessOrganisationId : null}
                        />

                    );

                case 'QCRuleDocuments':
                    return (
                        <FileUploaderAndViewer
                            value={initialDto.qcRuleDocuments || "[]"}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            category="qc_documents"
                            ownerId={initialDto.id}
                            ownerName={`${initialDto.name || ''}`.trim() || 'unknown'}
                            bucketName={userInfo.businessOrganisationId ? userInfo.businessOrganisationId : null}
                        />

                    );
                case 'QCResultDocuments':
                    return (
                        <FileUploaderAndViewer
                            value={initialDto.qcResultDocuments || "[]"}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            category="qc_result_documents"
                            ownerId={initialDto.id}
                            ownerName={`${initialDto.name || ''}`.trim() || 'unknown'}
                            bucketName={userInfo.businessOrganisationId ? userInfo.businessOrganisationId : null}
                        />

                    );

                case 'QCReportDocuments':
                    return (
                        <FileUploaderAndViewer
                            value={initialDto.qcReportDocuments || "[]"}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            category="qc_report_documents"
                            ownerId={initialDto.id}
                            ownerName={`${initialDto.name || ''}`.trim() || 'unknown'}
                            bucketName={userInfo.businessOrganisationId ? userInfo.businessOrganisationId : null}
                        />

                    );

                case 'ConsentDocument':
                    return (
                        <FileUploaderAndViewer
                            value={initialDto.consentDocument || "[]"}
                            onChange={(value) => handleInputChange(fieldName, value)}
                            category="consent_documents"
                            ownerId={initialDto.id}
                            ownerName={`${initialDto.name || ''}`.trim() || 'unknown'}
                            bucketName={userInfo.businessOrganisationId ? userInfo.businessOrganisationId : null}
                        />

                    );

                case 'Alert':
                    return (
                        <Tag color={value} >{value}</Tag>
                    )
                case 'CalculationFormula':
                    return (
                        <FormulaBuilder
                            inputValue={initialDto.calculationFormula}
                            onValueChange={(value) => {
                                handleInputChange(fieldName, value)
                            }}
                        />
                    )


                default:
                    return <Input placeholder={`Enter ${fieldName}`} value={value} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />;
            }
        case 'List`1':
            return (
                <Space>
                    <Button type="primary" size='small' icon={<FileSearchOutlined />} onClick={() => handleBrowseEntity(fieldEntityActionConfig[fieldName])}>Browse</Button>
                    {value && value.length > 0 ? `${value.length} item(s) selected` : '-'}
                </Space>
            );
        case 'Decimal':
            switch (fieldName) {
                case 'ExpectedValue':
                case 'StandardDeviation':
                case 'AcceptableLimitLow':
                case 'AcceptableLimitHigh':
                case 'WarningLimitLow':
                case 'WarningLimitHigh':
                    return (
                        <Input placeholder={`Enter ${fieldName}`} value={value} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />
                    );

                case 'DefaultRate':
                case 'PercentageIncrease':
                case 'Tax':
                case 'Rate':
                    return (
                        <Input suffix='%' placeholder={`Enter ${fieldName}`} value={value} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />
                    );

                default:
                    const currencySymbol = userInfo?.currencySymbol || '£';
                    const currencyCode = userInfo?.currencyCode || 'GBP';
                    return (
                        <Input prefix={currencySymbol} suffix={currencyCode} placeholder={`Enter ${fieldName}`} value={value} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />
                    );
            }
            const currencySymbol = userInfo.currencySymbol || '£';
            const currencyCode = userInfo.currencyCode || 'GBP';
            return (
                <Input prefix={currencySymbol} suffix={currencyCode} placeholder={`Enter ${fieldName}`} value={value} onBlur={(e) => handleInputChange(fieldName, e.target.value)} />
            );
        case 'Int32':
            return (
                <Input type="number" value={value} placeholder={`Enter ${fieldName}`} onBlur={(e) => handleInputChange(fieldName, parseInt(e.target.value))} />
            );
        case 'Guid':
            if (isReadOnly) {
                return <Tag color='default'>{value || '-'}</Tag>;
            }
            switch (fieldName) {
                case 'TestInventoryLineId':
                case 'TestOrderId':
                case 'TestOrderBulkRequestId':
                    return <Tag color='default'>This field can't be edited here</Tag>;
                case 'TestResultReportId':
                case 'InvoiceReportId':
                    return (
                        <Space>
                            <Button type="primary" size='small' icon={<FileSearchOutlined />} onClick={() => handleBrowseEntity(fieldEntityActionConfig[fieldName])}>View</Button>
                        </Space>
                    );
                case 'IdentityUserId':
                    return (
                        fieldEntityActionConfig[fieldName] && (
                            <SingleEntitySelect
                                singularEntity={fieldEntityActionConfig[fieldName].singularEntity}
                                pluralEntity={fieldEntityActionConfig[fieldName].pluralEntity}
                                constantPrefix={fieldEntityActionConfig[fieldName].constantPrefix}
                                listEntities={fieldEntityActionConfig[fieldName].listEntities}
                                onChange={(value) => handleInputChange(fieldName, value)}
                                value={value}
                                primarySearchProp={fieldEntityActionConfig[fieldName].primarySearchProp}
                                secondarySearchProp={fieldEntityActionConfig[fieldName].secondarySearchProp}
                                enableCreate={false}
                            />
                        )
                    );
                default:
                    return (
                        fieldEntityActionConfig[fieldName] && (
                            <SingleEntitySelect
                                singularEntity={fieldEntityActionConfig[fieldName].singularEntity}
                                pluralEntity={fieldEntityActionConfig[fieldName].pluralEntity}
                                constantPrefix={fieldEntityActionConfig[fieldName].constantPrefix}
                                listEntities={fieldEntityActionConfig[fieldName].listEntities}
                                onChange={(value) => handleInputChange(fieldName, value)}
                                value={value}
                                primarySearchProp={fieldEntityActionConfig[fieldName].primarySearchProp}
                                secondarySearchProp={fieldEntityActionConfig[fieldName].secondarySearchProp}
                            />
                        )

                    );
            }
        case 'Boolean':
            switch (fieldName) {
                case 'ValidationStatus':
                    return (
                        <Tag
                            color={value ? 'success' : 'error'}
                            icon={value ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                        >
                            {value ? 'Valid' : 'Invalid'}

                        </Tag>
                    );
                default:
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={(checked) => handleInputChange(fieldName, checked)}
                        />
                    );
            }
        case 'DateTime':
            switch (fieldName) {
                case 'CompletedDate':
                case 'UpdatedAt':
                case 'CreatedAt':
                    return <Tag>{value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-'}</Tag>;
                default:
                    return (
                        <DatetimeInput
                            fieldName={fieldName}
                            value={value}
                            handleInputChange={handleInputChange}
                        />
                    );
            }

        default:
            return <span>{value || '-'}</span>;

    }



};

