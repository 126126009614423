import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ConfigProvider, theme } from "antd";
import enGB from 'antd/locale/en_GB';
import frFR from 'antd/locale/fr_FR';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CookiesProvider, useCookies } from 'react-cookie';
import App from "./App.jsx";

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./local/en/translation.json')
      },
      fr: {
        translation: require('./local/fr/translation.json')
      }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const ThemeContext = React.createContext();

const ThemeProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['language', 'theme']);
  const [initialized, setInitialized] = useState(false);

  const [themeConfig, setThemeConfig] = useState(() => ({
    algorithm: cookies.theme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      colorPrimary: '#42836a',
      colorSecondary: '#6c757d',
      colorPrimaryBg: cookies.theme === 'dark' ? '#343a40' : '#f8f9fa',
      colorLink: '#42836a',
    },
    locale: cookies.language === 'fr' ? frFR : enGB,
  }));

  useEffect(() => {
    if (!initialized) {
      // Set initial language for i18next
      const savedLanguage = cookies.language || 'en';
      i18n.changeLanguage(savedLanguage);
      setThemeConfig(prev => ({
        ...prev,
        locale: savedLanguage === 'fr' ? frFR : enGB,
      }));
      setInitialized(true);
    }
  }, [cookies.language, initialized]);

  useEffect(() => {
    // Save theme preference to cookie
    setCookie('theme', themeConfig.algorithm === theme.darkAlgorithm ? 'dark' : 'light', { path: '/', maxAge: 31536000 }); // maxAge: 1 year
    // Save language preference to cookie
    setCookie('language', themeConfig.locale === frFR ? 'fr' : 'en', { path: '/', maxAge: 31536000 });
  }, [themeConfig, setCookie]);

  const toggleTheme = () => {
    setThemeConfig((prevConfig) => ({
      ...prevConfig,
      algorithm:
        prevConfig.algorithm === theme.darkAlgorithm
          ? theme.defaultAlgorithm
          : theme.darkAlgorithm,
      token: {
        ...prevConfig.token,
        colorPrimaryBg:
          prevConfig.algorithm === theme.darkAlgorithm ? '#f8f9fa' : '#343a40',
      },
    }));
  };

  const changeLocale = (locale) => {
    i18n.changeLanguage(locale); // Change i18next language
    setThemeConfig((prevConfig) => ({
      ...prevConfig,
      locale: locale === 'en' ? enGB : frFR,
    }));
  };

  return (
    <ThemeContext.Provider value={{ themeConfig, toggleTheme, changeLocale }}>
      <ConfigProvider theme={themeConfig} locale={themeConfig.locale}>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

export { ThemeContext };