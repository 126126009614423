import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined, EditOutlined, DeleteOutlined, FormOutlined, HistoryOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Pagination, Tag, Popconfirm, Select, DatePicker, Card, message, Affix, Divider, Tooltip, Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { convertKeyToCamelCase } from '../../utils/functions';
import { getTranslatedFilters } from './utils/fieldFilters';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import TableTitle from './common/TableTitle';

const { RangePicker } = DatePicker;

const ListItemsDataTable = ({
  dtoConfig,
  data,
  filter,
  onFilterChange,
  currentPage,
  totalPages,
  limit = 10,
  loading,
  handleDeleteRow,
  handleSaveRow,
  onStartEditRow,
  onDataUpdated,
  handleEditForm,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingRowId, setEditingRowId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [searchProp, setSearchProp] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  // current view width
  const [viewWidth, setViewWidth] = useState(window.innerWidth);

  const searchInput = useRef(null);


  const { statusFilters, alertFilters, valueFilters, emailConfirmedFilters, genderFilters, roleFilters } = getTranslatedFilters();

  useEffect(() => {
    setSearchText(filter.query || '');
    setSearchedColumn(filter.filter || '');
    setSearchProp(filter.filter || '');
    setDateRange(filter.startDate && filter.endDate ? [dayjs(filter.startDate), dayjs(filter.endDate)] : []);
  }, [filter]);

  useEffect(() => {
    if (!dtoConfig) {
      return;
    }
    const renderedColumns = renderColumns(dtoConfig, handleDeleteRow, handleEditForm)?.map((column) => {
      if (column.filtered) {
        return {
          ...column,
          ...getColumnSearchProps(column.dataIndex),
        };
      }
      return column;
    });
    setColumns(renderedColumns);
  }, [dtoConfig, selectedRowKeys]);

  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleBulkDelete = async () => {
    setIsBulkDeleting(true);
    for (const key of selectedRowKeys) {
      try {
        await handleDeleteRow(key);
        await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay
      } catch (error) {
        console.error(`Failed to delete item with id ${key}:`, error);
        message.error(t('Failed to delete some items. Please try again.'));
        break;
      }
    }
    setSelectedRowKeys([]);
    setIsBulkDeleting(false);
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      query: selectedKeys[0],
      filter: dataIndex,
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSearchPropChange = (value) => {
    setSearchProp(value || '');
    onFilterChange({ ...filter, filter: value || '' });
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();
      setDateRange(dates);
      onFilterChange({ ...filter, startDate, endDate });
    } else {
      setDateRange([]);
      onFilterChange({ ...filter, startDate: null, endDate: null });
    }
  };


  const renderColumns = (dtoConfig, handleDeleteRow, handleEditForm) => {
    let columnsConfig = [];

    columnsConfig.push({
      title: 'Actions',
      filtered: false,
      fixed: 'left',
      width: 150,
      render: (text, record) => (
        <Space>
          <Button
            type="text"
            icon={<FormOutlined />}
            onClick={() => handleEditForm(record.id)}
          >
            {t("View")}
          </Button>
          <Popconfirm
            title={t("Delete item")}
            description={t("Are you sure you want to delete this item?")}
            okText={t("Yes")}
            cancelText={t("No")}
            onConfirm={() => handleDeleteRow(record.id)}
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
        </Space>
      ),
    });

    // Add other columns
    Object.keys(dtoConfig).forEach((key) => {
      if (dtoConfig[key].isSummaryProp) {
        let filters = [];
        let render = (text, record) => <span>{text}</span>;
        let onFilter = null;
        let sorter = null;
        let filterMode = null;
        let filterSearch = false;

        // Add logic for non-editable columns
        switch (key) {
          case 'Result':
          case 'Order':
            filters = [
              { text: 'A', value: 'A' },
            ];
            filterSearch = true;
            filterMode = 'menu';
            render = (text, record) => (
              editingRow === record.id ? (
                <Input
                  value={record[convertKeyToCamelCase(key)]}
                  onChange={(e) => {
                    onDataUpdated({
                      ...record,
                      [convertKeyToCamelCase(key)]: e.target.value,
                    });
                  }}
                />
              ) : (
                <strong>{record[convertKeyToCamelCase(key)]}</strong>
              )
            );
            onFilter = (value, record) =>
              record[convertKeyToCamelCase(key)].toLowerCase().includes(value.toLowerCase());
            sorter = (a, b) => a[convertKeyToCamelCase(key)].localeCompare(b[convertKeyToCamelCase(key)]);
            break;
          case 'Alert':
            filters = alertFilters;   
            render = (text, record) => (
              <Tag
                color={record[convertKeyToCamelCase(key)].toString().toLowerCase()}
              >
                {record[convertKeyToCamelCase(key)]}
              </Tag>
            );
          break;


          case 'Status':
          case 'QCScheduleStatus':
          case 'ValidationStatus':
          case 'QCResultStatus':
            filters = statusFilters;
            render = (text, record) => (
              <Tag
                color={
                  record[convertKeyToCamelCase(key)] === 'Active' || record[convertKeyToCamelCase(key)] === true
                    ? 'purple'
                    : record[convertKeyToCamelCase(key)] === 'Inactive'
                      ? 'red'
                      : record[convertKeyToCamelCase(key)] === 'Pending'
                        ? 'blue'
                        : record[convertKeyToCamelCase(key)] === 'Passed' || record[convertKeyToCamelCase(key)] === 'Approved' || record[convertKeyToCamelCase(key)] === 'Completed'
                          ? 'green'
                          : 'orange'
                }
              >
                {record[convertKeyToCamelCase(key)]?.toString().toUpperCase() || "-"}
              </Tag>
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'Gender':
            filters = genderFilters;
            render = (text, record) => <strong>{record[convertKeyToCamelCase(key)]}</strong>;
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'EmailConfirmed':
          case 'IsActive':
            filters = emailConfirmedFilters;
            render = (text, record) => (
              <Tag color={record[convertKeyToCamelCase(key)] ? 'green' : 'red'}>
                {record[convertKeyToCamelCase(key)] ? t('Yes') : t('No')}
              </Tag>
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'Roles':
            filters = roleFilters;
            render = (text, record) => (
              record[convertKeyToCamelCase(key)]?.map((role) => (
                <Tag key={role} color="blue">
                  {role}
                </Tag>
              ))
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)].includes(value);
            break;
          default:
            filters = [];
            render = (text, record) => <span>{text}</span>;
            onFilter = null;
        }

        columnsConfig.push({
          title: (
            <Tooltip title={key}>
              <span>{key}</span>
            </Tooltip>
          ),
          dataIndex: convertKeyToCamelCase(key),
          key: convertKeyToCamelCase(key),
          filtered: filters.length > 0 ? false : true,
          filters: filters,
          render: render,
          onFilter: onFilter,
          sorter: sorter,
          filterMode: filterMode,
          filterSearch: filterSearch,
          ellipsis: true, // Enable ellipsis for long content
          resizable: true, // Enable column resizing
          width: 100,
        });
      }
    });

    return columnsConfig;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`${t("Search by")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const tableTitle = () => (
    <TableTitle
      dtoConfig={dtoConfig}
      searchProp={searchProp}
      handleSearchPropChange={handleSearchPropChange}
      searchText={searchText}
      setSearchText={setSearchText}
      onFilterChange={onFilterChange}
      filter={filter}
      dateRange={dateRange}
      handleDateRangeChange={handleDateRangeChange}
      limit={limit}
      setSearchedColumn={setSearchedColumn}
      setSearchProp={setSearchProp}
      setDateRange={setDateRange}
      selectedRowKeys={selectedRowKeys}
      handleBulkDelete={handleBulkDelete}
      isBulkDeleting={isBulkDeleting}
      t={t}
    />
  );  

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys,
        onChange: onSelectChange,
      }}
      columns={columns}
      dataSource={data}
      loading={loading}
      scroll={{ x: true }}
      scrollToFirstRowOnChange
      sticky
      style={{ marginTop: 20}}
      tableLayout='fixed'
      pagination={{
        current: currentPage,
        pageSize: limit,
        total: totalPages * limit,
        showSizeChanger: false,
        onChange: (page, pageSize) => {
          onFilterChange({ ...filter, page, limit: pageSize });
        },
      }}
      rowKey="id"
      title={dtoConfig? tableTitle : null}
    />
  );
};

export default ListItemsDataTable;

