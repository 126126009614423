// LookupFormView component
import React, { useState, useEffect } from 'react';
import { Form, Alert, Flex, Select, Button, Card, Popconfirm, Tooltip, Space } from 'antd';
import { ReloadOutlined, TableOutlined, AppstoreOutlined, DeleteOutlined } from '@ant-design/icons';
import { beautifyKey } from '../../../utils/functions';
import SelectItemsDataTable from '../SelectItemsDataTable.component';
import CustomAutoComplete from './CustomAutoComplete';
// translation 
import { useTranslation } from 'react-i18next';

const LookupForm = ({
  editMode,
  entities,
  // selectedEntities contains the selected entities with full props and details
  selectedEntities,
  setSelectedEntities,
  onSearch,
  oneToOneView,
  viewMode,
  setViewMode,
  handleEdit,
  handleDelete,
  handleReloadEntities,
  loadingList,
  loadingListByIDs,
  loadingDetails,
  loadingCreate,
  loadingUpdate,
  entityDtoConfig,
  entityListFilter,
  limit,
  currentPage,
  totalPages,
  onFilterChange,
  updatedEntity,
  dispatch,
  getEntityDetails,
  updateEntity,
  onDataUpdated,
  errorUpdate,
  primarySearchProp,
  secondarySearchProp,
  groupSearchProp,
  singularEntity,
  pluralEntity,
  summaryProps,

  // enable buttons
  enableSearchBar,
  enableActionButtons,
}) => {

  const { t } = useTranslation(); // Initialize the translation hook

  const [tableData, setTableData] = useState(selectedEntities);
  const [selectedFilter, setSelectedFilter] = useState(primarySearchProp);

  useEffect(() => {
    setTableData(selectedEntities);
  }, [selectedEntities]);


  const handleDataUpdated = (updatedData) => {
    // Update the table data with the updated record
    const updatedTableData = tableData?.map((record) =>
      record.id === updatedData.id ? updatedData : record
    );
    setTableData(updatedTableData);

    // Pass the updated data back to the parent component
    onDataUpdated(updatedData);
  };


  const handleFilterChange = (filter) => {
    onFilterChange({ ...filter, page: 1 });
  };

  return (
    <Form name='lookupForm' layout='vertical' size='large' hidden={editMode !== "Lookup"} scrollToFirstError>
      {/* Search and filter */}
      <Flex gap="left" vertical>
        {(enableSearchBar === true) && (
          <Flex
            align="middle"
            wrap
            style={{ width: '100%' }}
          >
            <div style={{ flex: 1, marginRight: 16, marginBottom: 16  }}>
              <CustomAutoComplete
                entities={entities}
                selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities}
                selectedFilter={selectedFilter}
                oneToOneView={oneToOneView}
                pluralEntity={pluralEntity}
                onSearch={onSearch}
                primarySearchProp={primarySearchProp}
                secondarySearchProp={secondarySearchProp}
                groupSearchProp={groupSearchProp}
                onFilterChange={handleFilterChange}
                currentPage={currentPage}
                totalPages={totalPages}
                limit={limit}
              />
            </div>
            <div style={{ marginRight: 16}}>
              Filter: {"  "}
              <Select
                placeholder={t("Select a filter")}
                value={selectedFilter}
                style={{ minWidth: 100 }}
                onChange={(value) => {
                  setSelectedFilter(value);
                  handleFilterChange({ query: entityListFilter.query, filter: value, page: currentPage, limit: limit });
                }}
              >
                {Object.entries(entityDtoConfig)?.map(([fieldName, fieldProps]) => (
                  fieldProps.isSummaryProp ? <Select.Option key={fieldName} value={fieldName}>{fieldName}</Select.Option> : null
                ))}
              </Select>
            </div>
            <div>
              <Button type="text" onClick={() => setSelectedEntities([])} icon={<DeleteOutlined />} />
            </div>
          </Flex>
        )}
        {/* View mode buttons */}
        <Flex justify="end">
          <Tooltip title={t("Table View")}>
            <Button
              type={viewMode === 'table' ? 'primary' : 'default'}
              size="small"
              icon={<TableOutlined />}
              onClick={() => setViewMode('table')}
            />
          </Tooltip>
          <Tooltip title={t("Card View")}>
            <Button

              type={viewMode === 'cards' ? 'primary' : 'default'}
              size="small"
              icon={<AppstoreOutlined />}
              onClick={() => setViewMode('cards')}
              style={{ marginLeft: '8px' }}
            />
          </Tooltip>
          <Tooltip title={t("Refresh")}>
            <Button
              type="default"
              size="small"
              icon={<ReloadOutlined />}
              onClick={handleReloadEntities}
              style={{ marginLeft: '8px' }}
            />
          </Tooltip>

        </Flex>
        {/* Selected items view */}
        {selectedEntities && (
          viewMode === 'cards' ? (
            selectedEntities?.map((entity, index) => (
              <Card hoverable size='small' key={index}
                actions={
                  enableActionButtons ?
                    [
                      <Button
                        type="link"
                        onClick={() => handleEdit(entity.id)}
                      >
                        View/Edit
                      </Button>,
                      <Popconfirm
                        title="Delete"
                        description={t("Are you sure you want to delete this item?")}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => { handleDelete(entity.id) }}
                      >
                        <Button
                          type='link'
                          danger
                        >
                          Remove from list
                        </Button>
                      </Popconfirm>
                    ] : null}
              >
                {/* Display summary props */}
                {
                  Object.entries(entity)?.map(([key, value]) => (
                    summaryProps?.includes(key) ?
                      <p key={key}><strong>{beautifyKey(key)}:</strong> {value}</p>
                      : null
                  ))
                }
              </Card>
            ))
          ) : (

            <SelectItemsDataTable
              key={selectedEntities}
              loading={loadingList || loadingListByIDs || loadingDetails || loadingCreate || loadingUpdate}
              dtoConfig={entityDtoConfig}
              data={selectedEntities}
              filter={entityListFilter}
              onFilterChange={handleFilterChange}
              currentPage={currentPage}
              totalPages={totalPages}
              limit={limit}
              handleDeleteRow={(id) => { handleDelete(id) }}
              onStartEditRow={(id) => { dispatch(getEntityDetails(id)) }}
              handleSaveRow={(record) => {
                // Load the entity details first then update it 
                const _updatedEntity = { ...updatedEntity, ...record };
                dispatch(updateEntity(record.id, _updatedEntity));
              }}
              onDataUpdated={handleDataUpdated}
              handleEditForm={(id) => { handleEdit(id) }}
              setData={setSelectedEntities}
              enableActionButtons={enableActionButtons}
            />
          )
        )}
        {errorUpdate && (
          <Alert
            message={t("Validation Error")}
            description={
              errorUpdate?.map((error, index) => (
                <p key={index}>{error}</p>
              ))
            }
            type="error"
            closable
            showIcon
          />
        )}
      </Flex>
    </Form>
  );
};

export default LookupForm;