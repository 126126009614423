import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Alert, message, Space } from 'antd';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import { convertKeyToCamelCase, beautifyKey } from '../../../utils/functions';
import { renderField } from '../utils/renderUIComponents';

const CreateForm = ({
  newEntity,
  loadingCreate,
  handleSubmitNewEntity,
  errorCreate,
  singularEntity,
  entityDtoConfig,
  handleNewEntityInputChange,
  handleBrowseEntity,
  fieldsToHide,
  newForm
}) => {
  const { t } = useTranslation(); // Initialize the translation hook

  const userInfo = useSelector(state => state.userLogin.userInfo);

  return (
    <Form
      form={newForm}
      name='newForm'
      layout='horizontal'
      labelCol={{ span: 10 }}
      size='large'
      disabled={loadingCreate || newEntity === null}
      onFinish={handleSubmitNewEntity}
      onFinishFailed={() => {
        message.error(t('Please fill in all required fields')); // Use the translation function
      }}
    >
      <h3>{t('New')} {t(singularEntity)}</h3>
      {/* Display error message on errorCreate */}
      {errorCreate && errorCreate.length > 0 && (
        <div>
        <Alert
          message={t('Validation Error')}
          description={errorCreate?.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
          type="error"
          closable
          showIcon
        />
        <br />
        </div>
      )}
      {/* Render form fields */}
      {Object.entries(entityDtoConfig)?.map(([fieldName, fieldProps]) => (
        // skip hidden fields from rendering
        fieldsToHide?.includes(fieldName) ? null :
        <Form.Item
          key={fieldName}
          label={t(beautifyKey(fieldName))} // Translate the label
          name={fieldProps.type !== 'DateTime' ? convertKeyToCamelCase(fieldName) : undefined}
          rules={[{ required: fieldProps.isRequired, message: `${t(fieldProps.errorMessage)}` }]} // Translate the error message
        >
          {renderField(fieldName, fieldProps, newEntity, handleNewEntityInputChange, handleBrowseEntity, userInfo)}
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loadingCreate}>
          {t('Submit')} {/* Translate the button label */}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
