// constants/sampleConstants.js

export const SAMPLE_LIST_REQUEST = 'SAMPLE_LIST_REQUEST';
export const SAMPLE_LIST_SUCCESS = 'SAMPLE_LIST_SUCCESS';
export const SAMPLE_LIST_FAIL = 'SAMPLE_LIST_FAIL';

export const SAMPLE_DETAILS_REQUEST = 'SAMPLE_DETAILS_REQUEST';
export const SAMPLE_DETAILS_SUCCESS = 'SAMPLE_DETAILS_SUCCESS';
export const SAMPLE_DETAILS_FAIL = 'SAMPLE_DETAILS_FAIL';

export const SAMPLE_CREATE_REQUEST = 'SAMPLE_CREATE_REQUEST';
export const SAMPLE_CREATE_SUCCESS = 'SAMPLE_CREATE_SUCCESS';
export const SAMPLE_CREATE_FAIL = 'SAMPLE_CREATE_FAIL';
export const SAMPLE_CREATE_RESET = 'SAMPLE_CREATE_RESET';

export const SAMPLE_UPDATE_REQUEST = 'SAMPLE_UPDATE_REQUEST';
export const SAMPLE_UPDATE_SUCCESS = 'SAMPLE_UPDATE_SUCCESS';
export const SAMPLE_UPDATE_FAIL = 'SAMPLE_UPDATE_FAIL';
export const SAMPLE_UPDATE_RESET = 'SAMPLE_UPDATE_RESET';

export const SAMPLE_DELETE_REQUEST = 'SAMPLE_DELETE_REQUEST';
export const SAMPLE_DELETE_SUCCESS = 'SAMPLE_DELETE_SUCCESS';
export const SAMPLE_DELETE_FAIL = 'SAMPLE_DELETE_FAIL';

export const SAMPLE_DTO_REQUEST = 'SAMPLE_DTO_REQUEST';
export const SAMPLE_DTO_SUCCESS = 'SAMPLE_DTO_SUCCESS';
export const SAMPLE_DTO_FAIL = 'SAMPLE_DTO_FAIL';
export const SAMPLE_DTO_RESET = 'SAMPLE_DTO_RESET';

export const SAMPLE_LIST_BYIDS_REQUEST = 'SAMPLE_LIST_BYIDS_REQUEST';
export const SAMPLE_LIST_BYIDS_SUCCESS = 'SAMPLE_LIST_BYIDS_SUCCESS';
export const SAMPLE_LIST_BYIDS_FAIL = 'SAMPLE_LIST_BYIDS_FAIL';
export const SAMPLE_LIST_BYIDS_RESET = 'SAMPLE_LIST_BYIDS_RESET';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';