import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Button,
  Drawer,
  Form,
  Input,
  Space,
  message,
  Tabs,
  Typography,
  Popconfirm,
  Spin,
  Row,
  Col,
  theme
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import {
  listTemplates,
  getTemplateDetails,
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from '../../actions/templateActions';
import { ThemeContext } from '../../index';
import axios from 'axios';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;

const generateDummyData = (jsonConfig) => {
  const fillTemplateVariables = (template) => {
    const matches = template.match(/{{\s*[\w\.]+\s*}}/g) || [];
    const data = {};
    matches.forEach((match) => {
      const key = match.replace(/{{\s*|\s*}}/g, '');
      data[key] = `${key}1`;
    });
    return data;
  };

  const processBlock = (block) => {
    if (typeof block.template === 'string') {
      return {
        ...block,
        content: fillTemplateVariables(block.template)
      };
    }
    return block;
  };

  const processCollection = (collection) => {
    const processedCollection = { ...collection };
    if (collection.content && collection.content.default_template) {
      const dummyItem = fillTemplateVariables(collection.content.default_template);
      processedCollection.content.items = [
        { content: dummyItem },
        { content: dummyItem }
      ];
    }
    return processedCollection;
  };

  const processContent = (content) => {
    if (typeof content !== 'object') return content;

    const processedContent = {};
    for (const [key, value] of Object.entries(content)) {
      if (value && typeof value === 'object') {
        if (value.type === 'bloc') {
          processedContent[key] = processBlock(value);
        } else if (value.type === 'collection') {
          processedContent[key] = processCollection(value);
        } else if (Array.isArray(value)) {
          processedContent[key] = value?.map(item =>
            typeof item === 'object' ? processContent(item) : `${key}1`
          );
        } else {
          processedContent[key] = processContent(value);
        }
      } else {
        processedContent[key] = `${key}1`;
      }
    }
    return processedContent;
  };

  return {
    ...jsonConfig,
    data: processContent(jsonConfig.data)
  };
};


const TemplateManagementScreen = () => {
  const dispatch = useDispatch();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [activeTab, setActiveTab] = useState('edit');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const templates = useSelector((state) => state.templateList.templates);
  const loadingList = useSelector((state) => state.templateList.loading);
  const templateDetails = useSelector((state) => state.templateDetails.template);

  const { themeConfig } = useContext(ThemeContext);
  const isDarkMode = themeConfig.algorithm === theme.darkAlgorithm;

  useEffect(() => {
    dispatch(listTemplates());
  }, [dispatch]);

  const showDrawer = async (template = null) => {
    setEditingTemplate(template);
    setActiveTab('edit');
    if (template) {
      try {
        await dispatch(getTemplateDetails(template.name));
      } catch (error) {
        message.error('Failed to load template details');
      }
    } else {
      form.resetFields();
    }
    setIsDrawerVisible(true);
  };

  useEffect(() => {
    if (templateDetails && editingTemplate) {
      form.setFieldsValue({
        name: templateDetails.name,
        hbsTemplate: templateDetails.templateData,
        jsonConfig: JSON.stringify(templateDetails.jsonConfig, null, 2)
      });
    }
  }, [templateDetails, editingTemplate, form]);

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setEditingTemplate(null);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const submitData = {
      name: values.name,
      hbsTemplate: values.hbsTemplate,
      jsonConfig: JSON.parse(values.jsonConfig)
    };

    try {
      if (editingTemplate) {
        await dispatch(updateTemplate(editingTemplate.name, submitData));
        message.success('Template updated successfully');
      } else {
        const result = await dispatch(createTemplate(submitData));
        message.success('Template created successfully');
        setEditingTemplate(result); // Set the newly created template as the editing template
      }
      dispatch(listTemplates());
      // Switch to edit mode and load the template
      setActiveTab('edit');
      dispatch(getTemplateDetails(submitData.name));
    } catch (error) {
      message.error(`Failed to ${editingTemplate ? 'update' : 'create'} template: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (name) => {
    try {
      await dispatch(deleteTemplate(name));
      message.success('Template deleted successfully');
      dispatch(listTemplates());
    } catch (error) {
      message.error('Failed to delete template: ' + error.message);
    }
  };

  const handleTest = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const jsonConfig = JSON.parse(values.jsonConfig);
      const dummyData = generateDummyData(jsonConfig);
      const { data } = await axios.post('/print/documents/generate', dummyData, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
      setActiveTab('test');
    } catch (error) {
      message.error('Failed to generate test PDF: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleRefresh = () => {
    setSearchQuery('');
    dispatch(listTemplates());
  };

  const filteredTemplates = templates?.filter(template =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '30%',
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => showDrawer(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this template?"
            onConfirm={() => handleDelete(record.name)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>Template Management</Title>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Search
            placeholder="Search templates"
            onSearch={handleSearch}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} sm={12} md={16} lg={18}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showDrawer()}
            >
              Add New Template
            </Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredTemplates}
        loading={loadingList}
        rowKey="name"
      />

      <Drawer
        title={editingTemplate ? 'Edit Template' : 'Add New Template'}
        placement="right"
        width="80%"
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="Edit" key="edit">
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="name"
                label="Template Name"
                rules={[{ required: true, message: 'Please input the template name!' }]}
              >
                <Input disabled={!!editingTemplate} />
              </Form.Item>
              <Form.Item
                name="jsonConfig"
                label="JSON Config"
                rules={[
                  { required: true, message: 'Please input the JSON config!' },
                  {
                    validator: (_, value) => {
                      try {
                        JSON.parse(value);
                        return Promise.resolve();
                      } catch (error) {
                        return Promise.reject('Invalid JSON format');
                      }
                    },
                  },
                ]}
              >
                <AceEditor
                  mode="json"
                  theme={isDarkMode ? 'monokai' : 'github'}
                  name="jsonConfig"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{ width: '100%', height: '300px' }}
                />
              </Form.Item>
              <Form.Item
                name="hbsTemplate"
                label="HBS Template"
                rules={[{ required: true, message: 'Please input the HBS template!' }]}
              >
                <AceEditor
                  mode="handlebars"
                  theme={isDarkMode ? 'monokai' : 'github'}
                  name="hbsTemplate"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{ width: '100%', height: '300px' }}
                />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {editingTemplate ? 'Update' : 'Create'}
                  </Button>
                  <Button onClick={handleTest} loading={loading} disabled={!editingTemplate}>
                    Test
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane 
          tab="Test" 
          key="test" 
          >
            <Spin spinning={loading}>
              {pdfUrl && (
                <iframe
                  src={pdfUrl}
                  width="100%"
                  height={window.innerHeight - 200}
                  style={{ border: 'none' }}
                />
              )}
            </Spin>
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

export default TemplateManagementScreen;