import {

    PRICING_RULE_LIST_REQUEST,
    PRICING_RULE_LIST_SUCCESS,
    PRICING_RULE_LIST_FAIL,

    PRICING_RULE_DETAILS_REQUEST,
    PRICING_RULE_DETAILS_SUCCESS,
    PRICING_RULE_DETAILS_FAIL,

    PRICING_RULE_DELETE_REQUEST,
    PRICING_RULE_DELETE_SUCCESS,
    PRICING_RULE_DELETE_FAIL,

    PRICING_RULE_CREATE_REQUEST,
    PRICING_RULE_CREATE_SUCCESS,
    PRICING_RULE_CREATE_FAIL,
    PRICING_RULE_CREATE_RESET,

    PRICING_RULE_UPDATE_REQUEST,
    PRICING_RULE_UPDATE_SUCCESS,
    PRICING_RULE_UPDATE_FAIL,
    PRICING_RULE_UPDATE_RESET,

    SET_CURRENT_PAGE,

    PRICING_RULE_DTO_FAIL,
    PRICING_RULE_DTO_REQUEST,
    PRICING_RULE_DTO_SUCCESS,
    PRICING_RULE_DTO_RESET,

    PRICING_RULE_LIST_BYIDS_REQUEST,
    PRICING_RULE_LIST_BYIDS_SUCCESS,
    PRICING_RULE_LIST_BYIDS_FAIL,
    PRICING_RULE_LIST_BYIDS_RESET

} from "../constants/pricingRuleConstants";

const initialState = {
    pricingRules: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const pricingRuleListReducer = (
    state = {
        pricingRules: []
    }, action) => {
    switch (action.type) {
        case PRICING_RULE_LIST_REQUEST:
            return { loading: true, pricingRules: [] };
        case PRICING_RULE_LIST_SUCCESS:
            // our state will have a list of pricingRules after the request
            return {
                loading: false,
                pricingRules: action.payload.pricingRules,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case PRICING_RULE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const pricingRuleListByIDsReducer = (
    state = {
        pricingRules: []
    }, action) => {
    switch (action.type) {
        case PRICING_RULE_LIST_BYIDS_REQUEST:
            return { loading: true, pricingRules: [] };
        case PRICING_RULE_LIST_BYIDS_SUCCESS:
            // our state will have a list of pricingRules after the request
            return { loading: false, pricingRules: action.payload };
        case PRICING_RULE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case PRICING_RULE_LIST_BYIDS_RESET:
            return { pricingRules: [] };
        default:
            return state;
    }
}


export const pricingRuleDetailsReducer = (
    state = {
        pricingRule: { comments: [] }
    }, action) => {
    switch (action.type) {
        case PRICING_RULE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case PRICING_RULE_DETAILS_SUCCESS:
            // our state will have a list of pricingRules after the request
            return { loading: false, pricingRule: action.payload };
        case PRICING_RULE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const pricingRuleDtoReducer = (state = { pricingRuleDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case PRICING_RULE_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case PRICING_RULE_DTO_SUCCESS:
        return { ...state, loading: false, pricingRuleDto: action.payload };
      case PRICING_RULE_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case PRICING_RULE_DTO_RESET:
        return { pricingRuleDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const pricingRuleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRICING_RULE_DELETE_REQUEST:
            return { loading: true }

        case PRICING_RULE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PRICING_RULE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const pricingRuleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRICING_RULE_CREATE_REQUEST:
            return { loading: true }

        case PRICING_RULE_CREATE_SUCCESS:
            return { loading: false, success: true, pricingRule: action.payload }

        case PRICING_RULE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PRICING_RULE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const pricingRuleUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case PRICING_RULE_UPDATE_REQUEST:
            return { loading: true }

        case PRICING_RULE_UPDATE_SUCCESS:
            return { loading: false, success: true, pricingRule: action.payload }

        case PRICING_RULE_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case PRICING_RULE_UPDATE_RESET:
            return { pricingRule: {} }

        default:
            return state
    }
}