import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaUser, FaThList } from "react-icons/fa";
import { Input, Button, Select as SimpleSelect, DateInput, BackLink, Card, Details, ErrorSummary, Fieldset, ActionLink, Radios, Checkboxes, SummaryList, Tag, InsetText } from "nhsuk-react-components";
import { getIdentityUserDetails, updateIdentityUser } from "../../actions/userActions";
import { getPatientDetails, updatePatient } from "../../actions/patientActions";
import { listOrganisations } from "../../actions/organisationActions";
import { listUsers } from "../../actions/userActions";
import { listEcgs } from "../../actions/ecgActions";
import { listSymptoms } from "../../actions/symptomActions";
import { listAetiologies } from "../../actions/aetiologyActions";
import { listFollowupCentres } from "../../actions/followupCentreActions";
import { listImplantCentres } from "../../actions/implantCentreActions";
import { listImplants } from "../../actions/implantActions";
import { listConsents } from "../../actions/consentActions";
import { getShortUrlDetailsbyUserId, createShortUrl, updateShortUrl, getShortUrlDetails } from "../../actions/shortUrlActions";
// PATIENT_UPDATE_RESET
import { PATIENT_UPDATE_RESET } from "../../constants/patientConstants";
// SHORT_URL_CREATE_RESET
import { SHORT_URL_CREATE_RESET, SHORT_URL_DETAILS_RESET } from "../../constants/shortUrlConstants";
import Select from 'react-select';
import { debounce } from 'lodash';
import axios, { axois } from 'axios';
// import QRCode from "react-qr-code";
import QRCode from "react-qr-code";
import Callout from "../../components/callout/Callout.component";
import Checklist from "../../components/checklist/Checklist.component";
import Modal from "../../components/modal/Modal.component";
import ImplantHistoryDrawer from "../../components/implantHistoryDrawer/ImplantHistoryDrawer.component";
import ImplantInstanceList from "../../components/implantInstanceList/ImplantInstanceList.component";
import { extractBase64FromDataUri } from "../../utils/functions";

import SelectOther from "../../components/selectOther/SelectOther.component";

import { usePDF } from 'react-to-pdf';
import { Steps } from 'antd';

export default function PatientEditScreen({ history, match }) {


    const patientId = match.params._id;

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Edit Patient";
    }, []);

    // reset update state
    useEffect(() => {
        dispatch({ type: PATIENT_UPDATE_RESET });
    }, [dispatch]);

    // reset create short url state
    useEffect(() => {
        dispatch({ type: SHORT_URL_CREATE_RESET });
        dispatch({ type: SHORT_URL_DETAILS_RESET });
    }, [dispatch]);

    // get current user details
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [targetDevice, setTargetDevice] = useState("android");

    const [updatedPatient, setUpdatedPatient] = useState({});
    // get day month year
    const [dateofbirth, setdateofbirth] = useState(convertDate(Date.now()));
    const [generatorImplantDate, setgeneratorImplantDate] = useState(convertDate(Date.now()));

    // get current identity user
    const [currentIdentityUser, setCurrentIdentityUser] = useState({});

    // generic working state
    const [working, setWorking] = useState(false);

    // PDF hook
    const { toPDF, targetRef } = usePDF({ filename: 'pass.pdf' });

    // Steps 
    // 1. Patient Demographics
    // 2. Clinical Indications
    // 3. Implant Details
    const steps = [
        {
            title: 'Patient Demographics',
            description: 'Enter patient details',
        },
        {
            title: 'Clinical Indications',
            description: 'Enter clinical details',
        },
        {
            title: 'Implant Details',
            description: 'Enter implant details',
        },
    ];

    const [currentStep, setCurrentStep] = useState(0);

    const onStepChange = (value) => {
        setCurrentStep(value);
    };
    // Implant History
    const [openDrawer, setOpenDrawer] = useState(false);

    // implant type selected boolean
    const [implantTypeSelected, setImplantTypeSelected] = useState(true);

    // pass url
    const [passUrl, setPassUrl] = useState("");
    // selected ecgs
    const [selectedEcgs, setSelectedEcgs] = useState([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState([]);
    const [selectedAetiologies, setSelectedAetiologies] = useState([]);
    const [selectedImplants, setSelectedImplants] = useState([]);
    const [selectedImplantCentres, setSelectedImplantCentres] = useState([]);
    const [selectedFollowupCentres, setSelectedFollowupCentres] = useState([]);
    const [selectedConsents, setSelectedConsents] = useState([]);
    const [selectedImplantInstances, setSelectedImplantInstances] = useState([]);
    // Pass data
    const [passData, setPassData] = useState({});

    // show new IdentityUser form
    const [showNewIdentityUserForm, setShowNewIdentityUserForm] = useState(false);

    // Modal
    const [genericModalShow, setGenericModalShow] = useState(false);
    const [genericModalContent, setGenericModalContent] = useState();
    const [consentModalShow, setConsentModalShow] = useState(false);
    const [consentModalContent, setConsentModalContent] = useState();
    const [implantHistoryModalShow, setImplantHistoryModalShow] = useState(false);

    const genericPropertiesToIgnore = ["id", "patientEcgs", "patientSymptoms", "patientAetiologies", "patientImplants", "patientImplantCentres", "patientFollowUpCentres", "patientConsents", "patient"];
    const consentPropertiesToIgnore = ["id", "patientConsents"];


    const openGenericModal = () => {
        setGenericModalShow(true);
    };

    const closeGenericModal = () => {
        setGenericModalShow(false);
    };

    const openConsentModal = () => {
        setConsentModalShow(true);
    };

    const closeModal = () => {
        setConsentModalShow(false);
    };

    const openImplantHistoryModal = () => {
        setImplantHistoryModalShow(true);
    };

    const closeImplantHistoryModal = () => {
        setImplantHistoryModalShow(false);
    };



    // load patient details
    const patientDetails = useSelector((state) => state.patientDetails);
    const { loading, error, patient } = patientDetails;

    // load patient details
    const patientUpdate = useSelector((state) => state.patientUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = patientUpdate;

    // load organisations
    const organisationList = useSelector((state) => state.organisationList);
    const { loading: loadingOrganisation, error: errorOrganisation, organisations } = organisationList;

    // load ecgs
    const ecgList = useSelector((state) => state.ecgList);
    const { loading: loadingEcg, error: errorEcg, ecgs } = ecgList;

    // load implants
    const implantList = useSelector((state) => state.implantList);
    const { loading: loadingImplant, error: errorImplant, implants } = implantList;

    // load symptoms
    const symptomList = useSelector((state) => state.symptomList);
    const { loading: loadingSymptom, error: errorSymptom, symptoms } = symptomList;

    // load aetiologies
    const aetiologyList = useSelector((state) => state.aetiologyList);
    const { loading: loadingAetiology, error: errorAetiology, aetiologies } = aetiologyList;

    // load followup centres
    const followupCentreList = useSelector((state) => state.followupCentreList);
    const { loading: loadingFollowupCentre, error: errorFollowupCentre, followupCentres } = followupCentreList;

    // load implant centres
    const implantCentreList = useSelector((state) => state.implantCentreList);
    const { loading: loadingImplantCentre, error: errorImplantCentre, implantCentres } = implantCentreList;

    // load consents
    const consentList = useSelector((state) => state.consentList);
    const { loading: loadingConsent, error: errorConsent, consents } = consentList;

    // create shortUrl
    const shortUrlCreate = useSelector((state) => state.shortUrlCreate);
    const { loading: loadingShortUrlCreate, error: errorShortUrlCreate, success: successShortUrlCreate, shortUrl } = shortUrlCreate;

    // short url details by patientId
    const shortUrlDetailsByUserId = useSelector((state) => state.shortUrlDetailsByUserId);
    const { loading: loadingShortUrlDetailsByUserId, error: errorShortUrlDetailsByUserId, success: successShortUrlDetailsByUserId, shortUrl: shortUrlByUserId } = shortUrlDetailsByUserId;

    // update 
    const userList = useSelector((state) => state.userList);
    const { loading: loadingUsers, error: errorUsers, users } = userList;

    // handle user filter
    const [userListFilter, setuserListFilter] = useState({ query: "", page: 1, filter: "UserName" });

    // handle user filter change
    const handleUserFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setuserListFilter({ ...userListFilter, filter: selectedFilter });
    };

    // debounce the search text field
    // Debounce the search function
    const debouncedSearch = debounce((text) => {
        setuserListFilter({ ...userListFilter, query: text });
    }, 1000);


    // get patient details
    useEffect(() => {
        // reset pass url
        dispatch(getPatientDetails(patientId));
        dispatch(listOrganisations());
        dispatch(listUsers());
        dispatch(listEcgs({ limit: 100 }));
        dispatch(listSymptoms({ limit: 100 }));
        dispatch(listAetiologies({ limit: 100 }));
        dispatch(listFollowupCentres({ limit: 100 }));
        dispatch(listImplantCentres({ limit: 100 }));
        dispatch(getShortUrlDetailsbyUserId(patientId));
        dispatch(listImplants({ limit: 500 }));
        dispatch(listConsents({ limit: 20 }));
    }, [dispatch, patientId]);

    // listen to user filter changes
    useEffect(() => {
        dispatch(listUsers(userListFilter));
    }, [dispatch, userListFilter]);

    // listen to patient details changes
    useEffect(() => {
        if (patient && ecgs && symptoms && aetiologies && implants && implantCentres && followupCentres
            && patient.ecgIds && patient.symptomIds && patient.aetiologyIds && patient.implantIds
            && patient.implantCentreIds && patient.followUpCentreIds) {
            // update selected ecgs
            setSelectedEcgs(ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name })).filter(ecg => patient.ecgIds?.includes(ecg.value)));
            // update selected symptoms
            setSelectedSymptoms(symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name })).filter(symptom => patient.symptomIds?.includes(symptom.value)));
            // update selected aetiologies
            setSelectedAetiologies(aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name })).filter(aetiology => patient.aetiologyIds?.includes(aetiology.value)));
            // update selected implants
            setSelectedImplants(implants?.map(implant => ({ value: implant.id, label: implant.barcode })).filter(implant => patient.implantIds?.includes(implant.value)));
            // update selected implant centres
            setSelectedImplantCentres(implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name })).filter(implantCentre => patient.implantCentreIds?.includes(implantCentre.value)));
            // update selected followup centres
            setSelectedFollowupCentres(followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name })).filter(followupCentre => patient.followUpCentreIds?.includes(followupCentre.value)));
            // update selected consents
            setSelectedConsents(consents?.map(consent => ({ value: consent.id, label: consent.consentType })).filter(consent => patient.consentIds?.includes(consent.value)));
            // update date of birth
            setdateofbirth(convertDate(patient?.dateOfBirth));
            // update generator implant date
            setgeneratorImplantDate(convertDate(patient?.generatorImplantDate));
            // update new patient
            setUpdatedPatient(patient);

        }
    }, [patient, ecgs, symptoms, aetiologies, implants, implantCentres, followupCentres, consents]);


    // listen to shortUrl
    useEffect(() => {
        if (shortUrl) {
            setPassUrl(`/dotnet_api/v1/ShortUrl/code/${shortUrl.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrl, targetDevice, dispatch]);

    // listen to shortUrlByUserId
    useEffect(() => {

        // if undefined return
        if (!shortUrlByUserId) { return; }

        if (shortUrlByUserId.shortCode) {
            setPassUrl(`/dotnet_api/v1/ShortUrl/code/${shortUrlByUserId.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrlByUserId, targetDevice]);

    // listen to showNewIdentityUserForm to reset currentIdentityUser
    useEffect(() => {
        if (!showNewIdentityUserForm) {
            setCurrentIdentityUser({});
            // reset updatedPatient to the current identity user
            setUpdatedPatient({ ...updatedPatient, identityUserId: patient.identityUserId });

        }
    }, [showNewIdentityUserForm, userInfo]);


    // function to convert date to day, month, year
    // from 2023-12-23T11:25:50Z like format
    // to {day:month, month:month, year:year} for react nhs date input
    function convertDate(date) {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        return {
            day: day,
            month: month,
            year: year,
        };
    }


    // convert to date from {day:month, month:month, year:year}
    // to 2023-12-23T11:25:50Z like format
    function convertToDate(date) {
        const dateObj = new Date(date.year, date.month - 1, date.day);
        return dateObj.toISOString();
    }

    // listen to selected ecgs
    useEffect(() => {
        if (selectedEcgs) {
            setUpdatedPatient({ ...updatedPatient, ecgIds: selectedEcgs?.map(ecg => ecg.value) });
        }
    }, [selectedEcgs]);

    // listen to selected symptoms
    useEffect(() => {
        if (selectedSymptoms) {
            setUpdatedPatient({ ...updatedPatient, symptomIds: selectedSymptoms?.map(symptom => symptom.value) });
        }
    }, [selectedSymptoms]);

    // listen to selected aetiologies
    useEffect(() => {
        if (selectedAetiologies) {
            setUpdatedPatient({ ...updatedPatient, aetiologyIds: selectedAetiologies?.map(aetiology => aetiology.value) });
        }
    }, [selectedAetiologies]);

    // listen to selected implants
    useEffect(() => {
        if (selectedImplants) {
            setUpdatedPatient({ ...updatedPatient, implantIds: selectedImplants?.map(implant => implant.value) });
        }
    }, [selectedImplants]);

    // listen to selected implant centres
    useEffect(() => {
        if (selectedImplantCentres) {
            setUpdatedPatient({ ...updatedPatient, implantCentreIds: selectedImplantCentres?.map(implantCentre => implantCentre.value) });
        }
    }, [selectedImplantCentres]);

    // listen to selected followup centres
    useEffect(() => {
        if (selectedFollowupCentres) {
            setUpdatedPatient({ ...updatedPatient, followUpCentreIds: selectedFollowupCentres?.map(followupCentre => followupCentre.value) });
        }
    }, [selectedFollowupCentres]);

    // listen to selected consents
    useEffect(() => {
        if (selectedConsents) {
            setUpdatedPatient({ ...updatedPatient, consentIds: selectedConsents?.map(consent => consent.value) });
        }
    }, [selectedConsents]);

    // listen to selected implant centres
    useEffect(() => {
        if (!updatedPatient.implantIds || !updatedPatient.followUpCentreIds || !updatedPatient.implantCentreIds
            || !updatedPatient.followUpCentreIds[0] || !updatedPatient.implantCentreIds[0] || !selectedImplantInstances) { return; }

        // update pass data
        setPassData({
            "email": updatedPatient.email || "N/A",
            "generator_product_type": selectedImplantInstances?.find(selectedImplant => selectedImplant.value === updatedPatient.implantIds[0])?.productType || "N/A",
            "date_of_birth_ddmmyyyy": dateofbirth.day + "-" + dateofbirth.month + "-" + dateofbirth.year,
            "follow_up_centre": followupCentres?.find(followupCentre => followupCentre.id === updatedPatient.followUpCentreIds[0])?.name || "N/A",
            "full_name": updatedPatient.forename + " " + updatedPatient.surname || "N/A",
            "generator_man": updatedPatient.nhsNumber || "N/A",
            "generator_model_name": "Model Name",
            "generator_implant_date_ddmmyyyy": generatorImplantDate.day + "-" + generatorImplantDate.month + "-" + generatorImplantDate.year || "1-1-1900",
            "mr_conditional": updatedPatient.mrConditional || "N/A",
            "lead0": "Lead 0",
            "lead1": "Lead 1",
            "lead2": "Lead 2",
            "abandoned_hardware": updatedPatient.abondonedHardware || "N/A",
            "patient_id": patientId,
        });


    }, [updatedPatient, selectedImplantInstances, followupCentres, patientId]);


    // handle generate pass
    const handleGeneratePass = async (passData) => {
        try {
            // Ensure updatedPatient is defined
            if (!updatedPatient) {
                return;
            }

            // Define shortUrlRequest
            const shortUrlRequest = {
                longUrl: "",
                userFullName: `${updatedPatient.forename} ${updatedPatient.surname}`,
                userEmail: updatedPatient.email,
                userId: patientId,
                pkPassFile: null,
                targetDevice: targetDevice,
            };

            // Get long URL from /pass/android using axios and passData
            const androidResponse = await axios.post("/pass/android", passData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Set long URL
            shortUrlRequest.longUrl = androidResponse.data.passUrl;

            // Get PKPass file from /pass/ios using axios and passData
            const iosResponse = await axios.post("/pass/ios", passData, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Convert the PKPass file to base64
            const pkpassFile = new Blob([iosResponse.data], { type: 'application/vnd.apple.pkpass' });
            const base64data = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(pkpassFile);
                reader.onloadend = function () {
                    resolve(reader.result);
                };
            });

            // Set the base64-encoded PKPass file
            const base64content = extractBase64FromDataUri(base64data);
            shortUrlRequest.pkPassFile = base64data;

            // Create short URL
            dispatch(createShortUrl(shortUrlRequest));
        } catch (error) {
            console.error(error.message);
            alert('An error occurred while generating the pass. Please make sure you have filled in all the required fields.');
        }
    };

    // handle update patient
    const handleUpdatePatient = (e, updatedPatient) => {
        e.preventDefault();
        setCurrentIdentityUser(users?.find(user => user.id === updatedPatient.identityUserId));
        if (!updatedPatient.identityUserId) {
            alert("Please select an identity user.");
            return;
        }
        // dispatch update patient
        dispatch(updatePatient(patientId, updatedPatient));
    }

    // handle click on the checkboxes 
    // get the consent JSON and display it in a modal
    const handleConsentClick = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        setConsentModalContent(consent);
        openConsentModal();
    };

    // handle entity click
    // handle display entity details in modal
    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        openGenericModal();
    };

    // handle sendEmail containing the pass link
    // the endpoint dotnet_api/v1/auth/sendemail
    const handleSendPassEmail = async (passUri) => {
        // make sure updatedPatient is not undefined
        if (!updatedPatient) { return; }

        // if passUrl is empty or EmailToId is empty
        if (!passUri) {
            alert("Please generate a pass first!");
            return;
        }

        if (!updatedPatient?.email) {
            alert("Please select an identity user first!");
            return;
        }

        setWorking(true);

        // android pass url
        const siteRoot = window.location.origin;
        // pass URI either contain android or ios, remove it
        passUri = passUri.replace("/android", "");
        passUri = passUri.replace("/ios", "");

        // generate urls for android and ios

        const iosUrl = siteRoot + passUri + "/ios";
        const androidUrl = siteRoot + passUri + "/android";


        const email = {
            "EmailToId": updatedPatient.email,
            "EmailToName": updatedPatient.forename ? updatedPatient.forename + " " + updatedPatient.surname : "Patient",
            "EmailSubject": "Your Pass",
            "EmailBody": "<p>Dear " + updatedPatient.forename + " " + updatedPatient.surname + ",</p><p>Please find your pass links below:</p><h3>Android: <a href='" + androidUrl + "'>Download for Android</a></h3><h3>iOS: <a href='" + iosUrl + "'>Download for iOS</a></h3>",
        }

        // use the endpoint dotnet_api/v1/auth/sendemail to send email
        const response = await axios.post(
            "/dotnet_api/v1/auth/sendemail",
            email,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then((response) => {
            setWorking(false);
            alert("Email sent successfully!");
        })
            // catch error
            .catch(function (error) {
                setWorking(false);
                alert(error.message);
            });
    }
    const handleCheckMRConditional = async (selectedImplants) => {
        // get implant objects based on selectedImplants value
        const selectedImplantObjects = selectedImplants?.map(selectedImplant => implants.find(implant => implant.id === selectedImplant.value));
        // check if any of the selected implants is MR Conditional
        const mrConditional = selectedImplantObjects.some(selectedImplant => selectedImplant.mrConditional);
        setUpdatedPatient({ ...updatedPatient, mrConditional: mrConditional });
    }

    // handle device list condition change
    const handleDeviceListConditionChange = (target) => {
        setUpdatedPatient({
            ...updatedPatient,
            mrConditional: target.isMRIConditional,
            abondonedHardware: target.isAbandonedHardware
        });
        setSelectedImplantInstances(target.deviceList);
    };

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">
                        <div className="welcome-title d-flex justify-content-between align-items-center">
                            <h3>Edit Patient</h3>
                            <BackLink
                                asElement="a"
                                href="#"
                                onClick={() => history.goBack()}
                            >
                                Return back
                            </BackLink>

                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">
                                <Steps
                                    current={currentStep}
                                    onChange={onStepChange}
                                    items={steps}
                                />
                                <div className="nhsuk-u-padding-top-3"></div>

                                <div className="welcome-content">
                                    <div className="welcome-text">
                                        <Card.Group>
                                            {currentStep === 0 && (
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            <div className="icon-container ">
                                                                <FaUser size={30} />
                                                            </div>
                                                            Patient: {updatedPatient?.forename} {updatedPatient?.surname}

                                                            {errorUpdate && (
                                                                <ErrorSummary
                                                                    aria-labelledby="error-summary-title"
                                                                    role="alert"
                                                                    tabIndex={-1}
                                                                >
                                                                    <ErrorSummary.Title id="error-summary-title">
                                                                        There is a problem
                                                                    </ErrorSummary.Title>
                                                                    <ErrorSummary.Body>
                                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                                            {errorUpdate?.map((err) => (
                                                                                <li key={err}>
                                                                                    {err}
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                    </ErrorSummary.Body>
                                                                </ErrorSummary>
                                                            )
                                                            }
                                                        </Card.Heading>
                                                        <p>Linked to: {" "}
                                                            {updatedPatient.identityUserId === userInfo.userId ? (
                                                                <Tag color="green">Your Account</Tag>
                                                            ) : (
                                                                <Tag color="pink">Another Account</Tag>
                                                            )}
                                                        </p>
                                                        <Checkboxes name="checkboxes">
                                                            <Checkboxes.Box
                                                                conditional={<p>If you prefer to link this patient to another identity account, use the dropdown below:</p>}
                                                                checked={showNewIdentityUserForm}
                                                                onChange={(e) => setShowNewIdentityUserForm(e.target.checked)}
                                                            >
                                                                Link/Amend account?
                                                            </Checkboxes.Box>
                                                        </Checkboxes>

                                                        {showNewIdentityUserForm &&
                                                            <Container>

                                                                <p>Please link an identity account to this patient.</p>
                                                                <Select
                                                                    isLoading={loadingUsers}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    options={users?.map(user => ({ value: user.id, label: user.userName }))}
                                                                    // selected value is the updatedPatient.patientId
                                                                    value={users?.map(user => ({ value: user.id, label: user.userName })).find(user => user.value === updatedPatient.identityUserId)}
                                                                    onInputChange={(text, actionMeta) => {
                                                                        if (actionMeta.action === 'input-change') {
                                                                            debouncedSearch(text);
                                                                        }
                                                                    }}
                                                                    onChange={(selectedOption) => {
                                                                        setCurrentIdentityUser(users?.find(user => user.id === selectedOption?.value));
                                                                        setUpdatedPatient({ ...updatedPatient, identityUserId: selectedOption?.value });
                                                                    }}

                                                                />
                                                                <div className="nhsuk-u-padding-top-3"></div>

                                                                <Link to={updatedPatient?.identityUserId ? `/users/${updatedPatient.identityUserId}` : "#"} target="_blank">
                                                                    → Identity user details
                                                                </Link>
                                                                <div className="nhsuk-u-padding-top-3"></div>

                                                                <Button
                                                                    type="submit"
                                                                    disabled={loadingUpdate}
                                                                    onClick={(e) => {
                                                                        handleUpdatePatient(e, updatedPatient);
                                                                    }}
                                                                >   Save
                                                                </Button>

                                                            </Container>
                                                        }
                                                        <InsetText>
                                                            <SimpleSelect
                                                                label="Implant type"
                                                                selected={updatedPatient?.implantType}
                                                                name="implantType"
                                                                required
                                                                onChange={(e) => setUpdatedPatient({ ...updatedPatient, implantType: e.target.value })}
                                                                value={updatedPatient?.implantType}
                                                            >
                                                                <SimpleSelect.Option
                                                                    value=""
                                                                >Select an option</SimpleSelect.Option>

                                                                <SimpleSelect.Option
                                                                    value="Cardiac implantable electronic device"
                                                                >Cardiac implantable electronic device</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Heart Valve"
                                                                >Heart Valve</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Coronary intervention"
                                                                >Coronary intervention</SimpleSelect.Option>
                                                                <SimpleSelect.Option
                                                                    value="Electrophysiology"
                                                                >Electrophysiology</SimpleSelect.Option>

                                                            </SimpleSelect>

                                                        </InsetText>
                                                        {implantTypeSelected && (
                                                            <Card.Description>

                                                                <Input
                                                                    id="input-forname"
                                                                    label="Forename"
                                                                    name="forename"

                                                                    value={updatedPatient?.forename}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, forename: e.target.value })}
                                                                />
                                                                <Input
                                                                    id="input-surname"
                                                                    label="Surname"
                                                                    name="surname"

                                                                    value={updatedPatient?.surname}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, surname: e.target.value })}
                                                                />
                                                                <DateInput
                                                                    id="input-dateofbirth"
                                                                    label="Date of birth"
                                                                    name="dateOfBirth"

                                                                    value={dateofbirth}
                                                                    onChange={(e) => {
                                                                        // debounce the date of birth

                                                                        setdateofbirth(e.target.value);
                                                                        setUpdatedPatient({ ...updatedPatient, dateOfBirth: convertToDate(e.target.value) });
                                                                    }}
                                                                />

                                                                <Input
                                                                    id="input-email"
                                                                    label="Email"
                                                                    name="email"

                                                                    value={updatedPatient?.email}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, email: e.target.value })}
                                                                />
                                                                <Input
                                                                    id="input-nhsnumber"
                                                                    label="NHS Number"
                                                                    name="nhsNumber"

                                                                    value={updatedPatient?.nhsNumber}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, nhsNumber: e.target.value })}
                                                                />
                                                                <Input
                                                                    id="input-phone"
                                                                    label="Phone Number"
                                                                    name="phoneNumber"

                                                                    value={updatedPatient?.phoneNumber}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, phoneNumber: e.target.value })}
                                                                />

                                                                <SimpleSelect
                                                                    label="Gendre"
                                                                    selected={updatedPatient?.gendre}
                                                                    name="gendre"

                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, gendre: e.target.value })}
                                                                >
                                                                    <SimpleSelect.Option
                                                                        value="Male"
                                                                    >Male</SimpleSelect.Option>
                                                                    <SimpleSelect.Option
                                                                        value="Female"
                                                                    >Female</SimpleSelect.Option>
                                                                    <SimpleSelect.Option
                                                                        value="Other"
                                                                    >Other</SimpleSelect.Option>
                                                                </SimpleSelect>

                                                            </Card.Description>

                                                        )}




                                                        {(successUpdate) ?
                                                            <Callout
                                                                label="Success"
                                                                header="Success!"
                                                                autoDismiss={true}
                                                                dismissTime={5000}
                                                                time={new Date().toLocaleTimeString()}
                                                                title="Success!"
                                                                message={"Patient updated successfully!"}
                                                                mode="success"
                                                            />
                                                            : null
                                                        }




                                                        <Button
                                                            type="submit"
                                                            disabled={loadingUpdate}
                                                            onClick={(e) => {
                                                                handleUpdatePatient(e, updatedPatient);
                                                            }}
                                                        >   Save
                                                        </Button>

                                                    </Card.Content>
                                                </Card>
                                            )}
                                            {currentStep === 1 && (
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            Clinical Indications
                                                            {errorUpdate && (
                                                                <ErrorSummary
                                                                    aria-labelledby="error-summary-title"
                                                                    role="alert"
                                                                    tabIndex={-1}
                                                                >
                                                                    <ErrorSummary.Title id="error-summary-title">
                                                                        There is a problem
                                                                    </ErrorSummary.Title>
                                                                    <ErrorSummary.Body>
                                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                                            {errorUpdate?.map((err) => (
                                                                                <li key={err}>
                                                                                    {err}
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                    </ErrorSummary.Body>
                                                                </ErrorSummary>
                                                            )
                                                            }

                                                        </Card.Heading>
                                                        <Card.Description>


                                                            <Details>
                                                                <Details.Summary>Aetiologies</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedAetiologies?.map(aetiology => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const aetiologyId = aetiology.value;
                                                                                        const selectedAetiology = aetiologies?.find(aetiology => aetiology.id === aetiologyId);
                                                                                        handleEntityClick(selectedAetiology);
                                                                                    }}
                                                                                >
                                                                                    {aetiology.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>
                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name }))}
                                                                // selected value is the updatedPatient.patientId find all aetiologies
                                                                value={updatedPatient.aetiologyIds ? (aetiologies?.map(aetiology => ({ value: aetiology.id, label: aetiology.name })).filter(aetiology => updatedPatient.aetiologyIds.includes(aetiology.value))) : ""}

                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedAetiologies(selectedOption);
                                                                }}
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            <Details>
                                                                <Details.Summary>Symptoms</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedSymptoms?.map(symptom => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const symptomId = symptom.value;
                                                                                        const selectedSymptom = symptoms?.find(symptom => symptom.id === symptomId);
                                                                                        handleEntityClick(selectedSymptom);
                                                                                    }}
                                                                                >
                                                                                    {symptom.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>
                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name }))}
                                                                // selected value is the updatedPatient.patientId
                                                                value={updatedPatient.symptomIds ? (symptoms?.map(symptom => ({ value: symptom.id, label: symptom.name })).filter(symptom => updatedPatient.symptomIds.includes(symptom.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedSymptoms(selectedOption);
                                                                }}
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            <Details>
                                                                <Details.Summary>ECGs</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedEcgs?.map(ecg => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const ecgId = ecg.value;
                                                                                        const selectedEcg = ecgs?.find(ecg => ecg.id === ecgId);
                                                                                        handleEntityClick(selectedEcg);
                                                                                    }}
                                                                                >
                                                                                    {ecg.label}
                                                                                </Link>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>

                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name }))}
                                                                // selected value is the updatedPatient.patientId
                                                                value={updatedPatient.ecgIds ? (ecgs?.map(ecg => ({ value: ecg.id, label: ecg.name })).filter(ecg => updatedPatient.ecgIds.includes(ecg.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedEcgs(selectedOption);
                                                                }}
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            {loadingConsent ? <p>Loading consents...</p> : errorConsent ? <p>{errorConsent}</p> :

                                                                <Checklist name="checklist" hint="Consent information." heading={"Patient Consents"} expand={false}>
                                                                    <Checkboxes>
                                                                        {
                                                                            consents?.map(consent => (
                                                                                <Checkboxes.Box
                                                                                    key={consent.id}
                                                                                    conditional={`Scope: ${consent.consentScope}`}
                                                                                    checked={selectedConsents?.map(selectedConsent => selectedConsent.value).includes(consent.id)}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.checked) {
                                                                                            setSelectedConsents([...selectedConsents, { value: consent.id, label: consent.consentType }]);
                                                                                        } else {
                                                                                            setSelectedConsents(selectedConsents?.filter(selectedConsent => selectedConsent.value !== consent.id));
                                                                                        }
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if (!selectedConsents?.map(selectedConsent => selectedConsent.value).includes(consent.id)) {
                                                                                            handleConsentClick(consent.id);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {consent.consentType}
                                                                                </Checkboxes.Box>
                                                                            ))
                                                                        }

                                                                    </Checkboxes>


                                                                </Checklist>
                                                            }


                                                            <div className="nhsuk-u-padding-top-3"></div>
                                                            <Button
                                                                type="submit"
                                                                disabled={loadingUpdate}
                                                                onClick={(e) => {
                                                                    handleUpdatePatient(e, updatedPatient);
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            )}

                                            {currentStep === 2 && (
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Heading className="nhsuk-heading-m">
                                                            Implant Details
                                                        </Card.Heading>
                                                        <Card.Description>

                                                            {errorUpdate && (
                                                                <ErrorSummary
                                                                    aria-labelledby="error-summary-title"
                                                                    role="alert"
                                                                    tabIndex={-1}
                                                                >
                                                                    <ErrorSummary.Title id="error-summary-title">
                                                                        There is a problem
                                                                    </ErrorSummary.Title>
                                                                    <ErrorSummary.Body>
                                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                                            {errorUpdate?.map((err) => (
                                                                                <li key={err}>
                                                                                    {err}
                                                                                </li>
                                                                            ))}

                                                                        </ul>
                                                                    </ErrorSummary.Body>
                                                                </ErrorSummary>
                                                            )
                                                            }

                                                            <ImplantInstanceList
                                                                patientNHSNumber={updatedPatient.nhsNumber}
                                                                patientFullName={updatedPatient.forename ? `${updatedPatient.forename} ${updatedPatient.surname}` : ""}
                                                                onDeviceListConditionChange={(targetValue) => { handleDeviceListConditionChange(targetValue) }
                                                                }

                                                            />


                                                            <Checkboxes>
                                                                <Checkboxes.Box
                                                                    conditional={<p>MR conditionality applies.</p>}
                                                                    checked={updatedPatient?.mrConditional}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, mrConditional: e.target.checked })}
                                                                >
                                                                    MR Conditional?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>The hardware has been abandoned.</p>}
                                                                    checked={updatedPatient?.abondonedHardware}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, abondonedHardware: e.target.checked })}
                                                                >
                                                                    Abandoned Hardware?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Active High Voltage Therapy.</p>}
                                                                    checked={updatedPatient?.activeHighVoltageTherapy}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, activeHighVoltageTherapy: e.target.checked })}
                                                                >
                                                                    Active High Voltage Therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient received appropriate HV therapy.</p>}
                                                                    checked={updatedPatient?.patientReceivedAppropriateHVTherapy}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, patientReceivedAppropriateHVTherapy: e.target.checked })}
                                                                >
                                                                    Patient received appropriate HV therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient received inappropriate HV therapy.</p>}
                                                                    checked={updatedPatient?.patientReceivedInappropriateHVTherapy}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, patientReceivedInappropriateHVTherapy: e.target.checked })}
                                                                >
                                                                    Patient received inappropriate HV therapy?
                                                                </Checkboxes.Box>
                                                                <Checkboxes.Box
                                                                    conditional={<p>Patient under active advisory.</p>}
                                                                    checked={updatedPatient?.patientUnderActiveAdvisory}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, patientUnderActiveAdvisory: e.target.checked })}
                                                                >
                                                                    Patient under active advisory?
                                                                </Checkboxes.Box>

                                                            </Checkboxes>
                                                            <SelectOther
                                                                id="bradyprogrammedmode"
                                                                label="Brady Programmed Mode"
                                                                name="bradyProgrammedMode"
                                                                value={updatedPatient?.bradyProgrammedMode}
                                                                onChange={(e) => setUpdatedPatient({ ...updatedPatient, bradyProgrammedMode: e.target.value })}
                                                            >
                                                                <SimpleSelect.Option value="">Select an option</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="AAIR">AAIR</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="DDD">DDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="AOO">AOO</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="DOO">DOO</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVT">VVT</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VVT">VVT</SimpleSelect.Option>
                                                                <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>

                                                            </SelectOther>

                                                            <div className="nhsuk-u-padding-top-3"></div>


                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Input
                                                                    id="input-bradyprogrammedlowerrate"
                                                                    label="Brady Programmed Lower Rate"
                                                                    name="bradyProgrammedLowerRate"
                                                                    type="number"
                                                                    value={updatedPatient?.bradyProgrammedLowerRate}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, bradyProgrammedLowerRate: e.target.value })}
                                                                />
                                                                <Input
                                                                    id="input-tachytherapyzonerate"
                                                                    label="Tachy Therapy Zone Rate"
                                                                    name="tachyTherapyZoneRate"
                                                                    type="number"
                                                                    value={updatedPatient?.tachyTherapyZoneRate}
                                                                    onChange={(e) => setUpdatedPatient({ ...updatedPatient, tachyTherapyZoneRate: e.target.value })}
                                                                />
                                                            </div>





                                                            <div className="nhsuk-u-padding-top-3"></div>

                                                            <Details>
                                                                <Details.Summary>Implant Centres</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedImplantCentres?.map(implantCentre => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const implantCentreId = implantCentre.value;
                                                                                        const selectedImplantCentre = implantCentres?.find(implantCentre => implantCentre.id === implantCentreId);
                                                                                        handleEntityClick(selectedImplantCentre);
                                                                                    }}
                                                                                >
                                                                                    {implantCentre.label}
                                                                                </Link>

                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>

                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name }))}
                                                                // selected value is the updatedPatient.patientId
                                                                value={updatedPatient.implantCentreIds ? (implantCentres?.map(implantCentre => ({ value: implantCentre.id, label: implantCentre.name })).filter(implantCentre => updatedPatient.implantCentreIds.includes(implantCentre.value))) : ""}
                                                                isMulti
                                                                onChange={(selectedOption) => {
                                                                    setSelectedImplantCentres(selectedOption);
                                                                }}

                                                            />

                                                            <div className="nhsuk-u-padding-top-3"></div>
                                                            <Details>
                                                                <Details.Summary>Followup Centres</Details.Summary>
                                                                <Details.Text>
                                                                    <ul>
                                                                        {selectedFollowupCentres?.map(followupCentre => (

                                                                            <li>
                                                                                <Link
                                                                                    to="#"
                                                                                    onClick={() => {
                                                                                        const followupCentreId = followupCentre.value;
                                                                                        const selectedFollowupCentre = followupCentres?.find(followupCentre => followupCentre.id === followupCentreId);
                                                                                        handleEntityClick(selectedFollowupCentre);
                                                                                    }}
                                                                                >
                                                                                    {followupCentre.label}
                                                                                </Link>


                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </Details.Text>
                                                            </Details>


                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                options={followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name }))}
                                                                // selected value is the updatedPatient.patientId
                                                                value={updatedPatient.followUpCentreIds ? (followupCentres?.map(followupCentre => ({ value: followupCentre.id, label: followupCentre.name })).filter(followupCentre => updatedPatient.followUpCentreIds.includes(followupCentre.value))) : ""}

                                                                onChange={(selectedOption) => {
                                                                    setSelectedFollowupCentres(selectedOption);
                                                                }}
                                                                isMulti
                                                            />
                                                            <div className="nhsuk-u-padding-top-3"></div>


                                                            <InsetText
                                                                id="implant-history"
                                                            >
                                                                <p>Browse Practician Notes and Patient History</p>

                                                                <ActionLink
                                                                    href="#implant-history"
                                                                    onClick={() => {
                                                                        if (updatedPatient.nhsNumber) {
                                                                            setOpenDrawer(true);
                                                                        } else {
                                                                            alert("Please provide a valid NHS number");
                                                                        }
                                                                    }}
                                                                >
                                                                    Side notes
                                                                </ActionLink>
                                                            </InsetText>

                                                            {(successUpdate) ?
                                                                <Callout
                                                                    label="Success"
                                                                    header="Success!"
                                                                    autoDismiss={true}
                                                                    dismissTime={5000}
                                                                    time={new Date().toLocaleTimeString()}
                                                                    message={"Patient updated successfully!"}
                                                                    mode="success"
                                                                />
                                                                : null
                                                            }


                                                        </Card.Description>
                                                        <Button
                                                            type="submit"
                                                            disabled={loadingUpdate}
                                                            onClick={(e) => {
                                                                handleUpdatePatient(e, updatedPatient);
                                                            }}
                                                        >   Save
                                                        </Button>

                                                    </Card.Content>
                                                </Card>
                                            )}
                                        </Card.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nhsuk-grid-column-one-third" style={{ display: 'flex', justifyContent: 'center' }}>

                            <Card style={{ padding: "10px" }}>

                                <Card.Description>

                                    <Fieldset>
                                        <Radios name="device" inline id="inline-device" hint="Select the target device.">
                                            <Radios.Radio value="android"
                                                default
                                                checked={targetDevice === "android"}
                                                onChange={(e) => {
                                                    setTargetDevice(e.target.value);
                                                }
                                                }
                                            >
                                                Android
                                            </Radios.Radio>
                                            <Radios.Radio value="ios"
                                                onChange={(e) => {
                                                    setTargetDevice(e.target.value);
                                                }
                                                }
                                            >

                                                iOS</Radios.Radio>

                                        </Radios>

                                    </Fieldset>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>


                                        {passUrl?.length > 0 ? (

                                            <div
                                                ref={targetRef}>

                                                <h3>Scan to Download the Pass</h3>

                                                <QRCode

                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: "hand" }}
                                                    value={passUrl ? (passUrl.includes(window.location.origin) ? passUrl : window.location.origin + passUrl) : ""}
                                                    viewBox={`0 0 256 256`}
                                                    onClick={
                                                        () => {
                                                            // navigate to passUrl new tab
                                                            window.open(passUrl, '_blank');
                                                        }
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <h3>Generate pass to view QR code</h3>
                                        )}


                                    </div>
                                </Card.Description>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        disabled={loadingShortUrlCreate}
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleGeneratePass(passData);
                                        }}
                                    >   Re/Generate
                                    </Button>
                                </div>
                                {errorShortUrlCreate && (

                                    <Callout
                                        label="Error"
                                        header="Error!"
                                        dismissTime={5000}
                                        time={new Date().toLocaleTimeString()}
                                        message={errorShortUrlCreate}
                                        mode="error"
                                    />
                                )}

                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ActionLink
                                        asElement="a"
                                        href="#"
                                        secondary
                                        disabled={working}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSendPassEmail(passUrl, currentIdentityUser?.email, currentIdentityUser?.userName);
                                        }}
                                    >
                                        {working ? "Sending..." : "Send Pass by email"}
                                    </ActionLink>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ActionLink
                                        asElement="a"
                                        href="#"
                                        disabled={working}
                                        onClick={() => toPDF()}
                                    >
                                        Print
                                    </ActionLink>
                                </div>

                            </Card>
                            <Modal show={consentModalShow} handleClose={closeModal}>
                                <h2>{consentModalContent?.consentType}</h2>
                                {consentModalContent && (
                                    <SummaryList>
                                        {
                                            Object.entries(consentModalContent)?.map(([key, value]) => (
                                                !consentPropertiesToIgnore.includes(key) &&
                                                <SummaryList.Row>
                                                    <SummaryList.Key>
                                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                                    </SummaryList.Key>
                                                    <SummaryList.Value>
                                                        {value ? value.toString() : "-"}
                                                    </SummaryList.Value>
                                                </SummaryList.Row>
                                            ))
                                        }

                                    </SummaryList>

                                )}
                                <Button
                                    type="submit"
                                    disabled={loadingUpdate}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeModal();
                                    }
                                    }
                                >   I Agree to the above
                                </Button>

                            </Modal>

                            <Modal show={genericModalShow} handleClose={closeGenericModal}>
                                {genericModalContent && (
                                    <SummaryList>
                                        {
                                            Object.entries(genericModalContent)?.map(([key, value]) => (
                                                !genericPropertiesToIgnore.includes(key) &&
                                                <SummaryList.Row>
                                                    <SummaryList.Key>
                                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                                    </SummaryList.Key>
                                                    <SummaryList.Value>
                                                        {value ? value.toString() : "-"}
                                                    </SummaryList.Value>
                                                </SummaryList.Row>
                                            ))
                                        }

                                    </SummaryList>

                                )}
                                <Button
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeGenericModal();
                                    }
                                    }
                                >   Close
                                </Button>
                            </Modal>

                            <ImplantHistoryDrawer
                                patientNHSNumber={updatedPatient.nhsNumber}
                                patientFullName={updatedPatient.forename ? `${updatedPatient.forename} ${updatedPatient.surname}` : ""}
                                isOpen={openDrawer}
                                closeDrawer={() => setOpenDrawer(false)}
                            />



                        </div>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}




