import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Space, Button, Typography, Row, Col, Card } from 'antd';
import { useHistory } from "react-router-dom";
import { PlusOutlined, ArrowLeftOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ListItemsDataTable from "../../components/crud/ListItemsDataTable.component";
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import { fieldEntityActionConfig } from "../../components/crud/utils/fieldEntityActionConfig";

export default function EntityManagementScreen({ entityName }) {
  const { t } = useTranslation();
  const { Title } = Typography;
  const dispatch = useDispatch();
  const history = useHistory();

  const [isListView, setIsListView] = useState(true);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [limit, setLimit] = useState(10);

  const entityConfig = fieldEntityActionConfig[`${entityName}Ids`];
  const [listFilter, setListFilter] = useState({
    query: "",
    page: 1,
    limit: limit,
    filter: entityConfig.primarySearchProp
  });

  const entityListState = useSelector((state) => state[`${entityConfig.singularEntity}List`]);
  const { loading, error, [entityConfig.pluralEntity]: entities, currentPage, totalPages } = entityListState;

  const dtoConfig = useSelector((state) => state[`${entityConfig.singularEntity}Dto`]);
  const { [`${entityConfig.singularEntity}Dto`]: entityDtoConfig } = dtoConfig;

  useEffect(() => {
    dispatch(entityConfig.listEntities(listFilter));
    dispatch(entityConfig.loadDto());
  }, [dispatch, entityConfig, listFilter]);

  const handleCreate = () => {
    setIsListView(false);
    setSelectedEntityId(null);
  };

  const handleEdit = (id) => {
    setIsListView(false);
    setSelectedEntityId(id);
  };

  const handleDelete = (id) => {
    dispatch(entityConfig.deleteEntity(id));
    setListFilter({ ...listFilter, page: 1 });
  };

  const handleBack = () => {
    setIsListView(true);
    setSelectedEntityId(null);
  };

  const renderMobileCard = (item) => (
    <Card
      key={item.id}
      style={{ marginBottom: 16 }}
      actions={[
        <Button icon={<EditOutlined />} onClick={() => handleEdit(item.id)}>Edit</Button>,
        <Button icon={<DeleteOutlined />} onClick={() => handleDelete(item.id)} danger>Delete</Button>
      ]}
    >
      <Card.Meta
        title={item[entityConfig.primarySearchProp]}
        description={
          <Space direction="vertical">
            {entityConfig.summaryProps.map(prop => (
              <span key={prop}>{`${prop}: ${item[prop]}`}</span>
            ))}
          </Space>
        }
      />
    </Card>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
            Return back
          </Button>
          <Title level={3}>{entityName} Management</Title>
        </Space>
      </Col>

      {isListView ? (
        <>
          <Col xs={24}>
            <Button
              type="primary"
              onClick={handleCreate}
              icon={<PlusOutlined />}
              size="large"
              style={{ marginBottom: 16 }}
            >
              {t("Create")} {entityName}
            </Button>
          </Col>
          <Col xs={24}>
            {entityDtoConfig && (
              <ListItemsDataTable
                loading={loading}
                dtoConfig={entityDtoConfig}
                data={entities}
                filter={listFilter}
                currentPage={currentPage}
                totalPages={totalPages}
                limit={limit}
                onFilterChange={setListFilter}
                handleDeleteRow={handleDelete}
                handleEditForm={handleEdit}
                enableActionButtons={true}
                renderMobileCard={renderMobileCard}
              />
            )}
          </Col>
        </>
      ) : (
        <Col xs={24}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button type="text" onClick={handleBack} icon={<ArrowLeftOutlined />}>
              {t("Back to")} {entityName} {t("List")}
            </Button>
            <DynamicFormSP
              currentEntityId={selectedEntityId}
              defaultEditMode={selectedEntityId ? "Edit" : "Create"}
              primarySearchProp={entityConfig.primarySearchProp}
              secondarySearchProp={entityConfig.secondarySearchProp}
              singularEntity={entityConfig.singularEntity}
              pluralEntity={entityConfig.pluralEntity}
              constantPrefix={entityConfig.constantPrefix}
              loadDto={entityConfig.loadDto}
              createEntity={entityConfig.createEntity}
              updateEntity={entityConfig.updateEntity}
              deleteEntity={entityConfig.deleteEntity}
              getEntityDetails={entityConfig.getEntityDetails}
              listEntities={entityConfig.listEntities}
              listEntitiesByIds={entityConfig.listEntitiesByIds}
              onUpdatedEntityChange={() => { }}
              isParent={true}
              fieldsToHide={["Id"]}
              summaryProps={entityConfig.summaryProps}
              enableCreate={true}
              enableSearchBar={false}
              enableActionButtons={true}
            />
          </Space>
        </Col>
      )}
    </Row>
  );
}