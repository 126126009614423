import axios from 'axios';

import {
    AETIOLOGY_LIST_REQUEST,
    AETIOLOGY_LIST_SUCCESS,
    AETIOLOGY_LIST_FAIL,

    AETIOLOGY_DETAILS_REQUEST,
    AETIOLOGY_DETAILS_SUCCESS,
    AETIOLOGY_DETAILS_FAIL,

    AETIOLOGY_DELETE_REQUEST,
    AETIOLOGY_DELETE_SUCCESS,
    AETIOLOGY_DELETE_FAIL,

    AETIOLOGY_CREATE_REQUEST,
    AETIOLOGY_CREATE_SUCCESS,
    AETIOLOGY_CREATE_FAIL,

    AETIOLOGY_UPDATE_REQUEST,
    AETIOLOGY_UPDATE_SUCCESS,
    AETIOLOGY_UPDATE_FAIL,

    AETIOLOGY_REQUEST,
    AETIOLOGY_FAIL,
    AETIOLOGY_RESET,
    AETIOLOGY_SUCCESS,

    AETIOLOGY_DTO_REQUEST,
    AETIOLOGY_DTO_SUCCESS,
    AETIOLOGY_DTO_FAIL,
    AETIOLOGY_DTO_RESET,

    AETIOLOGY_LIST_BYIDS_REQUEST,
    AETIOLOGY_LIST_BYIDS_SUCCESS,
    AETIOLOGY_LIST_BYIDS_FAIL,
    AETIOLOGY_LIST_BYIDS_RESET
    

} from "../constants/aetiologyConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listAetiologies = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: AETIOLOGY_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/aetiology/all', config);

        dispatch({
            type: AETIOLOGY_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: AETIOLOGY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later aetiologies",
        });
    }
}

// list aetiologies by ids
export const listAetiologiesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: AETIOLOGY_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        
        const { data } = await axios.post('/dotnet_api/v1/aetiology/ids/all', ids, config);

        dispatch({
            type: AETIOLOGY_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: AETIOLOGY_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later aetiologies",
        });
    }
}


export const loadAetiologyDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: AETIOLOGY_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/aetiology/aetiologyDto', config);

        dispatch({
            type: AETIOLOGY_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: AETIOLOGY_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getAetiologyDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: AETIOLOGY_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/aetiology/${_id}`, config);

        dispatch({
            type: AETIOLOGY_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: AETIOLOGY_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteAetiology = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AETIOLOGY_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/aetiology/${_id}/`,
            config
        )

        dispatch({
            type: AETIOLOGY_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: AETIOLOGY_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createAetiology = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AETIOLOGY_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/aetiology/`,
            changes,
            config
        )

        dispatch({
            type: AETIOLOGY_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: AETIOLOGY_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadAetiologyBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make aetiology using a local aetiology
export const makeAetiology = (file, aetiologyId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const aetiology = await loadAetiologyBase64(file);
    const aetiology_input = JSON.stringify({ "aetiology": aetiology });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        aetiology_input,
        config
    )
    try {
        dispatch({
            type: AETIOLOGY_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": aetiology }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: AETIOLOGY_SUCCESS
                });
                if (aetiologyId && response.data) {
                    dispatch(updateAetiology(aetiologyId, { aetiology: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: AETIOLOGY_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: AETIOLOGY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateAetiology = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: AETIOLOGY_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/aetiology/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: AETIOLOGY_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: AETIOLOGY_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: AETIOLOGY_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}