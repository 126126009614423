export const SYSTEM_SETTING_LIST_REQUEST = 'SYSTEM_SETTING_LIST_REQUEST'
export const SYSTEM_SETTING_LIST_SUCCESS = 'SYSTEM_SETTING_LIST_SUCCESS'
export const SYSTEM_SETTING_LIST_FAIL = 'SYSTEM_SETTING_LIST_FAIL'

export const SYSTEM_SETTING_DETAILS_REQUEST = 'SYSTEM_SETTING_DETAILS_REQUEST'
export const SYSTEM_SETTING_DETAILS_SUCCESS = 'SYSTEM_SETTING_DETAILS_SUCCESS'
export const SYSTEM_SETTING_DETAILS_FAIL = 'SYSTEM_SETTING_DETAILS_FAIL'

export const SYSTEM_SETTING_UPDATE_REQUEST = 'SYSTEM_SETTING_UPDATE_REQUEST'
export const SYSTEM_SETTING_UPDATE_SUCCESS = 'SYSTEM_SETTING_UPDATE_SUCCESS'
export const SYSTEM_SETTING_UPDATE_FAIL = 'SYSTEM_SETTING_UPDATE_FAIL'
export const SYSTEM_SETTING_UPDATE_RESET = 'SYSTEM_SETTING_UPDATE_RESET'

export const SYSTEM_SETTING_CREATE_REQUEST = 'SYSTEM_SETTING_CREATE_REQUEST'
export const SYSTEM_SETTING_CREATE_SUCCESS = 'SYSTEM_SETTING_CREATE_SUCCESS'
export const SYSTEM_SETTING_CREATE_FAIL = 'SYSTEM_SETTING_CREATE_FAIL'
export const SYSTEM_SETTING_CREATE_RESET = 'SYSTEM_SETTING_CREATE_RESET'

export const SYSTEM_SETTING_DELETE_REQUEST = 'SYSTEM_SETTING_DELETE_REQUEST'
export const SYSTEM_SETTING_DELETE_SUCCESS = 'SYSTEM_SETTING_DELETE_SUCCESS'
export const SYSTEM_SETTING_DELETE_FAIL = 'SYSTEM_SETTING_DELETE_FAIL'
export const SYSTEM_SETTING_DELETE_RESET = 'SYSTEM_SETTING_DELETE_RESET'

export const SYSTEM_SETTING_DTO_REQUEST = 'SYSTEM_SETTING_DTO_REQUEST'
export const SYSTEM_SETTING_DTO_SUCCESS = 'SYSTEM_SETTING_DTO_SUCCESS'
export const SYSTEM_SETTING_DTO_FAIL = 'SYSTEM_SETTING_DTO_FAIL'
