import {

    RATING_LIST_REQUEST,
    RATING_LIST_SUCCESS,
    RATING_LIST_FAIL,

    RATING_DETAILS_REQUEST,
    RATING_DETAILS_SUCCESS,
    RATING_DETAILS_FAIL,

    RATING_DELETE_REQUEST,
    RATING_DELETE_SUCCESS,
    RATING_DELETE_FAIL,

    RATING_CREATE_REQUEST,
    RATING_CREATE_SUCCESS,
    RATING_CREATE_FAIL,
    RATING_CREATE_RESET,

    RATING_UPDATE_REQUEST,
    RATING_UPDATE_SUCCESS,
    RATING_UPDATE_FAIL,
    RATING_UPDATE_RESET,

    SET_CURRENT_PAGE,

    RATING_DTO_FAIL,
    RATING_DTO_REQUEST,
    RATING_DTO_SUCCESS,
    RATING_DTO_RESET,

    RATING_LIST_BYIDS_REQUEST,
    RATING_LIST_BYIDS_SUCCESS,
    RATING_LIST_BYIDS_FAIL,
    RATING_LIST_BYIDS_RESET

} from "../constants/ratingConstants";

const initialState = {
    ratings: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const ratingListReducer = (
    state = {
        ratings: []
    }, action) => {
    switch (action.type) {
        case RATING_LIST_REQUEST:
            return { loading: true, ratings: [] };
        case RATING_LIST_SUCCESS:
            // our state will have a list of ratings after the request
            return {
                loading: false,
                ratings: action.payload.ratings,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case RATING_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const ratingListByIDsReducer = (
    state = {
        ratings: []
    }, action) => {
    switch (action.type) {
        case RATING_LIST_BYIDS_REQUEST:
            return { loading: true, ratings: [] };
        case RATING_LIST_BYIDS_SUCCESS:
            // our state will have a list of ratings after the request
            return { loading: false, ratings: action.payload };
        case RATING_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case RATING_LIST_BYIDS_RESET:
            return { ratings: [] };
        default:
            return state;
    }
}


export const ratingDetailsReducer = (
    state = {
        rating: { comments: [] }
    }, action) => {
    switch (action.type) {
        case RATING_DETAILS_REQUEST:
            return { loading: true, ...state };
        case RATING_DETAILS_SUCCESS:
            // our state will have a list of ratings after the request
            return { loading: false, rating: action.payload };
        case RATING_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const ratingDtoReducer = (state = { ratingDto: {} }, action) => {
    switch (action.type) {
        case RATING_DTO_REQUEST:
            return { loading: true, ratingDto: {} };
        case RATING_DTO_SUCCESS:
            return { loading: false, ratingDto: action.payload };
        case RATING_DTO_FAIL:
            return { loading: false, error: action.payload };
        case RATING_DTO_RESET:
            return { ratingDto: {} };
        default:
            return state;
    }
}

export const ratingDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case RATING_DELETE_REQUEST:
            return { loading: true }

        case RATING_DELETE_SUCCESS:
            return { loading: false, success: true }

        case RATING_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const ratingCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case RATING_CREATE_REQUEST:
            return { loading: true }

        case RATING_CREATE_SUCCESS:
            return { loading: false, success: true, rating: action.payload }

        case RATING_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case RATING_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const ratingUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case RATING_UPDATE_REQUEST:
            return { loading: true }

        case RATING_UPDATE_SUCCESS:
            return { loading: false, success: true, rating: action.payload }

        case RATING_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case RATING_UPDATE_RESET:
            return { rating: {} }

        default:
            return state
    }
}