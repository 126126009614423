import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Upload, Modal, message, Space, Typography, Tooltip, Card, Progress, Popconfirm, Image, Spin, Collapse, Button } from 'antd';
import { InboxOutlined, FilePdfOutlined, FileImageOutlined, EyeOutlined, DeleteOutlined, CaretRightOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Text } = Typography;
const { Panel } = Collapse;

const FileUploaderAndViewer = ({ value = "[]", onChange, category, ownerId, ownerName, bucketName }) => {

  const { t } = useTranslation();

  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [imagePreviewVisible, setImagePreviewVisible] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (value && value !== "[]") {
      const fileIds = JSON.parse(value);
      fetchFileDetails(fileIds);
    }
  }, [value]);

  const fetchFileDetails = async (fileIds) => {
    // if (fileIds.length === 0) return;
    if(fileIds.length === 0) return setFileList([]);
    try {
      const response = await axios.post(`/dotnet_api/v1/storage/objects/${bucketName}`, fileIds, {
        headers: { Authorization: `Bearer ${userInfo.token}` }
      });
      const uniqueFiles = response.data.reduce((acc, file) => {
        if (!acc.find(f => f.id === file.id)) {
          acc.push({
            uid: file.id,
            name: file.name,
            status: 'done',
            url: file.url,
            type: file.contentType,
            size: file.size,
            ...file
          });
        }
        return acc;
      }, []);
      setFileList(uniqueFiles);
    } catch (error) {
      message.error('Failed to fetch file details');
    }
  };

  const handleClearAll = () => {
  
        setFileList([]);
        onChange("[]");
  };

  const handleUpload = async (info) => {
    const { status, response, name } = info.file;

    if (status === 'uploading') {
      setUploadingFile(info.file);
      const percent = info.file.percent.toFixed(2);
      info.file.percent = percent;
      setFileList(prevList => {
        const updatedList = [...prevList];
        const fileIndex = updatedList.findIndex(item => item.uid === info.file.uid);
        if (fileIndex > -1) {
          updatedList[fileIndex] = { ...updatedList[fileIndex], percent };
        } else {
          updatedList.push({ ...info.file, percent });
        }
        return updatedList;
      });
    }

    if (status === 'done') {
      setUploadingFile(null);
      setFileList(prevList => [
        ...prevList.filter(file => file.uid !== info.file.uid),
        {
          uid: response.id,
          name: response.name,
          status: 'done',
          url: response.url,
          type: response.contentType,
          size: response.size,
          ...response
        }
      ]);

      let newFileIds;
      try {
        const parsedValue = JSON.parse(value);
        newFileIds = Array.isArray(parsedValue) ? [...parsedValue, response.id] : [response.id];
      } catch (error) {
        console.error("Error parsing JSON value:", error);
        newFileIds = [response.id];
      }
      
      onChange(JSON.stringify(newFileIds));
      message.success(`${name} file uploaded successfully.`);
    } else if (status === 'error') {
      setUploadingFile(null);
      message.error(`${name} file upload failed.`);
    }
  };

  const handleRemove = (file) => {
    setFileList(prevList => prevList.filter(item => item.uid !== file.uid));

    let newFileIds;
    try {
      const parsedValue = JSON.parse(value);
      newFileIds = Array.isArray(parsedValue) ? parsedValue.filter(fileName => fileName !== file.id) : [];
    } catch (error) {
      console.error("Error parsing JSON value:", error);
      newFileIds = [];
    }
    
    onChange(JSON.stringify(newFileIds));

    if (file.url) {
      deleteFile(file);
    }
  };
  
  const deleteFile = async (file) => {
    try {
      await axios.delete(`/dotnet_api/v1/storage/file`, {
        params: {
          bucketName: bucketName,
          fileName: file.id
        },
        headers: { Authorization: `Bearer ${userInfo.token}` }
      });
      message.success(`${file.name} deleted successfully.`);
    } catch (error) {
      message.error(`Failed to delete ${file.name}.`);
    }
  };

  const handlePreview = (file) => {
    // Ensure the URL uses HTTPS
    const secureUrl = file.url.replace('http://', 'https://');
  
    if (file.type === 'application/pdf') {
      setPreviewFile({...file, url: secureUrl});
      setPreviewVisible(true);
    } else if (file.type.startsWith('image/')) {
      setImagePreviewUrl(secureUrl);
      setImagePreviewVisible(true);
    } else {
      // For other file types, open in a new tab but still use the secure URL
      window.open(secureUrl, '_blank');
    }
  };
  
  
  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', category);
    formData.append('ownerId', ownerId);
    formData.append('ownerName', ownerName);
    formData.append('bucketName', bucketName);

    try {
      const response = await axios.post('/dotnet_api/v1/storage/upload', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        },
        onUploadProgress: (progressEvent) => {
          const percent = (progressEvent.loaded / progressEvent.total) * 100;
          onProgress({ percent });
        }
      });
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderIcon = (file) => {
    if (file.type === 'application/pdf') {
      return <FilePdfOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />;
    } else if (file.type.startsWith('image/')) {
      return <FileImageOutlined style={{ fontSize: '24px', color: '#1890ff' }} />;
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
    else return (bytes / 1048576).toFixed(2) + ' MB';
  };

  const renderMetadata = (file) => (
    <>
      <p><strong>{t('Category')}:</strong> {file.category}</p>
      <p><strong>{t('Content Type')}:</strong> {file.type}</p>
      <p><strong>{t('Size')}:</strong> {formatFileSize(file.size)}</p>
      <p><strong>{t('Extension')}:</strong> {file.extension}</p>
      <p><strong>{t('Created')}:</strong> {new Date(file.created).toLocaleString()}</p>
      <p><strong>{t('Last Modified')}:</strong> {new Date(file.lastModified).toLocaleString()}</p>
      <p><strong>{t('Owner ID')}:</strong> {file.ownerId}</p>
      <p><strong>{t('Owner Name')}:</strong> {file.ownerName}</p>
    </>
  );

  return (
    <Card>
      <Dragger
        customRequest={customRequest}
        showUploadList={false}
        onChange={handleUpload}
        onRemove={handleRemove}
        fileList={fileList}
        accept=".pdf,.jpg,.jpeg,.png,.docx"
        multiple={true}
        maxCount={10}
        beforeUpload={(file) => {
          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            message.error(t('File must be smaller than 5MB!'));
          }
          return isLt5M;
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
        <p className="ant-upload-hint">
          {t('Support for PDF, DOCX, and images. Max 5MB per file.')}
        </p>
      </Dragger>

      <Popconfirm
        title={t('Are you sure you want to clear all files?')}
        onConfirm={handleClearAll}
        okText={t('Yes')}
        cancelText={t('No')}
      >
        <Button type="danger" style={{ marginTop: 10 }} icon={<DeleteOutlined />}>{t('Clear All')}</Button>
      </Popconfirm>

      <Collapse
        ghost
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        {fileList?.map(file => (
          <Panel
            key={file.uid}
            header={
              <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                  {renderIcon(file)}
                  <Tooltip title={t('Size: {{size}}', { size: formatFileSize(file.size) })}>
                    <Text ellipsis style={{ maxWidth: 150 }}>{file.name}</Text>
                  </Tooltip>
                </Space>
                <Space>
                  {file.status === 'uploading' ? (
                    <Space>
                      <Progress percent={file.percent} size="small" style={{ width: 100 }} />
                      <Spin />
                    </Space>
                  ) : (
                    <>
                      <Text type="secondary">{formatFileSize(file.size)}</Text>
                      <Tooltip title={t('Preview')}>
                        <EyeOutlined onClick={(e) => {
                          e.stopPropagation();
                          handlePreview(file);
                        }} />
                      </Tooltip>
                      <Popconfirm
                        title={t('Are you sure you want to delete this file?')}
                        onConfirm={(e) => { e.stopPropagation(); handleRemove(file); }}
                        okText={t('Yes')}
                        cancelText={t('No')}
                      >
                        <DeleteOutlined onClick={(e) => e.stopPropagation()} />
                      </Popconfirm>
                    </>
                  )}
                </Space>
              </Space>
            }
          >
            {renderMetadata(file)}
          </Panel>
        ))}
      </Collapse>

      <Modal
        visible={previewVisible}
        title={
          <Space>
            {previewFile?.name}
            <Button 
              icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} 
              onClick={toggleFullscreen}
            />
          </Space>
        }
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width={isFullscreen ? "100%" : "70%"}
        style={isFullscreen ? { top: 0, paddingBottom: 0 } : {}}
        bodyStyle={isFullscreen ? { height: 'calc(100vh - 108px)', overflow: 'auto', padding: 0 } : { padding: 0 }}
      >
        {previewFile && previewFile.type === 'application/pdf' && (
          <iframe
            src={previewFile.url}
            style={{ width: '100%', height: isFullscreen ? 'calc(100vh - 120px)' : '500px' }}
            title={t('PDF Viewer')}
          />
        )}
      </Modal>

      <Image
        style={{ display: 'none' }}
        src={imagePreviewUrl}
        preview={{
          visible: imagePreviewVisible,
          onVisibleChange: (vis) => setImagePreviewVisible(vis),
        }}
      />
    </Card>
  );
};

export default FileUploaderAndViewer;
