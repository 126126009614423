import React, { useState, useEffect, useCallback } from 'react';
import { Select, DatePicker, TimePicker, InputNumber, Button, Space, Typography, Input, Row, Col, Alert } from 'antd';
import { PlusOutlined, MinusCircleOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const QualityControlFrequencyInput = ({ inputValue, onChange }) => {
  const [frequencyType, setFrequencyType] = useState('Weekly');
  const [dateRange, setDateRange] = useState([null, null]);
  const [weeklyConfig, setWeeklyConfig] = useState([]);
  const [hiddenValue, setHiddenValue] = useState(inputValue);
  const [localValue, setLocalValue] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    let parsedValue = inputValue;
    if (typeof inputValue === 'string') {
      try {
        parsedValue = JSON.parse(inputValue);
      } catch (error) {
        console.error('Error parsing QualityControl frequency value:', error);
        return;
      }
    }
  
    if (parsedValue) {
      setFrequencyType(parsedValue.FrequencyType || 'Weekly');
      setDateRange([
        parsedValue.StartDate ? dayjs(parsedValue.StartDate) : null,
        parsedValue.EndDate ? dayjs(parsedValue.EndDate) : null,
      ]);
      setWeeklyConfig(parsedValue.WeeklyConfig?.DaysOfWeek || []);
      setHiddenValue(JSON.stringify(parsedValue));
      setLocalValue(parsedValue);
    }
  }, [inputValue]);
  
  const updateValue = useCallback(() => {
    const newValue = {
      FrequencyType: frequencyType,
      StartDate: dateRange[0]?.toISOString() || null,
      EndDate: dateRange[1]?.toISOString() || null,
      WeeklyConfig: {
        DaysOfWeek: weeklyConfig,
      },
    };
    setLocalValue(newValue);
    setHasChanges(true);
  }, [frequencyType, dateRange, weeklyConfig]);

  useEffect(() => {
    updateValue();
  }, [frequencyType, dateRange, weeklyConfig, updateValue]);

  const handleValidateChanges = () => {
    const newValueString = JSON.stringify(localValue);
    setHiddenValue(newValueString);
    onChange({ target: { value: newValueString } });
    setHasChanges(false);
  };

  const handleAddDay = () => {
    setWeeklyConfig([...weeklyConfig, { Day: 'Monday', TimeHHmm: '09:00', Duration: 60 }]);
  };

  const handleRemoveDay = (index) => {
    setWeeklyConfig(weeklyConfig.filter((_, i) => i !== index));
  };

  const handleDayChange = (index, field, value) => {
    setWeeklyConfig(weeklyConfig?.map((day, i) => i === index ? { ...day, [field]: value } : day));
  };

  const handleDateRangeChange = (dates, dateStrings) => {
    setDateRange(dates);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Select value={frequencyType} onChange={setFrequencyType} style={{ width: '100%' }}>
        <Option value="Weekly">Weekly</Option>
        <Option value="Bi-Weekly">Bi-Weekly</Option>
      </Select>

      <RangePicker
        value={dateRange}
        onChange={handleDateRangeChange}
        style={{ width: '100%' }}
        format="YYYY-MM-DD HH:mm"
        showTime={{ format: 'HH:mm' }}
        allowClear={true}
      />

      {(frequencyType === 'Weekly' || frequencyType === 'Bi-Weekly') && (
        <>
          {weeklyConfig?.map((day, index) => (
            <Row key={index} gutter={8} align="middle" style={{ marginBottom: 8 }}>
              <Col>
                <Select
                  value={day.Day}
                  onChange={(value) => handleDayChange(index, 'Day', value)}
                  style={{ width: 120 }}
                >
                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']?.map((d) => (
                    <Option key={d} value={d}>{d}</Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <TimePicker
                  value={day.TimeHHmm ? dayjs(day.TimeHHmm, 'HH:mm') : null}
                  format="HH:mm"
                  onChange={(time) => handleDayChange(index, 'TimeHHmm', time ? time.format('HH:mm') : '')}
                  allowClear
                />
              </Col>
              <Col>
                <InputNumber
                  style={{ width: 170 }}
                  min={1}
                  value={day.Duration}
                  onChange={(value) => handleDayChange(index, 'Duration', value)}
                  addonAfter="minutes"
                />
              </Col>
              <Col>
                <Button icon={<MinusCircleOutlined />} onClick={() => handleRemoveDay(index)} />
              </Col>
            </Row>
          ))}
          <Button type="dashed" onClick={handleAddDay} block icon={<PlusOutlined />}>
            Add Day
          </Button>
        </>
      )}

      {hasChanges && (
        <Alert
          message="Unsaved Changes"
          description="Please remember to validate your changes by clicking the 'Validate Changes' button below."
          type="info"
          showIcon
          icon={<InfoCircleOutlined />}
        />
      )}

      <Row justify="end">
        {hasChanges && (
          <Button size='small' type="primary" onClick={handleValidateChanges} icon={<CheckOutlined />}>
            Validate Changes
          </Button>
        )}
      </Row>

      <Input type="hidden" value={hiddenValue} />
    </Space>
  );
};

export default QualityControlFrequencyInput;