import {

    CONSTANT_LIST_REQUEST,
    CONSTANT_LIST_SUCCESS,
    CONSTANT_LIST_FAIL,

    CONSTANT_DETAILS_REQUEST,
    CONSTANT_DETAILS_SUCCESS,
    CONSTANT_DETAILS_FAIL,

    CONSTANT_DELETE_REQUEST,
    CONSTANT_DELETE_SUCCESS,
    CONSTANT_DELETE_FAIL,

    CONSTANT_CREATE_REQUEST,
    CONSTANT_CREATE_SUCCESS,
    CONSTANT_CREATE_FAIL,
    CONSTANT_CREATE_RESET,

    CONSTANT_UPDATE_REQUEST,
    CONSTANT_UPDATE_SUCCESS,
    CONSTANT_UPDATE_FAIL,
    CONSTANT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    CONSTANT_DTO_FAIL,
    CONSTANT_DTO_REQUEST,
    CONSTANT_DTO_SUCCESS,
    CONSTANT_DTO_RESET,

    CONSTANT_LIST_BYIDS_REQUEST,
    CONSTANT_LIST_BYIDS_SUCCESS,
    CONSTANT_LIST_BYIDS_FAIL,
    CONSTANT_LIST_BYIDS_RESET

} from "../constants/constantConstants";

const initialState = {
    constants: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const constantListReducer = (
    state = {
        constants: []
    }, action) => {
    switch (action.type) {
        case CONSTANT_LIST_REQUEST:
            return { loading: true, constants: [] };
        case CONSTANT_LIST_SUCCESS:
            // our state will have a list of constants after the request
            return {
                loading: false,
                constants: action.payload.constants,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case CONSTANT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const constantListByIDsReducer = (
    state = {
        constants: []
    }, action) => {
    switch (action.type) {
        case CONSTANT_LIST_BYIDS_REQUEST:
            return { loading: true, constants: [] };
        case CONSTANT_LIST_BYIDS_SUCCESS:
            // our state will have a list of constants after the request
            return { loading: false, constants: action.payload };
        case CONSTANT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case CONSTANT_LIST_BYIDS_RESET:
            return { constants: [] };
        default:
            return state;
    }
}


export const constantDetailsReducer = (
    state = {
        constant: { comments: [] }
    }, action) => {
    switch (action.type) {
        case CONSTANT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case CONSTANT_DETAILS_SUCCESS:
            // our state will have a list of constants after the request
            return { loading: false, constant: action.payload };
        case CONSTANT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const constantDtoReducer = (state = { constantDto: {} }, action) => {
    switch (action.type) {
        case CONSTANT_DTO_REQUEST:
            return { loading: true, constantDto: {} };
        case CONSTANT_DTO_SUCCESS:
            return { loading: false, constantDto: action.payload };
        case CONSTANT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case CONSTANT_DTO_RESET:
            return { constantDto: {} };
        default:
            return state;
    }
}

export const constantDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANT_DELETE_REQUEST:
            return { loading: true }

        case CONSTANT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CONSTANT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const constantCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANT_CREATE_REQUEST:
            return { loading: true }

        case CONSTANT_CREATE_SUCCESS:
            return { loading: false, success: true, constant: action.payload }

        case CONSTANT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case CONSTANT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const constantUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case CONSTANT_UPDATE_REQUEST:
            return { loading: true }

        case CONSTANT_UPDATE_SUCCESS:
            return { loading: false, success: true, constant: action.payload }

        case CONSTANT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case CONSTANT_UPDATE_RESET:
            return { constant: {} }

        default:
            return state
    }
}