import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { createTestInventoryLine, updateTestInventoryLine, deleteTestInventoryLine, getTestInventoryLineDetails, listTestInventoryLines, listTestInventoryLinesByIds, loadTestInventoryLineDto } from '../../actions/testInventoryLineActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function TestInventoryLineListScreen({ history }) {

  useEffect(() => {
    document.title = "TestInventoryLine";
  }, []);

  const dispatch = useDispatch();

  const [selectedTestInventoryLineId, setSelectedTestInventoryLineId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [testInventoryLineListFilter, setTestInventoryLineListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'Name' });

  // Load testInventoryLine list from the state
  const testInventoryLineList = useSelector((state) => state.testInventoryLineList);
  const { loading, error, testInventoryLines, currentPage, totalPages } = testInventoryLineList;

  // Load testInventoryLine DTO from the state
  const testInventoryLineDto = useSelector((state) => state.testInventoryLineDto);
  const { testInventoryLineDto: testInventoryLineDtoConfig } = testInventoryLineDto;

  // Use effect to load the testInventoryLine DTO and the testInventoryLine list
  useEffect(() => {
    dispatch(loadTestInventoryLineDto());
    dispatch(listTestInventoryLines(testInventoryLineListFilter));
  }, [dispatch, testInventoryLineListFilter]);

  // Handle delete testInventoryLine
  const handleDelete = (id) => {
    dispatch(deleteTestInventoryLine(id));
    setTestInventoryLineListFilter({ query: '', page: 1, limit: 10, filter: 'Name' });
  };

  // Handle edit testInventoryLine
  const handleEditForm = (id) => {
    setSelectedTestInventoryLineId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  // Handle canceling the dynamic form
  const handleCancelForm = () => {
    setSelectedTestInventoryLineId(null);
    setShowDynamicForm(false);
  };

  // Handle create testInventoryLine
  const handleCreateTestInventoryLine = () => {
    setSelectedTestInventoryLineId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>TestInventoryLine Management</h2>
      </Space>
      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreateTestInventoryLine}
            icon={<PlusOutlined />}
            size="large"
          >
            Create TestInventoryLine
          </Button>

          <ListItemsDataTable
            loading={loading}
            dtoConfig={testInventoryLineDtoConfig}
            data={testInventoryLines}
            filter={testInventoryLineListFilter}
            onFilterChange={setTestInventoryLineListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={(id) => { handleDelete(id) }}
            onStartEditRow={(id) => { setSelectedTestInventoryLineId(id) }}
            handleSaveRow={(updatedRow) => {
              dispatch(updateTestInventoryLine(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getTestInventoryLineDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}


      {showDynamicForm && (

        <React.Fragment>
          <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to TestInventoryLines
            </Button>

          </Space>

          <DynamicFormSP

            currentEntityId={selectedTestInventoryLineId}
            selectedItems={null}
            selectedEntityIds={null}
            listingMode='allEntities'
            defaultEditMode={editMode}
            primarySearchProp={"surname"}
            secondarySearchProp={"forename"}
            singularEntity={"testInventoryLine"}
            pluralEntity={"testInventoryLines"}
            constantPrefix={"TEST_INVENTORY_LINE"}
            onSelectedItems={(selectedItems) => { }}
            loadDto={loadTestInventoryLineDto}
            createEntity={createTestInventoryLine}
            updateEntity={updateTestInventoryLine}
            deleteEntity={deleteTestInventoryLine}
            getEntityDetails={getTestInventoryLineDetails}
            listEntities={listTestInventoryLines}
            listEntitiesByIds={listTestInventoryLinesByIds}
            onSelectedEntitiesChange={(e) => { }}
            onUpdatedEntityChange={(e) => { }}
            onNewEntityChange={(e) => { }}
            isParent={true}
            fieldsToHide={['Id', 'TestName', 'TestCategoryName']}
            summaryProps={['name', 'lotCode', 'barcode', 'price']}

            // enable buttons
            enableCreate={true}
            enableSearchBar={false}




          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

}