import {

    NOTE_LIST_REQUEST,
    NOTE_LIST_SUCCESS,
    NOTE_LIST_FAIL,

    NOTE_DETAILS_REQUEST,
    NOTE_DETAILS_SUCCESS,
    NOTE_DETAILS_FAIL,

    NOTE_DELETE_REQUEST,
    NOTE_DELETE_SUCCESS,
    NOTE_DELETE_FAIL,

    NOTE_CREATE_REQUEST,
    NOTE_CREATE_SUCCESS,
    NOTE_CREATE_FAIL,
    NOTE_CREATE_RESET,

    NOTE_UPDATE_REQUEST,
    NOTE_UPDATE_SUCCESS,
    NOTE_UPDATE_FAIL,
    NOTE_UPDATE_RESET,

    SET_CURRENT_PAGE,

    NOTE_DTO_FAIL,
    NOTE_DTO_REQUEST,
    NOTE_DTO_SUCCESS,
    NOTE_DTO_RESET,

    NOTE_LIST_BYIDS_REQUEST,
    NOTE_LIST_BYIDS_SUCCESS,
    NOTE_LIST_BYIDS_FAIL,
    NOTE_LIST_BYIDS_RESET

} from "../constants/noteConstants";

const initialState = {
    notes: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const noteListReducer = (
    state = {
        notes: []
    }, action) => {
    switch (action.type) {
        case NOTE_LIST_REQUEST:
            return { loading: true, notes: [] };
        case NOTE_LIST_SUCCESS:
            // our state will have a list of notes after the request
            return {
                loading: false,
                notes: action.payload.notes,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case NOTE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const noteListByIDsReducer = (
    state = {
        notes: []
    }, action) => {
    switch (action.type) {
        case NOTE_LIST_BYIDS_REQUEST:
            return { loading: true, notes: [] };
        case NOTE_LIST_BYIDS_SUCCESS:
            // our state will have a list of notes after the request
            return { loading: false, notes: action.payload };
        case NOTE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case NOTE_LIST_BYIDS_RESET:
            return { notes: [] };
        default:
            return state;
    }
}


export const noteDetailsReducer = (
    state = {
        note: { comments: [] }
    }, action) => {
    switch (action.type) {
        case NOTE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case NOTE_DETAILS_SUCCESS:
            // our state will have a list of notes after the request
            return { loading: false, note: action.payload };
        case NOTE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const noteDtoReducer = (state = { noteDto: {} }, action) => {
    switch (action.type) {
        case NOTE_DTO_REQUEST:
            return { loading: true, noteDto: {} };
        case NOTE_DTO_SUCCESS:
            return { loading: false, noteDto: action.payload };
        case NOTE_DTO_FAIL:
            return { loading: false, error: action.payload };
        case NOTE_DTO_RESET:
            return { noteDto: {} };
        default:
            return state;
    }
}

export const noteDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTE_DELETE_REQUEST:
            return { loading: true }

        case NOTE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case NOTE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const noteCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTE_CREATE_REQUEST:
            return { loading: true }

        case NOTE_CREATE_SUCCESS:
            return { loading: false, success: true, note: action.payload }

        case NOTE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case NOTE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const noteUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case NOTE_UPDATE_REQUEST:
            return { loading: true }

        case NOTE_UPDATE_SUCCESS:
            return { loading: false, success: true, note: action.payload }

        case NOTE_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case NOTE_UPDATE_RESET:
            return { note: {} }

        default:
            return state
    }
}