export const CONSTANT_LIST_REQUEST = 'CONSTANT_LIST_REQUEST'
export const CONSTANT_LIST_SUCCESS = 'CONSTANT_LIST_SUCCESS'
export const CONSTANT_LIST_FAIL = 'CONSTANT_LIST_FAIL'


export const CONSTANT_DETAILS_REQUEST = 'CONSTANT_DETAILS_REQUEST'
export const CONSTANT_DETAILS_SUCCESS = 'CONSTANT_DETAILS_SUCCESS'
export const CONSTANT_DETAILS_FAIL = 'CONSTANT_DETAILS_FAIL'


export const CONSTANT_DELETE_REQUEST = 'CONSTANT_DELETE_REQUEST'
export const CONSTANT_DELETE_SUCCESS = 'CONSTANT_DELETE_SUCCESS'
export const CONSTANT_DELETE_FAIL = 'CONSTANT_DELETE_FAIL'

export const CONSTANT_CREATE_REQUEST = 'CONSTANT_CREATE_REQUEST'
export const CONSTANT_CREATE_SUCCESS = 'CONSTANT_CREATE_SUCCESS'
export const CONSTANT_CREATE_FAIL = 'CONSTANT_CREATE_FAIL'
export const CONSTANT_CREATE_RESET = 'CONSTANT_CREATE_RESET'

export const CONSTANT_UPDATE_REQUEST = 'CONSTANT_UPDATE_REQUEST'
export const CONSTANT_UPDATE_SUCCESS = 'CONSTANT_UPDATE_SUCCESS'
export const CONSTANT_UPDATE_FAIL = 'CONSTANT_UPDATE_FAIL'
export const CONSTANT_UPDATE_RESET = 'CONSTANT_UPDATE_RESET'

export const CONSTANT_REQUEST = 'CONSTANT_REQUEST'
export const CONSTANT_SUCCESS = 'CONSTANT_SUCCESS'
export const CONSTANT_FAIL = 'CONSTANT_FAIL'
export const CONSTANT_RESET = 'CONSTANT_RESET'

export const CONSTANT_COUNT_REQUEST = "CONSTANT_COUNT_REQUEST";
export const CONSTANT_COUNT_SUCCESS = "CONSTANT_COUNT_SUCCESS";
export const CONSTANT_COUNT_FAIL = "CONSTANT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const CONSTANT_DTO_REQUEST = 'CONSTANT_DTO_REQUEST';
export const CONSTANT_DTO_SUCCESS = 'CONSTANT_DTO_SUCCESS';
export const CONSTANT_DTO_FAIL = 'CONSTANT_DTO_FAIL';
export const CONSTANT_DTO_RESET = 'CONSTANT_DTO_RESET';

export const CONSTANT_LIST_BYIDS_REQUEST = 'CONSTANT_LIST_BYIDS_REQUEST';
export const CONSTANT_LIST_BYIDS_SUCCESS = 'CONSTANT_LIST_BYIDS_SUCCESS';
export const CONSTANT_LIST_BYIDS_FAIL = 'CONSTANT_LIST_BYIDS_FAIL';
export const CONSTANT_LIST_BYIDS_RESET = 'CONSTANT_LIST_BYIDS_RESET';