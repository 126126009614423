import {
    CONTROL_MATERIAL_LIST_REQUEST,
    CONTROL_MATERIAL_LIST_SUCCESS,
    CONTROL_MATERIAL_LIST_FAIL,
    CONTROL_MATERIAL_DETAILS_REQUEST,
    CONTROL_MATERIAL_DETAILS_SUCCESS,
    CONTROL_MATERIAL_DETAILS_FAIL,
    CONTROL_MATERIAL_DELETE_REQUEST,
    CONTROL_MATERIAL_DELETE_SUCCESS,
    CONTROL_MATERIAL_DELETE_FAIL,
    CONTROL_MATERIAL_CREATE_REQUEST,
    CONTROL_MATERIAL_CREATE_SUCCESS,
    CONTROL_MATERIAL_CREATE_FAIL,
    CONTROL_MATERIAL_UPDATE_REQUEST,
    CONTROL_MATERIAL_UPDATE_SUCCESS,
    CONTROL_MATERIAL_UPDATE_FAIL,
    CONTROL_MATERIAL_DTO_REQUEST,
    CONTROL_MATERIAL_DTO_SUCCESS,
    CONTROL_MATERIAL_DTO_FAIL,
    CONTROL_MATERIAL_DTO_RESET,
    CONTROL_MATERIAL_LIST_BYIDS_REQUEST,
    CONTROL_MATERIAL_LIST_BYIDS_SUCCESS,
    CONTROL_MATERIAL_LIST_BYIDS_FAIL,
    CONTROL_MATERIAL_LIST_BYIDS_RESET,
} from '../constants/controlMaterialConstants';

export const controlMaterialListReducer = (state = { controlMaterials: [] }, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_LIST_REQUEST:
            return { loading: true, controlMaterials: [] };
        case CONTROL_MATERIAL_LIST_SUCCESS:
            return {
                loading: false,
                controlMaterials: action.payload.controlMaterials,
                page: action.payload.page,
                pages: action.payload.pages,
            };
        case CONTROL_MATERIAL_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const controlMaterialDetailsReducer = (state = { controlMaterial: {} }, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_DETAILS_REQUEST:
            return { ...state, loading: true };
        case CONTROL_MATERIAL_DETAILS_SUCCESS:
            return { loading: false, controlMaterial: action.payload };
        case CONTROL_MATERIAL_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const controlMaterialDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_DELETE_REQUEST:
            return { loading: true };
        case CONTROL_MATERIAL_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CONTROL_MATERIAL_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const controlMaterialCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_CREATE_REQUEST:
            return { loading: true };
        case CONTROL_MATERIAL_CREATE_SUCCESS:
            return { loading: false, success: true, controlMaterial: action.payload };
        case CONTROL_MATERIAL_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const controlMaterialUpdateReducer = (state = { controlMaterial: {} }, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_UPDATE_REQUEST:
            return { loading: true };
        case CONTROL_MATERIAL_UPDATE_SUCCESS:
            return { loading: false, success: true, controlMaterial: action.payload };
        case CONTROL_MATERIAL_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const controlMaterialDtoReducer = (state = { controlMaterialDto: {} }, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_DTO_REQUEST:
            return { loading: true };
        case CONTROL_MATERIAL_DTO_SUCCESS:
            return { loading: false, controlMaterialDto: action.payload };
        case CONTROL_MATERIAL_DTO_FAIL:
            return { loading: false, error: action.payload };
        case CONTROL_MATERIAL_DTO_RESET:
            return { controlMaterialDto: {} };
        default:
            return state;
    }
};

export const controlMaterialListByIdsReducer = (state = { controlMaterials: [] }, action) => {
    switch (action.type) {
        case CONTROL_MATERIAL_LIST_BYIDS_REQUEST:
            return { loading: true, controlMaterials: [] };
        case CONTROL_MATERIAL_LIST_BYIDS_SUCCESS:
            return { loading: false, controlMaterials: action.payload };
        case CONTROL_MATERIAL_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case CONTROL_MATERIAL_LIST_BYIDS_RESET:
            return { controlMaterials: [] };
        default:
            return state;
    }
};