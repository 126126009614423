import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getIdentityUserDetails, 
  updateIdentityUser, 
  resetPassword, 
  getAllRoles
} from '../../actions/userActions';
import { listBusinessOrganisations, sendEmail } from '../../actions/businessOrganisationActions';
import { 
  Button, 
  Card, 
  Alert, 
  Space, 
  Form, 
  Input, 
  Select, 
  Row, 
  Col, 
  notification, 
  Drawer, 
  Modal, 
  Spin 
} from 'antd';
import { 
  ArrowLeftOutlined, 
  CheckCircleTwoTone, 
  SyncOutlined, 
  EditOutlined, 
  MailOutlined 
} from '@ant-design/icons';
import { 
  UPDATE_USER_PASSWORD_RESET, 
  IDENTITY_USER_UPDATE_RESET, 
  IDENTITY_USER_DETAILS_RESET 
} from '../../constants/userConstants';
import RolePermissionManager from '../../components/userRole/RolePermissionManager.component';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function EditUserScreen({ match, history }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userId = match.params._id;

    const [newIdentityUser, setNewIdentityUser] = useState({});
    const [newPassword, setNewPassword] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [emailSending, setEmailSending] = useState(false);
    const [emailForm] = Form.useForm();

    const openNotification = (placement, description) => {
        api.info({
            message: `Success`,
            description: description,
            placement,
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        });
    };

    useEffect(() => {
        dispatch({ type: IDENTITY_USER_DETAILS_RESET });
        dispatch({ type: IDENTITY_USER_UPDATE_RESET });
        dispatch({ type: UPDATE_USER_PASSWORD_RESET });
        dispatch(listBusinessOrganisations());
        dispatch(getAllRoles());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getIdentityUserDetails(userId));
    }, [dispatch, userId]);

    const identityUserDetails = useSelector(state => state.identityUserDetails);
    const { loading, error, identityUser } = identityUserDetails;

    const identityUserUpdate = useSelector(state => state.identityUserUpdate);
    const { loading: loadingUpdateIdentityUser, error: errorUpdateIdentityUser, success: successUpdateIdentityUser } = identityUserUpdate;

    const businessOrganisationList = useSelector(state => state.businessOrganisationList);
    const { loading: loadingBusinessOrganisations, error: errorBusinessOrganisations, businessOrganisations } = businessOrganisationList;

    const updatePassword = useSelector(state => state.updatePassword);
    const { loading: loadingUpdatePassword, error: errorUpdatePassword, success: successUpdatePassword } = updatePassword;

    const { roles } = useSelector(state => state.roles);

    useEffect(() => {
        if (identityUser && !loading && identityUser.id === userId) {
            const claims = identityUser.claims || [];
            const FirstName = claims.find(claim => claim.type.includes("claims/givenname"));
            const LastName = claims.find(claim => claim.type.includes("claims/surname"));
            const PhoneNumber = claims.find(claim => claim.type.includes("claims/mobilephone"));
            const BusinessOrganisationId = claims.find(claim => claim.type.includes("claims/groupsid"));

            setNewIdentityUser({
                ...identityUser,
                userName: identityUser.userName ? identityUser.userName : "",
                firstName: FirstName ? FirstName.value : "",
                lastName: LastName ? LastName.value : "",
                phoneNumber: PhoneNumber ? PhoneNumber.value : "",
                businessOrganisationId: BusinessOrganisationId ? BusinessOrganisationId.value : "",
                roles: identityUser.roles ? identityUser.roles : []
            });
        }
    }, [identityUser, loading]);

    useEffect(() => {
        if (successUpdateIdentityUser) {
            openNotification('bottom', 'User information updated successfully');
            dispatch({ type: IDENTITY_USER_UPDATE_RESET });
        }
    }, [successUpdateIdentityUser, dispatch, userId]);

    useEffect(() => {
        if (successUpdatePassword) {
            dispatch({ type: UPDATE_USER_PASSWORD_RESET });
            openNotification('bottom', 'User password updated successfully');
        }
    }, [successUpdatePassword, dispatch, userId]);

    const handleUpdateIdentityUser = async () => {
        dispatch(updateIdentityUser(userId, newIdentityUser));
    };

    const handleUpdatePassword = async () => {
        if (!newPassword || newPassword.length < 6) {
            alert("Password must be at least 6 characters");
            return;
        }
        dispatch(resetPassword(userId, newPassword));
    };

    const handleRefreshRoles = () => {
        dispatch(listBusinessOrganisations());
        dispatch(getAllRoles());
    };

    const showEmailModal = () => {
        setEmailModalVisible(true);
        emailForm.setFieldsValue({ to: newIdentityUser.email });
    };

    const handleSendEmail = () => {
        setEmailSending(true);
        emailForm.validateFields().then((values) => {
            dispatch(sendEmail({
                "EmailToId": values.to,
                "EmailToName": `${newIdentityUser.firstName} ${newIdentityUser.lastName}`,
                "EmailSubject": values.subject,
                "EmailBody": values.message
            }))
                .then(() => {
                    setEmailSending(false);
                    setEmailModalVisible(false);
                    openNotification('bottom', t('Email sent successfully'));
                })
                .catch((error) => {
                    setEmailSending(false);
                    openNotification('bottom', t('Failed to send email: {{error}}', { error: error.message }));
                });
        }).catch(() => {
            setEmailSending(false);
        });
    };

    const generateEmailTemplate = (template) => {
        let subject = '';
        let message = '';
        switch (template) {
            case 'greeting':
                subject = t('Welcome to Our Platform');
                message = t('Dear {{name}},\n\nWelcome to our platform! We\'re excited to have you on board.\n\nBest regards,\nThe Team', {
                    name: `${newIdentityUser.firstName} ${newIdentityUser.lastName}`
                });
                break;
            case 'confirm_account':
                subject = t('Please Confirm Your Account');
                message = t('Dear {{name}},\n\nPlease confirm your account by clicking on the following link: [Confirmation Link]\n\nBest regards,\nThe Team', {
                    name: `${newIdentityUser.firstName} ${newIdentityUser.lastName}`
                });
                break;
            case 'subscription_expiry':
                subject = t('Subscription Expiry Reminder');
                message = t('Dear {{name}},\n\nThis is a reminder that your subscription is set to expire on {{date}}. Please renew your subscription to continue using our services.\n\nBest regards,\nThe Team', {
                    name: `${newIdentityUser.firstName} ${newIdentityUser.lastName}`,
                    date: moment().add(30, 'days').format('YYYY-MM-DD') // Example expiry date
                });
                break;
            default:
                break;
        }
        emailForm.setFieldsValue({ subject, message });
    };

    return (
        <React.Fragment>
            {contextHolder}
            <h2>{t('User Identity Information')}</h2>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Button
                    type="link"
                    onClick={() => history.goBack()}
                    icon={<ArrowLeftOutlined />}
                >
                    {t('Return back')}
                </Button>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Card
                            title={t("User Information")}
                            bordered={false}
                            style={{ width: "100%" }}
                            loading={loading}
                        >
                            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                {errorUpdateIdentityUser && (
                                    <Alert
                                        message={t("Error")}
                                        description={errorUpdateIdentityUser}
                                        type="error"
                                        showIcon
                                    />
                                )}
                                {error && (
                                    <Alert
                                        message={t("Error")}
                                        description={error}
                                        type="error"
                                        showIcon
                                    />
                                )}
                                <Alert
                                    message={t("Warning")}
                                    description={t("You are about to update a user's information. Please ensure you have the correct permissions.")}
                                    type="warning"
                                    showIcon
                                />
                                {(newIdentityUser && newIdentityUser.roles) && (
                                    <Form
                                        name="editUserForm"
                                        layout="horizontal"
                                        labelCol={{ span: 6 }}
                                        onFinish={handleUpdateIdentityUser}
                                        size="large"
                                        initialValues={newIdentityUser}
                                    >
                                        <Form.Item label={t("Email")} name="email">
                                            <Input
                                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, email: e.target.value })}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("User Name")} name="userName">
                                            <Input
                                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, userName: e.target.value })}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("First Name")} name="firstName">
                                            <Input
                                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, firstName: e.target.value })}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("Last Name")} name="lastName">
                                            <Input
                                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, lastName: e.target.value })}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("Phone")} name="phoneNumber">
                                            <Input
                                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, phoneNumber: e.target.value })}
                                            />
                                        </Form.Item>
                                        <Form.Item label={t("Organization")} name="businessOrganisationId">
                                            <Select
                                                onChange={(value) => setNewIdentityUser({ ...newIdentityUser, businessOrganisationId: value })}
                                            >
                                                {businessOrganisations?.map((org) => (
                                                    <Select.Option key={org.id} value={org.id}>
                                                        {org.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={t("Roles")} name="roles">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setNewIdentityUser({ ...newIdentityUser, roles: value })}
                                                    value={newIdentityUser.roles}
                                                >
                                                    {roles?.map((role) => (
                                                        <Select.Option key={role} value={role}>
                                                            {role}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                                <Space size="small">
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={() => setDrawerVisible(true)}
                                                        style={{ marginLeft: 8 }}
                                                    />
                                                    <Button
                                                        icon={<SyncOutlined />}
                                                        onClick={handleRefreshRoles}
                                                        disabled={loadingBusinessOrganisations}
                                                    />
                                                </Space>
                                            </div>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={loadingUpdateIdentityUser}>
                                                {t("Update User")}
                                            </Button>
                                            <Button
                                                type="link"
                                                onClick={() => {
                                                    dispatch({ type: IDENTITY_USER_UPDATE_RESET });
                                                    dispatch(getIdentityUserDetails(userId));
                                                }}
                                            >
                                                {t("Reset")}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                )}
                            </Space>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                            <Card title={t("Reset Password")} bordered={false} style={{ width: "100%" }} loading={loadingUpdatePassword}>
                                <Form
                                    name="resetPasswordForm"
                                    layout="vertical"
                                    onFinish={handleUpdatePassword}
                                    size="large"
                                >
                                    <Form.Item label={t("New Password")} name="newPassword">
                                        <Input.Password
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loadingUpdatePassword}>
                                            {t("Reset Password")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                                {errorUpdatePassword && (
                                    <Alert
                                        message={t("Error")}
                                        description={errorUpdatePassword}
                                        type="error"
                                        showIcon
                                    />
                                )}
                            </Card>
                            <Card title={t("User Actions")} bordered={false} style={{ width: "100%" }}>
                                <Button
                                    type="primary"
                                    icon={<MailOutlined />}
                                    onClick={showEmailModal}
                                    style={{ marginRight: 8 }}
                                >
                                    {t('Send Email')}
                                </Button>
                            </Card>
                        </Space>
                    </Col>
                </Row>
            </Space>

            <Drawer
                title={t("Edit Roles")}
                placement="right"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                width={900}
            >
                <RolePermissionManager currentRole={newIdentityUser?.roles} />
            </Drawer>

            <Modal
                title={t("Send Email")}
                visible={emailModalVisible}
                onCancel={() => setEmailModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setEmailModalVisible(false)}>
                        {t("Close")}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSendEmail}
                        loading={emailSending}
                        icon={<MailOutlined />}
                    >
                        {t("Send")}
                    </Button>,
                ]}
            >
                <Spin spinning={emailSending}>
                    <Form form={emailForm} layout="vertical">
                        <Form.Item 
                            name="to" 
                            label={t("To")} 
                            rules={[{ required: true, message: t('Please input the recipient email!') }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            name="subject" 
                            label={t("Subject")} 
                            rules={[{ required: true, message: t('Please input the email subject!') }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            name="message" 
                            label={t("Message")} 
                            rules={[{ required: true, message: t('Please input the email message!') }]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </React.Fragment>
    );
}

export default EditUserScreen;