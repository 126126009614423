import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Progress, Typography, Alert, Space, Form, message, Checkbox } from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MappingTable from './MappingTable';
import InventoryLineOptionsModal from './InventoryLineOptionsModal';
import { createRecord } from './api';

const { Text } = Typography;

export default function ImportModal({ visible, onCancel, csvHeaders, csvRows, testDto, testCategoryDto, inventoryLineDto, userToken }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [inventoryLineForm] = Form.useForm();
  const [deviceMappings, setDeviceMappings] = useState([]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentRecord, setCurrentRecord] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [stopImport, setStopImport] = useState(false);
  const [importing, setImporting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [inventoryLineModalVisible, setInventoryLineModalVisible] = useState(false);
  const [generateInventoryLine, setGenerateInventoryLine] = useState(false);

  useEffect(() => {
    if (visible && csvHeaders.length > 0) {
      const newDeviceMappings = Object.keys(testDto)?.map(dtoProperty => ({
        dtoProperty,
        csvHeader: findBestMatch(dtoProperty, csvHeaders),
        defaultValue: '',
      }));
      setDeviceMappings(newDeviceMappings);
    }
  }, [visible, csvHeaders, testDto]);

  const findBestMatch = (dtoProperty, headers) => {
    const lowerDtoProperty = dtoProperty.toLowerCase();
    return headers.find(header => header.toLowerCase() === lowerDtoProperty) || '';
  };

  const handleGenerateInventoryLineChange = (e) => {
    setGenerateInventoryLine(e.target.checked);
    if (e.target.checked) {
      setInventoryLineModalVisible(true);
    } else {
      inventoryLineForm.resetFields();
    }
  };

  const handleInventoryLineModalOk = async () => {
    try {
      await inventoryLineForm.validateFields();
      setInventoryLineModalVisible(false);
    } catch (errorInfo) {
      message.error(t('importer.pleaseCompleteAllFields'));
    }
  };

  const handleStartImport = async () => {
    try {
      await form.validateFields();
      if (generateInventoryLine) {
        await inventoryLineForm.validateFields();
      }
    } catch (errorInfo) {
      message.error(t('importer.pleaseCompleteAllFields'));
      return;
    }

    setImporting(true);
    setTotalRecords(csvRows.length);
    setCurrentRecord(0);
    setCurrentProgress(0);
    setErrors([]);

    const inventoryLineOptions = generateInventoryLine ? inventoryLineForm.getFieldsValue() : null;

    try {
      await createRecord(csvRows, csvHeaders, deviceMappings, testDto, testCategoryDto, inventoryLineDto, generateInventoryLine, inventoryLineOptions, userToken, {
        setCurrentRecord,
        setCurrentProgress,
        setErrors,
        stopImport
      });

      if (errors.length === 0) {
        message.success(t('importer.importCompletedSuccessfully'));
      } else {
        message.warning(`${t('importer.importCompletedWithErrors')} ${errors.length} ${t('importer.errors')}`);
      }
    } catch (error) {
      setErrors(prevErrors => [...prevErrors, error.message]);
      message.error(t('importer.importFailed'));
    } finally {
      setImporting(false);
    }
  };

  return (
    <Modal
      title={t('importer.dataMapping')}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      footer={null}
      bodyStyle={{ padding: 24, maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
    >
      <Form form={form} layout="vertical">
        <Alert
          message={
            <Checkbox
              checked={generateInventoryLine}
              onChange={handleGenerateInventoryLineChange}
              style={{ fontSize: '16px' }}
            >
              {t('importer.generateInventoryLine')}
            </Checkbox>
          }
          description={
            <span>
              {t('importer.generateInventoryLineDescription')}
              <Button type="link" size='small' onClick={() => setInventoryLineModalVisible(true)}>
                {t('importer.editYourOptions')}
              </Button>
            </span>
            }
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />

        <MappingTable
          deviceMappings={deviceMappings}
          setDeviceMappings={setDeviceMappings}
          csvHeaders={csvHeaders}
          testDto={testDto}
        />

        {errors.length > 0 && (
          <Alert
            message={t('importer.importErrors')}
            description={
              <div style={{ maxHeight: '200px', overflowY: 'auto', borderRadius: '4px', padding: '8px' }}>
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  <li>{errors.length} {t('importer.importErrorsFound')}</li>
                </ul>
              </div>
            }
            type="error"
            showIcon
            style={{ margin: 16 }}
          />
        )}

        {importing && (
          <Progress percent={currentProgress} style={{ marginBottom: 16 }} />
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <Text>
            {t('importer.processedRecords', { currentRecord, totalRecords })}
          </Text>
          <Space>
            <Button onClick={onCancel}>
              {t('importer.cancel')}
            </Button>
            <Button
              type="primary"
              onClick={handleStartImport}
              loading={importing}
              disabled={!csvHeaders.length || importing}
            >
              {t('importer.startImport')}
            </Button>
            {importing && (
              <Button
                danger
                onClick={() => setStopImport(true)}
                icon={<StopOutlined />}
              >
                {t('importer.stopImport')}
              </Button>
            )}
          </Space>
        </div>
      </Form>

      <InventoryLineOptionsModal
        visible={inventoryLineModalVisible}
        onCancel={() => setInventoryLineModalVisible(false)}
        onOk={handleInventoryLineModalOk}
        form={inventoryLineForm}
      />
    </Modal>
  );
}
