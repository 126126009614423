import axios from 'axios';
import {
    SMTP_CONFIG_LIST_REQUEST,
    SMTP_CONFIG_LIST_SUCCESS,
    SMTP_CONFIG_LIST_FAIL,
    SMTP_CONFIG_DETAILS_REQUEST,
    SMTP_CONFIG_DETAILS_SUCCESS,
    SMTP_CONFIG_DETAILS_FAIL,
    SMTP_CONFIG_DELETE_REQUEST,
    SMTP_CONFIG_DELETE_SUCCESS,
    SMTP_CONFIG_DELETE_FAIL,
    SMTP_CONFIG_CREATE_REQUEST,
    SMTP_CONFIG_CREATE_SUCCESS,
    SMTP_CONFIG_CREATE_FAIL,
    SMTP_CONFIG_UPDATE_REQUEST,
    SMTP_CONFIG_UPDATE_SUCCESS,
    SMTP_CONFIG_UPDATE_FAIL,
    SMTP_CONFIG_COUNT_REQUEST,
    SMTP_CONFIG_COUNT_SUCCESS,
    SMTP_CONFIG_COUNT_FAIL,
} from "../constants/smtpConfigConstants";

const API_BASE_URL = '/dotnet_api/v1/smtpConfig';

// Helper function to handle errors
const handleError = (error) => {
    const errorMessage = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
    return errorMessage;
};

// Helper function to get config with auth token
const getConfig = (getState) => {
    const { userLogin: { userInfo } } = getState();
    return {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    };
};

export const listSmtpConfigs = (filter = {}) => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_LIST_REQUEST });
        const config = { ...getConfig(getState), params: filter };
        const { data } = await axios.get(`${API_BASE_URL}/all`, config);
        dispatch({ type: SMTP_CONFIG_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_LIST_FAIL, payload: handleError(error) });
    }
};

export const countSmtpConfigs = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_COUNT_REQUEST });
        const { data } = await axios.get(`${API_BASE_URL}/count`, getConfig(getState));
        dispatch({ type: SMTP_CONFIG_COUNT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_COUNT_FAIL, payload: handleError(error) });
    }
};

export const getSmtpConfigDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_DETAILS_REQUEST });
        const { data } = await axios.get(`${API_BASE_URL}/${id}`, getConfig(getState));
        dispatch({ type: SMTP_CONFIG_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_DETAILS_FAIL, payload: handleError(error) });
    }
};

export const deleteSmtpConfig = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_DELETE_REQUEST });
        await axios.delete(`${API_BASE_URL}/${id}`, getConfig(getState));
        dispatch({ type: SMTP_CONFIG_DELETE_SUCCESS });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_DELETE_FAIL, payload: handleError(error) });
    }
};

export const createSmtpConfig = (smtpConfig) => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_CREATE_REQUEST });
        const { data } = await axios.post(API_BASE_URL, smtpConfig, getConfig(getState));
        dispatch({ type: SMTP_CONFIG_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_CREATE_FAIL, payload: handleError(error) });
    }
};

export const updateSmtpConfig = (id, changes) => async (dispatch, getState) => {
    try {
        dispatch({ type: SMTP_CONFIG_UPDATE_REQUEST });
        const { data } = await axios.patch(`${API_BASE_URL}/${id}`, changes, getConfig(getState));
        dispatch({ type: SMTP_CONFIG_UPDATE_SUCCESS, payload: data });
        dispatch({ type: SMTP_CONFIG_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SMTP_CONFIG_UPDATE_FAIL, payload: handleError(error) });
    }
};