import {
    QC_RULE_LIST_REQUEST,
    QC_RULE_LIST_SUCCESS,
    QC_RULE_LIST_FAIL,
    QC_RULE_DETAILS_REQUEST,
    QC_RULE_DETAILS_SUCCESS,
    QC_RULE_DETAILS_FAIL,
    QC_RULE_DELETE_REQUEST,
    QC_RULE_DELETE_SUCCESS,
    QC_RULE_DELETE_FAIL,
    QC_RULE_CREATE_REQUEST,
    QC_RULE_CREATE_SUCCESS,
    QC_RULE_CREATE_FAIL,
    QC_RULE_UPDATE_REQUEST,
    QC_RULE_UPDATE_SUCCESS,
    QC_RULE_UPDATE_FAIL,
    QC_RULE_DTO_REQUEST,
    QC_RULE_DTO_SUCCESS,
    QC_RULE_DTO_FAIL,
    QC_RULE_DTO_RESET,
    QC_RULE_LIST_BYIDS_REQUEST,
    QC_RULE_LIST_BYIDS_SUCCESS,
    QC_RULE_LIST_BYIDS_FAIL,
    QC_RULE_LIST_BYIDS_RESET,
} from '../constants/qcRuleConstants';

export const qcRuleListReducer = (state = { qcRules: [] }, action) => {
    switch (action.type) {
        case QC_RULE_LIST_REQUEST:
            return { loading: true, qcRules: [] };
        case QC_RULE_LIST_SUCCESS:
            return { 
                loading: false, 
                qcRules: action.payload.qcRules,
                page: action.payload.page,
                pages: action.payload.pages,
                count: action.payload.count,
            };
        case QC_RULE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcRuleDetailsReducer = (state = { qcRule: {} }, action) => {
    switch (action.type) {
        case QC_RULE_DETAILS_REQUEST:
            return { ...state, loading: true };
        case QC_RULE_DETAILS_SUCCESS:
            return { loading: false, qcRule: action.payload };
        case QC_RULE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcRuleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_RULE_DELETE_REQUEST:
            return { loading: true };
        case QC_RULE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case QC_RULE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcRuleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_RULE_CREATE_REQUEST:
            return { loading: true };
        case QC_RULE_CREATE_SUCCESS:
            return { loading: false, success: true, qcRule: action.payload };
        case QC_RULE_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcRuleUpdateReducer = (state = { qcRule: {} }, action) => {
    switch (action.type) {
        case QC_RULE_UPDATE_REQUEST:
            return { loading: true };
        case QC_RULE_UPDATE_SUCCESS:
            return { loading: false, success: true, qcRule: action.payload };
        case QC_RULE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcRuleDtoReducer = (state = { qcRuleDto: {} }, action) => {
    switch (action.type) {
        case QC_RULE_DTO_REQUEST:
            return { loading: true };
        case QC_RULE_DTO_SUCCESS:
            return { loading: false, qcRuleDto: action.payload };
        case QC_RULE_DTO_FAIL:
            return { loading: false, error: action.payload };
        case QC_RULE_DTO_RESET:
            return { qcRuleDto: {} };
        default:
            return state;
    }
};

export const qcRuleListByIdsReducer = (state = { qcRules: [] }, action) => {
    switch (action.type) {
        case QC_RULE_LIST_BYIDS_REQUEST:
            return { loading: true, qcRules: [] };
        case QC_RULE_LIST_BYIDS_SUCCESS:
            return { loading: false, qcRules: action.payload };
        case QC_RULE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case QC_RULE_LIST_BYIDS_RESET:
            return { qcRules: [] };
        default:
            return state;
    }
};