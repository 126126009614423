import {

    ATTACHMENT_LIST_REQUEST,
    ATTACHMENT_LIST_SUCCESS,
    ATTACHMENT_LIST_FAIL,

    ATTACHMENT_DETAILS_REQUEST,
    ATTACHMENT_DETAILS_SUCCESS,
    ATTACHMENT_DETAILS_FAIL,

    ATTACHMENT_DELETE_REQUEST,
    ATTACHMENT_DELETE_SUCCESS,
    ATTACHMENT_DELETE_FAIL,

    ATTACHMENT_CREATE_REQUEST,
    ATTACHMENT_CREATE_SUCCESS,
    ATTACHMENT_CREATE_FAIL,
    ATTACHMENT_CREATE_RESET,

    ATTACHMENT_UPDATE_REQUEST,
    ATTACHMENT_UPDATE_SUCCESS,
    ATTACHMENT_UPDATE_FAIL,
    ATTACHMENT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    ATTACHMENT_DTO_FAIL,
    ATTACHMENT_DTO_REQUEST,
    ATTACHMENT_DTO_SUCCESS,
    ATTACHMENT_DTO_RESET,

    ATTACHMENT_LIST_BYIDS_REQUEST,
    ATTACHMENT_LIST_BYIDS_SUCCESS,
    ATTACHMENT_LIST_BYIDS_FAIL,
    ATTACHMENT_LIST_BYIDS_RESET

} from "../constants/attachmentConstants";

const initialState = {
    attachments: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const attachmentListReducer = (
    state = {
        attachments: []
    }, action) => {
    switch (action.type) {
        case ATTACHMENT_LIST_REQUEST:
            return { loading: true, attachments: [] };
        case ATTACHMENT_LIST_SUCCESS:
            // our state will have a list of attachments after the request
            return {
                loading: false,
                attachments: action.payload.attachments,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case ATTACHMENT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const attachmentListByIDsReducer = (
    state = {
        attachments: []
    }, action) => {
    switch (action.type) {
        case ATTACHMENT_LIST_BYIDS_REQUEST:
            return { loading: true, attachments: [] };
        case ATTACHMENT_LIST_BYIDS_SUCCESS:
            // our state will have a list of attachments after the request
            return { loading: false, attachments: action.payload };
        case ATTACHMENT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case ATTACHMENT_LIST_BYIDS_RESET:
            return { attachments: [] };
        default:
            return state;
    }
}


export const attachmentDetailsReducer = (
    state = {
        attachment: { comments: [] }
    }, action) => {
    switch (action.type) {
        case ATTACHMENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case ATTACHMENT_DETAILS_SUCCESS:
            // our state will have a list of attachments after the request
            return { loading: false, attachment: action.payload };
        case ATTACHMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const attachmentDtoReducer = (state = { attachmentDto: {} }, action) => {
    switch (action.type) {
        case ATTACHMENT_DTO_REQUEST:
            return { loading: true, attachmentDto: {} };
        case ATTACHMENT_DTO_SUCCESS:
            return { loading: false, attachmentDto: action.payload };
        case ATTACHMENT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case ATTACHMENT_DTO_RESET:
            return { attachmentDto: {} };
        default:
            return state;
    }
}

export const attachmentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ATTACHMENT_DELETE_REQUEST:
            return { loading: true }

        case ATTACHMENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ATTACHMENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const attachmentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ATTACHMENT_CREATE_REQUEST:
            return { loading: true }

        case ATTACHMENT_CREATE_SUCCESS:
            return { loading: false, success: true, attachment: action.payload }

        case ATTACHMENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ATTACHMENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const attachmentUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case ATTACHMENT_UPDATE_REQUEST:
            return { loading: true }

        case ATTACHMENT_UPDATE_SUCCESS:
            return { loading: false, success: true, attachment: action.payload }

        case ATTACHMENT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case ATTACHMENT_UPDATE_RESET:
            return { attachment: {} }

        default:
            return state
    }
}