// Import actions
import * as ConsentActions from '../../../actions/consentActions';
import * as AetiologyActions from '../../../actions/aetiologyActions';
import * as AttachmentActions from '../../../actions/attachmentActions';
import * as ConstantActions from '../../../actions/constantActions';
import * as DocumentActions from '../../../actions/documentActions';
import * as EncounterActions from '../../../actions/encounterActions';
import * as FollowupCentreActions from '../../../actions/followupCentreActions';
import * as GuidelineActions from '../../../actions/guidelineActions';
import * as NoteActions from '../../../actions/noteActions';
import * as OrganisationActions from '../../../actions/organisationActions';
import * as PaymentActions from '../../../actions/paymentActions';
import * as PractitionerActions from '../../../actions/practitionerActions';
import * as RatingActions from '../../../actions/ratingActions';
import * as ReportActions from '../../../actions/reportActions';
import * as SampleActions from '../../../actions/sampleActions';
import * as ShortUrlActions from '../../../actions/shortUrlActions';
import * as SymptomActions from '../../../actions/symptomActions';
import * as TestActions from '../../../actions/testActions';
import * as TestResultActions from '../../../actions/testResultActions';
import * as TestOrderActions from '../../../actions/testOrderActions';
import * as TestOrderBulkRequestActions from '../../../actions/testOrderBulkRequestActions';
import * as PatientActions from '../../../actions/patientActions';
import * as TestResultReportActions from '../../../actions/testResultReportActions';
import * as TestInventoryLineActions from '../../../actions/testInventoryLineActions';
import * as PricingTierActions from '../../../actions/pricingTierActions';
import * as PricingRuleActions from '../../../actions/pricingRuleActions';
import * as UserActions from '../../../actions/userActions';
import * as InvoiceReportActions from '../../../actions/invoiceReportActions';
import * as InvoiceLineActions from '../../../actions/invoiceLineActions';
import * as TestCategoryActions from '../../../actions/testCategoryActions';
import * as controlMaterialActions from '../../../actions/controlMaterialActions';
import * as qcScheduleActions from '../../../actions/qcScheduleActions';
import * as qcRuleActions from '../../../actions/qcRuleActions';
import * as qcResultActions from '../../../actions/qcResultActions';
import * as qcReportActions from '../../../actions/qcReportActions';
import * as BusinessOrganisationActions from '../../../actions/businessOrganisationActions';


export const fieldEntityActionConfig = {

    BusinessOrganisationIds: {
        fieldName: "BusinessOrganisationIds",
        primarySearchProp: "name",
        secondarySearchProp: "contactEmail",
        singularEntity: "businessOrganisation",
        pluralEntity: "businessOrganisations",
        constantPrefix: "BUSINESS_ORGANISATION",
        summaryProps: ['name', 'contactEmail', 'contactPhone', 'subscriptionPackage'],
        loadDto: BusinessOrganisationActions.loadBusinessOrganisationDto,
        createEntity: BusinessOrganisationActions.createBusinessOrganisation,
        updateEntity: BusinessOrganisationActions.updateBusinessOrganisation,
        deleteEntity: BusinessOrganisationActions.deleteBusinessOrganisation,
        getEntityDetails: BusinessOrganisationActions.getBusinessOrganisationDetails,
        listEntities: BusinessOrganisationActions.listBusinessOrganisations,
        listEntitiesByIds: BusinessOrganisationActions.listBusinessOrganisationsByIds,
        enableSearchBar: true,
    },


    ConsentIds: {
        fieldName: "ConsentIds",
        primarySearchProp: "consentPurpose",
        secondarySearchProp: "consentScope",
        singularEntity: "consent",
        pluralEntity: "consents",
        constantPrefix: "CONSENT",
        summaryProps: ['consentPurpose', 'consentScope', 'consentDocument'],
        loadDto: ConsentActions.loadConsentDto,
        createEntity: ConsentActions.createConsent,
        updateEntity: ConsentActions.updateConsent,
        deleteEntity: ConsentActions.deleteConsent,
        getEntityDetails: ConsentActions.getConsentDetails,
        listEntities: ConsentActions.listConsents,
        listEntitiesByIds: ConsentActions.listConsentsByIds,
    },

    AetiologyIds: {
        fieldName: "AetiologyIds",
        primarySearchProp: "name",
        secondarySearchProp: "description",
        singularEntity: "aetiology",
        pluralEntity: "aetiologies",
        constantPrefix: "AETIOLOGY",
        summaryProps: ['name', 'description'],
        loadDto: AetiologyActions.loadAetiologyDto,
        createEntity: AetiologyActions.createAetiology,
        updateEntity: AetiologyActions.updateAetiology,
        deleteEntity: AetiologyActions.deleteAetiology,
        getEntityDetails: AetiologyActions.getAetiologyDetails,
        listEntities: AetiologyActions.listAetiologies,
        listEntitiesByIds: AetiologyActions.listAetiologiesByIds,
        enableSearchBar: true,
    },

    SymptomIds: {
        fieldName: "SymptomIds",
        primarySearchProp: "name",
        secondarySearchProp: "description",
        singularEntity: "symptom",
        pluralEntity: "symptoms",
        constantPrefix: "SYMPTOM",
        summaryProps: ['name', 'description'],
        loadDto: SymptomActions.loadSymptomDto,
        createEntity: SymptomActions.createSymptom,
        updateEntity: SymptomActions.updateSymptom,
        deleteEntity: SymptomActions.deleteSymptom,
        getEntityDetails: SymptomActions.getSymptomDetails,
        listEntities: SymptomActions.listSymptoms,
        listEntitiesByIds: SymptomActions.listSymptomsByIds,
        enableSearchBar: true,
    },

    FollowupCentreIds: {
        fieldName: "FollowupCentreIds",
        primarySearchProp: "name",
        secondarySearchProp: "streetAddress",
        singularEntity: "followupCentre",
        pluralEntity: "followupCentres",
        constantPrefix: "FOLLOWUP_CENTRE",
        summaryProps: ['name', 'email', 'phone'],
        enableSearchBar: true,
        loadDto: FollowupCentreActions.loadFollowupCentreDto,
        createEntity: FollowupCentreActions.createFollowupCentre,
        updateEntity: FollowupCentreActions.updateFollowupCentre,
        deleteEntity: FollowupCentreActions.deleteFollowupCentre,
        getEntityDetails: FollowupCentreActions.getFollowupCentreDetails,
        listEntities: FollowupCentreActions.listFollowupCentres,
        listEntitiesByIds: FollowupCentreActions.listFollowupCentresByIds,
    },

    PractitionerIds: {
        fieldName: "PractitionerIds",
        primarySearchProp: "forename",
        secondarySearchProp: "email",
        singularEntity: "practitioner",
        pluralEntity: "practitioners",
        constantPrefix: "PRACTITIONER",
        summaryProps: ['forename', 'surname', 'email', 'phone', 'description'],
        loadDto: PractitionerActions.loadPractitionerDto,
        createEntity: PractitionerActions.createPractitioner,
        updateEntity: PractitionerActions.updatePractitioner,
        deleteEntity: PractitionerActions.deletePractitioner,
        getEntityDetails: PractitionerActions.getPractitionerDetails,
        listEntities: PractitionerActions.listPractitioners,
        listEntitiesByIds: PractitionerActions.listPractitionersByIds,
    },

    OrganisationIds: {
        fieldName: "OrganisationIds",
        primarySearchProp: "name",
        secondarySearchProp: "email",
        singularEntity: "organisation",
        pluralEntity: "organisations",
        constantPrefix: "ORGANISATION",
        summaryProps: ['name', 'description', 'email', 'telephone'],
        enableSearchBar: true,
        loadDto: OrganisationActions.loadOrganisationDto,
        createEntity: OrganisationActions.createOrganisation,
        updateEntity: OrganisationActions.updateOrganisation,
        deleteEntity: OrganisationActions.deleteOrganisation,
        getEntityDetails: OrganisationActions.getOrganisationDetails,
        listEntities: OrganisationActions.listOrganisations,
        listEntitiesByIds: OrganisationActions.listOrganisationsByIds,
    },

    EncounterIds: {
        fieldName: "EncounterIds",
        primarySearchProp: "referenceNumber",
        secondarySearchProp: "encounterType",
        singularEntity: "encounter",
        pluralEntity: "encounters",
        constantPrefix: "ENCOUNTER",
        summaryProps: ['referenceNumber', 'encounterType', 'encounterDate', 'encounterReason', 'encounterStatus'],
        enableSearchBar: true,
        loadDto: EncounterActions.loadEncounterDto,
        createEntity: EncounterActions.createEncounter,
        updateEntity: EncounterActions.updateEncounter,
        deleteEntity: EncounterActions.deleteEncounter,
        getEntityDetails: EncounterActions.getEncounterDetails,
        listEntities: EncounterActions.listEncounters,
        listEntitiesByIds: EncounterActions.listEncountersByIds,
    },

    AttachmentIds: {
        fieldName: "AttachmentIds",
        primarySearchProp: "name",
        secondarySearchProp: "status",
        singularEntity: "attachment",
        pluralEntity: "attachments",
        constantPrefix: "ATTACHMENT",
        summaryProps: ['name', 'description', 'url'],
        loadDto: AttachmentActions.loadAttachmentDto,
        createEntity: AttachmentActions.createAttachment,
        updateEntity: AttachmentActions.updateAttachment,
        deleteEntity: AttachmentActions.deleteAttachment,
        getEntityDetails: AttachmentActions.getAttachmentDetails,
        listEntities: AttachmentActions.listAttachments,
        listEntitiesByIds: AttachmentActions.listAttachmentsByIds,
    },

    PaymentIds: {
        fieldName: "PaymentIds",
        primarySearchProp: "referenceNumber",
        secondarySearchProp: "status",
        singularEntity: "payment",
        pluralEntity: "payments",
        constantPrefix: "PAYMENT",
        summaryProps: ['referenceNumber', 'paymentDate', 'totalAmount', 'amountPaid', 'status'],
        loadDto: PaymentActions.loadPaymentDto,
        createEntity: PaymentActions.createPayment,
        updateEntity: PaymentActions.updatePayment,
        deleteEntity: PaymentActions.deletePayment,
        getEntityDetails: PaymentActions.getPaymentDetails,
        listEntities: PaymentActions.listPayments,
        listEntitiesByIds: PaymentActions.listPaymentsByIds,
    },

    TestIds: {
        fieldName: "TestIds",
        primarySearchProp: "name",
        secondarySearchProp: "category",
        groupSearchProp: "category",
        singularEntity: "test",
        pluralEntity: "tests",
        constantPrefix: "TEST",
        summaryProps: ['name', 'category', 'order', 'type', 'code'],
        loadDto: TestActions.loadTestDto,
        createEntity: TestActions.createTest,
        updateEntity: TestActions.updateTest,
        deleteEntity: TestActions.deleteTest,
        getEntityDetails: TestActions.getTestDetails,
        listEntities: TestActions.listTests,
        listEntitiesByIds: TestActions.listTestsByIds,
    },

    TestResultIds: {
        fieldName: "TestResultIds",
        primarySearchProp: "orderCode",
        secondarySearchProp: "barcode",
        singularEntity: "testResult",
        pluralEntity: "testResults",
        constantPrefix: "TEST_RESULT",
        summaryProps: ['orderCode', 'barcode', 'status', 'resultDate'],
        loadDto: TestResultActions.loadTestResultDto,
        createEntity: TestResultActions.createTestResult,
        updateEntity: TestResultActions.updateTestResult,
        deleteEntity: TestResultActions.deleteTestResult,
        getEntityDetails: TestResultActions.getTestResultDetails,
        listEntities: TestResultActions.listTestResults,
        listEntitiesByIds: TestResultActions.listTestResultsByIds,
        defaultViewMode: 'table',
        enableSearchBar: false,
        enableListViewButton: false,
        enableCreateButton: false,

    },

    PatientId: {
        fieldName: "PatientId",
        primarySearchProp: "forename",
        secondarySearchProp: "surname",
        singularEntity: "patient",
        pluralEntity: "patients",
        constantPrefix: "PATIENT",
        summaryProps: ['forename', 'surname', 'email', 'phone'],
        loadDto: PatientActions.loadPatientDto,
        createEntity: PatientActions.createPatient,
        updateEntity: PatientActions.updatePatient,
        deleteEntity: PatientActions.deletePatient,
        getEntityDetails: PatientActions.getPatientDetails,
        listEntities: PatientActions.listPatients,
        listEntitiesByIds: PatientActions.listPatientsByIds,
        oneToOneView: true
    },


    TestOrderIds: {
        fieldName: "TestOrderIds",
        primarySearchProp: "barcode",
        secondarySearchProp: "orderType",
        singularEntity: "testOrder",
        pluralEntity: "testOrders",
        constantPrefix: "TEST_ORDER",
        summaryProps: ['barcode', 'orderType', 'orderDate', 'status'],
        loadDto: TestOrderActions.loadTestOrderDto,
        createEntity: TestOrderActions.createTestOrder,
        updateEntity: TestOrderActions.updateTestOrder,
        deleteEntity: TestOrderActions.deleteTestOrder,
        getEntityDetails: TestOrderActions.getTestOrderDetails,
        listEntities: TestOrderActions.listTestOrders,
        listEntitiesByIds: TestOrderActions.listTestOrdersByIds,
    },


    TestResultReportId: {
        fieldName: "TestResultReportId",
        primarySearchProp: "orderNumber",
        secondarySearchProp: "barcode",
        singularEntity: "testResultReport",
        pluralEntity: "testResultReports",
        constantPrefix: "TEST_RESULT_REPORT",
        summaryProps: ['orderNumber', 'barcode', 'resultDate', 'status', 'validationStatus'],
        loadDto: TestResultReportActions.loadTestResultReportDto,
        createEntity: TestResultReportActions.createTestResultReport,
        updateEntity: TestResultReportActions.updateTestResultReport,
        deleteEntity: TestResultReportActions.deleteTestResultReport,
        getEntityDetails: TestResultReportActions.getTestResultReportDetails,
        listEntities: TestResultReportActions.listTestResultReports,
        listEntitiesByIds: TestResultReportActions.listTestResultReportsByIds,
        oneToOneView: true,
        enableSearchBar: false,

    },

    TestOrderBulkRequestIds: {
        fieldName: "TestOrderBulkRequestIds",
        primarySearchProp: "barcode",
        secondarySearchProp: "orderType",
        singularEntity: "testOrderBulkRequest",
        pluralEntity: "testOrderBulkRequests",
        constantPrefix: "TEST_ORDER_BULK_REQUEST",
        summaryProps: ['barcode', 'orderNumber', 'orderDate', 'status'],
        loadDto: TestOrderBulkRequestActions.loadTestOrderBulkRequestDto,
        createEntity: TestOrderBulkRequestActions.createTestOrderBulkRequest,
        updateEntity: TestOrderBulkRequestActions.updateTestOrderBulkRequest,
        deleteEntity: TestOrderBulkRequestActions.deleteTestOrderBulkRequest,
        getEntityDetails: TestOrderBulkRequestActions.getTestOrderBulkRequestDetails,
        listEntities: TestOrderBulkRequestActions.listTestOrderBulkRequests,
        listEntitiesByIds: TestOrderBulkRequestActions.listTestOrderBulkRequestsByIds,
        enableSearchBar: false,
        enableCreateButton: false,
    },

    TestInventoryLineIds: {
        fieldName: "TestInventoryLineIds",
        primarySearchProp: "name",
        secondarySearchProp: "testCategoryName",
        groupSearchProp: "testCategoryName",
        singularEntity: "testInventoryLine",
        pluralEntity: "testInventoryLines",
        constantPrefix: "TEST_INVENTORY_LINE",
        summaryProps: ['name', 'testName', 'testCategoryName', 'lotCode', 'barcode', 'price'],
        loadDto: TestInventoryLineActions.loadTestInventoryLineDto,
        createEntity: TestInventoryLineActions.createTestInventoryLine,
        updateEntity: TestInventoryLineActions.updateTestInventoryLine,
        deleteEntity: TestInventoryLineActions.deleteTestInventoryLine,
        getEntityDetails: TestInventoryLineActions.getTestInventoryLineDetails,
        listEntities: TestInventoryLineActions.listTestInventoryLines,
        listEntitiesByIds: TestInventoryLineActions.listTestInventoryLinesByIds,
        enableSearchBar: true,
        enableCreateButton: true,

    },

    PricingTierId: {
        fieldName: "PricingTierId",
        primarySearchProp: "name",
        secondarySearchProp: "description",
        singularEntity: "pricingTier",
        pluralEntity: "pricingTiers",
        constantPrefix: "PRICING_TIER",
        summaryProps: ['name', 'description', 'isActive', 'defaultRate'],
        loadDto: PricingTierActions.loadPricingTierDto,
        createEntity: PricingTierActions.createPricingTier,
        updateEntity: PricingTierActions.updatePricingTier,
        deleteEntity: PricingTierActions.deletePricingTier,
        getEntityDetails: PricingTierActions.getPricingTierDetails,
        listEntities: PricingTierActions.listPricingTiers,
        listEntitiesByIds: PricingTierActions.listPricingTiersByIds,
        enableSearchBar: false
    },


    PricingRuleIds: {
        fieldName: "PricingRuleIds",
        primarySearchProp: "testInventoryLineName",
        secondarySearchProp: "flatIncrease",
        singularEntity: "pricingRule",
        pluralEntity: "pricingRules",
        constantPrefix: "PRICING_RULE",
        summaryProps: ['TestInventoryLineName', 'PercentageIncrease', 'FlatIncrease', 'NetPrice', 'Condition'],
        loadDto: PricingRuleActions.loadPricingRuleDto,
        createEntity: PricingRuleActions.createPricingRule,
        updateEntity: PricingRuleActions.updatePricingRule,
        deleteEntity: PricingRuleActions.deletePricingRule,
        getEntityDetails: PricingRuleActions.getPricingRuleDetails,
        listEntities: PricingRuleActions.listPricingRules,
        listEntitiesByIds: PricingRuleActions.listPricingRulesByIds,
        enableSearchBar: false

    },

    // invoiceReport 
    InvoiceReportId: {
        fieldName: "InvoiceReportId",
        primarySearchProp: "invoiceNumber",
        secondarySearchProp: "customerName",
        singularEntity: "invoiceReport",
        pluralEntity: "invoiceReports",
        constantPrefix: "INVOICE_REPORT",
        summaryProps: ["InvoiceNumber", "InvoiceDate", "CustomerName", "Total", "AmountPaid", "InvoiceStatus"],
        loadDto: InvoiceReportActions.loadInvoiceReportDto,
        createEntity: InvoiceReportActions.createInvoiceReport,
        updateEntity: InvoiceReportActions.updateInvoiceReport,
        deleteEntity: InvoiceReportActions.deleteInvoiceReport,
        getEntityDetails: InvoiceReportActions.getInvoiceReportDetails,
        listEntities: InvoiceReportActions.listInvoiceReports,
        listEntitiesByIds: InvoiceReportActions.listInvoiceReportsByIds,
        enableSearchBar: false
    },

    // invoiceReport 
    InvoiceReportIds: {
        fieldName: "InvoiceReportIds",
        primarySearchProp: "invoiceNumber",
        secondarySearchProp: "customerName",
        singularEntity: "invoiceReport",
        pluralEntity: "invoiceReports",
        constantPrefix: "INVOICE_REPORT",
        summaryProps: ["InvoiceNumber", "InvoiceDate", "CustomerName", "Total", "AmountPaid", "BalanceDue", "InvoiceStatus"],
        loadDto: InvoiceReportActions.loadInvoiceReportDto,
        createEntity: InvoiceReportActions.createInvoiceReport,
        updateEntity: InvoiceReportActions.updateInvoiceReport,
        deleteEntity: InvoiceReportActions.deleteInvoiceReport,
        getEntityDetails: InvoiceReportActions.getInvoiceReportDetails,
        listEntities: InvoiceReportActions.listInvoiceReports,
        listEntitiesByIds: InvoiceReportActions.listInvoiceReportsByIds,
        enableSearchBar: false
    },

    // invoiceLine
    InvoiceLineIds: {
        fieldName: "InvoiceLineIds",
        primarySearchProp: "invoiceNumber",
        secondarySearchProp: "invoiceDate",
        singularEntity: "invoiceLine",
        pluralEntity: "invoiceLines",
        constantPrefix: "INVOICE_LINE",
        summaryProps: ["ItemDescription", "Quantity", "UnitPrice", "TaxRate", "DiscountPercentage", "LineTotal"],
        loadDto: InvoiceLineActions.loadInvoiceLineDto,
        createEntity: InvoiceLineActions.createInvoiceLine,
        updateEntity: InvoiceLineActions.updateInvoiceLine,
        deleteEntity: InvoiceLineActions.deleteInvoiceLine,
        getEntityDetails: InvoiceLineActions.getInvoiceLineDetails,
        listEntities: InvoiceLineActions.listInvoiceLines,
        listEntitiesByIds: InvoiceLineActions.listInvoiceLinesByIds,
        enableSearchBar: false
    },

    IdentityUserId: {
        fieldName: "IdentityUserId",
        primarySearchProp: "userName",
        secondarySearchProp: "email",
        singularEntity: "user",
        pluralEntity: "users",
        constantPrefix: "USER",
        summaryProps: ['userName', 'email', 'phoneNumber', 'isActive'],
        listEntities: UserActions.listUsersInOrganisation,
        
        enableSearchBar: false
    },

    TestCategoryId: {
        fieldName: "TestCategoryId",
        primarySearchProp: "name",
        secondarySearchProp: "order",
        singularEntity: "testCategory",
        pluralEntity: "testCategories",
        constantPrefix: "TEST_CATEGORY",
        summaryProps: ['name', 'order', 'description'],
        listEntities: TestCategoryActions.listTestCategories,
        loadDto: TestCategoryActions.loadTestCategoryDto,
        createEntity: TestCategoryActions.createTestCategory,
        updateEntity: TestCategoryActions.updateTestCategory,
        deleteEntity: TestCategoryActions.deleteTestCategory,
        getEntityDetails: TestCategoryActions.getTestCategoryDetails,
        listEntitiesByIds: TestCategoryActions.listTestCategoriesByIds,

        enableSearchBar: false
    },


    ParentCategoryId: {
        fieldName: "ParentCategoryId",
        primarySearchProp: "name",
        secondarySearchProp: "order",
        singularEntity: "testCategory",
        pluralEntity: "testCategories",
        constantPrefix: "TEST_CATEGORY",
        summaryProps: ['name', 'order', 'description'],
        listEntities: TestCategoryActions.listTestCategories,
        enableSearchBar: false
    },

    TestId: {
        fieldName: "TestId",
        primarySearchProp: "name",
        secondarySearchProp: "testCategoryName",
        singularEntity: "test",
        pluralEntity: "tests",
        constantPrefix: "TEST",
        summaryProps: ['name', 'category', 'type', 'code'],
        listEntities: TestActions.listTests,
        enableSearchBar: false
    },

    SampleId: {
        fieldName: "SampleId",
        primarySearchProp: "name",
        secondarySearchProp: "sampleType",
        singularEntity: "sample",
        pluralEntity: "samples",
        constantPrefix: "SAMPLE",
        summaryProps: ['name', 'sampleType', 'sampleDate', 'sampleStatus'],
        listEntities: SampleActions.listSamples,
        enableSearchBar: false
    },

    ControlMaterialIds: {
        fieldName: "ControlMaterialIds",
        primarySearchProp: "name",
        secondarySearchProp: "lotNumber",
        singularEntity: "controlMaterial",
        pluralEntity: "controlMaterials",
        constantPrefix: "CONTROL_MATERIAL",
        summaryProps: ['name', 'lotNumber', 'expirationDate'],
        loadDto: controlMaterialActions.loadControlMaterialDto,
        createEntity: controlMaterialActions.createControlMaterial,
        updateEntity: controlMaterialActions.updateControlMaterial,
        deleteEntity: controlMaterialActions.deleteControlMaterial,
        getEntityDetails: controlMaterialActions.getControlMaterialDetails,
        listEntities: controlMaterialActions.listControlMaterials,
        listEntitiesByIds: controlMaterialActions.listControlMaterialsByIds,
    },

    QCScheduleIds: {
        fieldName: "QCScheduleIds",
        primarySearchProp: "barcode",
        secondarySearchProp: "duration",
        singularEntity: "qcSchedule",
        pluralEntity: "qcSchedules",
        constantPrefix: "QC_SCHEDULE",
        summaryProps: ['barcode', 'duration', 'qcScheduleStatus'],
        loadDto: qcScheduleActions.loadQCScheduleDto,
        createEntity: qcScheduleActions.createQCSchedule,
        updateEntity: qcScheduleActions.updateQCSchedule,
        deleteEntity: qcScheduleActions.deleteQCSchedule,
        getEntityDetails: qcScheduleActions.getQCScheduleDetails,
        listEntities: qcScheduleActions.listQCSchedules,
        listEntitiesByIds: qcScheduleActions.listQCSchedulesByIds,
    },

    QCScheduleId: {
        fieldName: "QCScheduleId",
        primarySearchProp: "barcode",
        secondarySearchProp: "duration",
        singularEntity: "qcSchedule",
        pluralEntity: "qcSchedules",
        constantPrefix: "QC_SCHEDULE",
        summaryProps: ['barcode', 'duration', 'qcScheduleStatus'],
        loadDto: qcScheduleActions.loadQCScheduleDto,
        createEntity: qcScheduleActions.createQCSchedule,
        updateEntity: qcScheduleActions.updateQCSchedule,
        deleteEntity: qcScheduleActions.deleteQCSchedule,
        getEntityDetails: qcScheduleActions.getQCScheduleDetails,
        listEntities: qcScheduleActions.listQCSchedules,
        listEntitiesByIds: qcScheduleActions.listQCSchedulesByIds,
    },

    ControlMaterialId: {
        fieldName: "ControlMaterialId",
        primarySearchProp: "name",
        secondarySearchProp: "lotNumber",
        singularEntity: "controlMaterial",
        pluralEntity: "controlMaterials",
        constantPrefix: "CONTROL_MATERIAL",
        summaryProps: ['name', 'lotNumber', 'expirationDate'],
        loadDto: controlMaterialActions.loadControlMaterialDto,
        createEntity: controlMaterialActions.createControlMaterial,
        updateEntity: controlMaterialActions.updateControlMaterial,
        deleteEntity: controlMaterialActions.deleteControlMaterial,
        getEntityDetails: controlMaterialActions.getControlMaterialDetails,
        listEntities: controlMaterialActions.listControlMaterials,
        listEntitiesByIds: controlMaterialActions.listControlMaterialsByIds,
    },


    QCRuleIds: {
        fieldName: "QCRuleIds",
        primarySearchProp: "name",
        secondarySearchProp: "barcode",
        singularEntity: "qcRule",
        pluralEntity: "qcRules",
        constantPrefix: "QC_RULE",
        summaryProps: ['name', 'barcode', 'description'],
        loadDto: qcRuleActions.loadQCRuleDto,
        createEntity: qcRuleActions.createQCRule,
        updateEntity: qcRuleActions.updateQCRule,
        deleteEntity: qcRuleActions.deleteQCRule,
        getEntityDetails: qcRuleActions.getQCRuleDetails,
        listEntities: qcRuleActions.listQCRules,
        listEntitiesByIds: qcRuleActions.listQCRulesByIds,
    },

    QCRuleId: {
        fieldName: "QCRuleId",
        primarySearchProp: "name",
        secondarySearchProp: "barcode",
        singularEntity: "qcRule",
        pluralEntity: "qcRules",
        constantPrefix: "QC_RULE",
        summaryProps: ['name', 'barcode', 'description'],
        loadDto: qcRuleActions.loadQCRuleDto,
        createEntity: qcRuleActions.createQCRule,
        updateEntity: qcRuleActions.updateQCRule,
        deleteEntity: qcRuleActions.deleteQCRule,
        getEntityDetails: qcRuleActions.getQCRuleDetails,
        listEntities: qcRuleActions.listQCRules,
        listEntitiesByIds: qcRuleActions.listQCRulesByIds,
    },


    QCResultIds: {
        fieldName: "QCResultIds",
        primarySearchProp: "qcResultStatus",
        secondarySearchProp: "resultValue",
        singularEntity: "qcResult",
        pluralEntity: "qcResults",
        constantPrefix: "QC_RESULT",
        summaryProps: ['qcScheduleId", "controlMaterialId", "resultValue", "alert",  "qcResultStatus", "performedDate'],
        loadDto: qcResultActions.loadQCResultDto,
        createEntity: qcResultActions.createQCResult,
        updateEntity: qcResultActions.updateQCResult,
        deleteEntity: qcResultActions.deleteQCResult,
        getEntityDetails: qcResultActions.getQCResultDetails,
        listEntities: qcResultActions.listQCResults,
        listEntitiesByIds: qcResultActions.listQCResultsByIds,
    },

    QCReportIds: {
        fieldName: "QCReportIds",
        primarySearchProp: "name",
        secondarySearchProp: "date",
        singularEntity: "qcReport",
        pluralEntity: "qcReports",
        constantPrefix: "QC_REPORT",
        summaryProps: ['name', 'date', 'status', 'author'],
        loadDto: qcReportActions.loadQCReportDto,
        createEntity: qcReportActions.createQCReport,
        updateEntity: qcReportActions.updateQCReport,
        deleteEntity: qcReportActions.deleteQCReport,
        getEntityDetails: qcReportActions.getQCReportDetails,
        listEntities: qcReportActions.listQCReports,
        listEntitiesByIds: qcReportActions.listQCReportsByIds,
    },




};