import {

    TEST_ORDER_BULK_REQUEST_LIST_REQUEST,
    TEST_ORDER_BULK_REQUEST_LIST_SUCCESS,
    TEST_ORDER_BULK_REQUEST_LIST_FAIL,

    TEST_ORDER_BULK_REQUEST_DETAILS_REQUEST,
    TEST_ORDER_BULK_REQUEST_DETAILS_SUCCESS,
    TEST_ORDER_BULK_REQUEST_DETAILS_FAIL,

    TEST_ORDER_BULK_REQUEST_DELETE_REQUEST,
    TEST_ORDER_BULK_REQUEST_DELETE_SUCCESS,
    TEST_ORDER_BULK_REQUEST_DELETE_FAIL,

    TEST_ORDER_BULK_REQUEST_CREATE_REQUEST,
    TEST_ORDER_BULK_REQUEST_CREATE_SUCCESS,
    TEST_ORDER_BULK_REQUEST_CREATE_FAIL,
    TEST_ORDER_BULK_REQUEST_CREATE_RESET,

    TEST_ORDER_BULK_REQUEST_UPDATE_REQUEST,
    TEST_ORDER_BULK_REQUEST_UPDATE_SUCCESS,
    TEST_ORDER_BULK_REQUEST_UPDATE_FAIL,
    TEST_ORDER_BULK_REQUEST_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_ORDER_BULK_REQUEST_DTO_FAIL,
    TEST_ORDER_BULK_REQUEST_DTO_REQUEST,
    TEST_ORDER_BULK_REQUEST_DTO_SUCCESS,
    TEST_ORDER_BULK_REQUEST_DTO_RESET,

    TEST_ORDER_BULK_REQUEST_LIST_BYIDS_REQUEST,
    TEST_ORDER_BULK_REQUEST_LIST_BYIDS_SUCCESS,
    TEST_ORDER_BULK_REQUEST_LIST_BYIDS_FAIL,
    TEST_ORDER_BULK_REQUEST_LIST_BYIDS_RESET

} from "../constants/testOrderBulkRequestConstants";

const initialState = {
    testOrderBulkRequests: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testOrderBulkRequestListReducer = (
    state = {
        testOrderBulkRequests: []
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_LIST_REQUEST:
            return { loading: true, testOrderBulkRequests: [] };
        case TEST_ORDER_BULK_REQUEST_LIST_SUCCESS:
            // our state will have a list of testOrderBulkRequests after the request
            return {
                loading: false,
                testOrderBulkRequests: action.payload.testOrderBulkRequests,
                totalPages: action.payload.totalPages,
                totalCount: action.payload.totalCount,
                currentPage: action.payload.currentPage,
                statistics: action.payload.statistics
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_ORDER_BULK_REQUEST_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testOrderBulkRequestListByIDsReducer = (
    state = {
        testOrderBulkRequests: []
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_LIST_BYIDS_REQUEST:
            return { loading: true, testOrderBulkRequests: [] };
        case TEST_ORDER_BULK_REQUEST_LIST_BYIDS_SUCCESS:
            // our state will have a list of testOrderBulkRequests after the request
            return { loading: false, testOrderBulkRequests: action.payload };
        case TEST_ORDER_BULK_REQUEST_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_ORDER_BULK_REQUEST_LIST_BYIDS_RESET:
            return { testOrderBulkRequests: [] };
        default:
            return state;
    }
}


export const testOrderBulkRequestDetailsReducer = (
    state = {
        testOrderBulkRequest: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_ORDER_BULK_REQUEST_DETAILS_SUCCESS:
            // our state will have a list of testOrderBulkRequests after the request
            return { loading: false, testOrderBulkRequest: action.payload };
        case TEST_ORDER_BULK_REQUEST_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testOrderBulkRequestDtoReducer = (state = { testOrderBulkRequestDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case TEST_ORDER_BULK_REQUEST_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case TEST_ORDER_BULK_REQUEST_DTO_SUCCESS:
        return { ...state, loading: false, testOrderBulkRequestDto: action.payload };
      case TEST_ORDER_BULK_REQUEST_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case TEST_ORDER_BULK_REQUEST_DTO_RESET:
        return { testOrderBulkRequestDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const testOrderBulkRequestDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_DELETE_REQUEST:
            return { loading: true }

        case TEST_ORDER_BULK_REQUEST_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_ORDER_BULK_REQUEST_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testOrderBulkRequestCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_CREATE_REQUEST:
            return { loading: true }

        case TEST_ORDER_BULK_REQUEST_CREATE_SUCCESS:
            return { loading: false, success: true, testOrderBulkRequest: action.payload }

        case TEST_ORDER_BULK_REQUEST_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_ORDER_BULK_REQUEST_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testOrderBulkRequestUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_ORDER_BULK_REQUEST_UPDATE_REQUEST:
            return { loading: true }

        case TEST_ORDER_BULK_REQUEST_UPDATE_SUCCESS:
            return { loading: false, success: true, testOrderBulkRequest: action.payload }

        case TEST_ORDER_BULK_REQUEST_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_ORDER_BULK_REQUEST_UPDATE_RESET:
            return { testOrderBulkRequest: {} }

        default:
            return state
    }
}