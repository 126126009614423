import axios from 'axios';
import {
  TEMPLATE_LIST_REQUEST,
  TEMPLATE_LIST_SUCCESS,
  TEMPLATE_LIST_FAIL,
  TEMPLATE_DETAILS_REQUEST,
  TEMPLATE_DETAILS_SUCCESS,
  TEMPLATE_DETAILS_FAIL,
  TEMPLATE_CREATE_REQUEST,
  TEMPLATE_CREATE_SUCCESS,
  TEMPLATE_CREATE_FAIL,
  TEMPLATE_UPDATE_REQUEST,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAIL,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_FAIL,
  TEMPLATE_PREVIEW_REQUEST,
  TEMPLATE_PREVIEW_SUCCESS,
  TEMPLATE_PREVIEW_FAIL,
  GENERIC_TEMPLATE_LIST_REQUEST,
  GENERIC_TEMPLATE_LIST_SUCCESS,
  GENERIC_TEMPLATE_LIST_FAIL,
  GENERIC_TEMPLATE_DETAILS_REQUEST,
  GENERIC_TEMPLATE_DETAILS_SUCCESS,
  GENERIC_TEMPLATE_DETAILS_FAIL,
  GENERIC_TEMPLATE_CREATE_REQUEST,
  GENERIC_TEMPLATE_CREATE_SUCCESS,
  GENERIC_TEMPLATE_CREATE_FAIL,
  GENERIC_TEMPLATE_UPDATE_REQUEST,
  GENERIC_TEMPLATE_UPDATE_SUCCESS,
  GENERIC_TEMPLATE_UPDATE_FAIL,
  GENERIC_TEMPLATE_DELETE_REQUEST,
  GENERIC_TEMPLATE_DELETE_SUCCESS,
  GENERIC_TEMPLATE_DELETE_FAIL,

} from '../constants/templateConstants';

export const listTemplates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/print/templates', config);
    dispatch({ type: TEMPLATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEMPLATE_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getTemplateDetails = (name) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data: templateData } = await axios.get(`/print/templates/${name}`, config);
    const { data: configData } = await axios.get(`/print/configs/${name}`, config);
    

    dispatch({ 
      type: TEMPLATE_DETAILS_SUCCESS, 
      payload: { 
        name: name,
        templateData: templateData.content, 
        jsonConfig: configData.content 
      } 
    });
  } catch (error) {
    dispatch({
      type: TEMPLATE_DETAILS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const createTemplate = (templateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.post('/print/templates', { name: templateData.name, content: templateData.hbsTemplate }, config);
    await axios.post('/print/configs', { name: templateData.name, content: templateData.jsonConfig }, config);
    
    dispatch({ type: TEMPLATE_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: TEMPLATE_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const updateTemplate = (name, templateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/print/templates/${name}`, { content: templateData.hbsTemplate }, config);
    await axios.put(`/print/configs/${name}`, { content: templateData.jsonConfig }, config);
    
    dispatch({ type: TEMPLATE_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: TEMPLATE_UPDATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const deleteTemplate = (name) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/print/templates/${name}`, config);
    await axios.delete(`/print/configs/${name}`, config);
    
    dispatch({ type: TEMPLATE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: TEMPLATE_DELETE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const previewTemplate = (templateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TEMPLATE_PREVIEW_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post('/print/templates/preview', templateData, config);
    dispatch({ type: TEMPLATE_PREVIEW_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: TEMPLATE_PREVIEW_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
    throw error;
  }
};

export const listGenericTemplates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERIC_TEMPLATE_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/dotnet_api/v1/template/all', config);
    dispatch({ type: GENERIC_TEMPLATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GENERIC_TEMPLATE_LIST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getGenericTemplateDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERIC_TEMPLATE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/dotnet_api/v1/template/${id}`, config);

    dispatch({ type: GENERIC_TEMPLATE_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GENERIC_TEMPLATE_DETAILS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const createGenericTemplate = (templateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERIC_TEMPLATE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post('/dotnet_api/v1/template', templateData, config);
    
    dispatch({ type: GENERIC_TEMPLATE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GENERIC_TEMPLATE_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const updateGenericTemplate = (id, templateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERIC_TEMPLATE_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(`/dotnet_api/v1/template/${id}`, templateData, config);
    
    dispatch({ type: GENERIC_TEMPLATE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GENERIC_TEMPLATE_UPDATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const deleteGenericTemplate = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GENERIC_TEMPLATE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/dotnet_api/v1/template/${id}`, config);
    
    dispatch({ type: GENERIC_TEMPLATE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: GENERIC_TEMPLATE_DELETE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

