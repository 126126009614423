import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card, Spin, message, Button, Modal, Tooltip, Space } from 'antd';
import { Document, Page, PDFViewer } from '@react-pdf/renderer';
import { FilePdfOutlined, ReloadOutlined, FullscreenOutlined, FullscreenExitOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';


export function replacePlaceholders(config, dataMap) {
  const updatedConfig = JSON.parse(JSON.stringify(config));

  function traverseAndPopulate(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (obj[key].type === 'collection' && Array.isArray(dataMap[key])) {
          obj[key].content.items = dataMap[key];
        }
        else if (obj[key].type === 'groupedCollection' && Array.isArray(dataMap[key])) {
          obj[key].content.items = dataMap[key];
        } else if (obj[key].type === 'bloc' && dataMap[key] && dataMap[key].content) {
          obj[key].content = dataMap[key].content;
        } else {
          traverseAndPopulate(obj[key]);
        }
      }
    }
  }

  traverseAndPopulate(updatedConfig);

  return updatedConfig;
}
const Printer = ({ configEndpoint, generateEndpoint, data }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);
  const [useIframe, setUseIframe] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  const generatePDF = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data: configData } = await axios.get(configEndpoint, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      });

      const request = replacePlaceholders(configData.content, data);

      const pdfResponse = await axios.post(generateEndpoint, request, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        },
        responseType: 'blob',
      });

      const contentType = pdfResponse.headers['content-type'];
      if (contentType !== 'application/pdf') {
        throw new Error(t('Received non-PDF response: {{contentType}}', { contentType }));
      }

      const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
    } catch (error) {
      console.error(t('Error fetching config or generating PDF:'), error);
      setError(error.message || t('Failed to generate PDF'));
      message.error(t('Failed to generate PDF'));
    } finally {
      setLoading(false);
    }
  };

  const toggleViewerType = () => {
    setUseIframe(!useIframe);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderPDFViewer = () => {
    const viewerStyle = isFullscreen
      ? { width: '100%', height: 'calc(100vh - 64px)' }
      : { width: '100%', height: '70vh' };

    return useIframe ? (
      <iframe
        src={pdfUrl}
        style={viewerStyle}
        title={t('PDF Viewer')}
      />
    ) : (
      <PDFViewer style={viewerStyle}>
        <Document file={pdfUrl}>
          <Page pageNumber={1} />
        </Document>
      </PDFViewer>
    );
  };

  return (
    <Card
      title={<Space><FilePdfOutlined /> {t('PDF Printer')}</Space>}
      extra={
        <Space>
          <Tooltip title={t('Generate PDF')}>
            <Button icon={<ReloadOutlined />} onClick={generatePDF} disabled={loading} />
          </Tooltip>
          {pdfUrl && (
            <>
              <Tooltip title={useIframe ? t('Switch to PDFViewer') : t('Switch to Browser PDF Viewer')}>
                <Button icon={useIframe ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={toggleViewerType} />
              </Tooltip>
              <Tooltip title={isFullscreen ? t('Exit Fullscreen') : t('Fullscreen')}>
                <Button icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={toggleFullscreen} />
              </Tooltip>
            </>
          )}
        </Space>
      }
    >
      <Modal
        visible={isFullscreen}
        onCancel={toggleFullscreen}
        footer={null}
        width="100%"
        style={{ top: 0, paddingBottom: 0 }}
        bodyStyle={{ height: '100vh', padding: 0 }}
      >
        {pdfUrl && renderPDFViewer()}
      </Modal>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <Spin size="large" tip={t('Generating PDF...')} />
        </div>
      ) : error ? (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <p style={{ color: 'red' }}>{t('Error')}: {error}</p>
          <Button onClick={generatePDF}>{t('Try Again')}</Button>
        </div>
      ) : pdfUrl ? (
        renderPDFViewer()
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
          <Button icon={<FilePdfOutlined />} onClick={generatePDF} size="large">
            {t('Generate PDF')}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default Printer;
