export const AETIOLOGY_LIST_REQUEST = 'AETIOLOGY_LIST_REQUEST'
export const AETIOLOGY_LIST_SUCCESS = 'AETIOLOGY_LIST_SUCCESS'
export const AETIOLOGY_LIST_FAIL = 'AETIOLOGY_LIST_FAIL'


export const AETIOLOGY_DETAILS_REQUEST = 'AETIOLOGY_DETAILS_REQUEST'
export const AETIOLOGY_DETAILS_SUCCESS = 'AETIOLOGY_DETAILS_SUCCESS'
export const AETIOLOGY_DETAILS_FAIL = 'AETIOLOGY_DETAILS_FAIL'


export const AETIOLOGY_DELETE_REQUEST = 'AETIOLOGY_DELETE_REQUEST'
export const AETIOLOGY_DELETE_SUCCESS = 'AETIOLOGY_DELETE_SUCCESS'
export const AETIOLOGY_DELETE_FAIL = 'AETIOLOGY_DELETE_FAIL'

export const AETIOLOGY_CREATE_REQUEST = 'AETIOLOGY_CREATE_REQUEST'
export const AETIOLOGY_CREATE_SUCCESS = 'AETIOLOGY_CREATE_SUCCESS'
export const AETIOLOGY_CREATE_FAIL = 'AETIOLOGY_CREATE_FAIL'
export const AETIOLOGY_CREATE_RESET = 'AETIOLOGY_CREATE_RESET'

export const AETIOLOGY_UPDATE_REQUEST = 'AETIOLOGY_UPDATE_REQUEST'
export const AETIOLOGY_UPDATE_SUCCESS = 'AETIOLOGY_UPDATE_SUCCESS'
export const AETIOLOGY_UPDATE_FAIL = 'AETIOLOGY_UPDATE_FAIL'
export const AETIOLOGY_UPDATE_RESET = 'AETIOLOGY_UPDATE_RESET'

export const AETIOLOGY_REQUEST = 'AETIOLOGY_REQUEST'
export const AETIOLOGY_SUCCESS = 'AETIOLOGY_SUCCESS'
export const AETIOLOGY_FAIL = 'AETIOLOGY_FAIL'
export const AETIOLOGY_RESET = 'AETIOLOGY_RESET'

export const AETIOLOGY_COUNT_REQUEST = "AETIOLOGY_COUNT_REQUEST";
export const AETIOLOGY_COUNT_SUCCESS = "AETIOLOGY_COUNT_SUCCESS";
export const AETIOLOGY_COUNT_FAIL = "AETIOLOGY_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const AETIOLOGY_DTO_REQUEST = 'AETIOLOGY_DTO_REQUEST';
export const AETIOLOGY_DTO_SUCCESS = 'AETIOLOGY_DTO_SUCCESS';
export const AETIOLOGY_DTO_FAIL = 'AETIOLOGY_DTO_FAIL';
export const AETIOLOGY_DTO_RESET = 'AETIOLOGY_DTO_RESET';

export const AETIOLOGY_LIST_BYIDS_REQUEST = 'AETIOLOGY_LIST_BYIDS_REQUEST';
export const AETIOLOGY_LIST_BYIDS_SUCCESS = 'AETIOLOGY_LIST_BYIDS_SUCCESS';
export const AETIOLOGY_LIST_BYIDS_FAIL = 'AETIOLOGY_LIST_BYIDS_FAIL';
export const AETIOLOGY_LIST_BYIDS_RESET = 'AETIOLOGY_LIST_BYIDS_RESET';