import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Typography, message, Affix, Tag, Statistic, Modal } from 'antd';
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { listInvoiceReports, getInvoiceReportDetails, updateInvoiceReport, loadInvoiceReportDto, deleteInvoiceReport } from '../../actions/invoiceReportActions';
import { listInvoiceLines, listInvoiceLinesByIds, loadInvoiceLineDto } from '../../actions/invoiceLineActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';
import Printer from '../../components/printer/Printer.component';
import { barcodeToBase64 } from '../../utils/functions';
const { Title } = Typography;

export default function InvoiceReportsScreen({ history }) {
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState({
    selectedInvoiceId: null,
    invoiceReport: null,
    invoiceLines: [],
    editMode: 'Lookup',
    printerVisible: false,
    documentData: {},
  });

  const [invoiceListFilter, setInvoiceListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'InvoiceNumber',
  });

  const invoiceReportList = useSelector((state) => state.invoiceReportList);
  const { loading, error, invoiceReports, currentPage, totalPages, statistics } = invoiceReportList;

  const invoiceReportDetails = useSelector((state) => state.invoiceReportDetails);
  const { loading: loadingDetails, error: errorDetails } = invoiceReportDetails;

  // get userInfo from state
  const {userInfo} = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (currentState.editMode === 'Lookup') {
      dispatch(listInvoiceReports(invoiceListFilter));
    }
  }, [dispatch, invoiceListFilter, currentState.editMode]);

  useEffect(() => {
    if (currentState.selectedInvoiceId) {
      dispatch(getInvoiceReportDetails(currentState.selectedInvoiceId));
    }
  }, [dispatch, currentState.selectedInvoiceId]);

  useEffect(() => {
    if (currentState.invoiceReport && currentState.invoiceReport.invoiceLineIds) {
      dispatch(listInvoiceLinesByIds(currentState.invoiceReport.invoiceLineIds));
    }
  }, [dispatch, currentState.invoiceReport]);

  const handleInvoiceSelect = (id) => {
    setCurrentState(prevState => ({
      ...prevState,
      selectedInvoiceId: id,
      editMode: 'Edit'
    }));
  };

  const handleBackToList = () => {
    setCurrentState(prevState => ({
      ...prevState,
      selectedInvoiceId: null,
      invoiceReport: null,
      invoiceLines: [],
      editMode: 'Lookup'
    }));
  };

  const handleDeleteInvoiceReport = (id) => {
    dispatch(deleteInvoiceReport(id));
    setInvoiceListFilter({ query: '', page: 1, limit: 10, filter: 'InvoiceNumber' });
  };

  const handlePrint = async () => {
    if (currentState.invoiceReport) {
      const barcodeImage = await barcodeToBase64(currentState.invoiceReport.invoiceNumber.split(', ')[0], {
        format: 'CODE128',
        width: 2,
        height: 50,
        fontSize: 12
      });

      const data = {
        "invoiceHeader": {
          content: {
            ...currentState.invoiceReport,
            barcode: currentState.invoiceReport.barcode || currentState.invoiceReport.invoiceNumber,
          },
        },
        "invoiceSummary": {
          content: currentState.invoiceReport,
        },
        "customerInfo":
        {
          content: {
            ...currentState.invoiceReport,
          },
        },
        "invoiceLines": currentState.invoiceLines?.map(line => ({
          content: line,
          template: line.template || null
        })),
        "barcode": {
          content: {
            barcodeImage: barcodeImage,
          }
        }
      };

      setCurrentState(prevState => ({
        ...prevState,
        documentData: data,
        printerVisible: true
      }));
    }
  };

  const renderStatistics = () => {
    if (!statistics) return null;

    return (
      <Card title="Invoice Statistics" style={{ marginBottom: 16 }}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Statistic title="Total Invoices" value={statistics.totalCount} />
          </Col>
          <Col span={8}>
            <Statistic title="Total Amount" value={statistics.total.sum} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col span={8}>
            <Statistic title="Total Balance Due" value={statistics.balanceDue.sum} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col span={8}>
            <Statistic title="Average Invoice Amount" value={statistics.total.average} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col span={8}>
            <Statistic title="Largest Invoice" value={statistics.total.max} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col span={8}>
            <Statistic title="Smallest Invoice" value={statistics.total.min} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
          Back
        </Button>
        <Title level={2}>Invoice Reports</Title>
      </Space>
      {currentState.editMode === 'Lookup' ? (
        <div style={{ marginTop: 16 }}>
          {renderStatistics()}
          <ListItemsDataTable
            loading={loading}
            dtoConfig={{
              InvoiceNumber: { isSummaryProp: true },
              CustomerName: { isSummaryProp: true },
              Total: { isSummaryProp: true },
              InvoiceStatus: { isSummaryProp: true },
            }}
            data={invoiceReports}
            filter={invoiceListFilter}
            onFilterChange={setInvoiceListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={invoiceListFilter.limit}
            handleEditForm={handleInvoiceSelect}
            handleDeleteRow={handleDeleteInvoiceReport}
            enableActionButtons={true}
          />
        </div>
      ) : (
        <>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleBackToList} style={{ marginBottom: 16 }}>
            Back to Invoice List
          </Button>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={18}>
              {currentState.invoiceReport && currentState.invoiceReport.invoiceLineIds && (
                <Card title="Invoice Lines" style={{ marginBottom: 16 }}>
                  <DynamicFormSP
                    key={`invoice-lines-${currentState.selectedInvoiceId}`}
                    defaultEditMode="Lookup"
                    listingMode='selectedIds'
                    selectedItems={[]}
                    selectedItemIds={currentState.invoiceReport.invoiceLineIds}
                    primarySearchProp="itemDescription"
                    secondarySearchProp="quantity"
                    singularEntity="invoiceLine"
                    pluralEntity="invoiceLines"
                    constantPrefix="INVOICE_LINE"
                    loadDto={loadInvoiceLineDto}
                    listEntities={listInvoiceLines}
                    listEntitiesByIds={listInvoiceLinesByIds}
                    isParent={false}
                    onSelectedEntitiesChange={(selectedItems) => {
                      setCurrentState(prevState => ({
                        ...prevState,
                        invoiceLines: selectedItems
                      }));
                    }}
                    fieldsToHide={['Id', 'InvoiceReportId']}
                    summaryProps={['itemDescription', 'quantity', 'unitPrice', 'lineTotal']}
                    enableCreate={false}
                    enableSearchBar={false}
                    enableActionButtons={false}
                  />
                </Card>
              )}
              <Card title="Invoice Details">
                <DynamicFormSP
                  key={currentState.selectedInvoiceId}
                  currentEntityId={currentState.selectedInvoiceId}
                  defaultEditMode={currentState.editMode}
                  primarySearchProp="invoiceNumber"
                  secondarySearchProp="customerName"
                  singularEntity="invoiceReport"
                  pluralEntity="invoiceReports"
                  constantPrefix="INVOICE_REPORT"
                  loadDto={loadInvoiceReportDto}
                  updateEntity={updateInvoiceReport}
                  getEntityDetails={getInvoiceReportDetails}
                  listEntities={listInvoiceReports}
                  listEntitiesByIds={listInvoiceReports}
                  onUpdatedEntityChange={(updatedEntity) => {
                    setCurrentState(prevState => ({
                      ...prevState,
                      invoiceReport: updatedEntity,
                    }));
                  }}
                  isParent={true}
                  fieldsToHide={['Id', 'InvoiceLineIds']}
                  summaryProps={['invoiceNumber', 'customerName', 'total', 'invoiceStatus']}
                  enableCreate={false}
                  enableSearchBar={false}
                  enableActionButtons={true}
                />
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Affix offsetTop={80}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Card>
                    <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint} block>
                      Print Invoice
                    </Button>
                  </Card>
                  <InvoiceSummaryCard currentState={currentState} />
                </Space>
              </Affix>
            </Col>
          </Row>
        </>
      )}
      <PrinterModal currentState={currentState} setCurrentState={setCurrentState} />
    </div>
  );
}

function InvoiceSummaryCard({ currentState }) {
  if (!currentState.invoiceReport) return null;

  return (
    <Card title="Invoice Summary">
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <span>Invoice Number:</span>
          <Tag color="blue">{currentState.invoiceReport.invoiceNumber}</Tag>
        </div>
        <div>
          <span>Customer Name:</span>
          {currentState.invoiceReport.customerName}
        </div>
        <div>
          <span>Total: </span>
          {currentState.invoiceReport.total}
        </div>
        <div>
          <span>Invoice Status:</span>
          <Tag color="blue">{currentState.invoiceReport.invoiceStatus}</Tag>
        </div>
      </Space>
    </Card>
  );
}

function PrinterModal({ currentState, setCurrentState }) {
  const handlePrintCancel = () => {
    setCurrentState(prevState => ({
      ...prevState,
      printerVisible: false
    }));
  };

  return (
    <Modal
      title="Print Invoice"
      visible={currentState.printerVisible}
      onCancel={handlePrintCancel}
      footer={null}
      width={800}
    >
      <Printer 
        data={currentState.documentData}
        configEndpoint="/print/configs/invoice"
        generateEndpoint="/print/documents/generate"      
        />
    </Modal>
  );
}


