import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { listTestCategories, createTestCategory, updateTestCategory, deleteTestCategory, getTestCategoryDetails, loadTestCategoryDto } from '../../actions/testCategoryActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function TestCategoryListScreen({ history }) {
useEffect(() => {
  document.title = "Test Categories Management";
}, []);

const dispatch = useDispatch();

const [selectedTestId, setSelectedTestId] = useState(null);
const [editMode, setEditMode] = useState('Lookup');
const [limit, setLimit] = useState(10);
const [showDynamicForm, setShowDynamicForm] = useState(false);
const [testCategoryListFilter, setTestCategoryListFilter] = useState({
  query: '',
  page: 1,
  limit: 10,
  filter: 'name'
});

const testCategoryList = useSelector((state) => state.testCategoryList);
const { loading, error, testCategories, currentPage, totalPages } = testCategoryList;

const testCategoryDto = useSelector((state) => state.testCategoryDto);
const { testCategoryDto: testCategoryDtoConfig } = testCategoryDto;

useEffect(() => {
  dispatch(loadTestCategoryDto());
  dispatch(listTestCategories(testCategoryListFilter));
}, [dispatch, testCategoryListFilter]);

const handleDelete = (id) => {
  dispatch(deleteTestCategory(id));
  setTestCategoryListFilter({ query: '', page: 1, limit: 10, filter: 'TestId' });
};

const handleEditForm = (id) => {
  setSelectedTestId(id);
  setEditMode('Edit');
  setShowDynamicForm(true);
};

const handleCancelForm = () => {
  setSelectedTestId(null);
  setShowDynamicForm(false);
};

const handleCreateTestCategory = () => {
  setSelectedTestId(null);
  setEditMode('Create');
  setShowDynamicForm(true);
};

return (
  <React.Fragment>
    <Space direction="vertical" style={{ width: "100%" }}>
      <h2>Test Category Management</h2>
    </Space>
    {!showDynamicForm && (
      <React.Fragment>
        <Button
          type="primary"
          onClick={handleCreateTestCategory}
          icon={<PlusOutlined />}
          size="large"
        >
          Create Test Category
        </Button>

        <ListItemsDataTable
          loading={loading}
          dtoConfig={testCategoryDtoConfig}
          data={testCategories}
          filter={testCategoryListFilter}
          onFilterChange={setTestCategoryListFilter}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          handleDeleteRow={(id) => { handleDelete(id) }}
          onStartEditRow={(id) => { setSelectedTestId(id) }}
          handleSaveRow={(updatedRow) => {
            dispatch(updateTestCategory(updatedRow.id, updatedRow));
          }}
          onDataUpdated={(updatedRow) => {
            dispatch(getTestCategoryDetails(updatedRow.id));
          }}
          handleEditForm={handleEditForm}
        />
      </React.Fragment>
    )}

    {showDynamicForm && (
      <React.Fragment>
        <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
            Back to Test Categories
          </Button>
        </Space>

        <DynamicFormSP
          currentEntityId={selectedTestId}
          selectedItems={null}
          selectedEntityIds={null}
          listingMode='allEntities'
          defaultEditMode={editMode}
          primarySearchProp="name"
          secondarySearchProp="testType"
          singularEntity="testCategory"
          pluralEntity="testCategories"
          constantPrefix="TEST_CATEGORY"
          onSelectedItems={(selectedItems) => { }}
          loadDto={loadTestCategoryDto}
          createEntity={createTestCategory}
          listEntitiesByIds={listTestCategories}
          updateEntity={updateTestCategory}
          deleteEntity={deleteTestCategory}
          getEntityDetails={getTestCategoryDetails}
          listEntities={listTestCategories}
          onSelectedEntitiesChange={(e) => { }}
          onUpdatedEntityChange={(e) => { }}
          onNewEntityChange={(e) => { }}
          isParent={true}
          fieldsToHide={['Id']}
          summaryProps={['testId', 'testType', 'collectionDate', 'status']}
          enableCreate={true}
          enableSearchBar={false}
        />
      </React.Fragment>
    )}
  </React.Fragment>
);
}
