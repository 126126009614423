import {
    SAMPLE_INVENTORY_LINE_LIST_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_FAIL,
    SAMPLE_INVENTORY_LINE_DETAILS_REQUEST,
    SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS,
    SAMPLE_INVENTORY_LINE_DETAILS_FAIL,
    SAMPLE_INVENTORY_LINE_DELETE_REQUEST,
    SAMPLE_INVENTORY_LINE_DELETE_SUCCESS,
    SAMPLE_INVENTORY_LINE_DELETE_FAIL,
    SAMPLE_INVENTORY_LINE_CREATE_REQUEST,
    SAMPLE_INVENTORY_LINE_CREATE_SUCCESS,
    SAMPLE_INVENTORY_LINE_CREATE_FAIL,
    SAMPLE_INVENTORY_LINE_CREATE_RESET,
    SAMPLE_INVENTORY_LINE_UPDATE_REQUEST,
    SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS,
    SAMPLE_INVENTORY_LINE_UPDATE_FAIL,
    SAMPLE_INVENTORY_LINE_UPDATE_RESET,
    SAMPLE_INVENTORY_LINE_DTO_REQUEST,
    SAMPLE_INVENTORY_LINE_DTO_SUCCESS,
    SAMPLE_INVENTORY_LINE_DTO_FAIL,
    SAMPLE_INVENTORY_LINE_DTO_RESET,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_RESET,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL,
    
} from "../constants/sampleInventoryLinesConstants";

export const sampleInventoryLineListReducer = (state = { sampleInventoryLines: [] }, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_LIST_REQUEST:
            return { loading: true, sampleInventoryLines: [] };
        case SAMPLE_INVENTORY_LINE_LIST_SUCCESS:
            return {
                loading: false,
                sampleInventoryLines: action.payload.sampleInventoryLines,
                totalPages: action.payload.totalPages,
                totalCount: action.payload.totalCount,
                currentPage: action.payload.currentPage,
            };
        case SAMPLE_INVENTORY_LINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const sampleInventoryLineListByIDsReducer = (state = { sampleInventoryLines: [] }, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST:
            return { loading: true, sampleInventoryLines: [] };
        case SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS:
            return { loading: false, sampleInventoryLines: action.payload };
        case SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case SAMPLE_INVENTORY_LINE_LIST_BYIDS_RESET:
            return { sampleInventoryLines: [] };
        default:
            return state;
    }
}

export const sampleInventoryLineListByBarcodeReducer = (state = { sampleInventoryLines: [] }, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST:
            return { loading: true, sampleInventoryLines: [] };
        case SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS:
            return {
                loading: false,
                sampleInventoryLines: action.payload
            };
        case SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const sampleInventoryLineDetailsReducer = (state = { sampleInventoryLine: {} }, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS:
            return { loading: false, sampleInventoryLine: action.payload };
        case SAMPLE_INVENTORY_LINE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const sampleInventoryLineDtoReducer = (state = { sampleInventoryLineDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case SAMPLE_INVENTORY_LINE_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case SAMPLE_INVENTORY_LINE_DTO_SUCCESS:
        return { ...state, loading: false, sampleInventoryLineDto: action.payload };
      case SAMPLE_INVENTORY_LINE_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case SAMPLE_INVENTORY_LINE_DTO_RESET:
        return { sampleInventoryLineDto: {}, loading: false, error: null };
      default:
        return state;
    }
};

export const sampleInventoryLineDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_DELETE_REQUEST:
            return { loading: true }
        case SAMPLE_INVENTORY_LINE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case SAMPLE_INVENTORY_LINE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const sampleInventoryLineCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_CREATE_REQUEST:
            return { loading: true }
        case SAMPLE_INVENTORY_LINE_CREATE_SUCCESS:
            return { loading: false, success: true, sampleInventoryLine: action.payload }
        case SAMPLE_INVENTORY_LINE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case SAMPLE_INVENTORY_LINE_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const sampleInventoryLineUpdateReducer = (state = { sampleInventoryLine: {} }, action) => {
    switch (action.type) {
        case SAMPLE_INVENTORY_LINE_UPDATE_REQUEST:
            return { loading: true }
        case SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS:
            return { loading: false, success: true, sampleInventoryLine: action.payload }
        case SAMPLE_INVENTORY_LINE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case SAMPLE_INVENTORY_LINE_UPDATE_RESET:
            return { sampleInventoryLine: {} }
        default:
            return state
    }
}