import axios from 'axios';
import {
    BUSINESS_ORGANISATION_LIST_REQUEST,
    BUSINESS_ORGANISATION_LIST_SUCCESS,
    BUSINESS_ORGANISATION_LIST_FAIL,
    BUSINESS_ORGANISATION_DETAILS_REQUEST,
    BUSINESS_ORGANISATION_DETAILS_SUCCESS,
    BUSINESS_ORGANISATION_DETAILS_FAIL,
    BUSINESS_ORGANISATION_CREATE_REQUEST,
    BUSINESS_ORGANISATION_CREATE_SUCCESS,
    BUSINESS_ORGANISATION_CREATE_FAIL,
    BUSINESS_ORGANISATION_UPDATE_REQUEST,
    BUSINESS_ORGANISATION_UPDATE_SUCCESS,
    BUSINESS_ORGANISATION_UPDATE_FAIL,
    BUSINESS_ORGANISATION_DELETE_REQUEST,
    BUSINESS_ORGANISATION_DELETE_SUCCESS,
    BUSINESS_ORGANISATION_DELETE_FAIL,
    BUSINESS_ORGANISATION_DTO_REQUEST,
    BUSINESS_ORGANISATION_DTO_SUCCESS,
    BUSINESS_ORGANISATION_DTO_FAIL,
    BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST,
    BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS,
    BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL,

    BUSINESS_ORGANISATION_ACTIVATE_REQUEST,
    BUSINESS_ORGANISATION_ACTIVATE_SUCCESS,
    BUSINESS_ORGANISATION_ACTIVATE_FAIL,
    BUSINESS_ORGANISATION_DEACTIVATE_REQUEST,
    BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS,
    BUSINESS_ORGANISATION_DEACTIVATE_FAIL,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
    
} from '../constants/businessOrganisationConstants';

export const listBusinessOrganisations = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_LIST_REQUEST });
        const { userLogin: { userInfo } } = getState();

        let params = filter || {};

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        };

        const { data } = await axios.get('/dotnet_api/v1/businessOrganisation/all', config);

        dispatch({
            type: BUSINESS_ORGANISATION_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const listBusinessOrganisationsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post('/dotnet_api/v1/businessOrganisation/ids/all', ids, config);

        dispatch({
            type: BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getBusinessOrganisationDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_DETAILS_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get(`/dotnet_api/v1/businessOrganisation/${id}`, config);

        dispatch({
            type: BUSINESS_ORGANISATION_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const createBusinessOrganisation = (businessOrganisation) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_CREATE_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post('/dotnet_api/v1/businessOrganisation', businessOrganisation, config);

        dispatch({
            type: BUSINESS_ORGANISATION_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: BUSINESS_ORGANISATION_CREATE_FAIL,
            payload: errorMessages,
        });
    }
};

export const updateBusinessOrganisation = (id, changes) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_UPDATE_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.patch(`/dotnet_api/v1/businessOrganisation/${id}`, changes, config);

        dispatch({
            type: BUSINESS_ORGANISATION_UPDATE_SUCCESS,
            payload: data
        });

        dispatch({
            type: BUSINESS_ORGANISATION_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: BUSINESS_ORGANISATION_UPDATE_FAIL,
            payload: errorMessages,
        });
    }
};

export const deleteBusinessOrganisation = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_DELETE_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        await axios.delete(`/dotnet_api/v1/businessOrganisation/${id}`, config);

        dispatch({ type: BUSINESS_ORGANISATION_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const loadBusinessOrganisationDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_DTO_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.get('/dotnet_api/v1/businessOrganisation/businessOrganisationDto', config);

        dispatch({
            type: BUSINESS_ORGANISATION_DTO_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const activateBusinessOrganisation = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_ACTIVATE_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post(`/dotnet_api/v1/businessOrganisation/${id}/reactivate`, {}, config);

        dispatch({
            type: BUSINESS_ORGANISATION_ACTIVATE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_ACTIVATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const deactivateBusinessOrganisation = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUSINESS_ORGANISATION_DEACTIVATE_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post(`/dotnet_api/v1/businessOrganisation/${id}/deactivate`, {}, config);

        dispatch({
            type: BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: BUSINESS_ORGANISATION_DEACTIVATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const sendEmail = (content) => async (dispatch, getState) => {
    try {
        dispatch({ type: SEND_EMAIL_REQUEST });
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.post('/dotnet_api/v1/auth/sendmail',content , config);

        dispatch({
            type: SEND_EMAIL_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SEND_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

