export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST'
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS'
export const PAYMENT_LIST_FAIL = 'PAYMENT_LIST_FAIL'


export const PAYMENT_DETAILS_REQUEST = 'PAYMENT_DETAILS_REQUEST'
export const PAYMENT_DETAILS_SUCCESS = 'PAYMENT_DETAILS_SUCCESS'
export const PAYMENT_DETAILS_FAIL = 'PAYMENT_DETAILS_FAIL'


export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST'
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS'
export const PAYMENT_DELETE_FAIL = 'PAYMENT_DELETE_FAIL'

export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAIL = 'PAYMENT_CREATE_FAIL'
export const PAYMENT_CREATE_RESET = 'PAYMENT_CREATE_RESET'

export const PAYMENT_UPDATE_REQUEST = 'PAYMENT_UPDATE_REQUEST'
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS'
export const PAYMENT_UPDATE_FAIL = 'PAYMENT_UPDATE_FAIL'
export const PAYMENT_UPDATE_RESET = 'PAYMENT_UPDATE_RESET'

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_FAIL = 'PAYMENT_FAIL'
export const PAYMENT_RESET = 'PAYMENT_RESET'

export const PAYMENT_COUNT_REQUEST = "PAYMENT_COUNT_REQUEST";
export const PAYMENT_COUNT_SUCCESS = "PAYMENT_COUNT_SUCCESS";
export const PAYMENT_COUNT_FAIL = "PAYMENT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const PAYMENT_DTO_REQUEST = 'PAYMENT_DTO_REQUEST';
export const PAYMENT_DTO_SUCCESS = 'PAYMENT_DTO_SUCCESS';
export const PAYMENT_DTO_FAIL = 'PAYMENT_DTO_FAIL';
export const PAYMENT_DTO_RESET = 'PAYMENT_DTO_RESET';

export const PAYMENT_LIST_BYIDS_REQUEST = 'PAYMENT_LIST_BYIDS_REQUEST';
export const PAYMENT_LIST_BYIDS_SUCCESS = 'PAYMENT_LIST_BYIDS_SUCCESS';
export const PAYMENT_LIST_BYIDS_FAIL = 'PAYMENT_LIST_BYIDS_FAIL';
export const PAYMENT_LIST_BYIDS_RESET = 'PAYMENT_LIST_BYIDS_RESET';