import React, { useEffect } from "react";
import { Typography, Row, Col, Button, Result } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const NotFoundScreen = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("Page Not Found");
  }, [t]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={24} sm={20} md={16} lg={12} xl={8}>
        <Result
          status="404"
          title={<Title level={2}>{t("Page Not Found")}</Title>}
          subTitle={t("Sorry, the page you are looking for cannot be found.")}
          extra={
            <Link to="/">
              <Button type="primary" icon={<HomeOutlined />}>
                {t("Return to Dashboard")}
              </Button>
            </Link>
          }
        />
      </Col>
    </Row>
  );
};

export default NotFoundScreen;