import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Row, Col } from 'antd';
import CommonFooter from '../footer/CommonFooter.component';

const { Content } = Layout;

const NonAuthLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Content style={{ flex: 1, padding: '0 20px' }}>
        <Row justify="center" align="middle" style={{ minHeight: '100%', padding: '20px 0' }}>
          <Col xs={24} sm={20} md={16} lg={12} xl={10}>
            {props.children}
          </Col>
        </Row>
      </Content>
      <CommonFooter />
    </Layout>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.node,
};

export default NonAuthLayout;
