// reducers/sampleReducers.js

import {
    SAMPLE_LIST_REQUEST,
    SAMPLE_LIST_SUCCESS,
    SAMPLE_LIST_FAIL,

    SAMPLE_DETAILS_REQUEST,
    SAMPLE_DETAILS_SUCCESS,
    SAMPLE_DETAILS_FAIL,

    SAMPLE_DELETE_REQUEST,
    SAMPLE_DELETE_SUCCESS,
    SAMPLE_DELETE_FAIL,

    SAMPLE_CREATE_REQUEST,
    SAMPLE_CREATE_SUCCESS,
    SAMPLE_CREATE_FAIL,
    SAMPLE_CREATE_RESET,

    SAMPLE_UPDATE_REQUEST,
    SAMPLE_UPDATE_SUCCESS,
    SAMPLE_UPDATE_FAIL,
    SAMPLE_UPDATE_RESET,

    SET_CURRENT_PAGE,

    SAMPLE_DTO_REQUEST,
    SAMPLE_DTO_SUCCESS,
    SAMPLE_DTO_FAIL,
    SAMPLE_DTO_RESET,

    SAMPLE_LIST_BYIDS_REQUEST,
    SAMPLE_LIST_BYIDS_SUCCESS,
    SAMPLE_LIST_BYIDS_FAIL,
    SAMPLE_LIST_BYIDS_RESET
} from "../constants/sampleConstants";

export const sampleListReducer = (state = { samples: [] }, action) => {
    switch (action.type) {
        case SAMPLE_LIST_REQUEST:
            return { loading: true, samples: [] };
        case SAMPLE_LIST_SUCCESS:
            return {
                loading: false,
                samples: action.payload.samples,
                totalPages: action.payload.totalPages,
                totalCount: action.payload.totalCount,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case SAMPLE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const sampleListByIDsReducer = (state = { samples: [] }, action) => {
    switch (action.type) {
        case SAMPLE_LIST_BYIDS_REQUEST:
            return { loading: true, samples: [] };
        case SAMPLE_LIST_BYIDS_SUCCESS:
            return { loading: false, samples: action.payload };
        case SAMPLE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case SAMPLE_LIST_BYIDS_RESET:
            return { samples: [] };
        default:
            return state;
    }
}

export const sampleDetailsReducer = (state = { sample: { comments: [] } }, action) => {
    switch (action.type) {
        case SAMPLE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case SAMPLE_DETAILS_SUCCESS:
            return { loading: false, sample: action.payload };
        case SAMPLE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const sampleDtoReducer = (state = { sampleDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case SAMPLE_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case SAMPLE_DTO_SUCCESS:
        return { ...state, loading: false, sampleDto: action.payload };
      case SAMPLE_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case SAMPLE_DTO_RESET:
        return { sampleDto: {}, loading: false, error: null };
      default:
        return state;
    }
};

export const sampleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SAMPLE_DELETE_REQUEST:
            return { loading: true }
        case SAMPLE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case SAMPLE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const sampleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SAMPLE_CREATE_REQUEST:
            return { loading: true }
        case SAMPLE_CREATE_SUCCESS:
            return { loading: false, success: true, sample: action.payload }
        case SAMPLE_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case SAMPLE_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const sampleUpdateReducer = (state = { sample: {} }, action) => {
    switch (action.type) {
        case SAMPLE_UPDATE_REQUEST:
            return { loading: true }
        case SAMPLE_UPDATE_SUCCESS:
            return { loading: false, success: true, sample: action.payload }
        case SAMPLE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case SAMPLE_UPDATE_RESET:
            return { sample: {} }
        default:
            return state
    }
}