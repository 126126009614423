export const PRACTITIONER_LIST_REQUEST = 'PRACTITIONER_LIST_REQUEST'
export const PRACTITIONER_LIST_SUCCESS = 'PRACTITIONER_LIST_SUCCESS'
export const PRACTITIONER_LIST_FAIL = 'PRACTITIONER_LIST_FAIL'


export const PRACTITIONER_DETAILS_REQUEST = 'PRACTITIONER_DETAILS_REQUEST'
export const PRACTITIONER_DETAILS_SUCCESS = 'PRACTITIONER_DETAILS_SUCCESS'
export const PRACTITIONER_DETAILS_FAIL = 'PRACTITIONER_DETAILS_FAIL'


export const PRACTITIONER_DELETE_REQUEST = 'PRACTITIONER_DELETE_REQUEST'
export const PRACTITIONER_DELETE_SUCCESS = 'PRACTITIONER_DELETE_SUCCESS'
export const PRACTITIONER_DELETE_FAIL = 'PRACTITIONER_DELETE_FAIL'

export const PRACTITIONER_CREATE_REQUEST = 'PRACTITIONER_CREATE_REQUEST'
export const PRACTITIONER_CREATE_SUCCESS = 'PRACTITIONER_CREATE_SUCCESS'
export const PRACTITIONER_CREATE_FAIL = 'PRACTITIONER_CREATE_FAIL'
export const PRACTITIONER_CREATE_RESET = 'PRACTITIONER_CREATE_RESET'

export const PRACTITIONER_UPDATE_REQUEST = 'PRACTITIONER_UPDATE_REQUEST'
export const PRACTITIONER_UPDATE_SUCCESS = 'PRACTITIONER_UPDATE_SUCCESS'
export const PRACTITIONER_UPDATE_FAIL = 'PRACTITIONER_UPDATE_FAIL'
export const PRACTITIONER_UPDATE_RESET = 'PRACTITIONER_UPDATE_RESET'

export const PRACTITIONER_REQUEST = 'PRACTITIONER_REQUEST'
export const PRACTITIONER_SUCCESS = 'PRACTITIONER_SUCCESS'
export const PRACTITIONER_FAIL = 'PRACTITIONER_FAIL'
export const PRACTITIONER_RESET = 'PRACTITIONER_RESET'

export const PRACTITIONER_COUNT_REQUEST = "PRACTITIONER_COUNT_REQUEST";
export const PRACTITIONER_COUNT_SUCCESS = "PRACTITIONER_COUNT_SUCCESS";
export const PRACTITIONER_COUNT_FAIL = "PRACTITIONER_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const PRACTITIONER_DTO_REQUEST = 'PRACTITIONER_DTO_REQUEST';
export const PRACTITIONER_DTO_SUCCESS = 'PRACTITIONER_DTO_SUCCESS';
export const PRACTITIONER_DTO_FAIL = 'PRACTITIONER_DTO_FAIL';
export const PRACTITIONER_DTO_RESET = 'PRACTITIONER_DTO_RESET';

export const PRACTITIONER_LIST_BYIDS_REQUEST = 'PRACTITIONER_LIST_BYIDS_REQUEST';
export const PRACTITIONER_LIST_BYIDS_SUCCESS = 'PRACTITIONER_LIST_BYIDS_SUCCESS';
export const PRACTITIONER_LIST_BYIDS_FAIL = 'PRACTITIONER_LIST_BYIDS_FAIL';
export const PRACTITIONER_LIST_BYIDS_RESET = 'PRACTITIONER_LIST_BYIDS_RESET';