import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listFollowupCentres, createFollowupCentre, updateFollowupCentre, deleteFollowupCentre, getFollowupCentreDetails } from '../../actions/followupCentreActions';
// constants 
import { FOLLOWUP_CENTRE_CREATE_RESET, FOLLOWUP_CENTRE_UPDATE_RESET } from '../../constants/followupCentreConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function FollowupCentreCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the followupCentreCreate and followupCentreUpdate
    useEffect(() => {
        dispatch({ type: FOLLOWUP_CENTRE_CREATE_RESET });
        dispatch({ type: FOLLOWUP_CENTRE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/dotnet_api/v1/followupCentre/all?page=1&limit=2&filter=Email&query=gmail
    const [followupCentreListFilter, setfollowupCentreListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get followupCentre list from redux store
    const followupCentreList = useSelector((state) => state.followupCentreList);
    const { loading, error, followupCentres, currentPage, totalPages } = followupCentreList;

    // get followupCentre create from redux store
    const followupCentreCreate = useSelector((state) => state.followupCentreCreate);
    const { success: successCreate, error: followupCentreCreateErrors } = followupCentreCreate;

    // get followupCentre update from redux store
    const followupCentreUpdate = useSelector((state) => state.followupCentreUpdate);
    const { success: successUpdate, loading: followupCentreUpdateProcessing } = followupCentreUpdate;

    // get followupCentre delete from redux store
    const followupCentreDelete = useSelector((state) => state.followupCentreDelete);
    const { success: successDelete } = followupCentreDelete;

    // get followupCentre details from redux store
    const followupCentreDetails = useSelector((state) => state.followupCentreDetails);
    const { followupCentre: followupCentreDet, loading: followupCentreDetailsLoading } = followupCentreDetails;

    // newFollowupCentre state
    const [newFollowupCentre, setNewFollowupCentre] = useState({});
    const [updatedFollowupCentre, setUpdatedFollowupCentre] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listFollowupCentres(followupCentreListFilter));
    }, [dispatch, followupCentreListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setfollowupCentreListFilter({ ...followupCentreListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setfollowupCentreListFilter({ ...followupCentreListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setfollowupCentreListFilter({ ...followupCentreListFilter, filter: selectedFilter });
    };

    // get all followupCentres
    useEffect(() => {
        dispatch(listFollowupCentres({}));
    }, [dispatch]);

    // listen to followupCentreDet 
    useEffect(() => {
        if (followupCentreDet) {
            // use spread operator to copy the followupCentreDet object
            setUpdatedFollowupCentre({ ...followupCentreDet });
        }
    }, [followupCentreDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newFollowupCentre state
            setNewFollowupCentre({
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                email: '',
                phoneNumber: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this followupCentre?')) {
            dispatch(deleteFollowupCentre(id)).then(() => {
                dispatch(listFollowupCentres({}));
            }
            );
        }
    };

    // handle edit
    const handleEditFollowupCentre = (id) => {
        dispatch(getFollowupCentreDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateFollowupCentre = () => {
        dispatch(createFollowupCentre(newFollowupCentre)).then(() => {
            setUpdatedFollowupCentre(newFollowupCentre);
            setEditMode(true);
            dispatch(listFollowupCentres({}));
        }
        );
    };

    // handle update
    const handleUpdateFollowupCentre = () => {
        dispatch(updateFollowupCentre(updatedFollowupCentre.id, updatedFollowupCentre)).then(() => {

            setUpdatedFollowupCentre(updatedFollowupCentre);
            setEditMode(true);
            dispatch(getFollowupCentreDetails(updatedFollowupCentre.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>FollowupCentre Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the followupCentres. You can create, update and delete followupCentres in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {followupCentreCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {followupCentreCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {followupCentreCreateErrors?.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-followupCentres"
                                        label="Search followupCentres"
                                        name="search-followupCentres"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {followupCentres && followupCentres.length > 0 ? (
                                            followupCentres?.map((followupCentre) => (
                                                <Details key={followupCentre._id}>
                                                    <Details.Summary>
                                                        {followupCentre.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Address:</strong> {followupCentre.streetAddress}
                                                        </p>
                                                        <p>
                                                            <strong>City:</strong> {followupCentre.city}
                                                        </p>
                                                        <p>
                                                            <strong>State:</strong> {followupCentre.state}
                                                        </p>
                                                        <p>
                                                            <strong>Postal Code:</strong> {followupCentre.postalCode}
                                                        </p>
                                                        <p>
                                                            <strong>Country:</strong> {followupCentre.country}
                                                        </p>
                                                        <p>
                                                            <strong>Email:</strong> {followupCentre.email}
                                                        </p>
                                                        <p>
                                                            <strong>Phone Number:</strong> {followupCentre.phoneNumber}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={followupCentreDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditFollowupCentre(followupCentre.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(followupCentre.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit FollowupCentre
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an followupCentre. If you want to create a new followupCentre, please click the "Create FollowupCentre" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create FollowupCentre
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, name: e.target.value })}
                                            value={updatedFollowupCentre.name ? updatedFollowupCentre.name : ''}
                                            required
                                        />
                                        <Input
                                            id="streetAddress"
                                            label="Street Address"
                                            name="streetAddress"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, streetAddress: e.target.value })}
                                            value={updatedFollowupCentre.streetAddress ? updatedFollowupCentre.streetAddress : ''}
                                            required
                                        />
                                        <Input
                                            id="city"
                                            label="City"
                                            name="city"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, city: e.target.value })}
                                            value={updatedFollowupCentre.city ? updatedFollowupCentre.city : ''}
                                            required
                                        />
                                        <Input
                                            id="state"
                                            label="State"
                                            name="state"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, state: e.target.value })}
                                            value={updatedFollowupCentre.state ? updatedFollowupCentre.state : ''}
                                            required
                                        />
                                        <Input
                                            id="postalCode"
                                            label="Postal Code"
                                            name="postalCode"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, postalCode: e.target.value })}
                                            value={updatedFollowupCentre.postalCode ? updatedFollowupCentre.postalCode : ''}
                                            required
                                        />
                                        <Input
                                            id="country"
                                            label="Country"
                                            name="country"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, country: e.target.value })}
                                            value={updatedFollowupCentre.country ? updatedFollowupCentre.country : ''}
                                            required
                                        />
                                        <Input
                                            id="email"
                                            label="Email"
                                            name="email"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, email: e.target.value })}
                                            value={updatedFollowupCentre.email ? updatedFollowupCentre.email : ''}
                                            required
                                        />
                                        <Input
                                            id="phoneNumber"
                                            label="Phone Number"
                                            name="phoneNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedFollowupCentre({ ...updatedFollowupCentre, phoneNumber: e.target.value })}
                                            value={updatedFollowupCentre.phoneNumber ? updatedFollowupCentre.phoneNumber : ''}
                                            required
                                        />

                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The followupCentre was created successfully." : "The followupCentre was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={followupCentreUpdateProcessing}
                                        onClick={handleUpdateFollowupCentre}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new FollowupCentre
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, name: e.target.value })}
                                            value={newFollowupCentre?.name}
                                            required
                                        />
                                        <Input
                                            id="streetAddress-create"
                                            label="Street Address"
                                            name="streetAddress-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, streetAddress: e.target.value })}
                                            value={newFollowupCentre?.streetAddress}
                                            required
                                        />
                                        <Input
                                            id="city-create"
                                            label="City"
                                            name="city-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, city: e.target.value })}
                                            value={newFollowupCentre?.city}
                                            required
                                        />
                                        <Input
                                            id="state-create"
                                            label="State"
                                            name="state-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, state: e.target.value })}
                                            value={newFollowupCentre?.state}
                                            required
                                        />
                                        <Input
                                            id="postalCode-create"
                                            label="Postal Code"
                                            name="postalCode-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, postalCode: e.target.value })}
                                            value={newFollowupCentre?.postalCode}
                                            required
                                        />
                                        <Input
                                            id="country-create"
                                            label="Country"
                                            name="country-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, country: e.target.value })}
                                            value={newFollowupCentre?.country}
                                            required
                                        />
                                        <Input
                                            id="email-create"
                                            label="Email"
                                            name="email-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, email: e.target.value })}
                                            value={newFollowupCentre?.email}
                                            required
                                        />
                                        <Input
                                            id="phoneNumber-create"
                                            label="Phone Number"
                                            name="phoneNumber-create"
                                            type="text"
                                            onChange={(e) => setNewFollowupCentre({ ...newFollowupCentre, phoneNumber: e.target.value })}
                                            value={newFollowupCentre?.phoneNumber}
                                            required
                                        />

                                    </Card.Description>

                                    <Button
                                        disabled={followupCentreUpdateProcessing}
                                        onClick={handleCreateFollowupCentre}
                                    >
                                        Create FollowupCentre
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






