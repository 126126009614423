import axios from 'axios';

import {
    TEST_RESULT_REPORT_LIST_REQUEST,
    TEST_RESULT_REPORT_LIST_SUCCESS,
    TEST_RESULT_REPORT_LIST_FAIL,

    TEST_RESULT_REPORT_DETAILS_REQUEST,
    TEST_RESULT_REPORT_DETAILS_SUCCESS,
    TEST_RESULT_REPORT_DETAILS_FAIL,

    TEST_RESULT_REPORT_DELETE_REQUEST,
    TEST_RESULT_REPORT_DELETE_SUCCESS,
    TEST_RESULT_REPORT_DELETE_FAIL,

    TEST_RESULT_REPORT_CREATE_REQUEST,
    TEST_RESULT_REPORT_CREATE_SUCCESS,
    TEST_RESULT_REPORT_CREATE_FAIL,

    TEST_RESULT_REPORT_UPDATE_REQUEST,
    TEST_RESULT_REPORT_UPDATE_SUCCESS,
    TEST_RESULT_REPORT_UPDATE_FAIL,

    TEST_RESULT_REPORT_REQUEST,
    TEST_RESULT_REPORT_FAIL,
    TEST_RESULT_REPORT_RESET,
    TEST_RESULT_REPORT_SUCCESS,

    TEST_RESULT_REPORT_DTO_REQUEST,
    TEST_RESULT_REPORT_DTO_SUCCESS,
    TEST_RESULT_REPORT_DTO_FAIL,
    TEST_RESULT_REPORT_DTO_RESET,

    TEST_RESULT_REPORT_LIST_BYIDS_REQUEST,
    TEST_RESULT_REPORT_LIST_BYIDS_SUCCESS,
    TEST_RESULT_REPORT_LIST_BYIDS_FAIL,
    TEST_RESULT_REPORT_LIST_BYIDS_RESET,

    TEST_RESULT_REPORT_VALIDATION_UPDATE_REQUEST,
    TEST_RESULT_REPORT_VALIDATION_UPDATE_SUCCESS,
    TEST_RESULT_REPORT_VALIDATION_UPDATE_FAIL,
    

} from "../constants/testResultReportConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listTestResultReports = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_REPORT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/testResultReport/all', config);

        dispatch({
            type: TEST_RESULT_REPORT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_RESULT_REPORT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testResultReports",
        });
    }
}

// list testResultReports by ids
export const listTestResultReportsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_REPORT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/testResultReport/ids/all', ids, config);

        dispatch({
            type: TEST_RESULT_REPORT_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_RESULT_REPORT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testResultReports",
        });
    }
}


export const loadTestResultReportDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_REPORT_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/testResultReport/testResultReportDto', config);
        
        dispatch({
            type: TEST_RESULT_REPORT_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_RESULT_REPORT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getTestResultReportDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_REPORT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/testResultReport/${_id}`, config);

        dispatch({
            type: TEST_RESULT_REPORT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_RESULT_REPORT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const updateValidationStatus = (id, validationStatus) => async (dispatch, getState) => {
    try {
      dispatch({ type: TEST_RESULT_REPORT_VALIDATION_UPDATE_REQUEST });
  
      const {
        userLogin: { userInfo },
    } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.patch(
            `/dotnet_api/v1/testResultReport/${id}/validation/`,
            validationStatus ,
            config
        );


  
      dispatch({
        type: TEST_RESULT_REPORT_VALIDATION_UPDATE_SUCCESS,
        payload: data,
      });
  
      return data;
    } catch (error) {
      dispatch({
        type: TEST_RESULT_REPORT_VALIDATION_UPDATE_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
      throw error;
    }
  };

export const deleteTestResultReport = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_RESULT_REPORT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/testResultReport/${_id}/`,
            config
        )

        dispatch({
            type: TEST_RESULT_REPORT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: TEST_RESULT_REPORT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTestResultReport = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_RESULT_REPORT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/testResultReport/`,
            changes,
            config
        )

        dispatch({
            type: TEST_RESULT_REPORT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: TEST_RESULT_REPORT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadTestResultReportBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make testResultReport using a local testResultReport
export const makeTestResultReport = (file, testResultReportId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const testResultReport = await loadTestResultReportBase64(file);
    const testResultReport_input = JSON.stringify({ "testResultReport": testResultReport });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        testResultReport_input,
        config
    )
    try {
        dispatch({
            type: TEST_RESULT_REPORT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": testResultReport }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: TEST_RESULT_REPORT_SUCCESS
                });
                if (testResultReportId && response.data) {
                    dispatch(updateTestResultReport(testResultReportId, { testResultReport: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: TEST_RESULT_REPORT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: TEST_RESULT_REPORT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateTestResultReport = (_id, changes) => async (dispatch, getState) => {

    try {
        dispatch({
            type: TEST_RESULT_REPORT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }


        const {data} = await axios.patch(
            `/dotnet_api/v1/testResultReport/${_id}/`,
            changes,
            config
        )


        dispatch({
            type: TEST_RESULT_REPORT_UPDATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEST_RESULT_REPORT_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}