import {
    BUSINESS_ORGANISATION_LIST_REQUEST,
    BUSINESS_ORGANISATION_LIST_SUCCESS,
    BUSINESS_ORGANISATION_LIST_FAIL,
    BUSINESS_ORGANISATION_DETAILS_REQUEST,
    BUSINESS_ORGANISATION_DETAILS_SUCCESS,
    BUSINESS_ORGANISATION_DETAILS_FAIL,
    BUSINESS_ORGANISATION_CREATE_REQUEST,
    BUSINESS_ORGANISATION_CREATE_SUCCESS,
    BUSINESS_ORGANISATION_CREATE_FAIL,
    BUSINESS_ORGANISATION_CREATE_RESET,
    BUSINESS_ORGANISATION_UPDATE_REQUEST,
    BUSINESS_ORGANISATION_UPDATE_SUCCESS,
    BUSINESS_ORGANISATION_UPDATE_FAIL,
    BUSINESS_ORGANISATION_UPDATE_RESET,
    BUSINESS_ORGANISATION_DELETE_REQUEST,
    BUSINESS_ORGANISATION_DELETE_SUCCESS,
    BUSINESS_ORGANISATION_DELETE_FAIL,
    BUSINESS_ORGANISATION_DTO_REQUEST,
    BUSINESS_ORGANISATION_DTO_SUCCESS,
    BUSINESS_ORGANISATION_DTO_FAIL,
    BUSINESS_ORGANISATION_DTO_RESET,
    BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST,
    BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS,
    BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL,
    BUSINESS_ORGANISATION_LIST_BY_IDS_RESET,
    SET_CURRENT_PAGE,
    BUSINESS_ORGANISATION_ACTIVATE_REQUEST,
    BUSINESS_ORGANISATION_ACTIVATE_SUCCESS,
    BUSINESS_ORGANISATION_ACTIVATE_FAIL,
    BUSINESS_ORGANISATION_DEACTIVATE_REQUEST,
    BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS,
    BUSINESS_ORGANISATION_DEACTIVATE_FAIL,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
} from '../constants/businessOrganisationConstants';

export const businessOrganisationListReducer = (state = { businessOrganisations: [] }, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_LIST_REQUEST:
            return { loading: true, businessOrganisations: [] };
        case BUSINESS_ORGANISATION_LIST_SUCCESS:
            return {
                loading: false,
                businessOrganisations: action.payload.organisations,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case BUSINESS_ORGANISATION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const businessOrganisationListByIdsReducer = (state = { businessOrganisations: [] }, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST:
            return { loading: true, businessOrganisations: [] };
        case BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS:
            return {
                loading: false,
                businessOrganisations: action.payload,
            };
        case BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL:
            return { loading: false, error: action.payload };
        case BUSINESS_ORGANISATION_LIST_BY_IDS_RESET:
            return { businessOrganisations: [] };
        default:
            return state;
    }
};

export const businessOrganisationDetailsReducer = (state = { businessOrganisation: {} }, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_DETAILS_REQUEST:
            return { loading: true, ...state };
        case BUSINESS_ORGANISATION_DETAILS_SUCCESS:
            return { loading: false, businessOrganisation: action.payload };
        case BUSINESS_ORGANISATION_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const businessOrganisationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_DELETE_REQUEST:
            return { loading: true };
        case BUSINESS_ORGANISATION_DELETE_SUCCESS:
            return { loading: false, success: true };
        case BUSINESS_ORGANISATION_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const businessOrganisationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_CREATE_REQUEST:
            return {loading: true };
        case BUSINESS_ORGANISATION_CREATE_SUCCESS:
            return { loading: false, success: true, businessOrganisation: action.payload };
        case BUSINESS_ORGANISATION_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case BUSINESS_ORGANISATION_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const businessOrganisationUpdateReducer = (state = { businessOrganisation: {} }, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_UPDATE_REQUEST:
            return { loading: true };
        case BUSINESS_ORGANISATION_UPDATE_SUCCESS:
            return { loading: false, success: true, businessOrganisation: action.payload };
        case BUSINESS_ORGANISATION_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case BUSINESS_ORGANISATION_UPDATE_RESET:
            return { businessOrganisation: {} };
        default:
            return state;
    }
};

export const businessOrganisationDtoReducer = (state = { businessOrganisationDto: {} }, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_DTO_REQUEST:
            return { loading: true, businessOrganisationDto: {} };
        case BUSINESS_ORGANISATION_DTO_SUCCESS:
            return { loading: false, success: true, businessOrganisationDto: action.payload };
        case BUSINESS_ORGANISATION_DTO_FAIL:
            return { loading: false, error: action.payload };
        case BUSINESS_ORGANISATION_DTO_RESET:
            return { businessOrganisationDto: {} };
        default:
            return state;
    }
};

export const businessOrganisationActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_ACTIVATE_REQUEST:
            return { loading: true };
        case BUSINESS_ORGANISATION_ACTIVATE_SUCCESS:
            return { loading: false, success: true, businessOrganisation: action.payload };
        case BUSINESS_ORGANISATION_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const businessOrganisationDeactivateReducer = (state = {}, action) => {
    switch (action.type) {
        case BUSINESS_ORGANISATION_DEACTIVATE_REQUEST:
            return { loading: true };
        case BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS:
            return { loading: false, success: true, businessOrganisation: action.payload };
        case BUSINESS_ORGANISATION_DEACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const sendEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_EMAIL_REQUEST:
            return { loading: true };
        case SEND_EMAIL_SUCCESS:
            return { loading: false, success: true };
        case SEND_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

