import axios from 'axios';

import {
    SAMPLE_LIST_REQUEST,
    SAMPLE_LIST_SUCCESS,
    SAMPLE_LIST_FAIL,

    SAMPLE_DETAILS_REQUEST,
    SAMPLE_DETAILS_SUCCESS,
    SAMPLE_DETAILS_FAIL,

    SAMPLE_DELETE_REQUEST,
    SAMPLE_DELETE_SUCCESS,
    SAMPLE_DELETE_FAIL,

    SAMPLE_CREATE_REQUEST,
    SAMPLE_CREATE_SUCCESS,
    SAMPLE_CREATE_FAIL,

    SAMPLE_UPDATE_REQUEST,
    SAMPLE_UPDATE_SUCCESS,
    SAMPLE_UPDATE_FAIL,

    SAMPLE_DTO_REQUEST,
    SAMPLE_DTO_SUCCESS,
    SAMPLE_DTO_FAIL,
    SAMPLE_DTO_RESET,

    SAMPLE_LIST_BYIDS_REQUEST,
    SAMPLE_LIST_BYIDS_SUCCESS,
    SAMPLE_LIST_BYIDS_FAIL,
    SAMPLE_LIST_BYIDS_RESET
} from "../constants/sampleConstants";

export const listSamples = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/sample/all', config);

        dispatch({
            type: SAMPLE_LIST_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SAMPLE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occurred, please try again later",
        });
    }
}

export const listSamplesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_LIST_BYIDS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        
        const { data } = await axios.post('/dotnet_api/v1/sample/ids/all', ids, config);

        dispatch({
            type: SAMPLE_LIST_BYIDS_SUCCESS,
            payload: data
        });

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: SAMPLE_LIST_BYIDS_FAIL,
            payload: errorMessages
        });
    }
}

export const loadSampleDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/sample/sampleDto', config);

        dispatch({
            type: SAMPLE_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SAMPLE_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getSampleDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/sample/${id}`, config);

        dispatch({
            type: SAMPLE_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SAMPLE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteSample = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/sample/${id}/`,
            config
        )

        dispatch({
            type: SAMPLE_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: SAMPLE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createSample = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/sample/`,
            changes,
            config
        )

        dispatch({
            type: SAMPLE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: SAMPLE_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

export const updateSample = (id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/sample/${id}/`,
            changes,
            config
        )

        dispatch({
            type: SAMPLE_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: SAMPLE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: SAMPLE_UPDATE_FAIL,
            payload: errorMessages,
        })
    }
}