import {

    TEST_CATEGORY_LIST_REQUEST,
    TEST_CATEGORY_LIST_SUCCESS,
    TEST_CATEGORY_LIST_FAIL,

    TEST_CATEGORY_DETAILS_REQUEST,
    TEST_CATEGORY_DETAILS_SUCCESS,
    TEST_CATEGORY_DETAILS_FAIL,

    TEST_CATEGORY_DELETE_REQUEST,
    TEST_CATEGORY_DELETE_SUCCESS,
    TEST_CATEGORY_DELETE_FAIL,

    TEST_CATEGORY_CREATE_REQUEST,
    TEST_CATEGORY_CREATE_SUCCESS,
    TEST_CATEGORY_CREATE_FAIL,
    TEST_CATEGORY_CREATE_RESET,

    TEST_CATEGORY_UPDATE_REQUEST,
    TEST_CATEGORY_UPDATE_SUCCESS,
    TEST_CATEGORY_UPDATE_FAIL,
    TEST_CATEGORY_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_CATEGORY_DTO_FAIL,
    TEST_CATEGORY_DTO_REQUEST,
    TEST_CATEGORY_DTO_SUCCESS,
    TEST_CATEGORY_DTO_RESET,

    TEST_CATEGORY_LIST_BYIDS_REQUEST,
    TEST_CATEGORY_LIST_BYIDS_SUCCESS,
    TEST_CATEGORY_LIST_BYIDS_FAIL,
    TEST_CATEGORY_LIST_BYIDS_RESET

} from "../constants/testCategoryConstants";

const initialState = {
    testCategories: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testCategoryListReducer = (
    state = {
        testCategories: []
    }, action) => {
    switch (action.type) {
        case TEST_CATEGORY_LIST_REQUEST:
            return { loading: true, testCategories: [] };
        case TEST_CATEGORY_LIST_SUCCESS:
            // our state will have a list of testCategories after the request
            return {
                loading: false,
                testCategories: action.payload.testCategories,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testCategoryListByIDsReducer = (
    state = {
        testCategories: []
    }, action) => {
    switch (action.type) {
        case TEST_CATEGORY_LIST_BYIDS_REQUEST:
            return { loading: true, testCategories: [] };
        case TEST_CATEGORY_LIST_BYIDS_SUCCESS:
            // our state will have a list of testCategories after the request
            return { loading: false, testCategories: action.payload };
        case TEST_CATEGORY_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_CATEGORY_LIST_BYIDS_RESET:
            return { testCategories: [] };
        default:
            return state;
    }
}


export const testCategoryDetailsReducer = (
    state = {
        testCategory: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_CATEGORY_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_CATEGORY_DETAILS_SUCCESS:
            // our state will have a list of testCategories after the request
            return { loading: false, testCategory: action.payload };
        case TEST_CATEGORY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testCategoryDtoReducer = (state = { testCategoryDto: {} }, action) => {
    switch (action.type) {
      case TEST_CATEGORY_DTO_REQUEST:
        return { ...state, loading: true };
      case TEST_CATEGORY_DTO_SUCCESS: 
        return { loading: false, testCategoryDto: action.payload };
      case TEST_CATEGORY_DTO_FAIL:
        return { loading: false, error: action.payload };
      case TEST_CATEGORY_DTO_RESET:
        return { testCategoryDto: {} };
      default:
        return state;
    }
  };
  
export const testCategoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_CATEGORY_DELETE_REQUEST:
            return { loading: true }

        case TEST_CATEGORY_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testCategoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_CATEGORY_CREATE_REQUEST:
            return { loading: true }

        case TEST_CATEGORY_CREATE_SUCCESS:
            return { loading: false, success: true, testCategory: action.payload }

        case TEST_CATEGORY_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_CATEGORY_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testCategoryUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_CATEGORY_UPDATE_REQUEST:
            return { loading: true }

        case TEST_CATEGORY_UPDATE_SUCCESS:
            return { loading: false, success: true, testCategory: action.payload }

        case TEST_CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_CATEGORY_UPDATE_RESET:
            return { testCategory: {} }

        default:
            return state
    }
}