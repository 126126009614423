import i18next from 'i18next';

export const getTranslatedFilters = () => {
    const t = (key) => i18next.t(key);

    return {
        statusFilters: [
            { text: t("Active"), value: "Active" },
            { text: t("Inactive"), value: "Inactive" },
            { text: t("Requested"), value: "Requested" },
            { text: t("Suspended"), value: "Suspended" },
            { text: t("Approved"), value: "Approved" },
            { text: t("Rejected"), value: "Rejected" },
            { text: t("Pending"), value: "Pending" },
            { text: t("Completed"), value: "Completed" },
            { text: t("Cancelled"), value: "Cancelled" }
        ],
        
        alertFilters: [
            { text: t("Red"), value: "Red" },
            { text: t("Green"), value: "Green" },
            { text: t("Gray"), value: "Gray" }
        ],

        valueFilters: [
            { text: t("*"), value: true },
            { text: t("-"), value: false }
        ],

        emailConfirmedFilters: [
            { text: t("Yes"), value: true },
            { text: t("No"), value: false }
        ],

        genderFilters: [
            { text: t("Male"), value: "Male" },
            { text: t("Female"), value: "Female" }
        ],

        roleFilters: [
            { text: t("SuperUser"), value: "SuperUser" },
            { text: t("UnauthorizedUser"), value: "UnauthorizedUser" },
            { text: t("CardiologyStaff"), value: "CardiologyStaff" },
            { text: t("TrustUserManager"), value: "TrustUserManager" },
            { text: t("Patient"), value: "Patient" }
        ]
    };
};

export const {
    statusFilters,
    alertFilters,
    valueFilters,
    emailConfirmedFilters,
    genderFilters,
    roleFilters
} = getTranslatedFilters();