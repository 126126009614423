import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Layout, ConfigProvider, Button, Typography, Space, Avatar, Badge, Empty, Spin, List, Tag, Tooltip, Card, Input } from 'antd';
import { ArrowUpOutlined, EyeOutlined, MessageOutlined, UsergroupAddOutlined, LikeOutlined, CommentOutlined, RightOutlined, LinkOutlined, ReloadOutlined, PushpinOutlined } from '@ant-design/icons';
import { ThemeContext } from '../../index';
import { Client4, WebSocketClient } from '@mattermost/client';
import ReactMarkdown from 'react-markdown';

import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;

const ProlabActivityComponent = ({ chatTitle, teamId, token, serverUrl, mattermostUrl, teamGuid }) => {
  // State declarations
  const [client, setClient] = useState(null);
  const [wsClient, setWsClient] = useState(null);
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState({});
  const [userStatuses, setUserStatuses] = useState({});
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [newEventsCount, setNewEventsCount] = useState(0);
  const [pinnedPosts, setPinnedPosts] = useState([]);

  // Context and refs
  const { themeConfig } = useContext(ThemeContext);
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);

  const { t } = useTranslation();

  // useEffect hooks
  useEffect(() => {
    initializeClient();
    return () => {
      if (wsClient) wsClient.close();
    };
  }, []);

  useEffect(() => {
    if (client && Object.keys(users).length > 0) {
      const intervalId = setInterval(() => {
        fetchUserStatuses(client);
      }, 60000); // Update every minute

      return () => clearInterval(intervalId);
    }
  }, [client, users]);
  useEffect(() => {
    if (client && selectedChannelId) {
      fetchMessages(selectedChannelId);
      fetchPinnedPosts(selectedChannelId);
    }
  }, [client, selectedChannelId]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = messages?.filter(message =>
        message.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (users[message.user_id]?.username || '').toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  }, [searchQuery, messages, users]);

  // Functions
  const initializeClient = async () => {
    try {
      const newClient = new Client4();
      newClient.setUrl(serverUrl);
      newClient.setToken(token);
      setClient(newClient);

      const newWsClient = new WebSocketClient();

      const wsUrl = new URL(serverUrl);
      wsUrl.protocol = wsUrl.protocol === 'https:' ? 'wss:' : 'ws:';
      wsUrl.pathname = wsUrl.pathname.replace(/\/+$/, '') + '/api/v4/websocket';

      await newWsClient.initialize(wsUrl.toString(), token);
      setWsClient(newWsClient);

      newWsClient.setEventCallback(handleWebSocketEvent);

      await fetchChannels(newClient);
      await fetchUsers(newClient);
      // Fetch user statuses after fetching users
      await fetchUserStatuses(newClient);
    } catch (error) {
      console.error('Error initializing Mattermost client:', error);
    }
  };

  const handleWebSocketEvent = (event) => {
    setNewEventsCount(prevCount => prevCount + 1);

    switch (event.event) {
      case 'posted':
        handleNewMessage(event);
        break;
      case 'post_edited':
        handlePostEdited(event);
        break;
      case 'post_deleted':
        handlePostDeleted(event);
        break;
      case 'reaction_added':
      case 'reaction_removed':
        handleReactionUpdate(event);
        break;
      case 'channel_viewed':
      case 'channel_updated':
        handleChannelUpdate(event);
        break;
      case 'user_added':
      case 'user_removed':
        handleUserUpdate(event);
        break;
      case 'status_change':
        handleStatusChange(event);
        break;
      case 'thread_updated':
        handleThreadUpdate(event);
        break;
    }
  };

  const fetchChannels = async (client) => {
    try {
      const fetchedChannels = await client.getMyChannels(teamId);
      const channelsWithStats = await Promise.all(fetchedChannels?.map(async (channel) => {
        const stats = await client.getChannelStats(channel.id);
        return { ...channel, ...stats };
      }));
      setChannels(channelsWithStats);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  };

  const fetchMessages = async (channelId) => {
    if (!client) {
      console.error('Client not initialized');
      return;
    }
    try {
      setLoading(true);
      const postsResponse = await client.getPosts(channelId);
      const allMessages = Object.values(postsResponse.posts);
      const sortedMessages = allMessages.sort((a, b) => b.create_at - a.create_at);

      const messagesWithReactionsAndThreads = await Promise.all(sortedMessages?.map(async (message) => {
        const reactions = await fetchReactions(message.id);
        const threadCount = await fetchThreadCount(message.id);
        return { ...message, reactions, reply_count: threadCount };
      }));

      setMessages(messagesWithReactionsAndThreads.slice(0, 100));
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPinnedPosts = async (channelId) => {
    try {
      const pinnedPostsResponse = await client.getPinnedPosts(channelId);
      setPinnedPosts(Object.values(pinnedPostsResponse.posts));
    } catch (error) {
      console.error('Error fetching pinned posts:', error);
    }
  };

  const fetchReactions = async (postId) => {
    try {
      const response = await fetch(`${serverUrl}/api/v4/posts/${postId}/reactions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 403) {
          console.warn(`No permission to fetch reactions for post ${postId}`);
          return [];
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching reactions for post ${postId}:`, error);
      return [];
    }
  };

  const handleReactionUpdate = async (event) => {
    const postId = event.data.reaction ? event.data.reaction.post_id : null;
    if (postId) {
      try {
        const reactions = await fetchReactions(postId);
        setMessages(prevMessages => prevMessages?.map(message =>
          message.id === postId ? { ...message, reactions } : message
        ));
      } catch (error) {
        console.error('Error updating reactions:', error);
      }
    }
  };

  const fetchThreadCount = async (postId) => {
    try {
      const thread = await client.getPostThread(postId);
      return Object.keys(thread.posts).length - 1; // Subtract 1 to exclude the root post
    } catch (error) {
      console.error(`Error fetching thread count for post ${postId}:`, error);
      return 0;
    }
  };

  const fetchUsers = async (client) => {
    try {
      const fetchedUsers = await client.getProfiles();
      const usersObj = fetchedUsers.reduce((acc, user) => ({ ...acc, [user.id]: user }), {});
      setUsers(usersObj);
      setTotalUsers(fetchedUsers.length);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUserStatuses = async (client) => {
    try {
      const userIds = Object.keys(users);
      if (userIds.length === 0) {
        return;
      }
      const statuses = await client.getStatusesByIds(userIds);

      const newUserStatuses = statuses.reduce((acc, status) => {
        acc[status.user_id] = status;
        return acc;
      }, {});

      setUserStatuses(newUserStatuses);
    } catch (error) {
      console.error('Error fetching user statuses:', error);
      if (error.message) {
        console.error('Error message:', error.message);
      }
      if (error.status_code) {
        console.error('Status code:', error.status_code);
      }
    }
  };


  const handleNewMessage = (event) => {
    const newMessage = JSON.parse(event.data.post);
    setMessages(prevMessages => {
      const updatedMessages = [{ ...newMessage, reactions: [], reply_count: 0 }, ...prevMessages]
        .sort((a, b) => b.create_at - a.create_at)
        .slice(0, 100);
      return updatedMessages;
    });
    updateChannelMessageCount(event.broadcast.channel_id, 1);
  };

  const handlePostEdited = (event) => {
    const editedPost = JSON.parse(event.data.post);
    setMessages(prevMessages => prevMessages?.map(message =>
      message.id === editedPost.id ? { ...message, ...editedPost } : message
    ));
  };

  const handlePostDeleted = (event) => {
    const deletedPostId = JSON.parse(event.data.post).id;
    setMessages(prevMessages => prevMessages?.filter(message => message.id !== deletedPostId));
    updateChannelMessageCount(event.broadcast.channel_id, -1);
  };

  const handleChannelUpdate = (event) => {
    if (event.event === 'channel_viewed') {
      updateChannelMessageCount(event.data.channel_id, 0, event.data.total_msg_count);
    } else if (event.event === 'channel_updated') {
      const updatedChannel = JSON.parse(event.data.channel);
      setChannels(prevChannels => prevChannels?.map(channel =>
        channel.id === updatedChannel.id ? { ...channel, ...updatedChannel } : channel
      ));
    }
  };

  const handleUserUpdate = (event) => {
    fetchUsers(client);
    fetchChannels(client);
  };

  const handleStatusChange = (event) => {
    setUserStatuses(prevStatuses => ({
      ...prevStatuses,
      [event.data.user_id]: event.data.status
    }));
  };

  const handleThreadUpdate = async (event) => {
    const rootPostId = event.data.post.root_id || event.data.post.id;
    try {
      const threadCount = await fetchThreadCount(rootPostId);
      setMessages(prevMessages => prevMessages?.map(message =>
        message.id === rootPostId ? { ...message, reply_count: threadCount } : message
      ));
    } catch (error) {
      console.error('Error updating thread count:', error);
    }
  };

  const updateChannelMessageCount = (channelId, change, newTotal = null) => {
    setChannels(prevChannels => prevChannels?.map(channel =>
      channel.id === channelId
        ? { ...channel, total_msg_count: newTotal !== null ? newTotal : channel.total_msg_count + change }
        : channel
    ));
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const scrollToTop = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = 0;
    }
    setNewEventsCount(0);
  };

  const handleChannelClick = (channelId) => {
    setSelectedChannelId(channelId);
  };

  const getReactionCount = (message) => {
    return message.reactions ? message.reactions.length : 0;
  };

  const getTotalPosts = () => {
    return channels.reduce((sum, channel) => sum + channel.total_msg_count, 0);
  };

  const handleViewPost = (message) => {
    const channel = channels.find(c => c.id === message.channel_id);
    const postUrl = `${mattermostUrl}/${teamGuid}/channels/${channel?.name || message.channel_id}/posts/${message.id}`;
    window.open(postUrl, '_blank');
  };

  const getUserStatusColor = (userId) => {
    const status = userStatuses[userId]?.status || 'offline';
    switch (status) {
      case 'online':
        return '#52c41a';  // green
      case 'away':
        return '#faad14';  // orange
      case 'dnd':
        return '#f5222d';  // red
      case 'offline':
      default:
        return '#d9d9d9';  // gray
    }
  };
  const handleRefresh = () => {
    if (selectedChannelId) {
      fetchMessages(selectedChannelId);
      fetchPinnedPosts(selectedChannelId);
    }
  };

  const renderMessage = (message, isPinned = false) => (
    <List.Item
      key={message.id}
      actions={[
        <Space>
          <Tooltip title={t("Reactions")}>
            <Badge count={getReactionCount(message)} overflowCount={99}>
              <LikeOutlined key="reactions" />
            </Badge>
          </Tooltip>
          <Tooltip title={t("Replies")}>
            <Badge count={message.reply_count || 0} overflowCount={99}>
              <CommentOutlined key="replies" />
            </Badge>
          </Tooltip>
          <Button
            type="link"
            size="small"
            icon={<LinkOutlined />}
            onClick={() => handleViewPost(message)}
          >
            {t("View Post")}
          </Button>
        </Space>,
      ]}
    >
      <List.Item.Meta
        avatar={
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Avatar src={`${serverUrl}/api/v4/users/${message.user_id}/image`} />
            <span
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: getUserStatusColor(message.user_id),
                border: '2px solid #fff'
              }}
            />
          </div>
        }
        title={
          <Space direction="vertical" size={0}>
            <Text strong>{users[message.user_id]?.username || t('Unknown user')}</Text>
            <Text type="secondary" style={{ fontSize: '0.8em' }}>
              {new Date(message.create_at).toLocaleString()}
            </Text>
          </Space>
        }
        description={
          <div style={{ marginTop: '8px' }}>
            {isPinned && <Tag icon={<PushpinOutlined />} color="warning">Pinned</Tag>}
            {message.root_id && <Tag color="processing">{t("Threaded Reply")}</Tag>}
            <ReactMarkdown>{message.message}</ReactMarkdown>
          </div>
        }
      />
    </List.Item>
  );

  // Render
  // Render
  return (
    <ConfigProvider theme={themeConfig}>
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={{ padding: '0 50px' }}>
          <Title level={2}>{t("Collaboration Activity") + ' ' + chatTitle}</Title>

          <Space style={{ marginBottom: '20px' }}>
            <Tooltip title={t("Total Channels")}>
              <Tag color="blue" icon={<ArrowUpOutlined />}>{channels.length}</Tag>
            </Tooltip>
            <Tooltip title={t("Total Users")}>
              <Tag color="green" icon={<UsergroupAddOutlined />}>{totalUsers}</Tag>
            </Tooltip>
            <Tooltip title={t("Total Posts")}>
              <Tag color="orange" icon={<MessageOutlined />}>{getTotalPosts()}</Tag>
            </Tooltip>
          </Space>

          <Title level={3}>{t("Channels")}</Title>
          <List
            itemLayout="horizontal"
            dataSource={channels}
            renderItem={(channel) => (
              <List.Item
                onClick={() => handleChannelClick(channel.id)}
                style={{ cursor: 'pointer' }}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<MessageOutlined />} />}
                  title={
                    <Space>
                      <Text strong>{channel.display_name}</Text>
                      <Badge count={channel.total_msg_count} overflowCount={999} />
                    </Space>
                  }
                  description={`${channel.member_count} ${t("members")}`}
                />
                <Button
                  type="link"
                  icon={<RightOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`${mattermostUrl}/${teamGuid}/channels/${channel.name}`, '_blank');
                  }}
                >
                  {t("Join Discussion")}
                </Button>
              </List.Item>
            )}
          />

          <Card
            title={
              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <span>{selectedChannelId ? `${t("Messages in")} ${channels.find(channel => channel.id === selectedChannelId)?.display_name}` : t("Messages")}</span>
                <Space>
                  <Search
                    placeholder={t("Search messages")}
                    allowClear
                    onSearch={handleSearch}
                    style={{ width: 200 }}
                  />
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={handleRefresh}
                    type="default"
                    size="small"
                  />
                </Space>
              </Space>
            }
          >
            {selectedChannelId ? (
              <div style={{ height: '400px', overflow: 'auto', position: 'relative' }} ref={messageListRef}>
                {newEventsCount > 0 && (
                  <Button
                    type="primary"
                    size="small"
                    style={{ position: 'sticky', top: 10, left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}
                    onClick={scrollToTop}
                  >
                    {newEventsCount} {t("new update", { count: newEventsCount })}
                  </Button>
                )}
                {loading ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Spin size="large" tip={t("Updating messages...")} />
                  </div>
                ) : (
                  <List
                    dataSource={[...pinnedPosts, ...filteredMessages]}
                    renderItem={(message, index) => renderMessage(message, index < pinnedPosts.length)}
                  />
                )}
                <div ref={messagesEndRef} />
              </div>
            ) : (
              <Empty description={t("Select a channel to view messages")} />
            )}
          </Card>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

ProlabActivityComponent.propTypes = {
  teamId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  serverUrl: PropTypes.string.isRequired,
  mattermostUrl: PropTypes.string.isRequired,
  teamGuid: PropTypes.string.isRequired,
};

export default ProlabActivityComponent;