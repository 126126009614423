import React from 'react';
import { Table, Select, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const { Text } = Typography;

export default function MappingTable({ deviceMappings, setDeviceMappings, csvHeaders, testDto }) {
  const { t } = useTranslation();

  const handleCsvHeaderChange = (dtoProperty, csvHeader) => {
    setDeviceMappings(prevMappings =>
      prevMappings.map(mapping =>
        mapping.dtoProperty === dtoProperty ? { ...mapping, csvHeader } : mapping
      )
    );
  };

  const handleDefaultValueChange = (dtoProperty, defaultValue) => {
    setDeviceMappings(prevMappings =>
      prevMappings.map(mapping =>
        mapping.dtoProperty === dtoProperty ? { ...mapping, defaultValue } : mapping
      )
    );
  };

  const columns = [
    {
      title: t('importer.property'),
      dataIndex: 'property',
      key: 'property',
      width: '30%',
      render: (text, record) => (
        <>
          {record.dtoProperty} <br /> <Text type="secondary">{testDto[record.dtoProperty].type}</Text>
        </>
      ),
    },
    {
      title: t('importer.csvHeader'),
      dataIndex: 'csvHeader',
      key: 'csvHeader',
      width: '35%',
      render: (text, record) => (
        <Select
          value={record.csvHeader}
          onChange={(value) => handleCsvHeaderChange(record.dtoProperty, value)}
          style={{ width: '100%' }}
        >
          <Option value="">{t('importer.notMapped')}</Option>
          {csvHeaders.map((header, index) => (
            <Option key={index} value={header.trim()}>
              {header}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: t('importer.defaultValue'),
      dataIndex: 'defaultValue',
      key: 'defaultValue',
      width: '35%',
      render: (text, record) => (
        <Input
          value={record.defaultValue}
          onChange={(e) => handleDefaultValueChange(record.dtoProperty, e.target.value)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={deviceMappings}
      pagination={false}
      bordered
      scroll={{ y: 'calc(80vh - 400px)' }}
    />
  );
}