import axios from 'axios';
import {
    CONTROL_MATERIAL_LIST_REQUEST,
    CONTROL_MATERIAL_LIST_SUCCESS,
    CONTROL_MATERIAL_LIST_FAIL,
    CONTROL_MATERIAL_DETAILS_REQUEST,
    CONTROL_MATERIAL_DETAILS_SUCCESS,
    CONTROL_MATERIAL_DETAILS_FAIL,
    CONTROL_MATERIAL_DELETE_REQUEST,
    CONTROL_MATERIAL_DELETE_SUCCESS,
    CONTROL_MATERIAL_DELETE_FAIL,
    CONTROL_MATERIAL_CREATE_REQUEST,
    CONTROL_MATERIAL_CREATE_SUCCESS,
    CONTROL_MATERIAL_CREATE_FAIL,
    CONTROL_MATERIAL_UPDATE_REQUEST,
    CONTROL_MATERIAL_UPDATE_SUCCESS,
    CONTROL_MATERIAL_UPDATE_FAIL,
    CONTROL_MATERIAL_DTO_REQUEST,
    CONTROL_MATERIAL_DTO_SUCCESS,
    CONTROL_MATERIAL_DTO_FAIL,
    CONTROL_MATERIAL_LIST_BYIDS_REQUEST,
    CONTROL_MATERIAL_LIST_BYIDS_SUCCESS,
    CONTROL_MATERIAL_LIST_BYIDS_FAIL,
} from "../constants/controlMaterialConstants";

export const listControlMaterials = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = filter ? filter : {};

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/controlMaterial/all', config);

        dispatch({
            type: CONTROL_MATERIAL_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const listControlMaterialsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/controlMaterial/ids/all', ids, config);

        dispatch({
            type: CONTROL_MATERIAL_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getControlMaterialDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/controlMaterial/${id}`, config);

        dispatch({
            type: CONTROL_MATERIAL_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteControlMaterial = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_DELETE_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        await axios.delete(`/dotnet_api/v1/controlMaterial/${id}/`, config);

        dispatch({ type: CONTROL_MATERIAL_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const createControlMaterial = (controlMaterial) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_CREATE_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/controlMaterial/', controlMaterial, config);

        dispatch({
            type: CONTROL_MATERIAL_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const updateControlMaterial = (id, controlMaterial) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_UPDATE_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.patch(`/dotnet_api/v1/controlMaterial/${id}/`, controlMaterial, config);

        dispatch({
            type: CONTROL_MATERIAL_UPDATE_SUCCESS,
            payload: data,
        });
        dispatch({
            type: CONTROL_MATERIAL_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const loadControlMaterialDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTROL_MATERIAL_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/controlMaterial/controlMaterialDto', config);

        dispatch({
            type: CONTROL_MATERIAL_DTO_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CONTROL_MATERIAL_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}