export const QC_REPORT_LIST_REQUEST = 'QC_REPORT_LIST_REQUEST';
export const QC_REPORT_LIST_SUCCESS = 'QC_REPORT_LIST_SUCCESS';
export const QC_REPORT_LIST_FAIL = 'QC_REPORT_LIST_FAIL';

export const QC_REPORT_DETAILS_REQUEST = 'QC_REPORT_DETAILS_REQUEST';
export const QC_REPORT_DETAILS_SUCCESS = 'QC_REPORT_DETAILS_SUCCESS';
export const QC_REPORT_DETAILS_FAIL = 'QC_REPORT_DETAILS_FAIL';

export const QC_REPORT_DELETE_REQUEST = 'QC_REPORT_DELETE_REQUEST';
export const QC_REPORT_DELETE_SUCCESS = 'QC_REPORT_DELETE_SUCCESS';
export const QC_REPORT_DELETE_FAIL = 'QC_REPORT_DELETE_FAIL';

export const QC_REPORT_CREATE_REQUEST = 'QC_REPORT_CREATE_REQUEST';
export const QC_REPORT_CREATE_SUCCESS = 'QC_REPORT_CREATE_SUCCESS';
export const QC_REPORT_CREATE_FAIL = 'QC_REPORT_CREATE_FAIL';

export const QC_REPORT_UPDATE_REQUEST = 'QC_REPORT_UPDATE_REQUEST';
export const QC_REPORT_UPDATE_SUCCESS = 'QC_REPORT_UPDATE_SUCCESS';
export const QC_REPORT_UPDATE_FAIL = 'QC_REPORT_UPDATE_FAIL';

export const QC_REPORT_DTO_REQUEST = 'QC_REPORT_DTO_REQUEST';
export const QC_REPORT_DTO_SUCCESS = 'QC_REPORT_DTO_SUCCESS';
export const QC_REPORT_DTO_FAIL = 'QC_REPORT_DTO_FAIL';
export const QC_REPORT_DTO_RESET = 'QC_REPORT_DTO_RESET';

export const QC_REPORT_LIST_BYIDS_REQUEST = 'QC_REPORT_LIST_BYIDS_REQUEST';
export const QC_REPORT_LIST_BYIDS_SUCCESS = 'QC_REPORT_LIST_BYIDS_SUCCESS';
export const QC_REPORT_LIST_BYIDS_FAIL = 'QC_REPORT_LIST_BYIDS_FAIL';
export const QC_REPORT_LIST_BYIDS_RESET = 'QC_REPORT_LIST_BYIDS_RESET';