export const CONTROL_MATERIAL_LIST_REQUEST = 'CONTROL_MATERIAL_LIST_REQUEST';
export const CONTROL_MATERIAL_LIST_SUCCESS = 'CONTROL_MATERIAL_LIST_SUCCESS';
export const CONTROL_MATERIAL_LIST_FAIL = 'CONTROL_MATERIAL_LIST_FAIL';

export const CONTROL_MATERIAL_DETAILS_REQUEST = 'CONTROL_MATERIAL_DETAILS_REQUEST';
export const CONTROL_MATERIAL_DETAILS_SUCCESS = 'CONTROL_MATERIAL_DETAILS_SUCCESS';
export const CONTROL_MATERIAL_DETAILS_FAIL = 'CONTROL_MATERIAL_DETAILS_FAIL';

export const CONTROL_MATERIAL_DELETE_REQUEST = 'CONTROL_MATERIAL_DELETE_REQUEST';
export const CONTROL_MATERIAL_DELETE_SUCCESS = 'CONTROL_MATERIAL_DELETE_SUCCESS';
export const CONTROL_MATERIAL_DELETE_FAIL = 'CONTROL_MATERIAL_DELETE_FAIL';

export const CONTROL_MATERIAL_CREATE_REQUEST = 'CONTROL_MATERIAL_CREATE_REQUEST';
export const CONTROL_MATERIAL_CREATE_SUCCESS = 'CONTROL_MATERIAL_CREATE_SUCCESS';
export const CONTROL_MATERIAL_CREATE_FAIL = 'CONTROL_MATERIAL_CREATE_FAIL';

export const CONTROL_MATERIAL_UPDATE_REQUEST = 'CONTROL_MATERIAL_UPDATE_REQUEST';
export const CONTROL_MATERIAL_UPDATE_SUCCESS = 'CONTROL_MATERIAL_UPDATE_SUCCESS';
export const CONTROL_MATERIAL_UPDATE_FAIL = 'CONTROL_MATERIAL_UPDATE_FAIL';

export const CONTROL_MATERIAL_DTO_REQUEST = 'CONTROL_MATERIAL_DTO_REQUEST';
export const CONTROL_MATERIAL_DTO_SUCCESS = 'CONTROL_MATERIAL_DTO_SUCCESS';
export const CONTROL_MATERIAL_DTO_FAIL = 'CONTROL_MATERIAL_DTO_FAIL';
export const CONTROL_MATERIAL_DTO_RESET = 'CONTROL_MATERIAL_DTO_RESET';

export const CONTROL_MATERIAL_LIST_BYIDS_REQUEST = 'CONTROL_MATERIAL_LIST_BYIDS_REQUEST';
export const CONTROL_MATERIAL_LIST_BYIDS_SUCCESS = 'CONTROL_MATERIAL_LIST_BYIDS_SUCCESS';
export const CONTROL_MATERIAL_LIST_BYIDS_FAIL = 'CONTROL_MATERIAL_LIST_BYIDS_FAIL';
export const CONTROL_MATERIAL_LIST_BYIDS_RESET = 'CONTROL_MATERIAL_LIST_BYIDS_RESET';