import {
    QC_SCHEDULE_LIST_REQUEST,
    QC_SCHEDULE_LIST_SUCCESS,
    QC_SCHEDULE_LIST_FAIL,
    QC_SCHEDULE_DETAILS_REQUEST,
    QC_SCHEDULE_DETAILS_SUCCESS,
    QC_SCHEDULE_DETAILS_FAIL,
    QC_SCHEDULE_DELETE_REQUEST,
    QC_SCHEDULE_DELETE_SUCCESS,
    QC_SCHEDULE_DELETE_FAIL,
    QC_SCHEDULE_CREATE_REQUEST,
    QC_SCHEDULE_CREATE_SUCCESS,
    QC_SCHEDULE_CREATE_FAIL,
    QC_SCHEDULE_UPDATE_REQUEST,
    QC_SCHEDULE_UPDATE_SUCCESS,
    QC_SCHEDULE_UPDATE_FAIL,
    QC_SCHEDULE_DTO_REQUEST,
    QC_SCHEDULE_DTO_SUCCESS,
    QC_SCHEDULE_DTO_FAIL,
    QC_SCHEDULE_DTO_RESET,
    QC_SCHEDULE_LIST_BYIDS_REQUEST,
    QC_SCHEDULE_LIST_BYIDS_SUCCESS,
    QC_SCHEDULE_LIST_BYIDS_FAIL,
    QC_SCHEDULE_LIST_BYIDS_RESET,
} from '../constants/qcScheduleConstants';

export const qcScheduleListReducer = (state = { qcSchedules: [] }, action) => {
    switch (action.type) {
        case QC_SCHEDULE_LIST_REQUEST:
            return { loading: true, qcSchedules: [] };
        case QC_SCHEDULE_LIST_SUCCESS:
            return { 
                loading: false, 
                qcSchedules: action.payload.qcSchedules,
                page: action.payload.page,
                pages: action.payload.pages,
                count: action.payload.count,
            };
        case QC_SCHEDULE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcScheduleDetailsReducer = (state = { qcSchedule: {} }, action) => {
    switch (action.type) {
        case QC_SCHEDULE_DETAILS_REQUEST:
            return { ...state, loading: true };
        case QC_SCHEDULE_DETAILS_SUCCESS:
            return { loading: false, qcSchedule: action.payload };
        case QC_SCHEDULE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcScheduleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_SCHEDULE_DELETE_REQUEST:
            return { loading: true };
        case QC_SCHEDULE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case QC_SCHEDULE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcScheduleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_SCHEDULE_CREATE_REQUEST:
            return { loading: true };
        case QC_SCHEDULE_CREATE_SUCCESS:
            return { loading: false, success: true, qcSchedule: action.payload };
        case QC_SCHEDULE_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcScheduleUpdateReducer = (state = { qcSchedule: {} }, action) => {
    switch (action.type) {
        case QC_SCHEDULE_UPDATE_REQUEST:
            return { loading: true };
        case QC_SCHEDULE_UPDATE_SUCCESS:
            return { loading: false, success: true, qcSchedule: action.payload };
        case QC_SCHEDULE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcScheduleDtoReducer = (state = { qcScheduleDto: {} }, action) => {
    switch (action.type) {
        case QC_SCHEDULE_DTO_REQUEST:
            return { loading: true };
        case QC_SCHEDULE_DTO_SUCCESS:
            return { loading: false, qcScheduleDto: action.payload };
        case QC_SCHEDULE_DTO_FAIL:
            return { loading: false, error: action.payload };
        case QC_SCHEDULE_DTO_RESET:
            return { qcScheduleDto: {} };
        default:
            return state;
    }
};

export const qcScheduleListByIdsReducer = (state = { qcSchedules: [] }, action) => {
    switch (action.type) {
        case QC_SCHEDULE_LIST_BYIDS_REQUEST:
            return { loading: true, qcSchedules: [] };
        case QC_SCHEDULE_LIST_BYIDS_SUCCESS:
            return { loading: false, qcSchedules: action.payload };
        case QC_SCHEDULE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case QC_SCHEDULE_LIST_BYIDS_RESET:
            return { qcSchedules: [] };
        default:
            return state;
    }
};