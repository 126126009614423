import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Space, Row, Col, Card, Steps, Typography, message, theme, Affix, Descriptions, Tag, Divider, Modal, Drawer, Statistic  } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, ArrowRightOutlined, FileTextOutlined, FilePdfOutlined, ExperimentOutlined, FileSearchOutlined, PrinterOutlined, CalendarOutlined, UserOutlined, NumberOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { updateTestOrderBulkRequest, loadTestOrderBulkRequestDto, getTestOrderBulkRequestDetails } from '../../actions/testOrderBulkRequestActions';
import Printer from '../../components/printer/Printer.component';
import TestOrderBulkRequestStep from './steps/TestOrderBulkRequestStep';
import SelectTestsStep from './steps/SelectTestsStep';
import ReviewInvoiceStep from './steps/ReviewInvoiceStep';
import SampleView from '../../components/sample/SampleView.component';
import { barcodeToBase64 } from '../../utils/functions';

const { Title, Paragraph } = Typography;
const { Step } = Steps;

export default function EditTestOrderBulkRequestScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _id } = useParams();
  const [currentState, setCurrentState] = useState({
    updatedTestOrderBulkRequest: null,
    invoiceReportUpdatedEntity: null,
    testOrderBulkRequestId: _id,
    invoiceReportId: null,
    invoiceLineIds: null,
    invoiceLines: [],
    editMode: 'Edit',
    invoiceLoading: false,
    documentData: {},
    documentType: {},
    printerVisible: false,
    isBusy: false,
    sampleViewVisible: false,
    current: 0
  });

  const testOrderBulkRequestState = useSelector((state) => state.testOrderBulkRequestUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = testOrderBulkRequestState;

  const testOrderBulkRequestDetails = useSelector((state) => state.testOrderBulkRequestDetails);
  const { loading: loadingDetails, error: errorDetails, testOrderBulkRequest } = testOrderBulkRequestDetails;

  const invoiceReportState = useSelector((state) => state.invoiceReportUpdate);


  useEffect(() => {
    document.title = "Edit TestOrder Bulk Request";
    dispatch(getTestOrderBulkRequestDetails(_id));
    dispatch(loadTestOrderBulkRequestDto());
  }, [dispatch, _id]);

  useEffect(() => {
    if (testOrderBulkRequest) {
      setCurrentState(prevState => ({
        ...prevState,
        updatedTestOrderBulkRequest: testOrderBulkRequest,
        invoiceReportId: testOrderBulkRequest.invoiceReportId,
        invoiceLineIds: testOrderBulkRequest.invoiceLineIds
      }));
    }
  }, [testOrderBulkRequest]);

  // listen to changes in the loading state of testOrderBulkRequestState
  useEffect(() => {
    if (testOrderBulkRequestState.loading) {
      setCurrentState(prevState => ({ ...prevState, isBusy: true }));
    } else {
      setCurrentState(prevState => ({ ...prevState, isBusy: false }));
    }
  }, [testOrderBulkRequestState.loading]);



  const { token } = theme.useToken();

  const handleStepChange = (direction) => {
    setCurrentState(prevState => ({
      ...prevState,
      current: direction === 'next' ? prevState.current + 1 : prevState.current - 1
    }));
  };

  const handleSaveTestInventoryLines = async () => {
    const { updatedTestOrderBulkRequest, testOrderBulkRequestId } = currentState;
    if (!updatedTestOrderBulkRequest || !testOrderBulkRequestId) {
      message.error('Error: No test order bulk request to update');
      return;
    }
  
    if (updatedTestOrderBulkRequest.testInventoryLineIds.length === 0) {
      message.warning('Please select at least one test before saving');
      return;
    }
  
    setCurrentState(prevState => ({ ...prevState, isBusy: true }));
  
    try {
      await dispatch(updateTestOrderBulkRequest(testOrderBulkRequestId, updatedTestOrderBulkRequest));
      message.success('Selected tests updated successfully', 5);
      
      // Fetch the updated TestOrderBulkRequest details
      const updatedDetails = await dispatch(getTestOrderBulkRequestDetails(testOrderBulkRequestId));
      
      // Update the state with the fresh data
      setCurrentState(prevState => ({
        ...prevState,
        updatedTestOrderBulkRequest: updatedDetails,
        isBusy: false
      }));
    } catch (error) {
      message.error('Failed to update selected tests: ' + error.message);
      setCurrentState(prevState => ({ ...prevState, isBusy: false }));
    }
  };

  const handlePrintInvoice = () => {
    const { invoiceReportUpdatedEntity, invoiceLines } = currentState;
    if (invoiceReportUpdatedEntity?.id) {
      const data = {
        "invoiceHeader": {
          content: invoiceReportUpdatedEntity,
        },
        "invoiceSummary": {
          content: invoiceReportUpdatedEntity,
        },
        "invoiceLines": invoiceLines?.map(line => ({
          content: line,
          template: line.template || null
        }))
      };

      setCurrentState(prevState => ({
        ...prevState,
        documentData: data,
        documentType: 'invoice',
        printerVisible: true
      }));
    }
  };

  // handle navigate to result
  const handleNavigateToResult = () => {
    // get testResultReportId from testOrderBulkRequest
    // then navigate to /testresultreports/edit/:testResultReportId
    const { updatedTestOrderBulkRequest } = currentState;
    if (updatedTestOrderBulkRequest?.testResultReportId
      && updatedTestOrderBulkRequest?.testResultReportId !== 'N/A') {
      history.push(`/testResultReports/edit/${updatedTestOrderBulkRequest.testResultReportId}`);
    }
  };


  // handle print receipt
  const handlePrintReceipt = async () => {
    const { invoiceReportUpdatedEntity, invoiceLines, updatedTestOrderBulkRequest } = currentState;

    

    const barcodeImage = await barcodeToBase64(updatedTestOrderBulkRequest?.barcode, {
      format: 'CODE128',
      width: 2,
      height: 50,
      fontSize: 12
    });

    if (invoiceReportUpdatedEntity?.id) {
      const data = {
        "receiptInfo": {
          content: invoiceReportUpdatedEntity,
        },
        "paymentInfo": {
          content: { ... invoiceReportUpdatedEntity, barcodeImage: barcodeImage},
        },
        "receiptLines": invoiceLines?.map(line => ({
          content: line,
          template: line.template || null
        }))
      };

      setCurrentState(prevState => ({
        ...prevState,
        documentData: data,
        documentType: 'receipt',
        printerVisible: true
      }));
    }
  };
    

  const steps = [
    {
      title: 'TestOrder Bulk Request',
      content: <TestOrderBulkRequestStep
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    },
    {
      title: 'Tests',
      content: <SelectTestsStep
        currentState={currentState}
        setCurrentState={setCurrentState}
        handleSaveTestInventoryLines={handleSaveTestInventoryLines}
      />
    },
    {
      title: 'Invoice and Payment',
      content: <ReviewInvoiceStep
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    }
  ];

  const items = steps?.map(item => ({ key: item.title, title: item.title }));

  const contentStyle = {
    padding: 24,
    textAlign: 'left',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={2}>Edit TestOrder Bulk Request</Title>
      </Space>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
        Back to TestOrder Bulk Requests
      </Button>
      <br />
      <br />
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
  <Col xs={24} md={18}>
    <Card>
      <Steps current={currentState.current} items={items} />
      <div style={contentStyle}>{steps[currentState.current].content}</div>
      <div style={{ marginTop: 24, textAlign: 'right' }}>
        {currentState.current > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => handleStepChange('prev')}
            icon={<ArrowLeftOutlined />}
          >
            Previous
          </Button>
        )}
        {currentState.current < steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => handleStepChange('next')}
            icon={<ArrowRightOutlined />}
            disabled={loadingUpdate || invoiceReportState.loading}
          >
            Next
          </Button>
        )}
        {currentState.current === steps.length - 1 && (
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => history.push('/testOrderBulkRequests')}
          >
            Finish
          </Button>
        )}
      </div>
    </Card>
  </Col>
  <Col xs={24} md={6}>
    <Affix offsetTop={80}>
      {currentState.current === 0 && (
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Statistic
              title="Quick Summary"
              value="Review and Pay"
              valueStyle={{ color: '#3f8600' }}
            />
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={() => setCurrentState(prevState => ({ ...prevState, current: 2 }))}
              block
            >
              Jump to Summary
            </Button>
            <Paragraph type="secondary">
              Skip test selection and go directly to review your order and complete payment.
            </Paragraph>
          </Space>
        </Card>
      )}
      {currentState.current === 2 && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <InvoiceSummaryCard currentState={currentState} />
          <ActionsCard
            handlePrintInvoice={handlePrintInvoice}
            handlePrintReceipt={handlePrintReceipt}
            handleNavigateToResult={handleNavigateToResult}
            showSampleView={() => setCurrentState(prev => ({ ...prev, sampleViewVisible: true }))}
          />
        </Space>
      )}
    </Affix>
  </Col>
</Row>      <PrinterModal currentState={currentState} setCurrentState={setCurrentState} data={currentState.documentData} documentType={currentState.documentType} />
      <Drawer
        title="Sample View"
        placement="right"
        width="80%"
        onClose={() => setCurrentState(prev => ({ ...prev, sampleViewVisible: false }))}
        visible={currentState.sampleViewVisible}
      >
        <SampleView
          barcode={currentState.updatedTestOrderBulkRequest?.barcode}
          patientId={currentState.updatedTestOrderBulkRequest?.patientId}
          testOrderBulkRequestId={currentState.testOrderBulkRequestId}
        />
      </Drawer>

    </React.Fragment>
  );
}

const InvoiceSummaryCard = ({ currentState }) => {
  const { invoiceReportUpdatedEntity } = currentState;
  const { loading: loadingInvoiceUpdate } = useSelector((state) => state.invoiceReportUpdate);
  const { loading: loadingUpdate } = useSelector((state) => state.testOrderBulkRequestUpdate);

  const { userInfo } = useSelector((state) => state.userLogin);

  if (!invoiceReportUpdatedEntity) return null;

  return (
    <Card title="Invoice Summary" loading={loadingInvoiceUpdate || loadingUpdate}>
      <p>
        Invoice Number: <Tag color='blue'>{invoiceReportUpdatedEntity.invoiceNumber}</Tag>
      </p>
      <Statistic title="Subtotal" value={invoiceReportUpdatedEntity.subtotal} precision={2} prefix={userInfo?.currencySymbol}/>
      <Statistic title="Total Tax" value={invoiceReportUpdatedEntity.totalTax} precision={2} prefix={userInfo?.currencySymbol}/>
      <Statistic title="Total" value={invoiceReportUpdatedEntity.total} precision={2} prefix={userInfo?.currencySymbol}/>
      <Statistic title="Balance Due" value={invoiceReportUpdatedEntity.balanceDue} precision={2} prefix={userInfo?.currencySymbol}/>
      <p>
        Status: <Tag color='blue'>{invoiceReportUpdatedEntity.invoiceStatus || "N/A"}</Tag>
      </p>
    </Card>
  );
};

const ActionsCard = ({ handlePrintInvoice, handlePrintReceipt, showSampleView, handleNavigateToResult}) => (
  <Card>
    <Space direction="vertical">
      <Button type="text" icon={<ExperimentOutlined />} onClick={showSampleView}>View Samples</Button>
      <Button type="text" icon={<FileSearchOutlined />} onClick={handleNavigateToResult}>Result Report</Button>
      <Button type="text" icon={<PrinterOutlined />} onClick={handlePrintReceipt}>Print Receipt</Button>
      <Button type="text" icon={<PrinterOutlined />} onClick={handlePrintInvoice}>Print Invoice</Button>
    </Space>
  </Card>
);

const PrinterModal = ({ currentState, setCurrentState, data, documentType}) => (
  <Modal
    title={`Print ${documentType}`}
    visible={currentState.printerVisible}
    width="70%"
    style={{ top: 20 }}
    footer={null}
    onOk={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
    onCancel={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
  >
    <Printer
      configEndpoint={`/print/configs/${documentType}`}
      generateEndpoint="/print/documents/generate"
      data={data}
    />
  </Modal>
);