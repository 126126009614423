import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Statistic } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  createPricingRule,
  updatePricingRule,
  deletePricingRule,
  getPricingRuleDetails,
  listPricingRules,
  listPricingRulesByIds,
  loadPricingRuleDto,
} from '../../actions/pricingRuleActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function PricingRuleManagementScreen({ history }) {
  useEffect(() => {
    document.title = "Pricing Rule Management";
  }, []);

  const dispatch = useDispatch();

  const [selectedPricingRuleId, setSelectedPricingRuleId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [pricingRuleListFilter, setPricingRuleListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'testInventoryLineName',
  });

  const pricingRuleList = useSelector((state) => state.pricingRuleList);
  const { loading, error, pricingRules, currentPage, totalPages } = pricingRuleList;

  const pricingRuleDto = useSelector((state) => state.pricingRuleDto);
  const { pricingRuleDto: pricingRuleDtoConfig } = pricingRuleDto;

  const {userInfo} = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(loadPricingRuleDto());
    dispatch(listPricingRules(pricingRuleListFilter));
  }, [dispatch, pricingRuleListFilter]);

  const handleDelete = (id) => {
    dispatch(deletePricingRule(id));
    setPricingRuleListFilter({ query: '', page: 1, limit: 10, filter: 'testInventoryLineName' });
  };

  const handleEditForm = (id) => {
    setSelectedPricingRuleId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  const handleCancelForm = () => {
    setSelectedPricingRuleId(null);
    setShowDynamicForm(false);
  };

  const handleCreatePricingRule = () => {
    setSelectedPricingRuleId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h2>Pricing Rule Management</h2>
      </Space>
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Total Pricing Rules" value={pricingRules?.length || 0} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Active Rules" value={pricingRules?.filter(rule => rule.isActive).length || 0} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic title="Average Rate" value={pricingRules?.reduce((acc, rule) => acc + rule.defaultRate, 0) / pricingRules?.length || 0} prefix={userInfo?.currencySymbol} precision={2} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic title="Highest Rate" value={Math.max(...(pricingRules?.map(rule => rule.defaultRate) || [0]))} prefix={userInfo?.currencySymbol} precision={2} />
          </Card>
        </Col>
      </Row>

      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreatePricingRule}
            icon={<PlusOutlined />}
            size="large"
          >
            Create Pricing Rule
          </Button>
          <ListItemsDataTable
            loading={loading}
            dtoConfig={pricingRuleDtoConfig}
            data={pricingRules}
            filter={pricingRuleListFilter}
            onFilterChange={setPricingRuleListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={handleDelete}
            onStartEditRow={setSelectedPricingRuleId}
            handleSaveRow={(updatedRow) => {
              dispatch(updatePricingRule(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getPricingRuleDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}

      {showDynamicForm && (
        <React.Fragment>
          <Space direction="vertical" style={{ width: '100%', padding: '10px' }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Pricing Rules
            </Button>
          </Space>

          <DynamicFormSP
            currentEntityId={selectedPricingRuleId}
            selectedItems={[]}
            selectedEntityIds={[]}
            listingMode="allEntities"
            defaultEditMode={editMode}
            primarySearchProp="name"
            secondarySearchProp="description"
            singularEntity="pricingRule"
            pluralEntity="pricingRules"
            constantPrefix="PRICING_TIER"
            loadDto={loadPricingRuleDto}
            createEntity={createPricingRule}
            updateEntity={updatePricingRule}
            deleteEntity={deletePricingRule}
            getEntityDetails={getPricingRuleDetails}
            listEntities={listPricingRules}
            listEntitiesByIds={listPricingRulesByIds}
            onSelectedItems={() => {}}
            onSelectedEntitiesChange={() => {}}
            onUpdatedEntityChange={() => {}}
            onNewEntityChange={() => {}}
            isParent={true}
            fieldsToHide={['Id']}
            summaryProps={['name', 'description', 'defaultRate', 'isActive']}
            enableCreate={true}
            enableSearchBar={false}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}