import axios from 'axios';

import {
    QC_RULE_LIST_REQUEST,
    QC_RULE_LIST_SUCCESS,
    QC_RULE_LIST_FAIL,

    QC_RULE_DETAILS_REQUEST,
    QC_RULE_DETAILS_SUCCESS,
    QC_RULE_DETAILS_FAIL,

    QC_RULE_DELETE_REQUEST,
    QC_RULE_DELETE_SUCCESS,
    QC_RULE_DELETE_FAIL,

    QC_RULE_CREATE_REQUEST,
    QC_RULE_CREATE_SUCCESS,
    QC_RULE_CREATE_FAIL,

    QC_RULE_UPDATE_REQUEST,
    QC_RULE_UPDATE_SUCCESS,
    QC_RULE_UPDATE_FAIL,

    QC_RULE_DTO_REQUEST,
    QC_RULE_DTO_SUCCESS,
    QC_RULE_DTO_FAIL,
    QC_RULE_DTO_RESET,

    QC_RULE_LIST_BYIDS_REQUEST,
    QC_RULE_LIST_BYIDS_SUCCESS,
    QC_RULE_LIST_BYIDS_FAIL,
    QC_RULE_LIST_BYIDS_RESET
} from "../constants/qcRuleConstants";

export const listQCRules = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RULE_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/qcRule/all', config);

        dispatch({
            type: QC_RULE_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RULE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const listQCRulesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RULE_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/qcRule/ids/all', ids, config);

        dispatch({
            type: QC_RULE_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RULE_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getQCRuleDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RULE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/qcRule/${id}`, config);

        dispatch({
            type: QC_RULE_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RULE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteQCRule = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RULE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/qcRule/${id}/`,
            config
        )

        dispatch({
            type: QC_RULE_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: QC_RULE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createQCRule = (qcRule) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RULE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }


        const { data } = await axios.post(
            `/dotnet_api/v1/qcRule/`,
            qcRule,
            config
        )

        dispatch({
            type: QC_RULE_CREATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_RULE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateQCRule = (id, qcRule) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RULE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }        

        const { data } = await axios.patch(
            `/dotnet_api/v1/qcRule/${id}/`,
            qcRule,
            config
        )

        dispatch({
            type: QC_RULE_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: QC_RULE_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: QC_RULE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const loadQCRuleDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RULE_DTO_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get( '/dotnet_api/v1/qcRule/qcRuleDto', config);

        dispatch({
            type: QC_RULE_DTO_SUCCESS,
            payload: data,
        });

    }

    catch (error) {
        dispatch({
            type: QC_RULE_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }

}
