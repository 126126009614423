import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Input, Button, Space, Tooltip, Typography, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { listTests, listTestsByIds } from '../../actions/testActions';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

const { Text } = Typography;

const FormulaBuilder = ({ inputValue, onValueChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const testList = useSelector(state => state.testList);
  const testListByIDs = useSelector(state => state.testListByIDs);
  const [suggestions, setSuggestions] = useState([]);
  const [displayFormula, setDisplayFormula] = useState('');
  const [idFormula, setIdFormula] = useState('');
  const [selectedTests, setSelectedTests] = useState([]);
  const [isLoadingExistingFormula, setIsLoadingExistingFormula] = useState(false);

  const debouncedSearch = useCallback(
    debounce((query) => {
      dispatch(listTests({ filter: 'name', query, limit: 10 }));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (testList.error) {
      message.error(t('errorFetchingTests'));
    }
  }, [testList.error, t]);

  useEffect(() => {
    if (inputValue) {
      setIdFormula(inputValue);
      setIsLoadingExistingFormula(true);
      const ids = extractIds(inputValue);
      if (ids.length > 0) {
        dispatch(listTestsByIds(ids));
      }
    }
  }, [inputValue, dispatch]);

  useEffect(() => {
    if (isLoadingExistingFormula && testListByIDs.tests && testListByIDs.tests.length > 0) {
      const translated = translateIdsToNames(idFormula, testListByIDs.tests);
      setDisplayFormula(translated);
      setSelectedTests(testListByIDs.tests);
      setIsLoadingExistingFormula(false);
    }
  }, [testListByIDs.tests, idFormula, isLoadingExistingFormula]);

  useEffect(() => {
    if (testList.tests) {
      updateSuggestions();
    }
  }, [testList.tests, displayFormula]);

  const extractIds = (formula) => {
    const idRegex = /T([a-f0-9-]+)/g;
    return (formula.match(idRegex) || [])?.map(id => id.substring(1));
  };

  const translateIdsToNames = (formula, testList) => {
    return formula.replace(/T([a-f0-9-]+)/g, (_, id) => {
      const test = testList.find(t => t.id === id);
      return test ? `${test.name}` : `[T${id}]`;
    });
  };

  const handleFormulaChange = (value) => {
    setDisplayFormula(value);
    const lastTerm = value.split(/[\s\[\]]+/).pop();
    if (lastTerm) {
      debouncedSearch(lastTerm);
    }
  };

  const updateSuggestions = () => {
    const inputTests = displayFormula?.match(/\[.+?\]/g) || [];
    const newSuggestions = testList.tests
      .filter(test => !inputTests.includes(`[${test.name}]`))
      ?.map(test => ({
        value: `[${test.name}]`,
        label: test.name,
      }));
    setSuggestions(newSuggestions);
  };

  const handleSelect = (value, option) => {
    const formulaParts = displayFormula.split(/(\s+)/);
    formulaParts.pop();
    setDisplayFormula(formulaParts.join('') + value);
    
    const selectedTest = testList.tests.find(test => test.name === option.label);
    if (selectedTest) {
      setSelectedTests(prev => [...prev, selectedTest]);
    }
  };

  const addOperator = (operator) => {
    setDisplayFormula(prevFormula => prevFormula + operator);
  };

  const handleClear = () => {
    setDisplayFormula('');
    setIdFormula('');
    onValueChange('');
    setSelectedTests([]);
  };
  const translateNamesToIds = (formula) => {
    return formula.replace(/\[([^\]]+)\]/g, (match, testName) => {
      const test = selectedTests.find(t => t.name === testName);
      return test ? `[T${test.id}]` : match;
    });
  };

  const handleValidate = () => {
    if (displayFormula) {
      // Remove any double brackets before translation
      const cleanedFormula = displayFormula.replace(/\[\[/g, '[').replace(/\]\]/g, ']');
      const formulaWithIds = translateNamesToIds(cleanedFormula);
      setIdFormula(formulaWithIds);
      onValueChange(formulaWithIds);
      message.success(t('formulaValidatedSuccessfully'));
      // Reset the test list
      dispatch(listTests({}));
    } else {
      message.error(t('pleaseEnterFormulaToValidate'));
    }
  };


  return (
    <div style={{ width: '100%', padding: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '8px' }}>
      <AutoComplete
        value={displayFormula}
        options={suggestions}
        onSelect={handleSelect}
        onChange={handleFormulaChange}
        style={{ width: '100%', marginBottom: '30px' }}
        notFoundContent={testList.loading ? t('loading') : t('noTestsFound')}
      >
        <Input.TextArea
          placeholder={t('formulaBuilderPlaceholder')}
        />
      </AutoComplete>
      <Space style={{ marginBottom: '10px' }}>
        {['+', '-', '*', '/', '(', ')']?.map(op => (
          <Tooltip key={op} title={t(`operator_${op}`)}>
            <Button size="small" onClick={() => addOperator(op)}>{op}</Button>
          </Tooltip>
        ))}
        <Tooltip title={t('clear')}>
          <Button size="small" icon={<CloseOutlined />} onClick={handleClear} />
        </Tooltip>
        <Tooltip title={t('validateFormula')}>
          <Button size="small" type="primary" icon={<CheckOutlined />} onClick={handleValidate}>
            {t('validate')}
          </Button>
        </Tooltip>
      </Space>
      <div>
        <Text strong>{t('currentFormula')}: </Text>
        <Text code>{displayFormula}</Text>
      </div>
      <div>
        <Text strong>{t('formulaWithIds')}: </Text>
        <Text code>{idFormula}</Text>
      </div>
    </div>
  );
};

export default FormulaBuilder;