import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  Row, Col, Card, Typography, Table, Button, Space, 
  Descriptions, Avatar, Tag, Divider, message, Modal, Affix, Drawer
} from 'antd';
import { 
  UserOutlined, FileTextOutlined, ExperimentOutlined, 
  PrinterOutlined, BarChartOutlined, EyeOutlined
} from '@ant-design/icons';
import { getPatientDetails } from '../../actions/patientActions';
import { 
  listInvoiceReports, getInvoiceReportDetails, loadInvoiceReportDto 
} from '../../actions/invoiceReportActions';
import { 
  listTestResultReports, getTestResultReportDetails, loadTestResultReportDto 
} from '../../actions/testResultReportActions';
import { 
  listTestResults, loadTestResultDto, getTestResultDetails, listTestResultsByIds 
} from '../../actions/testResultActions';
import {
  loadInvoiceLineDto, listInvoiceLines, listInvoiceLinesByIds, getInvoiceLineDetails
} from '../../actions/invoiceLineActions';
import TestHistoryVisualizer from '../../components/testHistoryVisualizer/TestHistoryVisualizer.component';
import Printer from '../../components/printer/Printer.component';
import { barcodeToBase64, evaluateFlexibleUnitConversion } from '../../utils/functions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";

const { Title, Text } = Typography;

const PatientDashboard = () => {
  const { t } = useTranslation();
  const { _id } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    activeTab: 'invoices',
    showVisualizer: false,
    testResultDrawerVisible: false,
    invoiceDrawerVisible: false,
    selectedTestResult: null,
    selectedInvoice: null,
    selectedInvoiceLines: [],
    selectedTestResults: [],
    printerVisible: false,
    printType: '',
    reportDocumentData: null,
    invoiceDocumentData: null
  });

  const patientDetails = useSelector((state) => state.patientDetails.patient);
  const invoiceReports = useSelector((state) => state.invoiceReportList.invoiceReports);
  const testResultReports = useSelector((state) => state.testResultReportList.testResultReports);

  useEffect(() => {
    if (_id) {
      dispatch(getPatientDetails(_id));
      dispatch(listInvoiceReports({ patientId: _id }));
      dispatch(listTestResultReports({ patientId: _id }));
    }
  }, [dispatch, _id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.getTime() === 0 ? t('N/A') : date.toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    });
  };

  const handlePrintInvoice = async () => {

    if (state.selectedInvoice?.id) {
      const barcodeImage = await barcodeToBase64(state.selectedInvoice.invoiceNumber.split(', ')[0], {
        format: 'CODE128',
        width: 2,
        height: 50,
        fontSize: 12
      });


      const data = {
        "invoiceHeader": { 
          content: {
            ... state.selectedInvoice,
            barcode: state.selectedInvoice.barcode || state.selectedInvoice.invoiceNumber,
          }
        },
        "invoiceSummary": { 
          content: state.selectedInvoice,
        },
        "customerInfo":
        {
          content: {
            ...state.selectedInvoice,
          },
        },
        "barcode": {
          content: {
            barcodeImage: barcodeImage,
          }
        },
        "invoiceLines": state.selectedInvoiceLines?.map(line => ({
          content: {
            ...line,
            // other props
          },
          template: null
        }))
      };

      setState(prev => ({
        ...prev,
        invoiceDocumentData: data,
        printerVisible: true,
        printType: 'invoice'
      }));
    }
  };

  const handlePrintReport = async () => {
    if (!state.selectedTestResult.validationStatus) {
      message.warning(t('Report must be validated before printing.'));
      return;
    }

    const barcodeImage = await barcodeToBase64(state.selectedTestResult.barcode, {
      format: 'CODE128',
      width: 2,
      height: 25,
      fontSize: 12
    });

    const data = {
      "header": {
        content: {
          ...state.selectedTestResult,
          reportNumber: state.selectedTestResult.orderNumber,
          reportDate: formatDate(state.selectedTestResult.resultDate),
        },
      },
      "patientInfo": {
        content: {
          ...patientDetails,
          patientName: `${patientDetails.forename} ${patientDetails.surname}`,
          dateOfBirth: formatDate(patientDetails.dateOfBirth),
          nhsNumber: patientDetails.healthInsuranceNumber,
        },
      },
      "testResults": state.selectedTestResults?.map(testResult => ({
        content: {
          ...testResult,
          result: testResult.result || 'N/A',
          status: getTestResultStatus(testResult),
          resultClass: getTestResultClass(testResult),
          ...getConvertedResult(testResult),
          method: testResult.test?.method || '',
        }
      })),
      "reportSummary": {
        content: {
          ...state.selectedTestResult,
          orderingPhysician: state.selectedTestResult.orderingPractitioner,
          specimenCollectedDate: formatDate(state.selectedTestResult.orderDate),
          specimenReceivedDate: formatDate(state.selectedTestResult.orderDate),
          reportDate: formatDate(state.selectedTestResult.resultDate)
        },
      },
      "interpretation": {
        content: {
          interpretationText: state.selectedTestResult.reportConclusion || t("No interpretation provided.")
        },
      },
      "notes": {
        content: {
          notesText: state.selectedTestResult.comments || t("No additional notes.")
        },
      },
      "signature": {
        content: {
          validatedBy: state.selectedTestResult.verifiedBy,
          validationDate: formatDate(state.selectedTestResult.verifiedDate)
        },
      },
      "footer": {
        content: {
          footerText: t("This report is confidential and intended only for the individual or entity to whom it is addressed. If you have received this report in error, please notify the laboratory immediately.")
        },
      },
      "barcode": {
        content: {
          barcodeImage: barcodeImage,
          barcode: state.selectedTestResult.barcode,
        }
      }
    };

    setState(prev => ({
      ...prev,
      reportDocumentData: data,
      printerVisible: true,
      printType: 'test_result_report'
    }));
  };


  const getTestResultStatus = (testResult) => {
    const numResult = parseFloat(testResult.result);
    const numCriticalHigh = parseFloat(testResult.criticalHigh);
    const numCriticalLow = parseFloat(testResult.criticalLow);
    return !isNaN(numResult) && (!isNaN(numCriticalHigh) || !isNaN(numCriticalLow))
      ? (numResult > numCriticalHigh || numResult < numCriticalLow ? '*' : 'N')
      : 'N/A';
  };

  const getTestResultClass = (testResult) => {
    const numResult = parseFloat(testResult.result);
    const numCriticalHigh = parseFloat(testResult.criticalHigh);
    const numCriticalLow = parseFloat(testResult.criticalLow);
    return numResult > numCriticalHigh || numResult < numCriticalLow ? 'text-warning' : 'text-success';
  };

  const getConvertedResult = (testResult) => {
    const numResult = parseFloat(testResult.result);
    const numCriticalHigh = parseFloat(testResult.criticalHigh);
    const numCriticalLow = parseFloat(testResult.criticalLow);
    let convertedResult = null;

    if (testResult.secondaryUnits) {
      convertedResult = evaluateFlexibleUnitConversion(
        testResult.secondaryUnits,
        numResult,
        numCriticalLow,
        numCriticalHigh
      );
    }

    return {
      convertedResult: convertedResult ? convertedResult.convertedValue : 'N/A',
      secondaryUnits: convertedResult ? convertedResult.convertedUnit : null,
      secondaryReferenceRange: convertedResult ? convertedResult.convertedReferenceRange : null,
    };
  };

  const handleViewTestResult = (record) => {
    setState(prev => ({ ...prev, selectedTestResult: record, testResultDrawerVisible: true }));
    dispatch(getTestResultReportDetails(record.id));
    dispatch(listTestResults({ testResultReportId: record.id }));
  };

  const handleViewInvoice = (record) => {
    setState(prev => ({ ...prev, selectedInvoice: record, invoiceDrawerVisible: true }));
    dispatch(getInvoiceReportDetails(record.id));
    dispatch(listInvoiceLines({ invoiceReportId: record.id }));
  };

  const columns = {
    invoices: [
      { title: t('Invoice Number'), dataIndex: 'invoiceNumber', key: 'invoiceNumber' },
      { title: t('Date'), dataIndex: 'invoiceDate', key: 'invoiceDate', render: formatDate },
      { 
        title: t('Amount'), 
        dataIndex: 'total', 
        key: 'total',
        render: (total) => (total)
      },
      { 
        title: t('Status'), 
        dataIndex: 'invoiceStatus', 
        key: 'invoiceStatus',
        render: (status) => (
          <Tag color={status === 'Paid' ? 'green' : 'orange'}>
            {t(status)}
          </Tag>
        )
      },
      {
        title: t('Actions'),
        key: 'actions',
        render: (_, record) => (
          <Button icon={<EyeOutlined />} onClick={() => handleViewInvoice(record)}>
            {t('View')}
          </Button>
        ),
      },
    ],
    testResults: [
      { title: t('Report Number'), dataIndex: 'orderNumber', key: 'orderNumber' },
      { title: t('Date'), dataIndex: 'orderDate', key: 'orderDate', render: formatDate },
      { 
        title: t('Status'), 
        dataIndex: 'status', 
        key: 'status',
        render: (status) => (
          <Tag color={status === 'Completed' ? 'green' : 'orange'}>
            {t(status)}
          </Tag>
        )
      },
      {
        title: t('Actions'),
        key: 'actions',
        render: (_, record) => (
          <Button icon={<EyeOutlined />} onClick={() => handleViewTestResult(record)}>
            {t('View')}
          </Button>
        ),
      },
    ],
  };

  const renderPatientInfo = () => (
    <Card>
      <Space align="start">
        <Avatar size={64} icon={<UserOutlined />} />
        <div>
          <Title level={4}>{`${patientDetails.forename} ${patientDetails.surname}`}</Title>
          <Text type="secondary">{t('NHS Number')}: {patientDetails.healthInsuranceNumber}</Text>
        </div>
      </Space>
      <Divider />
      <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label={t('Date of Birth')}>{formatDate(patientDetails.dateOfBirth)}</Descriptions.Item>
        <Descriptions.Item label={t('Gender')}>{t(patientDetails.gender)}</Descriptions.Item>
        <Descriptions.Item label={t('Blood Type')}>{patientDetails.bloodType || t('N/A')}</Descriptions.Item>
        <Descriptions.Item label={t('Phone')}>{patientDetails.phoneNumber}</Descriptions.Item>
        <Descriptions.Item label={t('Email')}>{patientDetails.email}</Descriptions.Item>
        <Descriptions.Item label={t('Address')}>{patientDetails.address}</Descriptions.Item>
      </Descriptions>
      {patientDetails.allergies && (
        <Descriptions column={1}>
          <Descriptions.Item label={t('Allergies')}>
            <Text type="warning">{patientDetails.allergies}</Text>
          </Descriptions.Item>
        </Descriptions>
      )}
      {patientDetails.medicalConditions && (
        <Descriptions column={1}>
          <Descriptions.Item label={t('Medical Conditions')}>{patientDetails.medicalConditions}</Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );

  if (!_id) {
    return <div>{t('Error: No patient ID provided')}</div>;
  }

  return (
    <div>
      <Title level={2}>{t('Patient Dashboard')}</Title>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Affix offsetTop={80}>
            <Card>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Button 
                  type={state.activeTab === 'invoices' ? 'primary' : 'default'} 
                  icon={<FileTextOutlined />} 
                  onClick={() => setState(prev => ({ ...prev, activeTab: 'invoices' }))}
                  block
                >
                  {t('Invoices')}
                </Button>
                <Button 
                  type={state.activeTab === 'testResults' ? 'primary' : 'default'} 
                  icon={<ExperimentOutlined />} 
                  onClick={() => setState(prev => ({ ...prev, activeTab: 'testResults' }))}
                  block
                >
                  {t('Test Results')}
                </Button>
                <Button 
                  icon={<BarChartOutlined />} 
                  onClick={() => setState(prev => ({ ...prev, showVisualizer: true }))}
                  block
                >
                  {t('Test History')}
                </Button>
              </Space>
            </Card>
          </Affix>
        </Col>
        <Col span={20}>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            {renderPatientInfo()}
            <Card>
              <Table 
                columns={columns[state.activeTab]} 
                dataSource={state.activeTab === 'invoices' ? invoiceReports : testResultReports}
                rowKey="id"
              />
            </Card>
          </Space>
        </Col>
      </Row>
      <Modal
        title={t("Test History Visualization")}
        visible={state.showVisualizer}
        onCancel={() => setState(prev => ({ ...prev, showVisualizer: false }))}
        width="80%"
        footer={null}
      >
        <TestHistoryVisualizer patientId={_id} />
      </Modal>
      <Drawer
        title={t("Test Result Report")}
        placement="right"
        width="80%"
        onClose={() => setState(prev => ({ ...prev, testResultDrawerVisible: false }))}
        visible={state.testResultDrawerVisible}
      >
        {state.selectedTestResult && (
          <>
            <Descriptions title={t("Test Result Summary")} bordered>
              <Descriptions.Item label={t("Order Number")}>{state.selectedTestResult.orderNumber}</Descriptions.Item>
              <Descriptions.Item label={t("Order Code")}>{state.selectedTestResult.orderCode}</Descriptions.Item>
              <Descriptions.Item label={t("Barcode")}>{state.selectedTestResult.barcode}</Descriptions.Item>
              <Descriptions.Item label={t("Order Date")}>{formatDate(state.selectedTestResult.orderDate)}</Descriptions.Item>
              <Descriptions.Item label={t("Result Date")}>{formatDate(state.selectedTestResult.resultDate)}</Descriptions.Item>
              <Descriptions.Item label={t("Status")}>{state.selectedTestResult.status}</Descriptions.Item>
              <Descriptions.Item label={t("Comments")}>{state.selectedTestResult.comments || t('No comments')}</Descriptions.Item>
              <Descriptions.Item label={t("Report Conclusion")}>{state.selectedTestResult.reportConclusion || t('No conclusion')}</Descriptions.Item>
              <Descriptions.Item label={t("Verified By")}>{state.selectedTestResult.verifiedBy || t('Not verified')}</Descriptions.Item>
              <Descriptions.Item label={t("Verified Date")}>{formatDate(state.selectedTestResult.verifiedDate)}</Descriptions.Item>
              <Descriptions.Item label={t("Validation Status")}>
                <Tag color={state.selectedTestResult.validationStatus ? 'green' : 'red'}>
                  {state.selectedTestResult.validationStatus ? t('Validated') : t('Not Validated')}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <DynamicFormSP
              key={state.selectedTestResult.id}
              defaultEditMode="Lookup"
              listingMode='selectedIds'
              selectedItems={[]}
              selectedItemIds={state.selectedTestResult.testResultIds}
              primarySearchProp="testName"
              secondarySearchProp="result"
              singularEntity="testResult"
              pluralEntity="testResults"
              constantPrefix="TEST_RESULT"
              loadDto={loadTestResultDto}
              getEntityDetails={getTestResultDetails}
              listEntities={listTestResults}
              listEntitiesByIds={listTestResultsByIds}
              fieldsToHide={['Id', 'TestResultReportId']}
              summaryProps={['testName', 'result', 'referenceRange', 'unit', 'status']}
              enableCreate={false}
              enableSearchBar={false}
              enableActionButtons={false}
              isParent={false}
              onSelectedEntitiesChange={(selectedItems) => {
                setState(prev => ({ ...prev, selectedTestResults: selectedItems }));
              }}
            />
            <Divider />
            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrintReport}>
              {t('Print Report')}
            </Button>
          </>
        )}
      </Drawer>
      <Drawer
        title={t("Invoice Report")}
        placement="right"
        width="80%"
        onClose={() => setState(prev => ({ ...prev, invoiceDrawerVisible: false }))}
        visible={state.invoiceDrawerVisible}
      >
        {state.selectedInvoice && (
          <>
            <Descriptions title={t("Invoice Summary")} bordered>
              <Descriptions.Item label={t("Invoice Number")}>{state.selectedInvoice.invoiceNumber}</Descriptions.Item>
              <Descriptions.Item label={t("Invoice Date")}>{formatDate(state.selectedInvoice.invoiceDate)}</Descriptions.Item>
              <Descriptions.Item label={t("Customer Name")}>{state.selectedInvoice.customerName}</Descriptions.Item>
              <Descriptions.Item label={t("Subtotal")}>{(state.selectedInvoice.subtotal)}</Descriptions.Item>
              <Descriptions.Item label={t("Total Tax")}>{(state.selectedInvoice.totalTax)}</Descriptions.Item>
              <Descriptions.Item label={t("Total")}>{(state.selectedInvoice.total)}</Descriptions.Item>
              <Descriptions.Item label={t("Balance Due")}>{(state.selectedInvoice.balanceDue)}</Descriptions.Item>
              <Descriptions.Item label={t("Status")}>
                <Tag color={state.selectedInvoice.invoiceStatus === 'Paid' ? 'green' : 'orange'}>
                  {t(state.selectedInvoice.invoiceStatus)}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <DynamicFormSP
              key={state.selectedInvoice.id}
              defaultEditMode="Lookup"
              listingMode='selectedIds'
              selectedItems={[]}
              selectedItemIds={state.selectedInvoice.invoiceLineIds}
              primarySearchProp="description"
              secondarySearchProp="quantity"
              singularEntity="invoiceLine"
              pluralEntity="invoiceLines"
              constantPrefix="INVOICE_LINE"
              loadDto={loadInvoiceLineDto}
              getEntityDetails={getInvoiceLineDetails}
              listEntities={listInvoiceLines}
              listEntitiesByIds={listInvoiceLinesByIds}
              fieldsToHide={['Id', 'InvoiceReportId']}
              summaryProps={['description', 'quantity', 'unitPrice', 'total']}
              enableCreate={false}
              enableSearchBar={false}
              enableActionButtons={false}
              isParent={false}
              onSelectedEntitiesChange={(selectedItems) => {
                setState(prev => ({ ...prev, selectedInvoiceLines: selectedItems }));
              }}
            />
            <Divider />
            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrintInvoice}>
              {t('Print Invoice')}
            </Button>
          </>
        )}
      </Drawer>
      <Modal
        title={t(`Print ${state.printType === 'invoice' ? 'Invoice' : 'Test Result Report'}`)}
        visible={state.printerVisible}
        onCancel={() => setState(prev => ({ ...prev, printerVisible: false }))}
        footer={null}
        width="80%"
        zIndex={1001} // Ensure this is higher than the Drawer's zIndex
      >
        <Printer
          configEndpoint={`/print/configs/${state.printType}`}
          generateEndpoint="/print/documents/generate"
          data={state.printType === 'invoice' ? state.invoiceDocumentData : state.reportDocumentData}
        />
      </Modal>
    </div>
  );
};

export default PatientDashboard;