import {

    TEST_RESULT_LIST_REQUEST,
    TEST_RESULT_LIST_SUCCESS,
    TEST_RESULT_LIST_FAIL,

    TEST_RESULT_DETAILS_REQUEST,
    TEST_RESULT_DETAILS_SUCCESS,
    TEST_RESULT_DETAILS_FAIL,

    TEST_RESULT_DELETE_REQUEST,
    TEST_RESULT_DELETE_SUCCESS,
    TEST_RESULT_DELETE_FAIL,

    TEST_RESULT_CREATE_REQUEST,
    TEST_RESULT_CREATE_SUCCESS,
    TEST_RESULT_CREATE_FAIL,
    TEST_RESULT_CREATE_RESET,

    TEST_RESULT_UPDATE_REQUEST,
    TEST_RESULT_UPDATE_SUCCESS,
    TEST_RESULT_UPDATE_FAIL,
    TEST_RESULT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_RESULT_DTO_FAIL,
    TEST_RESULT_DTO_REQUEST,
    TEST_RESULT_DTO_SUCCESS,
    TEST_RESULT_DTO_RESET,

    TEST_RESULT_LIST_BYIDS_REQUEST,
    TEST_RESULT_LIST_BYIDS_SUCCESS,
    TEST_RESULT_LIST_BYIDS_FAIL,
    TEST_RESULT_LIST_BYIDS_RESET

} from "../constants/testResultConstants";

const initialState = {
    testResults: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testResultListReducer = (
    state = {
        testResults: []
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_LIST_REQUEST:
            return { loading: true, testResults: [] };
        case TEST_RESULT_LIST_SUCCESS:
            // our state will have a list of testResults after the request
            return {
                loading: false,
                testResults: action.payload.testResults,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                totalCount: action.payload.totalCount
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_RESULT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testResultListByIDsReducer = (
    state = {
        testResults: []
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_LIST_BYIDS_REQUEST:
            return { loading: true, testResults: [] };
        case TEST_RESULT_LIST_BYIDS_SUCCESS:
            // our state will have a list of testResults after the request
            return { loading: false, testResults: action.payload };
        case TEST_RESULT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_RESULT_LIST_BYIDS_RESET:
            return { testResults: [] };
        default:
            return state;
    }
}


export const testResultDetailsReducer = (
    state = {
        testResult: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_RESULT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_RESULT_DETAILS_SUCCESS:
            // our state will have a list of testResults after the request
            return { loading: false, testResult: action.payload };
        case TEST_RESULT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testResultDtoReducer = (state = { testResultDto: {} }, action) => {
    switch (action.type) {
        case TEST_RESULT_DTO_REQUEST:
            return { loading: true };
        case TEST_RESULT_DTO_SUCCESS:
            return { loading: false, testResultDto: action.payload };
        case TEST_RESULT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case TEST_RESULT_DTO_RESET:
            return { testResultDto: {} };
        default:
            return state;
    }
}

export const testResultDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_RESULT_DELETE_REQUEST:
            return { loading: true }

        case TEST_RESULT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_RESULT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testResultCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_RESULT_CREATE_REQUEST:
            return { loading: true }

        case TEST_RESULT_CREATE_SUCCESS:
            return { loading: false, success: true, testResult: action.payload }

        case TEST_RESULT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_RESULT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testResultUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_RESULT_UPDATE_REQUEST:
            return { loading: true }

        case TEST_RESULT_UPDATE_SUCCESS:
            return { loading: false, success: true, testResult: action.payload }

        case TEST_RESULT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_RESULT_UPDATE_RESET:
            return { testResult: {} }

        default:
            return state
    }
}