import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { 
  Row, 
  Col, 
  Card, 
  Typography, 
  Button, 
  Space, 
  Divider, 
  Tag, 
  notification,
  Skeleton
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import QCResultMiniGraph from '../../components/qc/QCResultMiniGraph';
import { 
  loadQCResultDto, 
  updateQCResult, 
  getQCResultDetails, 
  listQCResultsByIds,
  listQCResults 
} from '../../actions/qcResultActions';

const { Title, Text } = Typography;

const EditQCResultScreen = () => {
  const { _id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const [qcResult, setQcResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [graphKey, setGraphKey] = useState(0); // Used to force re-render of graph

  const qcResultDetails = useSelector(state => state.qcResultDetails);
  const { loading: loadingDetails, error: errorDetails, qcResult: fetchedQCResult } = qcResultDetails;

  useEffect(() => {
    dispatch(getQCResultDetails(_id));
  }, [dispatch, _id]);

  useEffect(() => {
    if (fetchedQCResult) {
      setQcResult(fetchedQCResult);
      setLoading(false);
    }
  }, [fetchedQCResult]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Passed':
        return 'green';
      case 'Failed':
        return 'red';
      case 'Pending':
        return 'orange';
      default:
        return 'blue';
    }
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={2}>Edit QC Result</Title>
      </Space>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
        Back 
      </Button>
      <Divider />

      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={15}> {/* 65% width on large screens */}
              <DynamicFormSP
                key={`qc-result-${_id}`}
                currentEntityId={_id}
                selectedItems={[]}
                selectedEntityIds={[]}
                listingMode="allEntities"
                defaultEditMode="Edit"
                primarySearchProp="qcResultStatus"
                secondarySearchProp="resultValue"
                singularEntity="qcResult"
                pluralEntity="qcResults"
                constantPrefix="QC_RESULT"
                loadDto={loadQCResultDto}
                updateEntity={updateQCResult}
                getEntityDetails={getQCResultDetails}
                listEntities={listQCResults}
                listEntitiesByIds={listQCResultsByIds}
                onSelectedItems={() => {}}
                onSelectedEntitiesChange={() => {}}
                onUpdatedEntityChange={(updatedEntity) => {
                  setQcResult(updatedEntity);
                  setGraphKey(prevKey => prevKey + 1); // Force graph re-render
                }}
                onNewEntityChange={() => {}}
                onEditModeChange={(newEditMode) => {

                }}
                isParent={true}
                fieldsToHide={['Id']}
                summaryProps={['qcScheduleId', 'controlMaterialId', 'resultValue', 'qcResultStatus', 'performedDate']}
                enableCreate={false}
                enableSearchBar={false}
                enableActionButtons={true}
              />
          </Col>
          <Col xs={24} lg={9}> {/* 35% width on large screens */}
            <Card title="QC Result Visualization" extra={qcResult && <Tag color={getStatusColor(qcResult.qcResultStatus)}>{qcResult.qcResultStatus}</Tag>}>
              {qcResult && (
                <QCResultMiniGraph
                  key={graphKey}
                  acceptableLimitLow={qcResult.acceptableLimitLow}
                  warningLimitLow={qcResult.warningLimitLow}
                  warningLimitHigh={qcResult.warningLimitHigh}
                  acceptableLimitHigh={qcResult.acceptableLimitHigh}
                  foundValue={qcResult.resultValue}
                  findingDate={qcResult.performedDate}
                  unit={qcResult.unit || ''}
                />
              )}
              <Divider />
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text strong>Result Interpretation:</Text>
                <Text>
                  {qcResult && (qcResult.resultValue < qcResult.acceptableLimitLow || qcResult.resultValue > qcResult.acceptableLimitHigh)
                    ? "The result is outside the acceptable limits. Further investigation may be required."
                    : "The result is within acceptable limits."}
                </Text>
                {qcResult && (
                  <>
                    <Text>
                      Acceptable Range: {qcResult.acceptableLimitLow} - {qcResult.acceptableLimitHigh} {qcResult.unit}
                    </Text>
                    <Text>
                      Warning Range: {qcResult.warningLimitLow} - {qcResult.warningLimitHigh} {qcResult.unit}
                    </Text>
                  </>
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default EditQCResultScreen;