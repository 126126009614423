import axios from 'axios';

import {
    TEST_LIST_REQUEST,
    TEST_LIST_SUCCESS,
    TEST_LIST_FAIL,

    TEST_DETAILS_REQUEST,
    TEST_DETAILS_SUCCESS,
    TEST_DETAILS_FAIL,

    TEST_DELETE_REQUEST,
    TEST_DELETE_SUCCESS,
    TEST_DELETE_FAIL,

    TEST_CREATE_REQUEST,
    TEST_CREATE_SUCCESS,
    TEST_CREATE_FAIL,

    TEST_UPDATE_REQUEST,
    TEST_UPDATE_SUCCESS,
    TEST_UPDATE_FAIL,

    TEST_REQUEST,
    TEST_FAIL,
    TEST_RESET,
    TEST_SUCCESS,

    TEST_DTO_REQUEST,
    TEST_DTO_SUCCESS,
    TEST_DTO_FAIL,
    TEST_DTO_RESET,

    TEST_LIST_BYIDS_REQUEST,
    TEST_LIST_BYIDS_SUCCESS,
    TEST_LIST_BYIDS_FAIL,
    TEST_LIST_BYIDS_RESET
    

} from "../constants/testConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listTests = (filter) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/test/all', config);

        dispatch({
            type: TEST_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later tests",
        });
    }
}

// list tests by ids
export const listTestsByIds = (ids) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        
        const { data } = await axios.post('/dotnet_api/v1/test/ids/all', ids, config);

        dispatch({
            type: TEST_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEST_LIST_BYIDS_FAIL,
            payload: errorMessages
        });
    }
}


export const loadTestDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/test/testDto', config);

        dispatch({
            type: TEST_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getTestDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/test/${_id}`, config);

        dispatch({
            type: TEST_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteTest = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/test/${_id}/`,
            config
        )

        dispatch({
            type: TEST_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: TEST_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTest = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/test/`,
            changes,
            config
        )

        dispatch({
            type: TEST_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: TEST_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadTestBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make test using a local test
export const makeTest = (file, testId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const test = await loadTestBase64(file);
    const test_input = JSON.stringify({ "test": test });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        test_input,
        config
    )
    try {
        dispatch({
            type: TEST_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": test }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: TEST_SUCCESS
                });
                if (testId && response.data) {
                    dispatch(updateTest(testId, { test: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: TEST_FAIL
                });
            });
    }

    catch (error) {
        dispatch({
            type: TEST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateTest = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/test/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: TEST_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: TEST_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEST_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}