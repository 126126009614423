import React from "react";
import { Redirect } from "react-router-dom";


import {
  UserOutlined, AuditOutlined, FileAddOutlined, SettingOutlined, PieChartOutlined,
  DashboardOutlined, LoginOutlined, LogoutOutlined, MailOutlined, SafetyCertificateOutlined,
  TeamOutlined, EditOutlined, UserAddOutlined, UserSwitchOutlined, CustomerServiceOutlined,
  CrownOutlined, MedicineBoxFilled, LineChartOutlined, DatabaseOutlined, BarcodeOutlined,
  FieldTimeOutlined, FileTextOutlined, PaperClipOutlined, WalletOutlined, CreditCardOutlined,
  ExperimentOutlined, ClockCircleOutlined, ApartmentOutlined, ClusterOutlined, SecurityScanOutlined,
  ReconciliationOutlined, FunnelPlotOutlined, BarChartOutlined, FormOutlined, FundViewOutlined,
  MacCommandOutlined, CodeSandboxOutlined, BuildOutlined, BgColorsOutlined, BookOutlined
} from '@ant-design/icons';


// Auth screens
import RegisterScreen from "../screens/RegisterScreen";
import LoginScreen from "../screens/LoginScreen";
import DashboardScreen from "../screens/DashboardScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import ResetPasswordSentScreen from "../screens/ResetPasswordSentScreen";
import ResetPasswordSetupScreen from "../screens/ResetPasswordSetupScreen";
import TwoFACodeRequestScreen from "../screens/TwoFACodeRequestScreen";
import EmailConfirmedScreen from "../screens/EmailConfirmedScreen";

// Identity user
import EditUserScreen from "../screens/users/EditUserScreen";
import UserManagementScreen from "../screens/users/UserManagementScreen";
import CreateUserScreen from "../screens/users/CreateUserScreen";
// Patient screens
import PatientListScreen from "../screens/patients/PatientListScreen";
import PatientEditScreen from "../screens/patients/PatientEditScreen";
import PatientProfileScreen from "../screens/patients/PatientProfileScreen";

// Organisation screens
import OrganisationCRUDScreen from "../screens/organisations/OrganisationCRUDScreen";

// Symptom screens
import SymptomCRUDScreen from "../screens/symptoms/SymptomCRUDScreen";

// Aetiology screens
import AetiologyCRUDScreen from "../screens/aetiologies/AetiologyCRUDScreen";

// Ecg screens
import EcgCRUDScreen from "../screens/ecgs/EcgCRUDScreen";

// FollowupCentre screens
import FollowupCentreCRUDScreen from "../screens/followupCentres/FollowupCentreCRUDScreen";

// ImplantCentre screens
import ImplantCentreCRUDScreen from "../screens/implantCentres/ImplantCentreCRUDScreen";

// Implant screens
import ImplantCRUDScreen from "../screens/implants/ImplantCRUDScreen";

// Template screens
import TemplateManagementScreen from "../screens/settings/TemplateManagementScreen";


// Patient emergency Access screens
import PatientEmergencyAccessScreen from "../screens/patients/PatientEmergencyAccessScreen";

// Implant History
import ImplantHistoryScreen from "../screens/implantHistories/ImplantHistoryCRUDScreen";

// Implant Instance
import ImplantInstanceCRUDScreen from "../screens/implantInstances/ImplantInstanceCRUDScreen";

// Settings
import SettingsScreen from "../screens/SettingsScreen";

// Unauthorized
import UnauthorizedScreen from "../screens/UnauthorizedScreen";

// Not Found
import NotFoundScreen from "../screens/NotFoundScreen";

// TestOrders
import TestOrdersScreen from "../screens/tests/TestOrdersScreen";

// Test
import TestListScreen from "../screens/tests/TestListScreen";
import TestCategoryListScreen from "../screens/tests/TestCategoryListScreen";

// Inventory
import TestInventoryLineListScreen from "../screens/inventory/TestInventoryLinesScreen";

// Test Order Bulk Requests
import TestOrderBulkRequestsScreen from "../screens/testOrderBulkRequests/TestOrderBulkRequestsScreen";
import CreateTestOrderBulkRequestScreen from "../screens/testOrderBulkRequests/CreateTestOrderBulkRequestsScreen";
import EditTestOrderBulkRequestScreen from "../screens/testOrderBulkRequests/EditTestOrderBulkRequestsScreen";

// Test Result Reports
import TestResultReportsScreen from "../screens/testResultReports/TestResultReportsScreen";
import EditTestResultReportScreen from "../screens/testResultReports/EditTestResultReportScreen";

// Pricing
import PricingTierManagementScreen from "../screens/pricing/PricingTierManagementScreen";
import PricingRuleManagementScreen from "../screens/pricing/PricingRuleManagementScreen";

// Sample
import SampleManagementScreen from "../screens/samples/SampleManagementScreen";
import SampleInventoryLinesScreen from "../screens/samples/SampleInventoryLinesScreen";
import SamplesViewPage from "../screens/samples/SamplesVewScreen";

// Control Panel
import ControlPanelScreen from "../screens/settings/controlPanel/ControlPanelScreen";
import SystemSettingsManagementScreen from "../screens/settings/GeneralSettingsManagementScreen";
import SymptomsManagementScreen from "../screens/settings/SymptomsManagementScreen";
import AetiologiesManagementScreen from "../screens/settings/AetiologiesManagementScreen";
import FollowupCentresManagementScreen from "../screens/settings/FollowupCentresManagementScreen";
import ConsentsManagementScreen from "../screens/settings/ConsentsManagementScreen";
import OrganisationsManagementScreen from "../screens/settings/OrganisationsManagementScreen";
import SmtpConfigScreen from "../screens/settings/SmtpConfigScreen";
import EncountersManagementScreen from "../screens/settings/EncountersManagementScreen";
import PaymentsManagementScreen from "../screens/payments/PaymentsManagementScreen";
import QCDashboard from "../screens/qc/QCDashboard";
import QCResultManagement from "../screens/qc/QCResultManagement";
import QCScheduleManagementScreen from "../screens/qc/QCScheduleManagementScreen";
import ControlMaterialManagementScreen from "../screens/qc/ControlMaterialManagementScreen";
import QCRuleManagementScreen from "../screens/qc/QCRuleManagementScreen";
import QCReportManagementScreen from "../screens/qc/QCReportManagementScreen";
import EditQCResultScreen from "../screens/qc/EditQCResultScreen";
import InvoiceReportsScreen from "../screens/payments/InvoiceReportsScreen";
import AccountingScreen from "../screens/payments/AccountingScreen";
import ChatScreen from "../screens/users/ChatScreen";
import TestDataImporterScreen from "../screens/settings/TestDataImporter/TestDataImporterScreen";
import PatientDashboard from "../screens/patients/PatientDashboardScreen";
import BusinessOrganisationManagementScreen from "../screens/organisations/BusinessOrganisationManagementScreen";
import GenericTemplateManagementScreen from "../screens/settings/GenericTemplateManagementScreen";

// super admin routes
// super admin routes
const superUserRoutes = [
  // User Management
  { path: "/user_management", component: UserManagementScreen, searchLabel: "User Management", searchable: true, icon: <TeamOutlined /> },
  // User Create
  { path: "/users/create", component: CreateUserScreen, searchLabel: "Create User", searchable: true, icon: <UserAddOutlined /> },
  // Profile
  { path: "/users/:_id", component: EditUserScreen, searchLabel: "Edit User Profile", searchable: false, icon: <EditOutlined /> },
  // Email Settings
  { path: "/settings/smtp", component: SmtpConfigScreen, searchLabel: "SMTP Settings", searchable: true, icon: <MailOutlined /> },
  // Organisation
  { path: "/organisations", component: OrganisationCRUDScreen, searchLabel: "Organizations", searchable: true, icon: <ApartmentOutlined /> },
  { path: "/settings/organizations", component: OrganisationsManagementScreen, searchLabel: "Organizations Management", searchable: true, icon: <ApartmentOutlined /> },
  // Organisation Management
  { path: "/business_organisation_management", component: BusinessOrganisationManagementScreen, searchLabel: "Business Organisation Management", searchable: true, icon: <ApartmentOutlined /> },
];

// Private routes
const authenticatedUserRoutes = [
  // Dashboard
  { path: "/user_dashboard", component: DashboardScreen, icon: <DashboardOutlined /> },
  // Redirect to /user_dashboard by default
  { path: "/", exact: true, component: () => <Redirect to="/user_dashboard" />, icon: <DashboardOutlined /> },
  { path: "/unauthorized", component: UnauthorizedScreen, icon: <CrownOutlined /> },
  // Patient Profile
  { path: "/myprofile", component: PatientProfileScreen, icon: <UserOutlined /> },
  // Patient
  { path: "/patients", component: PatientListScreen, searchLabel: "Patient List", searchable: true, icon: <CustomerServiceOutlined /> },
  // Patient Edit
  { path: "/patient/:_id", component: PatientEditScreen, searchLabel: "Edit Patient", searchable: false, icon: <EditOutlined /> },
  // Settings
  { path: "/settings", component: SettingsScreen, searchLabel: "Settings", searchable: true, icon: <SettingOutlined /> },
  // Data Import Export

  // Symptom
  { path: "/symptoms", component: SymptomCRUDScreen, searchLabel: "Symptoms", searchable: true, icon: <PaperClipOutlined /> },
  // Aetiology
  { path: "/aetiologies", component: AetiologyCRUDScreen, searchLabel: "Aetiologies", searchable: true, icon: <ClusterOutlined /> },
  // Ecg
  { path: "/ecgs", component: EcgCRUDScreen, searchLabel: "ECGs", searchable: true, icon: <LineChartOutlined /> },
  // Follow up
  { path: "/followup_centres", component: FollowupCentreCRUDScreen, searchLabel: "Follow-up Centres", searchable: true, icon: <MedicineBoxFilled /> },
  // Implant centre
  { path: "/implant_centres", component: ImplantCentreCRUDScreen, searchLabel: "Implant Centres", searchable: true, icon: <CrownOutlined /> },
  // Implant
  { path: "/implants", component: ImplantCRUDScreen, searchLabel: "Implants", searchable: true, icon: <BuildOutlined /> },
  // Template
  { path: "/settings/templates", component: TemplateManagementScreen, searchLabel: "Templates", searchable: true, icon: <FormOutlined /> },
  {path: "/settings/generic_templates", component: GenericTemplateManagementScreen, searchLabel: "Generic Templates", searchable: true, icon: <FormOutlined />},
  // data import export
  // Implant History
  { path: "/implant_history", component: ImplantHistoryScreen, searchLabel: "Implant History", searchable: true, icon: <FieldTimeOutlined /> },
  // implant Instance
  { path: "/implant_instance", component: ImplantInstanceCRUDScreen, searchLabel: "Implant Instances", searchable: true, icon: <BarcodeOutlined /> },
  // Test Orders
  { path: "/testOrders", component: TestOrdersScreen, searchLabel: "Test Orders", searchable: true, icon: <FileTextOutlined /> },
  // Test Order Bulk Requests
  { path: "/testOrderBulkRequests", component: TestOrderBulkRequestsScreen, searchLabel: "Bulk Test Orders", searchable: true, icon: <FileAddOutlined /> },
  { path: "/testOrderBulkRequests/create", component: CreateTestOrderBulkRequestScreen, searchLabel: "Create Bulk Test Order", searchable: true, icon: <FileAddOutlined /> },
  { path: "/testOrderBulkRequests/edit/:_id", component: EditTestOrderBulkRequestScreen, searchLabel: "Edit Bulk Test Order", searchable: false, icon: <EditOutlined /> },
  // Test Result Reports
  { path: "/testResultReports", component: TestResultReportsScreen, searchLabel: "Test Result Reports", searchable: true, icon: <FileTextOutlined /> },
  { path: "/testResultReports/edit/:_id", component: EditTestResultReportScreen, searchLabel: "Edit Test Result Report", searchable: false, icon: <EditOutlined /> },
  // Test
  { path: "/TestCatalog", component: TestListScreen, searchLabel: "Test Catalog", searchable: true, icon: <BookOutlined /> },
  // Test categoryList screen
  { path: "/TestCategories", component: TestCategoryListScreen, searchLabel: "Test Categories", searchable: true, icon: <BgColorsOutlined /> },
  // Inventory
  { path: "/inventoryLines", component: TestInventoryLineListScreen, searchLabel: "Inventory Lines", searchable: true, icon: <BarcodeOutlined /> },
  // Pricing
  { path: "/pricing/tiers", component: PricingTierManagementScreen, searchLabel: "Pricing Tiers", searchable: true, icon: <WalletOutlined /> },
  { path: "/pricing/rules", component: PricingRuleManagementScreen, searchLabel: "Pricing Rules", searchable: true, icon: <CreditCardOutlined /> },
  // Sample
  { path: "/sampleCatalog", component: SampleManagementScreen, searchLabel: "Sample Catalog", searchable: true, icon: <DatabaseOutlined /> },
  { path: "/sampleInventoryLines", component: SampleInventoryLinesScreen, searchLabel: "Sample Inventory Lines", searchable: true, icon: <BarcodeOutlined /> },
  { path: "/samples", component: SamplesViewPage, searchLabel: "Samples View", searchable: true, icon: <DatabaseOutlined /> },
  // Control Panel
  { path: "/control_panel", component: ControlPanelScreen, searchLabel: "Control Panel", searchable: true, icon: <PieChartOutlined /> },
  { path: "/settings/system", component: SystemSettingsManagementScreen, searchLabel: "System Settings", searchable: true, icon: <SettingOutlined /> },
  { path: "/settings/symptoms", component: SymptomsManagementScreen, searchLabel: "Symptoms Management", searchable: true, icon: <PaperClipOutlined /> },
  { path: "/settings/aetiologies", component: AetiologiesManagementScreen, searchLabel: "Aetiologies Management", searchable: true, icon: <ClusterOutlined /> },
  { path: "/settings/followup_centres", component: FollowupCentresManagementScreen, searchLabel: "Follow-up Centres Management", searchable: true, icon: <MedicineBoxFilled /> },
  { path: "/settings/consents", component: ConsentsManagementScreen, searchLabel: "Consents Management", searchable: true, icon: <SafetyCertificateOutlined /> },
  // Encounters
  { path: "/encounters", component: EncountersManagementScreen, searchLabel: "Encounters", searchable: true, icon: <FieldTimeOutlined /> },
  // payments
  { path: "/payments", component: PaymentsManagementScreen, searchLabel: "Payments", searchable: true, icon: <WalletOutlined /> },
  { path: "/invoiceReports", component: InvoiceReportsScreen, searchLabel: "Invoice Reports", searchable: true, icon: <CreditCardOutlined /> },
  { path: "/accounting", component: AccountingScreen, searchLabel: "Accounting", searchable: true, icon: <FundViewOutlined /> },
  // QC Dashboard
  { path: "/qc_dashboard", component: QCDashboard, searchLabel: "QC Dashboard", searchable: true, icon: <PieChartOutlined /> },
  { path: "/qc_result_management", component: QCResultManagement, searchLabel: "QC Result Management", searchable: true, icon: <ExperimentOutlined /> },
  { path: "/qc_schedule_management", component: QCScheduleManagementScreen, searchLabel: "QC Schedule Management", searchable: true, icon: <ClockCircleOutlined /> },
  { path: "/control_material_management", component: ControlMaterialManagementScreen, searchLabel: "Control Material Management", searchable: true, icon: <DatabaseOutlined /> },
  { path: "/qc_rule_management", component: QCRuleManagementScreen, searchLabel: "QC Rule Management", searchable: true, icon: <FunnelPlotOutlined /> },
  { path: "/qc_report_management", component: QCReportManagementScreen, searchLabel: "QC Report Management", searchable: true, icon: <FileTextOutlined /> },
  { path: "/qc_result_management/edit/:_id", component: EditQCResultScreen, searchLabel: "Edit QC Result", searchable: false, icon: <EditOutlined /> },

  // Chat
  { path: "/chat", component: ChatScreen, searchLabel: "Chat", searchable: true, icon: <MacCommandOutlined /> },

  // Test Data Importer
  { path: "/test_data_importer", component: TestDataImporterScreen, searchLabel: "Test Data Importer", searchable: true, icon: <DatabaseOutlined /> },

  // Patient Dashboard
  { path: "/patient_dashboard/:_id", component: PatientDashboard, searchLabel: "Patient Dashboard", searchable: true, icon: <DashboardOutlined /> },


]
  ;

// Public routes
const publicRoutes = [
  // Authencation page
  { path: "/usersignup", component: RegisterScreen, icon: <UserAddOutlined /> },
  { path: "/userlogin", component: LoginScreen, icon: <LoginOutlined /> },
  { path: "/user_reset_password", component: ResetPasswordScreen, icon: <SafetyCertificateOutlined /> },
  { path: "/user_reset_passwordsent", component: ResetPasswordSentScreen, icon: <MailOutlined /> },
  { path: "/user_reset_password_setup", component: ResetPasswordSetupScreen, icon: <SafetyCertificateOutlined /> },
  { path: "/2fauth", component: TwoFACodeRequestScreen, icon: <SecurityScanOutlined /> },
  { path: "/email_confirmed", component: EmailConfirmedScreen, icon: <MailOutlined /> },
  { path: "/patient/emergency/:_id", component: PatientEmergencyAccessScreen, icon: <CustomerServiceOutlined /> },
  { path: "/not_found", component: NotFoundScreen, icon: <CodeSandboxOutlined /> }
];


export { authenticatedUserRoutes, publicRoutes, superUserRoutes };