import React, { useState, useEffect } from 'react';
import { DatePicker, Button, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';

const DatetimeInput = ({ fieldName, value, handleInputChange }) => {
  const [displayValue, setDisplayValue] = useState(() => {
    const parsedValue = value ? dayjs(value) : dayjs();
    return parsedValue.isValid() && parsedValue.year() >= 1902 ? parsedValue : null;
  });

  useEffect(() => {
    if (value && dayjs(value).isSame(dayjs('0001-01-01T00:00:00'))) {
      const now = dayjs();
      setDisplayValue(now);
      handleInputChange(fieldName, now.toISOString());
    }
  }, [value, fieldName, handleInputChange]);

  const handleNowClick = () => {
    const now = dayjs();
    setDisplayValue(now);
    handleInputChange(fieldName, now.toISOString());
  };

  const handleDateChange = (date, dateString) => {
    if (date && date.isValid() && date.year() >= 1902) {
      setDisplayValue(date);
      handleInputChange(fieldName, date.toISOString());
    } else {
      setDisplayValue(null);
      handleInputChange(fieldName, null);
    }
  };

  return (
    <Space>
      <DatePicker
        value={displayValue}
        placeholder="Select date and time"
        allowClear={true}
        showTime={{ format: 'HH:mm' }}
        format="YYYY-MM-DD HH:mm"
        onChange={handleDateChange}
      />
      {!displayValue && (
        <Tooltip title="Set to current date and time">
          <Button type="primary" size="small" onClick={handleNowClick}>
            Now
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};

export default DatetimeInput;