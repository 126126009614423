import {
    TEMPLATE_LIST_REQUEST,
    TEMPLATE_LIST_SUCCESS,
    TEMPLATE_LIST_FAIL,
    TEMPLATE_DETAILS_REQUEST,
    TEMPLATE_DETAILS_SUCCESS,
    TEMPLATE_DETAILS_FAIL,
    TEMPLATE_CREATE_REQUEST,
    TEMPLATE_CREATE_SUCCESS,
    TEMPLATE_CREATE_FAIL,
    TEMPLATE_UPDATE_REQUEST,
    TEMPLATE_UPDATE_SUCCESS,
    TEMPLATE_UPDATE_FAIL,
    TEMPLATE_DELETE_REQUEST,
    TEMPLATE_DELETE_SUCCESS,
    TEMPLATE_DELETE_FAIL,
    TEMPLATE_PREVIEW_REQUEST,
    TEMPLATE_PREVIEW_SUCCESS,
    TEMPLATE_PREVIEW_FAIL,
    GENERIC_TEMPLATE_LIST_REQUEST,
    GENERIC_TEMPLATE_LIST_SUCCESS,
    GENERIC_TEMPLATE_LIST_FAIL,
    GENERIC_TEMPLATE_DETAILS_REQUEST,
    GENERIC_TEMPLATE_DETAILS_SUCCESS,
    GENERIC_TEMPLATE_DETAILS_FAIL,
    GENERIC_TEMPLATE_CREATE_REQUEST,
    GENERIC_TEMPLATE_CREATE_SUCCESS,
    GENERIC_TEMPLATE_CREATE_FAIL,
    GENERIC_TEMPLATE_UPDATE_REQUEST,
    GENERIC_TEMPLATE_UPDATE_SUCCESS,
    GENERIC_TEMPLATE_UPDATE_FAIL,
    GENERIC_TEMPLATE_DELETE_REQUEST,
    GENERIC_TEMPLATE_DELETE_SUCCESS,
    GENERIC_TEMPLATE_DELETE_FAIL,
  
  } from '../constants/templateConstants';
  
  export const templateListReducer = (state = { templates: [] }, action) => {
    switch (action.type) {
      case TEMPLATE_LIST_REQUEST:
        return { loading: true, templates: [] };
      case TEMPLATE_LIST_SUCCESS:
        return { loading: false, templates: action.payload };
      case TEMPLATE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const templateDetailsReducer = (state = { template: {} }, action) => {
    switch (action.type) {
      case TEMPLATE_DETAILS_REQUEST:
        return { loading: true, ...state };
      case TEMPLATE_DETAILS_SUCCESS:
        return { loading: false, template: action.payload };
      case TEMPLATE_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const templateCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case TEMPLATE_CREATE_REQUEST:
        return { loading: true };
      case TEMPLATE_CREATE_SUCCESS:
        return { loading: false, success: true };
      case TEMPLATE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const templateUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case TEMPLATE_UPDATE_REQUEST:
        return { loading: true };
      case TEMPLATE_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case TEMPLATE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const templateDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case TEMPLATE_DELETE_REQUEST:
        return { loading: true };
      case TEMPLATE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case TEMPLATE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const templatePreviewReducer = (state = {}, action) => {
    switch (action.type) {
      case TEMPLATE_PREVIEW_REQUEST:
        return { loading: true };
      case TEMPLATE_PREVIEW_SUCCESS:
        return { loading: false, preview: action.payload };
      case TEMPLATE_PREVIEW_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const genericTemplateListReducer = (state = { genericTemplates: [] }, action) => {
    switch (action.type) {
      case GENERIC_TEMPLATE_LIST_REQUEST:
        return { loading: true, genericTemplates: [] };
      case GENERIC_TEMPLATE_LIST_SUCCESS:
        return { 
          loading: false, 
          genericTemplates: action.payload.templates,
          pages: action.payload.totalPages,
          page: action.payload.currentPage,
        };
      case GENERIC_TEMPLATE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const genericTemplateDetailsReducer = (state = { genericTemplate: {} }, action) => {
    switch (action.type) {
      case GENERIC_TEMPLATE_DETAILS_REQUEST:
        return { loading: true, ...state };
      case GENERIC_TEMPLATE_DETAILS_SUCCESS:
        return { loading: false, genericTemplate: action.payload };
      case GENERIC_TEMPLATE_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const genericTemplateCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case GENERIC_TEMPLATE_CREATE_REQUEST:
        return { loading: true };
      case GENERIC_TEMPLATE_CREATE_SUCCESS:
        return { loading: false, success: true, genericTemplate: action.payload };
      case GENERIC_TEMPLATE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const genericTemplateUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case GENERIC_TEMPLATE_UPDATE_REQUEST:
        return { loading: true };
      case GENERIC_TEMPLATE_UPDATE_SUCCESS:
        return { loading: false, success: true, genericTemplate: action.payload };
      case GENERIC_TEMPLATE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const genericTemplateDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case GENERIC_TEMPLATE_DELETE_REQUEST:
        return { loading: true };
      case GENERIC_TEMPLATE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case GENERIC_TEMPLATE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  