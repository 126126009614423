import React, { useEffect, useState } from "react";
import { Layout, Typography, Result, Button, Space } from "antd";
import { CheckCircleOutlined, LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const EmailConfirmedScreen = () => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    document.title = t('page.emailConfirmed.title', 'Email Confirmed');
  }, [t]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
      } else {
        handleLogout();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("twoFactorAuthentication");
    window.location.href = "/userlogin";
  };

  const handlePasswordReset = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("twoFactorAuthentication");
    window.location.href = "/user_reset_password_setup";
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "50px 0" }}>
        <Result
          status="success"
          icon={<CheckCircleOutlined />}
          title={t('page.emailConfirmed.header', 'Email Confirmed!')}
          subTitle={
            <Paragraph>
              {t('page.emailConfirmed.message', 'Your email has been confirmed. You can now login to the application.')}
              <br />
              {t('page.emailConfirmed.redirect', 'You will be redirected to the login page in {{countdown}} seconds...', { countdown })}
            </Paragraph>
          }
          extra={
            <Space size="middle">
              <Button type="primary" icon={<LockOutlined />} onClick={handlePasswordReset}>
                {t('page.emailConfirmed.resetPassword', 'Reset my password')}
              </Button>
              <Button icon={<LogoutOutlined />} onClick={handleLogout}>
                {t('page.emailConfirmed.login', 'Go to Login')}
              </Button>
            </Space>
          }
        />
      </Content>
    </Layout>
  );
};

export default EmailConfirmedScreen;