import React from 'react';
import { Typography } from 'antd';
import DynamicFormSP from '../../../components/crud/DynamicFormSP.component';
import { createTestOrderBulkRequest, updateTestOrderBulkRequest, getTestOrderBulkRequestDetails, listTestOrderBulkRequests, listTestOrderBulkRequestsByIds, loadTestOrderBulkRequestDto } from '../../../actions/testOrderBulkRequestActions';

const { Title, Paragraph } = Typography;

const TestOrderBulkRequestStep = ({ currentState, setCurrentState }) => {
  const { testOrderBulkRequestId, editMode } = currentState;

  return (
    <div>
      <Title level={4}>{editMode === 'Edit' ? 'Edit' : 'Create'} a TestOrder Bulk Request</Title>
      <Paragraph>
        {editMode === 'Edit' 
          ? 'Update the TestOrder Bulk Request information as needed. This step allows you to modify details such as the healthcare provider information, patient demographics, and any additional notes.'
          : 'To initiate the TestOrder Bulk Request process, fill out the required information such as the healthcare provider details, patient demographics, and any additional notes. This step ensures that all necessary data is captured accurately to streamline the ordering process.'}
      </Paragraph>
      <DynamicFormSP
        currentEntityId={testOrderBulkRequestId}
        selectedItems={null}
        selectedEntityIds={null}
        listingMode="allEntities"
        defaultEditMode={editMode}
        primarySearchProp="surname"
        secondarySearchProp="forename"
        singularEntity="testOrderBulkRequest"
        pluralEntity="testOrderBulkRequests"
        constantPrefix="TEST_ORDER_BULK_REQUEST"
        loadDto={loadTestOrderBulkRequestDto}
        createEntity={createTestOrderBulkRequest}
        updateEntity={updateTestOrderBulkRequest}
        getEntityDetails={getTestOrderBulkRequestDetails}
        listEntities={listTestOrderBulkRequests}
        listEntitiesByIds={listTestOrderBulkRequestsByIds}
        onUpdatedEntityChange={(updatedEntity) => {
          setCurrentState(prevState => ({
            ...prevState,
            updatedTestOrderBulkRequest: updatedEntity,
            invoiceReportId: updatedEntity?.invoiceReportId
          }));
        }}
        onEditModeChange={(newEditMode) => {
          setCurrentState(prevState => ({
            ...prevState,
            editMode: newEditMode,
          }));
        }}
        isParent={false}
        fieldsToHide={['Id', 'Test', 'TestResultReportId', 'InvoiceReportId', 'SampleIds', 'TestOrderIds', 'TestInventoryLineIds']}
        summaryProps={['healthInsuranceNumber', 'surname', 'forename', 'dateOfBirth']}
        enableExportButtons={true}
        enableCreate={false}
        enableSearchBar={false}
        enableActionButtons={true}
      />
    </div>
  );
};

export default TestOrderBulkRequestStep;