import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Button, Space, Drawer, Form, Input, Switch, 
  message, Popconfirm, Typography, Card, Row, Col, Alert, Divider,
  Tag
} from 'antd';
import {
  EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, ReloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  listSystemSettings,
  createSystemSetting,
  updateSystemSetting,
  deleteSystemSetting,
  getSystemSettingDto
} from '../../actions/systemSettingActions';

const { Title } = Typography;

const SystemSettingsManagementScreen = () => {
  const [form] = Form.useForm();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [drawerError, setDrawerError] = useState(null);

  const dispatch = useDispatch();

  const systemSettingList = useSelector(state => state.systemSettingList);
  const { loading, error, systemSettings = [] } = systemSettingList;

  const systemSettingCreate = useSelector(state => state.systemSettingCreate);
  const { success: successCreate, error: errorCreate, loading: loadingCreate } = systemSettingCreate;

  const systemSettingUpdate = useSelector(state => state.systemSettingUpdate);
  const { success: successUpdate, error: errorUpdate, loading: loadingUpdate } = systemSettingUpdate;

  const systemSettingDelete = useSelector(state => state.systemSettingDelete);
  const { success: successDelete, error: errorDelete } = systemSettingDelete;

  useEffect(() => {
    dispatch(listSystemSettings());
    dispatch(getSystemSettingDto());
  }, [dispatch]);

  useEffect(() => {
    if (successCreate || successUpdate) {
      setIsDrawerVisible(false);
      form.resetFields();
      dispatch(listSystemSettings());
      setDrawerError(null);
    }
    if (successDelete) {
      dispatch(listSystemSettings());
    }
  }, [successCreate, successUpdate, successDelete, dispatch, form]);

  useEffect(() => {
    if (errorCreate || errorUpdate) {
      setDrawerError(Array.isArray(errorCreate || errorUpdate)
        ? (errorCreate || errorUpdate).join(' ')
        : (errorCreate || errorUpdate));
    }
  }, [errorCreate, errorUpdate]);

  const showDrawer = (record = null) => {
    setEditingId(record ? record.id : null);
    if (record) {
      form.setFieldsValue({
        ...record,
        lastUpdated: moment(record.lastUpdated)
      });
    } else {
      form.resetFields();
    }
    setIsDrawerVisible(true);
    setDrawerError(null);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setEditingId(null);
    form.resetFields();
    setDrawerError(null);
  };

  const onFinish = (values) => {
    const currentDateTime = moment().toISOString();
    if (editingId) {
      dispatch(updateSystemSetting(editingId, { ...values, id: editingId, lastUpdated: currentDateTime }));
    } else {
      dispatch(createSystemSetting({ ...values, lastUpdated: currentDateTime }));
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteSystemSetting(id));
  };

  const handleRefresh = () => {
    dispatch(listSystemSettings());
  };

  const columns = [
    {
      title: 'Setting',
      dataIndex: 'settingKey',
      key: 'settingKey',
      sorter: (a, b) => a.settingKey.localeCompare(b.settingKey),
    },
    {
      title: 'Value',
      dataIndex: 'settingValue',
      key: 'settingValue',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => (a.group || '').localeCompare(b.group || ''),
      render: group => group ? <Tag>{group}</Tag> : null,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: isActive => isActive ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>,
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: date => moment(date).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => moment(a.lastUpdated).unix() - moment(b.lastUpdated).unix(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => showDrawer(record)}>Edit</Button>
          <Popconfirm
            title="Are you sure to delete this setting?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type='text' icon={<DeleteOutlined />} danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const filteredSettings = systemSettings?.filter(setting =>
    setting.settingKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
    setting.settingValue.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (setting.group && setting.group.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Card>
      <Title level={2}>System Settings Management</Title>
      <Divider style={{ marginTop: '12px', marginBottom: '24px' }} />
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Input.Search
            placeholder="Search settings"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: 300 }}
          />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => showDrawer()}>
            Add New Setting
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
            Refresh
          </Button>
        </Col>
      </Row>

      {error && <Alert message={error} type="error" showIcon style={{ marginTop: 16, marginBottom: 16 }} />}

      <Table
        columns={columns}
        dataSource={filteredSettings}
        rowKey="id"
        loading={loading}
        style={{ marginTop: 16 }}
      />

      <Drawer
        title={editingId ? "Edit System Setting" : "Add New System Setting"}
        placement="right"
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
        width={520}
      >
        {drawerError && (
          <Alert
            message="Error"
            description={drawerError}
            type="error"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="settingKey"
            label="Setting Key"
            rules={[{ required: true, message: 'Setting Key is required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="settingValue"
            label="Setting Value"
            rules={[{ required: true, message: 'Setting Value is required' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="group" label="Group">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description is required' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loadingCreate || loadingUpdate}>
              {editingId ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      
      {errorDelete && message.error(Array.isArray(errorDelete) ? errorDelete.join(' ') : errorDelete)}
    </Card>
  );
};

export default SystemSettingsManagementScreen;