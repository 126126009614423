import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Card, Form, Input, Button, Typography, Space, Alert, Select, theme } from "antd";
import { LockOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { ThemeContext } from "../index";
import logo from "../assets/images/logo.svg";

const { Title } = Typography;
const { useToken } = theme;

function ResetPasswordScreen() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { themeConfig } = useContext(ThemeContext);
  const { token } = useToken();

  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [password, setPassword] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = t("resetPassword.pageTitle");
  }, [t]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedToken = searchParams.get("token");
    const decodedToken = encodedToken.replace(/ /g, "+");
    const email = searchParams.get("email");
    setEmail(email);
    setResetToken(decodedToken);
  }, [location]);

  const handleResetPassword = async (values) => {
    if (!password || password.length < 6) {
      setResetMessage(t("resetPassword.passwordLengthError"));
      return;
    }

    try {
      setLoading(true);

      const resetRequest = {
        email: email,
        password: password,
        token: resetToken,
      };

      await axios.post("/dotnet_api/v1/auth/reset_password", resetRequest);

      setResetMessage(t("resetPassword.resetSuccessful"));
      setTimeout(() => {
        history.push("/userlogin");
      }, 2000);
    } catch (error) {
      setResetMessage(t("resetPassword.resetError"));
      console.error("Reset password error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  const languageOptions = [
    { value: "en", label: "🇬🇧 English" },
    { value: "fr", label: "🇫🇷 Français" },
  ];

  const handleGoBack = () => {
    // Redirect to login screen
    history.push("/userlogin");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: token.colorBgLayout,
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: 400,
          boxShadow: `0 4px 8px ${token.colorBgElevated}`,
          borderRadius: "8px",
          background: token.colorBgContainer,
        }}
        cover={
          <div
            style={{
              background: "transparent",
              marginTop: "50px",
              marginBottom: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
                maxWidth: "200px",
                filter:
                  themeConfig.algorithm === theme.darkAlgorithm
                    ? "invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)"
                    : "none",
              }}
            />
          </div>
        }
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Title level={3} style={{ textAlign: "center", margin: 0, color: token.colorTextHeading }}>
            {t("resetPassword.title")}
          </Title>

          {resetMessage && (
            <Alert
              message={resetMessage}
              type={resetMessage.includes("successful") ? "success" : "error"}
              showIcon
            />
          )}

          <Form form={form} name="resetPassword" onFinish={handleResetPassword} layout="vertical">
            <Form.Item
              name="password"
              rules={[{ required: true, message: t("resetPassword.passwordRequired") }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("resetPassword.newPasswordPlaceholder")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}>
                {loading ? t("resetPassword.resetting") : t("resetPassword.resetButton")}
              </Button>
            </Form.Item>
          </Form>

          <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
            {t("resetPasswordSent.goBack")}
          </Button>

          <Select
            defaultValue={i18n.language}
            style={{ width: 120, marginTop: 16 }}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
        </Space>
      </Card>
    </div>
  );
}

export default ResetPasswordScreen;