export const PRICING_TIER_LIST_REQUEST = 'PRICING_TIER_LIST_REQUEST'
export const PRICING_TIER_LIST_SUCCESS = 'PRICING_TIER_LIST_SUCCESS'
export const PRICING_TIER_LIST_FAIL = 'PRICING_TIER_LIST_FAIL'


export const PRICING_TIER_DETAILS_REQUEST = 'PRICING_TIER_DETAILS_REQUEST'
export const PRICING_TIER_DETAILS_SUCCESS = 'PRICING_TIER_DETAILS_SUCCESS'
export const PRICING_TIER_DETAILS_FAIL = 'PRICING_TIER_DETAILS_FAIL'


export const PRICING_TIER_DELETE_REQUEST = 'PRICING_TIER_DELETE_REQUEST'
export const PRICING_TIER_DELETE_SUCCESS = 'PRICING_TIER_DELETE_SUCCESS'
export const PRICING_TIER_DELETE_FAIL = 'PRICING_TIER_DELETE_FAIL'

export const PRICING_TIER_CREATE_REQUEST = 'PRICING_TIER_CREATE_REQUEST'
export const PRICING_TIER_CREATE_SUCCESS = 'PRICING_TIER_CREATE_SUCCESS'
export const PRICING_TIER_CREATE_FAIL = 'PRICING_TIER_CREATE_FAIL'
export const PRICING_TIER_CREATE_RESET = 'PRICING_TIER_CREATE_RESET'

export const PRICING_TIER_UPDATE_REQUEST = 'PRICING_TIER_UPDATE_REQUEST'
export const PRICING_TIER_UPDATE_SUCCESS = 'PRICING_TIER_UPDATE_SUCCESS'
export const PRICING_TIER_UPDATE_FAIL = 'PRICING_TIER_UPDATE_FAIL'
export const PRICING_TIER_UPDATE_RESET = 'PRICING_TIER_UPDATE_RESET'

export const PRICING_TIER_REQUEST = 'PRICING_TIER_REQUEST'
export const PRICING_TIER_SUCCESS = 'PRICING_TIER_SUCCESS'
export const PRICING_TIER_FAIL = 'PRICING_TIER_FAIL'
export const PRICING_TIER_RESET = 'PRICING_TIER_RESET'

export const PRICING_TIER_COUNT_REQUEST = "PRICING_TIER_COUNT_REQUEST";
export const PRICING_TIER_COUNT_SUCCESS = "PRICING_TIER_COUNT_SUCCESS";
export const PRICING_TIER_COUNT_FAIL = "PRICING_TIER_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const PRICING_TIER_DTO_REQUEST = 'PRICING_TIER_DTO_REQUEST';
export const PRICING_TIER_DTO_SUCCESS = 'PRICING_TIER_DTO_SUCCESS';
export const PRICING_TIER_DTO_FAIL = 'PRICING_TIER_DTO_FAIL';
export const PRICING_TIER_DTO_RESET = 'PRICING_TIER_DTO_RESET';

export const PRICING_TIER_LIST_BYIDS_REQUEST = 'PRICING_TIER_LIST_BYIDS_REQUEST';
export const PRICING_TIER_LIST_BYIDS_SUCCESS = 'PRICING_TIER_LIST_BYIDS_SUCCESS';
export const PRICING_TIER_LIST_BYIDS_FAIL = 'PRICING_TIER_LIST_BYIDS_FAIL';
export const PRICING_TIER_LIST_BYIDS_RESET = 'PRICING_TIER_LIST_BYIDS_RESET';