export const DOCUMENT_LIST_REQUEST = 'DOCUMENT_LIST_REQUEST'
export const DOCUMENT_LIST_SUCCESS = 'DOCUMENT_LIST_SUCCESS'
export const DOCUMENT_LIST_FAIL = 'DOCUMENT_LIST_FAIL'


export const DOCUMENT_DETAILS_REQUEST = 'DOCUMENT_DETAILS_REQUEST'
export const DOCUMENT_DETAILS_SUCCESS = 'DOCUMENT_DETAILS_SUCCESS'
export const DOCUMENT_DETAILS_FAIL = 'DOCUMENT_DETAILS_FAIL'


export const DOCUMENT_DELETE_REQUEST = 'DOCUMENT_DELETE_REQUEST'
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS'
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL'

export const DOCUMENT_CREATE_REQUEST = 'DOCUMENT_CREATE_REQUEST'
export const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS'
export const DOCUMENT_CREATE_FAIL = 'DOCUMENT_CREATE_FAIL'
export const DOCUMENT_CREATE_RESET = 'DOCUMENT_CREATE_RESET'

export const DOCUMENT_UPDATE_REQUEST = 'DOCUMENT_UPDATE_REQUEST'
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS'
export const DOCUMENT_UPDATE_FAIL = 'DOCUMENT_UPDATE_FAIL'
export const DOCUMENT_UPDATE_RESET = 'DOCUMENT_UPDATE_RESET'

export const DOCUMENT_REQUEST = 'DOCUMENT_REQUEST'
export const DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS'
export const DOCUMENT_FAIL = 'DOCUMENT_FAIL'
export const DOCUMENT_RESET = 'DOCUMENT_RESET'

export const DOCUMENT_COUNT_REQUEST = "DOCUMENT_COUNT_REQUEST";
export const DOCUMENT_COUNT_SUCCESS = "DOCUMENT_COUNT_SUCCESS";
export const DOCUMENT_COUNT_FAIL = "DOCUMENT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const DOCUMENT_DTO_REQUEST = 'DOCUMENT_DTO_REQUEST';
export const DOCUMENT_DTO_SUCCESS = 'DOCUMENT_DTO_SUCCESS';
export const DOCUMENT_DTO_FAIL = 'DOCUMENT_DTO_FAIL';
export const DOCUMENT_DTO_RESET = 'DOCUMENT_DTO_RESET';

export const DOCUMENT_LIST_BYIDS_REQUEST = 'DOCUMENT_LIST_BYIDS_REQUEST';
export const DOCUMENT_LIST_BYIDS_SUCCESS = 'DOCUMENT_LIST_BYIDS_SUCCESS';
export const DOCUMENT_LIST_BYIDS_FAIL = 'DOCUMENT_LIST_BYIDS_FAIL';
export const DOCUMENT_LIST_BYIDS_RESET = 'DOCUMENT_LIST_BYIDS_RESET';