export const ORGANISATION_LIST_REQUEST = 'ORGANISATION_LIST_REQUEST'
export const ORGANISATION_LIST_SUCCESS = 'ORGANISATION_LIST_SUCCESS'
export const ORGANISATION_LIST_FAIL = 'ORGANISATION_LIST_FAIL'


export const ORGANISATION_DETAILS_REQUEST = 'ORGANISATION_DETAILS_REQUEST'
export const ORGANISATION_DETAILS_SUCCESS = 'ORGANISATION_DETAILS_SUCCESS'
export const ORGANISATION_DETAILS_FAIL = 'ORGANISATION_DETAILS_FAIL'


export const ORGANISATION_DELETE_REQUEST = 'ORGANISATION_DELETE_REQUEST'
export const ORGANISATION_DELETE_SUCCESS = 'ORGANISATION_DELETE_SUCCESS'
export const ORGANISATION_DELETE_FAIL = 'ORGANISATION_DELETE_FAIL'

export const ORGANISATION_CREATE_REQUEST = 'ORGANISATION_CREATE_REQUEST'
export const ORGANISATION_CREATE_SUCCESS = 'ORGANISATION_CREATE_SUCCESS'
export const ORGANISATION_CREATE_FAIL = 'ORGANISATION_CREATE_FAIL'
export const ORGANISATION_CREATE_RESET = 'ORGANISATION_CREATE_RESET'


export const ORGANISATION_UPDATE_REQUEST = 'ORGANISATION_UPDATE_REQUEST'
export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS'
export const ORGANISATION_UPDATE_FAIL = 'ORGANISATION_UPDATE_FAIL'
export const ORGANISATION_UPDATE_RESET = 'ORGANISATION_UPDATE_RESET'

export const ORGANISATION_REQUEST = 'ORGANISATION_REQUEST'
export const ORGANISATION_SUCCESS = 'ORGANISATION_SUCCESS'
export const ORGANISATION_FAIL = 'ORGANISATION_FAIL'
export const ORGANISATION_RESET = 'ORGANISATION_RESET'

export const ORGANISATION_COUNT_REQUEST = "ORGANISATION_COUNT_REQUEST";
export const ORGANISATION_COUNT_SUCCESS = "ORGANISATION_COUNT_SUCCESS";
export const ORGANISATION_COUNT_FAIL = "ORGANISATION_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const ORGANISATION_DTO_FAIL = 'ORGANISATION_DTO_FAIL'
export const ORGANISATION_DTO_REQUEST = 'ORGANISATION_DTO_REQUEST'
export const ORGANISATION_DTO_SUCCESS = 'ORGANISATION_DTO_SUCCESS'
export const ORGANISATION_DTO_RESET = 'ORGANISATION_DTO_RESET'

export const ORGANISATION_LIST_BYIDS_REQUEST = 'ORGANISATION_LIST_BYIDS_REQUEST'
export const ORGANISATION_LIST_BYIDS_SUCCESS = 'ORGANISATION_LIST_BYIDS_SUCCESS'
export const ORGANISATION_LIST_BYIDS_FAIL = 'ORGANISATION_LIST_BYIDS_FAIL'
export const ORGANISATION_LIST_BYIDS_RESET = 'ORGANISATION_LIST_BYIDS_RESET'