export const INVOICE_LINE_LIST_REQUEST = 'INVOICE_LINE_LIST_REQUEST'
export const INVOICE_LINE_LIST_SUCCESS = 'INVOICE_LINE_LIST_SUCCESS'
export const INVOICE_LINE_LIST_FAIL = 'INVOICE_LINE_LIST_FAIL'


export const INVOICE_LINE_DETAILS_REQUEST = 'INVOICE_LINE_DETAILS_REQUEST'
export const INVOICE_LINE_DETAILS_SUCCESS = 'INVOICE_LINE_DETAILS_SUCCESS'
export const INVOICE_LINE_DETAILS_FAIL = 'INVOICE_LINE_DETAILS_FAIL'


export const INVOICE_LINE_DELETE_REQUEST = 'INVOICE_LINE_DELETE_REQUEST'
export const INVOICE_LINE_DELETE_SUCCESS = 'INVOICE_LINE_DELETE_SUCCESS'
export const INVOICE_LINE_DELETE_FAIL = 'INVOICE_LINE_DELETE_FAIL'

export const INVOICE_LINE_CREATE_REQUEST = 'INVOICE_LINE_CREATE_REQUEST'
export const INVOICE_LINE_CREATE_SUCCESS = 'INVOICE_LINE_CREATE_SUCCESS'
export const INVOICE_LINE_CREATE_FAIL = 'INVOICE_LINE_CREATE_FAIL'
export const INVOICE_LINE_CREATE_RESET = 'INVOICE_LINE_CREATE_RESET'

export const INVOICE_LINE_UPDATE_REQUEST = 'INVOICE_LINE_UPDATE_REQUEST'
export const INVOICE_LINE_UPDATE_SUCCESS = 'INVOICE_LINE_UPDATE_SUCCESS'
export const INVOICE_LINE_UPDATE_FAIL = 'INVOICE_LINE_UPDATE_FAIL'
export const INVOICE_LINE_UPDATE_RESET = 'INVOICE_LINE_UPDATE_RESET'

export const INVOICE_LINE_REQUEST = 'INVOICE_LINE_REQUEST'
export const INVOICE_LINE_SUCCESS = 'INVOICE_LINE_SUCCESS'
export const INVOICE_LINE_FAIL = 'INVOICE_LINE_FAIL'
export const INVOICE_LINE_RESET = 'INVOICE_LINE_RESET'

export const INVOICE_LINE_COUNT_REQUEST = "INVOICE_LINE_COUNT_REQUEST";
export const INVOICE_LINE_COUNT_SUCCESS = "INVOICE_LINE_COUNT_SUCCESS";
export const INVOICE_LINE_COUNT_FAIL = "INVOICE_LINE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const INVOICE_LINE_DTO_REQUEST = 'INVOICE_LINE_DTO_REQUEST';
export const INVOICE_LINE_DTO_SUCCESS = 'INVOICE_LINE_DTO_SUCCESS';
export const INVOICE_LINE_DTO_FAIL = 'INVOICE_LINE_DTO_FAIL';
export const INVOICE_LINE_DTO_RESET = 'INVOICE_LINE_DTO_RESET';

export const INVOICE_LINE_LIST_BYIDS_REQUEST = 'INVOICE_LINE_LIST_BYIDS_REQUEST';
export const INVOICE_LINE_LIST_BYIDS_SUCCESS = 'INVOICE_LINE_LIST_BYIDS_SUCCESS';
export const INVOICE_LINE_LIST_BYIDS_FAIL = 'INVOICE_LINE_LIST_BYIDS_FAIL';
export const INVOICE_LINE_LIST_BYIDS_RESET = 'INVOICE_LINE_LIST_BYIDS_RESET';