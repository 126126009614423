import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplants, createImplant, updateImplant, deleteImplant, getImplantDetails } from '../../actions/implantActions';
// constants 
import { IMPLANT_CREATE_RESET, IMPLANT_UPDATE_RESET } from '../../constants/implantConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';
import { convertToISODate, convertToComponentDate } from '../../utils/functions';

// debouncer 
import { debounce } from 'lodash';

export default function ImplantCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the implantCreate and implantUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_CREATE_RESET });
        dispatch({ type: IMPLANT_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/dotnet_api/v1/implant/all?page=1&limit=2&filter=Email&query=gmail
    const [implantListFilter, setimplantListFilter] = useState({ query: '', page: 1, filter: 'barcode' });

    // get implant list from redux store
    const implantList = useSelector((state) => state.implantList);
    const { loading, error, implants, currentPage, totalPages } = implantList;

    // get implant create from redux store
    const implantCreate = useSelector((state) => state.implantCreate);
    const { success: successCreate, error: implantCreateErrors } = implantCreate;

    // get implant update from redux store
    const implantUpdate = useSelector((state) => state.implantUpdate);
    const { success: successUpdate, loading: implantUpdateProcessing, error: errorUpdate } = implantUpdate;

    // get implant delete from redux store
    const implantDelete = useSelector((state) => state.implantDelete);
    const { success: successDelete } = implantDelete;

    // get implant details from redux store
    const implantDetails = useSelector((state) => state.implantDetails);
    const { implant: implantDet, loading: implantDetailsLoading } = implantDetails;

    // newImplant state
    const [newImplant, setNewImplant] = useState({});
    const [updatedImplant, setUpdatedImplant] = useState({});
    const [editMode, setEditMode] = useState(false);

    // implantStatus change date
    const [implantStatusNewDate, setImplantStatusNewDate] = useState(convertToISODate(Date.now()));

    // listen to filter changes
    useEffect(() => {
        dispatch(listImplants(implantListFilter));
    }, [dispatch, implantListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantListFilter({ ...implantListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantListFilter({ ...implantListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantListFilter({ ...implantListFilter, filter: selectedFilter });
    };

    // get all implants
    useEffect(() => {
        dispatch(listImplants({}));
    }, [dispatch]);

    // listen to implantDet 
    useEffect(() => {
        if (implantDet) {
            // use spread operator to copy the implantDet object
            setUpdatedImplant({ ...implantDet });
            // set the implantStatusNewDate to the ImplantStatusChangedDate
            setImplantStatusNewDate(convertToComponentDate(implantDet?.implantStatusChangedDate));
        }
    }, [implantDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplant state
            setNewImplant({
                modelName: '',
                nhsNumber: '',
                barcode: '',
                manufacturer: '',
                modelNumber: '',
                type: '',
                status: '',
                implantStatusChanged: false,
                implantStatusChangedDate: convertToISODate(Date.now()),
                mrConditional: false,
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implant?')) {
            dispatch(deleteImplant(id)).then(() => {
                dispatch(listImplants({}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplant = (id) => {
        dispatch(getImplantDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateImplant = () => {
        dispatch(createImplant(newImplant)).then(() => {
            if (successCreate) {
                setUpdatedImplant(newImplant);
                setEditMode(true);
                dispatch(listImplants({}));
            }
        }
        );
    };

    // handle update
    const handleUpdateImplant = () => {
        dispatch(updateImplant(updatedImplant.id, updatedImplant)).then(() => {
            setUpdatedImplant(updatedImplant);
            setEditMode(true);
            dispatch(getImplantDetails(updatedImplant.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Implant Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the implants. You can create, update and delete implants in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {errorUpdate && (
                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex={-1}
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                            {errorUpdate?.map((err) => (
                                                <li key={err}>
                                                    {err}
                                                </li>
                                            ))}

                                        </ul>
                                    </ErrorSummary.Body>
                                </ErrorSummary>
                            )
                            }
                            {implantCreateErrors && (
                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex={-1}
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                            {implantCreateErrors?.map((err) => (
                                                <li key={err}>
                                                    {err}
                                                </li>
                                            ))}

                                        </ul>
                                    </ErrorSummary.Body>
                                </ErrorSummary>
                            )
                            }
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-implants"
                                        label="Search implants"
                                        name="search-implants"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />

                                    <Select
                                        id="filter-implants"
                                        label="Filter by"
                                        name="filter-implants"
                                        onChange={handleFilterChange}
                                    >
                                        <Select.Option value="Barcode">Barcode/GTIN</Select.Option>
                                        <Select.Option value="ModelName">Model Name</Select.Option>
                                        <Select.Option value="ModelNumber">Model Number</Select.Option>
                                        <Select.Option value="Manufacturer">Manufacturer</Select.Option>
                                        <Select.Option value="NhsNumber">NHS Number</Select.Option>
                                        <Select.Option value="Type">Type</Select.Option>
                                    </Select>


                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {implants && implants.length > 0 ? (
                                            implants?.map((implant) => (
                                                <Details key={implant._id}>
                                                    <Details.Summary>
                                                        {implant.modelName}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Manufacturer:</strong> {implant.manufacturer}
                                                        </p>
                                                        <p>
                                                            <strong>Barcode/GTIN:</strong> {implant.barcode}
                                                        </p>
                                                        <p>
                                                            <strong>Model Number:</strong> {implant.modelNumber}
                                                        </p>
                                                        <p>
                                                            <strong>MRI Conditional:</strong>{" "}
                                                            <Tag color={implant.mrConditional ? "red" : "green"}>
                                                                {implant.mrConditional ? "Yes" : "No"}
                                                            </Tag>
                                                        </p>
                                                        <p>
                                                            <strong>Status:</strong> <Tag color={implant.status === 'Active' ? 'green' : 'red'}>{implant.status}</Tag>
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={implantDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditImplant(implant.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(implant.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Implant
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an implant. If you want to create a new implant, please click the "Create Implant" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Implant
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="modelName"
                                            label="Model Name"
                                            name="modelName"
                                            type="text"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, modelName: e.target.value })}
                                            value={updatedImplant.modelName ? updatedImplant.modelName : ''}
                                            required
                                        />
                                        <Input
                                            id="nhsNumber"
                                            label="NHS Number"
                                            name="nhsNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, nhsNumber: e.target.value })}
                                            value={updatedImplant.nhsNumber ? updatedImplant.nhsNumber : ''}
                                            required
                                        />
                                        <Input
                                            id="barcode"
                                            label="Barcode/GTIN"
                                            name="barcode"
                                            type="text"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, barcode: e.target.value })}
                                            value={updatedImplant.barcode ? updatedImplant.barcode : ''}
                                            required
                                        />
                                        <Input
                                            id="manufacturer"
                                            label="Manufacturer"
                                            name="manufacturer"
                                            type="text"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, manufacturer: e.target.value })}
                                            value={updatedImplant.manufacturer ? updatedImplant.manufacturer : ''}
                                            required
                                        />
                                        <Input
                                            id="modelNumber"
                                            label="Model Number"
                                            name="modelNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, modelNumber: e.target.value })}
                                            value={updatedImplant.modelNumber ? updatedImplant.modelNumber : ''}
                                            required
                                        />
                                        <Select
                                            id="modelCategory"
                                            label="Model Category"
                                            name="modelCategory"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, modelCategory: e.target.value })}
                                            value={updatedImplant.modelCategory ? updatedImplant.modelCategory : ''}
                                            required
                                        >

                                            <Select.Option value="">Select a model category</Select.Option>

                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Generator">Generator</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                        </Select>

                                        <Select
                                            id="modelType"
                                            label="Model Type"
                                            name="modelType"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, type: e.target.value })}
                                            value={updatedImplant.type ? updatedImplant.type : ''}
                                            required
                                        >
                                            <Select.Option value="">Select a model type</Select.Option>

                                            <Select.Option value="LV lead">LV lead</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                            <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                            <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                            <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                            <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                            <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                            <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                            <Select.Option value="His Lead">His Lead</Select.Option>
                                        </Select>
                                        <Select
                                            id="status"
                                            label="Status"
                                            name="status"
                                            onChange={(e) => setUpdatedImplant({ ...updatedImplant, status: e.target.value })}
                                            value={updatedImplant.status ? updatedImplant.status : ''}
                                            required
                                        >
                                            <Select.Option value="">Select the status</Select.Option>
                                            <Select.Option value="Active">Active</Select.Option>
                                            <Select.Option value="Inactive">Inactive</Select.Option>
                                            <Select.Option value="Unknown">Unknown</Select.Option>
                                        </Select>

                                        <DateInput
                                            id="ImplantStatusChangedDate"
                                            label="Implant Status Changed Date"
                                            name="ImplantStatusChangedDate"

                                            value={implantStatusNewDate}

                                            onChange={(e) => {
                                                setImplantStatusNewDate(e.target.value);
                                                setUpdatedImplant({ ...updatedImplant, implantStatusChangedDate: convertToISODate(e.target.value) })
                                            }}

                                        />
                                        <Checkboxes
                                            name="mrConditional"
                                            hint='Select if the implant is MRI Conditional'
                                        >
                                            <Checkboxes.Box
                                                conditional={<p>The implant is MRI Conditional</p>}
                                                onChange={(e) => setUpdatedImplant({ ...updatedImplant, mrConditional: e.target.checked })}
                                                checked={updatedImplant.mrConditional? updatedImplant.mrConditional : false}
                                                id="mrConditional"
                                                value="mrConditional"
                                                label="MRI Conditional"
                                            >
                                                MRI Conditional?
                                            </Checkboxes.Box>
                                        </Checkboxes>


                                        {(successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The implant was created successfully." : "The implant was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={implantUpdateProcessing}
                                        onClick={handleUpdateImplant}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>

                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Implant
                                    </Card.Heading>
                                    <Card.Description>

                                        <Input
                                            id="modelNameCreate"
                                            label="Model Name"
                                            name="modelName"
                                            type="text"
                                            onChange={(e) => setNewImplant({ ...newImplant, modelName: e.target.value })}
                                            value={newImplant.modelName ? newImplant.modelName : ''}
                                            required
                                        />
                                        <Input
                                            id="nhsNumberCreate"
                                            label="NHS Number"
                                            name="nhsNumber"
                                            type="text"
                                            onChange={(e) => setNewImplant({ ...newImplant, nhsNumber: e.target.value })}
                                            value={newImplant.nhsNumber ? newImplant.nhsNumber : ''}
                                            required
                                        />
                                        <Input
                                            id="barcodeCreate"
                                            label="Barcode/GTIN"
                                            name="barcode"
                                            type="text"
                                            onChange={(e) => setNewImplant({ ...newImplant, barcode: e.target.value })}
                                            value={newImplant.barcode ? newImplant.barcode : ''}
                                            required
                                        />

                                        <Input
                                            id='manufacturerCreate'
                                            label="Manufacturer"
                                            name="manufacturer"
                                            type="text"
                                            onChange={(e) => setNewImplant({ ...newImplant, manufacturer: e.target.value })}

                                            value={newImplant.manufacturer ? newImplant.manufacturer : ''}
                                            required
                                        />
                                        <Input
                                            id="modelNumberCreate"
                                            label="Model Number"
                                            name="modelNumber"
                                            type="text"
                                            onChange={(e) => setNewImplant({ ...newImplant, modelNumber: e.target.value })}
                                            value={newImplant.modelNumber ? newImplant.modelNumber : ''}
                                            required
                                        />

                                        <Select
                                            id="modelCategoryCreate"
                                            label="Model Category"
                                            name="modelCategory"
                                            onChange={(e) => setNewImplant({ ...newImplant, modelCategory: e.target.value })}
                                            value={newImplant.modelCategory ? newImplant.modelCategory : ''}
                                            required
                                        >
                                            <Select.Option value="">Select a model category</Select.Option>

                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Generator">Generator</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                        </Select>

                                        <Select
                                            id="modelTypeCreate"
                                            label="Model Type"
                                            name="modelType"
                                            onChange={(e) => setNewImplant({ ...newImplant, type: e.target.value })}
                                            value={newImplant.type ? newImplant.type : ''}
                                            required
                                        >
                                            <Select.Option value="">Select a model type</Select.Option>

                                            <Select.Option value="LV lead">LV lead</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                            <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                            <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                            <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                            <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                            <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                            <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                            <Select.Option value="His Lead">His Lead</Select.Option>
                                        </Select>
                                        <Select
                                            id="statusCreate"
                                            label="Status"
                                            name="statusCreate"
                                            onChange={(e) => setNewImplant({ ...newImplant, status: e.target.value })}
                                            value={newImplant.status ? newImplant.status : ''}
                                            required
                                        >
                                            <Select.Option value="">Select the status</Select.Option>
                                            <Select.Option value="Active">Active</Select.Option>
                                            <Select.Option value="Inactive">Inactive</Select.Option>
                                            <Select.Option value="Unknown">Unknown</Select.Option>
                                        </Select>

                                        <DateInput
                                            id="ImplantStatusChangedDateCreate"
                                            label="Implant Status Changed Date"
                                            name="ImplantStatusChangedDateCreate"

                                            value={convertToComponentDate(newImplant.implantStatusChangedDate)}

                                            onChange={(e) => {
                                                setImplantStatusNewDate(e.target.value);
                                                setNewImplant({ ...newImplant, implantStatusChangedDate: convertToISODate(e.target.value) })
                                            }}

                                        />
                                        <Checkboxes
                                            name="mrConditionalCreate"
                                            hint='Select if the implant is MRI Conditional'
                                        >
                                            <Checkboxes.Box
                                                conditional={<p>MRI Conditional</p>}
                                                onChange={(e) => setNewImplant({ ...newImplant, mrConditional: e.target.checked })}
                                                checked={newImplant.mrConditional? newImplant.mrConditional : false}
                                                id="mrConditionalCreate"
                                                value="mrConditional"
                                                label="MRI Conditional"
                                            >
                                                The implant is MRI Conditional
                                            </Checkboxes.Box>

                                        </Checkboxes>

                                    </Card.Description>

                                    <Button
                                        disabled={implantUpdateProcessing}
                                        onClick={handleCreateImplant}
                                    >
                                        Create Implant
                                    </Button>



                                </Card.Content>
                                {successCreate &&
                                    <Callout
                                        label="Success"
                                        header="Success!"
                                        autoDismiss={true}
                                        dismissTime={5000}
                                        time={new Date().toLocaleTimeString()}
                                        title="Success!"
                                        message="The implant was created successfully."
                                        mode="success"
                                    />
                                }

                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






