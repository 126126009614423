export const TEST_LIST_REQUEST = 'TEST_LIST_REQUEST'
export const TEST_LIST_SUCCESS = 'TEST_LIST_SUCCESS'
export const TEST_LIST_FAIL = 'TEST_LIST_FAIL'


export const TEST_DETAILS_REQUEST = 'TEST_DETAILS_REQUEST'
export const TEST_DETAILS_SUCCESS = 'TEST_DETAILS_SUCCESS'
export const TEST_DETAILS_FAIL = 'TEST_DETAILS_FAIL'


export const TEST_DELETE_REQUEST = 'TEST_DELETE_REQUEST'
export const TEST_DELETE_SUCCESS = 'TEST_DELETE_SUCCESS'
export const TEST_DELETE_FAIL = 'TEST_DELETE_FAIL'

export const TEST_CREATE_REQUEST = 'TEST_CREATE_REQUEST'
export const TEST_CREATE_SUCCESS = 'TEST_CREATE_SUCCESS'
export const TEST_CREATE_FAIL = 'TEST_CREATE_FAIL'
export const TEST_CREATE_RESET = 'TEST_CREATE_RESET'

export const TEST_UPDATE_REQUEST = 'TEST_UPDATE_REQUEST'
export const TEST_UPDATE_SUCCESS = 'TEST_UPDATE_SUCCESS'
export const TEST_UPDATE_FAIL = 'TEST_UPDATE_FAIL'
export const TEST_UPDATE_RESET = 'TEST_UPDATE_RESET'

export const TEST_REQUEST = 'TEST_REQUEST'
export const TEST_SUCCESS = 'TEST_SUCCESS'
export const TEST_FAIL = 'TEST_FAIL'
export const TEST_RESET = 'TEST_RESET'

export const TEST_COUNT_REQUEST = "TEST_COUNT_REQUEST";
export const TEST_COUNT_SUCCESS = "TEST_COUNT_SUCCESS";
export const TEST_COUNT_FAIL = "TEST_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const TEST_DTO_REQUEST = 'TEST_DTO_REQUEST';
export const TEST_DTO_SUCCESS = 'TEST_DTO_SUCCESS';
export const TEST_DTO_FAIL = 'TEST_DTO_FAIL';
export const TEST_DTO_RESET = 'TEST_DTO_RESET';

export const TEST_LIST_BYIDS_REQUEST = 'TEST_LIST_BYIDS_REQUEST';
export const TEST_LIST_BYIDS_SUCCESS = 'TEST_LIST_BYIDS_SUCCESS';
export const TEST_LIST_BYIDS_FAIL = 'TEST_LIST_BYIDS_FAIL';
export const TEST_LIST_BYIDS_RESET = 'TEST_LIST_BYIDS_RESET';