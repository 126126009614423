import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplantInstances, createImplantInstance, updateImplantInstance, deleteImplantInstance, getImplantInstanceDetails, listImplantInstancesByPatientNhsNumber } from '../../actions/implantInstanceActions';
import { getImplantDetailsByBarcode } from '../../actions/implantActions';

// constants 
import { IMPLANT_INSTANCE_CREATE_RESET, IMPLANT_INSTANCE_UPDATE_RESET } from '../../constants/implantInstanceConstants';
import Pagination from '../pagination/Pagination.component';
import AppSpinner from '../spinner/AppSpinner.component';

import Callout from '../callout/Callout.component';
import SelectOther from '../selectOther/SelectOther.component';
import Modal from '../modal/Modal.component';


const ImplantInstanceListReadOnly = ({ patientNHSNumber }) => {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();


    // reset the state of the implantInstanceCreate and implantInstanceUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_INSTANCE_CREATE_RESET });
        dispatch({ type: IMPLANT_INSTANCE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/dotnet_api/v1/implantInstance/all?page=1&limit=2&filter=Email&query=gmail
    const [implantInstanceListFilter, setimplantInstanceListFilter] = useState({ query: '', page: 1, filter: 'patientNHSNumber' });

    // get implantInstance list from redux store
    const implantInstanceListByNhsNumber = useSelector((state) => state.implantInstanceListByNhsNumber);
    const { implantInstances, loading, error, currentPage, totalPages } = implantInstanceListByNhsNumber;

    // get implantInstance create from redux store
    const implantInstanceCreate = useSelector((state) => state.implantInstanceCreate);
    const { success: successCreate, error: implantInstanceCreateErrors } = implantInstanceCreate;

    // get implantInstance update from redux store
    const implantInstanceUpdate = useSelector((state) => state.implantInstanceUpdate);
    const { success: successUpdate, loading: implantInstanceUpdateProcessing } = implantInstanceUpdate;

    // get implantInstance delete from redux store
    const implantInstanceDelete = useSelector((state) => state.implantInstanceDelete);
    const { success: successDelete } = implantInstanceDelete;

    // get implantInstance details from redux store
    const implantInstanceDetails = useSelector((state) => state.implantInstanceDetails);
    const { implantInstance: implantInstanceDet, loading: implantInstanceDetailsLoading } = implantInstanceDetails;

    // get implant details from redux store
    const implantDetailsByBarcode = useSelector((state) => state.implantDetailsByBarcode);
    const { implant, loading: loadingImplantDetailsByBarcode, error: errorImplantDetailsByBarcode, success: successImplantDetailsByBarcode } = implantDetailsByBarcode;

    // newImplantInstance state
    const [newImplantInstance, setNewImplantInstance] = useState({});
    const [updatedImplantInstance, setUpdatedImplantInstance] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isLead, setIsLead] = useState(true);
    const [isGenerator, setIsGenerator] = useState(true);
    const [autoFillDetail, setAutoFillDetail] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isMRIConditional, setIsMRIConditional] = useState(false);
    const [isAbandonedHardware, setIsAbandonedHardware] = useState(false);
    const [deviceListCondition, setDeviceListCondition] = useState({ isAbandonedHardware: false, isMRIConditional: false });


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, implantInstanceListFilter));
    }, [dispatch, implantInstanceListFilter, patientNHSNumber]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantInstanceListFilter({ ...implantInstanceListFilter, filter: selectedFilter });
    };

    // listen to implantInstanceDet 
    useEffect(() => {
        if (implantInstanceDet) {
            // use spread operator to copy the implantInstanceDet object
            setUpdatedImplantInstance({ ...implantInstanceDet });
        }
    }, [implantInstanceDet]);

    // listen to Implant details
    useEffect(() => {
        if (implant) {
            setNewImplantInstance({
                ...newImplantInstance,
                modelName: implant.modelName,
                modelNumber: implant.modelNumber,
                barcode: implant.barcode,
                manufacturer: implant.manufacturer,
                modelCategory: implant.modelCategory,
                type: implant.type,
                eventDate: new Date().toISOString(),
            });
        } else {
            setNewImplantInstance({
                ...newImplantInstance,
                modelName: '',
                modelNumber: '',
                barcode: '',
                manufacturer: '',
                modelCategory: '',
                type: '',
                eventDate: new Date().toISOString(),
            });
        }
    }, [implant]);

    // listen to implantInstances
    useEffect(() => {
        if (implantInstances) {
            // if at least one implant is MRI conditional, then the patient is MRI conditional
            const isMRIConditional = implantInstances.some(implantInstance => implantInstance.mrConditional && implantInstance.status.includes("Implanted"));
            const isAbandonedHardware = implantInstances.some(implantInstance => implantInstance.abandonedHardware);
            const deviceList = implantInstances?.map(implantInstance => implantInstance.barcode);
            setIsMRIConditional(isMRIConditional);
            setIsAbandonedHardware(isAbandonedHardware);
            setDeviceListCondition({ isAbandonedHardware: isAbandonedHardware, isMRIConditional: isMRIConditional, deviceList: deviceList });
        }
    }, [implantInstances]);



    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantInstance?')) {
            dispatch(deleteImplantInstance(id)).then(() => {
                dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantInstance = (id) => {
        dispatch(getImplantInstanceDetails(id));
        setEditMode(true);
        setShowModal(true);

    };

    // handle create
    const handleCreateImplantInstance = () => {
        dispatch(createImplantInstance(newImplantInstance));
    };

    // handle update
    const handleUpdateImplantInstance = () => {
        dispatch(updateImplantInstance(updatedImplantInstance.id, updatedImplantInstance)).then(() => {
            setUpdatedImplantInstance(updatedImplantInstance);
            setEditMode(true);
            dispatch(getImplantInstanceDetails(updatedImplantInstance.id));
            dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
        }
        );
    };
    // handle get implant by barcode
    const handleGetImplantByBarcode = (barcode) => {
        dispatch(getImplantDetailsByBarcode(barcode));
    };


    return (
        <React.Fragment>

            {isMRIConditional && (
                <Callout
                    label="MRI Conditional"
                    header="MRI Conditional"
                    time={new Date().toLocaleTimeString()}
                    title="MRI Conditional"
                    message="At least one of the implanted components is MRI conditional."
                    mode="warning"
                />
            )}
            {isAbandonedHardware && (
                <Callout
                    label="Abandoned Hardware"
                    header="Abandoned Hardware"
                    time={new Date().toLocaleTimeString()}
                    title="Abandoned Hardware"
                    message="At least one of the implanted components was abandoned."
                    mode="warning"
                />
            )}

            {loading ? (
                <AppSpinner />
            ) : error ? (
                <p>{error}</p>
            ) : (
                implantInstances && implantInstances.length > 0 ? (
                    implantInstances?.map((implantInstance) => (
                        <Card clickable
                            key={implantInstance.barcode}
                            style={{ borderLeft: implantInstance.status === 'Implanted, connected' ? '10px solid green' : implantInstance.status === 'Explanted' ? '10px solid orange' : '10px solid #005eb8' }}
                        >
                            <Card.Content>
                                <Details>
                                    <Details.Summary>
                                        <Tag>{implantInstance.modelCategory}</Tag><br />
                                        {implantInstance.modelName} - ({implantInstance.serialNumber})
                                    </Details.Summary>
                                    <Details.Text>
                                        {implantInstance.modelCategory === 'Generator' && (
                                            <InsetText>
                                                <p><strong>Implant Site: </strong>{implantInstance.generatorImplantSite}</p>
                                                <p><strong>Max System Capability: </strong>{implantInstance.maxSystemCapability}</p>
                                            </InsetText>
                                        )}
                                        {implantInstance.modelCategory === 'Lead' && (
                                            <InsetText>

                                                <p><strong>Lead Chamber:</strong> {implantInstance.leadChamber}</p>
                                                <p><strong>Pacing Site:</strong> {implantInstance.pacingSite}</p>
                                            </InsetText>
                                        )}
                                        <p>Manufacturer: {implantInstance.manufacturer}</p>
                                        <p>Model Name: {implantInstance.modelName}</p>
                                        <p>Model Number: {implantInstance.modelNumber}</p>
                                        <p>Serial Number: {implantInstance.serialNumber}</p>
                                        <p>Date Implanted: {implantInstance.eventDate}</p>
                                        <p>
                                            Status: <Tag>{implantInstance.status}</Tag>
                                        </p>
                                        <p>
                                            Abandoned Hardware: <Tag color={implantInstance.abandonedHardware ? 'red' : 'green'}>{implantInstance.abandonedHardware ? 'Yes' : 'No'}</Tag>
                                        </p>
                                        <p>
                                            MR Conditional: <Tag color={implantInstance.mrConditional ? 'red' : 'green'}>{implantInstance.mrConditional ? 'Yes' : 'No'}</Tag>
                                        </p>
                                    </Details.Text>

                                </Details>

                            </Card.Content>
                        </Card>
                    ))
                ) : (
                    <p>No Implants Found</p>
                )

            )}


        </React.Fragment>
    );
}

export default ImplantInstanceListReadOnly;