import {

    CONSENT_LIST_REQUEST,
    CONSENT_LIST_SUCCESS,
    CONSENT_LIST_FAIL,

    CONSENT_DETAILS_REQUEST,
    CONSENT_DETAILS_SUCCESS,
    CONSENT_DETAILS_FAIL,

    CONSENT_DELETE_REQUEST,
    CONSENT_DELETE_SUCCESS,
    CONSENT_DELETE_FAIL,

    CONSENT_CREATE_REQUEST,
    CONSENT_CREATE_SUCCESS,
    CONSENT_CREATE_FAIL,
    CONSENT_CREATE_RESET,

    CONSENT_UPDATE_REQUEST,
    CONSENT_UPDATE_SUCCESS,
    CONSENT_UPDATE_FAIL,
    CONSENT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    CONSENT_DTO_FAIL,
    CONSENT_DTO_REQUEST,
    CONSENT_DTO_SUCCESS,
    CONSENT_DTO_RESET,

    CONSENT_LIST_BYIDS_REQUEST,
    CONSENT_LIST_BYIDS_SUCCESS,
    CONSENT_LIST_BYIDS_FAIL,
    CONSENT_LIST_BYIDS_RESET

} from "../constants/consentConstants";

const initialState = {
    consents: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const consentListReducer = (
    state = {
        consents: []
    }, action) => {
    switch (action.type) {
        case CONSENT_LIST_REQUEST:
            return { loading: true, consents: [] };
        case CONSENT_LIST_SUCCESS:
            // our state will have a list of consents after the request
            return {
                loading: false,
                consents: action.payload.consents,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case CONSENT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const consentListByIDsReducer = (
    state = {
        consents: []
    }, action) => {
    switch (action.type) {
        case CONSENT_LIST_BYIDS_REQUEST:
            return { loading: true, consents: [] };
        case CONSENT_LIST_BYIDS_SUCCESS:
            // our state will have a list of consents after the request
            return { loading: false, consents: action.payload };
        case CONSENT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case CONSENT_LIST_BYIDS_RESET:
            return { consents: [] };
        default:
            return state;
    }
}


export const consentDetailsReducer = (
    state = {
        consent: { comments: [] }
    }, action) => {
    switch (action.type) {
        case CONSENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case CONSENT_DETAILS_SUCCESS:
            // our state will have a list of consents after the request
            return { loading: false, consent: action.payload };
        case CONSENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const consentDtoReducer = (state = { consentDto: {} }, action) => {
    switch (action.type) {
        case CONSENT_DTO_REQUEST:
            return { loading: true, consentDto: {} };
        case CONSENT_DTO_SUCCESS:
            return { loading: false, consentDto: action.payload };
        case CONSENT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case CONSENT_DTO_RESET:
            return { consentDto: {} };
        default:
            return state;
    }
}

export const consentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSENT_DELETE_REQUEST:
            return { loading: true }

        case CONSENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CONSENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const consentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSENT_CREATE_REQUEST:
            return { loading: true }

        case CONSENT_CREATE_SUCCESS:
            return { loading: false, success: true, consent: action.payload }

        case CONSENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case CONSENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const consentUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case CONSENT_UPDATE_REQUEST:
            return { loading: true }

        case CONSENT_UPDATE_SUCCESS:
            return { loading: false, success: true, consent: action.payload }

        case CONSENT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case CONSENT_UPDATE_RESET:
            return { consent: {} }

        default:
            return state
    }
}