export const ATTACHMENT_LIST_REQUEST = 'ATTACHMENT_LIST_REQUEST'
export const ATTACHMENT_LIST_SUCCESS = 'ATTACHMENT_LIST_SUCCESS'
export const ATTACHMENT_LIST_FAIL = 'ATTACHMENT_LIST_FAIL'


export const ATTACHMENT_DETAILS_REQUEST = 'ATTACHMENT_DETAILS_REQUEST'
export const ATTACHMENT_DETAILS_SUCCESS = 'ATTACHMENT_DETAILS_SUCCESS'
export const ATTACHMENT_DETAILS_FAIL = 'ATTACHMENT_DETAILS_FAIL'


export const ATTACHMENT_DELETE_REQUEST = 'ATTACHMENT_DELETE_REQUEST'
export const ATTACHMENT_DELETE_SUCCESS = 'ATTACHMENT_DELETE_SUCCESS'
export const ATTACHMENT_DELETE_FAIL = 'ATTACHMENT_DELETE_FAIL'

export const ATTACHMENT_CREATE_REQUEST = 'ATTACHMENT_CREATE_REQUEST'
export const ATTACHMENT_CREATE_SUCCESS = 'ATTACHMENT_CREATE_SUCCESS'
export const ATTACHMENT_CREATE_FAIL = 'ATTACHMENT_CREATE_FAIL'
export const ATTACHMENT_CREATE_RESET = 'ATTACHMENT_CREATE_RESET'

export const ATTACHMENT_UPDATE_REQUEST = 'ATTACHMENT_UPDATE_REQUEST'
export const ATTACHMENT_UPDATE_SUCCESS = 'ATTACHMENT_UPDATE_SUCCESS'
export const ATTACHMENT_UPDATE_FAIL = 'ATTACHMENT_UPDATE_FAIL'
export const ATTACHMENT_UPDATE_RESET = 'ATTACHMENT_UPDATE_RESET'

export const ATTACHMENT_REQUEST = 'ATTACHMENT_REQUEST'
export const ATTACHMENT_SUCCESS = 'ATTACHMENT_SUCCESS'
export const ATTACHMENT_FAIL = 'ATTACHMENT_FAIL'
export const ATTACHMENT_RESET = 'ATTACHMENT_RESET'

export const ATTACHMENT_COUNT_REQUEST = "ATTACHMENT_COUNT_REQUEST";
export const ATTACHMENT_COUNT_SUCCESS = "ATTACHMENT_COUNT_SUCCESS";
export const ATTACHMENT_COUNT_FAIL = "ATTACHMENT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const ATTACHMENT_DTO_REQUEST = 'ATTACHMENT_DTO_REQUEST';
export const ATTACHMENT_DTO_SUCCESS = 'ATTACHMENT_DTO_SUCCESS';
export const ATTACHMENT_DTO_FAIL = 'ATTACHMENT_DTO_FAIL';
export const ATTACHMENT_DTO_RESET = 'ATTACHMENT_DTO_RESET';

export const ATTACHMENT_LIST_BYIDS_REQUEST = 'ATTACHMENT_LIST_BYIDS_REQUEST';
export const ATTACHMENT_LIST_BYIDS_SUCCESS = 'ATTACHMENT_LIST_BYIDS_SUCCESS';
export const ATTACHMENT_LIST_BYIDS_FAIL = 'ATTACHMENT_LIST_BYIDS_FAIL';
export const ATTACHMENT_LIST_BYIDS_RESET = 'ATTACHMENT_LIST_BYIDS_RESET';