import {

    INVOICE_REPORT_LIST_REQUEST,
    INVOICE_REPORT_LIST_SUCCESS,
    INVOICE_REPORT_LIST_FAIL,

    INVOICE_REPORT_DETAILS_REQUEST,
    INVOICE_REPORT_DETAILS_SUCCESS,
    INVOICE_REPORT_DETAILS_FAIL,

    INVOICE_REPORT_DELETE_REQUEST,
    INVOICE_REPORT_DELETE_SUCCESS,
    INVOICE_REPORT_DELETE_FAIL,

    INVOICE_REPORT_CREATE_REQUEST,
    INVOICE_REPORT_CREATE_SUCCESS,
    INVOICE_REPORT_CREATE_FAIL,
    INVOICE_REPORT_CREATE_RESET,

    INVOICE_REPORT_UPDATE_REQUEST,
    INVOICE_REPORT_UPDATE_SUCCESS,
    INVOICE_REPORT_UPDATE_FAIL,
    INVOICE_REPORT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    INVOICE_REPORT_DTO_FAIL,
    INVOICE_REPORT_DTO_REQUEST,
    INVOICE_REPORT_DTO_SUCCESS,
    INVOICE_REPORT_DTO_RESET,

    INVOICE_REPORT_LIST_BYIDS_REQUEST,
    INVOICE_REPORT_LIST_BYIDS_SUCCESS,
    INVOICE_REPORT_LIST_BYIDS_FAIL,
    INVOICE_REPORT_LIST_BYIDS_RESET

} from "../constants/invoiceReportConstants";

const initialState = {
    invoiceReports: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const invoiceReportListReducer = (
    state = {
        invoiceReports: []
    }, action) => {
    switch (action.type) {
        case INVOICE_REPORT_LIST_REQUEST:
            return { loading: true, invoiceReports: [] };
        case INVOICE_REPORT_LIST_SUCCESS:
            // our state will have a list of invoiceReports after the request
            return {
                loading: false,
                invoiceReports: action.payload.invoiceReports,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                statistics: action.payload.statistics
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case INVOICE_REPORT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const invoiceReportListByIDsReducer = (
    state = {
        invoiceReports: []
    }, action) => {
    switch (action.type) {
        case INVOICE_REPORT_LIST_BYIDS_REQUEST:
            return { loading: true, invoiceReports: [] };
        case INVOICE_REPORT_LIST_BYIDS_SUCCESS:
            // our state will have a list of invoiceReports after the request
            return { loading: false, invoiceReports: action.payload };
        case INVOICE_REPORT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case INVOICE_REPORT_LIST_BYIDS_RESET:
            return { invoiceReports: [] };
        default:
            return state;
    }
}


export const invoiceReportDetailsReducer = (
    state = {
        invoiceReport: { comments: [] }
    }, action) => {
    switch (action.type) {
        case INVOICE_REPORT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case INVOICE_REPORT_DETAILS_SUCCESS:
            // our state will have a list of invoiceReports after the request
            return { loading: false, invoiceReport: action.payload };
        case INVOICE_REPORT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const invoiceReportDtoReducer = (state = { invoiceReportDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case INVOICE_REPORT_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case INVOICE_REPORT_DTO_SUCCESS:
        return { ...state, loading: false, invoiceReportDto: action.payload };
      case INVOICE_REPORT_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case INVOICE_REPORT_DTO_RESET:
        return { invoiceReportDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const invoiceReportDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_REPORT_DELETE_REQUEST:
            return { loading: true }

        case INVOICE_REPORT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case INVOICE_REPORT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const invoiceReportCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_REPORT_CREATE_REQUEST:
            return { loading: true }

        case INVOICE_REPORT_CREATE_SUCCESS:
            return { loading: false, success: true, invoiceReport: action.payload }

        case INVOICE_REPORT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case INVOICE_REPORT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const invoiceReportUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case INVOICE_REPORT_UPDATE_REQUEST:
            return { loading: true }

        case INVOICE_REPORT_UPDATE_SUCCESS:
            return { loading: false, success: true, invoiceReport: action.payload }

        case INVOICE_REPORT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case INVOICE_REPORT_UPDATE_RESET:
            return { invoiceReport: {} }

        default:
            return state
    }
}