import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers, deleteUser } from "../../actions/userActions";
import ListItemsDataTable from "../../components/crud/SelectItemsDataTable.component";
import { Space, Button, notification } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

export default function UserManagementScreen({ history }) {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { loading, error, users, currentPage, totalPages } = userList;
  const [limit, setLimit] = useState(10);
  const [api, contextHolder] = notification.useNotification();
  const [userListFilter, setUserListFilter] = useState({ query: "", page: 1, limit: limit, filter: "UserName" });
  const [sendingEmailConfirmationLink, setSendingEmailConfirmationLink] = useState(false);
  const [sendingEmailConfirmationLinkEmail, setSendingEmailConfirmationLinkEmail] = useState(null);
  const [userEmailConfirmationSentSucess, setUserEmailConfirmationSentSuccess] = useState(false);
  const [userEmailConfirmationSentError, setUserEmailConfirmationSentError] = useState(false);

  useEffect(() => {
    dispatch(listUsers(userListFilter));
  }, [dispatch, userListFilter]);

  const openNotification = (placement, description, message) => {
    api.info({
      message: message,
      description: description,
      placement,
      icon: (message === "Success") ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ff0000" />,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteUser(id));
    setUserListFilter({ query: "", page: 1, limit: limit, filter: "UserName" });
  };

  const handleResendEmailConfirmationLink = (email) => {
    setSendingEmailConfirmationLink(true);
    setSendingEmailConfirmationLinkEmail(email);
    axios
      .post("/dotnet_api/v1/auth/resendemailconfirmationlink", { Email: email })
      .then((res) => {
        if (res.status === 200) {
          setUserEmailConfirmationSentSuccess(true);
          setUserEmailConfirmationSentError(false);
          setSendingEmailConfirmationLink(false);
          openNotification("bottom", "Email confirmation link sent successfully", "Success");
        }
      })
      .catch((err) => {
        setUserEmailConfirmationSentSuccess(false);
        setUserEmailConfirmationSentError(true);
        setSendingEmailConfirmationLink(false);
        openNotification("bottom", "Error sending email confirmation link", "Error");
      });
  };

  const userDtoConfig = {
    UserName: { isSummaryProp: true },
    Email: { isSummaryProp: true },
    EmailConfirmed: { isSummaryProp: true },
    Roles: { isSummaryProp: true },
  };

  return (
    <React.Fragment>
      {contextHolder}
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>User Management</h2>
        <Button
          type="primary"
          onClick={() => history.push("/users/create")}
          icon={<PlusOutlined />}
          size="large"
        >
          Create User
        </Button>

        <ListItemsDataTable
          loading={loading}
          dtoConfig={userDtoConfig}
          data={users}
          filter={userListFilter}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          onFilterChange={setUserListFilter}
          handleDeleteRow={handleDelete}
          handleSaveRow={(updatedRow) => {}}
          onStartEditRow={() => {}}
          onDataUpdated={() => {}}
          handleEditForm={(id) => history.push(`/users/${id}`)}
          enableActionButtons={true}
        />
      </Space>
    </React.Fragment>
  );
}