import React, { useEffect } from "react";
import { Layout, Typography, Result, Button } from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const UnauthorizedScreen = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('page.unauthorized.title', 'Unauthorized Access');
  }, [t]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "50px 0" }}>
        <Result
          status="403"
          title="403"
          subTitle={t('page.unauthorized.subtitle', 'Sorry, you are not authorized to access this page.')}
          icon={<ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />}
          extra={
            <div style={{ textAlign: "center" }}>
              <Title level={4}>
                {t('page.unauthorized.message', "You don't have permission to view this page")}
              </Title>
              <Paragraph>
                {t('page.unauthorized.contact', 'Please contact your administrator if you believe this is an error.')}
              </Paragraph>
              <Link to="/">
                <Button type="primary" size="large">
                  {t('page.unauthorized.returnButton', 'Return to Dashboard')}
                </Button>
              </Link>
            </div>
          }
        />
      </Content>
    </Layout>
  );
};

export default UnauthorizedScreen;