import React, { useState, useEffect } from 'react';
import { Input, Button, Space, Popconfirm, Tooltip } from 'antd';
import { ReloadOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const EntityCodeInput = ({ prefix = 'ORD', inputValue, onValueChange }) => {
  const [entityCode, setEntityCode] = useState(inputValue || '');
  const [isGenerated, setIsGenerated] = useState(!inputValue);
  const [showValidateButton, setShowValidateButton] = useState(true);
  const { t } = useTranslation();

  const generateUniqueCode = () => {
    const timestamp = Date.now().toString().slice(-8);
    const randomCode = Math.random().toString(36).substr(2, 4).toUpperCase();
    const newCode = `${prefix}${timestamp}${randomCode}`;
    setEntityCode(newCode);
    setIsGenerated(true);
    setShowValidateButton(true);
    onValueChange({ target: { value: newCode } });
  };

  const validateCode = () => {
    onValueChange({ target: { value: entityCode } });
    setShowValidateButton(false);
  };

  useEffect(() => {
    if (inputValue) {
      setEntityCode(inputValue);
      setIsGenerated(false);
      setShowValidateButton(false);
    } else if (!entityCode) {
      generateUniqueCode();
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    setEntityCode(e.target.value);
    setIsGenerated(false);
    setShowValidateButton(true);
    onValueChange(e);
  };

  return (
    <Space>
      <Input
        value={entityCode}
        placeholder={t('entityCode.placeholder', { prefix })}
        onChange={handleInputChange}
      />
      {showValidateButton && (
        <Tooltip title={t('entityCode.validateTooltip')}>
          <Button
            type="text"
            icon={<CheckOutlined />}
            onClick={validateCode}
            size="small"
          >
            {t('entityCode.validate')}
          </Button>
        </Tooltip>
      )}
      <Popconfirm
        title={t('entityCode.confirmRegenerate')}
        onConfirm={generateUniqueCode}
        okText={t('common.yes')}
        cancelText={t('common.no')}
      >
        <Button
          type="text"
          icon={<ReloadOutlined />}
          title={isGenerated ? t('entityCode.regenerate') : t('entityCode.generateNew')}
        />
      </Popconfirm>
    </Space>
  );
};

export default EntityCodeInput;