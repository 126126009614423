import React, { useEffect } from 'react';
import { Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import DynamicFormSP from "../../../components/crud/DynamicFormSP.component";
import { createTestInventoryLine, deleteTestInventoryLine, getTestInventoryLineDetails, listTestInventoryLines, listTestInventoryLinesByIds, loadTestInventoryLineDto, updateTestInventoryLine } from '../../../actions/testInventoryLineActions';

const { Title, Paragraph } = Typography;

const SelectTestsStep = ({ currentState, setCurrentState, handleSaveTestInventoryLines }) => {
  const { t } = useTranslation();
  const { updatedTestOrderBulkRequest, editMode, isBusy, selectedInventoryLines } = currentState;

  useEffect(() => {
    if (updatedTestOrderBulkRequest) {
      setCurrentState(prevState => ({
        ...prevState,
        key: `test-inventory-lines-${Date.now()}`
      }));
    }
  }, [updatedTestOrderBulkRequest, setCurrentState]);

  return (
    <div>
      <Title level={4}>
        {editMode === 'Edit' 
          ? t('selectTestsStep.updateTitle') 
          : t('selectTestsStep.chooseTitle')}
      </Title>
      <Paragraph>
        {editMode === 'Edit'
          ? t('selectTestsStep.updateDescription')
          : t('selectTestsStep.chooseDescription')}
      </Paragraph>
      <Paragraph>
        {t('selectTestsStep.benefitsDescription')}
      </Paragraph>

      <DynamicFormSP
        defaultEditMode="Lookup"
        listingMode='selectedIds'
        selectedItems={[]}
        selectedItemIds={updatedTestOrderBulkRequest?.testInventoryLineIds || []}
        primarySearchProp="name"
        secondarySearchProp="description"
        singularEntity="testInventoryLine"
        pluralEntity="testInventoryLines"
        groupSearchProp="testCategoryName"
        constantPrefix="TEST_INVENTORY_LINE"
        loadDto={loadTestInventoryLineDto}
        createEntity={createTestInventoryLine}
        updateEntity={updateTestInventoryLine}
        deleteEntity={deleteTestInventoryLine}
        getEntityDetails={getTestInventoryLineDetails}
        listEntities={listTestInventoryLines}
        listEntitiesByIds={listTestInventoryLinesByIds}
        onSelectedEntitiesChange={(selectedItems) => {
          if (updatedTestOrderBulkRequest) {
            setCurrentState(prevState => ({
              ...prevState,
              updatedTestOrderBulkRequest: {
                ...prevState.updatedTestOrderBulkRequest,
                testInventoryLineIds: selectedItems?.map(item => item.id)
              }
            }));
          }
        }}
        isParent={false}
        fieldsToHide={['Id', 'Test', 'TestResultReportId', 'InvoiceReportId', 'SampleIds', 'TestOrderIds', 'TestInventoryLineIds']}
        summaryProps={['name', 'description', 'price']}
        enableCreate={true}
        enableSearchBar={true}
        enableActionButtons={true}
      />
      <Button
        type="primary"
        onClick={handleSaveTestInventoryLines}
        style={{ marginTop: 16 }}
        loading={currentState.isBusy}
      >
        {editMode === 'Edit' 
          ? t('selectTestsStep.updateButton') 
          : t('selectTestsStep.saveButton')}
      </Button>
    </div>
  );
};

export default SelectTestsStep;