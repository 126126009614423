import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Descriptions, Tag, Space, Divider, Typography, Row, Col } from 'antd';
import { CalendarOutlined, UserOutlined, NumberOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { loadInvoiceLineDto, listInvoiceLines, listInvoiceLinesByIds } from '../../../actions/invoiceLineActions';
import { loadInvoiceReportDto, createInvoiceReport, updateInvoiceReport, deleteInvoiceReport, getInvoiceReportDetails, listInvoiceReports, listInvoiceReportsByIds } from '../../../actions/invoiceReportActions';
import DynamicFormSP from "../../../components/crud/DynamicFormSP.component";

const { Title, Text } = Typography;

const ReviewInvoiceStep = ({ currentState, setCurrentState }) => {
  const { t } = useTranslation();
  const { invoiceReportUpdatedEntity, invoiceLineIds, editMode, invoiceReportId } = currentState;
  const userInfo = useSelector(state => state.userLogin.userInfo);

  const renderInvoiceSummary = () => (
    <Card
      title={<Title level={4}>{t('reviewInvoiceStep.invoiceSummary')}</Title>}
      className="invoice-summary-card"
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Text strong><NumberOutlined /> {t('reviewInvoiceStep.invoiceNumber')}</Text>
          <br />
          <Tag color="blue">{invoiceReportUpdatedEntity.invoiceNumber}</Tag>
        </Col>
        <Col xs={24} sm={12}>
          <Text strong><CalendarOutlined /> {t('reviewInvoiceStep.date')}</Text>
          <br />
          {new Date(invoiceReportUpdatedEntity.invoiceDate).toLocaleDateString()}
        </Col>
        <Col xs={24} sm={12}>
          <Text strong><UserOutlined /> {t('reviewInvoiceStep.customer')}</Text>
          <br />
          {invoiceReportUpdatedEntity.customerName}
        </Col>
        <Col xs={24} sm={12}>
          <Text strong><CheckCircleOutlined /> {t('reviewInvoiceStep.status')}</Text>
          <br />
          <Tag color={invoiceReportUpdatedEntity.invoiceStatus.toLowerCase() === 'paid' ? 'green' : 'orange'}>
            {t(`reviewInvoiceStep.statusValues.${invoiceReportUpdatedEntity.invoiceStatus.toLowerCase()}`)}
          </Tag>
        </Col>
      </Row>
    </Card>
  );

  const renderPaymentSummary = () => (
    <Card title={t('reviewInvoiceStep.paymentSummary')}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Text strong>{t('reviewInvoiceStep.totalTax')}</Text>
          <br />
          {new Intl.NumberFormat('en-GB', { style: 'currency', currency: userInfo?.currencySymbol }).format(invoiceReportUpdatedEntity.totalTax)}
        </Col>
        <Col xs={24} sm={12}>
          <Text strong>{t('reviewInvoiceStep.subtotal')}</Text>
          <br />
          {new Intl.NumberFormat('en-GB', { style: 'currency', currency: userInfo?.currencySymbol }).format(invoiceReportUpdatedEntity.subtotal)}
        </Col>
        <Col xs={24} sm={12}>
          <Text strong>{t('reviewInvoiceStep.balanceDue')}</Text>
          <br />
          <Text type={invoiceReportUpdatedEntity.balanceDue > 0 ? "danger" : "success"} strong>
            {new Intl.NumberFormat('en-GB', { style: 'currency', currency: userInfo?.currencySymbol }).format(invoiceReportUpdatedEntity.balanceDue)}
          </Text>
        </Col>
        <Col xs={24} sm={12}>
          <Text strong>{t('reviewInvoiceStep.total')}</Text>
          <br />
          <Text strong>
            {new Intl.NumberFormat('en-GB', { style: 'currency', currency: userInfo?.currencySymbol }).format(invoiceReportUpdatedEntity.total)}
          </Text>
        </Col>
      </Row>
    </Card>
  );

  return (
    <div>
      <Title level={4}>
        {editMode === 'Edit' 
          ? t('reviewInvoiceStep.updateAndReviewTitle') 
          : t('reviewInvoiceStep.reviewTitle')}
      </Title>
      {invoiceReportUpdatedEntity && invoiceReportUpdatedEntity.invoiceLineIds && (
        <div>
          <Space direction="vertical" style={{ width: '100%' }}>
            {(invoiceReportUpdatedEntity && editMode === 'Edit') && renderInvoiceSummary()}

            <Divider orientation="left">{t('reviewInvoiceStep.invoiceLines')}</Divider>
            {invoiceLineIds && (
              <DynamicFormSP
                key={invoiceLineIds?.map(id => id).join('-')}
                defaultEditMode="Lookup"
                listingMode='selectedIds'
                selectedItems={[]}
                selectedItemIds={invoiceLineIds}
                primarySearchProp="description"
                secondarySearchProp="quantity"
                singularEntity="invoiceLine"
                pluralEntity="invoiceLines"
                constantPrefix="INVOICE_LINE"
                loadDto={loadInvoiceLineDto}
                listEntities={listInvoiceLines}
                listEntitiesByIds={listInvoiceLinesByIds}
                isParent={false}
                onSelectedEntitiesChange={(selectedItems) => {
                  setCurrentState(prevState => ({
                    ...prevState,
                    invoiceLines: selectedItems
                  }));
                }}
                fieldsToHide={['Id', 'InvoiceReportId']}
                summaryProps={['description', 'quantity', 'unitPrice', 'total']}
                enableCreate={false}
                enableSearchBar={false}
                enableActionButtons={false}
              />
            )}
            {userInfo && renderPaymentSummary()}
          </Space>
        </div>
      )}
      
      <Divider orientation="left">
        {editMode === 'Edit' 
          ? t('reviewInvoiceStep.editInvoice') 
          : t('reviewInvoiceStep.createInvoice')}
      </Divider>

      {invoiceReportId && (
        <DynamicFormSP
          key={invoiceReportId}
          currentEntityId={invoiceReportId}
          defaultEditMode={editMode}
          primarySearchProp="invoiceNumber"
          secondarySearchProp="customerName"
          singularEntity="invoiceReport"
          pluralEntity="invoiceReports"
          constantPrefix="INVOICE_REPORT"
          loadDto={loadInvoiceReportDto}
          createEntity={createInvoiceReport}
          updateEntity={updateInvoiceReport}
          deleteEntity={deleteInvoiceReport}
          getEntityDetails={getInvoiceReportDetails}
          listEntities={listInvoiceReports}
          listEntitiesByIds={listInvoiceReportsByIds}
          onUpdatedEntityChange={(updatedEntity) => {
            setCurrentState(prevState => ({
              ...prevState,
              invoiceReportUpdatedEntity: updatedEntity,
              invoiceLineIds: updatedEntity.invoiceLineIds
            }));
          }}
          onLoading={(loading) => {
            setCurrentState(prevState => ({
              ...prevState,
              invoiceLoading: loading
            }));
          }}
          isParent={true}
          fieldsToHide={['Id', 'TestOrderBulkRequestId', 'InvoiceLineIds']}
          summaryProps={['invoiceNumber', 'invoiceDate', 'customerName', 'total']}
          enableCreate={editMode !== 'Edit'}
          enableSearchBar={false}
          enableActionButtons={false}
        />
      )}
    </div>
  );
};

export default ReviewInvoiceStep;