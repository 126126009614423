// utils/exportUtils.js
import { fieldEntityActionConfig } from '../components/crud/utils/fieldEntityActionConfig';

export const enrichUpdatedEntity = async (updatedEntity) => {
  const enrichedEntity = { ...updatedEntity };

  for (const [key, value] of Object.entries(updatedEntity)) {
    const config = fieldEntityActionConfig[key];
    if (config && config.listEntitiesByIds) {
      if (Array.isArray(value) && value.length > 0) {
        enrichedEntity[key] = await config.listEntitiesByIds(value);
      } else if (value && !Array.isArray(value)) {
        const result = await config.listEntitiesByIds([value]);
        enrichedEntity[key] = result[0];
      }
    }
  }

  return enrichedEntity;
};

const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else if (Array.isArray(obj[k])) {
      acc[pre + k] = obj[k]?.map(item => typeof item === 'object' ? JSON.stringify(item) : item).join('; ');
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
};

export const exportToCSV = (data) => {
  const flattenedData = flattenObject(data);
  const csvContent = Object.entries(flattenedData)
    ?.map(([key, value]) => `"${key}","${value}"`)
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};