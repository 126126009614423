import axios from 'axios';

export const fetchDtos = async (token) => {
  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  try {
    const [testDtoResponse, testCategoryDtoResponse, inventoryLineDtoResponse] = await Promise.all([
      axiosInstance.get('/dotnet_api/v1/test/testDto'),
      axiosInstance.get('/dotnet_api/v1/testCategory/testCategoryDto'),
      axiosInstance.get('/dotnet_api/v1/testInventoryLine/testInventoryLineDto')
    ]);

    return {
      testDto: testDtoResponse.data,
      testCategoryDto: testCategoryDtoResponse.data,
      inventoryLineDto: inventoryLineDtoResponse.data
    };
  } catch (error) {
    console.error('Error fetching DTOs:', error);
    throw error;
  }
};

export const createRecord = async (csvRows, csvHeaders, deviceMappings, testDto, testCategoryDto, inventoryLineDto, generateInventoryLine, inventoryLineOptions, token, callbacks) => {
  const { setCurrentRecord, setCurrentProgress, setErrors, stopImport } = callbacks;
  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  for (const [rowIndex, csvRow] of csvRows.entries()) {
    if (!csvRow) continue;

    if (stopImport) throw new Error('Import stopped');

    const rowDict = csvHeaders.reduce((acc, header, index) => {
      acc[convertKeyToCamelCase(header.trim())] = csvRow[index]?.trim();
      return acc;
    }, {});

    const newRecord = deviceMappings.reduce((acc, mapping) => {
      const csvHeader = convertKeyToCamelCase(mapping.csvHeader.trim());
      const dtoProperty = mapping.dtoProperty;
      acc[dtoProperty] = csvHeader ? rowDict[csvHeader] : mapping.defaultValue.trim();
      return acc;
    }, {});


    try {
      // Check if the category exists
      let categoryId;
      const categoryCheckRequest = createVoidTestCategoryRequest(testCategoryDto);
      categoryCheckRequest.name = newRecord.TestCategoryName;

      try {
        const categoryResponse = await axiosInstance.post('/dotnet_api/v1/testCategory/exists', categoryCheckRequest);
        categoryId = categoryResponse.data.id;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Category doesn't exist, create a new one
          const newCategoryRequest = {
            ...categoryCheckRequest,
            description: `Category for ${newRecord.TestCategoryName}`,
            isActive: true,
            order: Math.floor(Math.random() * 1000) // Random order between 0 and 999
          };
          const newCategoryResponse = await axiosInstance.post('/dotnet_api/v1/testCategory', newCategoryRequest);
          categoryId = newCategoryResponse.data.id;
        } else {
          throw error; // Re-throw if it's not a 404 error
        }
      }

      // Create or update the test
      const testData = createTestRequest(testDto, newRecord, categoryId);
      const testResponse = await axiosInstance.post('/dotnet_api/v1/test/createOrUpdate', testData);
      const createdTestId = testResponse.data.id;

      if (generateInventoryLine) {
        const inventoryLineData = createInventoryLineRequest(inventoryLineDto, createdTestId, inventoryLineOptions);
        await axiosInstance.post('/dotnet_api/v1/testInventoryLine', inventoryLineData);
      }

      setCurrentRecord(rowIndex + 1);
      setCurrentProgress(Math.round(((rowIndex + 1) / csvRows.length) * 100));

      await new Promise(resolve => setTimeout(resolve, 100)); // Sleep for 100ms between requests
    } catch (error) {
      setErrors(prevErrors => [...prevErrors, `Row ${rowIndex + 1}: ${error.message}`]);
    }
  }
};



const createVoidTestCategoryRequest = (dto) => {
  const request = {};
  Object.keys(dto).forEach(key => {
    const dtoField = dto[key];
    switch (dtoField.type) {
      case 'Int32':
        request[key] = 0;
        break;
      case 'Decimal':
        request[key] = 0;
        break;
      case 'Boolean':
        request[key] = false;
        break;
      case 'Guid':
        request[key] = '00000000-0000-0000-0000-000000000000';
        break;
      case 'List`1':
        request[key] = [];
        break;
      default:
        request[key] = '-';
    }
  });

  return request;
};

const createTestRequest = (dto, newRecord, categoryId) => {
  const request = {};
  Object.keys(dto).forEach(key => {
    const dtoField = dto[key];
    if (newRecord.hasOwnProperty(key)) {
      switch (dtoField.type) {
        case 'Int32':
          request[key] = parseInt(newRecord[key]) || 0;
          break;
        case 'Decimal':
          request[key] = parseFloat(newRecord[key]) || 0;
          break;
        case 'Boolean':
          request[key] = newRecord[key] === 'true' || newRecord[key] === true;
          break;
        case 'Guid':
          request[key] = newRecord[key] || '00000000-0000-0000-0000-000000000000';
          break;
        case 'List`1':
          request[key] = Array.isArray(newRecord[key]) ? newRecord[key] : [];
          break;
        default:
          request[key] = newRecord[key] || '';
      }
    } else {
      // Set default values based on DTO type
      request[key] = dtoField.initialValue;
    }
  });

  // Set the category ID
  request.TestCategoryId = categoryId;

  return request;
};

const createInventoryLineRequest = (dto, testId, options) => {
  const request = {};
  Object.keys(dto).forEach(key => {
    const dtoField = dto[key];
    switch (dtoField.type) {
      case 'Int32':
        request[key] = key === 'InitialQuantity' ? 1000 : (key === 'MinimumQuantity' ? 100 : 0);
        break;
      case 'Decimal':
        request[key] = 0;
        break;
      case 'Boolean':
        request[key] = false;
        break;
      case 'Guid':
        if (key === 'PricingTierId') {
          request[key] = options.pricingTierId;
        } else if (key === 'SampleId') {
          request[key] = options.sampleId;
        } else if (key === 'TestId') {
          request[key] = testId;
        } else {
          request[key] = '00000000-0000-0000-0000-000000000000';
        }
        break;
      case 'List`1':
        request[key] = [];
        break;
      case 'DateTime':
        if (key === 'ManufactureDate') {
          request[key] = new Date().toISOString();
        } else if (key === 'ExpiryDate') {
          request[key] = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();
        } else {
          request[key] = new Date().toISOString();
        }
        break;
      default:
        if (key === 'ReferenceCode') {
          request[key] = generateRandomCode('REF');
        } else if (key === 'LotCode') {
          request[key] = generateRandomCode('LOT');
        } else if (key === 'Barcode') {
          request[key] = generateRandomBarcode(options.barcodeStart, options.barcodeLength);
        } else {
          request[key] = '';
        }
    }
  });

  return request;
};

const generateRandomBarcode = (start, length) => {
  const randomDigits = Math.floor(Math.random() * Math.pow(10, length - start.length)).toString().padStart(length - start.length, '0');
  return `${start}${randomDigits}`;
};

const generateRandomCode = (prefix) => {
  const timestamp = Date.now().toString();
  const randomLetters = Math.random().toString(36).substring(2, 6).toUpperCase();
  return `${prefix}${timestamp}${randomLetters}`;
};

const convertKeyToCamelCase = (key) => {
  return key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};