import React, { useState, useEffect } from 'react';
import { Button, Typography, message, Card, Row, Col, Upload, Spin } from 'antd';
import { UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ImportModal from './ImportModal';
import { fetchDtos } from './api';

const { Title, Paragraph, Text } = Typography;
const { Dragger } = Upload;

export default function TestDataImporter({ history }) {
  const { t } = useTranslation();
  const [csvRows, setCsvRows] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [testDto, setTestDto] = useState(null);
  const [testCategoryDto, setTestCategoryDto] = useState(null);
  const [inventoryLineDto, setInventoryLineDto] = useState(null);
  const [fileList, setFileList] = useState([]);
  
  const userInfo = useSelector((state) => state.userLogin.userInfo);

  useEffect(() => {
    loadDtos();
  }, []);

  const loadDtos = async () => {
    try {
      const dtos = await fetchDtos(userInfo.token);
      setTestDto(dtos.testDto);
      setTestCategoryDto(dtos.testCategoryDto);
      setInventoryLineDto(dtos.inventoryLineDto);
    } catch (error) {
      message.error(t('importer.failedToFetchDTOs'));
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    fileList: fileList,
    beforeUpload: (file) => {
      if (!testDto || !testCategoryDto || !inventoryLineDto) {
        message.warning(t('importer.dtosStillLoading'));
        return false;
      }
  
      if (fileList.length >= 1) {
        message.error(t('importer.onlyOneFileAllowed'));
        return false;
      }
  
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const rows = csvData.split('\n')?.map(row => row.split(','));
        const headers = rows[0];
  
        if (file.size / (1024 * 1024) > 10) {
          message.error(t('importer.fileSizeLimitExceeded'));
          return;
        }
        setCsvHeaders(headers);
        setCsvRows(rows.slice(1));
        setFileList([file]);
        setModalVisible(true);
      };
      reader.onerror = () => {
        message.error(t('importer.failedToReadCsvFile'));
      };
      reader.readAsText(file);
      return false;
    },
    onRemove: () => {
      setFileList([]);
      setCsvHeaders([]);
      setCsvRows([]);
      return true;
    },
  };
  return (
    <div className="page-content">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
            {t('importer.back')}
          </Button>
          <Title level={2}>{t('importer.dataImport')}</Title>
          <Paragraph>{t('importer.dataImportDescription')}</Paragraph>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title={t('importer.importData')}>
            {testDto && testCategoryDto && inventoryLineDto ? (
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon"><UploadOutlined /></p>
                <p className="ant-upload-text">{t('importer.clickOrDragCsvFile')}</p>
                <p className="ant-upload-hint">{t('importer.uploadSingleCsvFile')} {t('importer.fileSizeLimit')}</p>
              </Dragger>
            ) : (
              <Spin tip={t('importer.loadingDTOs')}>
                <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Text>{t('importer.pleaseWait')}</Text>
                </div>
              </Spin>
            )}
          </Card>
        </Col>
      </Row>

      <ImportModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        csvHeaders={csvHeaders}
        csvRows={csvRows}
        testDto={testDto}
        testCategoryDto={testCategoryDto}
        inventoryLineDto={inventoryLineDto}
        userToken={userInfo.token}
      />
    </div>
  );
}