import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Statistic } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  createPricingTier,
  updatePricingTier,
  deletePricingTier,
  getPricingTierDetails,
  listPricingTiers,
  listPricingTiersByIds,
  loadPricingTierDto,
} from '../../actions/pricingTierActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function PricingTierManagementScreen({ history }) {
  useEffect(() => {
    document.title = "Pricing Tier Management";
  }, []);

  const dispatch = useDispatch();

  const [selectedPricingTierId, setSelectedPricingTierId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [pricingTierListFilter, setPricingTierListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'name',
  });

  const pricingTierList = useSelector((state) => state.pricingTierList);
  const { loading, error, pricingTiers, currentPage, totalPages } = pricingTierList;

  const pricingTierDto = useSelector((state) => state.pricingTierDto);
  const { pricingTierDto: pricingTierDtoConfig } = pricingTierDto;

  const {userInfo} = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(loadPricingTierDto());
    dispatch(listPricingTiers(pricingTierListFilter));
  }, [dispatch, pricingTierListFilter]);

  const handleDelete = (id) => {
    dispatch(deletePricingTier(id));
    setPricingTierListFilter({ query: '', page: 1, limit: 10, filter: 'name' });
  };

  const handleEditForm = (id) => {
    setSelectedPricingTierId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  const handleCancelForm = () => {
    setSelectedPricingTierId(null);
    setShowDynamicForm(false);
  };

  const handleCreatePricingTier = () => {
    setSelectedPricingTierId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h2>Pricing Tier Management</h2>
      </Space>
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Total Pricing Tiers" value={pricingTiers?.length || 0} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Active Tiers" value={pricingTiers?.filter(tier => tier.isActive).length || 0} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic title="Average Rate" value={pricingTiers?.reduce((acc, tier) => acc + tier.defaultRate, 0) / pricingTiers?.length || 0} prefix={userInfo?.currencySymbol} precision={2} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic title="Highest Rate" value={Math.max(...(pricingTiers?.map(tier => tier.defaultRate) || [0]))} prefix={userInfo?.currencySymbol} precision={2} />
          </Card>
        </Col>
      </Row>

      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreatePricingTier}
            icon={<PlusOutlined />}
            size="large"
          >
            Create Pricing Tier
          </Button>
          <ListItemsDataTable
            loading={loading}
            dtoConfig={pricingTierDtoConfig}
            data={pricingTiers}
            filter={pricingTierListFilter}
            onFilterChange={setPricingTierListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={handleDelete}
            onStartEditRow={setSelectedPricingTierId}
            handleSaveRow={(updatedRow) => {
              dispatch(updatePricingTier(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getPricingTierDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}

      {showDynamicForm && (
        <React.Fragment>
          <Space direction="vertical" style={{ width: '100%', padding: '10px' }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Pricing Tiers
            </Button>
          </Space>

          <DynamicFormSP
            currentEntityId={selectedPricingTierId}
            selectedItems={[]}
            selectedEntityIds={[]}
            listingMode="allEntities"
            defaultEditMode={editMode}
            primarySearchProp="name"
            secondarySearchProp="description"
            singularEntity="pricingTier"
            pluralEntity="pricingTiers"
            constantPrefix="PRICING_TIER"
            loadDto={loadPricingTierDto}
            createEntity={createPricingTier}
            updateEntity={updatePricingTier}
            deleteEntity={deletePricingTier}
            getEntityDetails={getPricingTierDetails}
            listEntities={listPricingTiers}
            listEntitiesByIds={listPricingTiersByIds}
            onSelectedItems={() => {}}
            onSelectedEntitiesChange={() => {}}
            onUpdatedEntityChange={() => {}}
            onNewEntityChange={() => {}}
            isParent={true}
            fieldsToHide={['Id']}
            summaryProps={['name', 'description', 'defaultRate', 'isActive']}
            enableCreate={true}
            enableSearchBar={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}