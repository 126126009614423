import JsBarcode from 'jsbarcode';

export function evaluateFlexibleUnitConversion(conversionString, x, x1 = null, x2 = null) {
    // Determine the precision of the input value(s)
    const getPrecision = (value) => {
      if (value === null || value === undefined) return 0;
      if (Number.isInteger(parseFloat(value))) return 2; // Default to 2 for integers
      const decimalPart = value.toString().split('.')[1];
      return decimalPart ? decimalPart.length : 0;
    };
  
    const precision = Math.max(getPrecision(x), getPrecision(x1), getPrecision(x2));
  
    // Split the string into expression and unit
    const [expression, unit] = conversionString.split('=')?.map(part => part.trim());
    
    // Function to evaluate the expression for a given value
    const evaluateForValue = (value) => {
      if (value === null || value === undefined) return null;
      const preparedExpression = expression.replace(/x/g, value);
      try {
        const result = Function('"use strict";return (' + preparedExpression + ')')();
        const factor = Math.pow(10, precision);
        return Math.round(result * factor) / factor;
      } catch (error) {
        console.error("Error evaluating expression for value =", value, ":", error);
        return null;
      }
    };
  
    // Evaluate for x, x1, and x2
    const convertedX = evaluateForValue(x);
    const convertedX1 = evaluateForValue(x1);
    const convertedX2 = evaluateForValue(x2);
  
    // Format the results
    const formatValue = (value) => value === null ? null : parseFloat(value.toFixed(precision));
  
    // Construct the return object
    let result = {
      convertedValue: formatValue(convertedX),
      convertedUnit: unit,
      convertedReferenceRange: null
    };
  
    if (x1 !== null || x2 !== null) {
      let lowerBound = convertedX1 !== null ? convertedX1 : convertedX;
      let upperBound = convertedX2 !== null ? convertedX2 : convertedX;
      
      // Ensure lower bound is actually lower
      if (lowerBound > upperBound) {
        [lowerBound, upperBound] = [upperBound, lowerBound];
      }
      
      result.convertedReferenceRange = `[${formatValue(lowerBound)} - ${formatValue(upperBound)}]`;
    }
  
    return result;
  }
  
  // convert to date from {day:month, month:month, year:year}
// to 2023-12-23T11:25:50Z like format
export function convertToISODate(date) {
    try {
        const dateObj = new Date(date.year, date.month - 1, date.day);
        if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date");
        }
        return dateObj.toISOString();
    } catch (error) {
        // empty date field
        return null;
    }
}

// function that generates inital object like newPatient from the Dto 
export function generateInitialObject(dto) {
    const initialObject = {};
    for (const [key, value] of Object.entries(dto)) {
        const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        switch (value) {
            case "String":
                initialObject[lowerCaseKey] = "";
                break;
            case "DateTime":
                initialObject[lowerCaseKey] = new Date().toISOString();
                break;
            case "Int":
                initialObject[lowerCaseKey] = 0;
                break;
            case "Double":
                initialObject[lowerCaseKey] = 0.0;
                break;
            case "Bool":
                initialObject[lowerCaseKey] = false;
                break;
            case "List`1":
                initialObject[lowerCaseKey] = [];
                break;
            default:
                initialObject[lowerCaseKey] = null;
                break;
        }
    }
    return initialObject;
}


export function convertToComponentDate(date) {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const componentDate = {
        day: day,
        month: month,
        year: year,
    };
    return componentDate;
}

export const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    browseFile: {
        width: '20%',
    },
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    },
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    },
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    },
};

// convert JSON to nice HTML 
// the keys are the headers <h3> 
// the values are the values <p>
export function convertJSONToHTML(json) {
    let html = '';
    for (const [key, value] of Object.entries(json)) {
        const beautifiedKey = beautifyKey(key);
        html += `<h3>${beautifiedKey}</h3><p>${value}</p>`;
    }
    return html;
}

export function beautifyKey(key) {
    const words = key.split(/(?=[A-Z])/);
    const beautifiedWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return beautifiedWords.join(' ');
}


export function extractBase64FromDataUri(dataUri) {
    const base64Data = dataUri.split(',')[1]; // Split at the comma and get the second part
    return base64Data;
}

// convert keys to camelCase
export function convertKeysToCamelCase(obj) {
    const newObj = {};
    for (const key in obj) {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObj[newKey] = obj[key];
    }
    return newObj;
}


// extract gtin expiry date and serial number from the barcode
export function parseBarcode(rawBarcode) {
    // barcode format: 010541473451018917231231216506715
    // gtin: 05414734510189
    try {
        if (!rawBarcode) {
            return {};
        }
        // gtin is from the 3rd character to the 17th character
        const gtinMatch = rawBarcode.match(/(?<=^.{2})(.{14})/);
        // expiry date is from the 19th character to the 24th character
        const expiryMatch = rawBarcode.match(/(?<=^.{18})(.{6})/);
        // serial number is from the 22nd character to the end
        const serialMatch = rawBarcode.match(/(?<=21)(\d+)/);


        let gtin, expiry, serial;

        if (gtinMatch) {
            gtin = gtinMatch[0];
        }

        if (expiryMatch) {
            const expiryDate = expiryMatch[0];
            const year = `20${expiryDate.slice(0, 2)}`;
            const month = expiryDate.slice(2, 4);
            const day = expiryDate.slice(4, 6);
            const dateObj = new Date(year, month - 1, day);
            if (isNaN(dateObj.getTime())) {
                // replace the expiry date with the current date
                const currentDate = new Date();
                expiry = currentDate.toISOString();
            }
            expiry = dateObj.toISOString();
        }

        if (serialMatch) {
            serial = serialMatch[0];
        }

        // if gtin is null then use the raw barcode
        gtin = gtin ? gtin : rawBarcode;

        return { gtin, expiry, serial };
    } catch (error) {
        return { gtin: rawBarcode, expiry: null, serial: null };
    }
}

// lowecase the first letter of a string
export function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function convertKeyToCamelCase(key) {
    return key
        .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2')  // Convert uppercase sequences to snake_case
        .replace(/([a-z\d])([A-Z])/g, '$1_$2')    // Convert lower-uppercase sequences to snake_case
        .toLowerCase()                            // Convert all to lowercase
        .replace(/_./g, match => match.charAt(1).toUpperCase()); // Convert snake_case to camelCase
}


/**
 * Converts a barcode value to a base64 encoded image string.
 * 
 * @param {string} value - The barcode value to encode.
 * @param {Object} options - JsBarcode options (optional).
 * @param {string} options.format - The barcode format (e.g., 'CODE128', 'EAN13').
 * @param {number} options.width - The width of a single bar.
 * @param {number} options.height - The height of the barcode.
 * @param {number} options.fontSize - The font size of the text.
 * @param {string} options.background - The background color (default: '#ffffff').
 * @param {string} options.lineColor - The color of the bars (default: '#000000').
 * @returns {Promise<string>} A promise that resolves with the base64 encoded image string.
 */
export function barcodeToBase64(value, options = {}) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    
    try {
      JsBarcode(canvas, value, {
        format: 'CODE128',
        width: 2,
        height: 100,
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
        ...options
      });
      
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    } catch (error) {
      reject(error);
    }
  });
}