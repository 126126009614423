import React, { useEffect, useState, useCallback } from 'react';
import { Card, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DynamicFormSP from '../DynamicFormSP.component';


const DynamicFormChildSP = ({
  selectedEntityConfig,
  selectedItemIds,
  editMode,
  handleUpdatedEntityInputChange,
  handleNewEntityInputChange,
  onSelectedEntitiesChange,
  onUpdatedEntityChange,
  onNewEntityChange,
  onEditModeChange,
  handleBackButtonClick,
  parentSingleEntity,
  parentPluralEntity,
  fieldsToHide,
  listingMode,
  enableCreate,
  enableSearchBar,
  enableActionButtons,
  enableExportButtons,
}) => {
  const [currentSelectedEntities, setCurrentSelectedEntities] = useState(selectedItemIds || []);

  useEffect(() => {
    if (selectedItemIds && selectedItemIds.length > 0) {
      setCurrentSelectedEntities(selectedItemIds);
    } else {
      setCurrentSelectedEntities([]);
    }
  }, [selectedItemIds]);

  const handleSelectedEntitiesChange = useCallback((selectedEntities) => {
    const selectedIds = selectedEntities?.map(entity => entity.id);
    setCurrentSelectedEntities(selectedIds);
    onSelectedEntitiesChange(selectedEntities);
    
    if (editMode === 'Edit') {
      if (selectedEntityConfig.oneToOneView) {
        handleUpdatedEntityInputChange(selectedEntityConfig.fieldName, selectedIds[0]);
      } else {
        handleUpdatedEntityInputChange(selectedEntityConfig.fieldName, selectedIds);
      }
    } else if (editMode === 'Create') {
      if (selectedEntityConfig.oneToOneView) {
        handleNewEntityInputChange(selectedEntityConfig.fieldName, selectedIds[0]);
      } else {
        handleNewEntityInputChange(selectedEntityConfig.fieldName, selectedIds);
      }
    }
  }, [editMode, selectedEntityConfig, handleUpdatedEntityInputChange, handleNewEntityInputChange, onSelectedEntitiesChange]);

  const handleUpdatedEntityChangeLocal = useCallback((updatedEntity) => {
    onUpdatedEntityChange(updatedEntity);
  }, [onUpdatedEntityChange]);

  const handleNewEntityChangeLocal = useCallback((newEntity) => {
    onNewEntityChange(newEntity);
  }, [onNewEntityChange]);

  if (!selectedEntityConfig) {
    return null;
  }

  return (
    <Card
      title={
        <div>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleBackButtonClick}>
            Save and back to {parentSingleEntity}
          </Button>
        </div>
      }
      style={{ width: '100%' }}
      bordered={true}
    >
      <DynamicFormSP
        defaultEditMode="Lookup"
        primarySearchProp={selectedEntityConfig.primarySearchProp}
        secondarySearchProp={selectedEntityConfig.secondarySearchProp}
        groupSearchProp={selectedEntityConfig.groupSearchProp}
        singularEntity={selectedEntityConfig.singularEntity}
        pluralEntity={selectedEntityConfig.pluralEntity}
        constantPrefix={selectedEntityConfig.constantPrefix}
        summaryProps={selectedEntityConfig.summaryProps}
        selectedItemIds={currentSelectedEntities}
        onSelectedItems={handleSelectedEntitiesChange}
        loadDto={selectedEntityConfig.loadDto}
        createEntity={selectedEntityConfig.createEntity}
        updateEntity={selectedEntityConfig.updateEntity}
        deleteEntity={selectedEntityConfig.deleteEntity}
        getEntityDetails={selectedEntityConfig.getEntityDetails}
        listEntities={selectedEntityConfig.listEntities}
        listEntitiesByIds={selectedEntityConfig.listEntitiesByIds}
        oneToOneView={selectedEntityConfig.oneToOneView}
        defaultViewMode={selectedEntityConfig.defaultViewMode}
        enableSearchBar={selectedEntityConfig.enableSearchBar || enableSearchBar}
        enableCreate={selectedEntityConfig.enableCreate || enableCreate}
        enableEdit={selectedEntityConfig.enableEdit}
        onSelectedEntitiesChange={handleSelectedEntitiesChange}
        onUpdatedEntityChange={handleUpdatedEntityChangeLocal}
        onNewEntityChange={handleNewEntityChangeLocal}
        isParent={false}
        fieldsToHide={fieldsToHide}
        listingMode={listingMode}
        enableActionButtons={enableActionButtons}
        enableExportButtons={enableExportButtons}
      />
    </Card>
  );
};

export default DynamicFormChildSP;