import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined, EditOutlined, DeleteOutlined, FormOutlined, CloseOutlined, SaveOutlined, HistoryOutlined} from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Popconfirm, Row, Select, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import { convertKeyToCamelCase } from '../../utils/functions';
import { statusFilters, emailConfirmedFilters, genderFilters, roleFilters  } from './utils/fieldFilters';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const SelectItemsDataTable = ({
  dtoConfig,
  data,
  setData,
  filter,
  onFilterChange,
  loading,
  handleDeleteRow,
  handleSaveRow,
  onStartEditRow,
  onDataUpdated,
  handleEditForm,
  entitiesMode,
  enableActionButtons
}) => {


  const { t } = useTranslation();


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingRowId, setEditingRowId] = useState(null);
  const [searchProp, setSearchProp] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const searchInput = useRef(null);

  useEffect(() => {
    setSearchText(filter.query || '');
    setSearchedColumn(filter.filter || '');
    setSearchProp(filter.filter || '');
    setDateRange(
      filter.startDate && filter.endDate
        ? [dayjs(filter.startDate), dayjs(filter.endDate)]
        : []
    );
  }, [filter]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      query: selectedKeys[0],
      filter: dataIndex,
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSaveRowLocal = (updatedRow) => {
    if (entitiesMode === "allEntities") {
      handleSaveRow(updatedRow);
      setEditingRowId(null);
    } else {
      handleSaveRow(updatedRow);
      setEditingRowId(null);
      setData((prevData) => prevData?.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    }
  };

  const handleSearchPropChange = (value) => {
    setSearchProp(value);
    onFilterChange({ ...filter, filter: value });
  };
  
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].toISOString();
      const endDate = dates[1].toISOString();
      setDateRange(dates);
      onFilterChange({ ...filter, startDate, endDate });
    } else {
      setDateRange([]);
      onFilterChange({ ...filter, startDate: null, endDate: null });
    }
  };


  const renderColumns = (dtoConfig, handleDeleteRow, handleEditForm) => {
    var columnsConfig = [];

    // Add the action column
    if (enableActionButtons) {
    columnsConfig.push({
      title: 'Actions',
      filtered: false,
      fixed: 'left',
      render: (text, record) => (
        <Space>
          {record.id === editingRowId && (
            <>
              <Button
                type="text"
                icon={<SaveOutlined />}
                onClick={() => handleSaveRowLocal(record)}
              />
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => setEditingRowId(null)}
              />
            </>
          )}
          {record.id !== editingRowId && (
            <>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => {
                  setEditingRowId(record.id);
                  onStartEditRow(record.id);
                }}
              />
              <Button
                type="text"
                icon={<FormOutlined />}
                onClick={() => handleEditForm(record.id)}
              >
                {t("View")}
              </Button>
              <Popconfirm
                title={t("Delete item")}
                description={t("Are you sure you want to delete this item?")}
                okText={t("Yes")}
                cancelText={t("No")}
                onConfirm={() => handleDeleteRow(record.id)}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  danger
                />
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    });
  }

    // Add other columns
    Object.keys(dtoConfig)?.map((key) => {
      if (dtoConfig[key].isSummaryProp) {
        let filters = [];
        let render = (text, record) => <span>{text}</span>;
        let onFilter = null;
        let sorter = null;
        let filterMode = null;
        let filterSearch = false;

        // Add logic for non-editable columns
        switch (key) {
          case 'Forename':
          case 'Surname':
          case 'Email':
          case 'Result':
          case 'Barcode':
          case 'Order':
            filters = [
              { },
            ];
            filterSearch = true;
            filterMode = 'menu';
            render = (text, record) =>
              record.id === editingRowId ? (
                <Input
                  defaultValue={text}
                  onChange={(e) => {
                    if (entitiesMode === "allEntities") {
                      onDataUpdated({
                        ...record,
                        [convertKeyToCamelCase(key)]: e.target.value,
                      });
                    } else {
                      setData((prevData) =>
                        prevData?.map((row) =>
                          row.id === record.id
                            ? { ...row, [convertKeyToCamelCase(key)]: e.target.value }
                            : row
                        )
                      );
                    }
                  }}
                />
              ) : (
                <strong>{record[convertKeyToCamelCase(key)]}</strong>
              );
              onFilter = (value, record) =>
              record[convertKeyToCamelCase(key)].toLowerCase().includes(value.toLowerCase());
            sorter = (a, b) => a[convertKeyToCamelCase(key)].localeCompare(b[convertKeyToCamelCase(key)]);
            break;
          case 'Status':
          case 'InvoiceStatus':
            filters = statusFilters;
            render = (text, record) => (
              <Tag
                color={
                  record[convertKeyToCamelCase(key)] === 'Active'
                    ? 'green'
                    : record[convertKeyToCamelCase(key)] === 'Inactive'
                    ? 'red'
                    : record[convertKeyToCamelCase(key)] === 'Pending'
                    ? 'blue'
                    : record[convertKeyToCamelCase(key)] === 'Completed'
                    ? 'purple'
                    : 'orange'
                }
              >
                {record[convertKeyToCamelCase(key)]}
              </Tag>
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'Gender':
            filters = genderFilters;
            render = (text, record) => <strong>{record[convertKeyToCamelCase(key)]}</strong>;
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'EmailConfirmed':
          case 'IsActive':
            filters = emailConfirmedFilters;
            render = (text, record) => (
              <Tag color={record[convertKeyToCamelCase(key)] ? 'green' : 'red'}>
                {record[convertKeyToCamelCase(key)] ? t('Yes') : t('No')}
              </Tag>
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)] === value;
            break;
          case 'Roles':
            filters = roleFilters;
            render = (text, record) => (
              record[convertKeyToCamelCase(key)]?.map((role) => (
                <Tag key={role} color="blue">
                  {role}
                </Tag>
              ))
            );
            onFilter = (value, record) => record[convertKeyToCamelCase(key)].includes(value);
            break;
          default:
            filters = [];
            render = (text, record) => <span>{text}</span>;
            onFilter = null;
        }

        columnsConfig.push({
          title: key,
          dataIndex: convertKeyToCamelCase(key),
          key: convertKeyToCamelCase(key),
          filtered: filters.length > 0 ? false : true,
          filters: filters,
          render: render,
          onFilter: onFilter,
          sorter: sorter,
          filterMode: filterMode,
          filterSearch: filterSearch,
        });
      }
    });

    return columnsConfig;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {t("Filter")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return (
    <Table
      title={
        entitiesMode === "allEntities" && (
          () => (
            <Space>
              <Select
                placeholder={t("Search by")}
                style={{ width: 150 }}
                value={searchProp}
                onChange={handleSearchPropChange}
              >
                {Object.keys(dtoConfig)
                  .filter((key) => dtoConfig[key].isSummaryProp)
                  ?.map((key) => (
                    <Select.Option key={key} value={key}>
                      {key}
                    </Select.Option>
                  ))}
              </Select>
              <Input.Search
                placeholder={`${t("Search by")} ${searchProp}`}
                onSearch={(value) => {
                  setSearchText(value);
                  onFilterChange({ ...filter, query: value });
                }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 200 }}
              />
              {Object.values(dtoConfig).some((field) => field.type === 'DateTime') && (
                <DatePicker.RangePicker
                  showTime={{ format: 'HH:mm' }}
                  value={dateRange}
                  allowClear={true}
                  onChange={handleDateRangeChange}
                  format="YYYY-MM-DDTHH:mm:ssZ"
                />
              )}
              <Button
                size="small"
                type="text"
                icon={<HistoryOutlined />}
                onClick={() => {
                  onFilterChange({ query: '', filter: '' });
                  setSearchText('');
                  setSearchedColumn('');
                  setSearchProp('');
                  setDateRange([]);
                }}
              >
                {t("Refresh")}
              </Button>
            </Space>
          )
        )
      }
      columns={renderColumns(dtoConfig, handleDeleteRow, handleEditForm)?.map((column) => {
        if (column.filtered) {
          return {
            ...column,
            ...getColumnSearchProps(column.dataIndex),
          };
        }
        return column;
      })}
      dataSource={data?.slice(0, 50)}
      loading={loading}
      scroll={{ x: true }}
      scrollToFirstRowOnChange
      pagination={false}
    />
  );
};

export default SelectItemsDataTable;
