import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Layout, Typography, Card, Table, Button, Input, Select, Form, 
  Drawer, Space, Popconfirm, message, Spin, Alert, Switch, Divider, 
  Tag, Row, Col
} from 'antd';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, 
  MailOutlined, SyncOutlined, ReloadOutlined
} from '@ant-design/icons';
import { 
  listSmtpConfigs, createSmtpConfig, updateSmtpConfig, 
  deleteSmtpConfig, getSmtpConfigDetails 
} from '../../actions/smtpConfigActions';
import axios from 'axios';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const SmtpConfigScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [searchTerm, setSearchTerm] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingSmtpConfig, setEditingSmtpConfig] = useState(null);
  const [testEmailTo, setTestEmailTo] = useState('');
  const [testingEmail, setTestingEmail] = useState(false);

  const smtpConfigList = useSelector((state) => state.smtpConfigList);
  const { loading, error, smtpConfigs } = smtpConfigList;

  const smtpConfigCreate = useSelector((state) => state.smtpConfigCreate);
  const { loading: createLoading, error: createError, success: successCreate } = smtpConfigCreate;

  const smtpConfigUpdate = useSelector((state) => state.smtpConfigUpdate);
  const { loading: updateLoading, error: updateError, success: successUpdate } = smtpConfigUpdate;

  useEffect(() => {
    dispatch(listSmtpConfigs());
    // reset the state when the component unmounts
    return () => {
      dispatch({ type: 'SMTP_CONFIG_CREATE_RESET' });
      dispatch({ type: 'SMTP_CONFIG_UPDATE_RESET' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (successCreate) {
      message.success('SMTP configuration created successfully');
      setDrawerVisible(false);
    }
    if (successUpdate) {
      message.success('SMTP configuration updated successfully');
      setDrawerVisible(false);
    }
  }, [successCreate, successUpdate]);

  const showDrawer = (record = null) => {
    setEditingSmtpConfig(record);
    form.resetFields();
    if (record) {
      form.setFieldsValue(record);
    }
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    setEditingSmtpConfig(null);
  };

  const onFinish = (values) => {
    if (editingSmtpConfig) {
      dispatch(updateSmtpConfig(editingSmtpConfig.id, values));
    } else {
      dispatch(createSmtpConfig(values));
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteSmtpConfig(id));
  };

  const handleTestEmail = async () => {
    setTestingEmail(true);
    try {
      const response = await axios.post("/dotnet_api/v1/auth/testemail", {
        EmailToId: testEmailTo,
        EmailToName: "Test Recipient",
        EmailSubject: "SMTP Configuration Test",
        EmailBody: "This is a test email from your SMTP configuration.",
      });
      message.success('Test email sent successfully!');
    } catch (error) {
      message.error('Failed to send test email. Please check your configuration.');
    } finally {
      setTestingEmail(false);
    }
  };

  const handleRefresh = () => {
    dispatch(listSmtpConfigs());
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
    },
    {
      title: 'Default',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (isDefault) => (
       <Tag color={isDefault ? 'green' : 'blue'}>{isDefault ? 'Yes' : 'No'}</Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => showDrawer(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this SMTP configuration?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type='text' icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: '24px' }}>
        <Card>
          <Title level={2}>SMTP Configuration</Title>
          <Divider style={{ marginTop: '12px', marginBottom: '24px' }} />
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            {error && <Alert message={error} type="error" showIcon />}
            {createError && <Alert message={createError} type="error" showIcon />}
            {updateError && <Alert message={updateError} type="error" showIcon />}

            <Row gutter={16} align="middle">
              <Col>
                <Input
                  placeholder="Search SMTP configurations"
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: 300 }}
                />
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showDrawer()}>
                  Add SMTP Configuration
                </Button>
              </Col>
              <Col>
                <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
                  Refresh
                </Button>
              </Col>
            </Row>

            <Card title="Test Email Configuration" extra={<SyncOutlined spin={testingEmail} />}>
              <Space>
                <Input
                  placeholder="Enter test email address"
                  value={testEmailTo}
                  onChange={(e) => setTestEmailTo(e.target.value)}
                  style={{ width: 300 }}
                />
                <Button 
                  type="primary" 
                  icon={<MailOutlined />} 
                  onClick={handleTestEmail}
                  loading={testingEmail}
                >
                  Send Test Email
                </Button>
              </Space>
            </Card>

            <Table
              columns={columns}
              dataSource={smtpConfigs?.filter(config => 
                config.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                config.host.toLowerCase().includes(searchTerm.toLowerCase())
              )}
              loading={loading}
              rowKey="id"
            />
          </Space>
        </Card>

        <Drawer
          title={editingSmtpConfig ? "Edit SMTP Configuration" : "Add SMTP Configuration"}
          placement="right"
          onClose={onClose}
          visible={drawerVisible}
          width={520}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="host" label="Host" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="port" label="Port" rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Item>
            <Form.Item name="username" label="Username" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password />
            </Form.Item>
            <Form.Item name="fromAddress" label="From Address" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="fromName" label="From Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="enableSsl" valuePropName="checked">
              <Switch checkedChildren="SSL Enabled" unCheckedChildren="SSL Disabled" />
            </Form.Item>
            <Form.Item name="isDefault" valuePropName="checked">
              <Switch checkedChildren="Default" unCheckedChildren="Not Default" />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true }]} >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={createLoading || updateLoading}>
                {editingSmtpConfig ? 'Update' : 'Create'}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </Content>
    </Layout>
  );
};

export default SmtpConfigScreen;