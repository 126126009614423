import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { createSample, updateSample, deleteSample, getSampleDetails, listSamples, listSamplesByIds, loadSampleDto } from '../../actions/sampleActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function SampleManagementScreen({ history }) {
  useEffect(() => {
    document.title = "Sample Management";
  }, []);

  const dispatch = useDispatch();

  const [selectedSampleId, setSelectedSampleId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [sampleListFilter, setSampleListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'Barcode' });

  // Load sample list from the state
  const sampleList = useSelector((state) => state.sampleList);
  const { loading, error, samples, currentPage, totalPages } = sampleList;

  // Load sample DTO from the state
  const sampleDto = useSelector((state) => state.sampleDto);
  const { sampleDto: sampleDtoConfig } = sampleDto;

  // Use effect to load the sample DTO and the sample list
  useEffect(() => {
    dispatch(loadSampleDto());
    dispatch(listSamples(sampleListFilter));
  }, [dispatch, sampleListFilter]);

  // Handle delete sample
  const handleDelete = (id) => {
    dispatch(deleteSample(id));
    setSampleListFilter({ query: '', page: 1, limit: 10, filter: 'Barcode' });
  };

  // Handle edit sample
  const handleEditForm = (id) => {
    setSelectedSampleId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  // Handle canceling the dynamic form
  const handleCancelForm = () => {
    setSelectedSampleId(null);
    setShowDynamicForm(false);
  };

  // Handle create sample
  const handleCreateSample = () => {
    setSelectedSampleId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>Sample Management</h2>
      </Space>
      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreateSample}
            icon={<PlusOutlined />}
            size="large"
          >
            Create Sample
          </Button>

          <ListItemsDataTable
            loading={loading}
            dtoConfig={sampleDtoConfig}
            data={samples}
            filter={sampleListFilter}
            onFilterChange={setSampleListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={(id) => { handleDelete(id) }}
            onStartEditRow={(id) => { setSelectedSampleId(id) }}
            handleSaveRow={(updatedRow) => {
              dispatch(updateSample(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getSampleDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}

      {showDynamicForm && (
        <React.Fragment>
          <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Samples
            </Button>
          </Space>

          <DynamicFormSP
            currentEntityId={selectedSampleId}
            selectedItems={null}
            selectedEntityIds={null}
            listingMode='allEntities'
            defaultEditMode={editMode}
            primarySearchProp="barcode"
            secondarySearchProp="sampleType"
            singularEntity="sample"
            pluralEntity="samples"
            constantPrefix="SAMPLE"
            onSelectedItems={(selectedItems) => { }}
            loadDto={loadSampleDto}
            createEntity={createSample}
            updateEntity={updateSample}
            deleteEntity={deleteSample}
            getEntityDetails={getSampleDetails}
            listEntities={listSamples}
            listEntitiesByIds={listSamplesByIds}
            onSelectedEntitiesChange={(e) => { }}
            onUpdatedEntityChange={(e) => { }}
            onNewEntityChange={(e) => { }}
            isParent={true}
            fieldsToHide={['Id', 'SampleInventoryLineId', 'CreatedAt', 'UpdatedAt']}
            summaryProps={['Name', 'SampleCode', 'SampleType']}
            enableCreate={true}
            enableSearchBar={false}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}