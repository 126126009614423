import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { createPatient, updatePatient, deletePatient, getPatientDetails, listPatients, listPatientsByIds, loadPatientDto } from '../../actions/patientActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function PatientListScreen({ history }) {

  useEffect(() => {
    document.title = "Patient Management";
  }, []);

  const dispatch = useDispatch();

  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [editMode, setEditMode] = useState('Edit');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [patientListFilter, setPatientListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'Surname' });

  // Load patient list from the state
  const patientList = useSelector((state) => state.patientList);
  const { loading, error, patients, currentPage, totalPages } = patientList;

  // Load patient DTO from the state
  const patientDto = useSelector((state) => state.patientDto);
  const { patientDto: patientDtoConfig } = patientDto;

  // Use effect to load the patient DTO and the patient list
  useEffect(() => {
    dispatch(loadPatientDto());
    dispatch(listPatients(patientListFilter));
  }, [dispatch, patientListFilter]);

  // Handle delete patient
  const handleDelete = (id) => {
    dispatch(deletePatient(id));
    setPatientListFilter({ query: '', page: 1, limit: 10, filter: 'Surname' });
  };

  // Handle edit patient
  const handleEditForm = (id) => {
    setSelectedPatientId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  // Handle canceling the dynamic form
  const handleCancelForm = () => {
    setSelectedPatientId(null);
    setShowDynamicForm(false);
  };

  // Handle create patient
  const handleCreatePatient = () => {
    setSelectedPatientId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>Patient Management</h2>
      </Space>
      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreatePatient}
            icon={<PlusOutlined />}
            size="large"
          >
            Create Patient
          </Button>

          <ListItemsDataTable
            loading={loading}
            dtoConfig={patientDtoConfig}
            data={patients}
            filter={patientListFilter}
            onFilterChange={setPatientListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={handleDelete}
            onStartEditRow={setSelectedPatientId}
            handleSaveRow={(updatedRow) => {
              dispatch(updatePatient(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getPatientDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}


      {showDynamicForm && (

        <React.Fragment>
          <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Patients
            </Button>

          </Space>

          <DynamicFormSP

            currentEntityId={selectedPatientId? selectedPatientId : null}
            selectedItems={null}
            selectedEntityIds={null}
            listingMode='selectedItems'
            defaultEditMode={editMode}
            primarySearchProp={"surname"}
            secondarySearchProp={"forename"}
            singularEntity={"patient"}
            pluralEntity={"patients"}
            constantPrefix={"PATIENT"}
            onSelectedItems={(selectedItems) => { }}
            loadDto={loadPatientDto}
            createEntity={createPatient}
            updateEntity={updatePatient}
            deleteEntity={deletePatient}
            getEntityDetails={getPatientDetails}
            listEntities={listPatients}
            listEntitiesByIds={listPatientsByIds}
            onSelectedEntitiesChange={(e) => { }}
            onUpdatedEntityChange={() => { }}
            onNewEntityChange={(e) => { }}
            onEditModeChange={(editMode) => { setEditMode(editMode) }}
            isParent={true}
            fieldsToHide={['Id', 'Test']}
            summaryProps={['healthInsuranceNumber', 'surname', 'forename', 'dateOfBirth']}

            // enable buttons
            enableCreate={true}
            enableSearchBar={true}
            enableExportButtons={true}





          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

}