import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Space,
  Row,
  Col,
  Card,
  Typography,
  message,
  Affix,
  Divider,
  Modal,
  Popconfirm,
  Statistic,
  Descriptions,
  Avatar,
  Tag
} from 'antd';
import {
  ArrowLeftOutlined,
  PrinterOutlined,
  MailOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  BarChartOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  updateTestResultReport,
  getTestResultReportDetails,
  loadTestResultReportDto,
  listTestResultReports,
  updateValidationStatus
} from '../../actions/testResultReportActions';
import {
  listTestResults,
  updateTestResult,
  loadTestResultDto,
  getTestResultDetails,
  listTestResultsByIds
} from '../../actions/testResultActions';
import { getPatientDetails } from '../../actions/patientActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import Printer from '../../components/printer/Printer.component';
import TestHistoryVisualizer from '../../components/testHistoryVisualizer/TestHistoryVisualizer.component';
import { barcodeToBase64, evaluateFlexibleUnitConversion } from '../../utils/functions';

const { Title, Paragraph, Text } = Typography;

export default function EditTestResultReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _id } = useParams();
  const [currentState, setCurrentState] = useState({
    testResultReport: null,
    testResults: [],
    testResultIds: [],
    printerVisible: false,
    reportDocumentData: {},
    editMode: 'Edit'
  });


  const [showVisualizer, setShowVisualizer] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);

  const testResultReportDetails = useSelector((state) => state.testResultReportDetails);
  const validationUpdate = useSelector((state) => state.testResultReportValidationUpdate);
  const patientDetailsData = useSelector((state) => state.patientDetails);

  useEffect(() => {
    dispatch(getTestResultReportDetails(_id));
    dispatch(loadTestResultReportDto());
    dispatch(loadTestResultDto());
  }, [dispatch, _id]);

  // listen to testResultReportDetails.testResultReport.patientId
  useEffect(() => {
    if (testResultReportDetails.testResultReport && testResultReportDetails.testResultReport.patientId) {
      dispatch(getPatientDetails(testResultReportDetails.testResultReport.patientId));
    }
  }, [testResultReportDetails.testResultReport, dispatch]);

  useEffect(() => {
    if (testResultReportDetails.testResultReport && !currentState.testResultReport) {
      setCurrentState(prevState => ({
        ...prevState,
        testResultReport: testResultReportDetails.testResultReport,
        testResultIds: testResultReportDetails.testResultReport.testResultIds || [],
      }));
      dispatch(listTestResults({ testResultReportId: _id }));
    }
  }, [testResultReportDetails.testResultReport, currentState.testResultReport, dispatch, _id]);


  useEffect(() => {
    if (validationUpdate.success) {
      setCurrentState(prevState => ({
        ...prevState,
        testResultReport: {
          ...prevState.testResultReport,
          validationStatus: validationUpdate.testResultReport.validationStatus
        }
      }));
      message.success(`Test Result Report ${validationUpdate.testResultReport.validationStatus ? 'validated' : 'invalidated'} successfully`);
    } else if (validationUpdate.error) {
      message.error(`Failed to ${validationUpdate.testResultReport.validationStatus ? 'validate' : 'invalidate'} the report: ${validationUpdate.error}`);
    }
  }, [validationUpdate]);

  useEffect(() => {
    if (patientDetailsData.patient) {
      setPatientDetails(patientDetailsData.patient);
    }
  }, [patientDetailsData.patient]);

  const handlePrintReport = async () => {
    if (!currentState.testResultReport.validationStatus) {
      message.warning('Report must be validated before printing.');
      return;
    }

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
      });
    };

    const barcodeImage = await barcodeToBase64(currentState.testResultReport.barcode, {
      format: 'CODE128',
      width: 2,
      height: 25,
      fontSize: 12
    });

    const data = {
      "header": {
        content: {
          ...currentState.testResultReport,
          reportNumber: currentState.testResultReport.orderNumber,
          reportDate: formatDate(currentState.testResultReport.resultDate),
        },
      },
      "patientInfo": {
        content: {
          ...patientDetails,
          patientName: `${patientDetails.forename} ${patientDetails.surname}`,
          dateOfBirth: formatDate(patientDetails.dateOfBirth),
          nhsNumber: patientDetails.healthInsuranceNumber,
        },
      },
"testResults": currentState.testResults?.map(testResult => {
  const numResult = parseFloat(testResult.result);
  const numCriticalHigh = parseFloat(testResult.criticalHigh);
  const numCriticalLow = parseFloat(testResult.criticalLow);

  let convertedResult = null;

  if (testResult.secondaryUnits) {
    convertedResult = evaluateFlexibleUnitConversion(
      testResult.secondaryUnits,
      numResult,
      numCriticalLow,
      numCriticalHigh
    );
  }



  return {
    content: {
      ...testResult,
      result: testResult.result || 'N/A',
      status: numResult > numCriticalHigh || numResult < numCriticalLow ? '*' : 'N',
      resultClass: numResult > numCriticalHigh || numResult < numCriticalLow ? 'text-warning' : 'text-success',
      convertedResult: convertedResult ? convertedResult.convertedValue : 'N/A',
      secondaryUnits: convertedResult ? convertedResult.convertedUnit : null,
      secondaryReferenceRange: convertedResult ? convertedResult.convertedReferenceRange : null,
      method: testResult.test.method || '',
    }
  };
}),
      "reportSummary": {
        content: {
          ...currentState.testResultReport,
          orderingPhysician: currentState.testResultReport.orderingPractitioner,
          specimenCollectedDate: formatDate(currentState.testResultReport.orderDate),
          specimenReceivedDate: formatDate(currentState.testResultReport.orderDate),
          reportDate: formatDate(currentState.testResultReport.resultDate)
        },
      },
      "interpretation": {
        content: {
          interpretationText: currentState.testResultReport.reportConclusion || "No interpretation provided."
        },
      },
      "notes": {
        content: {
          notesText: currentState.testResultReport.comments || "No additional notes."
        },
      },
      "signature": {
        content: {
          validatedBy: currentState.testResultReport.verifiedBy,
          validationDate: formatDate(currentState.testResultReport.verifiedDate)
        },
      },
      "footer": {
        content: {
          footerText: "This report is confidential and intended only for the individual or entity to whom it is addressed. If you have received this report in error, please notify the laboratory immediately."
        },
      },
      "barcode": {
        content: {
          barcodeImage: barcodeImage,
          barcode: currentState.testResultReport.barcode,
        }
      }
    };

    setCurrentState(prevState => ({
      ...prevState,
      reportDocumentData: data,
      printerVisible: true
    }));
  };


  const handleSendEmail = () => {
    if (!currentState.testResultReport.validationStatus) {
      message.warning('Report must be validated before sending email.');
      return;
    }
    message.info('Email sending functionality to be implemented');
  };

  const handleValidateReport = async () => {
    try {
      await dispatch(updateValidationStatus(_id, true));
      dispatch(getTestResultReportDetails(_id));
      message.success('Report validated successfully');
    } catch (error) {
      message.error('Failed to validate report: ' + error.message);
    }
  };

  const handleInvalidateReport = async () => {
    try {
      await dispatch(updateValidationStatus(_id, false));
      dispatch(getTestResultReportDetails(_id));
      message.success('Report invalidated successfully');
    } catch (error) {
      message.error('Failed to invalidate report: ' + error.message);
    }
  };

  const handleShowVisualizer = () => {
    setShowVisualizer(true);
  };

  const renderPatientSummary = () => {
    if (!patientDetails) return null;

    const calculateAge = (birthDate) => {
      const ageDifMs = Date.now() - new Date(birthDate).getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    return (
      <Card
        title={<Space><Avatar icon={<UserOutlined />} /> Patient Summary</Space>}
        style={{ marginBottom: 16 }}
        extra={
          <Tag color={currentState.testResultReport?.validationStatus ? 'green' : 'orange'}>
            {currentState.testResultReport?.validationStatus ? 'Validated' : 'Not Validated'}
          </Tag>
        }
      >
        <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label="Name">{`${patientDetails.forename} ${patientDetails.surname}`}</Descriptions.Item>
          <Descriptions.Item label="Age">{calculateAge(patientDetails.dateOfBirth)} years</Descriptions.Item>
          <Descriptions.Item label="Gender">{patientDetails.gender}</Descriptions.Item>
          <Descriptions.Item label="Blood Type">{patientDetails.bloodType || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label="NHS Number">{patientDetails.healthInsuranceNumber}</Descriptions.Item>
          <Descriptions.Item label="Phone">{patientDetails.phoneNumber}</Descriptions.Item>
        </Descriptions>
        {patientDetails.allergies && (
          <>
            <Divider />
            <Paragraph>
              <Text strong>Allergies: </Text>
              <Text type="warning">{patientDetails.allergies}</Text>
            </Paragraph>
          </>
        )}
        {patientDetails.medicalConditions && (
          <Paragraph>
            <Text strong>Medical Conditions: </Text>
            <Text>{patientDetails.medicalConditions}</Text>
          </Paragraph>
        )}
      </Card>
    );
  };


  return (
    <React.Fragment> 
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={2}>Edit Test Result Report</Title>
      </Space>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
        Back to Test Result Reports
      </Button>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          {renderPatientSummary()}
        </Col>
        <Col xs={24} md={18}>
          {currentState.testResultIds && (
            <DynamicFormSP
              key={currentState.testResultIds?.map(id => id).join('-')}
              defaultEditMode="Lookup"
              listingMode='selectedIds'
              selectedItems={[]}
              selectedItemIds={currentState.testResultIds}
              primarySearchProp="testName"
              secondarySearchProp="result"
              singularEntity="testResult"
              pluralEntity="testResults"
              constantPrefix="TEST_RESULT"
              loadDto={loadTestResultDto}
              listEntities={listTestResults}
              listEntitiesByIds={listTestResultsByIds}
              updateEntity={updateTestResult}
              getEntityDetails={getTestResultDetails}
              isParent={false}
              onSelectedEntitiesChange={(selectedItems) => {
                setCurrentState(prevState => ({
                  ...prevState,
                  testResults: selectedItems
                }));
              }}
              fieldsToHide={['Id', 'TestResultReportId']}
              summaryProps={['testName', 'result', 'referenceRange', 'unit', 'status']}
              enableCreate={false}
              enableSearchBar={false}
              enableActionButtons={true}
            />
          )}
          <Divider orientation="left">Edit Test Result Report</Divider>
          {currentState.testResultReport && (
            <DynamicFormSP
              currentEntityId={_id}
              defaultEditMode={currentState.editMode}
              primarySearchProp="reportNumber"
              secondarySearchProp="patientName"
              singularEntity="testResultReport"
              pluralEntity="testResultReports"
              constantPrefix="TEST_RESULT_REPORT"
              loadDto={loadTestResultReportDto}
              updateEntity={updateTestResultReport}
              getEntityDetails={getTestResultReportDetails}
              listEntities={listTestResultReports}
              listEntitiesByIds={listTestResultReports}
              onUpdatedEntityChange={(updatedEntity) => {
                setCurrentState(prevState => ({
                  ...prevState,
                  testResultReport: updatedEntity,
                  testResultIds: updatedEntity.testResultIds
                }));
              }}
              isParent={true}
              fieldsToHide={['Id', 'TestResultIds', 'TestOrderBulkRequestId', 'PatientId']}
              summaryProps={['reportNumber', 'reportDate', 'patientName', 'status']}
              enableCreate={false}
              enableSearchBar={false}
              enableActionButtons={false}
            />
          )}
        </Col>
        <Col xs={24} md={6}>
          <Affix offsetTop={80}>
            <Card>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Button
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={handlePrintReport}
                  disabled={!currentState.testResultReport?.validationStatus}
                  block
                >
                  Print Report
                </Button>
                <Button
                  icon={<MailOutlined />}
                  onClick={handleSendEmail}
                  disabled={!currentState.testResultReport?.validationStatus}
                  block
                >
                  Send Email
                </Button>
                <Button
                  icon={<BarChartOutlined />}
                  onClick={handleShowVisualizer}
                  block
                >
                  View Test History
                </Button>
                <Divider />
                <Statistic
                  title="Report Status"
                  value={currentState.testResultReport?.status || 'N/A'}
                  valueStyle={{ color: currentState.testResultReport?.status === 'Completed' ? '#3f8600' : '#cf1322' }}
                />
                <Divider />
                {currentState.testResultReport?.validationStatus ? (
                  <Popconfirm
                    title="Are you sure you want to invalidate this report?"
                    onConfirm={handleInvalidateReport}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="danger"
                      icon={<WarningOutlined />}
                      loading={validationUpdate.loading}
                      block
                    >
                      Invalidate Report
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to validate this report?"
                    onConfirm={handleValidateReport}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="primary"
                      icon={<CheckCircleOutlined />}
                      loading={validationUpdate.loading}
                      block
                    >
                      Validate Report
                    </Button>
                  </Popconfirm>
                )}
                {!currentState.testResultReport?.validationStatus && (
                  <Paragraph type="warning">
                    <WarningOutlined /> This report is not yet validated. Validate the report when all results are verified.
                  </Paragraph>
                )}
              </Space>
            </Card>
          </Affix>
        </Col>
      </Row>
      <PrinterModal currentState={currentState} setCurrentState={setCurrentState} patientDetails={patientDetails} />
      <Modal
        title="Test History Visualization"
        visible={showVisualizer}
        onCancel={() => setShowVisualizer(false)}
        width="80%"
        footer={null}
      >
        <TestHistoryVisualizer patientId={currentState.testResultReport?.patientId} />
      </Modal>
    </React.Fragment>
  );
}

const PrinterModal = ({ currentState, setCurrentState, patientDetails }) => (
  <Modal
    title="Print Test Result Report"
    visible={currentState.printerVisible}
    width="70%"
    style={{ top: 20 }}
    footer={null}
    onOk={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
    onCancel={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
  >
    <Printer
      configEndpoint="/print/configs/test_result_report"
      generateEndpoint="/print/documents/generate"
      data={{
        ...currentState.reportDocumentData,
        PATIENT_DETAILS: patientDetails
      }}
    />
  </Modal>
);

