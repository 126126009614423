import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../actions/userActions";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import { Card, Form, Input, Button, Typography, Space, Divider, Alert, Select, theme } from 'antd';
import { WarningOutlined, CheckCircleTwoTone, InfoCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.svg';
import { ThemeContext } from '../index';

const { Title, Text } = Typography;
const { useToken } = theme;

function TwoFACodeRequestScreen() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { themeConfig } = useContext(ThemeContext);
    const { token } = useToken();

    const [code, setCode] = useState('');
    const [TFAMessage, setTFAMessage] = useState('');
    const [sendingCode, setSendingCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [alertType, setAlertType] = useState('info');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        document.title = t('2FA.pageTitle');
    }, [t]);

    useEffect(() => {
        if (!userInfo || !userInfo.email) {
            history.push("/userlogin");
        } else {
            setEmail(userInfo.email);
            handle2FACodeRequest(userInfo.email);
        }
    }, [history, userInfo]);

    const handle2FACodeRequest = async (email) => {
        setSendingCode(true);
        setTFAMessage(t('2FA.sendingCode', { email }));
        setAlertType('info');
        try {
            await axios.post(`/dotnet_api/v1/auth/sendverificationcode`, { email });
            setSendingCode(false);
            setTFAMessage(t('2FA.codeSent', { email }));
            setAlertType('success');
        } catch (error) {
            setSendingCode(false);
            setTFAMessage(t('2FA.codeRequestFailed'));
            setAlertType('error');
        }
    }

    const handle2FACodeSubmission = async (values) => {
        setLoading(true);
        try {
            await axios.post(`/dotnet_api/v1/auth/verifycode`, { email, code: values.code });
            setLoading(false);
            setTFAMessage(t('2FA.codeVerified'));
            setAlertType('success');
            localStorage.setItem("2fauthenticated", "true");
            setTimeout(() => history.push("/"), 2000);
        } catch (error) {
            setLoading(false);
            setTFAMessage(t('2FA.codeVerificationFailed'));
            setAlertType('error');
        }
    }

    const handleLogout = () => {
        dispatch(logout());
        history.push("/userlogin");
    };

    const getAlertIcon = (type) => {
        switch (type) {
            case 'success':
                return <CheckCircleTwoTone twoToneColor="#52c41a" />;
            case 'error':
                return <WarningOutlined style={{ color: '#ff4d4f' }} />;
            case 'info':
            default:
                return <InfoCircleOutlined style={{ color: '#1890ff' }} />;
        }
    }

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
    };

    const languageOptions = [
        { value: 'en', label: '🇬🇧 English' },
        { value: 'fr', label: '🇫🇷 Français' },
    ];

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '100vh',
            background: token.colorBgLayout,
        }}>
            <Card 
                style={{ 
                    width: '100%',
                    maxWidth: 400,
                    boxShadow: `0 4px 8px ${token.colorBgElevated}`,
                    borderRadius: '8px',
                    background: token.colorBgContainer,
                }}
                cover={
                    <div style={{ 
                        background: 'transparent',
                        marginTop: '50px', 
                        marginBottom: '16px',
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                    }}>
                        <img
                            src={logo}
                            alt="logo"
                            style={{
                                width: '100%',
                                maxWidth: '200px',
                                filter: themeConfig.algorithm === theme.darkAlgorithm ? 'invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)' : 'none',
                            }}
                        />
                    </div>
                }
            >
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={3} style={{ textAlign: 'center', margin: 0, color: token.colorTextHeading }}>
                        {t('2FA.title')}
                    </Title>
                    <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                        {t('2FA.instruction')}
                    </Text>
                    <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                        <strong>{userInfo.email}</strong>
                    </Text>

                    <Form
                        onFinish={handle2FACodeSubmission}
                        style={{ width: '100%' }}
                    >
                        <Form.Item
                            name="code"
                            rules={[{ required: true, message: t('2FA.codeRequired') }]}
                        >
                            <Input
                                placeholder={t('2FA.codePlaceholder')}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                                loading={loading}
                            >
                                {loading ? t('2FA.verifying') : t('2FA.verify')}
                            </Button>
                        </Form.Item>
                    </Form>

                    {TFAMessage && (
                        <Alert
                            message={TFAMessage}
                            type={alertType}
                            showIcon
                            icon={getAlertIcon(alertType)}
                        />
                    )}

                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                        <a href="#" onClick={() => handle2FACodeRequest(email)} disabled={sendingCode}>
                            {sendingCode ? t('2FA.sendingCode') : t('2FA.resendCode')}
                        </a>
                    </div>

                    <Divider />

                    <div style={{ textAlign: 'center', marginTop: '8px' }}>
                        <Link to="/" onClick={handleLogout}>
                            {t('2FA.logout')}
                        </Link>
                    </div>

                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                        <Select
                            defaultValue={i18n.language}
                            style={{ width: 120 }}
                            onChange={handleLanguageChange}
                            options={languageOptions}
                        />
                    </div>
                </Space>
            </Card>
        </div>
    )
}

export default TwoFACodeRequestScreen;