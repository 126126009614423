export const BUSINESS_ORGANISATION_LIST_REQUEST = 'BUSINESS_ORGANISATION_LIST_REQUEST';
export const BUSINESS_ORGANISATION_LIST_SUCCESS = 'BUSINESS_ORGANISATION_LIST_SUCCESS';
export const BUSINESS_ORGANISATION_LIST_FAIL = 'BUSINESS_ORGANISATION_LIST_FAIL';

export const BUSINESS_ORGANISATION_DETAILS_REQUEST = 'BUSINESS_ORGANISATION_DETAILS_REQUEST';
export const BUSINESS_ORGANISATION_DETAILS_SUCCESS = 'BUSINESS_ORGANISATION_DETAILS_SUCCESS';
export const BUSINESS_ORGANISATION_DETAILS_FAIL = 'BUSINESS_ORGANISATION_DETAILS_FAIL';

export const BUSINESS_ORGANISATION_CREATE_REQUEST = 'BUSINESS_ORGANISATION_CREATE_REQUEST';
export const BUSINESS_ORGANISATION_CREATE_SUCCESS = 'BUSINESS_ORGANISATION_CREATE_SUCCESS';
export const BUSINESS_ORGANISATION_CREATE_FAIL = 'BUSINESS_ORGANISATION_CREATE_FAIL';

export const BUSINESS_ORGANISATION_UPDATE_REQUEST = 'BUSINESS_ORGANISATION_UPDATE_REQUEST';
export const BUSINESS_ORGANISATION_UPDATE_SUCCESS = 'BUSINESS_ORGANISATION_UPDATE_SUCCESS';
export const BUSINESS_ORGANISATION_UPDATE_FAIL = 'BUSINESS_ORGANISATION_UPDATE_FAIL';

export const BUSINESS_ORGANISATION_DELETE_REQUEST = 'BUSINESS_ORGANISATION_DELETE_REQUEST';
export const BUSINESS_ORGANISATION_DELETE_SUCCESS = 'BUSINESS_ORGANISATION_DELETE_SUCCESS';
export const BUSINESS_ORGANISATION_DELETE_FAIL = 'BUSINESS_ORGANISATION_DELETE_FAIL';

export const BUSINESS_ORGANISATION_DTO_REQUEST = 'BUSINESS_ORGANISATION_DTO_REQUEST';
export const BUSINESS_ORGANISATION_DTO_SUCCESS = 'BUSINESS_ORGANISATION_DTO_SUCCESS';
export const BUSINESS_ORGANISATION_DTO_FAIL = 'BUSINESS_ORGANISATION_DTO_FAIL';

export const BUSINESS_ORGANISATION_EXISTS_REQUEST = 'BUSINESS_ORGANISATION_EXISTS_REQUEST';
export const BUSINESS_ORGANISATION_EXISTS_SUCCESS = 'BUSINESS_ORGANISATION_EXISTS_SUCCESS';
export const BUSINESS_ORGANISATION_EXISTS_FAIL = 'BUSINESS_ORGANISATION_EXISTS_FAIL';

export const BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_REQUEST = 'BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_REQUEST';
export const BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_SUCCESS = 'BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_SUCCESS';
export const BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_FAIL = 'BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_FAIL';

export const BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_REQUEST = 'BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_REQUEST';
export const BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_SUCCESS = 'BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_SUCCESS';
export const BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_FAIL = 'BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_FAIL';

export const BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_REQUEST = 'BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_REQUEST';
export const BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_SUCCESS = 'BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_SUCCESS';
export const BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_FAIL = 'BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_FAIL';

export const BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_REQUEST = 'BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_REQUEST';
export const BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_SUCCESS = 'BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_SUCCESS';
export const BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_FAIL = 'BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_FAIL';

export const BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST = 'BUSINESS_ORGANISATION_LIST_BY_IDS_REQUEST';
export const BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS = 'BUSINESS_ORGANISATION_LIST_BY_IDS_SUCCESS';
export const BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL = 'BUSINESS_ORGANISATION_LIST_BY_IDS_FAIL';

export const BUSINESS_ORGANISATION_LIST_RESET = 'BUSINESS_ORGANISATION_LIST_RESET';
export const BUSINESS_ORGANISATION_DETAILS_RESET = 'BUSINESS_ORGANISATION_DETAILS_RESET';
export const BUSINESS_ORGANISATION_CREATE_RESET = 'BUSINESS_ORGANISATION_CREATE_RESET';
export const BUSINESS_ORGANISATION_UPDATE_RESET = 'BUSINESS_ORGANISATION_UPDATE_RESET';
export const BUSINESS_ORGANISATION_DELETE_RESET = 'BUSINESS_ORGANISATION_DELETE_RESET';
export const BUSINESS_ORGANISATION_DTO_RESET = 'BUSINESS_ORGANISATION_DTO_RESET';
export const BUSINESS_ORGANISATION_EXISTS_RESET = 'BUSINESS_ORGANISATION_EXISTS_RESET';
export const BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_RESET = 'BUSINESS_ORGANISATION_EXPIRED_SUBSCRIPTIONS_RESET';
export const BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_RESET = 'BUSINESS_ORGANISATION_ACTIVE_SUBSCRIPTIONS_RESET';
export const BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_RESET = 'BUSINESS_ORGANISATION_UPDATE_SUBSCRIPTION_STATUS_RESET';
export const BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_RESET = 'BUSINESS_ORGANISATION_UPDATE_PAYMENT_INFO_RESET';
export const BUSINESS_ORGANISATION_LIST_BY_IDS_RESET = 'BUSINESS_ORGANISATION_LIST_BY_IDS_RESET';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const BUSINESS_ORGANISATION_ACTIVATE_REQUEST = 'BUSINESS_ORGANISATION_ACTIVATE_REQUEST';
export const BUSINESS_ORGANISATION_ACTIVATE_SUCCESS = 'BUSINESS_ORGANISATION_ACTIVATE_SUCCESS';
export const BUSINESS_ORGANISATION_ACTIVATE_FAIL = 'BUSINESS_ORGANISATION_ACTIVATE_FAIL';

export const BUSINESS_ORGANISATION_DEACTIVATE_REQUEST = 'BUSINESS_ORGANISATION_DEACTIVATE_REQUEST';
export const BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS = 'BUSINESS_ORGANISATION_DEACTIVATE_SUCCESS';
export const BUSINESS_ORGANISATION_DEACTIVATE_FAIL = 'BUSINESS_ORGANISATION_DEACTIVATE_FAIL';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';