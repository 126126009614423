import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Space, Modal, message, Progress, Menu, Dropdown } from 'antd';
import { SaveOutlined, PrinterOutlined, EyeOutlined, FileExcelOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { beautifyKey, convertKeyToCamelCase } from '../../../utils/functions';
import { renderField } from '../utils/renderUIComponents';
import { fieldEntityActionConfig } from '../../crud/utils/fieldEntityActionConfig';
import { exportToCSV } from '../../../utils/exportUtils';
import Printer from '../../../components/printer/Printer.component';
// translation
import { useTranslation } from 'react-i18next';

import { useTranslatedOptions } from '../utils/fieldOptions'; // Import the translation function

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const EditForm = ({
  editMode,
  updatedEntity,
  loadingUpdate,
  editForm,
  handleSubmitUpdatedEntity,
  errorUpdate,
  singularEntity,
  entityDtoConfig,
  handleUpdatedEntityInputChange,
  handleBrowseEntity,
  fieldsToHide,
  enableExportButtons = false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // Initialize the translation hook
  const userInfo = useSelector(state => state.userLogin.userInfo);
  const entityLists = useSelector(state => {
    const lists = {};
    Object.keys(fieldEntityActionConfig).forEach(key => {
      const config = fieldEntityActionConfig[key];
      const storeKey = `${convertKeyToCamelCase(config.singularEntity)}ListByIDs`;
      lists[key] = state[storeKey];
    });
    return lists;
  });

  const [displayError, setDisplayError] = useState(null);
  const [loading, setLoading] = useState({ print: false, preview: false, csv: false });
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [printerModalVisible, setPrinterModalVisible] = useState(false);
  const [enrichedEntity, setEnrichedEntity] = useState(null);
  const [enrichProgress, setEnrichProgress] = useState(0);
  const [currentEnrichingField, setCurrentEnrichingField] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [config, setConfig] = useState(null);
  const [dataReadyForPrint, setDataReadyForPrint] = useState(false);

  useEffect(() => {
    if (loadingUpdate) setDisplayError(null);
    if (errorUpdate && errorUpdate !== displayError) setDisplayError(errorUpdate);
  }, [errorUpdate, displayError, loadingUpdate]);

  useEffect(() => {
    if (!enableExportButtons) return;
    fetchConfig();
  }, [singularEntity, enableExportButtons, userInfo.token]);

  useEffect(() => {
    if (enrichedEntity && !loading.preview && !loading.print) {
      setPrintData(preparePrintData(enrichedEntity, singularEntity));
      setDataReadyForPrint(true);
    }
  }, [enrichedEntity, singularEntity, loading.preview, loading.print]);

  useEffect(() => {
    if (dataReadyForPrint && loading.print) {
      setPrinterModalVisible(true);
    }
  }, [dataReadyForPrint, loading.print]);

  const fetchConfig = async () => {
    try {
      const { data: configData } = await axios.get(`/print/configs/${singularEntity}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` }
      });
      setConfig(configData);
      return configData;
    } catch (error) {
      console.error('Failed to fetch config:', error);
      message.error(t('Failed to fetch configuration'));
    }
  };

  const handleAction = async (action) => {
    setLoading(prev => ({ ...prev, [action]: true }));
    try {
      switch (action) {
        case 'preview':
          await handlePreview();
          break;
        case 'csv':
          await handleCSV();
          break;
        case 'print':
          await handlePrint();
          break;
        default:
          throw new Error(`${t("Unknown action:")} ${action}`);
      }
    } catch (error) {
      console.error(`Failed to ${action}:`, error);
      message.error(`${t("Failed to")} ${action}: ${error.message}`);
    } finally {
      setLoading(prev => ({ ...prev, [action]: false }));
    }
  };

  const handlePreview = async () => {
    setPreviewModalVisible(true);
    try {
      await enrichData();
    } catch (error) {
      console.error('Error during enrichment:', error);
      message.error(t('Failed to enrich data'));
    }
  };

  const handleCSV = async () => {
    try {
      await enrichData();
      await exportToCSV(enrichedEntity, `${singularEntity}_export`);
      message.success(t('CSV exported successfully'));
    } catch (error) {
      console.error('Error during CSV export:', error);
      message.error(t('Failed to export CSV'));
    }

  };

  const handlePrint = async () => {
    setPrinterModalVisible(true);
    try {
      await enrichData();
    } catch (error) {
      console.error('Error in handlePrint:', error);
      message.error(`${t('Failed to prepare print data')}: ${error.message}`);
    }
  };


  const enrichData = async () => {
    let enrichedData = { ...updatedEntity };
    setEnrichProgress(0);

    const fieldsToEnrich = Object.entries(updatedEntity)
      .filter(([key, value]) => Array.isArray(value) && value.length > 0 && typeof value[0] === 'string');

    const totalFields = fieldsToEnrich.length;

    for (const [index, [key, value]] of fieldsToEnrich.entries()) {
      const configKey = key.charAt(0).toUpperCase() + key.slice(1);
      const config = fieldEntityActionConfig[configKey];
      if (config?.listEntitiesByIds) {
        setCurrentEnrichingField(configKey);

        try {
          await dispatch(config.listEntitiesByIds(value));
          await delay(500);

          const entityListByIDs = entityLists[configKey];
          if (!entityListByIDs) throw new Error(`${t('No entity list found for')} ${configKey}`);

          const { loading: loadingListByIDs, error: errorListByIDs, [config.pluralEntity]: entitiesByIds } = entityListByIDs;

          if (loadingListByIDs) {
            index--;
            continue;
          }

          if (errorListByIDs) throw new Error(errorListByIDs);

          if (entitiesByIds) {
            enrichedData[key] = entitiesByIds;
          } else {
            console.warn(`No entities returned for ${key}`);
          }
        } catch (error) {
          console.error(`Error enriching data for ${key}:`, error);
          message.error(t(`Failed to enrich data for ${key}: ${error.message}`));
        }

        await delay(500);
      }
      setEnrichProgress(Math.round(((index + 1) / totalFields) * 100));
    }

    setEnrichedEntity(enrichedData);
    return enrichedData;
  };

  const preparePrintData = (enrichedEntity, singularEntity) => {
    if (!config?.content?.data) {
      throw new Error(t('Invalid print configuration'));
    }

    const printConfig = config.content.data;
    const printData = {};

    for (const [prop, settings] of Object.entries(printConfig)) {
      const { type } = settings;

      if (type === 'bloc') {
        printData[prop] = { content: enrichedEntity[prop] || enrichedEntity };
      } else if (type === 'collection') {
        const items = enrichedEntity[prop] || [];
        printData[prop] = items?.map(item => ({
          content: item,
          template: item.template || null
        }));
      } else {
        console.warn(`Unknown type '${type}' for configuration key '${prop}'`);
        printData[prop] = { content: {} };
      }
    }

    return printData;
  };

  // use effect to load config and enrich data and set the state for print data
  useEffect(() => {
    if (editMode === 'Edit' && updatedEntity && enableExportButtons) {
      fetchConfig().then(() => {
        enrichData()
      }
      );
    }
  }, [editMode, updatedEntity]);

  return (
    <>
      <Form
        name='editForm'
        layout='horizontal'
        labelCol={{ span: 10 }}
        hidden={editMode !== "Edit"}
        size='large'
        disabled={loadingUpdate}
        form={editForm}
        scrollToFirstError
        onFinish={handleSubmitUpdatedEntity}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
          <h3>{t(`Edit ${singularEntity}`)}</h3>
          {enableExportButtons && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="print"
                    icon={<PrinterOutlined />}
                    onClick={() => handleAction('print')}
                    disabled={loading.print}
                  >
                    {t('Print')}
                  </Menu.Item>
                  <Menu.Item
                    key="preview"
                    icon={<EyeOutlined />}
                    onClick={() => handleAction('preview')}
                    disabled={loading.preview || loadingUpdate}
                  >
                    {t('Preview')}
                  </Menu.Item>
                  <Menu.Item
                    key="csv"
                    icon={<FileExcelOutlined />}
                    onClick={() => handleAction('csv')}
                    disabled={loading.csv}
                  >
                    {t('Export CSV')}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                icon={<EllipsisOutlined />}
                size='small'
                loading={loading.print || loading.preview || loading.csv}
              />
            </Dropdown>
          )}
        </div>
        {displayError && (
          <Alert
            message={t("Validation Error")}
            description={displayError?.map((error, index) => <p key={index}>{error}</p>)}
            type="error"
            closable
            showIcon
            onClose={() => setDisplayError(null)}
            style={{ marginBottom: 16 }}
          />
        )}

        {Object.entries(entityDtoConfig)?.map(([fieldName, fieldProps]) => (
          !fieldsToHide?.includes(fieldName) && (
            <Form.Item
              key={fieldName}
              label={beautifyKey(fieldName)}
              name={fieldProps.type !== 'DateTime' ? convertKeyToCamelCase(fieldName) : undefined}
              rules={[{ required: fieldProps.isRequired, message: fieldProps.errorMessage }]}
            >
              {renderField(fieldName, fieldProps, updatedEntity, handleUpdatedEntityInputChange, handleBrowseEntity, userInfo)}
            </Form.Item>
          )
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingUpdate}>
            {t('Save changes')}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title={t(`Preview ${singularEntity}`)}
        visible={previewModalVisible}
        onCancel={() => {
          setPreviewModalVisible(false);
          setEnrichedEntity(null);
          setEnrichProgress(0);
        }}
        footer={null}
        width={800}
      >
        {enrichProgress < 100 ? (
          <div>
            <p>{t('Enriching data...')}</p>
            <Progress percent={enrichProgress} status="active" />
          </div>
        ) : enrichedEntity ? (
          <pre style={{ maxHeight: '70vh', overflow: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {JSON.stringify(enrichedEntity, null, 2)}
          </pre>
        ) : (
          <p>{t('No data available')}</p>
        )}
      </Modal>

      <Modal
        title={t(`Print ${singularEntity}`)}
        visible={printerModalVisible}
        onCancel={() => {
          setPrinterModalVisible(false);
          setDataReadyForPrint(false);
        }}
        footer={null}
        width="80%"
      >
        {enrichProgress < 100 ? (
          <div>
            <p>{t('Enriching data...')}</p>
            <Progress percent={enrichProgress} status="active" />
          </div>
        ) : dataReadyForPrint && printData ? (
          <Printer
            configEndpoint={`/print/configs/${singularEntity}`}
            generateEndpoint="/print/documents/generate"
            data={printData}
          />
        ) : (
          <p>{t('No data available')}</p>
        )}

      </Modal>
    </>
  );
};

export default EditForm;