import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Steps, Typography, message, theme, Affix, Descriptions, Tag, Statistic, Divider, Modal } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, ArrowRightOutlined, FileTextOutlined, FilePdfOutlined, ExperimentOutlined, FileSearchOutlined, PrinterOutlined, CalendarOutlined, UserOutlined, NumberOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { updateTestOrderBulkRequest, loadTestOrderBulkRequestDto, listTestOrderBulkRequests, getTestOrderBulkRequestDetails } from '../../actions/testOrderBulkRequestActions';
import { updateInvoiceReport } from '../../actions/invoiceReportActions';
import { loadInvoiceLineDto, listInvoiceLines, listInvoiceLinesByIds } from '../../actions/invoiceLineActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import Printer from '../../components/printer/Printer.component';
import TestOrderBulkRequestStep from './steps/TestOrderBulkRequestStep';
import SelectTestsStep from './steps/SelectTestsStep';
import ReviewInvoiceStep from './steps/ReviewInvoiceStep';
import { barcodeToBase64 } from '../../utils/functions';

const { Title, Paragraph } = Typography;
const { Step } = Steps;

export default function CreateTestOrderBulkRequestListScreen({ history }) {
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState({
    newTestOrderBulkRequest: null,
    updatedTestOrderBulkRequest: null,
    invoiceReportUpdatedEntity: null,
    testOrderBulkRequestId: null,
    invoiceReportId: null,
    invoiceLineIds: null,
    invoiceLines: [],
    editMode: 'Create',
    invoiceLoading: false,
    documentData: {},
    documentType: {},
    printerVisible: false,
    isBusy: false,
    current: 0,
    isStep2Saved: false
  });

  const [testOrderBulkRequestListFilter, setTestOrderBulkRequestListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'Barcode',
  });

  const testOrderBulkRequestState = useSelector((state) => state.testOrderBulkRequestUpdate);
  const invoiceReportState = useSelector((state) => state.invoiceReportUpdate);

  useEffect(() => {
    document.title = "TestOrder Bulk Request Create";
    resetState();
  }, []);

  useEffect(() => {
    if (currentState.updatedTestOrderBulkRequest?.id) {
      updateIds(currentState.updatedTestOrderBulkRequest);
    }
  }, [currentState.updatedTestOrderBulkRequest]);

  // listen to changes in the loading state of testOrderBulkRequestState
  useEffect(() => {
    if (testOrderBulkRequestState.loading) {
      setCurrentState(prevState => ({ ...prevState, isBusy: true }));
    } else {
      setCurrentState(prevState => ({ ...prevState, isBusy: false }));
    }
  }, [testOrderBulkRequestState.loading]);


  useEffect(() => {
    dispatch(listTestOrderBulkRequests(testOrderBulkRequestListFilter));
  }, [dispatch, testOrderBulkRequestListFilter]);

  const { token } = theme.useToken();

  const resetState = () => {
    setCurrentState(prevState => ({
      ...prevState,
      invoiceReportUpdatedEntity: null,
      updatedTestOrderBulkRequest: null,
      newTestOrderBulkRequest: null,
      testOrderBulkRequestId: null,
      editMode: 'Create',
      current: 0
    }));
  };

  const updateIds = (updatedRequest) => {
    setCurrentState(prevState => ({
      ...prevState,
      testOrderBulkRequestId: updatedRequest.id,
      invoiceReportId: updatedRequest.invoiceReportId
    }));
  };

  const handleStepChange = (direction) => {
    setCurrentState(prevState => ({
      ...prevState,
      current: direction === 'next' ? prevState.current + 1 : prevState.current - 1
    }));
  };

  const handleSaveTestInventoryLines = async () => {
    const { updatedTestOrderBulkRequest, testOrderBulkRequestId } = currentState;
    if (!updatedTestOrderBulkRequest || !testOrderBulkRequestId) {
      message.error('Error: No test order bulk request to update');
      return;
    }
  
    if (updatedTestOrderBulkRequest.testInventoryLineIds.length === 0) {
      message.warning('Please select at least one test before saving');
      return;
    }
  
    setCurrentState(prevState => ({ ...prevState, isBusy: true }));
  
    try {
      await dispatch(updateTestOrderBulkRequest(testOrderBulkRequestId, updatedTestOrderBulkRequest));
      message.success('Selected tests updated successfully', 5);
      
      // Fetch the updated TestOrderBulkRequest details
      const updatedDetails = await dispatch(getTestOrderBulkRequestDetails(testOrderBulkRequestId));
      
      // Update the state with the fresh data and set isStep2Saved to true
      setCurrentState(prevState => ({
        ...prevState,
        updatedTestOrderBulkRequest: updatedDetails,
        isBusy: false,
        isStep2Saved: true
      }));
    } catch (error) {
      message.error('Failed to update selected tests: ' + error.message);
      setCurrentState(prevState => ({ ...prevState, isBusy: false }));
    }
  };

  
  const handleSaveInvoiceChanges = () => {
    const { invoiceReportUpdatedEntity } = currentState;
    if (invoiceReportUpdatedEntity?.id) {
      dispatch(updateInvoiceReport(invoiceReportUpdatedEntity.id, invoiceReportUpdatedEntity));
      message.success('Invoice changes saved successfully!', 5);
    }
  };

  
  const handlePrintInvoice = () => {
    const { invoiceReportUpdatedEntity, invoiceLines } = currentState;
    if (invoiceReportUpdatedEntity?.id) {
      const data = {
        "invoiceHeader": {
          content: invoiceReportUpdatedEntity,
        },
        "invoiceSummary": {
          content: invoiceReportUpdatedEntity,
        },
        "invoiceLines": invoiceLines?.map(line => ({
          content: line,
          template: line.template || null
        }))
      };

      setCurrentState(prevState => ({
        ...prevState,
        documentData: data,
        documentType: 'invoice',
        printerVisible: true
      }));
    }
  };

  const handlePrintReceipt = async () => {
    const { invoiceReportUpdatedEntity, invoiceLines, updatedTestOrderBulkRequest } = currentState;

    const barcodeImage = await barcodeToBase64(updatedTestOrderBulkRequest?.barcode, {
      format: 'CODE128',
      width: 2,
      height: 50,
      fontSize: 12
    });

    if (invoiceReportUpdatedEntity?.id) {
      const data = {
        "receiptInfo": {
          content: invoiceReportUpdatedEntity,
        },
        "paymentInfo": {
          content: { ...invoiceReportUpdatedEntity, barcodeImage: barcodeImage },
        },
        "receiptLines": invoiceLines?.map(line => ({
          content: line,
          template: line.template || null
        }))
      };

      setCurrentState(prevState => ({
        ...prevState,
        documentData: data,
        documentType: 'receipt',
        printerVisible: true
      }));
    }
  };

  const steps = [
    {
      title: 'TestOrder Bulk Request',
      content: <TestOrderBulkRequestStep
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    },
    {
      title: 'Select Tests',
      content: <SelectTestsStep
        currentState={currentState}
        setCurrentState={setCurrentState}
        handleSaveTestInventoryLines={handleSaveTestInventoryLines}
      />
    },
    {
      title: 'Review Invoice and Pay',
      content: <ReviewInvoiceStep
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    }
  ];

  const items = steps?.map(item => ({ key: item.title, title: item.title }));

  const contentStyle = {
    padding: 24,
    textAlign: 'left',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={2}>TestOrder Bulk Request Management</Title>
      </Space>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
        Back to TestOrder Bulk Requests
      </Button>
      <br />
      <br />
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} lg={18}>
          <Card>
            <Steps current={currentState.current} items={items} />
            <div style={contentStyle}>{steps[currentState.current].content}</div>
            <div style={{ marginTop: 24, textAlign: 'right' }}>
              {currentState.current < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleStepChange('next')}
                  icon={<ArrowRightOutlined />}
                  disabled={
                    currentState.editMode === 'Create' || 
                    testOrderBulkRequestState.loading || 
                    invoiceReportState.loading ||
                    (currentState.current === 1 && !currentState.isStep2Saved) // Disable Next button in step 2 if not saved
                  }
                >
                  Next
                </Button>
              )}
              {currentState.current === steps.length - 1 && (
                <Button
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  onClick={resetState}
                >
                  Start Over
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={6}>
          <Affix offsetTop={80}>
            {currentState.current === 2 && (
              <Space direction="vertical" style={{ width: '100%' }}>
                <InvoiceSummaryCard currentState={currentState} />
                <ActionsCard
                  handlePrintInvoice={handlePrintInvoice}
                  handlePrintReceipt={handlePrintReceipt}
                  showSampleView={() => setCurrentState(prev => ({ ...prev, sampleViewVisible: true }))}
                  testOrderBulkRequestId={currentState.testOrderBulkRequestId}
                  history={history}
                />
              </Space>
            )}
          </Affix>
        </Col>
      </Row>
      <PrinterModal
        currentState={currentState}
        setCurrentState={setCurrentState}
        data={currentState.documentData}
        documentType={currentState.documentType}
      />
    </React.Fragment>
  );
}

  const InvoiceSummaryCard = ({ currentState }) => {
    const { invoiceReportUpdatedEntity, editMode } = currentState;
    const { loading: loadingInvoiceUpdate } = useSelector((state) => state.invoiceReportUpdate);
    const { loading: loadingUpdate } = useSelector((state) => state.testOrderBulkRequestUpdate);

    const { userInfo } = useSelector((state) => state.userLogin);

    if (!invoiceReportUpdatedEntity || editMode !== 'Edit') return null;

    return (
      <Card title="Invoice Summary" loading={loadingInvoiceUpdate || loadingUpdate}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <p>
              Invoice Number: <Tag color="blue">{invoiceReportUpdatedEntity.invoiceNumber}</Tag>
            </p>
          </Col>
          <Col xs={24} sm={12}>
            <Statistic title="Subtotal" value={invoiceReportUpdatedEntity.subtotal} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col xs={24} sm={12}>
            <Statistic title="Total Tax" value={invoiceReportUpdatedEntity.totalTax} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col xs={24} sm={12}>
            <Statistic title="Total" value={invoiceReportUpdatedEntity.total} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col xs={24} sm={12}>
            <Statistic title="Balance Due" value={invoiceReportUpdatedEntity.balanceDue} precision={2} prefix={userInfo?.currencySymbol} />
          </Col>
          <Col xs={24} sm={12}>
            <p>
              Status: <Tag color="blue">{invoiceReportUpdatedEntity.invoiceStatus || "N/A"}</Tag>
            </p>
          </Col>
        </Row>
      </Card>
    );
  };

const ActionsCard = ({ handlePrintInvoice, handlePrintReceipt, showSampleView, testOrderBulkRequestId, history }) => (
  <Card>
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button 
        type="text" 
        icon={<FileTextOutlined />} 
        onClick={() => history.push(`/testorderbulkrequests/edit/${testOrderBulkRequestId}`)}
        block
      >
        View Order Details
      </Button>
      <Button type="text" icon={<ExperimentOutlined />} onClick={showSampleView} block>Samples</Button>
      <Button type="text" icon={<PrinterOutlined />} onClick={handlePrintReceipt} block>Print Receipt</Button>
      <Button type="text" icon={<PrinterOutlined />} onClick={handlePrintInvoice} block>Print Invoice</Button>
    </Space>
  </Card>
);


const PrinterModal = ({ currentState, setCurrentState, data, documentType }) => (
  <Modal
    title={`Print ${documentType}`}
    visible={currentState.printerVisible}
    width="70%"
    style={{ top: 20 }}
    footer={null}
    onOk={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
    onCancel={() => setCurrentState(prevState => ({ ...prevState, printerVisible: false }))}
  >
    <Printer
      configEndpoint={`/print/configs/${documentType}`}
      generateEndpoint="/print/documents/generate"
      data={data}
    />
  </Modal>
);