import axios from 'axios';

import {
    TEST_RESULT_LIST_REQUEST,
    TEST_RESULT_LIST_SUCCESS,
    TEST_RESULT_LIST_FAIL,

    TEST_RESULT_DETAILS_REQUEST,
    TEST_RESULT_DETAILS_SUCCESS,
    TEST_RESULT_DETAILS_FAIL,

    TEST_RESULT_DELETE_REQUEST,
    TEST_RESULT_DELETE_SUCCESS,
    TEST_RESULT_DELETE_FAIL,

    TEST_RESULT_CREATE_REQUEST,
    TEST_RESULT_CREATE_SUCCESS,
    TEST_RESULT_CREATE_FAIL,

    TEST_RESULT_UPDATE_REQUEST,
    TEST_RESULT_UPDATE_SUCCESS,
    TEST_RESULT_UPDATE_FAIL,

    TEST_RESULT_REQUEST,
    TEST_RESULT_FAIL,
    TEST_RESULT_RESET,
    TEST_RESULT_SUCCESS,

    TEST_RESULT_DTO_REQUEST,
    TEST_RESULT_DTO_SUCCESS,
    TEST_RESULT_DTO_FAIL,
    TEST_RESULT_DTO_RESET,

    TEST_RESULT_LIST_BYIDS_REQUEST,
    TEST_RESULT_LIST_BYIDS_SUCCESS,
    TEST_RESULT_LIST_BYIDS_FAIL,
    TEST_RESULT_LIST_BYIDS_RESET
    

} from "../constants/testResultConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listTestResults = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/testResult/all', config);

        dispatch({
            type: TEST_RESULT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_RESULT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testResults",
        });
    }
}

// list testResults by ids
export const listTestResultsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/testResult/ids/all', ids, config);

        dispatch({
            type: TEST_RESULT_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_RESULT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testResults",
        });
    }
}


export const loadTestResultDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/testResult/testResultDto', config);

        dispatch({
            type: TEST_RESULT_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_RESULT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getTestResultDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_RESULT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/testResult/${_id}`, config);

        dispatch({
            type: TEST_RESULT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_RESULT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteTestResult = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_RESULT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/testResult/${_id}/`,
            config
        )

        dispatch({
            type: TEST_RESULT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: TEST_RESULT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTestResult = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_RESULT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/testResult/`,
            changes,
            config
        )

        dispatch({
            type: TEST_RESULT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: TEST_RESULT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}


const loadTestResultBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make testResult using a local testResult
export const makeTestResult = (file, testResultId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const testResult = await loadTestResultBase64(file);
    const testResult_input = JSON.stringify({ "testResult": testResult });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        testResult_input,
        config
    )
    try {
        dispatch({
            type: TEST_RESULT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": testResult }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: TEST_RESULT_SUCCESS
                });
                if (testResultId && response.data) {
                    dispatch(updateTestResult(testResultId, { testResult: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: TEST_RESULT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: TEST_RESULT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateTestResult = (_id, changes) => async (dispatch, getState) => {

    try {
        dispatch({
            type: TEST_RESULT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/testResult/${_id}/`,
            changes,
            config
        )


        dispatch({
            type: TEST_RESULT_UPDATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEST_RESULT_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}