import axios from 'axios';
import {
    SAMPLE_INVENTORY_LINE_LIST_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_FAIL,
    SAMPLE_INVENTORY_LINE_DETAILS_REQUEST,
    SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS,
    SAMPLE_INVENTORY_LINE_DETAILS_FAIL,
    SAMPLE_INVENTORY_LINE_DELETE_REQUEST,
    SAMPLE_INVENTORY_LINE_DELETE_SUCCESS,
    SAMPLE_INVENTORY_LINE_DELETE_FAIL,
    SAMPLE_INVENTORY_LINE_CREATE_REQUEST,
    SAMPLE_INVENTORY_LINE_CREATE_SUCCESS,
    SAMPLE_INVENTORY_LINE_CREATE_FAIL,
    SAMPLE_INVENTORY_LINE_UPDATE_REQUEST,
    SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS,
    SAMPLE_INVENTORY_LINE_UPDATE_FAIL,
    SAMPLE_INVENTORY_LINE_DTO_REQUEST,
    SAMPLE_INVENTORY_LINE_DTO_SUCCESS,
    SAMPLE_INVENTORY_LINE_DTO_FAIL,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS,
    SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL,
} from "../constants/sampleInventoryLinesConstants";

const getErrorMessages = (error) => {
    let errorMessages = [];
    if (error.response && error.response.data) {
        if (error.response.data.errors) {
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                errorMessages.push(`${key}: ${value[0]} `);
            }
        }
        if (error.response.data.detail) {
            errorMessages.push(`Detail: ${error.response.data.detail} `);
        }
        if (error.response.data.message) {
            errorMessages.push(`Message: ${error.response.data.message} `);
        }
    } else {
        errorMessages.push(error.message);
    }
    return errorMessages;
};

export const listSampleInventoryLines = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_INVENTORY_LINE_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params: filter
        }
        const { data } = await axios.get('/dotnet_api/v1/sampleInventoryLine/all', config);

        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_FAIL,
            payload: getErrorMessages(error)
        });
    }
}

export const listSampleInventoryLinesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/sampleInventoryLine/ids/all', ids, config);

        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL,
            payload: getErrorMessages(error)
        });
    }
}

export const listSampleInventoryLinesByBarcode = (barcode) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };
        const { data } = await axios.get(`/dotnet_api/v1/sampleInventoryLine/barcode/${barcode}`, config);

        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL,
            payload: getErrorMessages(error)
        });
    }
};

export const loadSampleInventoryLineResponseDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_INVENTORY_LINE_DTO_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/sampleInventoryLine/sampleInventoryLineDto', config);

        dispatch({
            type: SAMPLE_INVENTORY_LINE_DTO_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_DTO_FAIL,
            payload: getErrorMessages(error)
        });
    }
}

export const getSampleInventoryLineDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SAMPLE_INVENTORY_LINE_DETAILS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/sampleInventoryLine/${id}`, config);

        dispatch({
            type: SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_DETAILS_FAIL,
            payload: getErrorMessages(error)
        });
    }
}

export const deleteSampleInventoryLine = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/sampleInventoryLine/${id}/`,
            config
        )

        dispatch({
            type: SAMPLE_INVENTORY_LINE_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_DELETE_FAIL,
            payload: getErrorMessages(error)
        })
    }
}

export const createSampleInventoryLine = (sampleInventoryLine) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/dotnet_api/v1/sampleInventoryLine/`,
            sampleInventoryLine,
            config
        )

        dispatch({
            type: SAMPLE_INVENTORY_LINE_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_CREATE_FAIL,
            payload: getErrorMessages(error)
        })
    }
}

export const updateSampleInventoryLine = (id, sampleInventoryLine) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.patch(
            `/dotnet_api/v1/sampleInventoryLine/${id}/`,
            sampleInventoryLine,
            config
        )

        dispatch({
            type: SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SAMPLE_INVENTORY_LINE_UPDATE_FAIL,
            payload: getErrorMessages(error)
        })
    }
}