import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Statistic, Spin } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  createTestResultReport,
  updateTestResultReport,
  deleteTestResultReport,
  getTestResultReportDetails,
  listTestResultReports,
  listTestResultReportsByIds,
  loadTestResultReportDto,
} from '../../actions/testResultReportActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function TestResultReportsScreen({ history }) {
  useEffect(() => {
    document.title = "Test Result Reports";
  }, []);

  const dispatch = useDispatch();

  const [selectedTestResultReportId, setSelectedTestResultReportId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [testResultReportListFilter, setTestResultReportListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'OrderNumber',
  });

  const testResultReportList = useSelector((state) => state.testResultReportList);
  const { loading, error, testResultReports, currentPage, totalPages, totalCount, statistics } = testResultReportList;

  const testResultReportDto = useSelector((state) => state.testResultReportDto);
  const { testResultReportDto: testResultReportDtoConfig, loading: loadingDto } = testResultReportDto;

  useEffect(() => {
    dispatch(loadTestResultReportDto());
    dispatch(listTestResultReports(testResultReportListFilter));
  }, [dispatch, testResultReportListFilter]);

  const handleDelete = (id) => {
    dispatch(deleteTestResultReport(id));
    setTestResultReportListFilter({ query: '', page: 1, limit: 10, filter: 'OrderNumber' });
  };

  const handleEditForm = (id) => {
    setSelectedTestResultReportId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  const handleCancelForm = () => {
    setSelectedTestResultReportId(null);
    setShowDynamicForm(false);
  };

  const handleCreateTestResultReport = () => {
    setSelectedTestResultReportId(null);
    history.push('/testresultreports/create');
  };

  const getStatusCount = (status) => {
    return statistics?.statusCounts?.find(s => s.status === status)?.count || 0;
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h2>Test Result Report Management</h2>
      </Space>
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Total Reports" value={statistics?.totalCount || 0} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title="Completed Reports" value={getStatusCount('Completed')} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic title="Pending Reports" value={getStatusCount('Pending')} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic 
              title="Avg Tests Per Report" 
              value={statistics?.averageTestsPerReport || 0} 
              precision={2}
            />
          </Card>
        </Col>
      </Row>

      {(!showDynamicForm) && (
        <React.Fragment>
          <ListItemsDataTable
            loading={loading}
            dtoConfig={testResultReportDtoConfig}
            data={testResultReports}
            filter={testResultReportListFilter}
            onFilterChange={setTestResultReportListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={handleDelete}
            handleSaveRow={(updatedRow) => {
              dispatch(updateTestResultReport(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getTestResultReportDetails(updatedRow.id));
            }}
            handleEditForm={(id) => {
              history.push(`/testresultreports/edit/${id}`);
            }}
          />
        </React.Fragment>
      )}

      {showDynamicForm && (
        <React.Fragment>
          <Space direction="vertical" style={{ width: '100%', padding: '10px' }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Test Result Reports
            </Button>
          </Space>

          <DynamicFormSP
            currentEntityId={selectedTestResultReportId}
            selectedItems={[]}
            selectedEntityIds={[]}
            listingMode="allEntities"
            defaultEditMode={editMode}
            primarySearchProp="orderNumber"
            secondarySearchProp="patientName"
            singularEntity="testResultReport"
            pluralEntity="testResultReports"
            constantPrefix="TEST_RESULT_REPORT"
            loadDto={loadTestResultReportDto}
            createEntity={createTestResultReport}
            updateEntity={updateTestResultReport}
            deleteEntity={deleteTestResultReport}
            getEntityDetails={getTestResultReportDetails}
            listEntities={listTestResultReports}
            listEntitiesByIds={listTestResultReportsByIds}
            onSelectedItems={() => {}}
            onSelectedEntitiesChange={() => {}}
            onUpdatedEntityChange={() => {}}
            onNewEntityChange={() => {}}
            isParent={true}
            fieldsToHide={['Id']}
            summaryProps={['orderNumber', 'patientName', 'testName', 'resultDate', 'status']}
            enableCreate={true}
            enableSearchBar={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}