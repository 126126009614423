import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import DynamicFormSP from './DynamicFormSP.component';
import { fieldEntityActionConfig } from './utils/fieldEntityActionConfig';

const SingleEntitySelect = ({
  singularEntity,
  pluralEntity,
  constantPrefix,
  listEntities,
  onChange,
  value,
  primarySearchProp,
  secondarySearchProp,
  enableCreate = true,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [entityListFilter, setEntityListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: primarySearchProp,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const capitalizedSingularEntity = singularEntity.charAt(0).toUpperCase() + singularEntity.slice(1);
  const entityConfig = fieldEntityActionConfig[`${capitalizedSingularEntity}Ids`] || fieldEntityActionConfig[`${capitalizedSingularEntity}Id`];

  const entityList = useSelector((state) => state[`${singularEntity}List`]);
  const { loading: loadingList, [pluralEntity]: entities } = entityList;

  useEffect(() => {
    dispatch(listEntities(entityListFilter));
  }, [dispatch, entityListFilter]);

  const debouncedSearch = debounce((value) => {
    setEntityListFilter((prevFilter) => ({
      ...prevFilter,
      query: value,
    }));
  }, 300);

  const showModal = () => {
    setIsDropdownOpen(false);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = (newEntity) => {
    dispatch(entityConfig.createEntity(newEntity));
    setIsModalVisible(false);
    dispatch(listEntities(entityListFilter));
  };

  const dropdownRender = (menu) => (
    <div>
      {menu}
      {enableCreate && (
      <div
        style={{
          padding: '8px',
          borderTop: '1px solid #e8e8e8',
        }}
      >
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={showModal}
          style={{ width: '100%', textAlign: 'left' }}
        >
          {t(`Create New ${singularEntity}`)}
        </Button>
      </div>
      )}
    </div>
  );

  return (
    <>
      {entities && (
        <Select
          value={value}
          onChange={onChange}
          loading={loadingList}
          style={{ width: '100%' }}
          allowClear
          showSearch
          onSearch={debouncedSearch}
          placeholder={`Search ${singularEntity}`}
          optionFilterProp="children"
          dropdownRender={dropdownRender}
          open={isDropdownOpen}
          onDropdownVisibleChange={setIsDropdownOpen}
        >
          {entities.length > 0 ? (
            entities?.map((entity) => (
              <Select.Option key={entity.id} value={entity.id}>
                {entity[primarySearchProp]} | {entity[secondarySearchProp]}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled>{t("None of any")} {pluralEntity} {t("found")}</Select.Option>
          )}
        </Select>
      )}
      <Modal
        title={t(`Create New ${singularEntity}`)}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        {entityConfig && (
          <DynamicFormSP
            singularEntity={singularEntity}
            pluralEntity={pluralEntity}
            constantPrefix={constantPrefix}
            loadDto={entityConfig.loadDto}
            createEntity={handleCreate}
            updateEntity={entityConfig.updateEntity}
            deleteEntity={entityConfig.deleteEntity}
            getEntityDetails={entityConfig.getEntityDetails}
            listEntities={entityConfig.listEntities}
            listEntitiesByIds={entityConfig.listEntitiesByIds}
            defaultEditMode="Create"
            fieldsToHide={['Id']}
            isParent={false}
            enableCreate={true}
            enableSearchBar={false}
            enableActionButtons={false}
          />
        )}
      </Modal>
    </>
  );
};

export default SingleEntitySelect;