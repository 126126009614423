import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Card, Typography, Space, Button, Select, theme } from 'antd';
import { CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ThemeContext } from '../index';
import logo from '../assets/images/logo.svg';

const { Title, Text, Paragraph } = Typography;
const { useToken } = theme;

function ResetPasswordSentScreen() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { themeConfig } = useContext(ThemeContext);
    const { token } = useToken();

    useEffect(() => {
        document.title = t('resetPasswordSent.pageTitle');
    }, [t]);

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
    };

    const languageOptions = [
        { value: 'en', label: '🇬🇧 English' },
        { value: 'fr', label: '🇫🇷 Français' },
    ];

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '100vh',
            background: token.colorBgLayout,
        }}>
            <Card 
                style={{ 
                    width: '100%',
                    maxWidth: 400,
                    boxShadow: `0 4px 8px ${token.colorBgElevated}`,
                    borderRadius: '8px',
                    background: token.colorBgContainer,
                }}
                cover={
                    <div style={{ 
                        background: 'transparent',
                        marginTop: '50px', 
                        marginBottom: '16px',
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                    }}>
                        <img
                            src={logo}
                            alt="logo"
                            style={{
                                width: '100%',
                                maxWidth: '200px',
                                filter: themeConfig.algorithm === theme.darkAlgorithm ? 'invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)' : 'none',
                            }}
                        />
                    </div>
                }
            >
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={3} style={{ textAlign: 'center', margin: 0, color: token.colorTextHeading }}>
                        {t('resetPasswordSent.title')}
                    </Title>

                    <div style={{ textAlign: 'center' }}>
                        <CheckCircleOutlined style={{ fontSize: 50, color: token.colorSuccess }} />
                    </div>

                    <Title level={4} style={{ textAlign: 'center', color: token.colorTextHeading }}>
                        {t('resetPasswordSent.linkSentSuccessfully')}
                    </Title>

                    <Paragraph>
                        {t('resetPasswordSent.accountExistsMessage')}
                    </Paragraph>

                    <Paragraph>
                        {t('resetPasswordSent.linkExpiryMessage')}
                    </Paragraph>

                    <Button 
                        icon={<ArrowLeftOutlined />} 
                        onClick={() => history.goBack()}
                        style={{ marginTop: 16 }}
                    >
                        {t('resetPasswordSent.goBack')}
                    </Button>

                    <Select
                        defaultValue={i18n.language}
                        style={{ width: 120, marginTop: 16 }}
                        onChange={handleLanguageChange}
                        options={languageOptions}
                    />
                </Space>
            </Card>
        </div>
    );
}

export default ResetPasswordSentScreen;