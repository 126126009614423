export const CONSENT_LIST_REQUEST = 'CONSENT_LIST_REQUEST'
export const CONSENT_LIST_SUCCESS = 'CONSENT_LIST_SUCCESS'
export const CONSENT_LIST_FAIL = 'CONSENT_LIST_FAIL'


export const CONSENT_DETAILS_REQUEST = 'CONSENT_DETAILS_REQUEST'
export const CONSENT_DETAILS_SUCCESS = 'CONSENT_DETAILS_SUCCESS'
export const CONSENT_DETAILS_FAIL = 'CONSENT_DETAILS_FAIL'


export const CONSENT_DELETE_REQUEST = 'CONSENT_DELETE_REQUEST'
export const CONSENT_DELETE_SUCCESS = 'CONSENT_DELETE_SUCCESS'
export const CONSENT_DELETE_FAIL = 'CONSENT_DELETE_FAIL'

export const CONSENT_CREATE_REQUEST = 'CONSENT_CREATE_REQUEST'
export const CONSENT_CREATE_SUCCESS = 'CONSENT_CREATE_SUCCESS'
export const CONSENT_CREATE_FAIL = 'CONSENT_CREATE_FAIL'
export const CONSENT_CREATE_RESET = 'CONSENT_CREATE_RESET'

export const CONSENT_UPDATE_REQUEST = 'CONSENT_UPDATE_REQUEST'
export const CONSENT_UPDATE_SUCCESS = 'CONSENT_UPDATE_SUCCESS'
export const CONSENT_UPDATE_FAIL = 'CONSENT_UPDATE_FAIL'
export const CONSENT_UPDATE_RESET = 'CONSENT_UPDATE_RESET'

export const CONSENT_REQUEST = 'CONSENT_REQUEST'
export const CONSENT_SUCCESS = 'CONSENT_SUCCESS'
export const CONSENT_FAIL = 'CONSENT_FAIL'
export const CONSENT_RESET = 'CONSENT_RESET'

export const CONSENT_COUNT_REQUEST = "CONSENT_COUNT_REQUEST";
export const CONSENT_COUNT_SUCCESS = "CONSENT_COUNT_SUCCESS";
export const CONSENT_COUNT_FAIL = "CONSENT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const CONSENT_DTO_REQUEST = 'CONSENT_DTO_REQUEST';
export const CONSENT_DTO_SUCCESS = 'CONSENT_DTO_SUCCESS';
export const CONSENT_DTO_FAIL = 'CONSENT_DTO_FAIL';
export const CONSENT_DTO_RESET = 'CONSENT_DTO_RESET';

export const CONSENT_LIST_BYIDS_REQUEST = 'CONSENT_LIST_BYIDS_REQUEST';
export const CONSENT_LIST_BYIDS_SUCCESS = 'CONSENT_LIST_BYIDS_SUCCESS';
export const CONSENT_LIST_BYIDS_FAIL = 'CONSENT_LIST_BYIDS_FAIL';
export const CONSENT_LIST_BYIDS_RESET = 'CONSENT_LIST_BYIDS_RESET';