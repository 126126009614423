import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplantInstances, createImplantInstance, updateImplantInstance, deleteImplantInstance, getImplantInstanceDetails } from '../../actions/implantInstanceActions';
// constants 
import { IMPLANT_INSTANCE_CREATE_RESET, IMPLANT_INSTANCE_UPDATE_RESET } from '../../constants/implantInstanceConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';
import SelectOther from '../../components/selectOther/SelectOther.component';


export default function ImplantInstanceCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();


    // reset the state of the implantInstanceCreate and implantInstanceUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_INSTANCE_CREATE_RESET });
        dispatch({ type: IMPLANT_INSTANCE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/dotnet_api/v1/implantInstance/all?page=1&limit=2&filter=Email&query=gmail
    const [implantInstanceListFilter, setimplantInstanceListFilter] = useState({ query: '', page: 1, filter: 'serialNumber' });

    // get implantInstance list from redux store
    const implantInstanceList = useSelector((state) => state.implantInstanceList);
    const { loading, error, implantInstances, currentPage, totalPages } = implantInstanceList;

    // get implantInstance create from redux store
    const implantInstanceCreate = useSelector((state) => state.implantInstanceCreate);
    const { success: successCreate, error: implantInstanceCreateErrors } = implantInstanceCreate;

    // get implantInstance update from redux store
    const implantInstanceUpdate = useSelector((state) => state.implantInstanceUpdate);
    const { success: successUpdate, loading: implantInstanceUpdateProcessing } = implantInstanceUpdate;

    // get implantInstance delete from redux store
    const implantInstanceDelete = useSelector((state) => state.implantInstanceDelete);
    const { success: successDelete } = implantInstanceDelete;

    // get implantInstance details from redux store
    const implantInstanceDetails = useSelector((state) => state.implantInstanceDetails);
    const { implantInstance: implantInstanceDet, loading: implantInstanceDetailsLoading } = implantInstanceDetails;

    // newImplantInstance state
    const [newImplantInstance, setNewImplantInstance] = useState({});
    const [updatedImplantInstance, setUpdatedImplantInstance] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isALead, setIsALead] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantInstances(implantInstanceListFilter));
    }, [dispatch, implantInstanceListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantInstanceListFilter({ ...implantInstanceListFilter, filter: selectedFilter });
    };

    // get all implantInstances
    useEffect(() => {
        dispatch(listImplantInstances({}));
    }, [dispatch]);

    // listen to implantInstanceDet 
    useEffect(() => {
        if (implantInstanceDet) {
            // use spread operator to copy the implantInstanceDet object
            setUpdatedImplantInstance({ ...implantInstanceDet });
        }
    }, [implantInstanceDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplantInstance state
            setNewImplantInstance({
                patientName: '',
                patientNHSNumber: '',
                isFirstImplant: false,
                interventionCategory: '',
                modelName: '',
                modelNumber: '',
                barcode: '',
                manufacturer: '',
                modelCategory: '',
                type: '',
                eventDate: new Date().toISOString(),
                expiryDate: new Date().toISOString(),
                serialNumber: '',
                status: '',
                eventType: '',
                eventDetails: '',
                practitionerDetails: '',
                notes: '',
                generatorImplantSite: '',
                maxSystemCapability: '',
                leadChamber: '',
                pacingSite: '',
                bradyProgrammedMode: '',
                bradyProgrammedLowerRate: 0,
                activeHighVoltageTherapy: false,
                tachyTherapyZoneRate: 0,
                patientReceivedAppropriateHVTherapy: false,
                patientReceivedInappropriateHVTherapy: false,
                abandonedHardware: false,
                mrConditional: false,
            });
        }
    }, [editMode]);


    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantInstance?')) {
            dispatch(deleteImplantInstance(id)).then(() => {
                dispatch(listImplantInstances({}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantInstance = (id) => {
        dispatch(getImplantInstanceDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateImplantInstance = () => {
        dispatch(createImplantInstance(newImplantInstance)).then(() => {
            setUpdatedImplantInstance(newImplantInstance);
            setEditMode(true);
            dispatch(listImplantInstances({}));
        }
        );
    };

    // handle update
    const handleUpdateImplantInstance = () => {
        dispatch(updateImplantInstance(updatedImplantInstance.id, updatedImplantInstance)).then(() => {

            setUpdatedImplantInstance(updatedImplantInstance);
            setEditMode(true);
            dispatch(getImplantInstanceDetails(updatedImplantInstance.id));
        }
        );
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Implant Instance Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the Implant Records. You can create, update and delete implantInstances in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {implantInstanceCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {implantInstanceCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {implantInstanceCreateErrors?.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-implantInstances"
                                        label="Search Records"
                                        name="search-implantInstances"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                    <Select
                                        id="filter-implant-histories"
                                        label="Filter by"
                                        name="filter-implant-histories"
                                        onChange={handleFilterChange}
                                    >
                                        <Select.Option value="SerialNumber">Serial Number</Select.Option>
                                        <Select.Option value="Patientname">Patient full name</Select.Option>
                                        <Select.Option value="PatientNHSNumber">Patient NHS Number</Select.Option>
                                        <Select.Option value="Barcode">Barcode/GTIN</Select.Option>
                                        <Select.Option value="ModelName">Model Name</Select.Option>
                                        <Select.Option value="ModelNumber">Model Number</Select.Option>
                                        <Select.Option value="Manufacturer">Manufacturer</Select.Option>

                                        <Select.Option value="Type">Type</Select.Option>
                                    </Select>

                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {implantInstances && implantInstances.length > 0 ? (
                                            implantInstances?.map((implantInstance) => (
                                                <Details key={implantInstance.id}>
                                                    <Details.Summary>
                                                        {implantInstance.serialNumber}
                                                    </Details.Summary>

                                                    <Details.Text>
                                                        <p><strong>Date Implanted: </strong>{implantInstance.eventDate}</p>
                                                        <p><strong>Expiry Date:</strong> {implantInstance.expiryDate}</p>
                                                        <p><strong>Model Number: </strong>{implantInstance.modelNumber}</p>
                                                        <p><strong>Barcode: </strong>{implantInstance.barcode}</p>
                                                        <p><strong>Serial Number: </strong>{implantInstance.serialNumber}</p>
                                                        <p>
                                                            <strong>Status: </strong><Tag>{implantInstance.status}</Tag>
                                                        </p>
                                                        <p>
                                                            <strong>Abandoned Hardware:</strong> <Tag color={implantInstance.abandonedHardware ? 'red' : 'green'}>{implantInstance.abandonedHardware ? 'Yes' : 'No'}</Tag>
                                                        </p>
                                                        <p>
                                                            <strong>MR Conditional: </strong><Tag color={implantInstance.mrConditional ? 'red' : 'green'}>{implantInstance.mrConditional ? 'Yes' : 'No'}</Tag>
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={implantInstanceDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditImplantInstance(implantInstance.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(implantInstance.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}



                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Implant
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an implantInstance. If you want to create a new implantInstance, please click the "Create ImplantInstance" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Implant
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>

                                    <Card.Description>
                                        <Checkboxes name="checkboxes">

                                            <Checkboxes.Box
                                                checked={updatedImplantInstance?.isFirstImplant}
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, isFirstImplant: e.target.checked })}
                                            >
                                                First Implant?
                                            </Checkboxes.Box>
                                        </Checkboxes>

                                        <Input
                                            id="updateEventDateTime"
                                            label="Implantation Date"
                                            name="eventDateTime"
                                            type="datetime-local"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventDate: new Date(e.target.value).toISOString() })}
                                            value={updatedImplantInstance?.eventDate ? new Date(updatedImplantInstance.eventDate).toISOString().slice(0, 16) : ''}
                                            required
                                        />

                                        <Input
                                            id="patientName"
                                            label="Patient Name"
                                            name="patientName"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, patientName: e.target.value })}
                                            value={updatedImplantInstance?.patientName}
                                            required
                                        />
                                        <Input
                                            id="patientNHSNumber"
                                            label="Patient NHS Number"
                                            name="patientNHSNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, patientNHSNumber: e.target.value })}
                                            value={updatedImplantInstance?.patientNHSNumber}
                                            required
                                        />
                                        <Select
                                            id="interventionCategory"
                                            label="Intervention Category"
                                            name="interventionCategory"
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setUpdatedImplantInstance({ ...updatedImplantInstance, interventionCategory: selectedValue });
                                            }}
                                            value={updatedImplantInstance?.interventionCategory}
                                            required
                                        >
                                            <Select.Option value="">Select an intervention category</Select.Option>
                                            <Select.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                            <Select.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</Select.Option>
                                            <Select.Option value="Generator change only">Generator change only</Select.Option>
                                            <Select.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</Select.Option>
                                            <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>
                                            <Select.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</Select.Option>
                                            <Select.Option value="System explant">System explant</Select.Option>
                                            <Select.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</Select.Option>
                                            <Select.Option value="Lead intervention only">Lead intervention only</Select.Option>
                                            <Select.Option value="Monitor procedure only">Monitor procedure only</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>
                                        

                                        <Input
                                            id="modelName"
                                            label="Model Name"
                                            name="modelName"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelName: e.target.value })}
                                            value={updatedImplantInstance?.modelName}
                                            required
                                        />
                                        <Input
                                            id="modelNumber"
                                            label="Model Number"
                                            name="modelNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelNumber: e.target.value })}
                                            value={updatedImplantInstance?.modelNumber}
                                            required
                                        />
                                        <Input
                                            id="barcode"
                                            label="Barcode/GTIN"
                                            name="barcode"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, barcode: e.target.value })}
                                            value={updatedImplantInstance?.barcode}
                                            required
                                        />

                                        <Input
                                            id="manufacturer"
                                            label="Manufacturer"
                                            name="manufacturer"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, manufacturer: e.target.value })}
                                            value={updatedImplantInstance?.manufacturer}
                                            required
                                        />
                                        <Select
                                            id="modelCategory"
                                            label="Model Category"
                                            name="modelCategory"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelCategory: e.target.value })}
                                            value={updatedImplantInstance?.modelCategory}
                                            required
                                        >
                                            <Select.Option value="">Select a model category</Select.Option>

                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Generator">Generator</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                        </Select>

                                        <Select
                                            id="modelType"
                                            label="Model Type"
                                            name="modelType"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, type: e.target.value })}
                                            value={updatedImplantInstance?.type}
                                            required
                                        >
                                            <Select.Option value="">Select a model type</Select.Option>
                                            <Select.Option value="LV lead">LV lead</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                            <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                            <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                            <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                            <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                            <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                            <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                            <Select.Option value="His Lead">His Lead</Select.Option>

                                        </Select>
                                        <Select
                                            id="eventType"
                                            label="Event Type"
                                            name="eventType"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventType: e.target.value })}
                                            value={updatedImplantInstance?.eventType}
                                            required
                                        >
                                            <Select.Option value="">Select an event type</Select.Option>
                                            <Select.Option value="Implant">Implant</Select.Option>
                                            <Select.Option value="Revision">Revision</Select.Option>
                                            <Select.Option value="Removal">Removal</Select.Option>
                                            <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                            <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                            <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                            <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                            <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                            <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                            <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>

                                        </Select>


                                        <Input
                                            id="expiryDate"
                                            label="Expiry Date"
                                            name="expiryDate"
                                            type="datetime-local"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, expiryDate: new Date(e.target.value).toISOString() })}
                                            value={updatedImplantInstance?.expiryDate ? new Date(updatedImplantInstance.expiryDate).toISOString().slice(0, 16) : ''}
                                            required
                                        />
                                        <Input
                                            id="serialNumber"
                                            label="Serial Number"
                                            name="serialNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, serialNumber: e.target.value })}
                                            value={updatedImplantInstance?.serialNumber}
                                            required
                                        />
                                        <Select
                                            id="generatorImplantSite"
                                            label="Generator Implant Site"
                                            name="generatorImplantSite"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, generatorImplantSite: e.target.value })}
                                            value={updatedImplantInstance?.generatorImplantSite}
                                        >
                                            <Select.Option value="">Select a generator implant site</Select.Option>
                                            <Select.Option value="Chest wall - subcutaneous">Chest wall - subcutaneous</Select.Option>
                                            <Select.Option value="Chest wall - submuscular">Chest wall - submuscular</Select.Option>
                                            <Select.Option value="Abdominal - subcutaneous">Abdominal - subcutaneous</Select.Option>
                                            <Select.Option value="Abdominal - submuscular">Abdominal - submuscular</Select.Option>
                                            <Select.Option value="Intravascular - endocardial">Intravascular - endocardial</Select.Option>
                                            <Select.Option value="Intravascular - pulmonary artery">Intravascular - pulmonary artery</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>
                                        <Select
                                            id="maxSystemCapability"
                                            label="Max System Capability"
                                            name="maxSystemCapability"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, maxSystemCapability: e.target.value })}
                                            value={updatedImplantInstance?.maxSystemCapability}
                                        >
                                            <Select.Option value="">Select a max system capability</Select.Option>
                                            <Select.Option value="PM-AAI(R)">PM-AAI(R)</Select.Option>
                                            <Select.Option value="CRTD">CRTD</Select.Option>
                                            <Select.Option value="CRTP">CRTP</Select.Option>
                                            <Select.Option value="PM-DDD(R)">PM-DDD(R)</Select.Option>
                                            <Select.Option value="ICD-DR">ICD-DR</Select.Option>
                                            <Select.Option value="ICD-SQ">ICD-SQ</Select.Option>
                                            <Select.Option value="ICD-VR">ICD-VR</Select.Option>
                                            <Select.Option value="Monitor">Monitor</Select.Option>
                                            <Select.Option value="None">None</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                            <Select.Option value="PM-VVI(R)">PM-VVI(R)</Select.Option>
                                            <Select.Option value="PM-VDD(R) - single lead">PM-VDD(R) - single lead</Select.Option>
                                            <Select.Option value="ICD -VDD(R) - single lead">ICD -VDD(R) - single lead</Select.Option>
                                        </Select>
                                        <Select
                                            id="leadChamber"
                                            label="Lead Chamber"
                                            name="leadChamber"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, leadChamber: e.target.value })}
                                            value={updatedImplantInstance?.leadChamber}
                                        >
                                            <Select.Option value="">Select a lead chamber</Select.Option>
                                            <Select.Option value="Right Atrium">Right Atrium</Select.Option>
                                            <Select.Option value="Right Ventricle">Right Ventricle</Select.Option>
                                            <Select.Option value="Left Ventricle">Left Ventricle</Select.Option>
                                            <Select.Option value="NA">NA</Select.Option>
                                        </Select>
                                        <Select
                                            id="pacingSite"
                                            label="Pacing Site"
                                            name="pacingSite"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, pacingSite: e.target.value })}
                                            value={updatedImplantInstance?.pacingSite}
                                        >
                                            <Select.Option value="">Select a pacing site</Select.Option>
                                            <Select.Option value="Middle cardiac vein">Middle cardiac vein</Select.Option>
                                            <Select.Option value="CS - Posterior branch">CS - Posterior branch</Select.Option>
                                            <Select.Option value="CS - Posterolateral branch">CS - Posterolateral branch</Select.Option>
                                            <Select.Option value="CS - Lateral branch">CS - Lateral branch</Select.Option>
                                            <Select.Option value="CS - Anterolateral branch">CS - Anterolateral branch</Select.Option>
                                            <Select.Option value="CS - Anterior branch">CS - Anterior branch</Select.Option>
                                            <Select.Option value="Greater cardiac vein">Greater cardiac vein</Select.Option>
                                            <Select.Option value="LV epicardial - via pericardium">LV epicardial - via pericardium</Select.Option>
                                            <Select.Option value="LV endocardial - trans-septal">LV endocardial - trans-septal</Select.Option>
                                            <Select.Option value="LV other">LV other</Select.Option>
                                        </Select>
                                        <Select
                                            id="status"
                                            label="Status"
                                            name="status"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, status: e.target.value })}
                                            value={updatedImplantInstance?.status}
                                            required
                                        >
                                            <Select.Option value="">Select a status</Select.Option>
                                            <Select.Option value="Implanted, connected">Implanted, connected</Select.Option>
                                            <Select.Option value="Explanted">Explanted</Select.Option>
                                            <Select.Option value="Partial extraction">Partial extraction</Select.Option>
                                            <Select.Option value="Complete extraction">Complete extraction</Select.Option>
                                            <Select.Option value="Implanted, abandoned">Implanted, abandoned</Select.Option>

                                        </Select>



                                        <Checkboxes name="checkboxes">
                                            
                                            <Checkboxes.Box
                                                checked={updatedImplantInstance?.mrConditional}
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, mrConditional: e.target.checked })}
                                            >
                                                MR Conditional
                                            </Checkboxes.Box>
                                            
                                            <Checkboxes.Box
                                                checked={updatedImplantInstance?.abandonedHardware}
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, abandonedHardware: e.target.checked })}
                                            >
                                                Abandoned Hardware
                                            </Checkboxes.Box>
                                        </Checkboxes>

                                        <Textarea
                                            id="eventDetails"
                                            label="Event Details"
                                            name="eventDetails"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventDetails: e.target.value })}
                                            value={updatedImplantInstance?.eventDetails}
                                        />
                                        <Textarea
                                            id="PractitionerDetails"
                                            label="Practitioner Details"
                                            name="PractitionerDetails"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, practitionerDetails: e.target.value })}
                                            value={updatedImplantInstance?.practitionerDetails}
                                        />
                                        <Textarea
                                            id="Notes"
                                            label="Notes"
                                            name="Notes"
                                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, notes: e.target.value })}
                                            value={updatedImplantInstance?.notes}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The implantInstance was created successfully." : "The implantInstance was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={implantInstanceUpdateProcessing}
                                        onClick={handleUpdateImplantInstance}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Record
                                    </Card.Heading>
                                    <Card.Description>
                                        <Checkboxes name="checkboxes">

                                            <Checkboxes.Box
                                                checked={newImplantInstance?.isFirstImplant}
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, isFirstImplant: e.target.checked })}
                                            >
                                                First Implant?
                                            </Checkboxes.Box>
                                        </Checkboxes>

                                        <Input
                                            id="eventDate"
                                            label="Implantation Date"
                                            name="eventDate"
                                            type="datetime-local"
                                            onChange={(e) => setUpdatedImplantInstance({ ...newImplantInstance, eventDate: new Date(e.target.value).toISOString() })}
                                            value={newImplantInstance?.eventDate ? new Date(newImplantInstance.eventDate).toISOString().slice(0, 16) : ''}
                                            required
                                        />

                                        <Input
                                            id="create-patientName"
                                            label="Patient Name"
                                            name="createPatientName"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, patientName: e.target.value })}
                                            value={newImplantInstance?.patientName}
                                            required
                                        />
                                        <Input
                                            id="patientNHSNumber"
                                            label="Patient NHS Number"
                                            name="patientNHSNumber"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, patientNHSNumber: e.target.value })}
                                            value={newImplantInstance?.patientNHSNumber}
                                            required
                                        />
                                        <Select
                                            id="interventionCategory"
                                            label="Intervention Category"
                                            name="interventionCategory"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, interventionCategory: e.target.value })}
                                            value={newImplantInstance?.interventionCategory}
                                            required
                                        >
                                            <Select.Option value="">Select an intervention category</Select.Option>
                                            <Select.Option value="First ever pacemaker/ICD system">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                            <Select.Option value="Generator change only">First ever pacemaker/ICD system</Select.Option>
                                            <Select.Option value="New generator and lead(s) revision/replacement">Generator change only</Select.Option>
                                            <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">New generator and lead(s) revision/replacement</Select.Option>
                                            <Select.Option value="Downgrade - decreased functionality">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>

                                            <Select.Option value="System explant">Downgrade - decreased functionality</Select.Option>
                                            <Select.Option value="Wound revision/generator resite/haematoma">System explant</Select.Option>
                                            <Select.Option value="Lead intervention only">Wound revision/generator resite/haematoma</Select.Option>
                                            <Select.Option value="Monitor procedure only">Lead intervention only</Select.Option>
                                            <Select.Option value="Other">Monitor procedure only</Select.Option>
                                        </Select>
                                        <Input
                                            id="modelName"
                                            label="Model Name"
                                            name="modelName"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelName: e.target.value })}
                                            value={newImplantInstance?.modelName}
                                            required
                                        />
                                        <Input
                                            id="modelNumber"
                                            label="Model Number"
                                            name="modelNumber"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelNumber: e.target.value })}
                                            value={newImplantInstance?.modelNumber}
                                            required
                                        />
                                        <Input
                                            id="barcode"
                                            label="Barcode/GTIN"
                                            name="barcode"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, barcode: e.target.value })}
                                            value={newImplantInstance?.barcode}
                                            required
                                        />
                                        <Input
                                            id="manufacturer"
                                            label="Manufacturer"
                                            name="manufacturer"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, manufacturer: e.target.value })}
                                            value={newImplantInstance?.manufacturer}
                                            required
                                        />
                                        <Select
                                            id="modelCategory"
                                            label="Model Category"
                                            name="modelCategory"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelCategory: e.target.value })}
                                            value={newImplantInstance?.modelCategory}
                                            required
                                        >
                                            <Select.Option value="">Select a model category</Select.Option>

                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Generator">Generator</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                        </Select>
                                        <Select
                                            id="modelType"
                                            label="Model Type"
                                            name="modelType"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, type: e.target.value })}
                                            value={newImplantInstance?.type}
                                            required
                                        >
                                            <Select.Option value="">Select a model type</Select.Option>
                                            <Select.Option value="LV lead">LV lead</Select.Option>
                                            <Select.Option value="Lead">Lead</Select.Option>
                                            <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                            <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                            <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                            <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                            <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                            <Select.Option value="Accessory">Accessory</Select.Option>
                                            <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                            <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                            <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                            <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                            <Select.Option value="His Lead">His Lead</Select.Option>
                                        </Select>
                                        <Select
                                            id="eventType"
                                            label="Event Type"
                                            name="eventType"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, eventType: e.target.value })}
                                            value={newImplantInstance?.eventType}
                                            required
                                        >
                                            <Select.Option value="">Select an event type</Select.Option>
                                            <Select.Option value="Implant">Implant</Select.Option>
                                            <Select.Option value="Revision">Revision</Select.Option>
                                            <Select.Option value="Removal">Removal</Select.Option>
                                            <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                            <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                            <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                            <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Lead Replacement</Select.Option>
                                            <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                            <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                            <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>

                                        <Input
                                            id="expiryDate"
                                            label="Expiry Date"
                                            name="expiryDate"
                                            type="datetime-local"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, expiryDate: new Date(e.target.value).toISOString() })}
                                            value={newImplantInstance?.expiryDate ? new Date(newImplantInstance.expiryDate).toISOString().slice(0, 16) : ''}
                                            required
                                        />
                                        <Input
                                            id="serialNumber"
                                            label="Serial Number"
                                            name="serialNumber"
                                            type="text"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, serialNumber: e.target.value })}
                                            value={newImplantInstance?.serialNumber}
                                            required
                                        />
                                        <Select
                                            id="generatorImplantSite"
                                            label="Generator Implant Site"
                                            name="generatorImplantSite"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, generatorImplantSite: e.target.value })}
                                            value={newImplantInstance?.generatorImplantSite}
                                        >
                                            <Select.Option value="">Select a generator implant site</Select.Option>
                                            <Select.Option value="Chest wall - subcutaneous">Chest wall - subcutaneous</Select.Option>
                                            <Select.Option value="Chest wall - submuscular">Chest wall - submuscular</Select.Option>
                                            <Select.Option value="Abdominal - subcutaneous">Abdominal - subcutaneous</Select.Option>
                                            <Select.Option value="Abdominal - submuscular">Abdominal - submuscular</Select.Option>
                                            <Select.Option value="Intravascular - endocardial">Intravascular - endocardial</Select.Option>
                                            <Select.Option value="Intravascular - pulmonary artery">Intravascular - pulmonary artery</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>
                                        <Select
                                            id="maxSystemCapability"
                                            label="Max System Capability"
                                            name="maxSystemCapability"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, maxSystemCapability: e.target.value })}
                                            value={newImplantInstance?.maxSystemCapability}
                                        >
                                            <Select.Option value="">Select a max system capability</Select.Option>
                                            <Select.Option value="PM-AAI(R)">PM-AAI(R)</Select.Option>
                                            <Select.Option value="CRTD">CRTD</Select.Option>
                                            <Select.Option value="CRTP">CRTP</Select.Option>
                                            <Select.Option value="PM-DDD(R)">PM-DDD(R)</Select.Option>
                                            <Select.Option value="ICD-DR">ICD-DR</Select.Option>
                                            <Select.Option value="ICD-SQ">ICD-SQ</Select.Option>
                                            <Select.Option value="ICD-VR">ICD-VR</Select.Option>
                                            <Select.Option value="Monitor">Monitor</Select.Option>
                                            <Select.Option value="None">None</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                            <Select.Option value="PM-VVI(R)">PM-VVI(R)</Select.Option>
                                            <Select.Option value="PM-VDD(R) - single lead">PM-VDD(R) - single lead</Select.Option>
                                            <Select.Option value="ICD -VDD(R) - single lead">ICD -VDD(R) - single lead</Select.Option>
                                        </Select>
                                        <Select
                                            id="leadChamber"
                                            label="Lead Chamber"
                                            name="leadChamber"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, leadChamber: e.target.value })}
                                            value={newImplantInstance?.leadChamber}
                                        >
                                            <Select.Option value="">Select a lead chamber</Select.Option>
                                            <Select.Option value="Right Atrium">Right Atrium</Select.Option>
                                            <Select.Option value="Right Ventricle">Right Ventricle</Select.Option>
                                            <Select.Option value="Left Ventricle">Left Ventricle</Select.Option>
                                            <Select.Option value="NA">NA</Select.Option>
                                        </Select>
                                        <Select
                                            id="pacingSite"
                                            label="Pacing Site"
                                            name="pacingSite"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, pacingSite: e.target.value })}
                                            value={newImplantInstance?.pacingSite}
                                        >
                                            <Select.Option value="">Select a pacing site</Select.Option>
                                            <Select.Option value="Middle cardiac vein">Middle cardiac vein</Select.Option>
                                            <Select.Option value="CS - Posterior branch">CS - Posterior branch</Select.Option>
                                            <Select.Option value="CS - Posterolateral branch">CS - Posterolateral branch</Select.Option>
                                            <Select.Option value="CS - Lateral branch">CS - Lateral branch</Select.Option>
                                            <Select.Option value="CS - Anterolateral branch">CS - Anterolateral branch</Select.Option>
                                            <Select.Option value="CS - Anterior branch">CS - Anterior branch</Select.Option>
                                            <Select.Option value="Greater cardiac vein">Greater cardiac vein</Select.Option>
                                            <Select.Option value="LV epicardial - via pericardium">LV epicardial - via pericardium</Select.Option>
                                            <Select.Option value="LV endocardial - trans-septal">LV endocardial - trans-septal</Select.Option>
                                            <Select.Option value="LV other">LV other</Select.Option>
                                        </Select>
                                        <Select
                                            id="status"
                                            label="Status"
                                            name="status"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, status: e.target.value })}
                                            value={newImplantInstance?.status}
                                            required
                                        >
                                            <Select.Option value="">Select a status</Select.Option>
                                            <Select.Option value="Implanted, connected">Implanted, connected</Select.Option>
                                            <Select.Option value="Explanted">Explanted</Select.Option>
                                            <Select.Option value="Partial extraction">Partial extraction</Select.Option>
                                            <Select.Option value="Complete extraction">Complete extraction</Select.Option>
                                            <Select.Option value="Implanted, abandoned">Implanted, abandoned</Select.Option>

                                        </Select>
                                        <Checkboxes name="checkboxes">
                                            <Checkboxes.Box
                                                checked={newImplantInstance?.mrConditional}
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, mrConditional: e.target.checked })}
                                            >
                                                MR Conditional
                                            </Checkboxes.Box>
                                            <Checkboxes.Box
                                                checked={newImplantInstance?.abandonedHardware}
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, abandonedHardware: e.target.checked })}
                                            >
                                                Abandoned Hardware
                                            </Checkboxes.Box>
                                        </Checkboxes>
                                        <Textarea
                                            id="eventDetails"
                                            label="Event Details"
                                            name="eventDetails"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, eventDetails: e.target.value })}
                                            value={newImplantInstance?.eventDetails}
                                        />
                                        <Textarea
                                            id="PractitionerDetails"
                                            label="Practitioner Details"
                                            name="PractitionerDetails"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, practitionerDetails: e.target.value })}
                                            value={newImplantInstance?.practitionerDetails}
                                        />
                                        <Textarea
                                            id="Notes"
                                            label="Notes"
                                            name="Notes"
                                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, notes: e.target.value })}
                                            value={newImplantInstance?.notes}
                                        />


                                    </Card.Description>

                                    <Button
                                        disabled={implantInstanceUpdateProcessing}
                                        onClick={handleCreateImplantInstance}
                                    >
                                        Create a new Implant
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div >
        </React.Fragment >

    );
}






