import {

    PRICING_TIER_LIST_REQUEST,
    PRICING_TIER_LIST_SUCCESS,
    PRICING_TIER_LIST_FAIL,

    PRICING_TIER_DETAILS_REQUEST,
    PRICING_TIER_DETAILS_SUCCESS,
    PRICING_TIER_DETAILS_FAIL,

    PRICING_TIER_DELETE_REQUEST,
    PRICING_TIER_DELETE_SUCCESS,
    PRICING_TIER_DELETE_FAIL,

    PRICING_TIER_CREATE_REQUEST,
    PRICING_TIER_CREATE_SUCCESS,
    PRICING_TIER_CREATE_FAIL,
    PRICING_TIER_CREATE_RESET,

    PRICING_TIER_UPDATE_REQUEST,
    PRICING_TIER_UPDATE_SUCCESS,
    PRICING_TIER_UPDATE_FAIL,
    PRICING_TIER_UPDATE_RESET,

    SET_CURRENT_PAGE,

    PRICING_TIER_DTO_FAIL,
    PRICING_TIER_DTO_REQUEST,
    PRICING_TIER_DTO_SUCCESS,
    PRICING_TIER_DTO_RESET,

    PRICING_TIER_LIST_BYIDS_REQUEST,
    PRICING_TIER_LIST_BYIDS_SUCCESS,
    PRICING_TIER_LIST_BYIDS_FAIL,
    PRICING_TIER_LIST_BYIDS_RESET

} from "../constants/pricingTierConstants";

const initialState = {
    pricingTiers: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const pricingTierListReducer = (
    state = {
        pricingTiers: []
    }, action) => {
    switch (action.type) {
        case PRICING_TIER_LIST_REQUEST:
            return { loading: true, pricingTiers: [] };
        case PRICING_TIER_LIST_SUCCESS:
            // our state will have a list of pricingTiers after the request
            return {
                loading: false,
                pricingTiers: action.payload.pricingTiers,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case PRICING_TIER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const pricingTierListByIDsReducer = (
    state = {
        pricingTiers: []
    }, action) => {
    switch (action.type) {
        case PRICING_TIER_LIST_BYIDS_REQUEST:
            return { loading: true, pricingTiers: [] };
        case PRICING_TIER_LIST_BYIDS_SUCCESS:
            // our state will have a list of pricingTiers after the request
            return { loading: false, pricingTiers: action.payload };
        case PRICING_TIER_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case PRICING_TIER_LIST_BYIDS_RESET:
            return { pricingTiers: [] };
        default:
            return state;
    }
}


export const pricingTierDetailsReducer = (
    state = {
        pricingTier: { comments: [] }
    }, action) => {
    switch (action.type) {
        case PRICING_TIER_DETAILS_REQUEST:
            return { loading: true, ...state };
        case PRICING_TIER_DETAILS_SUCCESS:
            // our state will have a list of pricingTiers after the request
            return { loading: false, pricingTier: action.payload };
        case PRICING_TIER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const pricingTierDtoReducer = (state = { pricingTierDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case PRICING_TIER_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case PRICING_TIER_DTO_SUCCESS:
        return { ...state, loading: false, pricingTierDto: action.payload };
      case PRICING_TIER_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case PRICING_TIER_DTO_RESET:
        return { pricingTierDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const pricingTierDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRICING_TIER_DELETE_REQUEST:
            return { loading: true }

        case PRICING_TIER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PRICING_TIER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const pricingTierCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRICING_TIER_CREATE_REQUEST:
            return { loading: true }

        case PRICING_TIER_CREATE_SUCCESS:
            return { loading: false, success: true, pricingTier: action.payload }

        case PRICING_TIER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PRICING_TIER_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const pricingTierUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case PRICING_TIER_UPDATE_REQUEST:
            return { loading: true }

        case PRICING_TIER_UPDATE_SUCCESS:
            return { loading: false, success: true, pricingTier: action.payload }

        case PRICING_TIER_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case PRICING_TIER_UPDATE_RESET:
            return { pricingTier: {} }

        default:
            return state
    }
}