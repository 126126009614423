export const SAMPLE_INVENTORY_LINE_LIST_REQUEST = 'SAMPLE_INVENTORY_LINE_LIST_REQUEST';
export const SAMPLE_INVENTORY_LINE_LIST_SUCCESS = 'SAMPLE_INVENTORY_LINE_LIST_SUCCESS';
export const SAMPLE_INVENTORY_LINE_LIST_FAIL = 'SAMPLE_INVENTORY_LINE_LIST_FAIL';

export const SAMPLE_INVENTORY_LINE_DETAILS_REQUEST = 'SAMPLE_INVENTORY_LINE_DETAILS_REQUEST';
export const SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS = 'SAMPLE_INVENTORY_LINE_DETAILS_SUCCESS';
export const SAMPLE_INVENTORY_LINE_DETAILS_FAIL = 'SAMPLE_INVENTORY_LINE_DETAILS_FAIL';

export const SAMPLE_INVENTORY_LINE_DELETE_REQUEST = 'SAMPLE_INVENTORY_LINE_DELETE_REQUEST';
export const SAMPLE_INVENTORY_LINE_DELETE_SUCCESS = 'SAMPLE_INVENTORY_LINE_DELETE_SUCCESS';
export const SAMPLE_INVENTORY_LINE_DELETE_FAIL = 'SAMPLE_INVENTORY_LINE_DELETE_FAIL';

export const SAMPLE_INVENTORY_LINE_CREATE_REQUEST = 'SAMPLE_INVENTORY_LINE_CREATE_REQUEST';
export const SAMPLE_INVENTORY_LINE_CREATE_SUCCESS = 'SAMPLE_INVENTORY_LINE_CREATE_SUCCESS';
export const SAMPLE_INVENTORY_LINE_CREATE_FAIL = 'SAMPLE_INVENTORY_LINE_CREATE_FAIL';
export const SAMPLE_INVENTORY_LINE_CREATE_RESET = 'SAMPLE_INVENTORY_LINE_CREATE_RESET';

export const SAMPLE_INVENTORY_LINE_UPDATE_REQUEST = 'SAMPLE_INVENTORY_LINE_UPDATE_REQUEST';
export const SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS = 'SAMPLE_INVENTORY_LINE_UPDATE_SUCCESS';
export const SAMPLE_INVENTORY_LINE_UPDATE_FAIL = 'SAMPLE_INVENTORY_LINE_UPDATE_FAIL';
export const SAMPLE_INVENTORY_LINE_UPDATE_RESET = 'SAMPLE_INVENTORY_LINE_UPDATE_RESET';

export const SAMPLE_INVENTORY_LINE_DTO_REQUEST = 'SAMPLE_INVENTORY_LINE_DTO_REQUEST';
export const SAMPLE_INVENTORY_LINE_DTO_SUCCESS = 'SAMPLE_INVENTORY_LINE_DTO_SUCCESS';
export const SAMPLE_INVENTORY_LINE_DTO_FAIL = 'SAMPLE_INVENTORY_LINE_DTO_FAIL';
export const SAMPLE_INVENTORY_LINE_DTO_RESET = 'SAMPLE_INVENTORY_LINE_DTO_RESET';

export const SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST = 'SAMPLE_INVENTORY_LINE_LIST_BYIDS_REQUEST';
export const SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS = 'SAMPLE_INVENTORY_LINE_LIST_BYIDS_SUCCESS';
export const SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL = 'SAMPLE_INVENTORY_LINE_LIST_BYIDS_FAIL';
export const SAMPLE_INVENTORY_LINE_LIST_BYIDS_RESET = 'SAMPLE_INVENTORY_LINE_LIST_BYIDS_RESET';

export const SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST = 'SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_REQUEST';
export const SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS = 'SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_SUCCESS';
export const SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL = 'SAMPLE_INVENTORY_LINE_LIST_BY_BARCODE_FAIL';