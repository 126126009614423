export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST'
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS'
export const PATIENT_LIST_FAIL = 'PATIENT_LIST_FAIL'


export const PATIENT_DETAILS_REQUEST = 'PATIENT_DETAILS_REQUEST'
export const PATIENT_DETAILS_SUCCESS = 'PATIENT_DETAILS_SUCCESS'
export const PATIENT_DETAILS_FAIL = 'PATIENT_DETAILS_FAIL'


export const PATIENT_DELETE_REQUEST = 'PATIENT_DELETE_REQUEST'
export const PATIENT_DELETE_SUCCESS = 'PATIENT_DELETE_SUCCESS'
export const PATIENT_DELETE_FAIL = 'PATIENT_DELETE_FAIL'

export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST'
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS'
export const PATIENT_CREATE_FAIL = 'PATIENT_CREATE_FAIL'
export const PATIENT_CREATE_RESET = 'PATIENT_CREATE_RESET'


export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST'
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS'
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL'
export const PATIENT_UPDATE_RESET = 'PATIENT_UPDATE_RESET'

export const PATIENT_REQUEST = 'PATIENT_REQUEST'
export const PATIENT_SUCCESS = 'PATIENT_SUCCESS'
export const PATIENT_FAIL = 'PATIENT_FAIL'
export const PATIENT_RESET = 'PATIENT_RESET'

export const PATIENT_COUNT_REQUEST = "PATIENT_COUNT_REQUEST"
export const PATIENT_COUNT_SUCCESS = "PATIENT_COUNT_SUCCESS"
export const PATIENT_COUNT_FAIL = "PATIENT_COUNT_FAIL"


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const PATIENT_DTO_REQUEST = 'PATIENT_DTO_REQUEST'
export const PATIENT_DTO_SUCCESS = 'PATIENT_DTO_SUCCESS'
export const PATIENT_DTO_FAIL = 'PATIENT_DTO_FAIL'
export const PATIENT_DTO_RESET = 'PATIENT_DTO_RESET'

export const PATIENT_LIST_BYIDS_REQUEST = 'PATIENT_LIST_BYIDS_REQUEST'
export const PATIENT_LIST_BYIDS_SUCCESS = 'PATIENT_LIST_BYIDS_SUCCESS'
export const PATIENT_LIST_BYIDS_FAIL = 'PATIENT_LIST_BYIDS_FAIL'
export const PATIENT_LIST_BYIDS_RESET = 'PATIENT_LIST_BYIDS_RESET'
