import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Statistic, Timeline, Button, Modal, Typography, Calendar, Tag, Popover, Drawer, Space, Select, Table } from 'antd';
import { QuestionCircleOutlined, PrinterOutlined, FileTextOutlined, ExperimentOutlined, ScheduleOutlined, EditOutlined, ArrowRightOutlined, BarChartOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import Printer from '../../components/printer/Printer.component';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import { listQCResults, loadQCResultDto, updateQCResult, getQCResultDetails, listQCResultsByIds } from '../../actions/qcResultActions';
import { listQCSchedules } from '../../actions/qcScheduleActions';
import { listControlMaterials } from '../../actions/controlMaterialActions';
import QCResultMiniGraph from '../../components/qc/QCResultMiniGraph';

const { Title, Text } = Typography;
const { Option } = Select;

const QCDashboard = () => {
  const { t } = useTranslation();
  document.title = t('QC Dashboard');
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPrinterVisible, setIsPrinterVisible] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [selectedResultId, setSelectedResultId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const [expandedResults, setExpandedResults] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const qcResults = useSelector(state => state.qcResultList.qcResults);
  const qcSchedules = useSelector(state => state.qcScheduleList.qcSchedules);
  const controlMaterials = useSelector(state => state.controlMaterialList.controlMaterials);

  useEffect(() => {
    dispatch(listQCResults());
    dispatch(listQCSchedules());
    dispatch(listControlMaterials());
  }, [dispatch]);

  useEffect(() => {
    if (qcResults && qcSchedules && controlMaterials) {
      const passRate = calculatePassRate(qcResults);
      const upcomingSchedules = qcSchedules?.filter(s => new Date(s.scheduledDate) > new Date()).length;
      const expiringMaterials = controlMaterials?.filter(m => isExpiringSoon(m.expirationDate)).length;
      const recentFailures = qcResults?.filter(r => r.qcResultStatus === 'Failed').slice(0, 5);

      setDashboardData({
        passRate,
        upcomingSchedules,
        expiringMaterials,
        recentFailures,
      });
    }
  }, [qcResults, qcSchedules, controlMaterials]);

  const calculatePassRate = (results) => {
    if (!results || results.length === 0) return 0;
    const passedResults = results?.filter(r => r.qcResultStatus === 'Passed').length;
    return ((passedResults / results.length) * 100).toFixed(2);
  };

  const isExpiringSoon = (date) => {
    const expirationDate = new Date(date);
    const today = new Date();
    const differenceInDays = (expirationDate - today) / (1000 * 3600 * 24);
    return differenceInDays <= 30;
  };

  const handlePrint = () => {
    setIsPrinterVisible(true);
  };

  const handleQuickEdit = (resultId) => {
    setSelectedResultId(resultId);
    setIsEditDrawerVisible(true);
  };

  const toggleRowExpansion = (record) => {
    setExpandedRowKeys(keys => {
      const key = record.id;
      const expanded = keys.includes(key);
      return expanded ? keys?.filter(k => k !== key) : [...keys, key];
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Passed':
        return 'green';
      case 'Failed':
        return 'red';
      case 'Pending':
        return 'orange';
      default:
        return 'blue';
    }
  };

  const toggleResultDetails = (resultId) => {
    setExpandedResults(prev => ({
      ...prev,
      [resultId]: !prev[resultId]
    }));
  };

  const columns = [
    {
      title: t('Barcode'),
      dataIndex: 'barcode',
      key: 'barcode',
      render: (text) => <Text strong>{text || t('Unknown Test')}</Text>,
    },
    {
      title: t('Status'),
      dataIndex: 'qcResultStatus',
      key: 'qcResultStatus',
      render: (status) => <Tag color={getStatusColor(status)}>{t(status)}</Tag>,
    },
    {
      title: t('Result'),
      dataIndex: 'resultValue',
      key: 'resultValue',
      render: (value, record) => `${value || '0'} ${record.unit || ''}`,
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="default" size="small" icon={<EditOutlined />} onClick={() => handleQuickEdit(record.id)}>
            {t('Edit')}
          </Button>
          <Button type="link" size="small" icon={<ArrowRightOutlined />} onClick={() => history.push(`/qc_result_management/edit/${record.id}`)}>
            {t('Go to Details')}
          </Button>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => (
    <Space direction="vertical" size="small" style={{width: "100%"}}>
      <Text>{t('Acceptable Range')}: {record.acceptableLimitLow} - {record.acceptableLimitHigh} {record.unit}</Text>
      <Text>{t('Warning Range')}: {record.warningLimitLow} - {record.warningLimitHigh} {record.unit}</Text>
      <QCResultMiniGraph 
        acceptableLimitLow={record.acceptableLimitLow}
        warningLimitLow={record.warningLimitLow}
        warningLimitHigh={record.warningLimitHigh}
        acceptableLimitHigh={record.acceptableLimitHigh}
        foundValue={record.resultValue}
        findingDate={record.performedDate || new Date()}
        unit={record.unit || ''}
      />
    </Space>
  );

  const renderFailureDetails = (result) => (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Space align="start">
        <Tag color={getStatusColor(result.qcResultStatus)}>{t(result.qcResultStatus)}</Tag>
        <Text strong>{result.testName || t('Unnamed Test')}</Text>
        <Button 
          type="link" 
          icon={expandedResults[result.id] ? <UpOutlined /> : <DownOutlined />} 
          onClick={() => toggleResultDetails(result.id)}
        >
          {expandedResults[result.id] ? t('Hide Details') : t('Show Details')}
        </Button>
      </Space>
      {expandedResults[result.id] && (
        <>
          <Text>{t('Result')}: {result.resultValue || "-"} {result.unit}</Text>
          <Text>{t('Acceptable Range')}: {result.acceptableLimitLow} - {result.acceptableLimitHigh} {result.unit}</Text>
          <Text>{t('Warning Range')}: {result.warningLimitLow} - {result.warningLimitHigh} {result.unit}</Text>
          <Space>
            <Button type="default" size="small" icon={<EditOutlined />} onClick={() => handleQuickEdit(result.id)}>
              {t('Edit')}
            </Button>
            <Button type="link" size="small" icon={<ArrowRightOutlined />} onClick={() => history.push(`/qc_result_management/edit/${result.id}`)}>
              {t('Go to Edit Page')}
            </Button>
          </Space>
        </>
      )}
    </Space>
  );

  const renderPopoverContent = (result) => (
    <Space direction="vertical" size="small">
      <Text strong>{t('QC Result Details')}:</Text>
      <Text>{t('Status')}: <Tag color={getStatusColor(result.qcResultStatus)}>{t(result.qcResultStatus)}</Tag></Text>
      <Text>{t('Result')}: {result.resultValue || "-"} {result.unit}</Text>
      <Text>{t('Acceptable Range')}: {result.acceptableLimitLow} - {result.acceptableLimitHigh} {result.unit}</Text>
      <Text>{t('Warning Range')}: {result.warningLimitLow} - {result.warningLimitHigh} {result.unit}</Text>
      <Space>
        <Button type='primary' size="small" onClick={() => handleQuickEdit(result.id)}>{t('Quick Edit')}</Button>
        <Button size="small" onClick={() => history.push(`/qc_result_management/edit/${result.id}`)}>{t('Edit on Page')}</Button>
      </Space>
    </Space>
  );

  return (
    <div>
      <Title level={2}>{t('QC Dashboard')}</Title>

      {dashboardData ? (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic title={t('QC Pass Rate')} value={dashboardData.passRate} suffix="%" />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic title={t('Upcoming Schedules')} value={dashboardData.upcomingSchedules} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic title={t('Expiring Materials')} value={dashboardData.expiringMaterials} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic title={t('Recent QC Failures')} value={dashboardData.recentFailures.length} />
            </Card>
          </Col>
        </Row>
      ) : (
        <div>{t('Loading')}...</div>
      )}

      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} sm={12}>
          <Card title={t('Quick Actions')}>
            <Button block style={{ marginBottom: 8 }} icon={<FileTextOutlined />} onClick={() => history.push('/qc_result_management')}>{t('Enter QC Result')}</Button>
            <Button block style={{ marginBottom: 8 }} icon={<ScheduleOutlined />} onClick={() => history.push('/qc_schedule_management')}>{t('View QC Schedule')}</Button>
            <Button block style={{ marginBottom: 8 }} icon={<ExperimentOutlined />} onClick={() => history.push('/control_material_management')}>{t('Check Inventory')}</Button>
            <Button block icon={<QuestionCircleOutlined />} onClick={() => history.push('/qc_report_management')}>{t('Generate Report')}</Button>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card 
            title={t('Recent QC Failures')}
            extra={
              <Button size="small" icon={<PrinterOutlined />} onClick={handlePrint}>
                {t('Print')}
              </Button>
            }
          >
            {dashboardData && dashboardData.recentFailures.length > 0 ? (
              <Table
                scroll={{ x: true }}
                columns={columns}
                dataSource={dashboardData.recentFailures}
                rowKey="id"
                expandable={{
                  expandedRowRender,
                  expandRowByClick: true,
                }}
                pagination={false}
              />
            ) : (
              <div>{t('No recent QC failures')}</div>
            )}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24}>
          <Card 
            title={t('QC Schedule Calendar')} 
            extra={
              <Select 
                defaultValue="All" 
                style={{ width: 120 }} 
                onChange={setStatusFilter}
              >
                <Option value="All">{t('All Statuses')}</Option>
                <Option value="Passed">{t('Passed')}</Option>
                <Option value="Failed">{t('Failed')}</Option>
                <Option value="Pending">{t('Pending')}</Option>
              </Select>
            }
          >
            <Calendar
              dateCellRender={(value) => {
                const schedulesForDate = qcSchedules?.filter(s => 
                  new Date(s.scheduledDate).toDateString() === value.toDate().toDateString()
                );
                return (
                  <ul className="events">
                    {schedulesForDate?.map((schedule) => {
                      const relatedResult = qcResults.find(r => r.qcScheduleId === schedule.id);
                      if (statusFilter !== 'All' && relatedResult && relatedResult.qcResultStatus !== statusFilter) {
                        return null;
                      }
                      return (
                        <li key={schedule.id}>
                          <Popover
                            content={relatedResult ? renderPopoverContent(relatedResult) : t("No QC result available yet")}
                            trigger="click"
                          >
                            <Tag color={getStatusColor(relatedResult?.qcResultStatus || 'Pending')}>
                              {t(relatedResult?.qcResultStatus || 'Pending')}
                            </Tag>
                          </Popover>
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title={t('Print Dashboard Summary')}
        visible={isPrinterVisible}
        onCancel={() => setIsPrinterVisible(false)}
        footer={null}
        width="90%"
        style={{ maxWidth: 800 }}
      >
        {dashboardData && (
          <Printer
            configEndpoint="/print/configs/qc-dashboard"
            generateEndpoint="/print/documents/generate"
            data={dashboardData}
          />
        )}
      </Modal>

      <Drawer
        title={t('Edit QC Result')}
        placement="right"
        closable={true}
        onClose={() => {
          setIsEditDrawerVisible(false);
          setSelectedResultId(null);
        }}
        visible={isEditDrawerVisible}
        width={800}
      >
        {selectedResultId && (
          <DynamicFormSP
            key={selectedResultId}
            currentEntityId={selectedResultId}
            defaultEditMode="Edit"
            primarySearchProp="qcResultStatus"
            secondarySearchProp="resultValue"
            singularEntity="qcResult"
            pluralEntity="qcResults"
            constantPrefix="QC_RESULT"
            loadDto={loadQCResultDto}
            updateEntity={updateQCResult}
            getEntityDetails={getQCResultDetails}
            listEntitiesByIds={listQCResultsByIds}
            listEntities={listQCResults}
            onUpdatedEntityChange={() => {
              // Handle any changes after updating the entity
            }}
            isParent={true}
            fieldsToHide={['Id']}
            summaryProps={['qcScheduleId', 'controlMaterialId', 'resultValue', 'qcResultStatus', 'performedDate']}
            enableCreate={false}
            enableSearchBar={false}
            enableActionButtons={true}
          />
        )}
      </Drawer>
    </div>
  );
};

export default QCDashboard;