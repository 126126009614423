import {
    QC_REPORT_LIST_REQUEST,
    QC_REPORT_LIST_SUCCESS,
    QC_REPORT_LIST_FAIL,
    QC_REPORT_DETAILS_REQUEST,
    QC_REPORT_DETAILS_SUCCESS,
    QC_REPORT_DETAILS_FAIL,
    QC_REPORT_DELETE_REQUEST,
    QC_REPORT_DELETE_SUCCESS,
    QC_REPORT_DELETE_FAIL,
    QC_REPORT_CREATE_REQUEST,
    QC_REPORT_CREATE_SUCCESS,
    QC_REPORT_CREATE_FAIL,
    QC_REPORT_UPDATE_REQUEST,
    QC_REPORT_UPDATE_SUCCESS,
    QC_REPORT_UPDATE_FAIL,
    QC_REPORT_DTO_REQUEST,
    QC_REPORT_DTO_SUCCESS,
    QC_REPORT_DTO_FAIL,
    QC_REPORT_DTO_RESET,
    QC_REPORT_LIST_BYIDS_REQUEST,
    QC_REPORT_LIST_BYIDS_SUCCESS,
    QC_REPORT_LIST_BYIDS_FAIL,
    QC_REPORT_LIST_BYIDS_RESET,
} from '../constants/qcReportConstants';

export const qcReportListReducer = (state = { qcReports: [] }, action) => {
    switch (action.type) {
        case QC_REPORT_LIST_REQUEST:
            return { loading: true, qcReports: [] };
        case QC_REPORT_LIST_SUCCESS:
            return { 
                loading: false, 
                qcReports: action.payload.qcReports,
                page: action.payload.page,
                pages: action.payload.pages,
                count: action.payload.count,
            };
        case QC_REPORT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcReportDetailsReducer = (state = { qcReport: {} }, action) => {
    switch (action.type) {
        case QC_REPORT_DETAILS_REQUEST:
            return { ...state, loading: true };
        case QC_REPORT_DETAILS_SUCCESS:
            return { loading: false, qcReport: action.payload };
        case QC_REPORT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcReportDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_REPORT_DELETE_REQUEST:
            return { loading: true };
        case QC_REPORT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case QC_REPORT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcReportCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_REPORT_CREATE_REQUEST:
            return { loading: true };
        case QC_REPORT_CREATE_SUCCESS:
            return { loading: false, success: true, qcReport: action.payload };
        case QC_REPORT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcReportUpdateReducer = (state = { qcReport: {} }, action) => {
    switch (action.type) {
        case QC_REPORT_UPDATE_REQUEST:
            return { loading: true };
        case QC_REPORT_UPDATE_SUCCESS:
            return { loading: false, success: true, qcReport: action.payload };
        case QC_REPORT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcReportDtoReducer = (state = { qcReportDto: {} }, action) => {
    switch (action.type) {
        case QC_REPORT_DTO_REQUEST:
            return { loading: true };
        case QC_REPORT_DTO_SUCCESS:
            return { loading: false, qcReportDto: action.payload };
        case QC_REPORT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case QC_REPORT_DTO_RESET:
            return { qcReportDto: {} };
        default:
            return state;
    }
};

export const qcReportListByIdsReducer = (state = { qcReports: [] }, action) => {
    switch (action.type) {
        case QC_REPORT_LIST_BYIDS_REQUEST:
            return { loading: true, qcReports: [] };
        case QC_REPORT_LIST_BYIDS_SUCCESS:
            return { loading: false, qcReports: action.payload };
        case QC_REPORT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case QC_REPORT_LIST_BYIDS_RESET:
            return { qcReports: [] };
        default:
            return state;
    }
};