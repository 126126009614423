export const APPLICATION_LIST_REQUEST = 'APPLICATION_LIST_REQUEST'
export const APPLICATION_LIST_SUCCESS = 'APPLICATION_LIST_SUCCESS'
export const APPLICATION_LIST_FAIL = 'APPLICATION_LIST_FAIL'


export const APPLICATION_DETAILS_REQUEST = 'APPLICATION_DETAILS_REQUEST'
export const APPLICATION_DETAILS_SUCCESS = 'APPLICATION_DETAILS_SUCCESS'
export const APPLICATION_DETAILS_FAIL = 'APPLICATION_DETAILS_FAIL'


export const APPLICATION_DELETE_REQUEST = 'APPLICATION_DELETE_REQUEST'
export const APPLICATION_DELETE_SUCCESS = 'APPLICATION_DELETE_SUCCESS'
export const APPLICATION_DELETE_FAIL = 'APPLICATION_DELETE_FAIL'

export const APPLICATION_CREATE_REQUEST = 'APPLICATION_CREATE_REQUEST'
export const APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS'
export const APPLICATION_CREATE_FAIL = 'APPLICATION_CREATE_FAIL'
export const APPLICATION_CREATE_RESET = 'APPLICATION_CREATE_RESET'

export const APPLICATION_UPDATE_REQUEST = 'APPLICATION_UPDATE_REQUEST'
export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS'
export const APPLICATION_UPDATE_FAIL = 'APPLICATION_UPDATE_FAIL'
export const APPLICATION_UPDATE_RESET = 'APPLICATION_UPDATE_RESET'

export const APPLICATION_REQUEST = 'APPLICATION_REQUEST'
export const APPLICATION_SUCCESS = 'APPLICATION_SUCCESS'
export const APPLICATION_FAIL = 'APPLICATION_FAIL'
export const APPLICATION_RESET = 'APPLICATION_RESET'

export const APPLICATION_COUNT_REQUEST = "APPLICATION_COUNT_REQUEST";
export const APPLICATION_COUNT_SUCCESS = "APPLICATION_COUNT_SUCCESS";
export const APPLICATION_COUNT_FAIL = "APPLICATION_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const APPLICATION_DTO_REQUEST = 'APPLICATION_DTO_REQUEST';
export const APPLICATION_DTO_SUCCESS = 'APPLICATION_DTO_SUCCESS';
export const APPLICATION_DTO_FAIL = 'APPLICATION_DTO_FAIL';
export const APPLICATION_DTO_RESET = 'APPLICATION_DTO_RESET';

export const APPLICATION_LIST_BYIDS_REQUEST = 'APPLICATION_LIST_BYIDS_REQUEST';
export const APPLICATION_LIST_BYIDS_SUCCESS = 'APPLICATION_LIST_BYIDS_SUCCESS';
export const APPLICATION_LIST_BYIDS_FAIL = 'APPLICATION_LIST_BYIDS_FAIL';
export const APPLICATION_LIST_BYIDS_RESET = 'APPLICATION_LIST_BYIDS_RESET';