export const ENCOUNTER_LIST_REQUEST = 'ENCOUNTER_LIST_REQUEST'
export const ENCOUNTER_LIST_SUCCESS = 'ENCOUNTER_LIST_SUCCESS'
export const ENCOUNTER_LIST_FAIL = 'ENCOUNTER_LIST_FAIL'


export const ENCOUNTER_DETAILS_REQUEST = 'ENCOUNTER_DETAILS_REQUEST'
export const ENCOUNTER_DETAILS_SUCCESS = 'ENCOUNTER_DETAILS_SUCCESS'
export const ENCOUNTER_DETAILS_FAIL = 'ENCOUNTER_DETAILS_FAIL'


export const ENCOUNTER_DELETE_REQUEST = 'ENCOUNTER_DELETE_REQUEST'
export const ENCOUNTER_DELETE_SUCCESS = 'ENCOUNTER_DELETE_SUCCESS'
export const ENCOUNTER_DELETE_FAIL = 'ENCOUNTER_DELETE_FAIL'

export const ENCOUNTER_CREATE_REQUEST = 'ENCOUNTER_CREATE_REQUEST'
export const ENCOUNTER_CREATE_SUCCESS = 'ENCOUNTER_CREATE_SUCCESS'
export const ENCOUNTER_CREATE_FAIL = 'ENCOUNTER_CREATE_FAIL'
export const ENCOUNTER_CREATE_RESET = 'ENCOUNTER_CREATE_RESET'

export const ENCOUNTER_UPDATE_REQUEST = 'ENCOUNTER_UPDATE_REQUEST'
export const ENCOUNTER_UPDATE_SUCCESS = 'ENCOUNTER_UPDATE_SUCCESS'
export const ENCOUNTER_UPDATE_FAIL = 'ENCOUNTER_UPDATE_FAIL'
export const ENCOUNTER_UPDATE_RESET = 'ENCOUNTER_UPDATE_RESET'

export const ENCOUNTER_REQUEST = 'ENCOUNTER_REQUEST'
export const ENCOUNTER_SUCCESS = 'ENCOUNTER_SUCCESS'
export const ENCOUNTER_FAIL = 'ENCOUNTER_FAIL'
export const ENCOUNTER_RESET = 'ENCOUNTER_RESET'

export const ENCOUNTER_COUNT_REQUEST = "ENCOUNTER_COUNT_REQUEST";
export const ENCOUNTER_COUNT_SUCCESS = "ENCOUNTER_COUNT_SUCCESS";
export const ENCOUNTER_COUNT_FAIL = "ENCOUNTER_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const ENCOUNTER_DTO_REQUEST = 'ENCOUNTER_DTO_REQUEST';
export const ENCOUNTER_DTO_SUCCESS = 'ENCOUNTER_DTO_SUCCESS';
export const ENCOUNTER_DTO_FAIL = 'ENCOUNTER_DTO_FAIL';
export const ENCOUNTER_DTO_RESET = 'ENCOUNTER_DTO_RESET';

export const ENCOUNTER_LIST_BYIDS_REQUEST = 'ENCOUNTER_LIST_BYIDS_REQUEST';
export const ENCOUNTER_LIST_BYIDS_SUCCESS = 'ENCOUNTER_LIST_BYIDS_SUCCESS';
export const ENCOUNTER_LIST_BYIDS_FAIL = 'ENCOUNTER_LIST_BYIDS_FAIL';
export const ENCOUNTER_LIST_BYIDS_RESET = 'ENCOUNTER_LIST_BYIDS_RESET';