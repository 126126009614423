import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, Statistic, Button, Space, Typography, Tabs, message, Modal, Table
} from 'antd';
import {
  PrinterOutlined, FileExcelOutlined, BarChartOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import moment from 'moment';

import ListItemsDataTable from "../../components/crud/ListItemsDataTable.component";
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import Printer from '../../components/printer/Printer.component';

import {
  listInvoiceReports, loadInvoiceReportDto, updateInvoiceReport, getInvoiceReportDetails,
  listInvoiceReportsByIds, deleteInvoiceReport
} from '../../actions/invoiceReportActions';
import {
  listPayments, loadPaymentDto, updatePayment, getPaymentDetails,
  listPaymentsByIds, deletePayment
} from '../../actions/paymentActions';
import { exportToCSV } from '../../utils/exportUtils';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const AccountingScreen = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('invoices');
  const [isPrinterVisible, setIsPrinterVisible] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [limit, setLimit] = useState(10);
  const [editMode, setEditMode] = useState('Lookup');
  const [invoiceListFilter, setInvoiceListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'invoiceNumber' });
  const [paymentListFilter, setPaymentListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'paymentNumber' });
  const [printData, setPrintData] = useState(null);

  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    businessOrganisationName,
    currencySymbol,
    currencyCode,
  } = userInfo;

  const invoiceReportList = useSelector(state => state.invoiceReportList);
  const { loading: loadingInvoices, invoiceReports, statistics: invoiceStatistics } = invoiceReportList;
  const paymentList = useSelector(state => state.paymentList);
  const { loading: loadingPayments, payments, statistics: paymentStatistics } = paymentList;
  const invoiceReportDto = useSelector(state => state.invoiceReportDto.invoiceReportDto);
  const paymentDto = useSelector(state => state.paymentDto.paymentDto);

  useEffect(() => {
    dispatch(listInvoiceReports(invoiceListFilter));
    dispatch(listPayments(paymentListFilter));
    dispatch(loadInvoiceReportDto());
    dispatch(loadPaymentDto());
  }, [dispatch, invoiceListFilter, paymentListFilter]);

  const handleEdit = (id) => {
    setSelectedEntityId(id);
    setEditMode('Edit');
  };

  const handleDeleteInvoiceReport = async (id) => {
    
    dispatch(deleteInvoiceReport(id));
    dispatch(listInvoiceReports(invoiceListFilter));

  };

  const handleDeletePayment = async (id) => {
    dispatch(deletePayment(id));
    dispatch(listPayments(paymentListFilter));
  };

  const handleExportToExcel = () => {
    const dataToExport = activeTab === 'invoices' ? invoiceReports : payments;
    const fileName = activeTab === 'invoices' ? 'invoice_reports' : 'payments';
    exportToCSV(dataToExport, fileName);
    message.success(`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} exported successfully`);
  };

  const handlePrint = () => {
    const totalRevenue = invoiceStatistics?.total.sum || 0;
    const totalPayments = paymentStatistics?.totalAmount.sum || 0;
    const accountsReceivable = invoiceStatistics?.balanceDue.sum || 0;

    const currentDate = new Date();
    const agingBuckets = {
      '0-30 days': 0,
      '31-60 days': 0,
      '61-90 days': 0,
      '> 90 days': 0
    };

    invoiceReports.forEach(invoice => {
      const daysOutstanding = (currentDate - new Date(invoice.invoiceDate)) / (1000 * 60 * 60 * 24);
      const outstandingAmount = invoice.balanceDue;

      if (daysOutstanding <= 30) agingBuckets['0-30 days'] += outstandingAmount;
      else if (daysOutstanding <= 60) agingBuckets['31-60 days'] += outstandingAmount;
      else if (daysOutstanding <= 90) agingBuckets['61-90 days'] += outstandingAmount;
      else agingBuckets['> 90 days'] += outstandingAmount;
    });

    const printData = {
      reportHeader: {
        content: {
          main_header: `${businessOrganisationName} - Financial Statements`,
          header: "Confidential Financial Report",
          head: "Financial Statements",
          reportDate: moment().format('MMMM DD, YYYY')
        }
      },
      incomeStatement: {
        content: {
          items: [
            { name: "Total Invoiced Amount", amount: `${currencySymbol}${totalRevenue.toFixed(2)}`, isPositive: true },
            { name: "Total Payments Received", amount: `${currencySymbol}${totalPayments.toFixed(2)}`, isPositive: true },
            { name: "Outstanding Balance", amount: `${currencySymbol}${accountsReceivable.toFixed(2)}`, isPositive: accountsReceivable > 0, isTotal: true }
          ]
        }
      },
      balanceSheet: {
        content: {
          items: [
            { assetName: "Cash (Payments Received)", assetAmount: `${currencySymbol}${totalPayments.toFixed(2)}`, liabilityName: "", liabilityAmount: "" },
            { assetName: "Accounts Receivable", assetAmount: `${currencySymbol}${accountsReceivable.toFixed(2)}`, liabilityName: "", liabilityAmount: "" },
            { assetName: "Total Assets", assetAmount: `${currencySymbol}${(totalPayments + accountsReceivable).toFixed(2)}`, liabilityName: "", liabilityAmount: "", isTotal: true }
          ]
        }
      },
      cashFlowStatement: {
        content: {
          items: [
            { name: "Cash Inflow from Payments", amount: `${currencySymbol}${totalPayments.toFixed(2)}`, isPositive: true },
            { name: "Outstanding Receivables", amount: `${currencySymbol}${accountsReceivable.toFixed(2)}`, isPositive: false },
            { name: "Net Cash Position", amount: `${currencySymbol}${(totalPayments - accountsReceivable).toFixed(2)}`, isPositive: (totalPayments - accountsReceivable) > 0, isTotal: true }
          ]
        }
      },
      accountsReceivableAging: {
        content: {
          items: Object.entries(agingBuckets)?.map(([age, amount]) => ({
            age,
            amount: `${currencySymbol}${amount.toFixed(2)}`,
            percentage: accountsReceivable > 0 ? `${((amount / accountsReceivable) * 100).toFixed(2)}%` : '0.00%'
          })),
          total: `${currencySymbol}${accountsReceivable.toFixed(2)}`
        }
      },
      recentInvoices: invoiceReports?.map(invoice => ({
        content: invoice,
        template: invoice.template || ''
      })),

      invoiceStatistics: {
        content: {
          items: [
            { name: "Total Count", value: formatStatistic({ min: invoiceStatistics?.totalCount || 0, max: invoiceStatistics?.totalCount || 0, average: invoiceStatistics?.totalCount || 0, sum: invoiceStatistics?.totalCount || 0 }) },
            { name: "Total Amount", value: formatStatistic(invoiceStatistics?.total) },
            { name: "Amount Paid", value: formatStatistic(invoiceStatistics?.amountPaid) },
            { name: "Total Discount", value: formatStatistic(invoiceStatistics?.totalDiscount) },
            { name: "Balance Due", value: formatStatistic(invoiceStatistics?.balanceDue) },
            { name: "Total Tax", value: formatStatistic(invoiceStatistics?.totalTax) }
          ]
        }
      },
      recentPayments: payments?.map(payment => ({
        content: payment,
        template: payment.template || ''
      })),

      paymentStatistics: {
        content: {
          items: [
            { name: "Total Count", value: formatStatistic({ min: paymentStatistics?.totalCount || 0, max: paymentStatistics?.totalCount || 0, average: paymentStatistics?.totalCount || 0, sum: paymentStatistics?.totalCount || 0 }) },
            { name: "Total Amount", value: formatStatistic(paymentStatistics?.totalAmount) },
            { name: "Amount Paid", value: formatStatistic(paymentStatistics?.amountPaid) }
          ]
        }
      }
    };

    setPrintData(printData);
    setIsPrinterVisible(true);
  };

  const formatStatistic = (stat) => {
    if (!stat) return { min: `${currencySymbol}0.00`, max: `${currencySymbol}0.00`, average: `${currencySymbol}0.00`, sum: `${currencySymbol}0.00` };
    return {
      min: `${currencySymbol}${stat.min.toFixed(2)}`,
      max: `${currencySymbol}${stat.max.toFixed(2)}`,
      average: `${currencySymbol}${stat.average.toFixed(2)}`,
      sum: `${currencySymbol}${stat.sum.toFixed(2)}`
    };
  };

  const renderStatisticsSummary = (statistics, type) => {
    if (!statistics) return null;

    const columns = [
      { title: 'Statistic', dataIndex: 'statistic', key: 'statistic' },
      { title: 'Min', dataIndex: 'min', key: 'min' },
      { title: 'Max', dataIndex: 'max', key: 'max' },
      { title: 'Average', dataIndex: 'average', key: 'average' },
      { title: 'Sum', dataIndex: 'sum', key: 'sum' },
    ];

    const data = type === 'invoices' ? [
      {
        key: 'total',
        statistic: 'Total',
        min: `${currencySymbol}${statistics.total.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.total.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.total.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.total.sum.toFixed(2)}`,
      },
      {
        key: 'amountPaid',
        statistic: 'Amount Paid',
        min: `${currencySymbol}${statistics.amountPaid.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.amountPaid.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.amountPaid.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.amountPaid.sum.toFixed(2)}`,
      },
      {
        key: 'balanceDue',
        statistic: 'Balance Due',
        min: `${currencySymbol}${statistics.balanceDue.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.balanceDue.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.balanceDue.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.balanceDue.sum.toFixed(2)}`,
      },
      {
        key: 'totalDiscount',
        statistic: 'Total Discount',
        min: `${currencySymbol}${statistics.totalDiscount.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.totalDiscount.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.totalDiscount.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.totalDiscount.sum.toFixed(2)}`,
      },
      {
        key: 'totalTax',
        statistic: 'Total Tax',
        min: `${currencySymbol}${statistics.totalTax.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.totalTax.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.totalTax.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.totalTax.sum.toFixed(2)}`,
      },
    ] : [
      {
        key: 'totalAmount',
        statistic: 'Total Amount',
        min: `${currencySymbol}${statistics.totalAmount.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.totalAmount.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.totalAmount.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.totalAmount.sum.toFixed(2)}`,
      },
      {
        key: 'amountPaid',
        statistic: 'Amount Paid',
        min: `${currencySymbol}${statistics.amountPaid.min.toFixed(2)}`,
        max: `${currencySymbol}${statistics.amountPaid.max.toFixed(2)}`,
        average: `${currencySymbol}${statistics.amountPaid.average.toFixed(2)}`,
        sum: `${currencySymbol}${statistics.amountPaid.sum.toFixed(2)}`,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        style={{ marginTop: 16 }}
      />
    );
  };

  return (
    <div>
      <Title level={2}>{businessOrganisationName} Accounting Dashboard</Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Invoices"
              value={invoiceStatistics?.totalCount || 0}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Revenue"
              value={invoiceStatistics?.total.sum || 0}
              precision={2}
              prefix={currencySymbol}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Payments"
              value={invoiceStatistics?.amountPaid.sum || 0}
              precision={2}
              prefix={currencySymbol}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="Total Balance Due"
              value={(invoiceStatistics?.total.sum || 0) - (paymentStatistics?.totalAmount.sum || 0)}
              precision={2}
              prefix={currencySymbol}
              valueStyle={{ color: '#faad14' }}
            />
          </Card>
        </Col>
      </Row>

      <Card style={{ marginTop: 16 }}>
        <Space style={{ marginBottom: 16 }} wrap>
          <Button icon={<PrinterOutlined />} onClick={handlePrint}>Print</Button>
          <Button icon={<FileExcelOutlined />} onClick={handleExportToExcel}>Export to Excel</Button>
        </Space>

        <Tabs activeKey={activeTab} onChange={(key) => { setActiveTab(key); setEditMode('Lookup'); }}>
          <TabPane tab="Invoices" key="invoices">
            {editMode === 'Edit' ? (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => setEditMode('Lookup')}
                    style={{ marginBottom: 16 }}
                  >
                    Back to List
                  </Button>
                </Col>
                <Col xs={24}>
                  <DynamicFormSP
                    key={selectedEntityId}
                    currentEntityId={selectedEntityId}
                    defaultEditMode="Edit"
                    primarySearchProp="invoiceNumber"
                    secondarySearchProp="customerName"
                    singularEntity="invoiceReport"
                    pluralEntity="invoiceReports"
                    constantPrefix="INVOICE_REPORT"
                    loadDto={loadInvoiceReportDto}
                    updateEntity={updateInvoiceReport}
                    getEntityDetails={getInvoiceReportDetails}
                    listEntities={listInvoiceReports}
                    listEntitiesByIds={listInvoiceReportsByIds}
                    onUpdatedEntityChange={() => { }}
                    isParent={true}
                    fieldsToHide={['Id']}
                    enableCreate={false}
                    enableSearchBar={false}
                    enableActionButtons={true}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <ListItemsDataTable
                    loading={loadingInvoices}
                    dtoConfig={{
                      InvoiceNumber: { isSummaryProp: true },
                      CustomerName: { isSummaryProp: true },
                      Total: { isSummaryProp: true },
                      InvoiceStatus: { isSummaryProp: true },
                    }}
                    data={invoiceReports}
                    filter={invoiceListFilter}
                    onFilterChange={setInvoiceListFilter}
                    currentPage={invoiceListFilter.page}
                    totalPages={invoiceListFilter.totalPages}
                    limit={invoiceListFilter.limit}
                    handleEditForm={handleEdit}
                    handleDeleteRow={handleDeleteInvoiceReport}
                    enableActionButtons={true}
                  />
                </Col>
                <Col xs={24}>
                  {renderStatisticsSummary(invoiceStatistics, 'invoices')}
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tab="Payments" key="payments">
            {editMode === 'Edit' ? (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => setEditMode('Lookup')}
                    style={{ marginBottom: 16 }}
                  >
                    Back to List
                  </Button>
                </Col>
                <Col xs={24}>
                  <DynamicFormSP
                    key={selectedEntityId}
                    currentEntityId={selectedEntityId}
                    defaultEditMode="Edit"
                    primarySearchProp="paymentNumber"
                    secondarySearchProp="customerName"
                    singularEntity="payment"
                    pluralEntity="payments"
                    constantPrefix="PAYMENT"
                    loadDto={loadPaymentDto}
                    updateEntity={updatePayment}
                    getEntityDetails={getPaymentDetails}
                    listEntities={listPayments}
                    listEntitiesByIds={listPaymentsByIds}
                    onUpdatedEntityChange={() => { }}
                    isParent={true}
                    fieldsToHide={['Id']}
                    enableCreate={false}
                    enableSearchBar={false}
                    enableActionButtons={true}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <ListItemsDataTable
                    loading={loadingPayments}
                    dtoConfig={paymentDto}
                    data={payments}
                    filter={paymentListFilter}
                    onFilterChange={setPaymentListFilter}
                    currentPage={paymentListFilter.page}
                    totalPages={paymentListFilter.totalPages}
                    limit={limit}
                    handleDeleteRow={handleDeletePayment}
                    handleSaveRow={() => { }}
                    onDataUpdated={() => { }}
                    handleEditForm={handleEdit}
                  
                    enableActionButtons={true}
                  />
                </Col>
                <Col xs={24}>
                  {renderStatisticsSummary(paymentStatistics, 'payments')}
                </Col>
              </Row>
            )}
          </TabPane>
        </Tabs>
      </Card>

      <Modal
        title={`Print ${businessOrganisationName} Accounting Summary`}
        visible={isPrinterVisible}
        onCancel={() => setIsPrinterVisible(false)}
        footer={null}
        width="70%"
      >
        <Printer
          configEndpoint="/print/configs/accounting_report"
          generateEndpoint="/print/documents/generate"
          data={printData}
        />
      </Modal>
    </div>
  );
};

export default AccountingScreen;