import {

    TEST_LIST_REQUEST,
    TEST_LIST_SUCCESS,
    TEST_LIST_FAIL,

    TEST_DETAILS_REQUEST,
    TEST_DETAILS_SUCCESS,
    TEST_DETAILS_FAIL,

    TEST_DELETE_REQUEST,
    TEST_DELETE_SUCCESS,
    TEST_DELETE_FAIL,

    TEST_CREATE_REQUEST,
    TEST_CREATE_SUCCESS,
    TEST_CREATE_FAIL,
    TEST_CREATE_RESET,

    TEST_UPDATE_REQUEST,
    TEST_UPDATE_SUCCESS,
    TEST_UPDATE_FAIL,
    TEST_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_DTO_FAIL,
    TEST_DTO_REQUEST,
    TEST_DTO_SUCCESS,
    TEST_DTO_RESET,

    TEST_LIST_BYIDS_REQUEST,
    TEST_LIST_BYIDS_SUCCESS,
    TEST_LIST_BYIDS_FAIL,
    TEST_LIST_BYIDS_RESET

} from "../constants/testConstants";

const initialState = {
    tests: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testListReducer = (
    state = {
        tests: []
    }, action) => {
    switch (action.type) {
        case TEST_LIST_REQUEST:
            return { loading: true, tests: [] };
        case TEST_LIST_SUCCESS:
            // our state will have a list of tests after the request
            return {
                loading: false,
                tests: action.payload.tests,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testListByIDsReducer = (
    state = {
        tests: []
    }, action) => {
    switch (action.type) {
        case TEST_LIST_BYIDS_REQUEST:
            return { loading: true, tests: [] };
        case TEST_LIST_BYIDS_SUCCESS:
            // our state will have a list of tests after the request
            return { loading: false, tests: action.payload };
        case TEST_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_LIST_BYIDS_RESET:
            return { tests: [] };
        default:
            return state;
    }
}


export const testDetailsReducer = (
    state = {
        test: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_DETAILS_SUCCESS:
            // our state will have a list of tests after the request
            return { loading: false, test: action.payload };
        case TEST_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testDtoReducer = (state = { testDto: {} }, action) => {
    switch (action.type) {
      case TEST_DTO_REQUEST:
        return { ...state, loading: true };
      case TEST_DTO_SUCCESS:
        return { loading: false, testDto: action.payload };
      case TEST_DTO_FAIL:
        return { loading: false, error: action.payload };
      case TEST_DTO_RESET:
        return { testDto: {} };
      default:
        return state;
    }
  };
  
export const testDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_DELETE_REQUEST:
            return { loading: true }

        case TEST_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_CREATE_REQUEST:
            return { loading: true }

        case TEST_CREATE_SUCCESS:
            return { loading: false, success: true, test: action.payload }

        case TEST_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_UPDATE_REQUEST:
            return { loading: true }

        case TEST_UPDATE_SUCCESS:
            return { loading: false, success: true, test: action.payload }

        case TEST_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_UPDATE_RESET:
            return { test: {} }

        default:
            return state
    }
}