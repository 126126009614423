import i18next from 'i18next';

export const getTranslatedOptions = () => {
    const t = (key) => i18next.t(key);

    return {
        statusOptions: [
            { label: t("Active"), value: "Active" },
            { label: t("Inactive"), value: "Inactive" },
            { label: t("Requested"), value: "Requested" },
            { label: t("Suspended"), value: "Suspended" },
            { label: t("Approved"), value: "Approved" },
            { label: t("Rejected"), value: "Rejected" },
            { label: t("Pending"), value: "Pending" },
            { label: t("Completed"), value: "Completed" },
            { label: t("Cancelled"), value: "Cancelled" }
        ],

        bloodTypeOptions: [
            { label: t('A+'), value: 'A+' },
            { label: t('A-'), value: 'A-' },
            { label: t('B+'), value: 'B+' },
            { label: t('B-'), value: 'B-' },
            { label: t('AB+'), value: 'AB+' },
            { label: t('AB-'), value: 'AB-' },
            { label: t('O+'), value: 'O+' },
            { label: t('O-'), value: 'O-' }
        ],

        genderOptions: [
            { label: t('Male'), value: 'Male' },
            { label: t('Female'), value: 'Female' }
        ],

        encounterTypeOptions: [
            { label: t('Inpatient'), value: 'Inpatient' },
            { label: t('Outpatient'), value: 'Outpatient' },
            { label: t('Emergency'), value: 'Emergency' },
            { label: t('Home Health'), value: 'Home Health' },
            { label: t('Telehealth'), value: 'Telehealth' },
            { label: t('Urgent Care'), value: 'Urgent Care' },
            { label: t('Ambulatory'), value: 'Ambulatory' },
            { label: t('Day Care'), value: 'Day Care' },
            { label: t('Virtual'), value: 'Virtual' },
            { label: t('Other'), value: 'Other' }
        ],

        orderTypeOptions: [
            { label: t('Routine'), value: 'Routine' },
            { label: t('Urgent'), value: 'Urgent' },
            { label: t('Stat'), value: 'Stat' },
            { label: t('External'), value: 'External' },
            { label: t('Pre-Op'), value: 'Pre-Op' },
            { label: t('Timed'), value: 'Timed' },
            { label: t('Standing'), value: 'Standing' },
            { label: t('On-Call'), value: 'On-Call' }
        ],

        sampleTypeOptions: [
            { label: t('Blood'), value: 'Blood' },
            { label: t('Urine'), value: 'Urine' },
            { label: t('Stool'), value: 'Stool' },
            { label: t('Sputum'), value: 'Sputum' },
            { label: t('Swab'), value: 'Swab' },
            { label: t('Tissue'), value: 'Tissue' },
            { label: t('Fluid'), value: 'Fluid' },
            { label: t('Serum'), value: 'Serum' },
            { label: t('Plasma'), value: 'Plasma' },
            { label: t('CSF'), value: 'CSF' },
            { label: t('Pleural Fluid'), value: 'Pleural Fluid' },
            { label: t('Peritoneal Fluid'), value: 'Peritoneal Fluid' },
            { label: t('Pericardial Fluid'), value: 'Pericardial Fluid' },
            { label: t('Synovial Fluid'), value: 'Synovial Fluid' },
            { label: t('Bone Marrow'), value: 'Bone Marrow' },
            { label: t('Bone'), value: 'Bone' },
            { label: t('Hair'), value: 'Hair' },
            { label: t('Nail'), value: 'Nail' },
            { label: t('Saliva'), value: 'Saliva' },
            { label: t('Feces'), value: 'Feces' },
            { label: t('Breath'), value: 'Breath' },
            { label: t('Semen'), value: 'Semen' },
            { label: t('Vaginal Fluid'), value: 'Vaginal Fluid' },
            { label: t('Amniotic Fluid'), value: 'Amniotic Fluid' },
            { label: t('Umbilical Cord Blood'), value: 'Umbilical Cord Blood' },
            { label: t('Sweat'), value: 'Sweat' },
            { label: t('Tears'), value: 'Tears' },
            { label: t('Milk'), value: 'Milk' },
            { label: t('Cerebrospinal Fluid'), value: 'Cerebrospinal Fluid' },
            { label: t('Ascitic Fluid'), value: 'Ascitic Fluid' },
            { label: t('Pericardial Fluid'), value: 'Pericardial Fluid' },
            { label: t('Peritoneal Fluid'), value: 'Peritoneal Fluid' },
            { label: t('Synovial Fluid'), value: 'Synovial Fluid' },
            { label: t('Pleural Fluid'), value: 'Pleural Fluid' },
            { label: t('Other'), value: 'Other' }
        ],

        containerTypeOptions: [
            { label: t('Vacutainer'), value: 'Vacutainer' },
            { label: t('SST'), value: 'SST' },
            { label: t('EDTA'), value: 'EDTA' },
            { label: t('Heparin'), value: 'Heparin' },
            { label: t('Fluoride Oxalate'), value: 'Fluoride Oxalate' },
            { label: t('Plain'), value: 'Plain' },
            { label: t('Urine Container'), value: 'Urine Container' },
            { label: t('Stool Container'), value: 'Stool Container' },
            { label: t('Swab Container'), value: 'Swab Container' },
            { label: t('Tissue Container'), value: 'Tissue Container' },
            { label: t('Fluid Container'), value: 'Fluid Container' },
            { label: t('Serum Separator Tube'), value: 'Serum Separator Tube' },
            { label: t('Plasma Separator Tube'), value: 'Plasma Separator Tube' },
            { label: t('CSF Container'), value: 'CSF Container' },
            { label: t('Pleural Fluid Container'), value: 'Pleural Fluid Container' },
            { label: t('Peritoneal Fluid Container'), value: 'Peritoneal Fluid Container' },
            { label: t('Pericardial Fluid Container'), value: 'Pericardial Fluid Container' },
            { label: t('Synovial Fluid Container'), value: 'Synovial Fluid Container' },
            { label: t('Bone Marrow Container'), value: 'Bone Marrow Container' },
            { label: t('Bone Container'), value: 'Bone Container' },
            { label: t('Hair Container'), value: 'Hair Container' },
            { label: t('Nail Container'), value: 'Nail Container' },
            { label: t('Saliva Container'), value: 'Saliva Container' },
            { label: t('Feces Container'), value: 'Feces Container' },
            { label: t('Breath Container'), value: 'Breath Container' },
            { label: t('Semen Container'), value: 'Semen Container' },
            { label: t('Vaginal Fluid Container'), value: 'Vaginal Fluid Container' },
            { label: t('Amniotic Fluid Container'), value: 'Amniotic Fluid Container' },
            { label: t('Umbilical Cord Blood Container'), value: 'Umbilical Cord Blood Container' },
            { label: t('Sweat Container'), value: 'Sweat Container' },
            { label: t('Tears Container'), value: 'Tears Container' },
            { label: t('Milk Container'), value: 'Milk Container' },
            { label: t('Cerebrospinal Fluid Container'), value: 'Cerebrospinal Fluid Container' },
            { label: t('Ascitic Fluid Container'), value: 'Ascitic Fluid Container' },
            { label: t('Pericardial Fluid Container'), value: 'Pericardial Fluid Container' },
            { label: t('Peritoneal Fluid Container'), value: 'Peritoneal Fluid Container' },
            { label: t('Synovial Fluid Container'), value: 'Synovial Fluid Container' },
            { label: t('Pleural Fluid Container'), value: 'Pleural Fluid Container' },
            { label: t('Other'), value: 'Other' }
        ],

        qcResultStatusOptions: [
            { label: t('Passed'), value: 'Passed' },
            { label: t('Failed'), value: 'Failed' },
            { label: t('Inconclusive'), value: 'Inconclusive' },
            { label: t('Pending'), value: 'Pending' },
            { label: t('Rejected'), value: 'Rejected' },
            { label: t('Aborted'), value: 'Aborted' }
        ],

        // Add subscription packages Starter, Silver, Platinum
        subscriptionPackageOptions: [
            { label: t('Starter'), value: 'Starter' },
            { label: t('Silver'), value: 'Silver' },
            { label: t('Platinum'), value: 'Platinum' }
        ],
    };
};

export const {
    statusOptions,
    bloodTypeOptions,
    genderOptions,
    encounterTypeOptions,
    orderTypeOptions,
    sampleTypeOptions,
    containerTypeOptions,
    qcResultStatusOptions
} = getTranslatedOptions();
