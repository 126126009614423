import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card, Form, Input, Button, Typography, Space, Alert, Select, theme } from 'antd';
import { MailOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ThemeContext } from '../index';
import logo from '../assets/images/logo.svg';

const { Title, Text, Paragraph } = Typography;
const { useToken } = theme;

function ResetPasswordSetupScreen() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { themeConfig } = useContext(ThemeContext);
    const { token } = useToken();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [resetMessage, setResetMessage] = useState("");

    useEffect(() => {
        document.title = t('resetPasswordSetup.pageTitle');
    }, [t]);

    const handleForgottenPassword = async (values) => {
        if (!values.email) {
            alert(t('resetPasswordSetup.emailRequired'));
            return;
        }

        try {
            setLoading(true);
            await axios.post(`/dotnet_api/v1/auth/forgotpassword`, {
                email: values.email,
                ClientURI: window.location.origin + "/user_reset_password"
            });
            setLoading(false);
            setResetMessage(t('resetPasswordSetup.linkSent'));
        } catch (err) {
            setLoading(false);
            alert(t('resetPasswordSetup.error'));
        }
    };

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
    };

    const languageOptions = [
        { value: 'en', label: '🇬🇧 English' },
        { value: 'fr', label: '🇫🇷 Français' },
    ];

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '100vh',
            background: token.colorBgLayout,
        }}>
            <Card 
                style={{ 
                    width: '100%',
                    maxWidth: 400,
                    boxShadow: `0 4px 8px ${token.colorBgElevated}`,
                    borderRadius: '8px',
                    background: token.colorBgContainer,
                }}
                cover={
                    <div style={{ 
                        background: 'transparent',
                        marginTop: '50px', 
                        marginBottom: '16px',
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                    }}>
                        <img
                            src={logo}
                            alt="logo"
                            style={{
                                width: '100%',
                                maxWidth: '200px',
                                filter: themeConfig.algorithm === theme.darkAlgorithm ? 'invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)' : 'none',
                            }}
                        />
                    </div>
                }
            >
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={3} style={{ textAlign: 'center', margin: 0, color: token.colorTextHeading }}>
                        {t('resetPasswordSetup.title')}
                    </Title>

                    <Paragraph>
                        {t('resetPasswordSetup.instruction')}
                    </Paragraph>

                    {resetMessage && (
                        <Alert
                            message={resetMessage}
                            type="success"
                            showIcon
                            icon={<CheckCircleOutlined />}
                        />
                    )}

                    <Form
                        form={form}
                        name="resetPasswordSetup"
                        onFinish={handleForgottenPassword}
                        layout="vertical"
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: t('resetPasswordSetup.emailRequired') }]}
                        >
                            <Input 
                                prefix={<MailOutlined />} 
                                size="large"
                                placeholder={t('resetPasswordSetup.emailPlaceholder')} 
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                style={{ width: '100%' }} 
                                loading={loading}
                            >
                                {loading ? t('resetPasswordSetup.sending') : t('resetPasswordSetup.sendLink')}
                            </Button>
                        </Form.Item>
                    </Form>

                    <Button 
                        icon={<ArrowLeftOutlined />} 
                        onClick={() => history.goBack()}
                        style={{ marginTop: 16 }}
                    >
                        {t('resetPasswordSetup.goBack')}
                    </Button>

                    <Select
                        defaultValue={i18n.language}
                        style={{ width: 120, marginTop: 16 }}
                        onChange={handleLanguageChange}
                        options={languageOptions}
                    />
                </Space>
            </Card>
        </div>
    );
}

export default ResetPasswordSetupScreen;