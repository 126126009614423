import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

const CommonFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      Prolab LIS ©{new Date().getFullYear()} {t("All Rights Reserved")}.
    </Footer>
  );
};

export default CommonFooter;