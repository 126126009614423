import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createIdentityUser } from "../../actions/userActions";
import { Button, Card, Alert, Space, Form, Input, Select, notification, Divider } from "antd";
import { ArrowLeftOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { IDENTITY_USER_CREATE_RESET } from "../../constants/userConstants";
import SingleEntitySelect from "../../components/crud/SingleEntitySelect.component";
import { listBusinessOrganisations } from "../../actions/businessOrganisationActions";
import { composeInitialProps, useTranslation } from 'react-i18next';

function CreateUserScreen({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [newIdentityUser, setNewIdentityUser] = useState({});

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, description) => {
    api.info({
      message: t('Success'),
      description: description,
      placement,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };

  useEffect(() => {
    dispatch({ type: IDENTITY_USER_CREATE_RESET });
    dispatch(listBusinessOrganisations());
  }, [dispatch]);

  const identityUserCreate = useSelector((state) => state.identityUserCreate);
  const { loading, error, success, user } = identityUserCreate;

  const handleCreateIdentityUser = () => {
    dispatch(createIdentityUser(newIdentityUser)).then(() => {
    });
  };

  useEffect(() => {
    if (success) {
        openNotification('bottom', 'User information updated successfully');
        dispatch({ type: IDENTITY_USER_CREATE_RESET });
    }
}, [success, dispatch]);


  return (
    <React.Fragment>
      {contextHolder}
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Button
          type="link"
          onClick={() => history.goBack()}
          icon={<ArrowLeftOutlined />}
        >
          {t("Return back")}
        </Button>

        <Card
          title={t("Create New Identity User")}
          bordered={false}
          style={{ width: "100%" }}
        >
          {error && (
            <Alert
              message={t("Error")}
              description={error}
              type="error"
              showIcon
            />
          )}

          <Alert
            message={t("Warning")}
            description={t("This is a new user account. The user will be sent an email to confirm their email account. They will then have to reset their password.")}
            type="warning"
            showIcon
          />

          <Divider />

          <Form
            name="createUserForm"
            layout="horizontal"
            labelCol={{ span: 6 }}
            onFinish={handleCreateIdentityUser}
            size="large"
          >
            <Form.Item label={t("Username")} name="userName" rules={[{ required: true, message: t('Please input the username!') }]}>
              <Input
                onChange={(e) =>
                  setNewIdentityUser({ ...newIdentityUser, userName: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label={t("Email")} name="email" rules={[{ required: true, message: t('Please input the email!') }, { type: 'email', message: t('Please enter a valid email!') }]}>
              <Input
                onChange={(e) =>
                  setNewIdentityUser({ ...newIdentityUser, email: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label={t("Password")} name="password" rules={[{ required: true, message: t('Please input the password!') }]}>
              <Input.Password
                onChange={(e) =>
                  setNewIdentityUser({ ...newIdentityUser, password: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label={t("Organization")} name="organizationId" rules={[{ required: true, message: t('Please select an organization!') }]}>
                <SingleEntitySelect
                    singularEntity="businessOrganisation"
                    pluralEntity="businessOrganisations"
                    constantPrefix="BUSINESS_ORGANISATION"
                    listEntities={listBusinessOrganisations}
                    onChange={(value) => setNewIdentityUser({ ...newIdentityUser, businessOrganisationId: value })}
                    value={newIdentityUser.businessOrganisationId || ""}
                    primarySearchProp="name"
                    secondarySearchProp="contactEmail"
                />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("Create User")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
    </React.Fragment>
  );
}

export default CreateUserScreen;