import axios from 'axios';

import {
    ATTACHMENT_LIST_REQUEST,
    ATTACHMENT_LIST_SUCCESS,
    ATTACHMENT_LIST_FAIL,

    ATTACHMENT_DETAILS_REQUEST,
    ATTACHMENT_DETAILS_SUCCESS,
    ATTACHMENT_DETAILS_FAIL,

    ATTACHMENT_DELETE_REQUEST,
    ATTACHMENT_DELETE_SUCCESS,
    ATTACHMENT_DELETE_FAIL,

    ATTACHMENT_CREATE_REQUEST,
    ATTACHMENT_CREATE_SUCCESS,
    ATTACHMENT_CREATE_FAIL,

    ATTACHMENT_UPDATE_REQUEST,
    ATTACHMENT_UPDATE_SUCCESS,
    ATTACHMENT_UPDATE_FAIL,

    ATTACHMENT_REQUEST,
    ATTACHMENT_FAIL,
    ATTACHMENT_RESET,
    ATTACHMENT_SUCCESS,

    ATTACHMENT_DTO_REQUEST,
    ATTACHMENT_DTO_SUCCESS,
    ATTACHMENT_DTO_FAIL,
    ATTACHMENT_DTO_RESET,

    ATTACHMENT_LIST_BYIDS_REQUEST,
    ATTACHMENT_LIST_BYIDS_SUCCESS,
    ATTACHMENT_LIST_BYIDS_FAIL,
    ATTACHMENT_LIST_BYIDS_RESET
    

} from "../constants/attachmentConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listAttachments = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: ATTACHMENT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/attachment/all', config);

        dispatch({
            type: ATTACHMENT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ATTACHMENT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later attachments",
        });
    }
}

// list attachments by ids
export const listAttachmentsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: ATTACHMENT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/attachment/ids/all', ids, config);

        dispatch({
            type: ATTACHMENT_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ATTACHMENT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later attachments",
        });
    }
}


export const loadAttachmentDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ATTACHMENT_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/attachment/attachmentDto', config);

        dispatch({
            type: ATTACHMENT_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ATTACHMENT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getAttachmentDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ATTACHMENT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/attachment/${_id}`, config);

        dispatch({
            type: ATTACHMENT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ATTACHMENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteAttachment = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ATTACHMENT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/attachment/${_id}/`,
            config
        )

        dispatch({
            type: ATTACHMENT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: ATTACHMENT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createAttachment = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ATTACHMENT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/attachment/`,
            changes,
            config
        )

        dispatch({
            type: ATTACHMENT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: ATTACHMENT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadAttachmentBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make attachment using a local attachment
export const makeAttachment = (file, attachmentId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const attachment = await loadAttachmentBase64(file);
    const attachment_input = JSON.stringify({ "attachment": attachment });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        attachment_input,
        config
    )
    try {
        dispatch({
            type: ATTACHMENT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": attachment }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: ATTACHMENT_SUCCESS
                });
                if (attachmentId && response.data) {
                    dispatch(updateAttachment(attachmentId, { attachment: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: ATTACHMENT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: ATTACHMENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateAttachment = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ATTACHMENT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/attachment/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: ATTACHMENT_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: ATTACHMENT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: ATTACHMENT_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}