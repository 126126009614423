export const PRICING_RULE_LIST_REQUEST = 'PRICING_RULE_LIST_REQUEST'
export const PRICING_RULE_LIST_SUCCESS = 'PRICING_RULE_LIST_SUCCESS'
export const PRICING_RULE_LIST_FAIL = 'PRICING_RULE_LIST_FAIL'


export const PRICING_RULE_DETAILS_REQUEST = 'PRICING_RULE_DETAILS_REQUEST'
export const PRICING_RULE_DETAILS_SUCCESS = 'PRICING_RULE_DETAILS_SUCCESS'
export const PRICING_RULE_DETAILS_FAIL = 'PRICING_RULE_DETAILS_FAIL'


export const PRICING_RULE_DELETE_REQUEST = 'PRICING_RULE_DELETE_REQUEST'
export const PRICING_RULE_DELETE_SUCCESS = 'PRICING_RULE_DELETE_SUCCESS'
export const PRICING_RULE_DELETE_FAIL = 'PRICING_RULE_DELETE_FAIL'

export const PRICING_RULE_CREATE_REQUEST = 'PRICING_RULE_CREATE_REQUEST'
export const PRICING_RULE_CREATE_SUCCESS = 'PRICING_RULE_CREATE_SUCCESS'
export const PRICING_RULE_CREATE_FAIL = 'PRICING_RULE_CREATE_FAIL'
export const PRICING_RULE_CREATE_RESET = 'PRICING_RULE_CREATE_RESET'

export const PRICING_RULE_UPDATE_REQUEST = 'PRICING_RULE_UPDATE_REQUEST'
export const PRICING_RULE_UPDATE_SUCCESS = 'PRICING_RULE_UPDATE_SUCCESS'
export const PRICING_RULE_UPDATE_FAIL = 'PRICING_RULE_UPDATE_FAIL'
export const PRICING_RULE_UPDATE_RESET = 'PRICING_RULE_UPDATE_RESET'

export const PRICING_RULE_REQUEST = 'PRICING_RULE_REQUEST'
export const PRICING_RULE_SUCCESS = 'PRICING_RULE_SUCCESS'
export const PRICING_RULE_FAIL = 'PRICING_RULE_FAIL'
export const PRICING_RULE_RESET = 'PRICING_RULE_RESET'

export const PRICING_RULE_COUNT_REQUEST = "PRICING_RULE_COUNT_REQUEST";
export const PRICING_RULE_COUNT_SUCCESS = "PRICING_RULE_COUNT_SUCCESS";
export const PRICING_RULE_COUNT_FAIL = "PRICING_RULE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const PRICING_RULE_DTO_REQUEST = 'PRICING_RULE_DTO_REQUEST';
export const PRICING_RULE_DTO_SUCCESS = 'PRICING_RULE_DTO_SUCCESS';
export const PRICING_RULE_DTO_FAIL = 'PRICING_RULE_DTO_FAIL';
export const PRICING_RULE_DTO_RESET = 'PRICING_RULE_DTO_RESET';

export const PRICING_RULE_LIST_BYIDS_REQUEST = 'PRICING_RULE_LIST_BYIDS_REQUEST';
export const PRICING_RULE_LIST_BYIDS_SUCCESS = 'PRICING_RULE_LIST_BYIDS_SUCCESS';
export const PRICING_RULE_LIST_BYIDS_FAIL = 'PRICING_RULE_LIST_BYIDS_FAIL';
export const PRICING_RULE_LIST_BYIDS_RESET = 'PRICING_RULE_LIST_BYIDS_RESET';