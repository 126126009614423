import {

    ENCOUNTER_LIST_REQUEST,
    ENCOUNTER_LIST_SUCCESS,
    ENCOUNTER_LIST_FAIL,

    ENCOUNTER_DETAILS_REQUEST,
    ENCOUNTER_DETAILS_SUCCESS,
    ENCOUNTER_DETAILS_FAIL,

    ENCOUNTER_DELETE_REQUEST,
    ENCOUNTER_DELETE_SUCCESS,
    ENCOUNTER_DELETE_FAIL,

    ENCOUNTER_CREATE_REQUEST,
    ENCOUNTER_CREATE_SUCCESS,
    ENCOUNTER_CREATE_FAIL,
    ENCOUNTER_CREATE_RESET,

    ENCOUNTER_UPDATE_REQUEST,
    ENCOUNTER_UPDATE_SUCCESS,
    ENCOUNTER_UPDATE_FAIL,
    ENCOUNTER_UPDATE_RESET,

    SET_CURRENT_PAGE,

    ENCOUNTER_DTO_FAIL,
    ENCOUNTER_DTO_REQUEST,
    ENCOUNTER_DTO_SUCCESS,
    ENCOUNTER_DTO_RESET,

    ENCOUNTER_LIST_BYIDS_REQUEST,
    ENCOUNTER_LIST_BYIDS_SUCCESS,
    ENCOUNTER_LIST_BYIDS_FAIL,
    ENCOUNTER_LIST_BYIDS_RESET

} from "../constants/encounterConstants";

const initialState = {
    encounters: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const encounterListReducer = (
    state = {
        encounters: []
    }, action) => {
    switch (action.type) {
        case ENCOUNTER_LIST_REQUEST:
            return { loading: true, encounters: [] };
        case ENCOUNTER_LIST_SUCCESS:
            // our state will have a list of encounters after the request
            return {
                loading: false,
                encounters: action.payload.encounters,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case ENCOUNTER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const encounterListByIDsReducer = (
    state = {
        encounters: []
    }, action) => {
    switch (action.type) {
        case ENCOUNTER_LIST_BYIDS_REQUEST:
            return { loading: true, encounters: [] };
        case ENCOUNTER_LIST_BYIDS_SUCCESS:
            // our state will have a list of encounters after the request
            return { loading: false, encounters: action.payload };
        case ENCOUNTER_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case ENCOUNTER_LIST_BYIDS_RESET:
            return { encounters: [] };
        default:
            return state;
    }
}


export const encounterDetailsReducer = (
    state = {
        encounter: { comments: [] }
    }, action) => {
    switch (action.type) {
        case ENCOUNTER_DETAILS_REQUEST:
            return { loading: true, ...state };
        case ENCOUNTER_DETAILS_SUCCESS:
            // our state will have a list of encounters after the request
            return { loading: false, encounter: action.payload };
        case ENCOUNTER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const encounterDtoReducer = (state = { encounterDto: {} }, action) => {
    switch (action.type) {
        case ENCOUNTER_DTO_REQUEST:
            return { loading: true, encounterDto: {} };
        case ENCOUNTER_DTO_SUCCESS:
            return { loading: false, encounterDto: action.payload };
        case ENCOUNTER_DTO_FAIL:
            return { loading: false, error: action.payload };
        case ENCOUNTER_DTO_RESET:
            return { encounterDto: {} };
        default:
            return state;
    }
}

export const encounterDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ENCOUNTER_DELETE_REQUEST:
            return { loading: true }

        case ENCOUNTER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ENCOUNTER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const encounterCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ENCOUNTER_CREATE_REQUEST:
            return { loading: true }

        case ENCOUNTER_CREATE_SUCCESS:
            return { loading: false, success: true, encounter: action.payload }

        case ENCOUNTER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ENCOUNTER_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const encounterUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case ENCOUNTER_UPDATE_REQUEST:
            return { loading: true }

        case ENCOUNTER_UPDATE_SUCCESS:
            return { loading: false, success: true, encounter: action.payload }

        case ENCOUNTER_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case ENCOUNTER_UPDATE_RESET:
            return { encounter: {} }

        default:
            return state
    }
}