export const TEST_ORDER_LIST_REQUEST = 'TEST_ORDER_LIST_REQUEST'
export const TEST_ORDER_LIST_SUCCESS = 'TEST_ORDER_LIST_SUCCESS'
export const TEST_ORDER_LIST_FAIL = 'TEST_ORDER_LIST_FAIL'


export const TEST_ORDER_DETAILS_REQUEST = 'TEST_ORDER_DETAILS_REQUEST'
export const TEST_ORDER_DETAILS_SUCCESS = 'TEST_ORDER_DETAILS_SUCCESS'
export const TEST_ORDER_DETAILS_FAIL = 'TEST_ORDER_DETAILS_FAIL'


export const TEST_ORDER_DELETE_REQUEST = 'TEST_ORDER_DELETE_REQUEST'
export const TEST_ORDER_DELETE_SUCCESS = 'TEST_ORDER_DELETE_SUCCESS'
export const TEST_ORDER_DELETE_FAIL = 'TEST_ORDER_DELETE_FAIL'

export const TEST_ORDER_CREATE_REQUEST = 'TEST_ORDER_CREATE_REQUEST'
export const TEST_ORDER_CREATE_SUCCESS = 'TEST_ORDER_CREATE_SUCCESS'
export const TEST_ORDER_CREATE_FAIL = 'TEST_ORDER_CREATE_FAIL'
export const TEST_ORDER_CREATE_RESET = 'TEST_ORDER_CREATE_RESET'

export const TEST_ORDER_UPDATE_REQUEST = 'TEST_ORDER_UPDATE_REQUEST'
export const TEST_ORDER_UPDATE_SUCCESS = 'TEST_ORDER_UPDATE_SUCCESS'
export const TEST_ORDER_UPDATE_FAIL = 'TEST_ORDER_UPDATE_FAIL'
export const TEST_ORDER_UPDATE_RESET = 'TEST_ORDER_UPDATE_RESET'

export const TEST_ORDER_REQUEST = 'TEST_ORDER_REQUEST'
export const TEST_ORDER_SUCCESS = 'TEST_ORDER_SUCCESS'
export const TEST_ORDER_FAIL = 'TEST_ORDER_FAIL'
export const TEST_ORDER_RESET = 'TEST_ORDER_RESET'

export const TEST_ORDER_COUNT_REQUEST = "TEST_ORDER_COUNT_REQUEST";
export const TEST_ORDER_COUNT_SUCCESS = "TEST_ORDER_COUNT_SUCCESS";
export const TEST_ORDER_COUNT_FAIL = "TEST_ORDER_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const TEST_ORDER_DTO_REQUEST = 'TEST_ORDER_DTO_REQUEST';
export const TEST_ORDER_DTO_SUCCESS = 'TEST_ORDER_DTO_SUCCESS';
export const TEST_ORDER_DTO_FAIL = 'TEST_ORDER_DTO_FAIL';
export const TEST_ORDER_DTO_RESET = 'TEST_ORDER_DTO_RESET';

export const TEST_ORDER_LIST_BYIDS_REQUEST = 'TEST_ORDER_LIST_BYIDS_REQUEST';
export const TEST_ORDER_LIST_BYIDS_SUCCESS = 'TEST_ORDER_LIST_BYIDS_SUCCESS';
export const TEST_ORDER_LIST_BYIDS_FAIL = 'TEST_ORDER_LIST_BYIDS_FAIL';
export const TEST_ORDER_LIST_BYIDS_RESET = 'TEST_ORDER_LIST_BYIDS_RESET';