export const QC_RESULT_LIST_REQUEST = 'QC_RESULT_LIST_REQUEST';
export const QC_RESULT_LIST_SUCCESS = 'QC_RESULT_LIST_SUCCESS';
export const QC_RESULT_LIST_FAIL = 'QC_RESULT_LIST_FAIL';

export const QC_RESULT_DETAILS_REQUEST = 'QC_RESULT_DETAILS_REQUEST';
export const QC_RESULT_DETAILS_SUCCESS = 'QC_RESULT_DETAILS_SUCCESS';
export const QC_RESULT_DETAILS_FAIL = 'QC_RESULT_DETAILS_FAIL';

export const QC_RESULT_DELETE_REQUEST = 'QC_RESULT_DELETE_REQUEST';
export const QC_RESULT_DELETE_SUCCESS = 'QC_RESULT_DELETE_SUCCESS';
export const QC_RESULT_DELETE_FAIL = 'QC_RESULT_DELETE_FAIL';

export const QC_RESULT_CREATE_REQUEST = 'QC_RESULT_CREATE_REQUEST';
export const QC_RESULT_CREATE_SUCCESS = 'QC_RESULT_CREATE_SUCCESS';
export const QC_RESULT_CREATE_FAIL = 'QC_RESULT_CREATE_FAIL';

export const QC_RESULT_UPDATE_REQUEST = 'QC_RESULT_UPDATE_REQUEST';
export const QC_RESULT_UPDATE_SUCCESS = 'QC_RESULT_UPDATE_SUCCESS';
export const QC_RESULT_UPDATE_FAIL = 'QC_RESULT_UPDATE_FAIL';

export const QC_RESULT_DTO_REQUEST = 'QC_RESULT_DTO_REQUEST';
export const QC_RESULT_DTO_SUCCESS = 'QC_RESULT_DTO_SUCCESS';
export const QC_RESULT_DTO_FAIL = 'QC_RESULT_DTO_FAIL';
export const QC_RESULT_DTO_RESET = 'QC_RESULT_DTO_RESET';

export const QC_RESULT_LIST_BYIDS_REQUEST = 'QC_RESULT_LIST_BYIDS_REQUEST';
export const QC_RESULT_LIST_BYIDS_SUCCESS = 'QC_RESULT_LIST_BYIDS_SUCCESS';
export const QC_RESULT_LIST_BYIDS_FAIL = 'QC_RESULT_LIST_BYIDS_FAIL';
export const QC_RESULT_LIST_BYIDS_RESET = 'QC_RESULT_LIST_BYIDS_RESET';