import axios from 'axios';

import {
    QC_SCHEDULE_LIST_REQUEST,
    QC_SCHEDULE_LIST_SUCCESS,
    QC_SCHEDULE_LIST_FAIL,

    QC_SCHEDULE_DETAILS_REQUEST,
    QC_SCHEDULE_DETAILS_SUCCESS,
    QC_SCHEDULE_DETAILS_FAIL,

    QC_SCHEDULE_DELETE_REQUEST,
    QC_SCHEDULE_DELETE_SUCCESS,
    QC_SCHEDULE_DELETE_FAIL,

    QC_SCHEDULE_CREATE_REQUEST,
    QC_SCHEDULE_CREATE_SUCCESS,
    QC_SCHEDULE_CREATE_FAIL,

    QC_SCHEDULE_UPDATE_REQUEST,
    QC_SCHEDULE_UPDATE_SUCCESS,
    QC_SCHEDULE_UPDATE_FAIL,

    QC_SCHEDULE_DTO_REQUEST,
    QC_SCHEDULE_DTO_SUCCESS,
    QC_SCHEDULE_DTO_FAIL,
    QC_SCHEDULE_DTO_RESET,

    QC_SCHEDULE_LIST_BYIDS_REQUEST,
    QC_SCHEDULE_LIST_BYIDS_SUCCESS,
    QC_SCHEDULE_LIST_BYIDS_FAIL,
    QC_SCHEDULE_LIST_BYIDS_RESET
} from "../constants/qcScheduleConstants";

export const listQCSchedules = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_SCHEDULE_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/qcSchedule/all', config);

        dispatch({
            type: QC_SCHEDULE_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const listQCSchedulesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_SCHEDULE_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/qcSchedule/ids/all', ids, config);

        dispatch({
            type: QC_SCHEDULE_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getQCScheduleDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_SCHEDULE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/qcSchedule/${id}`, config);

        dispatch({
            type: QC_SCHEDULE_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteQCSchedule = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_SCHEDULE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/qcSchedule/${id}/`,
            config
        )

        dispatch({
            type: QC_SCHEDULE_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createQCSchedule = (qcSchedule) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_SCHEDULE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/dotnet_api/v1/qcSchedule/`,
            qcSchedule,
            config
        )

        dispatch({
            type: QC_SCHEDULE_CREATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateQCSchedule = (id, qcSchedule) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_SCHEDULE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.patch(
            `/dotnet_api/v1/qcSchedule/${id}/`,
            qcSchedule,
            config
        )

        dispatch({
            type: QC_SCHEDULE_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: QC_SCHEDULE_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const loadQCScheduleDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_SCHEDULE_DTO_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/dotnet_api/v1/qcSchedule/qcScheduleDto`,
            config
        )

        dispatch({
            type: QC_SCHEDULE_DTO_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_SCHEDULE_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}