import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Table, Button, Space, Modal, Form, Input, Select,
  Popconfirm, message, Card, Typography, Tag, Drawer, Spin, Row, Col, Affix, Tooltip, Divider
} from 'antd';
import {
  EditOutlined, DeleteOutlined, MailOutlined, SearchOutlined,
  CheckCircleOutlined, CloseCircleOutlined, UserOutlined, PlusOutlined,
  FileTextOutlined, SyncOutlined, HistoryOutlined, FilterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';

import {
  listBusinessOrganisations,
  createBusinessOrganisation,
  updateBusinessOrganisation,
  deleteBusinessOrganisation,
  activateBusinessOrganisation,
  deactivateBusinessOrganisation,
  getBusinessOrganisationDetails,
  loadBusinessOrganisationDto,
  sendEmail,
  listBusinessOrganisationsByIds
} from '../../actions/businessOrganisationActions';

import {
  listUsers,
  deleteUser,
  getAllRoles
} from '../../actions/userActions';

import DynamicFormSP from '../../components/crud/DynamicFormSP.component';

const { Title, Text } = Typography;

const BusinessOrganisationDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [emailForm] = Form.useForm();

  const [state, setState] = useState({
    isDrawerVisible: false,
    editingOrganisation: null,
    emailModalVisible: false,
    selectedOrganisation: null,
    activeTab: 'organisations',
    userListFilter: {
      query: '',
      page: 1,
      limit: 10,
      filter: 'userName',
    },
    organisationListFilter: {
      query: '',
      page: 1,
      limit: 10,
      filter: 'name',
    },
    searchText: '',
    searchedColumn: '',
    searchProp: '',
    selectedRowKeys: [],
    isBulkDeleting: false,
  });

  const [emailSending, setEmailSending] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);

  const businessOrganisationList = useSelector(state => state.businessOrganisationList);
  const { loading, error, businessOrganisations, currentPage, totalPages } = businessOrganisationList;

  const businessOrganisationDelete = useSelector(state => state.businessOrganisationDelete);
  const { loading: deleteLoading, success: deleteSuccess } = businessOrganisationDelete;

  const businessOrganisationActivate = useSelector(state => state.businessOrganisationActivate);
  const { loading: activateLoading, success: activateSuccess } = businessOrganisationActivate;

  const businessOrganisationDeactivate = useSelector(state => state.businessOrganisationDeactivate);
  const { loading: deactivateLoading, success: deactivateSuccess } = businessOrganisationDeactivate;

  const userList = useSelector(state => state.userList);
  const { loading: userLoading, users } = userList;

  useEffect(() => {
    dispatch(listBusinessOrganisations(state.organisationListFilter));
    dispatch(listUsers(state.userListFilter));
    dispatch(getAllRoles()).then(roles => setAvailableRoles(roles));
  }, [dispatch, state.userListFilter, state.organisationListFilter]);

  useEffect(() => {
    if (deleteSuccess || activateSuccess || deactivateSuccess) {
      dispatch(listBusinessOrganisations(state.organisationListFilter));
    }
  }, [deleteSuccess, activateSuccess, deactivateSuccess, dispatch]);

  const showDrawer = (organisation = null) => {
    setState(prev => ({ ...prev, editingOrganisation: organisation, isDrawerVisible: true }));
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteBusinessOrganisation(id));
      await handleDeletePrintFolders(id);
      message.success(t('Business organisation and associated print folders deleted successfully'));
      handleRefresh();
    } catch (error) {
      console.error('Error deleting business organisation:', error);
      message.error(t('Failed to delete business organisation or associated print folders'));
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await dispatch(deleteUser(id));
      message.success(t('User deleted successfully'));
      dispatch(listUsers(state.userListFilter));
    } catch (error) {
      console.error('Error deleting user:', error);
      message.error(t('Failed to delete user'));
    }
  };

  const handleDeleteTemplates = async (organisationId) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      await axios.delete(`/print/documents/organisation/${organisationId}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      });
      message.success(t('Templates deleted successfully'));
    } catch (error) {
      console.error('Error deleting templates:', error);
      message.error(t('Failed to delete templates'));
    }
  };
  
  const handleSyncTemplates = async (organisationId) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      await axios.post(`/print/documents/sync/${organisationId}`, null, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      });
      message.success(t('Templates synced successfully'));
    } catch (error) {
      console.error('Error syncing templates:', error);
      message.error(t('Failed to sync templates'));
    }
  };

  const showEmailModal = (organisation) => {
    setState(prev => ({
      ...prev,
      selectedOrganisation: organisation,
      emailModalVisible: true
    }));
    emailForm.setFieldsValue({ to: organisation.contactEmail });
  };

  const handleSendEmail = (organisation) => {
    setEmailSending(true);
    emailForm.validateFields().then((values) => {
      dispatch(sendEmail({
        "EmailToId": values.to,
        "EmailToName": organisation.name,
        "EmailSubject": values.subject,
        "EmailBody": values.message
      }))
        .then(() => {
          setEmailSending(false);
          message.success(t('Email sent successfully'));
        })
        .catch((error) => {
          setEmailSending(false);
          message.error(t('Failed to send email: {{error}}', { error: error.message }));
        });
    }).catch(() => {
      setEmailSending(false);
    });
  };

  const generateEmailTemplate = (template) => {
    const { selectedOrganisation } = state;
    let subject = '';
    let message = '';
    switch (template) {
      case 'subscription_reminder':
        subject = t('Subscription Reminder');
        message = t('Dear {{name}},\n\nThis is a friendly reminder that your subscription is due to expire on {{date}}. Please renew your subscription to continue using our services.\n\nBest regards,\nThe Prolab Team', {
          name: selectedOrganisation.name,
          date: moment(selectedOrganisation.subscriptionExpiryDate).format('YYYY-MM-DD')
        });
        break;
      case 'payment_reminder':
        subject = t('Payment Reminder');
        message = t('Dear {{name}},\n\nWe noticed that your last payment was on {{date}}. Please ensure your payment information is up to date to avoid any service interruptions.\n\nBest regards,\nThe Prolab Team', {
          name: selectedOrganisation.name,
          date: moment(selectedOrganisation.lastPaymentDate).format('YYYY-MM-DD')
        });
        break;
      case 'welcome_email':
        subject = t('Welcome to Prolab');
        message = t('Dear {{name}},\n\nWelcome to Prolab! We\'re thrilled to have you on board. Your subscription package is {{package}}.\n\nIf you have any questions, feel free to reach out to us.\n\nBest regards,\nThe Prolab Team', {
          name: selectedOrganisation.name,
          package: selectedOrganisation.subscriptionPackage
        });
        break;
      default:
        break;
    }
    emailForm.setFieldsValue({ subject, message });
  };

  const handleEditUser = (userId) => {
    history.push(`/users/${userId}`);
  };

  const handleCreateUser = () => {
    history.push('/users/create');
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState(prev => ({
      ...prev,
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      [state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter']: {
        ...prev[state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter'],
        query: selectedKeys[0],
        filter: dataIndex,
      }
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState(prev => ({ ...prev, searchText: '' }));
  };

  const handleActivateDeactivate = (id, isActive) => {
    if (isActive) {
      dispatch(deactivateBusinessOrganisation(id));
    } else {
      dispatch(activateBusinessOrganisation(id));
    }
  };

  const handleDeletePrintFolders = async (organisationId) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      await axios.delete(`/print/documents/organisation/${organisationId}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        },
        data: { organisationId }
      });
      message.success(t('Print folders deleted successfully'));
    } catch (error) {
      console.error('Error deleting print folders:', error);
      message.error(t('Failed to delete print folders'));
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = {
    organisations: [
      {
        title: t('Name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t('Identifier'),
        dataIndex: 'identifier',
        key: 'identifier',
        ...getColumnSearchProps('identifier'),
      },
      {
        title: t('Subscription Package'),
        dataIndex: 'subscriptionPackage',
        key: 'subscriptionPackage',
        ...getColumnSearchProps('subscriptionPackage'),
      },
      {
        title: t('Subscription Status'),
        dataIndex: 'isSubscriptionActive',
        key: 'isSubscriptionActive',
        filters: [
          { text: t('Active'), value: 'true' },
          { text: t('Inactive'), value: 'false' },
        ],
        onFilter: (value, record) => record.isSubscriptionActive.toString() === value,
        render: (isActive) => (
          <Tag color={isActive ? 'green' : 'red'}>
            {isActive ? t('Active') : t('Inactive')}
          </Tag>
        ),
      },
      {
        title: t('Expiry Date'),
        dataIndex: 'subscriptionExpiryDate',
        key: 'subscriptionExpiryDate',
        render: (date) => moment(date).format('YYYY-MM-DD'),
        sorter: (a, b) => moment(a.subscriptionExpiryDate).unix() - moment(b.subscriptionExpiryDate).unix(),
      },
      {
        title: t('Actions'),
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Button icon={<EditOutlined />} onClick={() => showDrawer(record)} />
            {!record.databaseConnectionString.includes("Database=prolab_core;") && (
              <>
                <Popconfirm
                  title={t("Are you sure you want to delete this business organisation?")}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <Button icon={<DeleteOutlined />} danger loading={deleteLoading} />
                </Popconfirm>
                <Popconfirm
                  title={record.isSubscriptionActive ? t("Are you sure you want to deactivate this business organisation?") : t("Are you sure you want to activate this business organisation?")}
                  onConfirm={() => handleActivateDeactivate(record.id, record.isSubscriptionActive)}
                >
                  <Button icon={record.isSubscriptionActive ? <CloseCircleOutlined /> : <CheckCircleOutlined />}>
                    {record.isSubscriptionActive ? t('Deactivate') : t('Activate')}
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title={t("Are you sure you want to delete this organisation's templates?")}
                  onConfirm={() => handleDeleteTemplates(record.id)}
                >
                  <Button icon={<DeleteOutlined />} danger>
                  {t('Delete Templates')}
                  </Button>
                </Popconfirm>
              </>
            )}
            <Popconfirm
              title={t("Are you sure you want to sync this organisation's templates?")}
              onConfirm={() => handleSyncTemplates(record.id)}
            >
              <Button icon={<SyncOutlined />}>
                {t('Sync Templates')}
              </Button>
            </Popconfirm>
            <Button icon={<MailOutlined />} onClick={() => showEmailModal(record)}>
              {t('Email')}
            </Button>
          </Space>
        ),
      },
    ],
    users: [
      {
        title: t('Name'),
        dataIndex: 'userName',
        key: 'userName',
        ...getColumnSearchProps('userName'),
        sorter: (a, b) => a.userName.localeCompare(b.userName),
      },
      {
        title: t('Email'),
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
      },
      {
        title: t('Roles'),
        dataIndex: 'roles',
        key: 'roles',
        filters: availableRoles?.map(role => ({ text: t(role), value: role })),
        onFilter: (value, record) => record.roles.includes(value),
        render: (roles) => (
          <>
            {roles?.map(role => (
              <Tag color="blue" key={role}>
                {t(role)}
              </Tag>
            ))}
          </>
        ),
      },
      {
        title: t('Actions'),
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Button icon={<EditOutlined />} onClick={() => handleEditUser(record.id)}>
              {t('Edit')}
            </Button>
            <Popconfirm
              title={t("Are you sure you want to delete this user?")}
              onConfirm={() => handleDeleteUser(record.id)}
            >
              <Button icon={<DeleteOutlined />} danger>
                {t('Delete')}
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ]
  };

  const handleFilterChange = (pagination, filters, sorter) => {
    setState(prev => ({
      ...prev,
      [state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter']: {
        ...prev[state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter'],
        page: pagination.current,
        limit: pagination.pageSize,
      }
    }));
  };

  const handleRefresh = () => {
    setState(prev => ({
      ...prev,
      [state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter']: {
        query: '',
        page: 1,
        limit: 10,
        filter: state.activeTab === 'organisations' ? 'name' : 'userName',
      },
      searchText: '',
      searchedColumn: '',
      searchProp: '',
      selectedRowKeys: [],
    }));
  };

  const handleSearchPropChange = (value) => {
    setState(prev => ({
      ...prev,
      searchProp: value,
      [state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter']: {
        ...prev[state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter'],
        filter: value,
      }
    }));
  };

  const handleBulkDelete = async () => {
    setState(prev => ({ ...prev, isBulkDeleting: true }));
    try {
      for (const key of state.selectedRowKeys) {
        if (state.activeTab === 'organisations') {
          await handleDelete(key);
        } else {
          await handleDeleteUser(key);
        }
      }
      setState(prev => ({ ...prev, selectedRowKeys: [], isBulkDeleting: false }));
      message.success(t('Selected items have been deleted.'));
      handleRefresh();
    } catch (error) {
      console.error(`Failed to delete some items:`, error);
      message.error(t('Failed to delete some items. Please try again.'));
      setState(prev => ({ ...prev, isBulkDeleting: false }));
    }
  };

  const tableTitle = () => {
    return (
      <div style={{ width: '100%' }}>
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} sm={24} md={4} lg={3} xl={2}>
            <Text strong>
              <FilterOutlined /> {t("Filter")}:
            </Text>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <Select
              placeholder={t("Search by")}
              style={{ width: '100%' }}
              value={state.searchProp}
              onChange={handleSearchPropChange}
            >
              {columns[state.activeTab].map((column) => (
                column.dataIndex && (
                  <Select.Option key={column.dataIndex} value={column.dataIndex}>
                    {column.title}
                  </Select.Option>
                )
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={10} lg={12} xl={14}>
            <Input.Search
              placeholder={`${t("Search by")} ${state.searchProp}`}
              onSearch={(value) => {
                setState(prev => ({
                  ...prev,
                  searchText: value,
                  [state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter']: {
                    ...prev[state.activeTab === 'organisations' ? 'organisationListFilter' : 'userListFilter'],
                    query: value,
                  }
                }));
              }}
              value={state.searchText}
              onChange={(e) => setState(prev => ({ ...prev, searchText: e.target.value }))}
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
            <Button
              type="text"
              icon={<HistoryOutlined />}
              onClick={handleRefresh}
            >
              {t("Refresh")}
            </Button>
          </Col>
        </Row>
        {state.selectedRowKeys.length > 0 && (
          <>
            <Divider style={{ margin: '16px 0' }} />
            <Row justify="center">
              <Col>
                <Popconfirm
                  title={t("Delete selected items")}
                  description={t("Are you sure you want to delete these items?")}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  onConfirm={handleBulkDelete}
                >
                  <Button type="primary" danger loading={state.isBulkDeleting}>
                    {t("Delete")} ({state.selectedRowKeys.length})
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  };
  
  return (
    <div>
  <Title level={2}>{t('Business Organisation Dashboard')}</Title>
  <Row gutter={[16, 16]}>
    <Col xs={24} sm={24} md={6} lg={5} xl={4}>
      <Affix offsetTop={80}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button
              type={state.activeTab === 'organisations' ? 'primary' : 'default'}
              icon={<FileTextOutlined />}
              onClick={() => setState(prev => ({ ...prev, activeTab: 'organisations' }))}
              block
            >
              {t('Organisations')}
            </Button>
            <Button
              type={state.activeTab === 'users' ? 'primary' : 'default'}
              icon={<UserOutlined />}
              onClick={() => setState(prev => ({ ...prev, activeTab: 'users' }))}
              block
            >
              {t('Users')}
            </Button>
          </Space>
        </Card>
      </Affix>
    </Col>
    <Col xs={24} sm={24} md={18} lg={19} xl={20}>
      <Card>
        <Space wrap style={{ marginBottom: 16 }}>
          {state.activeTab === 'organisations' && (
            <Button type="primary" onClick={() => showDrawer()} icon={<PlusOutlined />}>
              {t('Add Organisation')}
            </Button>
          )}
          {state.activeTab === 'users' && (
            <Button type="primary" onClick={handleCreateUser} icon={<PlusOutlined />}>
              {t('Add User')}
            </Button>
          )}
        </Space>
        <Table
          title={tableTitle}
          columns={columns[state.activeTab]}
          dataSource={state.activeTab === 'organisations' ? businessOrganisations : users}
          rowKey="id"
          loading={state.activeTab === 'organisations' ? loading : userLoading}
          pagination={{
            current: state.activeTab === 'organisations' ? state.organisationListFilter.page : state.userListFilter.page,
            pageSize: state.activeTab === 'organisations' ? state.organisationListFilter.limit : state.userListFilter.limit,
            total: totalPages * (state.activeTab === 'organisations' ? state.organisationListFilter.limit : state.userListFilter.limit),
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          onChange={handleFilterChange}
          scroll={{ x: 'max-content' }}
          rowSelection={{
            selectedRowKeys: state.selectedRowKeys,
            onChange: (selectedRowKeys) => setState(prev => ({ ...prev, selectedRowKeys })),
          }}
        />
      </Card>
    </Col>
  </Row>

  <Modal
    title={t("Send Email")}
    visible={state.emailModalVisible}
    onCancel={() => setState(prev => ({ ...prev, emailModalVisible: false }))}
    footer={[
      <Button key="close" onClick={() => setState(prev => ({ ...prev, emailModalVisible: false }))}>
        {t("Close")}
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={() => handleSendEmail(state.selectedOrganisation)}
        loading={emailSending}
        icon={<MailOutlined />}
      >
        {t("Send")}
      </Button>,
    ]}
  >
    <Spin spinning={emailSending}>
      <Form form={emailForm} layout="vertical">
        <Form.Item name="to" label={t("To")} rules={[{ required: true, message: t('Please input the recipient email!') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="subject" label={t("Subject")} rules={[{ required: true, message: t('Please input the email subject!') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="message" label={t("Message")} rules={[{ required: true, message: t('Please input the email message!') }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Space wrap>
          <Button onClick={() => generateEmailTemplate('subscription_reminder')}>{t("Subscription Reminder")}</Button>
          <Button onClick={() => generateEmailTemplate('payment_reminder')}>{t("Payment Reminder")}</Button>
          <Button onClick={() => generateEmailTemplate('welcome_email')}>{t("Welcome Email")}</Button>
        </Space>
      </Form>
    </Spin>
  </Modal>

  <Drawer
    title={state.editingOrganisation ? t("Edit Business Organisation") : t("Create Business Organisation")}
    placement="right"
    closable={false}
    onClose={() => setState(prev => ({ ...prev, isDrawerVisible: false }))}
    visible={state.isDrawerVisible}
    width={window.innerWidth > 768 ? 720 : '100%'}
  >
    <DynamicFormSP
      key={state.editingOrganisation ? state.editingOrganisation.id : 'new'}
      currentEntityId={state.editingOrganisation ? state.editingOrganisation.id : null}
      defaultEditMode={state.editingOrganisation ? "Edit" : "Create"}
      singularEntity="businessOrganisation"
      pluralEntity="businessOrganisations"
      constantPrefix="BUSINESS_ORGANISATION"
      loadDto={loadBusinessOrganisationDto}
      createEntity={createBusinessOrganisation}
      updateEntity={updateBusinessOrganisation}
      getEntityDetails={getBusinessOrganisationDetails}
      listEntities={listBusinessOrganisations}
      listEntitiesByIds={listBusinessOrganisationsByIds}
      onUpdatedEntityChange={(updatedEntity) => {
        // Handle updated entity if needed
      }}
      onCreatedEntityChange={(createdEntity) => {
        // Handle created entity if needed
      }}
      isParent={true}
      fieldsToHide={['Id']}
      summaryProps={['name', 'identifier', 'subscriptionPackage', 'isSubscriptionActive']}
      enableCreate={true}
      enableSearchBar={false}
      enableActionButtons={false}
    />
  </Drawer>
</div>
  );
};

export default BusinessOrganisationDashboard;