import React from 'react';
import { Space, Select, Input, DatePicker, Button, Divider, Popconfirm } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const TableTitle = ({
  dtoConfig,
  searchProp,
  handleSearchPropChange,
  searchText,
  setSearchText,
  onFilterChange,
  filter,
  dateRange,
  handleDateRangeChange,
  limit,
  setSearchedColumn,
  setSearchProp,
  setDateRange,
  selectedRowKeys,
  handleBulkDelete,
  isBulkDeleting,
  t
}) => {
  return (
    <React.Fragment>
      <Space wrap style={{ marginBottom: '16px' }}>
        <span>Filter:</span>
        <Select
          placeholder={t("Search by")}
          style={{ width: '150px', flex: 1 }}
          value={searchProp}
          onChange={handleSearchPropChange}
        >
          {Object.keys(dtoConfig)
            .filter((key) => dtoConfig[key].isSummaryProp)
            ?.map((key) => (
              <Select.Option key={key} value={key}>
                {key}
              </Select.Option>
            ))}
        </Select>
        <Input.Search
          placeholder={`${t("Search by")} ${searchProp}`}
          onSearch={(value) => {
            setSearchText(value);
            onFilterChange({ ...filter, query: value });
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ minWidth: '200px', flex: 2 }}
        />
        {Object.values(dtoConfig).some((field) => field.type === 'DateTime') && (
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            value={dateRange}
            allowClear={true}
            onChange={handleDateRangeChange}
            format="YYYY-MM-DD HH:mm"
            style={{ width: '300px', flex: 1 }}
          />
        )}
        <Button
          type="default"
          icon={<HistoryOutlined />}
          onClick={() => {
            onFilterChange({ query: '', page: 1, limit: limit, filter: '' });
            setSearchText('');
            setSearchedColumn('');
            setSearchProp('');
            setDateRange([]);
          }}
          style={{ minWidth: '100px' }}
        >
          {t("Refresh")}
        </Button>
      </Space>

      {selectedRowKeys.length > 0 && (
        <>
          <Divider style={{ margin: '12px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Popconfirm
              title={t("Delete selected items")}
              description={t("Are you sure you want to delete these items?")}
              okText={t("Yes")}
              cancelText={t("No")}
              onConfirm={handleBulkDelete}
            >
              <Button type="primary" danger loading={isBulkDeleting}>
                {t("Delete")} ({selectedRowKeys.length})
              </Button>
            </Popconfirm>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default TableTitle;