import {

    PRACTITIONER_LIST_REQUEST,
    PRACTITIONER_LIST_SUCCESS,
    PRACTITIONER_LIST_FAIL,

    PRACTITIONER_DETAILS_REQUEST,
    PRACTITIONER_DETAILS_SUCCESS,
    PRACTITIONER_DETAILS_FAIL,

    PRACTITIONER_DELETE_REQUEST,
    PRACTITIONER_DELETE_SUCCESS,
    PRACTITIONER_DELETE_FAIL,

    PRACTITIONER_CREATE_REQUEST,
    PRACTITIONER_CREATE_SUCCESS,
    PRACTITIONER_CREATE_FAIL,
    PRACTITIONER_CREATE_RESET,

    PRACTITIONER_UPDATE_REQUEST,
    PRACTITIONER_UPDATE_SUCCESS,
    PRACTITIONER_UPDATE_FAIL,
    PRACTITIONER_UPDATE_RESET,

    SET_CURRENT_PAGE,

    PRACTITIONER_DTO_FAIL,
    PRACTITIONER_DTO_REQUEST,
    PRACTITIONER_DTO_SUCCESS,
    PRACTITIONER_DTO_RESET,

    PRACTITIONER_LIST_BYIDS_REQUEST,
    PRACTITIONER_LIST_BYIDS_SUCCESS,
    PRACTITIONER_LIST_BYIDS_FAIL,
    PRACTITIONER_LIST_BYIDS_RESET

} from "../constants/practitionerConstants";

const initialState = {
    practitioners: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const practitionerListReducer = (
    state = {
        practitioners: []
    }, action) => {
    switch (action.type) {
        case PRACTITIONER_LIST_REQUEST:
            return { loading: true, practitioners: [] };
        case PRACTITIONER_LIST_SUCCESS:
            // our state will have a list of practitioners after the request
            return {
                loading: false,
                practitioners: action.payload.practitioners,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case PRACTITIONER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const practitionerListByIDsReducer = (
    state = {
        practitioners: []
    }, action) => {
    switch (action.type) {
        case PRACTITIONER_LIST_BYIDS_REQUEST:
            return { loading: true, practitioners: [] };
        case PRACTITIONER_LIST_BYIDS_SUCCESS:
            // our state will have a list of practitioners after the request
            return { loading: false, practitioners: action.payload };
        case PRACTITIONER_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case PRACTITIONER_LIST_BYIDS_RESET:
            return { practitioners: [] };
        default:
            return state;
    }
}


export const practitionerDetailsReducer = (
    state = {
        practitioner: { comments: [] }
    }, action) => {
    switch (action.type) {
        case PRACTITIONER_DETAILS_REQUEST:
            return { loading: true, ...state };
        case PRACTITIONER_DETAILS_SUCCESS:
            // our state will have a list of practitioners after the request
            return { loading: false, practitioner: action.payload };
        case PRACTITIONER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const practitionerDtoReducer = (state = { practitionerDto: {} }, action) => {
    switch (action.type) {
        case PRACTITIONER_DTO_REQUEST:
            return { loading: true, practitionerDto: {} };
        case PRACTITIONER_DTO_SUCCESS:
            return { loading: false, practitionerDto: action.payload };
        case PRACTITIONER_DTO_FAIL:
            return { loading: false, error: action.payload };
        case PRACTITIONER_DTO_RESET:
            return { practitionerDto: {} };
        default:
            return state;
    }
}

export const practitionerDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRACTITIONER_DELETE_REQUEST:
            return { loading: true }

        case PRACTITIONER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PRACTITIONER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const practitionerCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRACTITIONER_CREATE_REQUEST:
            return { loading: true }

        case PRACTITIONER_CREATE_SUCCESS:
            return { loading: false, success: true, practitioner: action.payload }

        case PRACTITIONER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PRACTITIONER_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const practitionerUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case PRACTITIONER_UPDATE_REQUEST:
            return { loading: true }

        case PRACTITIONER_UPDATE_SUCCESS:
            return { loading: false, success: true, practitioner: action.payload }

        case PRACTITIONER_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case PRACTITIONER_UPDATE_RESET:
            return { practitioner: {} }

        default:
            return state
    }
}