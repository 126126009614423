import {
    SYSTEM_SETTING_LIST_REQUEST,
    SYSTEM_SETTING_LIST_SUCCESS,
    SYSTEM_SETTING_LIST_FAIL,
    SYSTEM_SETTING_DETAILS_REQUEST,
    SYSTEM_SETTING_DETAILS_SUCCESS,
    SYSTEM_SETTING_DETAILS_FAIL,
    SYSTEM_SETTING_UPDATE_REQUEST,
    SYSTEM_SETTING_UPDATE_SUCCESS,
    SYSTEM_SETTING_UPDATE_FAIL,
    SYSTEM_SETTING_UPDATE_RESET,
    SYSTEM_SETTING_CREATE_REQUEST,
    SYSTEM_SETTING_CREATE_SUCCESS,
    SYSTEM_SETTING_CREATE_FAIL,
    SYSTEM_SETTING_CREATE_RESET,
    SYSTEM_SETTING_DELETE_REQUEST,
    SYSTEM_SETTING_DELETE_SUCCESS,
    SYSTEM_SETTING_DELETE_FAIL,
    SYSTEM_SETTING_DELETE_RESET,
    SYSTEM_SETTING_DTO_REQUEST,
    SYSTEM_SETTING_DTO_SUCCESS,
    SYSTEM_SETTING_DTO_FAIL,
} from '../constants/systemSettingConstants'

export const systemSettingListReducer = (state = { systemSettings: [] }, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_LIST_REQUEST:
            return { loading: true, systemSettings: [] }
        case SYSTEM_SETTING_LIST_SUCCESS:
            return {
                loading: false,
                systemSettings: action.payload.systemSettings,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
            }
        case SYSTEM_SETTING_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const systemSettingDetailsReducer = (state = { systemSetting: {} }, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_DETAILS_REQUEST:
            return { loading: true, ...state }
        case SYSTEM_SETTING_DETAILS_SUCCESS:
            return { loading: false, systemSetting: action.payload }
        case SYSTEM_SETTING_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const systemSettingUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_UPDATE_REQUEST:
            return { loading: true }
        case SYSTEM_SETTING_UPDATE_SUCCESS:
            return { loading: false, success: true, systemSetting: action.payload }
        case SYSTEM_SETTING_UPDATE_FAIL:
            return { loading: false, error: Array.isArray(action.payload) ? action.payload.join(' ') : action.payload }
        case SYSTEM_SETTING_UPDATE_RESET:
            return {}
        default:
            return state
    }
}

export const systemSettingCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_CREATE_REQUEST:
            return { loading: true }
        case SYSTEM_SETTING_CREATE_SUCCESS:
            return { loading: false, success: true, systemSetting: action.payload }
        case SYSTEM_SETTING_CREATE_FAIL:
            return { loading: false, error: Array.isArray(action.payload) ? action.payload.join(' ') : action.payload }
        case SYSTEM_SETTING_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const systemSettingDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_DELETE_REQUEST:
            return { loading: true }
        case SYSTEM_SETTING_DELETE_SUCCESS:
            return { loading: false, success: true }
        case SYSTEM_SETTING_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case SYSTEM_SETTING_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const systemSettingDtoReducer = (state = { dto: {} }, action) => {
    switch (action.type) {
        case SYSTEM_SETTING_DTO_REQUEST:
            return { loading: true, ...state }
        case SYSTEM_SETTING_DTO_SUCCESS:
            return { loading: false, dto: action.payload }
        case SYSTEM_SETTING_DTO_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}