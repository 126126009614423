import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import card from nhsuk-frontend/packages/components/card/card";
// You can import components from the global module
import { ActionLink, Button, Card, CareCard, ContentsList, WarningCallout, InsetText, BackLink, Tag } from 'nhsuk-react-components';
import { FaCheck, FaD, FaDatabase, FaUser, FaUserGroup, FaUsersViewfinder } from "react-icons/fa6";
import { FcAddressBook, FcBusinessman, FcDataBackup, FcSettings, FcSurvey } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaBuilding, FaCheckCircle, FaCottonBureau, FaCreditCard, FaCube, FaDotCircle, FaEnvelope, FaFile, FaPassport } from "react-icons/fa";


function SettingsScreen({ history }) {

    // user info from redux store
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        document.title = "Settings"; 
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            {userInfo && userInfo.roles.includes("UnauthorizedUser") && (
                                <WarningCallout label="Warning callout">
                                    <WarningCallout.Label>Important</WarningCallout.Label>
                                    <p>Hi {userInfo.username},</p>
                                    <p>Your account is being reviewed by an administrator. You will receive a confirmation by email to access the system once your account is approved.</p>
                                </WarningCallout>
                            )}


                            <h2 className="mt-0 header-title">My Heart Passport Configuration</h2>
                            <BackLink
                                asElement="a"
                                href="#"
                                onClick={() => history.goBack()}
                            >
                                Return back
                            </BackLink>

                            <div className="cards-grid">
                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container ">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/aetiologies">
                                                    Aetiologies
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the aetiologies.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/symptoms">
                                                    Symptoms
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the symptoms.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/implants">
                                                    Implant Registry
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the implants.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/implant_centres">
                                                    Implant Centres
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the implant centres.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/followup_centres">
                                                    Followup Centres
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the followup centres.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCube size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/ecgs">
                                                    ECG Indications
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the ECG indications.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <h2 className="mt-0 header-title">System Configuration</h2>
                            <div className="cards-grid">

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaEnvelope size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="/settings/email">
                                                    Email Settings
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the email settings.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaDatabase size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="settings/data_import_export">
                                                    Data Import/Export
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the data import/export settings.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaFile size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="settings/templates">
                                                    Templates
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the templates.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaFile size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="settings/generic_templates">
                                                    Templates
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the generic templates.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>

                                <Card clickable>
                                    <Card.Content>
                                        <Card.Heading className="nhsuk-heading-m">
                                            <div className="icon-container">
                                                <FaCheckCircle size={30} />
                                            </div>
                                            <Card.Link href="#">
                                                <Link to="settings/consents">
                                                    User Consents
                                                </Link>
                                            </Card.Link>
                                        </Card.Heading>
                                        <Card.Description>
                                            This section allows you to manage and configure the user consents.
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SettingsScreen;