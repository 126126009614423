import axios from 'axios';

import {
    ENCOUNTER_LIST_REQUEST,
    ENCOUNTER_LIST_SUCCESS,
    ENCOUNTER_LIST_FAIL,

    ENCOUNTER_DETAILS_REQUEST,
    ENCOUNTER_DETAILS_SUCCESS,
    ENCOUNTER_DETAILS_FAIL,

    ENCOUNTER_DELETE_REQUEST,
    ENCOUNTER_DELETE_SUCCESS,
    ENCOUNTER_DELETE_FAIL,

    ENCOUNTER_CREATE_REQUEST,
    ENCOUNTER_CREATE_SUCCESS,
    ENCOUNTER_CREATE_FAIL,

    ENCOUNTER_UPDATE_REQUEST,
    ENCOUNTER_UPDATE_SUCCESS,
    ENCOUNTER_UPDATE_FAIL,

    ENCOUNTER_REQUEST,
    ENCOUNTER_FAIL,
    ENCOUNTER_RESET,
    ENCOUNTER_SUCCESS,

    ENCOUNTER_DTO_REQUEST,
    ENCOUNTER_DTO_SUCCESS,
    ENCOUNTER_DTO_FAIL,
    ENCOUNTER_DTO_RESET,

    ENCOUNTER_LIST_BYIDS_REQUEST,
    ENCOUNTER_LIST_BYIDS_SUCCESS,
    ENCOUNTER_LIST_BYIDS_FAIL,
    ENCOUNTER_LIST_BYIDS_RESET
    

} from "../constants/encounterConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listEncounters = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: ENCOUNTER_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/encounter/all', config);

        dispatch({
            type: ENCOUNTER_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ENCOUNTER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later encounters",
        });
    }
}

// list encounters by ids
export const listEncountersByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: ENCOUNTER_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/encounter/ids/all', ids, config);

        dispatch({
            type: ENCOUNTER_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ENCOUNTER_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later encounters",
        });
    }
}


export const loadEncounterDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ENCOUNTER_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/encounter/encounterDto', config);

        dispatch({
            type: ENCOUNTER_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ENCOUNTER_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getEncounterDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ENCOUNTER_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/encounter/${_id}`, config);

        dispatch({
            type: ENCOUNTER_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ENCOUNTER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteEncounter = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENCOUNTER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/encounter/${_id}/`,
            config
        )

        dispatch({
            type: ENCOUNTER_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: ENCOUNTER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createEncounter = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENCOUNTER_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/encounter/`,
            changes,
            config
        )

        dispatch({
            type: ENCOUNTER_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: ENCOUNTER_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadEncounterBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make encounter using a local encounter
export const makeEncounter = (file, encounterId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const encounter = await loadEncounterBase64(file);
    const encounter_input = JSON.stringify({ "encounter": encounter });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        encounter_input,
        config
    )
    try {
        dispatch({
            type: ENCOUNTER_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": encounter }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: ENCOUNTER_SUCCESS
                });
                if (encounterId && response.data) {
                    dispatch(updateEncounter(encounterId, { encounter: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: ENCOUNTER_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: ENCOUNTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateEncounter = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENCOUNTER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/encounter/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: ENCOUNTER_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: ENCOUNTER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: ENCOUNTER_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}