import React, { useState, useMemo } from 'react';
import { Card, Input, Row, Col, Typography, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import {
  SettingOutlined,
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  CalendarOutlined,
  DollarOutlined,
  FormOutlined,
  ExperimentOutlined,
  MedicineBoxOutlined,
  MailOutlined,
  ToolOutlined,   
  BugOutlined,
  HomeOutlined,
  FileProtectOutlined,
  BankOutlined,
  BarChartOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  RadarChartOutlined,
  FileSearchOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { Search } = Input;

const ControlPanelScreen = () => {
  document.title = 'Control Panel';

  const [searchTerm, setSearchTerm] = useState('');

  const categories = useMemo(() => [
    {
      name: 'System Management',
      items: [
        { title: 'Email Configuration', path: '/settings/smtp', icon: <MailOutlined />, description: 'Configure email settings for notifications' },
        { title: 'General Settings', path: '/settings/system', icon: <ToolOutlined />, description: 'Manage system-wide configurations' },
        { title: 'Symptoms', path: '/settings/symptoms', icon: <MedicineBoxOutlined />, description: 'Maintain symptom database' },
        { title: 'Aetiology Database', path: '/settings/aetiologies', icon: <BugOutlined />, description: 'Manage disease causes and origins' },
        { title: 'Follow-up Centers', path: '/settings/followup_centres', icon: <HomeOutlined />, description: 'Administer patient follow-up locations' },
        { title: 'Consent Forms', path: '/settings/consents', icon: <FileProtectOutlined />, description: 'Manage patient consent documents' },
        { title: 'Organisations', path: '/settings/organizations', icon: <BankOutlined />, description: 'Maintain healthcare organization records' },
        { title: 'Templates', path: '/settings/templates', icon: <FormOutlined />, description: 'Create and edit document templates' },
      ],
    },
    {
      name: 'Clinical Operations',
      items: [
        { title: 'Patient Encounters', path: '/encounters', icon: <CalendarOutlined />, description: 'Record and manage patient visits' },
        { title: 'Payment Processing', path: '/payments', icon: <DollarOutlined />, description: 'Handle billing and payments' },
      ],
    },
    {
      name: 'Laboratory',
      items: [
        { title: 'QC Result Management', path: '/qc_result_management', icon: <BarChartOutlined />, description: 'Manage and review Quality Control results' },
        { title: 'QC Schedule Management', path: '/qc_schedule_management', icon: <ScheduleOutlined />, description: 'Plan and manage Quality Control schedules' },
        { title: 'Control Material Management', path: '/control_material_management', icon: <ContainerOutlined />, description: 'Manage Quality Control materials and inventory' },
        { title: 'QC Rule Management', path: '/qc_rule_management', icon: <SettingOutlined />, description: 'Define and manage Quality Control rules' },
        { title: 'QC Report Management', path: '/qc_report_management', icon: <FileSearchOutlined />, description: 'Generate and manage Quality Control reports' },
        { title: 'Test Data Importer', path: '/test_data_importer', icon: <DatabaseOutlined />, description: 'Import test data from external sources' },
      ],
    },
  ], []);

  const filteredCategories = useMemo(() => {
    if (!searchTerm) return categories;

    return categories?.map(category => ({
      ...category,
      items: category.items?.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    })).filter(category => category.items.length > 0);
  }, [categories, searchTerm]);

  const renderCategoryCards = (category) => (
    <Card
      key={category.name}
      style={{ marginBottom: 16 }}
      bodyStyle={{ padding: 0 }}
    >
      <Space align="center" style={{ padding: '12px 16px 0' }}>
        <Title level={4}>{category.name}</Title>
      </Space>
      <Row gutter={[8, 8]} style={{ padding: 16 }}>
        {category.items?.map(item => (
          <Col xs={12} sm={8} md={6} lg={4} key={item.path}>
            <Link to={item.path}>
              <Tooltip title={item.description}>
                <Card hoverable bodyStyle={{ padding: '12px', height: '100px' }}>
                  <Space direction="vertical" align="center" style={{ width: '100%' }}>
                    {React.cloneElement(item.icon, { style: { fontSize: '24px' } })}
                    <Text style={{ textAlign: 'center'}}>{item.title}</Text>
                  </Space>
                </Card>
              </Tooltip>
            </Link>
          </Col>
        ))}
      </Row>
    </Card>
  );

  return (
    <div style={{ padding: 16 }}>
      <Title level={2}>Control Panel</Title>
      <Search
        placeholder="Search for a specific area..."
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      {filteredCategories?.map(renderCategoryCards)}
    </div>
  );
};

export default ControlPanelScreen;