export const FOLLOWUP_CENTRE_LIST_REQUEST = 'FOLLOWUP_CENTRE_LIST_REQUEST'
export const FOLLOWUP_CENTRE_LIST_SUCCESS = 'FOLLOWUP_CENTRE_LIST_SUCCESS'
export const FOLLOWUP_CENTRE_LIST_FAIL = 'FOLLOWUP_CENTRE_LIST_FAIL'


export const FOLLOWUP_CENTRE_DETAILS_REQUEST = 'FOLLOWUP_CENTRE_DETAILS_REQUEST'
export const FOLLOWUP_CENTRE_DETAILS_SUCCESS = 'FOLLOWUP_CENTRE_DETAILS_SUCCESS'
export const FOLLOWUP_CENTRE_DETAILS_FAIL = 'FOLLOWUP_CENTRE_DETAILS_FAIL'


export const FOLLOWUP_CENTRE_DELETE_REQUEST = 'FOLLOWUP_CENTRE_DELETE_REQUEST'
export const FOLLOWUP_CENTRE_DELETE_SUCCESS = 'FOLLOWUP_CENTRE_DELETE_SUCCESS'
export const FOLLOWUP_CENTRE_DELETE_FAIL = 'FOLLOWUP_CENTRE_DELETE_FAIL'

export const FOLLOWUP_CENTRE_CREATE_REQUEST = 'FOLLOWUP_CENTRE_CREATE_REQUEST'
export const FOLLOWUP_CENTRE_CREATE_SUCCESS = 'FOLLOWUP_CENTRE_CREATE_SUCCESS'
export const FOLLOWUP_CENTRE_CREATE_FAIL = 'FOLLOWUP_CENTRE_CREATE_FAIL'
export const FOLLOWUP_CENTRE_CREATE_RESET = 'FOLLOWUP_CENTRE_CREATE_RESET'


export const FOLLOWUP_CENTRE_UPDATE_REQUEST = 'FOLLOWUP_CENTRE_UPDATE_REQUEST'
export const FOLLOWUP_CENTRE_UPDATE_SUCCESS = 'FOLLOWUP_CENTRE_UPDATE_SUCCESS'
export const FOLLOWUP_CENTRE_UPDATE_FAIL = 'FOLLOWUP_CENTRE_UPDATE_FAIL'
export const FOLLOWUP_CENTRE_UPDATE_RESET = 'FOLLOWUP_CENTRE_UPDATE_RESET'

export const FOLLOWUP_CENTRE_REQUEST = 'FOLLOWUP_CENTRE_REQUEST'
export const FOLLOWUP_CENTRE_SUCCESS = 'FOLLOWUP_CENTRE_SUCCESS'
export const FOLLOWUP_CENTRE_FAIL = 'FOLLOWUP_CENTRE_FAIL'
export const FOLLOWUP_CENTRE_RESET = 'FOLLOWUP_CENTRE_RESET'

export const FOLLOWUP_CENTRE_COUNT_REQUEST = "FOLLOWUP_CENTRE_COUNT_REQUEST"
export const FOLLOWUP_CENTRE_COUNT_SUCCESS = "FOLLOWUP_CENTRE_COUNT_SUCCESS"
export const FOLLOWUP_CENTRE_COUNT_FAIL = "FOLLOWUP_CENTRE_COUNT_FAIL"


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const FOLLOWUP_CENTRE_LIST_BYIDS_REQUEST = 'FOLLOWUP_CENTRE_LIST_BYIDS_REQUEST'
export const FOLLOWUP_CENTRE_LIST_BYIDS_SUCCESS = 'FOLLOWUP_CENTRE_LIST_BYIDS_SUCCESS'
export const FOLLOWUP_CENTRE_LIST_BYIDS_FAIL = 'FOLLOWUP_CENTRE_LIST_BYIDS_FAIL'
export const FOLLOWUP_CENTRE_LIST_BYIDS_RESET = 'FOLLOWUP_CENTRE_LIST_BYIDS_RESET'

// DTO
export const FOLLOWUP_CENTRE_DTO_FAIL = 'FOLLOWUP_CENTRE_DTO_FAIL'
export const FOLLOWUP_CENTRE_DTO_REQUEST = 'FOLLOWUP_CENTRE_DTO_REQUEST'
export const FOLLOWUP_CENTRE_DTO_SUCCESS = 'FOLLOWUP_CENTRE_DTO_SUCCESS'
export const FOLLOWUP_CENTRE_DTO_RESET = 'FOLLOWUP_CENTRE_DTO_RESET'
