import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Card, notification, Flex, Space } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { convertKeyToCamelCase } from '../../utils/functions';
import LookupForm from './common/LookupForm';
import EditForm from './common/EditForm';
import CreateForm from './common/CreateForm';
import DynamicFormChildSP from './common/DynamicFormChildSP';
import debounce from 'lodash/debounce';

// use translation hook
import { useTranslation } from 'react-i18next';

const DynamicFormSP = ({
    // selection
    currentEntityId,
    selectedItemIds = [],
    selectedItems,

    listingMode = 'selectedIds',
    // edit mode
    defaultEditMode,
    // search props
    primarySearchProp,
    secondarySearchProp,
    groupSearchProp,
    // entity props
    singularEntity,
    pluralEntity,
    constantPrefix,
    summaryProps,
    loadDto,
    createEntity,
    updateEntity,
    deleteEntity,
    getEntityDetails,
    listEntities,
    listEntitiesByIds,
    //actions and callbacks
    onSelectedEntitiesChange = () => { },
    onSelectedItems = () => { },
    onUpdatedEntityChange = () => { },
    onNewEntityChange = () => { },
    onEditModeChange = () => { },
    onLoading = () => { },
    // parent props
    isParent = true,
    fieldsToHide = [],

    // Optional props
    enableSearchBar = true,
    enableCreate = true,
    enableActionButtons = true,
    enableExportButtons = false,


}) => {

    const dispatch = useDispatch();

    const { t } = useTranslation();

    // Redux state variables
    const entityCreate = useSelector((state) => state[`${singularEntity}Create`]);
    const { loading: loadingCreate, error: errorCreate, success: successCreate, [singularEntity]: createdEntity } = entityCreate;

    const entityUpdate = useSelector((state) => state[`${singularEntity}Update`]);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = entityUpdate;

    const entityList = useSelector((state) => state[`${singularEntity}List`]);
    const { loading: loadingList, error: errorList, [pluralEntity]: entities, currentPage, totalPages } = entityList;

    const entityListByIDs = useSelector((state) => state[`${singularEntity}ListByIDs`]);
    const { loading: loadingListByIDs, error: errorListByIDs, [pluralEntity]: entitiesByIds } = entityListByIDs;

    const entityDetails = useSelector((state) => state[`${singularEntity}Details`]);
    const { loading: loadingDetails, error: errorDetails, [singularEntity]: entity } = entityDetails;

    const entityDto = useSelector((state) => state[`${singularEntity}Dto`]);
    const { [`${singularEntity}Dto`]: entityDtoConfig, loading: loadingDto } = entityDto;



    // State variables
    const [entityId, setEntityId] = useState(currentEntityId);
    const [editForm] = Form.useForm();
    const [newForm] = Form.useForm();

    const [editMode, setEditMode] = useState(defaultEditMode);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedSubEntityIds, setSelectedSubEntities] = useState([]);
    const [limit, setLimit] = useState(10);
    const [entityListFilter, setEntityListFilter] = useState({ query: '', page: currentPage, limit: limit, filter: primarySearchProp });
    const [isChildFormOpen, setIsChildFormOpen] = useState(false);
    const [selectedEntityConfig, setSelectedEntityConfig] = useState({});
    const [newEntity, setNewEntity] = useState({});
    const [updatedEntity, setUpdatedEntity] = useState({});
    const [viewMode, setViewMode] = useState('table'); // cards or table

    // State for showing/hiding child form
    const [showChildForm, setShowChildForm] = useState(false);

    // listen to loadings 
    const isLoading = loadingCreate || loadingUpdate || loadingList || loadingListByIDs || loadingDetails || loadingDto;

    // Reset Redux state on component mount and unmount
    useEffect(() => {
        dispatch({ type: `${constantPrefix}_CREATE_RESET` });
        dispatch({ type: `${constantPrefix}_UPDATE_RESET` });
        dispatch({ type: `${constantPrefix}_LIST_RESET` });
        dispatch({ type: `${constantPrefix}_LIST_BY_IDS_RESET` });
    }, [dispatch]);

    // Listen to EditMode changes
    useEffect(() => {
        setEditMode(defaultEditMode);
    }, [defaultEditMode]);

    // listen to editMode changes
    useEffect(() => {
        onEditModeChange(editMode);
    }, [editMode]);

    // listen to currentEntityId changes
    useEffect(() => {
        setEntityId(currentEntityId);
    }, [currentEntityId]);

    // listen to setUpdatedEntity 
    useEffect(() => {
        setUpdatedEntity(updatedEntity);
    }, [updatedEntity]);

    // Load DTO and entities on component mount
    useEffect(() => {
        dispatch(loadDto());
        if (editMode === 'Lookup') {
            dispatch(listEntities(entityListFilter));
        }
        if (selectedItemIds && selectedItemIds.length > 0) {
            if (Array.isArray(selectedItemIds)) {
                dispatch(listEntitiesByIds(selectedItemIds));
            } else if (typeof selectedItemIds === 'string' || typeof selectedItemIds === 'number') {
                dispatch(listEntitiesByIds([selectedItemIds]));
            } else {
                dispatch(listEntitiesByIds([]));
            }
        } else {
            dispatch(listEntitiesByIds([]));
        }
    }, [dispatch]);

    // listen to entities changes
    useEffect(() => {
        if (entities && listingMode === 'allEntities') {
            setSelectedEntities(entities);
        }
    }, [entities]);

    // Update selectedEntities when entitiesByIds changes
    useEffect(() => {
        if (entitiesByIds && listingMode === 'selectedIds') {
            setSelectedEntities(entitiesByIds);
        }
    }, [entitiesByIds]);

    // listen to entityListFilter changes
    useEffect(() => {
        dispatch(listEntities(entityListFilter));
    }, [entityListFilter]);


    // Initialize newEntity and set form values when entityDtoConfig changes
    useEffect(() => {
        if (!entityDtoConfig) return;
        var emptyValues = {};
        Object.entries(entityDtoConfig).forEach(([fieldName, fieldProps]) => {
            emptyValues[convertKeyToCamelCase(fieldName)] = fieldProps.initialValue;
        });
        setNewEntity(emptyValues);
        newForm.setFieldsValue(emptyValues);
    }, [entityDtoConfig]);

    // Set form values when updatedEntity changes
    useEffect(() => {
        editForm.setFieldsValue({ ...updatedEntity });
        editForm.validateFields();
        onUpdatedEntityChange(updatedEntity);
    }, [updatedEntity]);

    // Set form values when newEntity changes
    useEffect(() => {
        newForm.setFieldsValue({ ...newEntity });
        newForm.validateFields();
        onNewEntityChange(newEntity);
    }, [newEntity]);


    // Update parent component with selectedEntities
    useEffect(() => {
        onSelectedItems(selectedEntities);
        onSelectedEntitiesChange(selectedEntities);
    }, [selectedEntities]);

    // Update selectedSubEntityIds when entity changes
    useEffect(() => {
        if (entity) {
            setUpdatedEntity(entity);
            // Reset selectedSubEntityIds when switching to a different entity
            setSelectedSubEntities([]);
        }
    }, [entity]);

    // Show success notification on successUpdate
    useEffect(() => {
        if (successUpdate) {
            openNotification('bottom', t('Success'), t('Updated Successfully'));
            dispatch({ type: `${constantPrefix}_UPDATE_RESET` });
        }
    }, [successUpdate]);

    // Show error notification on errorUpdate
    useEffect(() => {
        if (errorUpdate) {
            openNotification('bottom', t('Error'), t('Update Failed Please refer to the error message at the top of the form'));
            dispatch({ type: `${constantPrefix}_UPDATE_RESET` });
        }
    }, [errorUpdate]);

    // Show success notification on successCreate and switch to edit mode
    useEffect(() => {
        if (successCreate && createdEntity) {
            openNotification('bottom', t('Success'), t('Created Successfully'));
            setEntityId(createdEntity.id);
            setEditMode('Edit');
            dispatch({ type: `${constantPrefix}_CREATE_RESET` });
        }
    }, [successCreate, createdEntity]);

    // Show error notification on errorCreate
    useEffect(() => {
        if (errorCreate) {
            openNotification('bottom', t('Error'), t('Creation Failed Please refer to the error message at the top of the form'));
        }
    }, [errorCreate]);


    // Get entity details when entityId changes
    useEffect(() => {
        if (entityId) {
            dispatch(getEntityDetails(entityId));
        }
    }, [entityId]);

    useEffect(() => {
        onLoading(isLoading);
    }, [loadingCreate, loadingUpdate, loadingList, loadingListByIDs, loadingDetails, loadingDto]);

    // Handle form submission for newEntity
    const handleSubmitNewEntity = () => {
        const camelCaseEntity = {};
        Object.entries(newEntity).forEach(([key, value]) => {
            camelCaseEntity[convertKeyToCamelCase(key)] = value;
        });
        if (camelCaseEntity) {
            dispatch(createEntity(camelCaseEntity));
        }
    };

    // Handle form submission for updatedEntity
    const handleSubmitUpdatedEntity = () => {
        const camelCaseEntity = {};
        Object.entries(updatedEntity).forEach(([key, value]) => {
            camelCaseEntity[convertKeyToCamelCase(key)] = value;
        });
        if (camelCaseEntity) {
            dispatch(updateEntity(entityId, camelCaseEntity));
        }
    };

    // Handle input change for newEntity
    const handleNewEntityInputChange = (fieldName, value) => {
        const lowerCaseFieldName = convertKeyToCamelCase(fieldName);
        setNewEntity({ ...newEntity, [lowerCaseFieldName]: value });
    };

    // Handle input change for updatedEntity
    const handleUpdatedEntityInputChange = (fieldName, value) => {
        const lowerCaseFieldName = convertKeyToCamelCase(fieldName);
        setUpdatedEntity({ ...updatedEntity, [lowerCaseFieldName]: value });
    };

    // Handle data updated
    const handleDataUpdated = (updatedRow) => {
        setSelectedEntities((prevData) =>
            prevData?.map((row) => (row.id === updatedRow.id ? updatedRow : row))
        );
    };

    // Show notification
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, type, description) => {
        api.info({
            message: type,
            description: description,
            placement,
            icon: type === t('Success') ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />,
        });
    };

    // Handle search
    const onSearch = (value) => {
        setEntityListFilter({ ...entityListFilter, query: value });
    };

    // Handle edit
    const handleEdit = (id) => {
        setEditMode('Edit');
        setEntityId(id);
    };

    // Handle delete
    const handleDelete = (id) => {
        const newSelectedEntities = selectedEntities?.filter(selectedEntity => selectedEntity.id !== id);
        setSelectedEntities(newSelectedEntities);
    }

    // handle reload selected entityIds 
    const handleReloadEntities = () => {
        dispatch(loadDto());
        if (selectedItemIds && selectedItemIds.length > 0) {
            if (Array.isArray(selectedItemIds)) {
                dispatch(listEntitiesByIds(selectedItemIds));
            } else if (typeof selectedItemIds === 'string' || typeof selectedItemIds === 'number') {
                dispatch(listEntitiesByIds([selectedItemIds]));
            } else {
                dispatch(listEntitiesByIds([]));
            }
        } else {
            dispatch(listEntitiesByIds([]));
        }
    }


    // Handle browse entity
    const handleBrowseEntity = (config) => {
        setSelectedSubEntities([]); // Reset selectedSubEntityIds before setting new values
        setSelectedEntityConfig(config);
        if (editMode === 'Edit' && updatedEntity) {
            var selectedSubEntityIds = updatedEntity[convertKeyToCamelCase(config.fieldName)] || [];
            setSelectedSubEntities(selectedSubEntityIds);
        }
        if (editMode === 'Create') {
            var selectedSubEntityIds = newEntity[convertKeyToCamelCase(config.fieldName)] || [];
            setSelectedSubEntities(selectedSubEntityIds);
        }
        setShowChildForm(true);
    };

    // Handle back button click
    const handleBackButtonClick = () => {
        setShowChildForm(false);
    };

    return (
        <div>

            {!showChildForm ? (
                <Card
                    loading={loadingDto}
                    title={
                        <div>

                            {editMode !== 'Lookup' && !isParent ? (
                                <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => setEditMode('Lookup')}>
                                    Back to {pluralEntity}
                                </Button>
                            ) : (
                                <Flex gap="middle" align="center" justify="space-between" hidden={enableCreate}>
                                    {!isParent && (
                                        <div>Select {pluralEntity} ({selectedEntities.length} selected)</div>
                                    )}
                                    {(editMode !== 'Create') ? (
                                        <Button type="primary" disabled={!enableCreate} onClick={() => {
                                            // ensure to empty the updated entity
                                            setUpdatedEntity(null);
                                            setEntityId(null);
                                            setEditMode('Create');
                                        }}>
                                            + {t("Create")} {singularEntity}
                                        </Button>
                                    ) : (
                                        <div>{t("Create")} {singularEntity}</div>
                                    )}
                                </Flex>
                            )}
                        </div>
                    }
                    style={{ width: '100%' }}
                    bordered={true}
                >

                    {contextHolder}
                    {(entityDtoConfig) && (
                        <React.Fragment>
                            {/* Lookup Form */}
                            <LookupForm
                                key={`lookup-form-${entityId}`}
                                selectedEntities={selectedEntities}
                                editMode={editMode}
                                singularEntity={singularEntity}
                                pluralEntity={pluralEntity}
                                primarySearchProp={primarySearchProp}
                                secondarySearchProp={secondarySearchProp}
                                groupSearchProp={groupSearchProp}
                                entities={entities}
                                viewMode={viewMode}
                                setViewMode={setViewMode}
                                setSelectedEntities={setSelectedEntities}
                                onSearch={onSearch}
                                onFilterChange={
                                    debounce((filter) => {
                                        dispatch(listEntities(filter));
                                    }, 1000)
                                }
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleReloadEntities={handleReloadEntities}
                                summaryProps={summaryProps}
                                loadingList={loadingList}
                                loadingListByIDs={loadingListByIDs}
                                loadingDetails={loadingDetails}
                                loadingCreate={loadingCreate}
                                loadingUpdate={loadingUpdate || loadingDetails}
                                entityDtoConfig={entityDtoConfig}
                                entityListFilter={entityListFilter}
                                limit={limit}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                dispatch={dispatch}
                                getEntityDetails={getEntityDetails}
                                updateEntity={updateEntity}
                                errorUpdate={errorUpdate}
                                handleDataUpdated={handleDataUpdated}

                                enableSearchBar={enableSearchBar}
                                enableActionButtons={enableActionButtons}
                            />

                            {/* Edit Form */}
                            {updatedEntity && (
                                <EditForm
                                    key={`edit-form-${entityId}`}
                                    editMode={editMode}
                                    updatedEntity={updatedEntity}
                                    loadingUpdate={loadingUpdate || loadingDetails}
                                    editForm={editForm}
                                    handleSubmitUpdatedEntity={handleSubmitUpdatedEntity}
                                    errorUpdate={errorUpdate}
                                    singularEntity={singularEntity}
                                    entityDtoConfig={entityDtoConfig}
                                    handleUpdatedEntityInputChange={handleUpdatedEntityInputChange}
                                    handleBrowseEntity={handleBrowseEntity}
                                    fieldsToHide={fieldsToHide}
                                    enableExportButtons={enableExportButtons}
                                />
                            )}
                            {/* Create Form */}
                            {(newEntity && editMode === 'Create') && (
                                <CreateForm
                                    key={`create-form${entityId}`}
                                    newEntity={newEntity}
                                    newForm={newForm}
                                    loadingCreate={loadingCreate}
                                    handleSubmitNewEntity={handleSubmitNewEntity}
                                    errorCreate={errorCreate}
                                    singularEntity={singularEntity}
                                    entityDtoConfig={entityDtoConfig}
                                    handleNewEntityInputChange={handleNewEntityInputChange}
                                    handleBrowseEntity={handleBrowseEntity}
                                    fieldsToHide={fieldsToHide}
                                />
                            )}
                        </React.Fragment>
                    )}
                </Card>
            ) : (
                <DynamicFormChildSP
                    key={`child-form-${entityId}`}
                    editMode={editMode} // Edit or Create
                    selectedItems={selectedSubEntityIds}
                    selectedItemIds={selectedSubEntityIds}
                    isChildFormOpen={isChildFormOpen}
                    setIsChildFormOpen={setIsChildFormOpen}
                    onSelectedItems={onSelectedItems}
                    selectedEntityConfig={selectedEntityConfig}
                    handleUpdatedEntityInputChange={handleUpdatedEntityInputChange}
                    handleNewEntityInputChange={handleNewEntityInputChange}
                    onNewEntityChange={onNewEntityChange}
                    onUpdatedEntityChange={onUpdatedEntityChange}
                    onSelectedEntitiesChange={onSelectedEntitiesChange}
                    onEditModeChange={onEditModeChange}
                    handleBackButtonClick={handleBackButtonClick}
                    parentSingleEntity={singularEntity}
                    parentPluralEntity={pluralEntity}
                    fieldsToHide={fieldsToHide}
                    isParent={false}
                    enableCreate={enableCreate}
                    enableSearchBar={enableSearchBar}

                />

            )}

        </div>
    );
};
export default DynamicFormSP;