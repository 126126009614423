import React from "react";
import ProlabActivityComponent from "../../components/chat/ProlabActivity.component";

export default function ChatScreen(props, navigation) {

    // get userInfo from the store
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return (
        <React.Fragment>

            <div style={{ height: '100vh', width: '100%', border: 'none' }}>
                <ProlabActivityComponent
                    chatTitle={userInfo.businessOrganisationName || "Prolab LIS"}
                    teamId={userInfo.mattermostTeamId}
                    token={userInfo.mattermostToken}
                    serverUrl={userInfo.mattermostServerUrl}
                    mattermostUrl={userInfo.mattermostMattermostUrl}
                    teamGuid={userInfo.mattermostTeamGuid}
                />
            </div>

        </React.Fragment>
    );
}