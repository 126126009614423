import {
    SMTP_CONFIG_LIST_REQUEST,
    SMTP_CONFIG_LIST_SUCCESS,
    SMTP_CONFIG_LIST_FAIL,
    SMTP_CONFIG_DETAILS_REQUEST,
    SMTP_CONFIG_DETAILS_SUCCESS,
    SMTP_CONFIG_DETAILS_FAIL,
    SMTP_CONFIG_DELETE_REQUEST,
    SMTP_CONFIG_DELETE_SUCCESS,
    SMTP_CONFIG_DELETE_FAIL,
    SMTP_CONFIG_CREATE_REQUEST,
    SMTP_CONFIG_CREATE_SUCCESS,
    SMTP_CONFIG_CREATE_FAIL,
    SMTP_CONFIG_UPDATE_REQUEST,
    SMTP_CONFIG_UPDATE_SUCCESS,
    SMTP_CONFIG_UPDATE_FAIL,
    SMTP_CONFIG_COUNT_REQUEST,
    SMTP_CONFIG_COUNT_SUCCESS,
    SMTP_CONFIG_COUNT_FAIL,
} from "../constants/smtpConfigConstants";

export const smtpConfigListReducer = (state = { smtpConfigs: [] }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_LIST_REQUEST:
            return { loading: true, smtpConfigs: [] };
        case SMTP_CONFIG_LIST_SUCCESS:
            return { 
                loading: false, 
                smtpConfigs: action.payload.smtpConfigs,
                page: action.payload.page,
                pages: action.payload.pages,
                count: action.payload.count
            };
        case SMTP_CONFIG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const smtpConfigDetailsReducer = (state = { smtpConfig: {} }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_DETAILS_REQUEST:
            return { ...state, loading: true };
        case SMTP_CONFIG_DETAILS_SUCCESS:
            return { loading: false, smtpConfig: action.payload };
        case SMTP_CONFIG_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const smtpConfigDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SMTP_CONFIG_DELETE_REQUEST:
            return { loading: true };
        case SMTP_CONFIG_DELETE_SUCCESS:
            return { loading: false, success: true };
        case SMTP_CONFIG_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const smtpConfigCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SMTP_CONFIG_CREATE_REQUEST:
            return { loading: true };
        case SMTP_CONFIG_CREATE_SUCCESS:
            return { loading: false, success: true, smtpConfig: action.payload };
        case SMTP_CONFIG_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const smtpConfigUpdateReducer = (state = { smtpConfig: {} }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_UPDATE_REQUEST:
            return { loading: true };
        case SMTP_CONFIG_UPDATE_SUCCESS:
            return { loading: false, success: true, smtpConfig: action.payload };
        case SMTP_CONFIG_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const smtpConfigCountReducer = (state = { count: 0 }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_COUNT_REQUEST:
            return { ...state, loading: true };
        case SMTP_CONFIG_COUNT_SUCCESS:
            return { loading: false, count: action.payload };
        case SMTP_CONFIG_COUNT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};