import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Typography, message, Alert, Statistic } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, FilterOutlined, SyncOutlined } from '@ant-design/icons';
import {
    listSampleInventoryLines,
    createSampleInventoryLine,
    updateSampleInventoryLine,
    deleteSampleInventoryLine,
    getSampleInventoryLineDetails,
    loadSampleInventoryLineResponseDto,
    listSampleInventoryLinesByIds
} from '../../actions/sampleInventoryLineActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

const { Title, Text } = Typography;

export default function SampleInventoryLinesScreen({ history }) {
    const dispatch = useDispatch();

    const [currentState, setCurrentState] = useState({
        selectedSampleInventoryLineId: null,
        editMode: 'Lookup',
        sampleInventoryLineListFilter: {
            query: '',
            page: 1,
            limit: 10,
            filter: 'Barcode',
        },
        showDynamicForm: false,
    });

    const sampleInventoryLineList = useSelector((state) => state.sampleInventoryLineList);
    const { loading, error, sampleInventoryLines, currentPage, totalPages, totalCount } = sampleInventoryLineList;

    const sampleInventoryLineDto = useSelector((state) => state.sampleInventoryLineDto);
    const { sampleInventoryLineDto: sampleInventoryLineDtoConfig, loading: dtoLoading, error: dtoError } = sampleInventoryLineDto;

    useEffect(() => {
        dispatch(loadSampleInventoryLineResponseDto());
        dispatch(listSampleInventoryLines(currentState.sampleInventoryLineListFilter));
    }, [dispatch, currentState.sampleInventoryLineListFilter]);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this sample inventory line?')) {
            dispatch(deleteSampleInventoryLine(id));
            setCurrentState(prevState => ({
                ...prevState,
                sampleInventoryLineListFilter: { ...prevState.sampleInventoryLineListFilter, page: 1 }
            }));
        }
    };

    const handleEditForm = (id) => {
        setCurrentState(prevState => ({
            ...prevState,
            selectedSampleInventoryLineId: id,
            editMode: 'Edit',
            showDynamicForm: true,
        }));
    };

    const handleCreateSampleInventoryLine = () => {
        setCurrentState(prevState => ({
            ...prevState,
            selectedSampleInventoryLineId: null,
            editMode: 'Create',
            showDynamicForm: true,
        }));
    };

    const handleFilterChange = (newFilter) => {
        setCurrentState(prevState => ({
            ...prevState,
            sampleInventoryLineListFilter: { ...prevState.sampleInventoryLineListFilter, ...newFilter, page: 1 }
        }));
    };

    const handleRefresh = () => {
        dispatch(listSampleInventoryLines(currentState.sampleInventoryLineListFilter));
    };


    if (error || dtoError) {
        return <Alert message="Error" description={error || dtoError} type="error" />;
    }

    const activeSamples = Array.isArray(sampleInventoryLines)
        ? sampleInventoryLines?.filter(s => s.currentStatus === 'Active').length
        : 0;

    const expiredSamples = Array.isArray(sampleInventoryLines)
        ? sampleInventoryLines?.filter(s => s.currentStatus === 'Expired').length
        : 0;

    return (
        <div>
            <Title level={2}>Sample Inventory Management</Title>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={8}>
                    <Card>
                        <Statistic title="Total Samples" value={totalCount || 0} />
                    </Card>
                </Col>
                <Col xs={24} sm={8}>
                    <Card>
                        <Statistic title="Active Samples" value={activeSamples} />
                    </Card>
                </Col>
                <Col xs={24} sm={8}>
                    <Card>
                        <Statistic title="Expired Samples" value={expiredSamples} />
                    </Card>
                </Col>
            </Row>


            {!currentState.showDynamicForm ? (
                <React.Fragment>
                            <Space>
                            <Button
                                type="primary"
                                size='large'
                                onClick={handleCreateSampleInventoryLine}
                            >
                                + Create a new Sample Inventory Line
                            </Button>
                        </Space>
            
                <ListItemsDataTable
                    loading={loading}
                    dtoConfig={sampleInventoryLineDtoConfig}
                    data={sampleInventoryLines || []}
                    filter={currentState.sampleInventoryLineListFilter}
                    onFilterChange={handleFilterChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleDeleteRow={handleDelete}
                    handleSaveRow={(updatedRow) => {
                        dispatch(updateSampleInventoryLine(updatedRow.id, updatedRow));
                    }}
                    handleEditForm={handleEditForm}
                    summaryProps={['Barcode', 'InventoryCode', 'ReceptionDate', 'CurrentStatus', 'Quantity', 'Unit']}
                />

                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>

                        <Button
                            type="text"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => setCurrentState(prevState => ({ ...prevState, showDynamicForm: false }))}
                        >
                            Back to Sample Inventory List
                        </Button>
                    </Space>
                    <DynamicFormSP
                        key={currentState.selectedSampleInventoryLineId || 'create'}
                        currentEntityId={currentState.selectedSampleInventoryLineId}
                        defaultEditMode={currentState.editMode}
                        singularEntity="sampleInventoryLine"
                        pluralEntity="sampleInventoryLines"
                        constantPrefix="SAMPLE_INVENTORY_LINE"
                        loadDto={loadSampleInventoryLineResponseDto}
                        createEntity={createSampleInventoryLine}
                        updateEntity={updateSampleInventoryLine}
                        getEntityDetails={getSampleInventoryLineDetails}
                        listEntitiesByIds={listSampleInventoryLinesByIds}
                        listEntities={listSampleInventoryLines}
                        onEditModeChange={(newEditMode) => setCurrentState(prevState => ({ ...prevState, editMode: newEditMode }))}
                        isParent={true}
                        fieldsToHide={['Id']}
                        summaryProps={['Barcode', 'InventoryCode', 'ReceptionDate', 'CurrentStatus', 'Quantity', 'Unit']}
                        enableCreate={true}
                        enableSearchBar={false}
                    />
                </React.Fragment>
            )}
        </div>
    );
}