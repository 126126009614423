import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Empty, Button, message, Select, DatePicker, Space, Table, Tooltip } from 'antd';
import { Line } from '@ant-design/plots';
import { ReloadOutlined, ClearOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;


const TestHistoryVisualizer = ({ patientId }) => {
  const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [dateRange, setDateRange] = useState([dayjs().subtract(6, 'month'), dayjs()]);
  const [availableTests, setAvailableTests] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [startDate, endDate] = dateRange;
      const response = await axios.get(`/dotnet_api/v1/testresult/patient/${patientId}/history`, 
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        },
        params: {          
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        }
      });

      const { testResults } = response.data;

      // Sort testResults by resultDate
      testResults.forEach(test => {
        test.results.sort((a, b) => new Date(a.resultDate) - new Date(b.resultDate));
      });

      setTestData(testResults);

      if (testResults.length > 0 && !selectedTest) {
        setSelectedTest(testResults[0].testName);
      } else if (testResults.length === 0) {
        setSelectedTest(null);
      }

      // Update available tests only once
      if (availableTests.length === 0) {
        setAvailableTests(testResults?.map(test => test.testName));
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch test history. Please try again.');
      message.error('Failed to fetch test history');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patientId, dateRange]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleClear = () => {
    setDateRange([dayjs().subtract(6, 'month'), dayjs()]);
    setSelectedTest(null);
  };

  const handleTestChange = (value) => {
    setSelectedTest(value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'resultDate',
      key: 'date',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Value',
      dataIndex: 'result',
      key: 'value',
      render: (value, record) => `${value} ${record.unit || ''}`,
    },
    {
      title: 'Reference Range',
      dataIndex: 'referenceRange',
      key: 'referenceRange',
    },
    {
      title: 'Critical Low',
      dataIndex: 'criticalLow',
      key: 'criticalLow',
      render: (value) => value !== null ? value : 'N/A',
    },
    {
      title: 'Critical High',
      dataIndex: 'criticalHigh',
      key: 'criticalHigh',
      render: (value) => value !== null ? value : 'N/A',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const renderContent = () => {
    if (loading) {
      return <Spin tip="Loading test history..." />;
    }

    if (error) {
      return (
        <Empty
          description={
            <Space direction="vertical">
              <span>{error}</span>
              <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
                Try Again
              </Button>
            </Space>
          }
        />
      );
    }

    if (testData.length === 0) {
      return <Empty description="No test history available" />;
    }

    const selectedTestData = testData.find(test => test.testName === selectedTest);

    if (selectedTestData && selectedTestData.results.length > 0) {
      // Flatten the data for plotting
      const plotData = selectedTestData.results.flatMap(result => [
        { resultDate: result.resultDate, value: Number(result.result), type: 'Result', unit: result.unit },
        { resultDate: result.resultDate, value: Number(result.criticalHigh), type: 'Critical High', unit: result.unit },
        { resultDate: result.resultDate, value: Number(result.criticalLow), type: 'Critical Low', unit: result.unit },
      ]);

      const chartConfig = {
        data: plotData,
        xField: 'resultDate',
        yField: 'value',
        seriesField: 'type',
        colorField: 'type',
        xAxis: {
          type: 'timeCat',
          tickCount: 5,
        },
        yAxis: {
          label: {
        formatter: (v) => `${v} ${plotData[0]?.unit || ''}`,
          },
        },
        interaction: {
          tooltip: {
            marker: false
          },
        },
      
        legend: {
          position: 'top',
        },
        line: {
          style: {
        lineWidth: 2,
          },
        },
        point: {
          shape: 'circle',
          size: 3,
          style: {
        fillOpacity: 1,
        stroke: '#fff',
          },
        },
      };

      return (
        <>
          <Line {...chartConfig} />
          <Table
            columns={columns}
            dataSource={selectedTestData.results}
            rowKey={(record) => record.resultDate}
            pagination={{ pageSize: 5 }}
            style={{ marginTop: 20 }}
          />
        </>
      );
    }

    return <Empty description="No data available for the selected test" />;
  };

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Select
          style={{ width: 300 }}
          placeholder="Select a test to view history"
          onChange={handleTestChange}
          value={selectedTest}
          showSearch
          allowClear
          optionFilterProp="children"
        >
          {availableTests?.map(test => (
            <Option key={test} value={test}>{test}</Option>
          ))}
        </Select>
        <RangePicker
          onChange={handleDateRangeChange}
          value={dateRange}
        />
        <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
          Refresh
        </Button>
        <Button icon={<ClearOutlined />} onClick={handleClear}>
          Clear filters
        </Button>
      </Space>
      {renderContent()}
    </div>
  );
};

export default TestHistoryVisualizer;
