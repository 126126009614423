import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, Card, Button, Space, Drawer, Timeline, Tag, Descriptions,
  Switch, Row, Col, Tooltip, Statistic, Alert, Modal, message
} from 'antd';
import {
  HistoryOutlined, InfoCircleOutlined, ReloadOutlined, EditOutlined,
  ExperimentOutlined, WarningOutlined, CheckCircleOutlined, ClockCircleOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import Printer from '../../components/printer/Printer.component';
import {
  loadSampleInventoryLineResponseDto,
  updateSampleInventoryLine,
  getSampleInventoryLineDetails,
  listSampleInventoryLinesByBarcode,
  listSampleInventoryLines,
  listSampleInventoryLinesByIds
} from '../../actions/sampleInventoryLineActions';

import { barcodeToBase64 } from '../../utils/functions';

const SampleView = ({ barcode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sampleInventoryLines, setSampleInventoryLines] = useState([]);
  const [groupedSamples, setGroupedSamples] = useState({});
  const [selectedSample, setSelectedSample] = useState(null);
  const [isSampleDrawerVisible, setIsSampleDrawerVisible] = useState(false);
  const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false);
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [showMergedView, setShowMergedView] = useState(false);
  const [printerVisible, setPrinterVisible] = useState(false);
  const [labelData, setLabelData] = useState({});

  const sampleInventoryLineState = useSelector((state) => state.sampleInventoryLineListByBarcode);
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (barcode) {
      fetchSampleInventoryLines(barcode);
    }
  }, [barcode]);

  useEffect(() => {
    if (sampleInventoryLineState.sampleInventoryLines) {
      setSampleInventoryLines(sampleInventoryLineState.sampleInventoryLines);
      groupSamplesBySampleType(sampleInventoryLineState.sampleInventoryLines);
    }
  }, [sampleInventoryLineState.sampleInventoryLines]);

  const fetchSampleInventoryLines = async (barcode) => {
    try {
      await dispatch(listSampleInventoryLinesByBarcode(barcode));
    } catch (error) {
      console.error(t('Failed to fetch sample inventory lines:'), error);
    }
  };

  const groupSamplesBySampleType = (samples) => {
    const grouped = samples.reduce((acc, sample) => {
      if (!acc[sample.sampleType]) {
        acc[sample.sampleType] = [];
      }
      acc[sample.sampleType].push(sample);
      return acc;
    }, {});
    setGroupedSamples(grouped);
  };

  const showSampleDetails = (sampleType) => {
    const samples = groupedSamples[sampleType];
    setSelectedSample(showMergedView ? samples : samples[0]);
    setIsSampleDrawerVisible(true);
  };

  const showSampleHistory = (record) => {
    const samples = showMergedView ? groupedSamples[record.sampleType] : [record];
    setSelectedSample(samples);
    setIsHistoryDrawerVisible(true);
  };

  const handleRefresh = () => {
    fetchSampleInventoryLines(barcode);
  };

  const handleEdit = (sample) => {
    setSelectedSample(sample);
    setIsEditDrawerVisible(true);
  };

  const handleSampleUpdate = (updatedSample) => {
    setSampleInventoryLines(prevLines =>
      prevLines?.map(line => line.id === updatedSample.id ? updatedSample : line)
    );
    groupSamplesBySampleType(sampleInventoryLines);
  };

  const handlePrintLabels = async () => {
    if (sampleInventoryLines.length === 0) {
      message.error(t('No samples to print labels for'));
      return;
    }

    try {
      const samplesToProcess = showMergedView
        ? Object.values(groupedSamples)?.map(samples => ({
            barcode: samples?.map(s => s.barcode).join(', '),
            sampleType: samples[0].sampleType,
            testName: samples?.map(s => s.testName).join(', '),
            collectionDate: samples[0].collectionDate,
            expirationDate: samples[0].expirationDate,
          }))
        : sampleInventoryLines;

      const labelLinesPromises = samplesToProcess?.map(async (sample) => {
        const barcodeImage = await barcodeToBase64(sample.barcode.split(', ')[0], {
          format: 'CODE128',
          width: 2,
          height: 50,
          fontSize: 12
        });

        return {
          barcodeImage: barcodeImage,
          sampleType: sample.sampleType,
          testName: sample.testName,
          collectionDate: moment(sample.collectionDate).format('YYYY-MM-DD HH:mm'),
          expirationDate: moment(sample.expirationDate).format('YYYY-MM-DD'),
        };
      });

      const labelLines = await Promise.all(labelLinesPromises);

      const labelData = {
        "ticketContent": labelLines?.map(line => ({
          content: line,
        })),
      };

      setLabelData(labelData);
      setPrinterVisible(true);
    } catch (error) {
      console.error(t('Error generating barcode images:'), error);
      message.error(t('Failed to generate barcode images'));
    }
  };

  const renderSampleDetails = () => (
    <Descriptions title={t("Sample Details")} column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} bordered>
      <Descriptions.Item label={t("Barcode")}>
        {Array.isArray(selectedSample) 
          ? selectedSample?.map(s => s.barcode).join(', ')
          : selectedSample.barcode}
      </Descriptions.Item>
      <Descriptions.Item label={t("Sample Type")}>{selectedSample[0]?.sampleType || selectedSample.sampleType}</Descriptions.Item>
      <Descriptions.Item label={t("Test Name(s)")}>
        {Array.isArray(selectedSample)
          ? selectedSample?.map(s => s.testName).join(', ')
          : selectedSample.testName}
      </Descriptions.Item>
      <Descriptions.Item label={t("Collection Date")}>
        {moment(Array.isArray(selectedSample) ? selectedSample[0].collectionDate : selectedSample.collectionDate).format('YYYY-MM-DD HH:mm')}
      </Descriptions.Item>
      <Descriptions.Item label={t("Collected By")}>
        {Array.isArray(selectedSample) ? selectedSample[0].collectedBy : selectedSample.collectedBy}
      </Descriptions.Item>
      <Descriptions.Item label={t("Current Status")}>
        <Tag color="green">{Array.isArray(selectedSample) ? selectedSample[0].currentStatus : selectedSample.currentStatus}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label={t("Current Location")}>
        {Array.isArray(selectedSample) ? selectedSample[0].currentLocation : selectedSample.currentLocation}
      </Descriptions.Item>
      <Descriptions.Item label={t("Quantity")}>
        {Array.isArray(selectedSample) 
          ? `${selectedSample.reduce((sum, s) => sum + s.quantity, 0)} ${selectedSample[0].unit}`
          : `${selectedSample.quantity} ${selectedSample.unit}`}
      </Descriptions.Item>
      <Descriptions.Item label={t("QC Passed")}>
        {(Array.isArray(selectedSample) ? selectedSample[0].qcPassed : selectedSample.qcPassed) 
          ? <CheckCircleOutlined style={{ color: 'green' }} /> 
          : <WarningOutlined style={{ color: 'red' }} />}
      </Descriptions.Item>
      <Descriptions.Item label={t("QC Notes")}>
        {Array.isArray(selectedSample) ? selectedSample[0].qcNotes || t('N/A') : selectedSample.qcNotes || t('N/A')}
      </Descriptions.Item>
      <Descriptions.Item label={t("Expiration Date")}>
        {moment(Array.isArray(selectedSample) ? selectedSample[0].expirationDate : selectedSample.expirationDate).format('YYYY-MM-DD')}
      </Descriptions.Item>
    </Descriptions>
  );

  const renderSampleHistory = () => {
    const parseHistory = (historyString) => {
      try {
        return JSON.parse(historyString);
      } catch (e) {
        return historyString
          .replace(/^\[|\]$/g, '')
          .split('},{')
          ?.map((item, index, array) => {
            if (index === 0) item = '{' + item;
            if (index === array.length - 1) item = item + '}';
            return JSON.parse(item);
          });
      }
    };
  
    const history = Array.isArray(selectedSample) 
      ? parseHistory(selectedSample[0].history)
      : parseHistory(selectedSample.history);
  
    return (
      <Timeline mode="left" style={{ height: '100%', overflowY: 'auto' }}>
        {history?.map((record, index) => (
          <Timeline.Item
            key={index}
            label={moment(record.UpdatedAt).format('YYYY-MM-DD HH:mm')}
            dot={<ExperimentOutlined style={{ fontSize: '16px' }} />}
            color={record.CurrentStatus === 'Completed' ? 'green' : record.CurrentStatus === 'Rejected' ? 'red' : 'blue'}
          >
            <p><strong>{t("Status")}:</strong> <Tag color="blue">{record.CurrentStatus}</Tag></p>
            <p><strong>{t("Location")}:</strong> {record.CurrentLocation}</p>
            <p><strong>{t("Accessed By")}:</strong> {record.LastAccessedBy}</p>
            {record.QCNotes && <p><strong>{t("QC Notes")}:</strong> {record.QCNotes}</p>}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  };

  const renderStatistics = () => (
    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
      <Col xs={24} sm={12} md={6}>
        <Statistic title={t("Total Samples")} value={sampleInventoryLines.length} />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Statistic
          title={t("Completed Samples")}
          value={sampleInventoryLines?.filter(s => s.currentStatus === 'Completed').length}
          valueStyle={{ color: '#3f8600' }}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Statistic
          title={t("In Progress Samples")}
          value={sampleInventoryLines?.filter(s => s.currentStatus === 'In Progress').length}
          valueStyle={{ color: '#faad14' }}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Statistic
          title={t("Rejected Samples")}
          value={sampleInventoryLines?.filter(s => s.currentStatus === 'Rejected').length}
          valueStyle={{ color: '#cf1322' }}
        />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("Barcode"),
      dataIndex: 'barcode',
      key: 'barcode',
      sorter: (a, b) => a.barcode.localeCompare(b.barcode),
      render: (text, record) => showMergedView ? record.barcodes.join(', ') : text,
      
    },
    {
      title: t("Sample Type"),
      dataIndex: 'sampleType',
      key: 'sampleType',
      sorter: (a, b) => a.sampleType.localeCompare(b.sampleType),
    },
    {
      title: t("Test Name(s)"),
      key: 'testNames',
      render: (_, record) => (
        <>
          {(showMergedView ? record.testNames : [record.testName])?.map((testName, index) => (
            <Tag color="blue" key={index}>
              {testName}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: t("Status"),
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      render: (status) => {
        let color = 'default';
        let icon = null;
        switch (status.toLowerCase()) {
          case 'received':
            color = 'blue';
            icon = <ClockCircleOutlined />;
            break;
          case 'in progress':
            color = 'orange';
            icon = <ExperimentOutlined />;
            break;
          case 'completed':
            color = 'green';
            icon = <CheckCircleOutlined />;
            break;
          case 'rejected':
            color = 'red';
            icon = <WarningOutlined />;
            break;
          default:
            color = 'default';
        }
        return <Tag color={color} icon={icon}>{status}</Tag>;
      },
      sorter: (a, b) => a.currentStatus.localeCompare(b.currentStatus),
    },
    {
      title: t("Collection Date"),
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) => moment(a.collectionDate).unix() - moment(b.collectionDate).unix(),
    },
    {
      title: t("Actions"),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title={t("View Details")}>
            <Button onClick={() => showSampleDetails(record.sampleType)} icon={<InfoCircleOutlined />} />
          </Tooltip>
          {!showMergedView && (
          <Tooltip title={t("View History")}>
            <Button onClick={() => showSampleHistory(record)} icon={<HistoryOutlined />} />
          </Tooltip>
          )}
          {!showMergedView && (
            <Tooltip title={t("Edit Sample")}>
              <Button onClick={() => handleEdit(record)} icon={<EditOutlined />} />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={t("Sample Inventory")}
      extra={
        <Space wrap>
          <Switch
            checkedChildren={t("Merged")}
            unCheckedChildren={t("All")}
            checked={showMergedView}
            onChange={setShowMergedView}
          />
          <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
            {t("Refresh")}
          </Button>
          <Button 
            icon={<PrinterOutlined />} 
            onClick={handlePrintLabels}
            disabled={sampleInventoryLines.length === 0}
          >
            {t("Print Labels")}
          </Button>
        </Space>
      }
    >
      {renderStatistics()}
      
      <Table
        columns={columns}
        sticky
        dataSource={showMergedView 
          ? Object.entries(groupedSamples)?.map(([sampleType, samples]) => ({
              key: sampleType,
              sampleType: sampleType,
              barcodes: samples?.map(s => s.barcode),
              testNames: [...new Set(samples?.map(s => s.testName))],
              currentStatus: samples[0].currentStatus,
              collectionDate: samples[0].collectionDate,
            }))
          : sampleInventoryLines
        }
        rowKey={showMergedView ? "sampleType" : "id"}
        loading={sampleInventoryLineState.loading}
        pagination={{
          total: showMergedView ? Object.keys(groupedSamples).length : sampleInventoryLines.length,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `${t("Total")} ${total} ${t("items")}`,
        }}
        scroll={{ x: 'max-content' }}
        size="small"
      />

      <Drawer
        width={window.innerWidth > 768 ? 640 : '100%'}
        placement="right"
        closable={true}
        onClose={() => setIsSampleDrawerVisible(false)}
        visible={isSampleDrawerVisible}
        title={t("Sample Details")}
      >
        {selectedSample && renderSampleDetails()}
      </Drawer>

      <Drawer
        width={window.innerWidth > 768 ? 640 : '100%'}
        placement="right"
        closable={true}
        onClose={() => setIsHistoryDrawerVisible(false)}
        visible={isHistoryDrawerVisible}
        title={t("Sample History")}
        bodyStyle={{ padding: 0, height: 'calc(100% - 55px)' }}
      >
        {selectedSample && renderSampleHistory()}
      </Drawer>

      <Drawer
        width={window.innerWidth > 768 ? 640 : '100%'}
        placement="right"
        closable={true}
        onClose={() => setIsEditDrawerVisible(false)}
        visible={isEditDrawerVisible}
        title={t("Edit Sample")}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message={t("Sample history tracking information")}
            description={t("All updates will be saved in the history. Please make sure to update the sample details accurately.")}
            type="info"
            showIcon
          />

          {selectedSample && !Array.isArray(selectedSample) && (
            <DynamicFormSP
              key={selectedSample.id}
              currentEntityId={selectedSample.id}
              defaultEditMode="Edit"
              primarySearchProp="barcode"
              secondarySearchProp="sampleType"
              singularEntity="sampleInventoryLine"
              pluralEntity="sampleInventoryLines"
              constantPrefix="SAMPLE_INVENTORY_LINE"
              loadDto={loadSampleInventoryLineResponseDto}
              updateEntity={updateSampleInventoryLine}
              getEntityDetails={getSampleInventoryLineDetails}
              onUpdatedEntityChange={handleSampleUpdate}
              listEntities={listSampleInventoryLines}
              listEntitiesByIds={listSampleInventoryLinesByIds}
              isParent={true}
              fieldsToHide={['Id', 'History']}
              summaryProps={['barcode', 'sampleType', 'currentStatus', 'currentLocation']}
              enableCreate={false}
              enableSearchBar={false}
              enableActionButtons={true}
            />
          )}
        </Space>
      </Drawer>

      <Modal
        title={t("Print Sample Labels")}
        visible={printerVisible}
        onCancel={() => setPrinterVisible(false)}
        footer={null}
        width="80%"
      >
        {labelData && (
          <Printer
            configEndpoint="/print/configs/sample_label"
            generateEndpoint="/print/documents/generate"
            data={labelData}
          />
        )}
      </Modal>
    </Card>
  );
};

export default SampleView;