import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from "./store";
import { Provider } from "react-redux";
// Import Routes
import { authenticatedUserRoutes, publicRoutes, superUserRoutes } from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
// Import layouts and screens
import NonAuthLayout from "./components/layout/NonAuthLayout.component";
import AuthLayout from "./components/layout/Layout.component";
import NotFoundScreen from "./screens/NotFoundScreen";

import 'react-responsive-modal/styles.css';
import './assets/css/styles.css';


const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>

        {publicRoutes?.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            isSuperUserProtected={false}
            exact
          />
        ))}
        <AuthLayout
          content={
            <Switch>
              {authenticatedUserRoutes?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              {superUserRoutes?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isSuperUserProtected={true}
                  exact
                />
              ))}
              <Route path="*" component={NotFoundScreen} />
            </Switch>
          }
        />
      </Switch>
    </Router>
  </Provider>
);

export default App;
