import {

    GUIDELINE_LIST_REQUEST,
    GUIDELINE_LIST_SUCCESS,
    GUIDELINE_LIST_FAIL,

    GUIDELINE_DETAILS_REQUEST,
    GUIDELINE_DETAILS_SUCCESS,
    GUIDELINE_DETAILS_FAIL,

    GUIDELINE_DELETE_REQUEST,
    GUIDELINE_DELETE_SUCCESS,
    GUIDELINE_DELETE_FAIL,

    GUIDELINE_CREATE_REQUEST,
    GUIDELINE_CREATE_SUCCESS,
    GUIDELINE_CREATE_FAIL,
    GUIDELINE_CREATE_RESET,

    GUIDELINE_UPDATE_REQUEST,
    GUIDELINE_UPDATE_SUCCESS,
    GUIDELINE_UPDATE_FAIL,
    GUIDELINE_UPDATE_RESET,

    SET_CURRENT_PAGE,

    GUIDELINE_DTO_FAIL,
    GUIDELINE_DTO_REQUEST,
    GUIDELINE_DTO_SUCCESS,
    GUIDELINE_DTO_RESET,

    GUIDELINE_LIST_BYIDS_REQUEST,
    GUIDELINE_LIST_BYIDS_SUCCESS,
    GUIDELINE_LIST_BYIDS_FAIL,
    GUIDELINE_LIST_BYIDS_RESET

} from "../constants/guidelineConstants";

const initialState = {
    guidelines: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const guidelineListReducer = (
    state = {
        guidelines: []
    }, action) => {
    switch (action.type) {
        case GUIDELINE_LIST_REQUEST:
            return { loading: true, guidelines: [] };
        case GUIDELINE_LIST_SUCCESS:
            // our state will have a list of guidelines after the request
            return {
                loading: false,
                guidelines: action.payload.guidelines,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case GUIDELINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const guidelineListByIDsReducer = (
    state = {
        guidelines: []
    }, action) => {
    switch (action.type) {
        case GUIDELINE_LIST_BYIDS_REQUEST:
            return { loading: true, guidelines: [] };
        case GUIDELINE_LIST_BYIDS_SUCCESS:
            // our state will have a list of guidelines after the request
            return { loading: false, guidelines: action.payload };
        case GUIDELINE_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case GUIDELINE_LIST_BYIDS_RESET:
            return { guidelines: [] };
        default:
            return state;
    }
}


export const guidelineDetailsReducer = (
    state = {
        guideline: { comments: [] }
    }, action) => {
    switch (action.type) {
        case GUIDELINE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case GUIDELINE_DETAILS_SUCCESS:
            // our state will have a list of guidelines after the request
            return { loading: false, guideline: action.payload };
        case GUIDELINE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const guidelineDtoReducer = (state = { guidelineDto: {} }, action) => {
    switch (action.type) {
        case GUIDELINE_DTO_REQUEST:
            return { loading: true, guidelineDto: {} };
        case GUIDELINE_DTO_SUCCESS:
            return { loading: false, guidelineDto: action.payload };
        case GUIDELINE_DTO_FAIL:
            return { loading: false, error: action.payload };
        case GUIDELINE_DTO_RESET:
            return { guidelineDto: {} };
        default:
            return state;
    }
}

export const guidelineDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case GUIDELINE_DELETE_REQUEST:
            return { loading: true }

        case GUIDELINE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case GUIDELINE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const guidelineCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case GUIDELINE_CREATE_REQUEST:
            return { loading: true }

        case GUIDELINE_CREATE_SUCCESS:
            return { loading: false, success: true, guideline: action.payload }

        case GUIDELINE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case GUIDELINE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const guidelineUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case GUIDELINE_UPDATE_REQUEST:
            return { loading: true }

        case GUIDELINE_UPDATE_SUCCESS:
            return { loading: false, success: true, guideline: action.payload }

        case GUIDELINE_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case GUIDELINE_UPDATE_RESET:
            return { guideline: {} }

        default:
            return state
    }
}