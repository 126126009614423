import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { createTest, updateTest, deleteTest, getTestDetails, listTests, listTestsByIds, loadTestDto } from '../../actions/testActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function TestListScreen({ history }) {

  useEffect(() => {
    document.title = "Test";
  }, []);

  const dispatch = useDispatch();

  const [selectedTestId, setSelectedTestId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [testListFilter, setTestListFilter] = useState({ query: '', page: 1, limit: 10, filter: 'Name' });

  // Load test list from the state
  const testList = useSelector((state) => state.testList);
  const { loading, error, tests, currentPage, totalPages } = testList;

  // Load test DTO from the state
  const testDto = useSelector((state) => state.testDto);
  const { testDto: testDtoConfig } = testDto;

  // Use effect to load the test DTO and the test list
  useEffect(() => {
    dispatch(loadTestDto());
    dispatch(listTests(testListFilter));
  }, [dispatch, testListFilter]);

  // Handle delete test
  const handleDelete = (id) => {
    dispatch(deleteTest(id));
    setTestListFilter({ query: '', page: 1, limit: 10, filter: 'Surname' });
  };

  // Handle edit test
  const handleEditForm = (id) => {
    setSelectedTestId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  // Handle canceling the dynamic form
  const handleCancelForm = () => {
    setSelectedTestId(null);
    setShowDynamicForm(false);
  };

  // Handle create test
  const handleCreateTest = () => {
    setSelectedTestId(null);
    setEditMode('Create');
    setShowDynamicForm(true);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        <h2>Test Management</h2>
      </Space>
      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreateTest}
            icon={<PlusOutlined />}
            size="large"
          >
            Create Test
          </Button>



          <ListItemsDataTable
            loading={loading}
            dtoConfig={testDtoConfig}
            data={tests}
            filter={testListFilter}
            onFilterChange={setTestListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={(id) => { handleDelete(id) }}
            onStartEditRow={(id) => { setSelectedTestId(id) }}
            handleSaveRow={(updatedRow) => {
              dispatch(updateTest(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getTestDetails(updatedRow.id));
            }}
            handleEditForm={handleEditForm}
          />
        </React.Fragment>
      )}


      {showDynamicForm && (

        <React.Fragment>
          <Space direction="vertical" style={{ width: "100%", padding: "10px" }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              Back to Tests
            </Button>

          </Space>

          <DynamicFormSP

            currentEntityId={selectedTestId}
            selectedItems={null}
            selectedEntityIds={null}
            listingMode='allEntities'
            defaultEditMode={editMode}
            primarySearchProp={"surname"}
            secondarySearchProp={"forename"}
            singularEntity={"test"}
            pluralEntity={"tests"}
            constantPrefix={"TEST"}
            onSelectedItems={(selectedItems) => { }}
            loadDto={loadTestDto}
            createEntity={createTest}
            updateEntity={updateTest}
            deleteEntity={deleteTest}
            getEntityDetails={getTestDetails}
            listEntities={listTests}
            listEntitiesByIds={listTestsByIds}
            onSelectedEntitiesChange={(e) => { }}
            onUpdatedEntityChange={(e) => { }}
            onNewEntityChange={(e) => { }}
            isParent={true}
            fieldsToHide={['Id', 'Test', 'TestCategoryName']}
            summaryProps={['healthInsuranceNumber', 'surname', 'forename', 'dateOfBirth']}

            // enable buttons
            enableCreate={true}
            enableSearchBar={false}




          />
        </React.Fragment>
      )}
    </React.Fragment>
  );

}