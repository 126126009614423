export const TEMPLATE_LIST_REQUEST = 'TEMPLATE_LIST_REQUEST';
export const TEMPLATE_LIST_SUCCESS = 'TEMPLATE_LIST_SUCCESS';
export const TEMPLATE_LIST_FAIL = 'TEMPLATE_LIST_FAIL';

export const TEMPLATE_DETAILS_REQUEST = 'TEMPLATE_DETAILS_REQUEST';
export const TEMPLATE_DETAILS_SUCCESS = 'TEMPLATE_DETAILS_SUCCESS';
export const TEMPLATE_DETAILS_FAIL = 'TEMPLATE_DETAILS_FAIL';

export const TEMPLATE_CREATE_REQUEST = 'TEMPLATE_CREATE_REQUEST';
export const TEMPLATE_CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS';
export const TEMPLATE_CREATE_FAIL = 'TEMPLATE_CREATE_FAIL';

export const TEMPLATE_UPDATE_REQUEST = 'TEMPLATE_UPDATE_REQUEST';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_FAIL = 'TEMPLATE_UPDATE_FAIL';

export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST';
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS';
export const TEMPLATE_DELETE_FAIL = 'TEMPLATE_DELETE_FAIL';

export const TEMPLATE_PREVIEW_REQUEST = 'TEMPLATE_PREVIEW_REQUEST';
export const TEMPLATE_PREVIEW_SUCCESS = 'TEMPLATE_PREVIEW_SUCCESS';
export const TEMPLATE_PREVIEW_FAIL = 'TEMPLATE_PREVIEW_FAIL';

export const GENERIC_TEMPLATE_LIST_REQUEST = 'GENERIC_TEMPLATE_LIST_REQUEST';
export const GENERIC_TEMPLATE_LIST_SUCCESS = 'GENERIC_TEMPLATE_LIST_SUCCESS';
export const GENERIC_TEMPLATE_LIST_FAIL = 'GENERIC_TEMPLATE_LIST_FAIL';

export const GENERIC_TEMPLATE_DETAILS_REQUEST = 'GENERIC_TEMPLATE_DETAILS_REQUEST';
export const GENERIC_TEMPLATE_DETAILS_SUCCESS = 'GENERIC_TEMPLATE_DETAILS_SUCCESS';
export const GENERIC_TEMPLATE_DETAILS_FAIL = 'GENERIC_TEMPLATE_DETAILS_FAIL';

export const GENERIC_TEMPLATE_CREATE_REQUEST = 'GENERIC_TEMPLATE_CREATE_REQUEST';
export const GENERIC_TEMPLATE_CREATE_SUCCESS = 'GENERIC_TEMPLATE_CREATE_SUCCESS';
export const GENERIC_TEMPLATE_CREATE_FAIL = 'GENERIC_TEMPLATE_CREATE_FAIL';

export const GENERIC_TEMPLATE_UPDATE_REQUEST = 'GENERIC_TEMPLATE_UPDATE_REQUEST';
export const GENERIC_TEMPLATE_UPDATE_SUCCESS = 'GENERIC_TEMPLATE_UPDATE_SUCCESS';
export const GENERIC_TEMPLATE_UPDATE_FAIL = 'GENERIC_TEMPLATE_UPDATE_FAIL';

export const GENERIC_TEMPLATE_DELETE_REQUEST = 'GENERIC_TEMPLATE_DELETE_REQUEST';
export const GENERIC_TEMPLATE_DELETE_SUCCESS = 'GENERIC_TEMPLATE_DELETE_SUCCESS';
export const GENERIC_TEMPLATE_DELETE_FAIL = 'GENERIC_TEMPLATE_DELETE_FAIL';