import axios from 'axios';

import {
    QC_RESULT_LIST_REQUEST,
    QC_RESULT_LIST_SUCCESS,
    QC_RESULT_LIST_FAIL,

    QC_RESULT_DETAILS_REQUEST,
    QC_RESULT_DETAILS_SUCCESS,
    QC_RESULT_DETAILS_FAIL,

    QC_RESULT_DELETE_REQUEST,
    QC_RESULT_DELETE_SUCCESS,
    QC_RESULT_DELETE_FAIL,

    QC_RESULT_CREATE_REQUEST,
    QC_RESULT_CREATE_SUCCESS,
    QC_RESULT_CREATE_FAIL,

    QC_RESULT_UPDATE_REQUEST,
    QC_RESULT_UPDATE_SUCCESS,
    QC_RESULT_UPDATE_FAIL,

    QC_RESULT_DTO_REQUEST,
    QC_RESULT_DTO_SUCCESS,
    QC_RESULT_DTO_FAIL,
    QC_RESULT_DTO_RESET,

    QC_RESULT_LIST_BYIDS_REQUEST,
    QC_RESULT_LIST_BYIDS_SUCCESS,
    QC_RESULT_LIST_BYIDS_FAIL,
    QC_RESULT_LIST_BYIDS_RESET
} from "../constants/qcResultConstants";

export const listQCResults = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RESULT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/qcResult/all', config);

        dispatch({
            type: QC_RESULT_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RESULT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const listQCResultsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RESULT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/qcResult/ids/all', ids, config);

        dispatch({
            type: QC_RESULT_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RESULT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getQCResultDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RESULT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/qcResult/${id}`, config);

        dispatch({
            type: QC_RESULT_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_RESULT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteQCResult = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RESULT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/qcResult/${id}/`,
            config
        )

        dispatch({
            type: QC_RESULT_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: QC_RESULT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createQCResult = (qcResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RESULT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/dotnet_api/v1/qcResult/`,
            qcResult,
            config
        )

        dispatch({
            type: QC_RESULT_CREATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_RESULT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateQCResult = (id, qcResult) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_RESULT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.patch(
            `/dotnet_api/v1/qcResult/${id}/`,
            qcResult,
            config
        )

        dispatch({
            type: QC_RESULT_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: QC_RESULT_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: QC_RESULT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const loadQCResultDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_RESULT_DTO_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/dotnet_api/v1/qcResult/qcResultDto`,
            config
        )

        dispatch({
            type: QC_RESULT_DTO_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_RESULT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}