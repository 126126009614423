export const QC_SCHEDULE_LIST_REQUEST = 'QC_SCHEDULE_LIST_REQUEST';
export const QC_SCHEDULE_LIST_SUCCESS = 'QC_SCHEDULE_LIST_SUCCESS';
export const QC_SCHEDULE_LIST_FAIL = 'QC_SCHEDULE_LIST_FAIL';

export const QC_SCHEDULE_DETAILS_REQUEST = 'QC_SCHEDULE_DETAILS_REQUEST';
export const QC_SCHEDULE_DETAILS_SUCCESS = 'QC_SCHEDULE_DETAILS_SUCCESS';
export const QC_SCHEDULE_DETAILS_FAIL = 'QC_SCHEDULE_DETAILS_FAIL';

export const QC_SCHEDULE_DELETE_REQUEST = 'QC_SCHEDULE_DELETE_REQUEST';
export const QC_SCHEDULE_DELETE_SUCCESS = 'QC_SCHEDULE_DELETE_SUCCESS';
export const QC_SCHEDULE_DELETE_FAIL = 'QC_SCHEDULE_DELETE_FAIL';

export const QC_SCHEDULE_CREATE_REQUEST = 'QC_SCHEDULE_CREATE_REQUEST';
export const QC_SCHEDULE_CREATE_SUCCESS = 'QC_SCHEDULE_CREATE_SUCCESS';
export const QC_SCHEDULE_CREATE_FAIL = 'QC_SCHEDULE_CREATE_FAIL';

export const QC_SCHEDULE_UPDATE_REQUEST = 'QC_SCHEDULE_UPDATE_REQUEST';
export const QC_SCHEDULE_UPDATE_SUCCESS = 'QC_SCHEDULE_UPDATE_SUCCESS';
export const QC_SCHEDULE_UPDATE_FAIL = 'QC_SCHEDULE_UPDATE_FAIL';

export const QC_SCHEDULE_DTO_REQUEST = 'QC_SCHEDULE_DTO_REQUEST';
export const QC_SCHEDULE_DTO_SUCCESS = 'QC_SCHEDULE_DTO_SUCCESS';
export const QC_SCHEDULE_DTO_FAIL = 'QC_SCHEDULE_DTO_FAIL';
export const QC_SCHEDULE_DTO_RESET = 'QC_SCHEDULE_DTO_RESET';

export const QC_SCHEDULE_LIST_BYIDS_REQUEST = 'QC_SCHEDULE_LIST_BYIDS_REQUEST';
export const QC_SCHEDULE_LIST_BYIDS_SUCCESS = 'QC_SCHEDULE_LIST_BYIDS_SUCCESS';
export const QC_SCHEDULE_LIST_BYIDS_FAIL = 'QC_SCHEDULE_LIST_BYIDS_FAIL';
export const QC_SCHEDULE_LIST_BYIDS_RESET = 'QC_SCHEDULE_LIST_BYIDS_RESET';