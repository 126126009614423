import axios from 'axios';

import {
    QC_REPORT_LIST_REQUEST,
    QC_REPORT_LIST_SUCCESS,
    QC_REPORT_LIST_FAIL,

    QC_REPORT_DETAILS_REQUEST,
    QC_REPORT_DETAILS_SUCCESS,
    QC_REPORT_DETAILS_FAIL,

    QC_REPORT_DELETE_REQUEST,
    QC_REPORT_DELETE_SUCCESS,
    QC_REPORT_DELETE_FAIL,

    QC_REPORT_CREATE_REQUEST,
    QC_REPORT_CREATE_SUCCESS,
    QC_REPORT_CREATE_FAIL,

    QC_REPORT_UPDATE_REQUEST,
    QC_REPORT_UPDATE_SUCCESS,
    QC_REPORT_UPDATE_FAIL,

    QC_REPORT_DTO_REQUEST,
    QC_REPORT_DTO_SUCCESS,
    QC_REPORT_DTO_FAIL,
    QC_REPORT_DTO_RESET,

    QC_REPORT_LIST_BYIDS_REQUEST,
    QC_REPORT_LIST_BYIDS_SUCCESS,
    QC_REPORT_LIST_BYIDS_FAIL,
    QC_REPORT_LIST_BYIDS_RESET
} from "../constants/qcReportConstants";

export const listQCReports = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_REPORT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/qcReport/all', config);

        dispatch({
            type: QC_REPORT_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_REPORT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const listQCReportsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_REPORT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/qcReport/ids/all', ids, config);

        dispatch({
            type: QC_REPORT_LIST_BYIDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_REPORT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getQCReportDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_REPORT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/qcReport/${id}`, config);

        dispatch({
            type: QC_REPORT_DETAILS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: QC_REPORT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteQCReport = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_REPORT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/qcReport/${id}/`,
            config
        )

        dispatch({
            type: QC_REPORT_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: QC_REPORT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createQCReport = (qcReport) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_REPORT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/dotnet_api/v1/qcReport/`,
            qcReport,
            config
        )

        dispatch({
            type: QC_REPORT_CREATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_REPORT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateQCReport = (id, qcReport) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QC_REPORT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.patch(
            `/dotnet_api/v1/qcReport/${id}/`,
            qcReport,
            config
        )

        dispatch({
            type: QC_REPORT_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: QC_REPORT_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: QC_REPORT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const loadQCReportDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QC_REPORT_DTO_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `/dotnet_api/v1/qcReport/qcReportDto`,
            config
        )

        dispatch({type: QC_REPORT_DTO_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: QC_REPORT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}