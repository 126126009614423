import {

    DOCUMENT_LIST_REQUEST,
    DOCUMENT_LIST_SUCCESS,
    DOCUMENT_LIST_FAIL,

    DOCUMENT_DETAILS_REQUEST,
    DOCUMENT_DETAILS_SUCCESS,
    DOCUMENT_DETAILS_FAIL,

    DOCUMENT_DELETE_REQUEST,
    DOCUMENT_DELETE_SUCCESS,
    DOCUMENT_DELETE_FAIL,

    DOCUMENT_CREATE_REQUEST,
    DOCUMENT_CREATE_SUCCESS,
    DOCUMENT_CREATE_FAIL,
    DOCUMENT_CREATE_RESET,

    DOCUMENT_UPDATE_REQUEST,
    DOCUMENT_UPDATE_SUCCESS,
    DOCUMENT_UPDATE_FAIL,
    DOCUMENT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    DOCUMENT_DTO_FAIL,
    DOCUMENT_DTO_REQUEST,
    DOCUMENT_DTO_SUCCESS,
    DOCUMENT_DTO_RESET,

    DOCUMENT_LIST_BYIDS_REQUEST,
    DOCUMENT_LIST_BYIDS_SUCCESS,
    DOCUMENT_LIST_BYIDS_FAIL,
    DOCUMENT_LIST_BYIDS_RESET

} from "../constants/documentConstants";

const initialState = {
    documents: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const documentListReducer = (
    state = {
        documents: []
    }, action) => {
    switch (action.type) {
        case DOCUMENT_LIST_REQUEST:
            return { loading: true, documents: [] };
        case DOCUMENT_LIST_SUCCESS:
            // our state will have a list of documents after the request
            return {
                loading: false,
                documents: action.payload.documents,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case DOCUMENT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const documentListByIDsReducer = (
    state = {
        documents: []
    }, action) => {
    switch (action.type) {
        case DOCUMENT_LIST_BYIDS_REQUEST:
            return { loading: true, documents: [] };
        case DOCUMENT_LIST_BYIDS_SUCCESS:
            // our state will have a list of documents after the request
            return { loading: false, documents: action.payload };
        case DOCUMENT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case DOCUMENT_LIST_BYIDS_RESET:
            return { documents: [] };
        default:
            return state;
    }
}


export const documentDetailsReducer = (
    state = {
        document: { comments: [] }
    }, action) => {
    switch (action.type) {
        case DOCUMENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case DOCUMENT_DETAILS_SUCCESS:
            // our state will have a list of documents after the request
            return { loading: false, document: action.payload };
        case DOCUMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const documentDtoReducer = (state = { documentDto: {} }, action) => {
    switch (action.type) {
        case DOCUMENT_DTO_REQUEST:
            return { loading: true, documentDto: {} };
        case DOCUMENT_DTO_SUCCESS:
            return { loading: false, documentDto: action.payload };
        case DOCUMENT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case DOCUMENT_DTO_RESET:
            return { documentDto: {} };
        default:
            return state;
    }
}

export const documentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_DELETE_REQUEST:
            return { loading: true }

        case DOCUMENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case DOCUMENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const documentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT_CREATE_REQUEST:
            return { loading: true }

        case DOCUMENT_CREATE_SUCCESS:
            return { loading: false, success: true, document: action.payload }

        case DOCUMENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case DOCUMENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const documentUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case DOCUMENT_UPDATE_REQUEST:
            return { loading: true }

        case DOCUMENT_UPDATE_SUCCESS:
            return { loading: false, success: true, document: action.payload }

        case DOCUMENT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case DOCUMENT_UPDATE_RESET:
            return { document: {} }

        default:
            return state
    }
}