import React, { useEffect } from "react";
import { Typography, Row, Col, Card, Space, Tag, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import {
  FileTextOutlined,
  UserOutlined,
  FileSearchOutlined,
  DollarOutlined,
  CheckSquareOutlined,
  CalendarOutlined,
  AreaChartOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ApiOutlined,
  TeamOutlined,
  BarChartOutlined
} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import SupersetDashboard from "../components/stats/SupersetDashboard.component";

const { Title, Text } = Typography;

const DashboardScreen = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Dashboard');
  }, [t]);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const quickLinks = [
    { title: t('Test Orders'), icon: <FileTextOutlined />, link: "/testOrderBulkRequests", color: "#1890ff" },
    { title: t('Patients'), icon: <UserOutlined />, link: "/patients", color: "#52c41a" },
    { title: t('Test Results'), icon: <FileSearchOutlined />, link: "/testResultReports", color: "#722ed1" },
    { title: t('Accounting'), icon: <DollarOutlined />, link: "/accounting", color: "#faad14" },
    { title: t('Quality Control'), icon: <CheckSquareOutlined />, link: "/qc_dashboard", color: "#eb2f96" },
    { title: t('Interoperability Engine'), icon: <ApiOutlined />, link: "https://yoctobe.com/wp-content/uploads/2024/07/Yoctobe-Integration-Engine.pdf", color: "#13c2c2", external: true },
    { 
      title: t('Collaboration Platform'), 
      icon: <TeamOutlined />, 
      link: "/mattermost", 
      color: "#fa8c16",
      external: true
    },
    { 
      title: t('Analytics'), 
      icon: <BarChartOutlined />, 
      link: "/superset/welcome/", 
      color: "#2f54eb",
      external: true
    },
  ];

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('Good morning');
    if (hour < 18) return t('Good afternoon');
    return t('Good evening');
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Card style={{ marginBottom: '20px' }}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <Text strong style={{ fontSize: '18px', display: 'block', marginBottom: '10px' }}>
            {getGreeting()}, {userInfo.username}!
          </Text>
          <Text style={{ fontSize: '14px', display: 'block', marginBottom: '10px' }}>
            Welcome to {userInfo.businessOrganisationName}
          </Text>
          <Divider style={{ margin: '10px 0' }} />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Space>
                <AreaChartOutlined style={{ color: '#1890ff' }} />
                <Text>{t('Subscription')}: {userInfo.businessOrganisationSubscriptionPackage}</Text>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Space>
                <CheckCircleOutlined style={{ color: '#52c41a' }} />
                <Text>{userInfo.subscriptionStatus}</Text>
              </Space>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Space>
                <Text>{t('Email Confirmed')}:</Text>
                {userInfo.emailConfirmed ? (
                  <Tag color="success" icon={<CheckCircleOutlined />}>{t('Yes')}</Tag>
                ) : (
                  <Tag color="error" icon={<CloseCircleOutlined />}>{t('No')}</Tag>
                )}
              </Space>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Space>
                <CalendarOutlined style={{ color: '#faad14' }} />
                <Text>{t('Expiry Date')}: {new Date(userInfo.subscriptionExpiryDate).toLocaleDateString()}</Text>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
      <Title level={3}>{t('Quick Access')}</Title>
      <Row gutter={[16, 16]}>
        {quickLinks.map((link, index) => (
          <Col xs={12} sm={8} md={6} lg={4} key={index}>
            {link.external ? (
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                <Card
                  hoverable
                  style={{ textAlign: "center", height: '100%' }}
                >
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={link.icon}
                      size="large"
                      style={{ backgroundColor: link.color, borderColor: link.color }}
                    />
                    <Text strong style={{ fontSize: '12px' }}>{link.title}</Text>
                  </Space>
                </Card>
              </a>
            ) : (
              <Link to={link.link}>
                <Card
                  hoverable
                  style={{ textAlign: "center", height: '100%' }}
                >
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={link.icon}
                      size="large"
                      style={{ backgroundColor: link.color, borderColor: link.color }}
                    />
                    <Text strong style={{ fontSize: '12px' }}>{link.title}</Text>
                  </Space>
                </Card>
              </Link>
            )}
          </Col>
        ))}
      </Row>

      <Title level={3}>{t('Analytics Dashboard')}</Title>
      <SupersetDashboard
        dashboardId={userInfo?.supersetDashboardId}
        baseURL={userInfo?.supersetBaseUrl}
        username={userInfo?.supersetUsername}
        password={userInfo?.supersetPassword}
        first_name={userInfo?.supersetFirstName}
        last_name={userInfo?.supersetLastName}
      />
    </Space>
  );
};

export default DashboardScreen;
