export const INVOICE_REPORT_LIST_REQUEST = 'INVOICE_REPORT_LIST_REQUEST'
export const INVOICE_REPORT_LIST_SUCCESS = 'INVOICE_REPORT_LIST_SUCCESS'
export const INVOICE_REPORT_LIST_FAIL = 'INVOICE_REPORT_LIST_FAIL'


export const INVOICE_REPORT_DETAILS_REQUEST = 'INVOICE_REPORT_DETAILS_REQUEST'
export const INVOICE_REPORT_DETAILS_SUCCESS = 'INVOICE_REPORT_DETAILS_SUCCESS'
export const INVOICE_REPORT_DETAILS_FAIL = 'INVOICE_REPORT_DETAILS_FAIL'


export const INVOICE_REPORT_DELETE_REQUEST = 'INVOICE_REPORT_DELETE_REQUEST'
export const INVOICE_REPORT_DELETE_SUCCESS = 'INVOICE_REPORT_DELETE_SUCCESS'
export const INVOICE_REPORT_DELETE_FAIL = 'INVOICE_REPORT_DELETE_FAIL'

export const INVOICE_REPORT_CREATE_REQUEST = 'INVOICE_REPORT_CREATE_REQUEST'
export const INVOICE_REPORT_CREATE_SUCCESS = 'INVOICE_REPORT_CREATE_SUCCESS'
export const INVOICE_REPORT_CREATE_FAIL = 'INVOICE_REPORT_CREATE_FAIL'
export const INVOICE_REPORT_CREATE_RESET = 'INVOICE_REPORT_CREATE_RESET'

export const INVOICE_REPORT_UPDATE_REQUEST = 'INVOICE_REPORT_UPDATE_REQUEST'
export const INVOICE_REPORT_UPDATE_SUCCESS = 'INVOICE_REPORT_UPDATE_SUCCESS'
export const INVOICE_REPORT_UPDATE_FAIL = 'INVOICE_REPORT_UPDATE_FAIL'
export const INVOICE_REPORT_UPDATE_RESET = 'INVOICE_REPORT_UPDATE_RESET'

export const INVOICE_REPORT_REQUEST = 'INVOICE_REPORT_REQUEST'
export const INVOICE_REPORT_SUCCESS = 'INVOICE_REPORT_SUCCESS'
export const INVOICE_REPORT_FAIL = 'INVOICE_REPORT_FAIL'
export const INVOICE_REPORT_RESET = 'INVOICE_REPORT_RESET'

export const INVOICE_REPORT_COUNT_REQUEST = "INVOICE_REPORT_COUNT_REQUEST";
export const INVOICE_REPORT_COUNT_SUCCESS = "INVOICE_REPORT_COUNT_SUCCESS";
export const INVOICE_REPORT_COUNT_FAIL = "INVOICE_REPORT_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const INVOICE_REPORT_DTO_REQUEST = 'INVOICE_REPORT_DTO_REQUEST';
export const INVOICE_REPORT_DTO_SUCCESS = 'INVOICE_REPORT_DTO_SUCCESS';
export const INVOICE_REPORT_DTO_FAIL = 'INVOICE_REPORT_DTO_FAIL';
export const INVOICE_REPORT_DTO_RESET = 'INVOICE_REPORT_DTO_RESET';

export const INVOICE_REPORT_LIST_BYIDS_REQUEST = 'INVOICE_REPORT_LIST_BYIDS_REQUEST';
export const INVOICE_REPORT_LIST_BYIDS_SUCCESS = 'INVOICE_REPORT_LIST_BYIDS_SUCCESS';
export const INVOICE_REPORT_LIST_BYIDS_FAIL = 'INVOICE_REPORT_LIST_BYIDS_FAIL';
export const INVOICE_REPORT_LIST_BYIDS_RESET = 'INVOICE_REPORT_LIST_BYIDS_RESET';