import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { errorMiddleware } from './routes/middleware/ErrorMiddleware';

import {
    businessOrganisationListReducer,
    businessOrganisationDetailsReducer,
    businessOrganisationCreateReducer,
    businessOrganisationUpdateReducer,
    businessOrganisationDeleteReducer,
    businessOrganisationDtoReducer,
    businessOrganisationListByIdsReducer,
    businessOrganisationActivateReducer,
    businessOrganisationDeactivateReducer,
    sendEmailReducer,
} from './reducers/businessOrganisationReducers';

import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    identityUserDetailsReducer,
    identityUserUpdateReducer,
    userUpdateReducer,
    userListReducer,
    userDeleteReducer,
    userAuthenticationCheckReducer,
    resetPasswordReducer,
    identityUserCreateReducer,
    policiesReducer,
    rolesReducer,
    rolePoliciesReducer
} from './reducers/userReducers';

// organisations
import {
    organisationListReducer,
    organisationDetailsReducer,
    organisationDeleteReducer,
    organisationCreateReducer,
    organisationUpdateReducer,
    organisationDtoReducer,
    organisationListByIDsReducer

} from './reducers/organisationReducers';

// systemSettings
import {
    systemSettingListReducer,
    systemSettingDetailsReducer,
    systemSettingUpdateReducer,
    systemSettingCreateReducer,
    systemSettingDeleteReducer,
    systemSettingDtoReducer,
} from './reducers/systemSettingReducers'
// constole materials
import {
    controlMaterialListReducer,
    controlMaterialDetailsReducer,
    controlMaterialDeleteReducer,
    controlMaterialCreateReducer,
    controlMaterialUpdateReducer,
    controlMaterialDtoReducer,
    controlMaterialListByIdsReducer,
} from './reducers/controlMaterialReducers';

// patients
import {
    patientListReducer,
    patientListByIdsReducer,
    patientDetailsReducer,
    patientEmergencyDetailsReducer,
    patientDeleteReducer,
    patientCreateReducer,
    patientUpdateReducer,
    patientUpdateConsentIdsReducer,
    patientDtoReducer,
} from './reducers/patientReducers';

// ecgs
import {
    ecgListReducer,
    ecgCountReducer,
    ecgDetailsReducer,
    ecgDeleteReducer,
    ecgCreateReducer,
    ecgUpdateReducer,
} from './reducers/ecgReducers';

// symptoms
import {
    symptomListReducer,
    symptomDetailsReducer,
    symptomDeleteReducer,
    symptomCreateReducer,
    symptomUpdateReducer,
    symptomDtoReducer,
    symptomListByIDsReducer

} from './reducers/symptomReducers';

// aetiologies
import {
    aetiologyListReducer,
    aetiologyDetailsReducer,
    aetiologyDeleteReducer,
    aetiologyCreateReducer,
    aetiologyUpdateReducer,
    aetiologyDtoReducer,
    aetiologyListByIDsReducer,
} from './reducers/aetiologyReducers';

// implants
import {
    implantListReducer,
    implantCountReducer,
    implantDetailsReducer,
    implantDetailsByBarcodeReducer,
    implantDeleteReducer,
    implantCreateReducer,
    implantUpdateReducer,
    implantCreateOrUpdateReducer,
    implantDtoReducer,
} from './reducers/implantReducers';

// implantCentres
import {
    implantCentreListReducer,
    implantCentreCountReducer,
    implantCentreDetailsReducer,
    implantCentreDeleteReducer,
    implantCentreCreateReducer,
    implantCentreUpdateReducer,
} from './reducers/implantCentreReducers';

// followupCentres
import {
    followupCentreListReducer,
    followupCentreDetailsReducer,
    followupCentreDeleteReducer,
    followupCentreCreateReducer,
    followupCentreUpdateReducer,
    followupCentreListByIDsReducer,
    followupCentreDtoReducer,
} from './reducers/followupCentreReducers';

// shortUrls
import {
    shortUrlListReducer,
    shortUrlCountReducer,
    shortUrlDetailsReducer,
    shortUrlDetailsByCodeReducer,
    shortUrlDetailsByUserIdReducer,
    shortUrlDeleteReducer,
    shortUrlCreateReducer,
    shortUrlUpdateReducer,
} from './reducers/shortUrlReducers';

// templates
import {
    templateListReducer,
    templateDetailsReducer,
    templateDeleteReducer,
    templateCreateReducer,
    templateUpdateReducer,
    genericTemplateListReducer,
    genericTemplateDetailsReducer,
    genericTemplateCreateReducer,
    genericTemplateUpdateReducer,
    genericTemplateDeleteReducer,  
} from './reducers/templateReducers';

// smtpConfigs
import {
    smtpConfigListReducer,
    smtpConfigCountReducer,
    smtpConfigDetailsReducer,
    smtpConfigDeleteReducer,
    smtpConfigCreateReducer,
    smtpConfigUpdateReducer,
} from './reducers/smtpConfigReducers';

// consents
import {
    consentListReducer,
    consentDetailsReducer,
    consentDeleteReducer,
    consentCreateReducer,
    consentUpdateReducer,
    consentDtoReducer,
    consentListByIDsReducer
} from './reducers/consentReducers';

// applications
import {
    applicationListReducer,
    applicationDetailsReducer,
    applicationDeleteReducer,
    applicationCreateReducer,
    applicationUpdateReducer,
    applicationDtoReducer,
    applicationListByIDsReducer,
} from './reducers/applicationReducers';

// attachments
import {
    attachmentListReducer,
    attachmentDetailsReducer,
    attachmentDeleteReducer,
    attachmentCreateReducer,
    attachmentUpdateReducer,
    attachmentDtoReducer,
    attachmentListByIDsReducer,
} from './reducers/attachmentReducers';

// constants
import {
    constantListReducer,
    constantDetailsReducer,
    constantDeleteReducer,
    constantCreateReducer,
    constantUpdateReducer,
    constantDtoReducer,
    constantListByIDsReducer,
} from './reducers/constantReducers';

// documents
import {
    documentListReducer,
    documentDetailsReducer,
    documentDeleteReducer,
    documentCreateReducer,
    documentUpdateReducer,
    documentDtoReducer,
    documentListByIDsReducer,
} from './reducers/documentReducers';

// encounters
import {
    encounterListReducer,
    encounterDetailsReducer,
    encounterDeleteReducer,
    encounterCreateReducer,
    encounterUpdateReducer,
    encounterDtoReducer,
    encounterListByIDsReducer,
} from './reducers/encounterReducers';

// guidelines
import {
    guidelineListReducer,
    guidelineDetailsReducer,
    guidelineDeleteReducer,
    guidelineCreateReducer,
    guidelineUpdateReducer,
    guidelineDtoReducer,
    guidelineListByIDsReducer,
} from './reducers/guidelineReducers';

// notes
import {
    noteListReducer,
    noteDetailsReducer,
    noteDeleteReducer,
    noteCreateReducer,
    noteUpdateReducer,
    noteDtoReducer,
    noteListByIDsReducer,
} from './reducers/noteReducers';

// payments
import {
    paymentListReducer,
    paymentDetailsReducer,
    paymentDeleteReducer,
    paymentCreateReducer,
    paymentUpdateReducer,
    paymentDtoReducer,
    paymentListByIDsReducer,
} from './reducers/paymentReducers';

// practitioners
import {
    practitionerListReducer,
    practitionerDetailsReducer,
    practitionerDeleteReducer,
    practitionerCreateReducer,
    practitionerUpdateReducer,
    practitionerDtoReducer,
    practitionerListByIDsReducer,
} from './reducers/practitionerReducers';

// pricingTiers
import {
    pricingTierListReducer,
    pricingTierListByIDsReducer,
    pricingTierDetailsReducer,
    pricingTierDeleteReducer,
    pricingTierCreateReducer,
    pricingTierUpdateReducer,
    pricingTierDtoReducer,
} from './reducers/pricingTierReducers';

// pricingRules
import {
    pricingRuleListReducer,
    pricingRuleListByIDsReducer,
    pricingRuleDetailsReducer,
    pricingRuleDeleteReducer,
    pricingRuleCreateReducer,
    pricingRuleUpdateReducer,
    pricingRuleDtoReducer,
} from './reducers/pricingRuleReducers';

// ratings
import {
    ratingListReducer,
    ratingDetailsReducer,
    ratingDeleteReducer,
    ratingCreateReducer,
    ratingUpdateReducer,
    ratingDtoReducer,
    ratingListByIDsReducer,
} from './reducers/ratingReducers';

// reports
import {
    reportListReducer,
    reportDetailsReducer,
    reportDeleteReducer,
    reportCreateReducer,
    reportUpdateReducer,
    reportDtoReducer,
    reportListByIDsReducer,
} from './reducers/reportReducers';

// samples
import {
    sampleListReducer,
    sampleDetailsReducer,
    sampleDeleteReducer,
    sampleCreateReducer,
    sampleUpdateReducer,
    sampleDtoReducer,
    sampleListByIDsReducer,
} from './reducers/sampleReducers';

// sampleInventoryLine
import {
    sampleInventoryLineListReducer,
    sampleInventoryLineListByIDsReducer,
    sampleInventoryLineListByBarcodeReducer,
    sampleInventoryLineDetailsReducer,
    sampleInventoryLineDeleteReducer,
    sampleInventoryLineCreateReducer,
    sampleInventoryLineUpdateReducer,
    sampleInventoryLineDtoReducer,

} from './reducers/sampleInventoryLineReducers';

// tests
import {
    testListReducer,
    testDetailsReducer,
    testDeleteReducer,
    testCreateReducer,
    testUpdateReducer,
    testDtoReducer,
    testListByIDsReducer,
} from './reducers/testReducers';


// testOrder
import {
    testOrderListReducer,
    testOrderDetailsReducer,
    testOrderDeleteReducer,
    testOrderCreateReducer,
    testOrderUpdateReducer,
    testOrderDtoReducer,
    testOrderListByIDsReducer,
} from './reducers/testOrderReducers';

// testOrderBulkRequest
import {
    testOrderBulkRequestListReducer,
    testOrderBulkRequestDetailsReducer,
    testOrderBulkRequestDeleteReducer,
    testOrderBulkRequestCreateReducer,
    testOrderBulkRequestUpdateReducer,
    testOrderBulkRequestDtoReducer,
    testOrderBulkRequestListByIDsReducer,
} from './reducers/testOrderBulkRequestReducers';

// testInventoryLine
import {
    testInventoryLineListReducer,
    testInventoryLineDetailsReducer,
    testInventoryLineDeleteReducer,
    testInventoryLineCreateReducer,
    testInventoryLineUpdateReducer,
    testInventoryLineDtoReducer,
    testInventoryLineListByIDsReducer,
} from './reducers/testInventoryLineReducers';

// testResult
import {
    testResultListReducer,
    testResultDetailsReducer,
    testResultDeleteReducer,
    testResultCreateReducer,
    testResultUpdateReducer,
    testResultDtoReducer,
    testResultListByIDsReducer,
} from './reducers/testResultReducers';

// testResultReport
import {
    testResultReportListReducer,
    testResultReportDetailsReducer,
    testResultReportDeleteReducer,
    testResultReportCreateReducer,
    testResultReportUpdateReducer,
    testResultReportDtoReducer,
    testResultReportListByIDsReducer,
    testResultReportValidationUpdateReducer
} from './reducers/testResultReportReducers';


// implantHistory
import {
    implantHistoryListReducer,
    implantHistoryListByNhsNumberReducer,
    implantHistoryCountReducer,
    implantHistoryDetailsReducer,
    implantHistoryDeleteReducer,
    implantHistoryCreateReducer,
    implantHistoryUpdateReducer,
} from './reducers/implantHistoryReducers';

// implantInstance
import {
    implantInstanceListReducer,
    implantInstanceCountReducer,
    implantInstanceDetailsReducer,
    implantInstanceDeleteReducer,
    implantInstanceCreateReducer,
    implantInstanceUpdateReducer,
} from './reducers/implantInstanceReducers';

// import invoiceLine
import {
    invoiceLineListReducer,
    invoiceLineListByIDsReducer,
    invoiceLineDetailsReducer,
    invoiceLineDeleteReducer,
    invoiceLineCreateReducer,
    invoiceLineUpdateReducer,
    invoiceLineDtoReducer,
} from './reducers/invoiceLineReducers';

// import invoiceReport
import {
    invoiceReportListReducer,
    invoiceReportListByIDsReducer,
    invoiceReportDetailsReducer,
    invoiceReportDeleteReducer,
    invoiceReportCreateReducer,
    invoiceReportUpdateReducer,
    invoiceReportDtoReducer,
} from './reducers/invoiceReportReducers';

// testCategory
import {
    testCategoryListReducer,
    testCategoryListByIDsReducer,
    testCategoryDetailsReducer,
    testCategoryDeleteReducer,
    testCategoryCreateReducer,
    testCategoryUpdateReducer,
    testCategoryDtoReducer,
} from './reducers/testCategoryReducers';


import {
    layoutReducer,
} from './reducers/layoutReducers';

import {
    qcScheduleListReducer,
    qcScheduleDetailsReducer,
    qcScheduleDeleteReducer,
    qcScheduleCreateReducer,
    qcScheduleUpdateReducer,
    qcScheduleDtoReducer,
    qcScheduleListByIdsReducer,
} from './reducers/qcScheduleReducers';

import {
    qcRuleListReducer,
    qcRuleDetailsReducer,
    qcRuleDeleteReducer,
    qcRuleCreateReducer,
    qcRuleUpdateReducer,
    qcRuleDtoReducer,
    qcRuleListByIdsReducer,
} from './reducers/qcRuleReducers';

import {
    qcResultListReducer,
    qcResultDetailsReducer,
    qcResultDeleteReducer,
    qcResultCreateReducer,
    qcResultUpdateReducer,
    qcResultDtoReducer,
    qcResultListByIdsReducer,
} from './reducers/qcResultReducers';

import {
    qcReportListReducer,
    qcReportDetailsReducer,
    qcReportDeleteReducer,
    qcReportCreateReducer,
    qcReportUpdateReducer,
    qcReportDtoReducer,
    qcReportListByIdsReducer,
} from './reducers/qcReportReducers';




const reducer = combineReducers({
    // user auth
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdate: userUpdateReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userAuthenticated: userAuthenticationCheckReducer,
    updatePassword: resetPasswordReducer,
    policies: policiesReducer,
    roles: rolesReducer,
    rolePolicies: rolePoliciesReducer,
    

    // identity user
    identityUserCreate: identityUserCreateReducer,
    identityUserDetails: identityUserDetailsReducer,
    identityUserUpdate: identityUserUpdateReducer,
    // layout
    layoutProps: layoutReducer,
    // organisation
    organisationList: organisationListReducer,
    organisationListByIDs: organisationListByIDsReducer,
    organisationDto: organisationDtoReducer,
    organisationDetails: organisationDetailsReducer,
    organisationDelete: organisationDeleteReducer,
    organisationCreate: organisationCreateReducer,
    organisationUpdate: organisationUpdateReducer,
    // systemSetting
    systemSettingList: systemSettingListReducer,
    systemSettingDetails: systemSettingDetailsReducer,
    systemSettingUpdate: systemSettingUpdateReducer,
    systemSettingCreate: systemSettingCreateReducer,
    systemSettingDelete: systemSettingDeleteReducer,
    systemSettingDtoReducer: systemSettingDtoReducer,
    // patient
    patientList: patientListReducer,
    patientListByIDs: patientListByIdsReducer,
    patientDetails: patientDetailsReducer,
    patientEmergencyDetails: patientEmergencyDetailsReducer,
    patientDelete: patientDeleteReducer,
    patientCreate: patientCreateReducer,
    patientUpdate: patientUpdateReducer,
    patientUpdateConsentIds: patientUpdateConsentIdsReducer,
    patientDto: patientDtoReducer,
    // ecg
    ecgList: ecgListReducer,
    ecgCount: ecgCountReducer,
    ecgDetails: ecgDetailsReducer,
    ecgDelete: ecgDeleteReducer,
    ecgCreate: ecgCreateReducer,
    ecgUpdate: ecgUpdateReducer,
    // symptom
    symptomList: symptomListReducer,
    symptomDetails: symptomDetailsReducer,
    symptomDelete: symptomDeleteReducer,
    symptomCreate: symptomCreateReducer,
    symptomUpdate: symptomUpdateReducer,
    symptomDto: symptomDtoReducer,
    symptomListByIDs: symptomListByIDsReducer,
    // aetiology
    aetiologyList: aetiologyListReducer,
    aetiologyDetails: aetiologyDetailsReducer,
    aetiologyDelete: aetiologyDeleteReducer,
    aetiologyCreate: aetiologyCreateReducer,
    aetiologyUpdate: aetiologyUpdateReducer,
    aetiologyDto: aetiologyDtoReducer,
    aetiologyListByIDs: aetiologyListByIDsReducer,
    // implant
    implantList: implantListReducer,
    implantCount: implantCountReducer,
    implantDetails: implantDetailsReducer,
    implantDetailsByBarcode: implantDetailsByBarcodeReducer,
    implantDelete: implantDeleteReducer,
    implantCreate: implantCreateReducer,
    implantUpdate: implantUpdateReducer,
    implantCreateOrUpdate: implantCreateOrUpdateReducer,
    implantDto: implantDtoReducer,
    // implantHistory
    implantHistoryList: implantHistoryListReducer,
    implantHistoryListByNhsNumber: implantHistoryListByNhsNumberReducer,
    implantHistoryCount: implantHistoryCountReducer,
    implantHistoryDetails: implantHistoryDetailsReducer,
    implantHistoryDelete: implantHistoryDeleteReducer,
    implantHistoryCreate: implantHistoryCreateReducer,
    implantHistoryUpdate: implantHistoryUpdateReducer,
    // implantInstance
    implantInstanceList: implantInstanceListReducer,
    implantInstanceListByNhsNumber: implantInstanceListReducer,
    implantInstanceCount: implantInstanceCountReducer,
    implantInstanceDetails: implantInstanceDetailsReducer,
    implantInstanceDelete: implantInstanceDeleteReducer,
    implantInstanceCreate: implantInstanceCreateReducer,
    implantInstanceUpdate: implantInstanceUpdateReducer,
    // implantCentre
    implantCentreList: implantCentreListReducer,
    implantCentreCount: implantCentreCountReducer,
    implantCentreDetails: implantCentreDetailsReducer,
    implantCentreDelete: implantCentreDeleteReducer,
    implantCentreCreate: implantCentreCreateReducer,
    implantCentreUpdate: implantCentreUpdateReducer,
    // followupCentre
    followupCentreList: followupCentreListReducer,
    followupCentreDetails: followupCentreDetailsReducer,
    followupCentreDelete: followupCentreDeleteReducer,
    followupCentreCreate: followupCentreCreateReducer,
    followupCentreUpdate: followupCentreUpdateReducer,
    followupCentreListByIDs: followupCentreListByIDsReducer,
    followupCentreDto: followupCentreDtoReducer,
    // shortUrl
    shortUrlList: shortUrlListReducer,
    shortUrlCount: shortUrlCountReducer,
    shortUrlDetails: shortUrlDetailsReducer,
    shortUrlDetailsByCode: shortUrlDetailsByCodeReducer,
    shortUrlDetailsByUserId: shortUrlDetailsByUserIdReducer,
    shortUrlDelete: shortUrlDeleteReducer,
    shortUrlCreate: shortUrlCreateReducer,
    shortUrlUpdate: shortUrlUpdateReducer,
    // template
    templateList: templateListReducer,
    templateDetails: templateDetailsReducer,
    templateDelete: templateDeleteReducer,
    templateCreate: templateCreateReducer,
    templateUpdate: templateUpdateReducer,
    genericTemplateList: genericTemplateListReducer,
    genericTemplateDetails: genericTemplateDetailsReducer,
    genericTemplateCreate: genericTemplateCreateReducer,
    genericTemplateUpdate: genericTemplateUpdateReducer,
    genericTemplateDelete: genericTemplateDeleteReducer,  
    // smtpConfig
    smtpConfigList: smtpConfigListReducer,
    smtpConfigCount: smtpConfigCountReducer,
    smtpConfigDetails: smtpConfigDetailsReducer,
    smtpConfigDelete: smtpConfigDeleteReducer,
    smtpConfigCreate: smtpConfigCreateReducer,
    smtpConfigUpdate: smtpConfigUpdateReducer,
    // consent
    consentList: consentListReducer,
    consentListByIDs: consentListByIDsReducer,
    consentDetails: consentDetailsReducer,
    consentDelete: consentDeleteReducer,
    consentCreate: consentCreateReducer,
    consentUpdate: consentUpdateReducer,
    consentDto: consentDtoReducer,
    // application
    applicationList: applicationListReducer,
    applicationListByIDs: applicationListByIDsReducer,
    applicationDetails: applicationDetailsReducer,
    applicationDelete: applicationDeleteReducer,
    applicationCreate: applicationCreateReducer,
    applicationUpdate: applicationUpdateReducer,
    applicationDto: applicationDtoReducer,
    // attachment
    attachmentList: attachmentListReducer,
    attachmentListByIDs: attachmentListByIDsReducer,
    attachmentDetails: attachmentDetailsReducer,
    attachmentDelete: attachmentDeleteReducer,
    attachmentCreate: attachmentCreateReducer,
    attachmentUpdate: attachmentUpdateReducer,
    attachmentDto: attachmentDtoReducer,
    // constant
    constantList: constantListReducer,
    constantListByIDs: constantListByIDsReducer,
    constantDetails: constantDetailsReducer,
    constantDelete: constantDeleteReducer,
    constantCreate: constantCreateReducer,
    constantUpdate: constantUpdateReducer,
    constantDto: constantDtoReducer,
    // document
    documentList: documentListReducer,
    documentListByIDs: documentListByIDsReducer,
    documentDetails: documentDetailsReducer,
    documentDelete: documentDeleteReducer,
    documentCreate: documentCreateReducer,
    documentUpdate: documentUpdateReducer,
    documentDto: documentDtoReducer,
    // encounter
    encounterList: encounterListReducer,
    encounterListByIDs: encounterListByIDsReducer,
    encounterDetails: encounterDetailsReducer,
    encounterDelete: encounterDeleteReducer,
    encounterCreate: encounterCreateReducer,
    encounterUpdate: encounterUpdateReducer,
    encounterDto: encounterDtoReducer,
    // guideline
    guidelineList: guidelineListReducer,
    guidelineListByIDs: guidelineListByIDsReducer,
    guidelineDetails: guidelineDetailsReducer,
    guidelineDelete: guidelineDeleteReducer,
    guidelineCreate: guidelineCreateReducer,
    guidelineUpdate: guidelineUpdateReducer,
    guidelineDto: guidelineDtoReducer,
    // note
    noteList: noteListReducer,
    noteListByIDs: noteListByIDsReducer,
    noteDetails: noteDetailsReducer,
    noteDelete: noteDeleteReducer,
    noteCreate: noteCreateReducer,
    noteUpdate: noteUpdateReducer,
    noteDto: noteDtoReducer,
    // payment
    paymentList: paymentListReducer,
    paymentListByIDs: paymentListByIDsReducer,
    paymentDetails: paymentDetailsReducer,
    paymentDelete: paymentDeleteReducer,
    paymentCreate: paymentCreateReducer,
    paymentUpdate: paymentUpdateReducer,
    paymentDto: paymentDtoReducer,
    // pricingTier
    pricingTierList: pricingTierListReducer,
    pricingTierListByIDs: pricingTierListByIDsReducer,
    pricingTierDetails: pricingTierDetailsReducer,
    pricingTierDelete: pricingTierDeleteReducer,
    pricingTierCreate: pricingTierCreateReducer,
    pricingTierUpdate: pricingTierUpdateReducer,
    pricingTierDto: pricingTierDtoReducer,
    // pricingRule
    pricingRuleList: pricingRuleListReducer,
    pricingRuleListByIDs: pricingRuleListByIDsReducer,
    pricingRuleDetails: pricingRuleDetailsReducer,
    pricingRuleDelete: pricingRuleDeleteReducer,
    pricingRuleCreate: pricingRuleCreateReducer,
    pricingRuleUpdate: pricingRuleUpdateReducer,
    pricingRuleDto: pricingRuleDtoReducer,
    // practitioner
    practitionerList: practitionerListReducer,
    practitionerListByIDs: practitionerListByIDsReducer,
    practitionerDetails: practitionerDetailsReducer,
    practitionerDelete: practitionerDeleteReducer,
    practitionerCreate: practitionerCreateReducer,
    practitionerUpdate: practitionerUpdateReducer,
    practitionerDto: practitionerDtoReducer,
    // rating
    ratingList: ratingListReducer,
    ratingListByIDs: ratingListByIDsReducer,
    ratingDetails: ratingDetailsReducer,
    ratingDelete: ratingDeleteReducer,
    ratingCreate: ratingCreateReducer,
    ratingUpdate: ratingUpdateReducer,
    ratingDto: ratingDtoReducer,
    // report
    reportList: reportListReducer,
    reportListByIDs: reportListByIDsReducer,
    reportDetails: reportDetailsReducer,
    reportDelete: reportDeleteReducer,
    reportCreate: reportCreateReducer,
    reportUpdate: reportUpdateReducer,
    reportDto: reportDtoReducer,
    // sample
    sampleList: sampleListReducer,
    sampleDetails: sampleDetailsReducer,
    sampleDelete: sampleDeleteReducer,
    sampleCreate: sampleCreateReducer,
    sampleUpdate: sampleUpdateReducer,
    sampleDto: sampleDtoReducer,
    sampleListByIDs: sampleListByIDsReducer,
    // sampleInventoryLine
    sampleInventoryLineList: sampleInventoryLineListReducer,
    sampleInventoryLineListByIDs: sampleInventoryLineListByIDsReducer,
    sampleInventoryLineDetails: sampleInventoryLineDetailsReducer,
    sampleInventoryLineDelete: sampleInventoryLineDeleteReducer,
    sampleInventoryLineCreate: sampleInventoryLineCreateReducer,
    sampleInventoryLineUpdate: sampleInventoryLineUpdateReducer,
    sampleInventoryLineDto: sampleInventoryLineDtoReducer,
    sampleInventoryLineListByBarcode: sampleInventoryLineListByBarcodeReducer,

    // test
    testList: testListReducer,
    testListByIDs: testListByIDsReducer,
    testDetails: testDetailsReducer,
    testDelete: testDeleteReducer,
    testCreate: testCreateReducer,
    testUpdate: testUpdateReducer,
    testDto: testDtoReducer,
    // testCategory
    testCategoryList: testCategoryListReducer,
    testCategoryListByIDs: testCategoryListByIDsReducer,
    testCategoryDetails: testCategoryDetailsReducer,
    testCategoryDelete: testCategoryDeleteReducer,
    testCategoryCreate: testCategoryCreateReducer,
    testCategoryUpdate: testCategoryUpdateReducer,
    testCategoryDto: testCategoryDtoReducer,
    // testOrder
    testOrderList: testOrderListReducer,
    testOrderListByIDs: testOrderListByIDsReducer,
    testOrderDetails: testOrderDetailsReducer,
    testOrderDelete: testOrderDeleteReducer,
    testOrderCreate: testOrderCreateReducer,
    testOrderUpdate: testOrderUpdateReducer,
    testOrderDto: testOrderDtoReducer,
    // testInventoryLine
    testInventoryLineList: testInventoryLineListReducer,
    testInventoryLineListByIDs: testInventoryLineListByIDsReducer,
    testInventoryLineDetails: testInventoryLineDetailsReducer,
    testInventoryLineDelete: testInventoryLineDeleteReducer,
    testInventoryLineCreate: testInventoryLineCreateReducer,
    testInventoryLineUpdate: testInventoryLineUpdateReducer,
    testInventoryLineDto: testInventoryLineDtoReducer,
    // testOrderBulkRequest
    testOrderBulkRequestList: testOrderBulkRequestListReducer,
    testOrderBulkRequestListByIDs: testOrderBulkRequestListByIDsReducer,
    testOrderBulkRequestDetails: testOrderBulkRequestDetailsReducer,
    testOrderBulkRequestDelete: testOrderBulkRequestDeleteReducer,
    testOrderBulkRequestCreate: testOrderBulkRequestCreateReducer,
    testOrderBulkRequestUpdate: testOrderBulkRequestUpdateReducer,
    testOrderBulkRequestDto: testOrderBulkRequestDtoReducer,
    // testResult
    testResultList: testResultListReducer,
    testResultListByIDs: testResultListByIDsReducer,
    testResultDetails: testResultDetailsReducer,
    testResultDelete: testResultDeleteReducer,
    testResultCreate: testResultCreateReducer,
    testResultUpdate: testResultUpdateReducer,
    testResultDto: testResultDtoReducer,
    // testResultReport
    testResultReportList: testResultReportListReducer,
    testResultReportListByIDs: testResultReportListByIDsReducer,
    testResultReportDetails: testResultReportDetailsReducer,
    testResultReportDelete: testResultReportDeleteReducer,
    testResultReportCreate: testResultReportCreateReducer,
    testResultReportUpdate: testResultReportUpdateReducer,
    testResultReportDto: testResultReportDtoReducer,
    testResultReportValidationUpdate: testResultReportValidationUpdateReducer,
    // invoiceLine
    invoiceLineList: invoiceLineListReducer,
    invoiceLineListByIDs: invoiceLineListByIDsReducer,
    invoiceLineDetails: invoiceLineDetailsReducer,
    invoiceLineDelete: invoiceLineDeleteReducer,
    invoiceLineCreate: invoiceLineCreateReducer,
    invoiceLineUpdate: invoiceLineUpdateReducer,
    invoiceLineDto: invoiceLineDtoReducer,
    // invoiceReport
    invoiceReportList: invoiceReportListReducer,
    invoiceReportListByIDs: invoiceReportListByIDsReducer,
    invoiceReportDetails: invoiceReportDetailsReducer,
    invoiceReportDelete: invoiceReportDeleteReducer,
    invoiceReportCreate: invoiceReportCreateReducer,
    invoiceReportUpdate: invoiceReportUpdateReducer,
    invoiceReportDto: invoiceReportDtoReducer,
    // constole materials
    controlMaterialList: controlMaterialListReducer,
    controlMaterialDetails: controlMaterialDetailsReducer,
    controlMaterialDelete: controlMaterialDeleteReducer,
    controlMaterialCreate: controlMaterialCreateReducer,
    controlMaterialUpdate: controlMaterialUpdateReducer,
    controlMaterialDto: controlMaterialDtoReducer,
    controlMaterialListByIDs: controlMaterialListByIdsReducer,

    qcScheduleList: qcScheduleListReducer,
    qcScheduleDetails: qcScheduleDetailsReducer,
    qcScheduleDelete: qcScheduleDeleteReducer,
    qcScheduleCreate: qcScheduleCreateReducer,
    qcScheduleUpdate: qcScheduleUpdateReducer,
    qcScheduleDto: qcScheduleDtoReducer,
    qcScheduleListByIDs: qcScheduleListByIdsReducer,

    qcRuleList: qcRuleListReducer,
    qcRuleDetails: qcRuleDetailsReducer,
    qcRuleDelete: qcRuleDeleteReducer,
    qcRuleCreate: qcRuleCreateReducer,
    qcRuleUpdate: qcRuleUpdateReducer,
    qcRuleDto: qcRuleDtoReducer,
    qcRuleListByIDs: qcRuleListByIdsReducer,

    qcResultList: qcResultListReducer,
    qcResultDetails: qcResultDetailsReducer,
    qcResultDelete: qcResultDeleteReducer,
    qcResultCreate: qcResultCreateReducer,
    qcResultUpdate: qcResultUpdateReducer,
    qcResultDto: qcResultDtoReducer,
    qcResultListByIDs: qcResultListByIdsReducer,

    qcReportList: qcReportListReducer,
    qcReportDetails: qcReportDetailsReducer,
    qcReportDelete: qcReportDeleteReducer,
    qcReportCreate: qcReportCreateReducer,
    qcReportUpdate: qcReportUpdateReducer,
    qcReportDto: qcReportDtoReducer,
    qcReportListByIDs: qcReportListByIdsReducer,

    businessOrganisationList: businessOrganisationListReducer,
    businessOrganisationDetails: businessOrganisationDetailsReducer,
    businessOrganisationCreate: businessOrganisationCreateReducer,
    businessOrganisationUpdate: businessOrganisationUpdateReducer,
    businessOrganisationDelete: businessOrganisationDeleteReducer,
    businessOrganisationDto: businessOrganisationDtoReducer,
    businessOrganisationListByIDs: businessOrganisationListByIdsReducer,
    businessOrganisationActivate: businessOrganisationActivateReducer,
    businessOrganisationDeactivate: businessOrganisationDeactivateReducer,
    sendEmail: sendEmailReducer,

});


const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: {
        userInfo: userInfoFromStorage,
    },
};


const middleware = [thunk, errorMiddleware];

const store = process.env.NODE_ENV === 'production' ?
    createStore(reducer, initialState, applyMiddleware(...middleware)) :
    createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

// con

export default store;