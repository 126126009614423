import axios from 'axios';

import {
    ORGANISATION_LIST_REQUEST,
    ORGANISATION_LIST_SUCCESS,
    ORGANISATION_LIST_FAIL,

    ORGANISATION_DETAILS_REQUEST,
    ORGANISATION_DETAILS_SUCCESS,
    ORGANISATION_DETAILS_FAIL,

    ORGANISATION_DELETE_REQUEST,
    ORGANISATION_DELETE_SUCCESS,
    ORGANISATION_DELETE_FAIL,

    ORGANISATION_CREATE_REQUEST,
    ORGANISATION_CREATE_SUCCESS,
    ORGANISATION_CREATE_FAIL,

    ORGANISATION_UPDATE_REQUEST,
    ORGANISATION_UPDATE_SUCCESS,
    ORGANISATION_UPDATE_FAIL,

    ORGANISATION_REQUEST,
    ORGANISATION_FAIL,
    ORGANISATION_RESET,
    ORGANISATION_SUCCESS,

    ORGANISATION_COUNT_SUCCESS,
    ORGANISATION_COUNT_REQUEST,
    ORGANISATION_COUNT_FAIL,

    ORGANISATION_DTO_FAIL,
    ORGANISATION_DTO_REQUEST,
    ORGANISATION_DTO_SUCCESS,
    ORGANISATION_DTO_RESET,

    ORGANISATION_LIST_BYIDS_REQUEST,
    ORGANISATION_LIST_BYIDS_SUCCESS,
    ORGANISATION_LIST_BYIDS_FAIL,
    ORGANISATION_LIST_BYIDS_RESET,

    SET_CURRENT_PAGE,
    

} from "../constants/organisationConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listOrganisations = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORGANISATION_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/organisation/all', config);

        dispatch({
            type: ORGANISATION_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ORGANISATION_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later organisations",
        });
    }
}

// list organisations by ids
export const listOrganisationsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORGANISATION_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/organisation/ids/all', ids, config);

        dispatch({
            type: ORGANISATION_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: ORGANISATION_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later organisations",
        });
    }
}


export const loadOrganisationDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ORGANISATION_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/organisation/organisationDto', config);

        dispatch({
            type: ORGANISATION_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ORGANISATION_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}
  


export const getOrganisationDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORGANISATION_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/organisation/${_id}`, config);

        dispatch({
            type: ORGANISATION_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ORGANISATION_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteOrganisation = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORGANISATION_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/organisation/${_id}/`,
            config
        )

        dispatch({
            type: ORGANISATION_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: ORGANISATION_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createOrganisation = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORGANISATION_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/organisation/`,
            changes,
            config
        )

        dispatch({
            type: ORGANISATION_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: ORGANISATION_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadOrganisationBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make organisation using a local organisation
export const makeOrganisation = (file, organisationId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const organisation = await loadOrganisationBase64(file);
    const organisation_input = JSON.stringify({ "organisation": organisation });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        organisation_input,
        config
    )
    try {
        dispatch({
            type: ORGANISATION_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": organisation }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: ORGANISATION_SUCCESS
                });
                if (organisationId && response.data) {
                    dispatch(updateOrganisation(organisationId, { organisation: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: ORGANISATION_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: ORGANISATION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateOrganisation = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORGANISATION_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/organisation/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: ORGANISATION_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: ORGANISATION_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: ORGANISATION_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}