import {
    QC_RESULT_LIST_REQUEST,
    QC_RESULT_LIST_SUCCESS,
    QC_RESULT_LIST_FAIL,
    QC_RESULT_DETAILS_REQUEST,
    QC_RESULT_DETAILS_SUCCESS,
    QC_RESULT_DETAILS_FAIL,
    QC_RESULT_DELETE_REQUEST,
    QC_RESULT_DELETE_SUCCESS,
    QC_RESULT_DELETE_FAIL,
    QC_RESULT_CREATE_REQUEST,
    QC_RESULT_CREATE_SUCCESS,
    QC_RESULT_CREATE_FAIL,
    QC_RESULT_UPDATE_REQUEST,
    QC_RESULT_UPDATE_SUCCESS,
    QC_RESULT_UPDATE_FAIL,
    QC_RESULT_DTO_REQUEST,
    QC_RESULT_DTO_SUCCESS,
    QC_RESULT_DTO_FAIL,
    QC_RESULT_DTO_RESET,
    QC_RESULT_LIST_BYIDS_REQUEST,
    QC_RESULT_LIST_BYIDS_SUCCESS,
    QC_RESULT_LIST_BYIDS_FAIL,
    QC_RESULT_LIST_BYIDS_RESET,
} from '../constants/qcResultConstants';

export const qcResultListReducer = (state = { qcResults: [] }, action) => {
    switch (action.type) {
        case QC_RESULT_LIST_REQUEST:
            return { loading: true, qcResults: [] };
        case QC_RESULT_LIST_SUCCESS:
            return { 
                loading: false, 
                qcResults: action.payload.qcResults,
                page: action.payload.page,
                pages: action.payload.pages,
                count: action.payload.count,
            };
        case QC_RESULT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcResultDetailsReducer = (state = { qcResult: {} }, action) => {
    switch (action.type) {
        case QC_RESULT_DETAILS_REQUEST:
            return { ...state, loading: true };
        case QC_RESULT_DETAILS_SUCCESS:
            return { loading: false, qcResult: action.payload };
        case QC_RESULT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcResultDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_RESULT_DELETE_REQUEST:
            return { loading: true };
        case QC_RESULT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case QC_RESULT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcResultCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case QC_RESULT_CREATE_REQUEST:
            return { loading: true };
        case QC_RESULT_CREATE_SUCCESS:
            return { loading: false, success: true, qcResult: action.payload };
        case QC_RESULT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcResultUpdateReducer = (state = { qcResult: {} }, action) => {
    switch (action.type) {
        case QC_RESULT_UPDATE_REQUEST:
            return { loading: true };
        case QC_RESULT_UPDATE_SUCCESS:
            return { loading: false, success: true, qcResult: action.payload };
        case QC_RESULT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const qcResultDtoReducer = (state = { qcResultDto: {} }, action) => {
    switch (action.type) {
        case QC_RESULT_DTO_REQUEST:
            return { loading: true };
        case QC_RESULT_DTO_SUCCESS:
            return { loading: false, qcResultDto: action.payload };
        case QC_RESULT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case QC_RESULT_DTO_RESET:
            return { qcResultDto: {} };
        default:
            return state;
    }
};

export const qcResultListByIdsReducer = (state = { qcResults: [] }, action) => {
    switch (action.type) {
        case QC_RESULT_LIST_BYIDS_REQUEST:
            return { loading: true, qcResults: [] };
        case QC_RESULT_LIST_BYIDS_SUCCESS:
            return { loading: false, qcResults: action.payload };
        case QC_RESULT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case QC_RESULT_LIST_BYIDS_RESET:
            return { qcResults: [] };
        default:
            return state;
    }
};