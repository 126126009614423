import {

    TEST_ORDER_LIST_REQUEST,
    TEST_ORDER_LIST_SUCCESS,
    TEST_ORDER_LIST_FAIL,

    TEST_ORDER_DETAILS_REQUEST,
    TEST_ORDER_DETAILS_SUCCESS,
    TEST_ORDER_DETAILS_FAIL,

    TEST_ORDER_DELETE_REQUEST,
    TEST_ORDER_DELETE_SUCCESS,
    TEST_ORDER_DELETE_FAIL,

    TEST_ORDER_CREATE_REQUEST,
    TEST_ORDER_CREATE_SUCCESS,
    TEST_ORDER_CREATE_FAIL,
    TEST_ORDER_CREATE_RESET,

    TEST_ORDER_UPDATE_REQUEST,
    TEST_ORDER_UPDATE_SUCCESS,
    TEST_ORDER_UPDATE_FAIL,
    TEST_ORDER_UPDATE_RESET,

    SET_CURRENT_PAGE,

    TEST_ORDER_DTO_FAIL,
    TEST_ORDER_DTO_REQUEST,
    TEST_ORDER_DTO_SUCCESS,
    TEST_ORDER_DTO_RESET,

    TEST_ORDER_LIST_BYIDS_REQUEST,
    TEST_ORDER_LIST_BYIDS_SUCCESS,
    TEST_ORDER_LIST_BYIDS_FAIL,
    TEST_ORDER_LIST_BYIDS_RESET

} from "../constants/testOrderConstants";

const initialState = {
    testOrders: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const testOrderListReducer = (
    state = {
        testOrders: []
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_LIST_REQUEST:
            return { loading: true, testOrders: [] };
        case TEST_ORDER_LIST_SUCCESS:
            // our state will have a list of testOrders after the request
            return {
                loading: false,
                testOrders: action.payload.testOrders,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case TEST_ORDER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testOrderListByIDsReducer = (
    state = {
        testOrders: []
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_LIST_BYIDS_REQUEST:
            return { loading: true, testOrders: [] };
        case TEST_ORDER_LIST_BYIDS_SUCCESS:
            // our state will have a list of testOrders after the request
            return { loading: false, testOrders: action.payload };
        case TEST_ORDER_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case TEST_ORDER_LIST_BYIDS_RESET:
            return { testOrders: [] };
        default:
            return state;
    }
}


export const testOrderDetailsReducer = (
    state = {
        testOrder: { comments: [] }
    }, action) => {
    switch (action.type) {
        case TEST_ORDER_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEST_ORDER_DETAILS_SUCCESS:
            // our state will have a list of testOrders after the request
            return { loading: false, testOrder: action.payload };
        case TEST_ORDER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const testOrderDtoReducer = (state = { testOrderDto: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case TEST_ORDER_DTO_REQUEST:
        return { ...state, loading: true, error: null };
      case TEST_ORDER_DTO_SUCCESS:
        return { ...state, loading: false, testOrderDto: action.payload };
      case TEST_ORDER_DTO_FAIL:
        return { ...state, loading: false, error: action.payload };
      case TEST_ORDER_DTO_RESET:
        return { testOrderDto: {}, loading: false, error: null };
      default:
        return state;
    }
  };
export const testOrderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_ORDER_DELETE_REQUEST:
            return { loading: true }

        case TEST_ORDER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEST_ORDER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const testOrderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEST_ORDER_CREATE_REQUEST:
            return { loading: true }

        case TEST_ORDER_CREATE_SUCCESS:
            return { loading: false, success: true, testOrder: action.payload }

        case TEST_ORDER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEST_ORDER_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const testOrderUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEST_ORDER_UPDATE_REQUEST:
            return { loading: true }

        case TEST_ORDER_UPDATE_SUCCESS:
            return { loading: false, success: true, testOrder: action.payload }

        case TEST_ORDER_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEST_ORDER_UPDATE_RESET:
            return { testOrder: {} }

        default:
            return state
    }
}