import axios from 'axios';

import {
    TEST_INVENTORY_LINE_LIST_REQUEST,
    TEST_INVENTORY_LINE_LIST_SUCCESS,
    TEST_INVENTORY_LINE_LIST_FAIL,

    TEST_INVENTORY_LINE_DETAILS_REQUEST,
    TEST_INVENTORY_LINE_DETAILS_SUCCESS,
    TEST_INVENTORY_LINE_DETAILS_FAIL,

    TEST_INVENTORY_LINE_DELETE_REQUEST,
    TEST_INVENTORY_LINE_DELETE_SUCCESS,
    TEST_INVENTORY_LINE_DELETE_FAIL,

    TEST_INVENTORY_LINE_CREATE_REQUEST,
    TEST_INVENTORY_LINE_CREATE_SUCCESS,
    TEST_INVENTORY_LINE_CREATE_FAIL,

    TEST_INVENTORY_LINE_UPDATE_REQUEST,
    TEST_INVENTORY_LINE_UPDATE_SUCCESS,
    TEST_INVENTORY_LINE_UPDATE_FAIL,

    TEST_INVENTORY_LINE_REQUEST,
    TEST_INVENTORY_LINE_FAIL,
    TEST_INVENTORY_LINE_RESET,
    TEST_INVENTORY_LINE_SUCCESS,

    TEST_INVENTORY_LINE_DTO_REQUEST,
    TEST_INVENTORY_LINE_DTO_SUCCESS,
    TEST_INVENTORY_LINE_DTO_FAIL,
    TEST_INVENTORY_LINE_DTO_RESET,

    TEST_INVENTORY_LINE_LIST_BYIDS_REQUEST,
    TEST_INVENTORY_LINE_LIST_BYIDS_SUCCESS,
    TEST_INVENTORY_LINE_LIST_BYIDS_FAIL,
    TEST_INVENTORY_LINE_LIST_BYIDS_RESET
    

} from "../constants/testInventoryLineConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listTestInventoryLines = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_INVENTORY_LINE_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/testInventoryLine/all', config);


        dispatch({
            type: TEST_INVENTORY_LINE_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_INVENTORY_LINE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testInventoryLines",
        });
    }
}

// list testInventoryLines by ids
export const listTestInventoryLinesByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_INVENTORY_LINE_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post('/dotnet_api/v1/testInventoryLine/ids/all', ids, config);

        dispatch({
            type: TEST_INVENTORY_LINE_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEST_INVENTORY_LINE_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later testInventoryLines",
        });
    }
}


export const loadTestInventoryLineDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_INVENTORY_LINE_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/testInventoryLine/testInventoryLineDto', config);

        dispatch({
            type: TEST_INVENTORY_LINE_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_INVENTORY_LINE_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getTestInventoryLineDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEST_INVENTORY_LINE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/testInventoryLine/${_id}`, config);

        dispatch({
            type: TEST_INVENTORY_LINE_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEST_INVENTORY_LINE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteTestInventoryLine = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_INVENTORY_LINE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/testInventoryLine/${_id}/`,
            config
        )

        dispatch({
            type: TEST_INVENTORY_LINE_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: TEST_INVENTORY_LINE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTestInventoryLine = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_INVENTORY_LINE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/testInventoryLine/`,
            changes,
            config
        )

        dispatch({
            type: TEST_INVENTORY_LINE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: TEST_INVENTORY_LINE_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadTestInventoryLineBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make testInventoryLine using a local testInventoryLine
export const makeTestInventoryLine = (file, testInventoryLineId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const testInventoryLine = await loadTestInventoryLineBase64(file);
    const testInventoryLine_input = JSON.stringify({ "testInventoryLine": testInventoryLine });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        testInventoryLine_input,
        config
    )
    try {
        dispatch({
            type: TEST_INVENTORY_LINE_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": testInventoryLine }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: TEST_INVENTORY_LINE_SUCCESS
                });
                if (testInventoryLineId && response.data) {
                    dispatch(updateTestInventoryLine(testInventoryLineId, { testInventoryLine: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: TEST_INVENTORY_LINE_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: TEST_INVENTORY_LINE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateTestInventoryLine = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEST_INVENTORY_LINE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/testInventoryLine/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: TEST_INVENTORY_LINE_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: TEST_INVENTORY_LINE_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEST_INVENTORY_LINE_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}