import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, message, Select, Transfer, Input, Button, Space, Popconfirm, Modal, Alert } from 'antd';
import { DeleteOutlined, ReloadOutlined, PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { 
  getAllRoles, 
  getPolicies, 
  updateRolePolicies, 
  createRole, 
  deleteRole,
  getRolePolicies,
  updateRole
} from '../../actions/userActions';

const { Option } = Select;

const RolePermissionManager = ({ currentRole }) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState(currentRole);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');
  const [editedRoleName, setEditedRoleName] = useState('');
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const rolesState = useSelector((state) => state.roles || {});
  const policiesState = useSelector((state) => state.policies || {});
  const rolePoliciesState = useSelector((state) => state.rolePolicies || {});
  const roleUpdateState = useSelector((state) => state.roleUpdate || {});
  const roleCreateState = useSelector((state) => state.roleCreate || {});
  const roleDeleteState = useSelector((state) => state.roleDelete || {});

  const { roles = [], loading: rolesLoading = false, error: rolesError = null } = rolesState;
  const { policies = [], loading: policiesLoading = false, error: policiesError = null } = policiesState;
  const { policies: rolePolicies = [], loading: rolePoliciesLoading = false, error: rolePoliciesError = null } = rolePoliciesState;
  const { error: updateRoleError = null } = roleUpdateState;
  const { error: createRoleError = null } = roleCreateState;
  const { error: deleteRoleError = null } = roleDeleteState;

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getPolicies());
  }, [dispatch]);

  useEffect(() => {
    setSelectedRole(currentRole);
  }, [currentRole]);

  useEffect(() => {
    if (selectedRole) {
      dispatch(getRolePolicies(selectedRole));
    }
  }, [selectedRole, dispatch]);

  useEffect(() => {
    if (Array.isArray(rolePolicies)) {
      setSelectedPolicies(rolePolicies);
    }
  }, [rolePolicies]);

  useEffect(() => {
    if (Array.isArray(roles) && roles.length > 0 && !selectedRole) {
      setSelectedRole(getRoleName(roles[0]));
    }
  }, [roles, selectedRole]);

  const handleRoleSelect = (value) => {
    setSelectedRole(value);
  };

  const handlePolicyChange = (newTargetKeys) => {
    setSelectedPolicies(newTargetKeys);
  };

  const handleSavePolicies = () => {
    if (selectedRole) {
      dispatch(updateRolePolicies(selectedRole, { policies: selectedPolicies }));
    }
  };

  const handleCreateRole = async () => {
    await dispatch(createRole(newRoleName));
    setIsCreateModalVisible(false);
    setNewRoleName('');
    await refreshRolesAndSelect();
  };

  const handleEditRole = async () => {
    await dispatch(updateRole(selectedRole, editedRoleName));
    setIsEditModalVisible(false);
    setEditedRoleName('');
    await refreshRolesAndSelect();
  };

  const handleDeleteRole = async (roleName) => {
    await dispatch(deleteRole(roleName));
    if (selectedRole === roleName) {
      setSelectedRole(null);
    }
    await refreshRolesAndSelect();
  };

  const refreshRolesAndSelect = async () => {
    await dispatch(getAllRoles());
    const updatedRoles = rolesState.roles || [];
    if (updatedRoles.length > 0) {
      setSelectedRole(getRoleName(updatedRoles[0]));
    }
  };

  const refreshPolicies = () => {
    dispatch(getPolicies());
    if (selectedRole) {
      dispatch(getRolePolicies(selectedRole));
    }
  };

  // Error handling
  useEffect(() => {
    if (rolesError) message.error(`Roles error: ${rolesError}`);
    if (policiesError) message.error(`Policies error: ${policiesError}`);
    if (rolePoliciesError) message.error(`Role policies error: ${rolePoliciesError}`);
    if (updateRoleError) message.error(`Update role error: ${updateRoleError}`);
    if (createRoleError) message.error(`Create role error: ${createRoleError}`);
    if (deleteRoleError) message.error(`Delete role error: ${deleteRoleError}`);
  }, [rolesError, policiesError, rolePoliciesError, updateRoleError, createRoleError, deleteRoleError]);

  const renderErrorAlert = () => {
    const errors = [rolesError, policiesError, rolePoliciesError, updateRoleError, createRoleError, deleteRoleError].filter(Boolean);
    if (errors.length > 0) {
      return (
        <Alert
          message="Error"
          description={errors.join('. ')}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
        />
      );
    }
    return null;
  };

  const getRoleName = (role) => {
    if (typeof role === 'string') return role;
    if (typeof role === 'object' && role !== null) {
      return role.detail || role.name || JSON.stringify(role);
    }
    return 'Unknown Role';
  };

  return (
    <Card title="Role and Permission Management" loading={rolesLoading || policiesLoading}>
      {renderErrorAlert()}
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Space>
          <Select
            style={{ width: 200 }}
            placeholder="Select a role"
            value={selectedRole}
            onChange={handleRoleSelect}
          >
            {Array.isArray(roles) && roles?.map((role, index) => (
              <Option key={index} value={getRoleName(role)}>
                <Space>
                  {getRoleName(role)}
                  <Popconfirm
                    title="Are you sure you want to delete this role?"
                    onConfirm={() => handleDeleteRole(getRoleName(role))}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button 
                      icon={<DeleteOutlined />} 
                      size="small" 
                      danger
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                </Space>
              </Option>
            ))}
          </Select>
          <Button icon={<ReloadOutlined />} onClick={refreshRolesAndSelect} />
          <Button icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)} />
          <Button icon={<EditOutlined />} onClick={() => {
            setEditedRoleName(getRoleName(selectedRole));
            setIsEditModalVisible(true);
          }} disabled={!selectedRole} />
        </Space>

        {selectedRole && (
          <Card title={`Manage Permissions for ${getRoleName(selectedRole)}`} loading={rolePoliciesLoading}>
            <Transfer
              dataSource={Array.isArray(policies) ? policies?.map(policy => ({
                key: policy,
                title: policy,
              })) : []}
              showSearch
              filterOption={(inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
              targetKeys={selectedPolicies}
              onChange={handlePolicyChange}
              render={item => item.title}
              listStyle={{
                width: "100%",
                height: "60vh",           
              }}
            />
            <Space style={{ marginTop: 16 }}>
              <Button icon={<SaveOutlined />} onClick={handleSavePolicies}>Save Changes</Button>
              <Button icon={<ReloadOutlined />} onClick={refreshPolicies}>Refresh Policies</Button>
            </Space>
          </Card>
        )}
      </Space>

      <Modal
        title="Create New Role"
        visible={isCreateModalVisible}
        onOk={handleCreateRole}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <Input
          placeholder="Enter role name"
          value={newRoleName}
          onChange={(e) => setNewRoleName(e.target.value)}
        />
      </Modal>

      <Modal
        title="Edit Role Name"
        visible={isEditModalVisible}
        onOk={handleEditRole}
        onCancel={() => setIsEditModalVisible(false)}
      >
        <Input
          placeholder="Enter new role name"
          value={editedRoleName}
          onChange={(e) => setEditedRoleName(e.target.value)}
        />
      </Modal>
    </Card>
  );
};

export default RolePermissionManager;