export const TEST_CATEGORY_LIST_REQUEST = 'TEST_CATEGORY_LIST_REQUEST'
export const TEST_CATEGORY_LIST_SUCCESS = 'TEST_CATEGORY_LIST_SUCCESS'
export const TEST_CATEGORY_LIST_FAIL = 'TEST_CATEGORY_LIST_FAIL'


export const TEST_CATEGORY_DETAILS_REQUEST = 'TEST_CATEGORY_DETAILS_REQUEST'
export const TEST_CATEGORY_DETAILS_SUCCESS = 'TEST_CATEGORY_DETAILS_SUCCESS'
export const TEST_CATEGORY_DETAILS_FAIL = 'TEST_CATEGORY_DETAILS_FAIL'


export const TEST_CATEGORY_DELETE_REQUEST = 'TEST_CATEGORY_DELETE_REQUEST'
export const TEST_CATEGORY_DELETE_SUCCESS = 'TEST_CATEGORY_DELETE_SUCCESS'
export const TEST_CATEGORY_DELETE_FAIL = 'TEST_CATEGORY_DELETE_FAIL'

export const TEST_CATEGORY_CREATE_REQUEST = 'TEST_CATEGORY_CREATE_REQUEST'
export const TEST_CATEGORY_CREATE_SUCCESS = 'TEST_CATEGORY_CREATE_SUCCESS'
export const TEST_CATEGORY_CREATE_FAIL = 'TEST_CATEGORY_CREATE_FAIL'
export const TEST_CATEGORY_CREATE_RESET = 'TEST_CATEGORY_CREATE_RESET'

export const TEST_CATEGORY_UPDATE_REQUEST = 'TEST_CATEGORY_UPDATE_REQUEST'
export const TEST_CATEGORY_UPDATE_SUCCESS = 'TEST_CATEGORY_UPDATE_SUCCESS'
export const TEST_CATEGORY_UPDATE_FAIL = 'TEST_CATEGORY_UPDATE_FAIL'
export const TEST_CATEGORY_UPDATE_RESET = 'TEST_CATEGORY_UPDATE_RESET'

export const TEST_CATEGORY_REQUEST = 'TEST_CATEGORY_REQUEST'
export const TEST_CATEGORY_SUCCESS = 'TEST_CATEGORY_SUCCESS'
export const TEST_CATEGORY_FAIL = 'TEST_CATEGORY_FAIL'
export const TEST_CATEGORY_RESET = 'TEST_CATEGORY_RESET'

export const TEST_CATEGORY_COUNT_REQUEST = "TEST_CATEGORY_COUNT_REQUEST";
export const TEST_CATEGORY_COUNT_SUCCESS = "TEST_CATEGORY_COUNT_SUCCESS";
export const TEST_CATEGORY_COUNT_FAIL = "TEST_CATEGORY_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const TEST_CATEGORY_DTO_REQUEST = 'TEST_CATEGORY_DTO_REQUEST';
export const TEST_CATEGORY_DTO_SUCCESS = 'TEST_CATEGORY_DTO_SUCCESS';
export const TEST_CATEGORY_DTO_FAIL = 'TEST_CATEGORY_DTO_FAIL';
export const TEST_CATEGORY_DTO_RESET = 'TEST_CATEGORY_DTO_RESET';

export const TEST_CATEGORY_LIST_BYIDS_REQUEST = 'TEST_CATEGORY_LIST_BYIDS_REQUEST';
export const TEST_CATEGORY_LIST_BYIDS_SUCCESS = 'TEST_CATEGORY_LIST_BYIDS_SUCCESS';
export const TEST_CATEGORY_LIST_BYIDS_FAIL = 'TEST_CATEGORY_LIST_BYIDS_FAIL';
export const TEST_CATEGORY_LIST_BYIDS_RESET = 'TEST_CATEGORY_LIST_BYIDS_RESET';