import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, Card, Typography, Space, Select, Alert, theme } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../actions/userActions';
import logo from '../assets/images/logo.svg';
import { ThemeContext } from '../index';

const { Title, Text } = Typography;
const { useToken } = theme;

const LoginScreen = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { themeConfig } = useContext(ThemeContext);
  const { token } = useToken();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split('=')[1] : '/user_dashboard';

  useEffect(() => {
    document.title = t('login.pageTitle');
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect, t]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values.email, values.password));
    setLoading(false);
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  const languageOptions = [
    { value: 'en', label: '🇬🇧 English' },
    { value: 'fr', label: '🇫🇷 Français' },
  ];

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      background: token.colorBgLayout,
    }}>
      <Card 
        style={{ 
          width: '100%',
          maxWidth: 400,
          boxShadow: `0 4px 8px ${token.colorBgElevated}`,
          borderRadius: '8px',
          background: token.colorBgContainer,
        }}
        cover={
          <div style={{ 
            background: 'transparent',
            marginTop: '50px', 
            marginBottom: '16px',
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
          }}>
            <img
              src={logo}
              alt="logo"
              style={{
                width: '100%',
                maxWidth: '200px',
                filter: themeConfig.algorithm === theme.darkAlgorithm ? 'invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)' : 'none',
              }}
            />
          </div>
        }
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Title level={3} style={{ textAlign: 'center', margin: 0, color: token.colorTextHeading }}>
            {t('login.welcomeBack')}
          </Title>
          <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
            {isPatient ? t('login.patientPortal') : t('login.staffPortal')}
          </Text>
          
          {errorMessage && (
            <Alert
              message={t('login.error')}
              description={errorMessage}
              type="error"
              closable
              onClose={() => setErrorMessage(null)}
            />
          )}

          <Form
            form={form}
            name="login"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('login.emailRequired') }]}
            >
              <Input size="large" prefix={<UserOutlined />} placeholder={t('login.emailPlaceholder')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('login.passwordRequired') }]}
            >
              <Input.Password size="large" prefix={<LockOutlined />} placeholder={t('login.passwordPlaceholder')} />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox onChange={(e) => setRememberMe(e.target.checked)}>{t('login.rememberMe')}</Checkbox>
              </Form.Item>
              <Link style={{ float: 'right' }} to="/user_reset_password_setup">
                {t('login.forgotPassword')}
              </Link>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} size="large">
                {t('login.loginButton')}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ textAlign: 'center' }}>
            <Text type="secondary">
              {t('login.noAccount')} <Link to="/usersignup">{t('login.signUp')}</Link>
            </Text>
          </div>

          <div style={{ textAlign: 'center' }}>
            <Text type="secondary" onClick={() => setIsPatient(!isPatient)} style={{ cursor: 'pointer' }}>
              {isPatient ? t('login.switchToStaff') : t('login.switchToPatient')}
            </Text>
          </div>

          <Select
            defaultValue={i18n.language}
            style={{ width: 120, marginTop: 16 }}
            onChange={handleLanguageChange}
            options={languageOptions}
          />
        </Space>
      </Card>
    </div>
  );
};

export default LoginScreen;