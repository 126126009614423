import axios from 'axios';

import {
    PRICING_TIER_LIST_REQUEST,
    PRICING_TIER_LIST_SUCCESS,
    PRICING_TIER_LIST_FAIL,

    PRICING_TIER_DETAILS_REQUEST,
    PRICING_TIER_DETAILS_SUCCESS,
    PRICING_TIER_DETAILS_FAIL,

    PRICING_TIER_DELETE_REQUEST,
    PRICING_TIER_DELETE_SUCCESS,
    PRICING_TIER_DELETE_FAIL,

    PRICING_TIER_CREATE_REQUEST,
    PRICING_TIER_CREATE_SUCCESS,
    PRICING_TIER_CREATE_FAIL,

    PRICING_TIER_UPDATE_REQUEST,
    PRICING_TIER_UPDATE_SUCCESS,
    PRICING_TIER_UPDATE_FAIL,

    PRICING_TIER_REQUEST,
    PRICING_TIER_FAIL,
    PRICING_TIER_RESET,
    PRICING_TIER_SUCCESS,

    PRICING_TIER_DTO_REQUEST,
    PRICING_TIER_DTO_SUCCESS,
    PRICING_TIER_DTO_FAIL,
    PRICING_TIER_DTO_RESET,

    PRICING_TIER_LIST_BYIDS_REQUEST,
    PRICING_TIER_LIST_BYIDS_SUCCESS,
    PRICING_TIER_LIST_BYIDS_FAIL,
    PRICING_TIER_LIST_BYIDS_RESET
    

} from "../constants/pricingTierConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listPricingTiers = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: PRICING_TIER_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/dotnet_api/v1/pricingTier/all', config);

        dispatch({
            type: PRICING_TIER_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: PRICING_TIER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later pricingTiers",
        });
    }
}

// list pricingTiers by ids
export const listPricingTiersByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: PRICING_TIER_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post('/dotnet_api/v1/pricingTier/ids/all', ids, config);

        dispatch({
            type: PRICING_TIER_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: PRICING_TIER_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later pricingTiers",
        });
    }
}


export const loadPricingTierDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PRICING_TIER_DTO_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get('/dotnet_api/v1/pricingTier/pricingTierDto', config);

        dispatch({
            type: PRICING_TIER_DTO_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: PRICING_TIER_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const getPricingTierDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: PRICING_TIER_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/dotnet_api/v1/pricingTier/${_id}`, config);

        dispatch({
            type: PRICING_TIER_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: PRICING_TIER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deletePricingTier = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRICING_TIER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/dotnet_api/v1/pricingTier/${_id}/`,
            config
        )

        dispatch({
            type: PRICING_TIER_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PRICING_TIER_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createPricingTier = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRICING_TIER_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/dotnet_api/v1/pricingTier/`,
            changes,
            config
        )

        dispatch({
            type: PRICING_TIER_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: PRICING_TIER_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadPricingTierBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make pricingTier using a local pricingTier
export const makePricingTier = (file, pricingTierId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const pricingTier = await loadPricingTierBase64(file);
    const pricingTier_input = JSON.stringify({ "pricingTier": pricingTier });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const {data} = await axios.post(
        apiEndPoint,
        pricingTier_input,
        config
    )
    try {
        dispatch({
            type: PRICING_TIER_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": pricingTier }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: PRICING_TIER_SUCCESS
                });
                if (pricingTierId && response.data) {
                    dispatch(updatePricingTier(pricingTierId, { pricingTier: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: PRICING_TIER_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: PRICING_TIER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updatePricingTier = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRICING_TIER_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/dotnet_api/v1/pricingTier/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: PRICING_TIER_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: PRICING_TIER_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: PRICING_TIER_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}