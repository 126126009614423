import {

    PATIENT_LIST_REQUEST,
    PATIENT_LIST_SUCCESS,
    PATIENT_LIST_FAIL,

    PATIENT_DETAILS_REQUEST,
    PATIENT_DETAILS_SUCCESS,
    PATIENT_DETAILS_FAIL,

    PATIENT_DELETE_REQUEST,
    PATIENT_DELETE_SUCCESS,
    PATIENT_DELETE_FAIL,

    PATIENT_CREATE_REQUEST,
    PATIENT_CREATE_SUCCESS,
    PATIENT_CREATE_FAIL,
    PATIENT_CREATE_RESET,

    PATIENT_UPDATE_REQUEST,
    PATIENT_UPDATE_SUCCESS,
    PATIENT_UPDATE_FAIL,
    PATIENT_UPDATE_RESET,

    PATIENT_COUNT_SUCCESS,
    PATIENT_COUNT_REQUEST,
    PATIENT_COUNT_FAIL,

    PATIENT_DTO_FAIL,
    PATIENT_DTO_REQUEST,
    PATIENT_DTO_SUCCESS,
    PATIENT_DTO_RESET,

    SET_CURRENT_PAGE,

    PATIENT_LIST_BYIDS_REQUEST,
    PATIENT_LIST_BYIDS_SUCCESS,
    PATIENT_LIST_BYIDS_FAIL,
    PATIENT_LIST_BYIDS_RESET

} from "../constants/patientConstants";

const initialState = {
    patients: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const patientListReducer = (
    state = {
        patients: []
    }, action) => {
    switch (action.type) {
        case PATIENT_LIST_REQUEST:
            return { loading: true, patients: [] };
        case PATIENT_LIST_SUCCESS:
            // our state will have a list of patients after the request
            return {
                loading: false,
                patients: action.payload.patients,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case PATIENT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const patientListByIdsReducer = (
    state = {
        patients: []
    }, action) => {
    switch (action.type) {
        case PATIENT_LIST_BYIDS_REQUEST:
            return { loading: true, patients: [] };
        case PATIENT_LIST_BYIDS_SUCCESS:
            // our state will have a list of patients after the request
            return {
                loading: false,
                patients: action.payload,
            };
        case PATIENT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case PATIENT_LIST_BYIDS_RESET:
            return { patients: [] };
        default:
            return state;
    }
}

export const patientCountReducer = (state = { patientCounts: [] }, action) => {
  switch (action.type) {
    case PATIENT_COUNT_REQUEST:
      return { loading: true, patientCounts: [] };
    case PATIENT_COUNT_SUCCESS:
      return { loading: false, patientCounts: action.payload };
    case PATIENT_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const patientDetailsReducer = (
    state = {
        patient: { comments: [] }
    }, action) => {
    switch (action.type) {
        case PATIENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case PATIENT_DETAILS_SUCCESS:
            // our state will have a list of patients after the request
            return { loading: false, patient: action.payload };
        case PATIENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// patientEmergencyAccess reducer
export const patientEmergencyDetailsReducer = (state = { patient: {} }, action) => {
    switch (action.type) {
        case PATIENT_DETAILS_REQUEST:
            return { loading: true, patient: {} }
        case PATIENT_DETAILS_SUCCESS:
            return { loading: false, success: true, patient: action.payload }
        case PATIENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// patientDto reducer
export const patientDtoReducer = (state = { patientDto: {} }, action) => {
    switch (action.type) {
        case PATIENT_DTO_REQUEST:
            return { loading: true, patientDto: {} }
        case PATIENT_DTO_SUCCESS:
            return { loading: false, success: true, patientDto: action.payload }
        case PATIENT_DTO_FAIL:
            return { loading: false, error: action.payload }
        case PATIENT_DTO_RESET:
            return { patientDto: {} }
        default:
            return state
    }
}



export const patientDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PATIENT_DELETE_REQUEST:
            return { loading: true }

        case PATIENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PATIENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const patientCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PATIENT_CREATE_REQUEST:
            return { loading: true }

        case PATIENT_CREATE_SUCCESS:
            return { loading: false, success: true, patient: action.payload }

        case PATIENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PATIENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const patientUpdateReducer = (state = { patient: {} }, action) => {
    switch (action.type) {
        case PATIENT_UPDATE_REQUEST:
            return { loading: true }

        case PATIENT_UPDATE_SUCCESS:
            return { loading: false, success: true, patient: action.payload }

        case PATIENT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PATIENT_UPDATE_RESET:
            return { patient: {} }

        default:
            return state
    }
}


export const patientUpdateConsentIdsReducer = (state = { patient: {} }, action) => {
    switch (action.type) {
        case PATIENT_UPDATE_REQUEST:
            return { loading: true }

        case PATIENT_UPDATE_SUCCESS:
            return { loading: false, success: true, patient: action.payload }

        case PATIENT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PATIENT_UPDATE_RESET:
            return { patient: {} }

        default:
            return state
    }
}