import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { createIdentityUser } from "../actions/userActions";
import { Card, Form, Input, Button, Typography, Space, Select, Alert, theme } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import { ThemeContext } from '../index';
import logo from '../assets/images/logo.svg';
import { IDENTITY_USER_CREATE_RESET } from "../constants/userConstants";
import { listBusinessOrganisations } from "../actions/businessOrganisationActions";

const { Title, Text } = Typography;
const { useToken } = theme;

function RegisterScreen() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { themeConfig } = useContext(ThemeContext);
    const { token } = useToken();

    const [form] = Form.useForm();
    const [newIdentityUser, setNewIdentityUser] = useState({});

    const redirect = location.search ? location.search.split("=")[1] : "/";

    const identityUserCreate = useSelector((state) => state.identityUserCreate);
    const { loading, error, success } = identityUserCreate;

    useEffect(() => {
        dispatch({ type: IDENTITY_USER_CREATE_RESET });
        dispatch(listBusinessOrganisations());
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                dispatch({ type: IDENTITY_USER_CREATE_RESET });
                window.location.href = '/userlogin';
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [success, dispatch]);

    const onFinish = (values) => {
        dispatch(createIdentityUser(values));
    };

    const handleLanguageChange = (value) => {
        i18n.changeLanguage(value);
    };

    const languageOptions = [
        { value: 'en', label: '🇬🇧 English' },
        { value: 'fr', label: '🇫🇷 Français' },
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            background: token.colorBgLayout,
        }}>
            <Card
                style={{
                    width: '100%',
                    maxWidth: 400,
                    boxShadow: `0 4px 8px ${token.colorBgElevated}`,
                    borderRadius: '8px',
                    background: token.colorBgContainer,
                }}
                cover={
                    <div style={{
                        background: 'transparent',
                        marginTop: '50px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px'
                    }}>
                        <img
                            src={logo}
                            alt="logo"
                            style={{
                                width: '100%',
                                maxWidth: '200px',
                                filter: themeConfig.algorithm === theme.darkAlgorithm ? 'invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)' : 'none',
                            }}
                        />
                    </div>
                }
            >
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Title level={3} style={{ textAlign: 'center', margin: 0, color: token.colorTextHeading }}>
                        {t('register.title')}
                    </Title>

                    {error && (
                        <Alert
                            message={t("Error")}
                            description={error}
                            type="error"
                            showIcon
                        />
                    )}

                    {success && (
                        <Alert
                            message={t("Success")}
                            description={t('register.successMessage')}
                            type="success"
                            showIcon
                        />
                    )}

                    <Form
                        form={form}
                        name="register"
                        onFinish={onFinish}
                        layout="vertical"
                        size="large"
                    >
                        <Form.Item
                            name="businessOrganisationId"
                            rules={[{ required: true, message: t('register.organisationRequired') }]}
                        >
                            <Select
                                placeholder={t('register.selectOrganisation')}
                                onChange={(value) => setNewIdentityUser({ ...newIdentityUser, businessOrganisationId: value })}
                            >
                                <Select.Option value="4fd0a57b-2789-4e43-a6af-9c263f258a6f">Prolab LIS</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: t('register.emailRequired') },
                                { type: 'email', message: t('register.invalidEmail') }
                            ]}
                        >
                            <Input 
                                prefix={<MailOutlined />} 
                                placeholder={t('register.emailPlaceholder')}
                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, email: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item
                            name="userName"
                            rules={[{ required: true, message: t('register.usernameRequired') }]}
                        >
                            <Input 
                                prefix={<UserOutlined />} 
                                placeholder={t('register.usernamePlaceholder')}
                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, userName: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: t('register.firstNameRequired') }]}
                        >
                            <Input 
                                prefix={<IdcardOutlined />} 
                                placeholder={t('register.firstNamePlaceholder')}
                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, firstName: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: t('register.lastNameRequired') }]}
                        >
                            <Input 
                                prefix={<IdcardOutlined />} 
                                placeholder={t('register.lastNamePlaceholder')}
                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, lastName: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: t('register.passwordRequired') }]}
                        >
                            <Input.Password 
                                prefix={<LockOutlined />} 
                                placeholder={t('register.passwordPlaceholder')}
                                onChange={(e) => setNewIdentityUser({ ...newIdentityUser, password: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                                {t('register.registerButton')}
                            </Button>
                        </Form.Item>
                    </Form>

                    <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                        {t('register.alreadyHaveAccount')} <Link to={redirect ? `/userlogin?redirect=${redirect}` : "/userlogin"}>{t('register.signInLink')}</Link>
                    </Text>
                    <Select
                        defaultValue={i18n.language}
                        style={{ width: 120, marginTop: 16 }}
                        onChange={handleLanguageChange}
                        options={languageOptions}
                    />
                </Space>
            </Card>
        </div>
    );
}

export default RegisterScreen;