import { notification } from 'antd';
import { createBrowserHistory } from 'history';
import i18next from 'i18next';

export const history = createBrowserHistory();

export const errorMiddleware = store => next => action => {
    if (action.type.endsWith('_FAIL')) {
        const errorMessages = Array.isArray(action.payload) ? action.payload : [action.payload];

        errorMessages.forEach(message => {
            if (typeof message === 'string') {
                const is401Error = message.includes('401');
                const is403Error = message.includes('403');
                const isAuthError = message.toLowerCase().includes('authorization') || 
                                    message.toLowerCase().includes('authentication');

                if (is401Error || is403Error || isAuthError) {
                    let errorType, errorDescription;

                    if (is401Error) {
                        errorType = i18next.t('Authentication Error');
                        errorDescription = i18next.t('Your session has expired. Please log in again.');
                    } else if (is403Error) {
                        errorType = i18next.t('Authorization Error');
                        errorDescription = i18next.t('You do not have permission to access this resource. Please contact your administrator.');
                    } else {
                        errorType = i18next.t('Authentication Error');
                        errorDescription = i18next.t('An authentication error occurred. Please try logging in again.');
                    }

                    notification.error({
                        message: errorType,
                        description: errorDescription,
                        placement: 'topRight',
                        duration: 4.5,
                    });
                }
            }
        });
    }

    return next(action);
};