import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space, Row, Col, Card, Statistic } from 'antd';
import { PlusOutlined, ArrowLeftOutlined, CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  createTestOrderBulkRequest,
  updateTestOrderBulkRequest,
  deleteTestOrderBulkRequest,
  getTestOrderBulkRequestDetails,
  listTestOrderBulkRequests,
  listTestOrderBulkRequestsByIds,
  loadTestOrderBulkRequestDto,
} from '../../actions/testOrderBulkRequestActions';
import DynamicFormSP from "../../components/crud/DynamicFormSP.component";
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';

export default function TestOrderBulkRequestListScreen({ history }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('TestOrderBulkRequest Management');
  }, []);


  const [selectedTestOrderBulkRequestId, setSelectedTestOrderBulkRequestId] = useState(null);
  const [editMode, setEditMode] = useState('Lookup');
  const [limit, setLimit] = useState(10);
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const [testOrderBulkRequestListFilter, setTestOrderBulkRequestListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: 'Barcode',
  });

  const testOrderBulkRequestList = useSelector((state) => state.testOrderBulkRequestList);
  const { loading, error, testOrderBulkRequests, currentPage, totalPages, totalCount, statistics } = testOrderBulkRequestList;

  const testOrderBulkRequestDto = useSelector((state) => state.testOrderBulkRequestDto);
  const { testOrderBulkRequestDto: testOrderBulkRequestDtoConfig } = testOrderBulkRequestDto;

  useEffect(() => {
    dispatch(loadTestOrderBulkRequestDto());
    dispatch(listTestOrderBulkRequests(testOrderBulkRequestListFilter));
  }, [dispatch, testOrderBulkRequestListFilter]);

  const handleDelete = (id) => {
    dispatch(deleteTestOrderBulkRequest(id));
    setTestOrderBulkRequestListFilter({ query: '', page: 1, limit: 10, filter: 'Barcode' });
  };

  const handleEditForm = (id) => {
    setSelectedTestOrderBulkRequestId(id);
    setEditMode('Edit');
    setShowDynamicForm(true);
  };

  const handleCancelForm = () => {
    setSelectedTestOrderBulkRequestId(null);
    setShowDynamicForm(false);
  };

  const handleCreateTestOrderBulkRequest = () => {
    setSelectedTestOrderBulkRequestId(null);
    history.push('/testorderbulkrequests/create');
  };

  const getStatusCount = (status) => {
    const statusCount = statistics?.statusCounts?.find((sc) => sc.status === status);
    return statusCount ? statusCount.count : 0;
  };

  const getOrderTypeCount = (orderType) => {
    const orderTypeCount = statistics?.orderTypeCounts?.find((otc) => otc.orderType === orderType);
    return orderTypeCount ? orderTypeCount.count : 0;
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h2>{t('TestOrderBulkRequest Management')}</h2>
      </Space>
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic title={t('Total Requests')} value={totalCount} />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card hoverable>
            <Statistic 
              title={t('Completed Requests')}
              value={getStatusCount('Completed')}
              prefix={<CheckCircleOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic
              title={t('Pending Requests')}
              value={getStatusCount('Pending')}
              prefix={<ClockCircleOutlined />}
              valueStyle={{ color: '#faad14' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={6}>
          <Card>
            <Statistic
              title={t('Urgent Requests')}
              value={getOrderTypeCount('Urgent')}
              prefix={<ExclamationCircleOutlined />}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      {!showDynamicForm && (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleCreateTestOrderBulkRequest}
            icon={<PlusOutlined />}
            size="large"
          >
            {t('Create TestOrderBulkRequest')}
          </Button>
          <ListItemsDataTable
            loading={loading}
            dtoConfig={testOrderBulkRequestDtoConfig}
            data={testOrderBulkRequests}
            filter={testOrderBulkRequestListFilter}
            onFilterChange={setTestOrderBulkRequestListFilter}
            currentPage={currentPage}
            totalPages={totalPages}
            limit={limit}
            handleDeleteRow={handleDelete}
            handleSaveRow={(updatedRow) => {
              dispatch(updateTestOrderBulkRequest(updatedRow.id, updatedRow));
            }}
            onDataUpdated={(updatedRow) => {
              dispatch(getTestOrderBulkRequestDetails(updatedRow.id));
            }}
            handleEditForm={(id) => {
              history.push(`/testorderbulkrequests/edit/${id}`);
            }}
          />
        </React.Fragment>
      )}

      
      {showDynamicForm && (
        <React.Fragment>
          <Space direction="vertical" style={{ width: '100%', padding: '10px' }}>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleCancelForm}>
              {t('Back to TestOrderBulkRequests')}
            </Button>
          </Space>

              <DynamicFormSP
                currentEntityId={selectedTestOrderBulkRequestId}
                selectedItems={[]}
                selectedEntityIds={[]}
                listingMode="allEntities"
                defaultEditMode={editMode}
                primarySearchProp="surname"
                secondarySearchProp="forename" 
                singularEntity="testOrderBulkRequest"
                pluralEntity="testOrderBulkRequests"
                constantPrefix="TEST_ORDER_BULK_REQUEST"
                loadDto={loadTestOrderBulkRequestDto}
                createEntity={createTestOrderBulkRequest}
                updateEntity={updateTestOrderBulkRequest}
                deleteEntity={deleteTestOrderBulkRequest}
                getEntityDetails={getTestOrderBulkRequestDetails}
                listEntities={listTestOrderBulkRequests}
                listEntitiesByIds={listTestOrderBulkRequestsByIds}
                onSelectedItems={() => {}}
                onSelectedEntitiesChange={() => {}}
                onUpdatedEntityChange={() => {}}
                onNewEntityChange={() => {}}
                isParent={true}
                fieldsToHide={['Id', 'Test']}
                summaryProps={['healthInsuranceNumber', 'surname', 'forename', 'dateOfBirth']}
                enableCreate={true}
                enableSearchBar={true}
              />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}