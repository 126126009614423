import React from 'react';
import { ColorPicker, Typography } from 'antd';
import { Line, Area } from '@ant-design/plots';
import { styles } from '../../utils/functions';

const { Text } = Typography;

const QCResultMiniGraph = ({ 
  acceptableLimitLow, 
  warningLimitLow, 
  warningLimitHigh, 
  acceptableLimitHigh, 
  foundValue, 
  findingDate,
  unit
}) => {
  // Convert all values to numbers
  const acceptableLimitLowNum = Number(acceptableLimitLow);
  const warningLimitLowNum = Number(warningLimitLow);
  const warningLimitHighNum = Number(warningLimitHigh);
  const acceptableLimitHighNum = Number(acceptableLimitHigh);
  const foundValueNum = Number(foundValue);

  // Validate if all values are numbers
  if (isNaN(acceptableLimitLowNum) || 
      isNaN(warningLimitLowNum) || 
      isNaN(warningLimitHighNum) || 
      isNaN(acceptableLimitHighNum) || 
      isNaN(foundValueNum)) {
    return <Text>No valid result value available for graph visualization.</Text>;
  }

  // Create date objects
  const findingDateObj = new Date(findingDate);
  const pastDate = new Date(findingDateObj);
  const futureDate = new Date(findingDateObj);
  pastDate.setFullYear(findingDateObj.getFullYear() - 1); // 1 year in the past
  futureDate.setFullYear(findingDateObj.getFullYear() + 1); // 1 year in the future

  // Prepare data for plotting
  const plotData = [
    // Extend limits before and after the finding date
    { date: pastDate.toISOString(), value: acceptableLimitLowNum, type: 'Critical Low', unit, region: 'Critical Range' },
    { date: findingDate, value: acceptableLimitLowNum, type: 'Critical Low', unit , region: 'Critical Range'},
    { date: futureDate.toISOString(), value: acceptableLimitLowNum, type: 'Critical Low', unit , region: 'Critical Range' },

    { date: pastDate.toISOString(), value: warningLimitLowNum, type: 'Warning Low', unit , region: 'Critical Range'},
    { date: findingDate, value: warningLimitLowNum, type: 'Warning Low', unit , region: 'Critical Range'},
    { date: futureDate.toISOString(), value: warningLimitLowNum, type: 'Warning Low', unit, region: 'Critical Range' },

    { date: pastDate.toISOString(), value: warningLimitHighNum, type: 'Warning High', unit , region: 'Critical Range'},
    { date: findingDate, value: warningLimitHighNum, type: 'Warning High', unit , region: 'Critical Range'},
    { date: futureDate.toISOString(), value: warningLimitHighNum, type: 'Warning High', unit, region: 'Critical Range' },

    { date: pastDate.toISOString(), value: acceptableLimitHighNum, type: 'Critical High', unit , region: 'Critical Range'},
    { date: findingDate, value: acceptableLimitHighNum, type: 'Critical High', unit , region: 'Critical Range'},
    { date: futureDate.toISOString(), value: acceptableLimitHighNum, type: 'Critical High', unit , region: 'Critical Range'},

    // Single point for the found value
    { date: findingDate, value: foundValueNum, type: 'Found Value', unit },
  ];

  // Configuration for Line chart
  const chartConfig = {
    data: plotData,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    colorField: 'type',
    color:['#19CDD7','#DDB27C'],

    xAxis: {
      type: 'time',
      tickCount: 5,
      title: {
        text: 'Date',
        style: {
          fontSize: 14,
        },
      },
    },
    yAxis: {
      label: {
        formatter: (v) => `${v} ${unit || ''}`,
      },
      title: {
        text: 'Value',
        style: {
          fontSize: 14,
        },
      },
    },
    point: {
      size: 40, // Increase the size of the points
      shape: 'diamond', // Change the shape of the points
    },
    style: {
        lineWidth: 4,
      },
    interaction: {
        tooltip: {
          marker: false
        },
      },    
      
    legend: {
      position: 'top',
    },
      annotations: [
    {
      type: "range",
      yField: 'y',
      xField: 'x',
      colorField: 'region',
      data: [
        { x: [pastDate.toISOString(), futureDate.toISOString()], y: [acceptableLimitLowNum, acceptableLimitHighNum], region: 'Critical Range' },
      ],
      scale: {
        color: {
          range: ["#d8d0c0", "#a3dda1"],
          independent: true,
          guide: null,
        },
      },
      style: { fillOpacity: 0.2 },
    }
  ]

  };

  return (
    <div style={{ width: '100%', height: '300px' }}> {/* Ensure container is full width */}
      <Text strong>Finding Date: {new Date(findingDate).toLocaleDateString()}</Text>
      <Line {...chartConfig} />
    </div>
  );
};

export default QCResultMiniGraph;
