import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listSymptoms, createSymptom, updateSymptom, deleteSymptom, getSymptomDetails } from '../../actions/symptomActions';
// constants 
import { SYMPTOM_CREATE_RESET, SYMPTOM_UPDATE_RESET } from '../../constants/symptomConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function SymptomCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Edit Symptoms"; 
    }, []);


    // reset the state of the symptomCreate and symptomUpdate
    useEffect(() => {
        dispatch({ type: SYMPTOM_CREATE_RESET });
        dispatch({ type: SYMPTOM_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/dotnet_api/v1/symptom/all?page=1&limit=2&filter=Email&query=gmail
    const [symptomListFilter, setsymptomListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get symptom list from redux store
    const symptomList = useSelector((state) => state.symptomList);
    const { loading, error, symptoms, currentPage, totalPages } = symptomList;

    // get symptom create from redux store
    const symptomCreate = useSelector((state) => state.symptomCreate);
    const { success: successCreate, error: symptomCreateErrors } = symptomCreate;

    // get symptom update from redux store
    const symptomUpdate = useSelector((state) => state.symptomUpdate);
    const { success: successUpdate, loading: symptomUpdateProcessing } = symptomUpdate;

    // get symptom delete from redux store
    const symptomDelete = useSelector((state) => state.symptomDelete);
    const { success: successDelete } = symptomDelete;

    // get symptom details from redux store
    const symptomDetails = useSelector((state) => state.symptomDetails);
    const { symptom: symptomDet, loading: symptomDetailsLoading } = symptomDetails;

    // newSymptom state
    const [newSymptom, setNewSymptom] = useState({});
    const [updatedSymptom, setUpdatedSymptom] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listSymptoms(symptomListFilter));
    }, [dispatch, symptomListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setsymptomListFilter({ ...symptomListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setsymptomListFilter({ ...symptomListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setsymptomListFilter({ ...symptomListFilter, filter: selectedFilter });
    };

    // get all symptoms
    useEffect(() => {
        dispatch(listSymptoms({}));
    }, [dispatch]);

    // listen to symptomDet 
    useEffect(() => {
        if (symptomDet) {
            // use spread operator to copy the symptomDet object
            setUpdatedSymptom({ ...symptomDet });
        }
    }, [symptomDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newSymptom state
            setNewSymptom({
                name: '',
                description: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this symptom?')) {
            dispatch(deleteSymptom(id)).then(() => {
                dispatch(listSymptoms({}));
            }
            );
        }
    };

    // handle edit
    const handleEditSymptom = (id) => {
        
        dispatch(getSymptomDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateSymptom = () => {
        dispatch(createSymptom(newSymptom)).then(() => {
            setUpdatedSymptom(newSymptom);
            setEditMode(true);
            dispatch(listSymptoms({}));
        }
        );
    };

    // handle update
    const handleUpdateSymptom = () => {
        dispatch(updateSymptom(updatedSymptom.id, updatedSymptom)).then(() => {

            setUpdatedSymptom(updatedSymptom);
            setEditMode(true);
            dispatch(getSymptomDetails(updatedSymptom.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Symptom Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the symptoms. You can create, update and delete symptoms in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {symptomCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {symptomCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {symptomCreateErrors?.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-symptoms"
                                        label="Search symptoms"
                                        name="search-symptoms"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {symptoms && symptoms.length > 0 ? (
                                            symptoms?.map((symptom) => (
                                                <Details key={symptom._id}>
                                                    <Details.Summary>
                                                        {symptom.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Description:</strong> {symptom.description}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={symptomDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditSymptom(symptom.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(symptom.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Symptom
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an symptom. If you want to create a new symptom, please click the "Create Symptom" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Symptom
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedSymptom({ ...updatedSymptom, name: e.target.value })}
                                            value={updatedSymptom.name ? updatedSymptom.name : ''}
                                            required
                                        />
                                        <Textarea
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={(e) => setUpdatedSymptom({ ...updatedSymptom, description: e.target.value })}
                                            value={updatedSymptom.description ? updatedSymptom.description : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The symptom was created successfully." : "The symptom was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={symptomUpdateProcessing}
                                        onClick={handleUpdateSymptom}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Symptom
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewSymptom({ ...newSymptom, name: e.target.value })}
                                            value={newSymptom?.name}
                                            required
                                        />
                                        <Textarea
                                            id="description-create"
                                            label="Description"
                                            name="description-create"
                                            onChange={(e) => setNewSymptom({ ...newSymptom, description: e.target.value })}
                                            value={newSymptom?.description}
                                        />

                                    </Card.Description>

                                    <Button
                                        disabled={symptomUpdateProcessing}
                                        onClick={handleCreateSymptom}
                                    >
                                        Create Symptom
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






