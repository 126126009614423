import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography, Space, Button, Row, Col, Card, Statistic,
  Calendar, Badge, Select, message, Tooltip, Tag, Popover
} from 'antd';
import {
  PlusOutlined, CalendarOutlined, UnorderedListOutlined,
  UserOutlined, ClockCircleOutlined, ScheduleOutlined,
  CloseCircleOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ListItemsDataTable from '../../components/crud/ListItemsDataTable.component';
import DynamicFormSP from '../../components/crud/DynamicFormSP.component';
import { fieldEntityActionConfig } from '../../components/crud/utils/fieldEntityActionConfig';
import { statusOptions } from '../../components/crud/utils/fieldOptions';

const { Title, Text } = Typography;
const { Option } = Select;

export default function EncountersManagementScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewMode, setViewMode] = useState('calendar');
  const [isFormView, setIsFormView] = useState(false);
  const [selectedEncounterId, setSelectedEncounterId] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all');

  const entityConfig = fieldEntityActionConfig.EncounterIds;
  const [listFilter, setListFilter] = useState({
    query: '',
    page: 1,
    limit: 10,
    filter: entityConfig.primarySearchProp
  });

  const encounterList = useSelector(state => state.encounterList);
  const { loading, encounters, error, currentPage, totalPages } = encounterList;

  const dtoConfig = useSelector(state => state[`${entityConfig.singularEntity}Dto`]);
  const { [`${entityConfig.singularEntity}Dto`]: entityDtoConfig } = dtoConfig;

  const encounterStats = useMemo(() => {
    const total = encounters?.length || 0;
    const completed = encounters?.filter(e => e.status === 'Completed').length || 0;
    const scheduled = encounters?.filter(e => e.status === 'Pending').length || 0;
    const cancelled = encounters?.filter(e => e.status === 'Cancelled').length || 0;

    return { total, completed, scheduled, cancelled };
  }, [encounters]);

  useEffect(() => {
    dispatch(entityConfig.listEntities(listFilter));
    dispatch(entityConfig.loadDto());
  }, [dispatch, listFilter]);

  const filteredEncounters = useMemo(() => {
    return encounters?.filter(encounter => {
      const matchesStatus = filterStatus === 'all' || encounter.status === filterStatus;
      return matchesStatus;
    });
  }, [encounters, filterStatus]);

  const handleCreateEncounter = () => {
    setSelectedEncounterId(null);
    setIsFormView(true);
  };

  const handleEditEncounter = (id) => {
    setSelectedEncounterId(id);
    setIsFormView(true);
  };

  const handleDeleteEncounter = (id) => {
    dispatch(entityConfig.deleteEntity(id));
    message.success('Encounter deleted successfully');
  };

  const handleBack = () => {
    setIsFormView(false);
    setSelectedEncounterId(null);
  };

  const getBadgeStatus = (status) => {
    switch (status) {
      case 'Completed': return 'success';
      case 'Pending': return 'processing';
      case 'Cancelled': return 'error';
      default: return 'default';
    }
  };

  const renderHeader = () => (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
          Return back
        </Button>
      </Space>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>{t("Encounter Management")}</Title>
        </Col>
        <Col>
          <Space>
            <Button
              type={viewMode === 'calendar' ? 'primary' : 'default'}
              icon={<CalendarOutlined />}
              onClick={() => setViewMode('calendar')}
            >
              Calendar
            </Button>
            <Button
              type={viewMode === 'list' ? 'primary' : 'default'}
              icon={<UnorderedListOutlined />}
              onClick={() => setViewMode('list')}
            >
              List
            </Button>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateEncounter}>
              Create Encounter
            </Button>
          </Space>
        </Col>
      </Row>
    </Space>
  );

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      {renderHeader()}

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Card hoverable>
            <Statistic
              title="Total Encounters"
              value={encounterStats.total}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card hoverable>
            <Statistic
              title="Completed"
              value={encounterStats.completed}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ClockCircleOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card hoverable>
            <Statistic
              title="Scheduled"
              value={encounterStats.scheduled}
              valueStyle={{ color: '#1890ff' }}
              prefix={<ScheduleOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card hoverable>
            <Statistic
              title="Cancelled"
              value={encounterStats.cancelled}
              valueStyle={{ color: '#cf1322' }}
              prefix={<CloseCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {!isFormView ? (
        <>
          <Row justify="end" gutter={[16, 16]} align="middle">
            <Col>
              <Select
                defaultValue="all"
                style={{ width: 200 }}
                onChange={(value) => setFilterStatus(value)}
              >
                <Option value="all">All Statuses</Option>
                {statusOptions?.map(option => (
                  <Option key={option.value} value={option.value}>{option.text}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          {viewMode === 'calendar' ? (
            <Calendar
              dateCellRender={(value) => {
                const encountersForDate = filteredEncounters?.filter(encounter =>
                  new Date(encounter.encounterDate).toDateString() === value.toDate().toDateString()
                );
                return (
                  <ul className="events" style={{ listStyle: 'none', padding: 0 }}>
                    {encountersForDate?.map((item) => (
                      <li key={item.id} style={{ marginBottom: '3px' }}>
                        <Popover
                          content={
                            <Space direction="vertical" size="small">
                              <Text strong>{item.patientName}</Text>
                              <Text>Type: {item.encounterType}</Text>
                              <Text>Status: <Tag color={getBadgeStatus(item.status)}>{item.status}</Tag></Text>
                              <Text>Reference: {item.referenceNumber}</Text>
                              <Button type="link" onClick={() => handleEditEncounter(item.id)}>Edit</Button>
                            </Space>
                          }
                          trigger="click"
                        >
                          <Badge
                            status={getBadgeStatus(item.status)}
                            text={item.referenceNumber}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                          />
                        </Popover>
                      </li>
                    ))}
                  </ul>
                );
              }}
              onSelect={(date) => {
                const encountersForDate = filteredEncounters?.filter(e =>
                  new Date(e.encounterDate).toDateString() === date.toDate().toDateString()
                );
                if (encountersForDate.length > 0) {
                  setSelectedEncounterId(encountersForDate[0].id);
                  setIsFormView(true);
                }
              }}
            />
          ) : (
            <ListItemsDataTable
              loading={loading}
              dtoConfig={entityDtoConfig}
              data={encounters}
              filter={listFilter}
              currentPage={currentPage}
              totalPages={totalPages}
              limit={listFilter.limit}
              onFilterChange={setListFilter}
              handleDeleteRow={handleDeleteEncounter}
              handleEditForm={handleEditEncounter}
              enableActionButtons={true}
            />
          )}
        </>
      ) : (
        <>
          <Button type="text" onClick={handleBack} icon={<ArrowLeftOutlined />}>
            Back to Encounters List
          </Button>

          <DynamicFormSP
            key={selectedEncounterId || 'new'}
            currentEntityId={selectedEncounterId}
            defaultEditMode={selectedEncounterId ? "Edit" : "Create"}
            primarySearchProp={entityConfig.primarySearchProp}
            secondarySearchProp={entityConfig.secondarySearchProp}
            singularEntity={entityConfig.singularEntity}
            pluralEntity={entityConfig.pluralEntity}
            constantPrefix={entityConfig.constantPrefix}
            loadDto={entityConfig.loadDto}
            createEntity={entityConfig.createEntity}
            updateEntity={entityConfig.updateEntity}
            deleteEntity={entityConfig.deleteEntity}
            getEntityDetails={entityConfig.getEntityDetails}
            listEntities={entityConfig.listEntities}
            listEntitiesByIds={entityConfig.listEntitiesByIds}
            onUpdatedEntityChange={() => {
              // Handle communication between parent and child components if needed
            }}
            isParent={true}
            fieldsToHide={["Id"]}
            summaryProps={entityConfig.summaryProps}
            enableCreate={true}
            enableSearchBar={false}
            enableActionButtons={true}
          />
        </>
      )}
    </Space>
  );
}
