import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import Barcode from 'react-barcode';
import { ReloadOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const RandomBarcode = ({ startsWith, length, format, width, height, fontSize, inputValue, onValueChange }) => {
  const [barcode, setBarcode] = useState(inputValue || '');
  const [isGenerated, setIsGenerated] = useState(!inputValue);
  const [showValidateButton, setShowValidateButton] = useState(true);
  const { t } = useTranslation();

  const generateRandomBarcode = () => {
    const randomDigits = Math.floor(Math.random() * Math.pow(10, length - startsWith.length))
      .toString().padStart(length - startsWith.length, '0');
    const newBarcode = `${startsWith}${randomDigits}`;
    setBarcode(newBarcode);
    setIsGenerated(true);
    setShowValidateButton(true);
    onValueChange({ target: { value: newBarcode } });
  };

  const validateBarcode = () => {
    onValueChange({ target: { value: barcode } });
    setShowValidateButton(false);
  };

  useEffect(() => {
    if (inputValue) {
      setBarcode(inputValue);
      setIsGenerated(false);
      setShowValidateButton(false);
    } else if (!barcode) {
      generateRandomBarcode();
    }
  }, [inputValue]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Barcode
        value={barcode}
        format={format}
        width={width}
        height={height}
        fontSize={fontSize}
      />
      <Space size="small" style={{ marginLeft: '10px' }}>
        {showValidateButton && (
          <Tooltip title={t('barcode.validateTooltip')}>
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={validateBarcode}
              size="small"
            >
              {t('barcode.validate')}
            </Button>
          </Tooltip>
        )}
                <Popconfirm
          title={t('barcode.confirmRegenerate')}
          onConfirm={generateRandomBarcode}
          okText={t('common.yes')}
          cancelText={t('common.no')}
        >
          <Button
            type="text"
            icon={<ReloadOutlined />}
            title={isGenerated ? t('barcode.regenerate') : t('barcode.generateNew')}
          />
        </Popconfirm>

      </Space>
    </div>
  );
};

export default RandomBarcode;