import axios from 'axios'
import {
    SYSTEM_SETTING_LIST_REQUEST,
    SYSTEM_SETTING_LIST_SUCCESS,
    SYSTEM_SETTING_LIST_FAIL,
    SYSTEM_SETTING_DETAILS_REQUEST,
    SYSTEM_SETTING_DETAILS_SUCCESS,
    SYSTEM_SETTING_DETAILS_FAIL,
    SYSTEM_SETTING_UPDATE_REQUEST,
    SYSTEM_SETTING_UPDATE_SUCCESS,
    SYSTEM_SETTING_UPDATE_FAIL,
    SYSTEM_SETTING_CREATE_REQUEST,
    SYSTEM_SETTING_CREATE_SUCCESS,
    SYSTEM_SETTING_CREATE_FAIL,
    SYSTEM_SETTING_DELETE_REQUEST,
    SYSTEM_SETTING_DELETE_SUCCESS,
    SYSTEM_SETTING_DELETE_FAIL,
    SYSTEM_SETTING_DTO_REQUEST,
    SYSTEM_SETTING_DTO_SUCCESS,
    SYSTEM_SETTING_DTO_FAIL,
} from '../constants/systemSettingConstants'

export const listSystemSettings = (filter = {}) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: filter,
        }

        const { data } = await axios.get('/dotnet_api/v1/SystemSetting/all', config)

        dispatch({
            type: SYSTEM_SETTING_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SYSTEM_SETTING_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSystemSettingDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get(`/dotnet_api/v1/SystemSetting/${id}`, config)

        dispatch({
            type: SYSTEM_SETTING_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SYSTEM_SETTING_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSystemSetting = (id, settings) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_UPDATE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.patch(`/dotnet_api/v1/SystemSetting/${id}`, settings, config)

        dispatch({
            type: SYSTEM_SETTING_UPDATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: SYSTEM_SETTING_UPDATE_FAIL,
            payload: errorMessages,
        })
    }
}

export const createSystemSetting = (settings) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_CREATE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post('/dotnet_api/v1/SystemSetting', settings, config)

        dispatch({
            type: SYSTEM_SETTING_CREATE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: SYSTEM_SETTING_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

export const deleteSystemSetting = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_DELETE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await axios.delete(`/dotnet_api/v1/SystemSetting/${id}`, config)

        dispatch({
            type: SYSTEM_SETTING_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: SYSTEM_SETTING_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSystemSettingDto = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SYSTEM_SETTING_DTO_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.get('/dotnet_api/v1/SystemSetting/systemSettingDto', config)

        dispatch({
            type: SYSTEM_SETTING_DTO_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SYSTEM_SETTING_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}