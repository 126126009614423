import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../index';
import { authenticatedUserRoutes, publicRoutes, superUserRoutes } from "../../routes/allRoutes";
import {
  Layout, Menu, theme, Input, AutoComplete, Button, ConfigProvider, Switch, Space,
  Avatar, Dropdown, Tooltip, Select, Modal, Drawer
} from 'antd';
import {
  DashboardOutlined, FileOutlined, TeamOutlined, UserOutlined, CalendarOutlined,
  SettingOutlined, MenuOutlined, LineOutlined, ApartmentOutlined, BarcodeOutlined,
  DollarOutlined, FileTextOutlined, FileSearchOutlined, FileDoneOutlined, CreditCardOutlined,
  FileExcelOutlined, LogoutOutlined, PlusOutlined, GlobalOutlined, MessageOutlined, SearchOutlined, BulbOutlined
} from '@ant-design/icons';

import { debounce } from 'lodash';

import logo from '../../assets/images/logo.svg';
import icon from '../../assets/images/logo.png';

const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

function getItem(label, key, icon, children) {
  return { key, icon, children, label };
}

const AuthLayout = ({ content }) => {
  const { t, i18n } = useTranslation();
  const { toggleTheme, changeLocale, themeConfig } = useContext(ThemeContext);
  const { algorithm } = themeConfig;
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const siderBigWidth = 300;
  const siderSmallWidth = 80;
  const [searchOptions, setSearchOptions] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [siderVisible, setSiderVisible] = useState(!isMobile);
  const [drawerWidth, setDrawerWidth] = useState(siderBigWidth);

  const {
    token: { colorBgContainer, colorPrimary, colorTextSecondary },
  } = theme.useToken();

  const userInfo = useMemo(() => JSON.parse(localStorage.getItem('userInfo')), []);

  useEffect(() => {
    if (!userInfo) {
      localStorage.removeItem("2fauthenticated");
      localStorage.removeItem("userInfo");
      window.location.href = "/userlogin";
    }
  }, [userInfo]);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      setSiderVisible(!newIsMobile);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updateDrawerWidth = () => {
      const screenWidth = window.innerWidth;
      const siderWidth = collapsed ? siderSmallWidth : siderBigWidth;
      setDrawerWidth(Math.min(screenWidth, siderWidth));
    };

    updateDrawerWidth();
    window.addEventListener('resize', updateDrawerWidth);

    return () => window.removeEventListener('resize', updateDrawerWidth);
  }, [collapsed, siderBigWidth, siderSmallWidth]);

  const items = useMemo(() => [
    getItem(t('Dashboard'), '/user_dashboard', <DashboardOutlined />),
    getItem(t('Patients'), '/patients', <UserOutlined />),
    getItem(t('Test Management'), 'test_management', <FileSearchOutlined />, [
      getItem(t('Test Bulk Order Requests'), '/testOrderBulkRequests', <FileOutlined />),
      getItem(t('Test Orders'), '/testOrders', <FileOutlined />),
      getItem(t('Test Result Reports'), '/testResultReports', <FileDoneOutlined />),
    ]),
    getItem(t('Encounters'), '/encounters', <CalendarOutlined />),
    ...(userInfo?.roles.includes('SuperUser') ? [
      getItem(t('User Management'), '/user_management', <TeamOutlined />),
      getItem(t('Business organisation Management'), '/business_organisation_management', <GlobalOutlined />)
    ] : []),
    getItem(t('Finance'), 'finance', <CreditCardOutlined />, [
      getItem(t('Accounting'), '/accounting', <DollarOutlined />),
      getItem(t('Invoice Reports'), '/invoiceReports', <FileExcelOutlined />),
      getItem(t('Payments'), '/payments', <DollarOutlined />),
    ]),
    getItem(t('Quality Control'), '/qc_dashboard', <FileOutlined />, [
      getItem(t('QC Dashboard'), '/qc_dashboard', <FileOutlined />),
      getItem(t('QC Result Management'), '/qc_result_management', <FileOutlined />),
      getItem(t('QC Schedule Management'), '/qc_schedule_management', <FileOutlined />),
      getItem(t('Control Material Management'), '/control_material_management', <FileOutlined />),
      getItem(t('QC Rule Management'), '/qc_rule_management', <FileOutlined />),
      getItem(t('QC Report Management'), '/qc_report_management', <FileOutlined />),
    ]),
    getItem(t('Chat'), '/chat', <MessageOutlined />),
    getItem(t('Settings'), '/settings', <SettingOutlined />, [
      getItem(t('Control Panel'), '/control_panel', <SettingOutlined />),
      getItem(t('Test Catalog'), '/TestCatalog', <FileTextOutlined />),
      getItem(t('Test Categories'), '/TestCategories', <FileTextOutlined />),
      getItem(t('Inventory Lines'), '/InventoryLines', <BarcodeOutlined />),
      getItem(t('Pricing Tiers'), '/pricing/tiers', <ApartmentOutlined />),
      getItem(t('Pricing Rules'), '/pricing/rules', <DollarOutlined />),
      getItem(t('Templates'), '/settings/templates', <FileOutlined />),
      getItem(t('Sample Catalog'), '/sampleCatalog', <FileOutlined />),
      getItem(t('Sample Inventory Lines'), '/sampleInventoryLines', <BarcodeOutlined />),
    ]),
  ], [t, userInfo]);

  const languageOptions = useMemo(() => [
    { value: 'en', label: 'English', flag: '🇬🇧' },
    { value: 'fr', label: 'Français', flag: '🇫🇷' },
  ], []);

  const handleOpenChange = useCallback((keys) => {
    setOpenKeys(keys);
  }, []);

  const handleMenuClick = useCallback((e) => {
    const { key, keyPath } = e;
    history.push(key);
    if (isMobile) {
      setSiderVisible(false);
    }
  }, [history, isMobile]);

  useEffect(() => {
    const currentPath = location.pathname;
    const newOpenKeys = items
      .filter((item) => item.children && item.children.some((child) => matchPath(currentPath, { path: child.key, exact: true })))
      .map((item) => item.key);
    const selectedKeys = items
      .filter((item) => matchPath(currentPath, { path: item.key, exact: true }))
      .map((item) => item.key);

    setOpenKeys((prevOpenKeys) => {
      const combinedKeys = [...new Set([...newOpenKeys, ...selectedKeys, ...prevOpenKeys])];
      return combinedKeys.filter(key =>
        items.some(item =>
          item.key === key || (item.children && item.children.some(child => child.key === key))
        )
      );
    });
  }, [location.pathname, items]);

  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      const allRoutes = [...authenticatedUserRoutes, ...publicRoutes, ...superUserRoutes];
      const filteredRoutes = allRoutes
        .filter(route => route.searchable && t(route.searchLabel).toLowerCase().includes(value.toLowerCase()))
        .map(route => ({
          value: route.path,
          label: (
            <div>
              {route.icon && route.icon}
              <span style={{ marginLeft: 8 }}>{t(route.searchLabel)}</span>
            </div>
          ),
        }));
      setSearchOptions(filteredRoutes);
    }, 300),
    [t]
  );

  const handleSearchSelect = useCallback((value) => {
    history.push(value);
    setSearchModalVisible(false);
  }, [history]);

  const handleLanguageChange = useCallback((value) => {
    changeLocale(value);
    setCurrentLanguage(value);
    i18n.changeLanguage(value);
  }, [changeLocale, i18n]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("2fauthenticated");
    window.location.href = "/userlogin";
  }, []);

  const userMenu = useMemo(() => (
    <Menu onClick={({ key }) => {
      switch (key) {
        case 'settings':
          history.push('/control_panel');
          break;
        case 'logout':
          handleLogout();
          break;
      }
    }}>
      <Menu.Item key="username" disabled>
        <UserOutlined /> {userInfo?.username}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="settings" icon={<SettingOutlined />}>{t('Settings')}</Menu.Item>
      <Menu.Item key="darkMode" icon={<BulbOutlined />}>
        {t('Dark Mode')}
        <Switch
          size='small'
          checked={algorithm === theme.darkAlgorithm}
          onChange={toggleTheme}
          style={{ marginLeft: 8 }}
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />}>{t('Logout')}</Menu.Item>
    </Menu>
  ), [algorithm, handleLogout, history, t, theme.darkAlgorithm, toggleTheme, userInfo]);

  const renderSearchModal = useCallback(() => (
    <Modal
      visible={searchModalVisible}
      onCancel={() => setSearchModalVisible(false)}
      footer={null}
      title={t("Search pages...")}
    >
      <AutoComplete
        style={{ width: '100%' }}
        options={searchOptions}
        onSelect={handleSearchSelect}
        onSearch={debouncedHandleSearch}
      >
        <Search placeholder={t("Search pages...")} enterButton />
      </AutoComplete>
    </Modal>
  ), [debouncedHandleSearch, handleSearchSelect, searchModalVisible, searchOptions, t]);

  const renderSider = useCallback(() => (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      width={siderBigWidth}
      collapsedWidth={siderSmallWidth}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        background: colorBgContainer,
      }}
    >
      <div style={{
        height: '32px',
        margin: '16px',
      }}>
        {collapsed ? (
          <img
            src={icon}
            alt="logo"
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              height: '32px',
              cursor: 'pointer', // Add this to display the hand cursor
              filter: algorithm === theme.darkAlgorithm ?
                `invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)`
                : 'none',
            }}
          />
        ) : (
          <img
            src={logo}
            alt="logo"
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              height: '32px',
              cursor: 'pointer', // Add this to display the hand cursor
              filter: algorithm === theme.darkAlgorithm ?
                `invert(1) sepia(1) saturate(2) hue-rotate(45deg) brightness(1.2) contrast(1.2)`
                : 'none',
            }}
          />
        )}
      </div>

      <Menu
        theme={algorithm === theme.darkAlgorithm ? 'dark' : 'light'}
        mode="inline"
        openKeys={openKeys}
        selectedKeys={[location.pathname]}
        onClick={handleMenuClick}
        onOpenChange={handleOpenChange}
        items={items}
        style={{
          backgroundColor: colorBgContainer,
        }}
      />
    </Sider>
  ), [algorithm, collapsed, handleMenuClick, handleOpenChange, items, location.pathname, openKeys, theme.darkAlgorithm]);


  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setSiderVisible(false)}
          visible={siderVisible}
          width={drawerWidth}
          bodyStyle={{ padding: 0 }}
        >
          {renderSider()}
        </Drawer>
      ) : (
        renderSider()
      )}
      <Layout style={{
        marginLeft: isMobile ? 0 : (collapsed ? siderSmallWidth : siderBigWidth),
        transition: 'margin-left 0.2s',
      }}>
        <Header style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: colorBgContainer,
          boxShadow: '0 1px 0 rgba(0, 0, 0, 0.15)',
          padding: '0 16px',
        }}>
          <Space>
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={() => isMobile ? setSiderVisible(!siderVisible) : setCollapsed(!collapsed)}
            />
            <Button
              type="text"
              icon={<SearchOutlined />}
              onClick={() => setSearchModalVisible(true)}
            />
          </Space>

          <Space size="small">
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => handleLanguageChange(key)}>
                  {languageOptions.map(option => (
                    <Menu.Item key={option.value}>
                      <Space>
                        {option.flag}
                        {option.label}
                      </Space>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <Button type="text">
                {languageOptions.find(option => option.value === currentLanguage)?.flag}
              </Button>
            </Dropdown>

            <Dropdown overlay={userMenu} trigger={['click']}>
              <Avatar
                style={{ backgroundColor: colorPrimary, cursor: 'pointer' }}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </Space>
        </Header>

        <Content style={{ margin: '24px 16px', padding: 24 }}>
          {content}
        </Content>

        <Footer style={{ textAlign: 'center', color: colorTextSecondary }}>
          {t('Prolab LIS ©{{year}} All Rights Reserved.', { year: new Date().getFullYear() })}
        </Footer>
      </Layout>
      {renderSearchModal()}
    </Layout>
  );
};

export default AuthLayout;