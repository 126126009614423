import {

    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,

    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,

    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_FAIL,

    PAYMENT_CREATE_REQUEST,
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_CREATE_FAIL,
    PAYMENT_CREATE_RESET,

    PAYMENT_UPDATE_REQUEST,
    PAYMENT_UPDATE_SUCCESS,
    PAYMENT_UPDATE_FAIL,
    PAYMENT_UPDATE_RESET,

    SET_CURRENT_PAGE,

    PAYMENT_DTO_FAIL,
    PAYMENT_DTO_REQUEST,
    PAYMENT_DTO_SUCCESS,
    PAYMENT_DTO_RESET,

    PAYMENT_LIST_BYIDS_REQUEST,
    PAYMENT_LIST_BYIDS_SUCCESS,
    PAYMENT_LIST_BYIDS_FAIL,
    PAYMENT_LIST_BYIDS_RESET

} from "../constants/paymentConstants";

const initialState = {
    payments: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const paymentListReducer = (
    state = {
        payments: [],
        statistics: null,
        currentPage: 1,
        totalPages: 1,
        totalCount: 0
    }, action) => {
    switch (action.type) {
        case PAYMENT_LIST_REQUEST:
            return { ...state, loading: true, error: null };
        case PAYMENT_LIST_SUCCESS:
            return {
                loading: false,
                payments: action.payload.payments,
                statistics: action.payload.statistics,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalCount: action.payload.statistics.totalCount,
                error: null
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case PAYMENT_LIST_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export const paymentListByIDsReducer = (
    state = {
        payments: []
    }, action) => {
    switch (action.type) {
        case PAYMENT_LIST_BYIDS_REQUEST:
            return { loading: true, payments: [] };
        case PAYMENT_LIST_BYIDS_SUCCESS:
            // our state will have a list of payments after the request
            return { loading: false, payments: action.payload };
        case PAYMENT_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case PAYMENT_LIST_BYIDS_RESET:
            return { payments: [] };
        default:
            return state;
    }
}


export const paymentDetailsReducer = (
    state = {
        payment: { comments: [] }
    }, action) => {
    switch (action.type) {
        case PAYMENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case PAYMENT_DETAILS_SUCCESS:
            // our state will have a list of payments after the request
            return { loading: false, payment: action.payload };
        case PAYMENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const paymentDtoReducer = (state = { paymentDto: {} }, action) => {
    switch (action.type) {
        case PAYMENT_DTO_REQUEST:
            return { loading: true, paymentDto: {} };
        case PAYMENT_DTO_SUCCESS:
            return { loading: false, paymentDto: action.payload };
        case PAYMENT_DTO_FAIL:
            return { loading: false, error: action.payload };
        case PAYMENT_DTO_RESET:
            return { paymentDto: {} };
        default:
            return state;
    }
}

export const paymentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PAYMENT_DELETE_REQUEST:
            return { loading: true }

        case PAYMENT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PAYMENT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const paymentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PAYMENT_CREATE_REQUEST:
            return { loading: true }

        case PAYMENT_CREATE_SUCCESS:
            return { loading: false, success: true, payment: action.payload }

        case PAYMENT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PAYMENT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const paymentUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case PAYMENT_UPDATE_REQUEST:
            return { loading: true }

        case PAYMENT_UPDATE_SUCCESS:
            return { loading: false, success: true, payment: action.payload }

        case PAYMENT_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case PAYMENT_UPDATE_RESET:
            return { payment: {} }

        default:
            return state
    }
}