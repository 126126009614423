export const RATING_LIST_REQUEST = 'RATING_LIST_REQUEST'
export const RATING_LIST_SUCCESS = 'RATING_LIST_SUCCESS'
export const RATING_LIST_FAIL = 'RATING_LIST_FAIL'


export const RATING_DETAILS_REQUEST = 'RATING_DETAILS_REQUEST'
export const RATING_DETAILS_SUCCESS = 'RATING_DETAILS_SUCCESS'
export const RATING_DETAILS_FAIL = 'RATING_DETAILS_FAIL'


export const RATING_DELETE_REQUEST = 'RATING_DELETE_REQUEST'
export const RATING_DELETE_SUCCESS = 'RATING_DELETE_SUCCESS'
export const RATING_DELETE_FAIL = 'RATING_DELETE_FAIL'

export const RATING_CREATE_REQUEST = 'RATING_CREATE_REQUEST'
export const RATING_CREATE_SUCCESS = 'RATING_CREATE_SUCCESS'
export const RATING_CREATE_FAIL = 'RATING_CREATE_FAIL'
export const RATING_CREATE_RESET = 'RATING_CREATE_RESET'

export const RATING_UPDATE_REQUEST = 'RATING_UPDATE_REQUEST'
export const RATING_UPDATE_SUCCESS = 'RATING_UPDATE_SUCCESS'
export const RATING_UPDATE_FAIL = 'RATING_UPDATE_FAIL'
export const RATING_UPDATE_RESET = 'RATING_UPDATE_RESET'

export const RATING_REQUEST = 'RATING_REQUEST'
export const RATING_SUCCESS = 'RATING_SUCCESS'
export const RATING_FAIL = 'RATING_FAIL'
export const RATING_RESET = 'RATING_RESET'

export const RATING_COUNT_REQUEST = "RATING_COUNT_REQUEST";
export const RATING_COUNT_SUCCESS = "RATING_COUNT_SUCCESS";
export const RATING_COUNT_FAIL = "RATING_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const RATING_DTO_REQUEST = 'RATING_DTO_REQUEST';
export const RATING_DTO_SUCCESS = 'RATING_DTO_SUCCESS';
export const RATING_DTO_FAIL = 'RATING_DTO_FAIL';
export const RATING_DTO_RESET = 'RATING_DTO_RESET';

export const RATING_LIST_BYIDS_REQUEST = 'RATING_LIST_BYIDS_REQUEST';
export const RATING_LIST_BYIDS_SUCCESS = 'RATING_LIST_BYIDS_SUCCESS';
export const RATING_LIST_BYIDS_FAIL = 'RATING_LIST_BYIDS_FAIL';
export const RATING_LIST_BYIDS_RESET = 'RATING_LIST_BYIDS_RESET';