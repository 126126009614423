import React from 'react';
import { Modal, Form, Checkbox, Input, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import SingleEntitySelect from '../../../components/crud/SingleEntitySelect.component';
import { listPricingTiers } from '../../../actions/pricingTierActions';
import { listSamples } from '../../../actions/sampleActions';

const InventoryLineOptionsModal = ({ visible, onCancel, onOk, form }) => {
    const { t } = useTranslation();
  
    return (
      <Modal
        title={t('importer.inventoryLineOptions')}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        width={600}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="pricingTierId"
            label={t('importer.pricingTier')}
            rules={[{ required: true, message: t('importer.pleaseSelectPricingTier') }]}
          >
            <SingleEntitySelect
              singularEntity="pricingTier"
              pluralEntity="pricingTiers"
              constantPrefix="PRICING_TIER"
              listEntities={listPricingTiers}
              primarySearchProp="name"
              secondarySearchProp="description"
            />
          </Form.Item>
          <Form.Item
            name="sampleId"
            label={t('importer.sample')}
            rules={[{ required: true, message: t('importer.pleaseSelectSample') }]}
          >
            <SingleEntitySelect
              singularEntity="sample"
              pluralEntity="samples"
              constantPrefix="SAMPLE"
              listEntities={listSamples}
              primarySearchProp="name"
              secondarySearchProp="sampleType"
            />
          </Form.Item>
          <Form.Item
            name="barcodeStart"
            label={t('importer.barcodeStart')}
            rules={[{ required: true, message: t('importer.pleaseEnterBarcodeStart') }]}
          >
            <Input placeholder={t('importer.barcodeStart')} />
          </Form.Item>
          <Form.Item
            name="barcodeLength"
            label={t('importer.barcodeLength')}
            rules={[{ required: true, message: t('importer.pleaseEnterBarcodeLength') }]}
          >
            <Input type="number" placeholder={t('importer.barcodeLength')} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  
  export default InventoryLineOptionsModal;
  