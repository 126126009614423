import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Row, Col, Card, Typography, Button, Space, Affix, Table, Tag, 
  Drawer, Timeline, message, Descriptions, Avatar, Modal, Input
} from 'antd';
import { 
  PrinterOutlined, BarcodeOutlined, FileTextOutlined, 
  HistoryOutlined, MergeCellsOutlined, UserOutlined, SearchOutlined
} from '@ant-design/icons';
import { getPatientDetails } from '../../actions/patientActions';
import Printer from '../../components/printer/Printer.component';

const { Title, Text } = Typography;
const { Search } = Input;

const SamplesViewPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [barcode, setBarcode] = useState('');
  const [testOrderBulkRequest, setTestOrderBulkRequest] = useState(null);
  const [sampleInventoryLines, setSampleInventoryLines] = useState([]);
  const [mergedSamples, setMergedSamples] = useState([]);
  const [selectedSample, setSelectedSample] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isPrinterVisible, setPrinterVisible] = useState(false);
  const [printerData, setPrinterData] = useState({});

  const patientDetails = useSelector(state => state.patientDetails.patient);

  const fetchTestOrderBulkRequest = async (barcode) => {
    try {
      const response = await axios.get(`/dotnet_api/v1/testOrderBulkRequest/barcode/${barcode}`);
      setTestOrderBulkRequest(response.data);
      dispatch(getPatientDetails(response.data.patientId));
      fetchSampleInventoryLines(barcode);
    } catch (error) {
      message.error('Failed to fetch TestOrderBulkRequest');
    }
  };

  const fetchSampleInventoryLines = async (barcode) => {
    try {
      const response = await axios.get(`/dotnet_api/v1/sampleinventoryline/barcode/${barcode}`);
      setSampleInventoryLines(response.data);
      generateMergedSamples(response.data);
    } catch (error) {
      message.error('Failed to fetch sample inventory lines');
    }
  };

  const generateMergedSamples = (samples) => {
    const mergedMap = new Map();
    samples.forEach(sample => {
      if (!mergedMap.has(sample.sampleId)) {
        mergedMap.set(sample.sampleId, { ...sample, testNames: [sample.testName] });
      } else {
        const existing = mergedMap.get(sample.sampleId);
        existing.testNames.push(sample.testName);
      }
    });
    setMergedSamples(Array.from(mergedMap.values()));
  };

  const handleMergeDuplicates = () => {
    setSampleInventoryLines(mergedSamples);
    message.success('Duplicate samples merged successfully');
  };

  const showSampleHistory = (sample) => {
    setSelectedSample(sample);
    setIsDrawerVisible(true);
  };

  const columns = [
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: 'Sample Type',
      dataIndex: 'sampleType',
      key: 'sampleType',
    },
    {
      title: 'Test Name(s)',
      dataIndex: 'testNames',
      key: 'testNames',
      render: (testNames) => (
        <>
          {(testNames || [])?.map(name => (
            <Tag color="blue" key={name}>
              {name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
    },
    {
      title: 'Collection Date',
      dataIndex: 'collectionDate',
      key: 'collectionDate',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showSampleHistory(record)} icon={<HistoryOutlined />}>
          History
        </Button>
      ),
    },
  ];

  const handlePrint = (type) => {
    let data;
    switch (type) {
      case 'samples':
        data = { samples: sampleInventoryLines, patient: patientDetails };
        break;
      case 'barcodes':
        data = { barcodes: sampleInventoryLines?.map(s => s.barcode) };
        break;
      case 'sampleSheet':
        data = { samples: sampleInventoryLines, testOrderBulkRequest, patient: patientDetails };
        break;
      default:
        message.error('Invalid print type');
        return;
    }
    setPrinterData(data);
    setPrinterVisible(true);
  };

  const renderPatientSummary = useMemo(() => {
    if (!patientDetails) return null;
  
    return (
      <Card title={<Space><Avatar icon={<UserOutlined />} /> Patient Summary</Space>}>
        <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label="Name">{`${patientDetails.forename} ${patientDetails.surname}`}</Descriptions.Item>
          <Descriptions.Item label="DOB">{new Date(patientDetails.dateOfBirth).toLocaleDateString()}</Descriptions.Item>
          <Descriptions.Item label="Gender">{patientDetails.gender}</Descriptions.Item>
          <Descriptions.Item label="NHS Number">{patientDetails.healthInsuranceNumber}</Descriptions.Item>
        </Descriptions>
      </Card>
    );
  }, [patientDetails]);

  return (
    <div style={{ padding: '24px' }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Title level={2}>Sample Management</Title>
        <Search
          placeholder="Enter TestOrderBulkRequest barcode"
          enterButton="Search"
          size="large"
          onSearch={(value) => fetchTestOrderBulkRequest(value)}
          style={{ width: 500 }}
        />
        {testOrderBulkRequest && (
          <>
            {renderPatientSummary}
            <Card title="Sample Inventory">
              <Space style={{ marginBottom: 16 }}>
                <Button onClick={handleMergeDuplicates} icon={<MergeCellsOutlined />}>
                  Merge Duplicates
                </Button>
              </Space>
              <Table columns={columns} dataSource={sampleInventoryLines} rowKey="id" />
            </Card>
          </>
        )}
      </Space>

      <Affix style={{ position: 'fixed', right: 50, bottom: 50 }}>
        <Card>
          <Space direction="vertical">
            <Button icon={<PrinterOutlined />} onClick={() => handlePrint('samples')} disabled={!testOrderBulkRequest}>
              Print Samples
            </Button>
            <Button icon={<BarcodeOutlined />} onClick={() => handlePrint('barcodes')} disabled={!testOrderBulkRequest}>
              Print Barcodes
            </Button>
            <Button icon={<FileTextOutlined />} onClick={() => handlePrint('sampleSheet')} disabled={!testOrderBulkRequest}>
              Generate Sample Sheet
            </Button>
            <Button onClick={() => history.push('/sampleInventory')}>
              Sample Inventory List
            </Button>
          </Space>
        </Card>
      </Affix>

      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        {selectedSample && (
          <>
            <Title level={4}>Sample History</Title>
            <Timeline mode="left">
              {JSON.parse(selectedSample.history)?.map((record, index) => (
                <Timeline.Item key={index} label={new Date(record.updatedAt).toLocaleString()}>
                  <p><strong>Status:</strong> {record.currentStatus}</p>
                  <p><strong>Location:</strong> {record.currentLocation}</p>
                  <p><strong>Accessed By:</strong> {record.lastAccessedBy}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </>
        )}
      </Drawer>

      <Modal
        title="Print"
        visible={isPrinterVisible}
        onCancel={() => setPrinterVisible(false)}
        footer={null}
        width={800}
      >
        <Printer
          configEndpoint="/print/configs/samples"
          generateEndpoint="/print/documents/generate"
          data={printerData}
        />
      </Modal>
    </div>
  );
};

export default SamplesViewPage;